import React, { useState, useEffect } from 'react';
import {
  Box,
  Toolbar,
  TableContainer,
  Table,
  // TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  // Pagination,
  Typography,
  Grid,
  Card,
  AlertColor,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Textinput from '../../components/formlib/Textinput';
import SearchBox from '../../components/formlib/SearchBox';
// import TableSortLabel from '@mui/material/TableSortLabel';
// import { visuallyHidden } from '@mui/utils';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import './UserGroupListGrid.css';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  EditGroups,
  GroupEmployees,
  GroupPermissions,
  ScreenDTO,
  ScreenNamesAccess,
  ScreenPermission,
  UserGroups,
  UserGroupsType,
  addGroups,
  editGroups,
  getAllEmployeesWithGroupName,
  getAllScreens,
  getGroupsById,
} from '../../services/configApi/userGroups/userGroupServices';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import backarrow from '../../assets/images/backarrow.svg';
import { useLocation, useNavigate } from 'react-router';
import { USER_GROUPS } from '../../routes/Routing';
// import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { TableHeader } from '../../components/formlib/TableHeader';
import _ from 'lodash';

const BackArrowImage = styled('img')`
  cursor: pointer;
  border: 3px;
`;

// export type ScreenPermission = {
//   id: string;
//   screenName: string;
//   isViewEnabled: boolean;
//   isAddEnabled: boolean;
//   isEditEnabled: boolean;
//   isDeleteEnabled: boolean;
// };

type HeadCell = {
  id: keyof Data;
  labelId: string;
  defaultLabelId: string;
  numeric: boolean;
  requiredSorting: boolean;
};

const headCells: HeadCell[] = [
  {
    id: 'screenName',
    labelId: 'Screens',
    defaultLabelId: 'Screens',
    numeric: false,
    requiredSorting: false,
  },

  {
    id: 'view',
    labelId: 'View',
    defaultLabelId: 'View',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'add',
    labelId: 'Add',
    defaultLabelId: 'Add',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'edit',
    labelId: 'Edit',
    defaultLabelId: 'Edit',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'delete',
    labelId: 'Delete',
    defaultLabelId: 'Delete',
    numeric: false,
    requiredSorting: false,
  },
];

export type Data = {
  screenName: string;
  view: string;
  add: string;
  edit: string;
  delete: string;
};
// interface EnhancedTableProps {
//   numSelected: number;
//   onRequestSort: (
//     event: React.MouseEvent<unknown>,
//     property: keyof Data,
//   ) => void;
//   order: Order;
//   orderBy: string;
//   rowCount: number;
// }

// export type Order = 'asc' | 'desc';

// const TableHeader: FC<EnhancedTableProps> = (props: EnhancedTableProps) => {
//   const { order, orderBy, onRequestSort } = props;

//   // const createSortHandler =
//   //   (property: keyof Data, requiredSort: boolean) =>
//   //   (event: React.MouseEvent<unknown>) => {
//   //     if (requiredSort) {
//   //       onRequestSort(event, property);
//   //     }
//   //   };

//   return (
//     <TableHead>
//       <TableRow>
//         {headCells.map((cell: HeadCell) => (
//           <TableCell
//             key={cell.id}
//             align={cell.numeric ? 'right' : 'left'}
//             sx={{ height: '49px', padding: '12px 14px' }}
//           >
//             <TableSortLabel
//               className="Tableheaderstyle"
//               // sx={{
//               //   fontFamily: 'Lato-Bold',
//               //   color: '#97A6A5',
//               //   textWrap: 'nowrap',
//               // }}
//               // active={orderBy === cell.id}
//               // direction={orderBy === cell.id ? order : 'asc'}
//               // onClick={createSortHandler(cell.id, cell.requiredSorting)}
//               // hideSortIcon={!cell.requiredSorting}
//             >
//               {cell.label}
//               {/* {orderBy === cell.id ? (
//                 <Box component="span" sx={visuallyHidden}>
//                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                 </Box>
//               ) : null} */}
//             </TableSortLabel>
//           </TableCell>
//         ))}
//       </TableRow>
//     </TableHead>
//   );
// };

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00C6B8',
          height: 3,
        },
      },
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    color: '#00C6B8',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const CustomTabs = styled(Tabs)({
  '& .MuiButtonBase-root.MuiTab-root': {
    padding: '12px 0px',
    maxWidth: '100%',
    minWidth: 'auto',
    margin: '0px 12px',
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    lineHeight: '20px',
  },
  '&.Mui-selected': {
    color: '#008C82',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: '24px 0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export interface TabProps {
  tabPosition: number;
}

const excludedIds = [
  'backend.email_text_template',
  'backend.work_schedule',
  'backend.block_time',
];

const AddUserGroup = () => {
  const CustomTableCell = styled(TableCell)(() => ({
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '17px',
    padding: '14px 16px',
  }));

  const [tabProp, setTabProp] = React.useState<TabProps>({
    tabPosition: 0,
  });
  // const [order, setOrder] = useState<Order>('desc');
  // const [orderBy, setOrderBy] = useState<keyof Data | string>('screenName');
  const [logs, setLogs] = useState<ScreenDTO[]>([]);
  // const [paginationCount, setPaginationCount] = useState<number>(0);
  // const [pageNumber, setPageNumber] = useState(0);
  const [employees, setEmployees] = useState<GroupEmployees[]>([]);
  const [mainEmployeeList, setMainEmployeeList] = useState<GroupEmployees[]>(
    [],
  );
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState<
    GroupEmployees[]
  >([]);
  const [employeeId, setEmployeeId] = React.useState<string[]>([]);
  const [removedEmployeeId, setRemovedEmployeeId] = React.useState<string[]>(
    [],
  );
  const [permissions, setPermissions] = useState<ScreenPermission[]>([]);
  const [groupName, setGroupName] = useState('');
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [addedEmployees, setAddedEmployees] = React.useState<GroupEmployees[]>(
    [],
  );
  const [backUpAddedEmp, setBackUpAddedEmp] = React.useState<GroupEmployees[]>(
    [],
  );
  const [selectRemoveArr, setSelectedRemoveArr] = React.useState<
    GroupEmployees[]
  >([]);

  const [screenNameAccessToggle, setScreenNameAccessToggle] = React.useState<
    ScreenNamesAccess[]
  >([]);

  const [defaultDisabled, setDefaultDisabled] = React.useState(false);

  const [btnDisable, setButtonDisable] = React.useState(true);
  const [removebtnDisable, setRemoveButtonDisable] = React.useState(true);
  const parentRef = React.useRef<HTMLDivElement>(null);
  // const PAGE_SIZE = 10;
  // const [onsearchquery, setOnsearchquery] = useState('');
  // const [searchTriggered, setSearchTriggered] = useState(false);
  // const [searchquery, setSearchquery] = useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (
    _event: React.ChangeEvent<object>,
    position: number,
  ) => {
    setTabProp({ ...tabProp, tabPosition: position });
    if (position === 1) {
      getEmployeeLists();
    } else if (position === 0) {
      setAddedEmployees(backUpAddedEmp);
    }
  };

  const filterEmployeeList = (allEmployees: GroupEmployees[]) => {
    const filterEmployees = mainEmployeeList.filter(
      (employee: GroupEmployees) =>
        !allEmployees.some(
          (selectedEmployee) => selectedEmployee.name === employee.name,
        ),
    );
    return filterEmployees;
  };

  // const filterAddedEmployeeList = (allEmployees: GroupEmployees[]) => {
  //   const filterEmployees = mainEmployeeList.filter(
  //     (employee: GroupEmployees) =>
  //       allEmployees.some(
  //         (selectedEmployee) => selectedEmployee.name === employee.name,
  //       ),
  //   );
  //   return filterEmployees;
  // };

  const handleSearchInputChange = (val: string) => {
    const inputValue = val;

    if (val !== '') {
      const filtered = employees.filter((employee) =>
        employee.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setEmployees(filtered);
    } else {
      const getFilteredEmpl = filterEmployeeList(backUpAddedEmp);
      setEmployees(getFilteredEmpl);
    }
  };

  const handleSearhAddedEmpl = (val: string) => {
    const inputValue = val;

    if (val !== '') {
      const filtered = backUpAddedEmp.filter((employee) =>
        employee.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setAddedEmployees([...new Set(filtered)]);
    } else {
      // const getFilteredEmpl = filterAddedEmployeeList(backUpAddedEmp);
      setAddedEmployees(backUpAddedEmp);
    }
  };

  // const handleRequestSort = (
  //   event: React.MouseEvent<unknown>,
  //   property: keyof Data,
  // ) => {
  //   setOrder(order === 'asc' ? 'desc' : 'asc');
  //   setOrderBy(property);
  // };

  // const handleSearchChange = useCallback(
  //   debounce((value: string) => {
  //     setPageNumber(0);
  //     setSearchquery(value);
  //     setSearchTriggered(true);
  //   }, 500),
  //   [],
  // );

  // const handleChangePage = (event: unknown, newPage: number) => {
  //   if (newPage - 1 !== pageNumber) {
  //     setPageNumber(newPage - 1);
  //     setLogs([]);
  //     setEmployees([]);
  //   }
  // };

  const getEmployeeLists = () => {
    toggleLoader(true);
    getAllEmployeesWithGroupName()
      .then((response) => {
        toggleLoader(false);
        setMainEmployeeList(response.employees);
        const filterEmployees = response.employees.filter(
          (employee: GroupEmployees) =>
            !addedEmployees.some(
              (selectedEmployee) => selectedEmployee.userId === employee.userId,
            ),
        );
        setEmployees(filterEmployees);
      })
      .catch(() => {
        toggleLoader(false);
        setToastrVariable('error');
      });
  };

  const getScreenAPI = (type: string, levelPassed: number | null) => {
    toggleLoader(true);
    // getAllScreens(pageNumber, PAGE_SIZE, orderBy, order, onsearchquery)
    getAllScreens(levelPassed)
      .then((response: ScreenDTO[]) => {
        const responsePermissions: ScreenDTO[] = response.filter(
          (row: ScreenDTO) => !excludedIds.includes(row.screenBackendId),
        );
        setLogs(responsePermissions);

        // setPaginationCount(response.length);

        if (type === 'New') {
          const initialPermissions: ScreenPermission[] =
            responsePermissions.map((screen) => ({
              id: screen.id,
              addPermission: false,
              editPermission: false,
              viewPermission: false,
              deletePermission: false,
              screenBackendId: screen.screenBackendId,
              permissionId: '',
            }));

          setPermissions(initialPermissions);
        }

        const initialAccessScreens: ScreenNamesAccess[] =
          responsePermissions.map((screen) => ({
            id: screen.id,
            isAddEnabled: screen.screenAccessPermission.isAddEnabled,
            isEditEnabled: screen.screenAccessPermission.isEditEnabled,
            isViewEnabled: screen.screenAccessPermission.isViewEnabled,
            isDeleteEnabled: screen.screenAccessPermission.isDeleteEnabled,
          }));

        setScreenNameAccessToggle(initialAccessScreens);

        toggleLoader(false);
      })
      .catch(() => {
        toggleLoader(false);
      });
  };

  // useEffect(() => {
  //   if (searchTriggered) {
  //     setOnsearchquery(searchquery);
  //     setSearchTriggered(false);
  //   }
  //   if (!searchquery) {
  //     setOnsearchquery(searchquery);
  //   }
  // }, [searchquery, searchTriggered]);

  // const update = useCallback(
  //   debounce(() => {
  //     handleUpdate();
  //   }, 1000),
  //   [],
  // );

  useEffect(() => {
    if (location.state?.groupId) {
      toggleLoader(true);
      getGroupsById(location.state?.groupId)
        .then(async (response: UserGroupsType) => {
          toggleLoader(false);
          setGroupName(response.groupName);
          if (location.state?.level === -1) {
            getScreenAPI('Existing', 1);
          } else {
            getScreenAPI('Existing', location.state?.level);
          }
          setDefaultDisabled(
            response.createdBy.toLowerCase() === 'default' ? true : false,
          );

          const filterScreens = response.screens.filter(
            (screen) => !excludedIds.includes(screen.screenBackendId),
          );

          const responsePermissions: ScreenPermission[] = filterScreens.map(
            (screen) => ({
              id: screen.id,
              addPermission: screen.addPermission,
              editPermission: screen.editPermission,
              viewPermission: screen.viewPermission,
              deletePermission: screen.deletePermission,
              permissionId: screen.permissionId,
              screenBackendId: screen.screenBackendId,
            }),
          );
          setPermissions(responsePermissions);
          setAddedEmployees(response.employees);
          setBackUpAddedEmp(response.employees);
        })
        .catch((err) => {
          toggleLoader(false);
          throw err;
        });
    } else {
      const selectedProgram = localStorage.getItem('selectedProgram');
      let permissionsStored: GroupPermissions = JSON.parse('[]');
      if (selectedProgram && selectedProgram !== null) {
        const selectedPermission = localStorage.getItem(
          JSON.parse(selectedProgram).id,
        );
        permissionsStored = JSON.parse(
          selectedPermission ? selectedPermission : '[]',
        );
      }
      if (permissionsStored?.superSuperAdmin) {
        getScreenAPI('New', 1);
      } else {
        getScreenAPI('New', null);
      }
    }
  }, []);

  const handleChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    employee: GroupEmployees,
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setButtonDisable(false);
      setRemoveButtonDisable(true);
      setSelectedCheckboxes((prevSelected) => [...prevSelected, employee]);
      setSelectedRemoveArr([]);
      // setEmployeeId((prevSelected) => [...prevSelected, employee.userId]);
    } else {
      setSelectedCheckboxes((prevSelected) => {
        const updatedSelected = prevSelected.filter(
          (item) => item.userId !== employee.userId,
        );

        if (updatedSelected.length === 0) {
          setButtonDisable(true);
        }

        return updatedSelected;
      });

      setEmployeeId((prevSelected) =>
        prevSelected.filter((item) => item !== employee.userId),
      );
    }
  };

  const save = () => {
    const obj: UserGroups = {
      groupName: groupName,
      screens: permissions,
      employee: employeeId,
    };
    const hasAnyPermissionTrue = permissions.some(
      (screen) =>
        screen.addPermission ||
        screen.editPermission ||
        screen.viewPermission ||
        screen.deletePermission,
    );

    if (hasAnyPermissionTrue) {
      toggleLoader(true);
      addGroups(obj)
        .then(() => {
          setOpen(true);
          setToastrVariable('success');
          setToastrId('userGroups.save');
          setToastrDefaultMessage('Group Added Successfully!');
          setEmployeeId([]);
          setSelectedCheckboxes([]);
          setGroupName('');
          setTimeout(() => {
            navigate(USER_GROUPS);
          }, 500);
          toggleLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const response = error as ApiMessage;
            if (response.code === 1042) {
              setToastrVariable('error');
              setToastrId('UserGroups.groupNameConflict');
              setToastrDefaultMessage(
                'Group name already exists. Please try with different group name',
              );
            } else {
              setToastrId('userGroups.saveError');
              setToastrDefaultMessage('Failed to Add Group');
            }
          }
          toggleLoader(false);
        });
    } else {
      setOpen(true);
      setToastrVariable('error');
      setToastrId('userGroups.permissionError');
      setToastrDefaultMessage(
        'At least one permission must be added to the group in order to save it.',
      );
      toggleLoader(false);
    }
  };

  const saveWithDebounce = _.debounce(save, 500);

  const update = () => {
    const filteredEmployeeId = employeeId.filter(
      (id) => !removedEmployeeId.includes(id),
    );

    const obj: EditGroups = {
      groupName: groupName,
      screens: defaultDisabled ? [] : permissions,
      addEmployee: filteredEmployeeId,
      removeEmployee: [...new Set(removedEmployeeId)],
    };
    const hasAnyPermissionTrue = permissions.some(
      (screen) =>
        screen.addPermission ||
        screen.editPermission ||
        screen.viewPermission ||
        screen.deletePermission,
    );

    if (hasAnyPermissionTrue || defaultDisabled) {
      toggleLoader(true);
      editGroups(location.state?.groupId, obj)
        .then(() => {
          setOpen(true);
          setToastrVariable('success');
          setToastrId('UserGroups.update');
          setToastrDefaultMessage('Group Updated Successfully');
          setTimeout(() => {
            navigate(USER_GROUPS);
          }, 500);
          toggleLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const response = error as ApiMessage;
            if (response.code === 1042) {
              setToastrVariable('error');
              setToastrId('UserGroups.groupNameConflict');
              setToastrDefaultMessage(
                'Group name already exists. Please try with different group name',
              );
            } else {
              setToastrId('UserGroups.updateError');
              setToastrDefaultMessage('Failed to Update Group');
            }
            toggleLoader(false);
          }
        });
    } else {
      setOpen(true);
      setToastrVariable('error');
      setToastrId('userGroups.permissionError');
      setToastrDefaultMessage(
        'At least one permission must be added to the group in order to save it.',
      );
      toggleLoader(false);
    }
  };

  const updateWithDebounce = _.debounce(update, 500);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const addEmployeeToGroup = () => {
    setButtonDisable(true);
    selectedCheckboxes.forEach((employee) => {
      setRemovedEmployeeId((prevRemoved) =>
        prevRemoved.filter((id) => id !== employee.userId),
      );
      setEmployeeId((prevSelected) => {
        if (!prevSelected.includes(employee.userId)) {
          return [...prevSelected, employee.userId];
        }
        return prevSelected;
      });
    });

    setBackUpAddedEmp((prevSelected) => [
      ...prevSelected,
      ...selectedCheckboxes,
    ]);

    const updatedAddedEmployees = [
      ...addedEmployees,
      ...selectedCheckboxes.filter(
        (employee) =>
          !addedEmployees.some((added) => added.userId === employee.userId),
      ),
    ];

    setAddedEmployees([...new Set(updatedAddedEmployees)]);

    // const getSelectedBackup = mainEmployeeList.filter((selectedEmployees) =>
    //   updatedAddedEmployees.some(
    //     (selectedEmployee) =>
    //       selectedEmployee.userId === selectedEmployees.userId,
    //   ),
    // );

    // setBackUpAddedEmp(getSelectedBackup);

    const updatedEmployees = employees.filter(
      (employee: GroupEmployees) =>
        !selectedCheckboxes.some(
          (selectedEmployee) => selectedEmployee.userId === employee.userId,
        ),
    );

    setEmployees(updatedEmployees);

    const reAddedForRemovalEmpl = employees.map((employee) => employee.userId);

    const updatedSelectedCheckboxes = selectRemoveArr.filter(
      (employee) => !reAddedForRemovalEmpl.includes(employee.userId),
    );

    setSelectedRemoveArr(updatedSelectedCheckboxes);
  };

  const handleRemovalChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    removedEmployees: GroupEmployees,
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setButtonDisable(true);
      setRemoveButtonDisable(false);
      setSelectedRemoveArr((prevSelected) => [
        ...prevSelected,
        removedEmployees,
      ]);
      // setSelectedCheckboxes((prevSelected) => [
      //   ...prevSelected,
      //   removedEmployees,
      // ]);
      setSelectedCheckboxes([]);
      // setRemovedEmployeeId((prevSelected) => [
      //   ...prevSelected,
      //   removedEmployees.userId,
      // ]);
      // setEmployeeId((prevSelected) =>
      //   prevSelected.filter((item) => item !== removedEmployees.userId),
      // );
    } else {
      // setRemoveButtonDisable(true);

      // setSelectedRemoveArr((prevSelected) =>
      //   prevSelected.filter((item) => item.userId !== removedEmployees.userId),
      // );
      setSelectedRemoveArr((prevSelected) => {
        const updatedSelected = prevSelected.filter(
          (item) => item.userId !== removedEmployees.userId,
        );

        // Disable remove button if no items are selected
        if (updatedSelected.length === 0) {
          setRemoveButtonDisable(true);
        }

        return updatedSelected;
      });
      // setSelectedCheckboxes((prevSelected) =>
      //   prevSelected.filter((item) => item.userId !== removedEmployees.userId),
      // );
      setRemovedEmployeeId((prevSelected) =>
        prevSelected.filter((item) => item !== removedEmployees.userId),
      );
    }
  };

  const removeEmployees = () => {
    setRemoveButtonDisable(true);

    selectRemoveArr.forEach((employee) => {
      setRemovedEmployeeId((prevSelected) => [
        ...prevSelected,
        employee.userId,
      ]);
    });

    const updatedEmployeesss = [
      ...employees,
      ...selectRemoveArr.filter(
        (selectedEmployee) =>
          !employees.some(
            (employee) => employee.userId === selectedEmployee.userId,
          ),
      ),
    ];

    setEmployees(updatedEmployeesss);

    const updatedEmployees = addedEmployees.filter(
      (employee: GroupEmployees) =>
        !selectRemoveArr.some(
          (selectedEmployee) => selectedEmployee.userId === employee.userId,
        ),
    );

    setAddedEmployees([...new Set(updatedEmployees)]);

    const getSelectedBackup = backUpAddedEmp.filter(
      (selectedEmployees) =>
        !updatedEmployeesss.some(
          (selectedEmployee) =>
            selectedEmployee.userId === selectedEmployees.userId,
        ),
    );

    setBackUpAddedEmp(getSelectedBackup);

    // const reAddedEmployeeFirstNames = addedEmployees.map(
    //   (employee) => employee.name,
    // );

    // const updatedSelectedCheckboxes = selectRemoveArr.filter(
    //   (employee) => !reAddedEmployeeFirstNames.includes(employee.name),
    // );

    // setSelectedCheckboxes(updatedSelectedCheckboxes);
  };

  const clearAllEmployees = () => {
    setSelectedCheckboxes([]);
    setButtonDisable(true);
  };

  const removeAllAddedEmployees = () => {
    setSelectedRemoveArr([]);
    setRemoveButtonDisable(true);
  };

  const handlePermissionChange = (
    screenId: string,
    permission: keyof Omit<
      ScreenPermission,
      'id' | 'screenBackendId' | 'permissionId'
    >,
  ) => {
    setPermissions((prevPermissions) => {
      return prevPermissions.map((screen) => {
        if (screen.screenBackendId === screenId) {
          if (
            permission === 'editPermission' ||
            permission === 'addPermission' ||
            permission === 'deletePermission'
          ) {
            screen.viewPermission = true;
          }
          if (
            (permission === 'viewPermission' &&
              screen.editPermission === true) ||
            (permission === 'viewPermission' &&
              screen.addPermission === true) ||
            (permission === 'viewPermission' &&
              screen.deletePermission === true)
          ) {
            screen.viewPermission = false;
          }
          screen[permission] = !screen[permission];
        }
        return screen;
      });
    });
  };

  const nameValidation = (value: string): string | undefined => {
    const trimmedValue = value?.replace(/^\s+/, '');
    const regex = /^[A-Za-z0-9(),'."! -]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const handleScreenName = (value: string) => {
    if (value === 'backend.employee') {
      return 'employeeText';
    } else if (value === 'backend.clients') {
      return 'groups.ClientText';
    } else if (value === 'backend.referral_dashboard') {
      return 'groups.ReferralText';
    } else if (value === 'backend.case_load') {
      return 'groups.case_load';
    } else if (value === 'backend.ssp_management') {
      return 'groups.ssp_management';
    } else if (value === 'backend.travel_expense') {
      return 'groups.travel_expense';
    } else if (value === 'myActivityText') {
      return 'myActivitySheetNameText';
    }
    return value;
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: '#E7F0F0',
            padding: '1px 24px',
            height: '100%',
          }}
        >
          <Toolbar disableGutters>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                my: '10px',
              }}
            >
              <BackArrowImage
                src={backarrow}
                alt="backarrow"
                onClick={() => navigate(USER_GROUPS)}
              />
              {!location.state?.groupId && (
                <MediumTypography
                  labelid="addUserGroup.titleText"
                  defaultlabel="Add Group"
                  fontSize="30px"
                  fontweight={700}
                  sxProps={{ padding: '0px 14px' }}
                />
              )}
              {location.state?.groupId && (
                <MediumTypography
                  labelid="addUserGroup.editText"
                  defaultlabel="Edit Group"
                  fontSize="30px"
                  fontweight={700}
                  sxProps={{ padding: '0px 14px' }}
                />
              )}
            </Box>
          </Toolbar>
          <Card sx={{ padding: '24px', my: '0px', ml: '0px' }}>
            <Grid container item spacing={2}>
              <Grid item xs={4.5} lg={4.5}>
                <Textinput
                  name="addGroup"
                  labelid="AddGroup.groupName"
                  defaultlabelid="Enter Group Name"
                  inputProps={{
                    maxLength: 50,
                  }}
                  Value={groupName}
                  handlechange={(value: string) => {
                    const inputValue = value;
                    const validatedValue = nameValidation(inputValue);
                    if (validatedValue !== undefined) {
                      setGroupName(value);
                    }
                  }}
                  disabled={defaultDisabled}
                />
              </Grid>
              <Grid item xs={4.5} lg={4.5}></Grid>
            </Grid>
            <Box sx={{ width: '100%', my: '10px', ml: '0px' }}>
              <CustomTabs
                value={tabProp.tabPosition}
                onChange={handleChange}
                aria-label="secondary tabs example"
              >
                <CustomTab label="Group Permissions" />

                {location.state?.groupId && <CustomTab label="Add Employees" />}
              </CustomTabs>
              <TabPanel value={tabProp.tabPosition} index={0}>
                <Box sx={{ flexGrow: 0 }}>
                  {/* <SearchBox
                    labelId="addUserGroup.searchText"
                    defaultlabel="Search Screens"
                    backgroundColor="rgba(231, 240, 240, 1)"
                    onChange={handleSearchChange}
                  /> */}
                </Box>
                <Box
                  sx={{
                    width: '63%',
                    my: '20px',
                    ml: '0px',
                  }}
                >
                  <Paper
                    sx={{
                      width: '100%',
                      border: '1px solid rgba(207, 222, 222, 1)',
                      boxShadow: 'none',
                    }}
                  >
                    <div
                      ref={parentRef}
                      style={{
                        pointerEvents: defaultDisabled ? 'none' : 'auto',
                        opacity: defaultDisabled ? '0.6' : '1 ',
                      }}
                      onKeyDownCapture={(e) => {
                        if (defaultDisabled) {
                          e.preventDefault();
                          e.stopPropagation();
                        }
                      }}
                      onFocus={() => {
                        if (defaultDisabled) {
                          if (parentRef.current) parentRef.current.focus();
                        }
                      }}
                    >
                      {' '}
                      <TableContainer>
                        <Table
                          sx={{ maxWidth: '100%' }}
                          aria-labelledby="tableTitle"
                          size={'medium'}
                        >
                          <TableHeader
                            className="listDataTableHead"
                            labelSxProp={{
                              color: '#fff !important',
                              textWrap: 'nowrap',
                            }}
                            headerNames={headCells}
                            checkBoxRequired={false}
                          />
                          <TableBody className="tableRowcss">
                            {logs &&
                              logs.map((row: ScreenDTO, index: number) => {
                                return (
                                  <TableRow
                                    hover
                                    tabIndex={-1}
                                    sx={{
                                      alignContent: 'flex-start',
                                      '&:nth-of-type(odd)': {
                                        backgroundColor: '#ECF9F8',
                                      },
                                    }}
                                  >
                                    <CustomTableCell>
                                      <MediumTypography
                                        labelid={handleScreenName(
                                          row.screenBackendId,
                                        )}
                                        defaultlabel="Screen Name"
                                      />
                                    </CustomTableCell>
                                    <TableCell padding="checkbox">
                                      <CustomCheckBox
                                        // disable={
                                        //   !screenNameAccessToggle[index]
                                        //     .isViewEnabled
                                        // }
                                        disable={
                                          !(
                                            defaultDisabled &&
                                            permissions[index].viewPermission
                                          ) &&
                                          !screenNameAccessToggle[index]
                                            .isViewEnabled
                                        }
                                        value={
                                          permissions[index].viewPermission
                                        }
                                        onCheckBoxClick={() =>
                                          handlePermissionChange(
                                            row.screenBackendId,
                                            'viewPermission',
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                      <CustomCheckBox
                                        // disable={
                                        //   !screenNameAccessToggle[index]
                                        //     .isAddEnabled
                                        // }
                                        disable={
                                          !(
                                            defaultDisabled &&
                                            permissions[index].addPermission
                                          ) &&
                                          !screenNameAccessToggle[index]
                                            .isAddEnabled
                                        }
                                        value={permissions[index].addPermission}
                                        onCheckBoxClick={() =>
                                          handlePermissionChange(
                                            row.screenBackendId,
                                            'addPermission',
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                      <CustomCheckBox
                                        // disable={
                                        //   !screenNameAccessToggle[index]
                                        //     .isEditEnabled
                                        // }
                                        disable={
                                          !(
                                            defaultDisabled &&
                                            permissions[index].editPermission
                                          ) &&
                                          !screenNameAccessToggle[index]
                                            .isEditEnabled
                                        }
                                        value={
                                          permissions[index].editPermission
                                        }
                                        onCheckBoxClick={() =>
                                          handlePermissionChange(
                                            row.screenBackendId,
                                            'editPermission',
                                          )
                                        }
                                      />
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                      <CustomCheckBox
                                        // disable={
                                        //   !screenNameAccessToggle[index]
                                        //     .isDeleteEnabled
                                        // }
                                        disable={
                                          !(
                                            defaultDisabled &&
                                            permissions[index].deletePermission
                                          ) &&
                                          !screenNameAccessToggle[index]
                                            .isDeleteEnabled
                                        }
                                        value={
                                          permissions[index].deletePermission
                                        }
                                        onCheckBoxClick={() =>
                                          handlePermissionChange(
                                            row.screenBackendId,
                                            'deletePermission',
                                          )
                                        }
                                      />
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </Paper>
                </Box>
                {/* <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {paginationCount > 10 && (
                    <Grid item>
                      <Pagination
                        boundaryCount={1}
                        page={pageNumber + 1}
                        onChange={handleChangePage}
                        sx={{
                          '& .MuiPaginationItem-root': {
                            fontFamily: 'Lato-Regular',
                            fontWeight: 400,
                            fontSize: '16px',
                            margin: '16px 0px 20px 0px',
                          },
                          '& .MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: '#008C82',
                            color: '#ffffff',
                          },
                        }}
                        shape="circular"
                        count={Math.ceil(paginationCount / 10)}
                        siblingCount={1}
                      />
                    </Grid>
                  )}
                </div> */}
              </TabPanel>

              {/* second tab start */}

              <TabPanel value={tabProp.tabPosition} index={1}>
                <div className="flex__">
                  <div>
                    <Box sx={{ flexGrow: 0 }}>
                      <MediumTypography
                        labelid="userGroup.employeeList"
                        defaultlabel="Employees List"
                        fontSize="16px"
                        fontweight={700}
                        textColor="rgba(151, 166, 165, 1)"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '40%',
                        my: '20px',
                        ml: '0px',
                      }}
                    >
                      <Paper
                        sx={{
                          width: '350px',
                          border: '1px solid rgba(207, 222, 222, 1)',
                          boxShadow: 'none',
                          height: '389px',
                          overflowY: 'scroll',
                        }}
                      >
                        <Box sx={{ flexGrow: 0 }}>
                          <SearchBox
                            labelId="addUserGroup.employeeSearch"
                            defaultlabel="Search Employees"
                            backgroundColor="rgba(231, 240, 240, 1)"
                            sxProps={{
                              position: 'relative',
                              transform: 'translate(15px, 10px)',
                            }}
                            onChange={(e) => handleSearchInputChange(e)}
                          />
                        </Box>
                        <FormGroup sx={{ my: '20px', ml: '15px' }}>
                          {employees.map((option: GroupEmployees) => (
                            <FormControlLabel
                              key={option.userId}
                              control={
                                <Checkbox
                                  onChange={(e) => handleChanges(e, option)}
                                  sx={{ fontFamily: 'Lato-Regular' }}
                                />
                              }
                              value={option.name}
                              id="userCheckbox"
                              label={
                                <span
                                  style={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                  }}
                                >
                                  {option.groupName !== null &&
                                  option.groupName !== undefined
                                    ? `${option.name} - ${option.groupName}`
                                    : option.name}
                                </span>
                              }
                              labelPlacement="end"
                              checked={selectedCheckboxes.some(
                                (employee) => employee.name === option.name,
                              )}
                              sx={{ fontFamily: 'Lato-Regular' }}
                              disabled={
                                defaultDisabled &&
                                groupName.toLowerCase() === 'default'
                              }
                            />
                          ))}
                        </FormGroup>
                      </Paper>
                    </Box>
                    {(groupName.toLowerCase() !== 'default' ||
                      !defaultDisabled) && (
                      <Box>
                        <Grid
                          container
                          direction="row"
                          alignItems="right"
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Grid item sx={{ marginRight: '16px' }}>
                            <ButtonComponent
                              className="btn-primary btn-submit"
                              variantType="contained"
                              type="submit"
                              labelId="BlockTime.addButton"
                              defaultLabelId="Add"
                              disabled={btnDisable}
                              onClick={addEmployeeToGroup}
                            />
                          </Grid>
                          <Grid item>
                            <ButtonComponent
                              className="btn-primary btn-cancel"
                              variantType="contained"
                              defaultLabelId="Clear"
                              labelId="clear.btn"
                              onClick={clearAllEmployees}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </div>
                  <div style={{ marginLeft: '10%' }}>
                    <Box sx={{ flexGrow: 0, my: '10px' }}>
                      <MediumTypography
                        labelid="userGroup.addedemployeeList"
                        defaultlabel="Added Employees List"
                        fontSize="16px"
                        fontweight={700}
                        textColor="rgba(151, 166, 165, 1)"
                      />
                    </Box>
                    <Box
                      sx={{
                        width: '35%',
                        my: '20px',
                        ml: '0px',
                      }}
                    >
                      <Paper
                        sx={{
                          width: '350px',
                          border: '1px solid rgba(207, 222, 222, 1)',
                          boxShadow: 'none',
                        }}
                      >
                        <Box sx={{ flexGrow: 0 }}>
                          <SearchBox
                            labelId="addUserGroup.employeeSearch"
                            defaultlabel="Search Employees"
                            backgroundColor="rgba(231, 240, 240, 1)"
                            sxProps={{
                              position: 'relative',
                              transform: 'translate(15px, 10px)',
                            }}
                            onChange={(e) => handleSearhAddedEmpl(e)}
                          />
                        </Box>
                        <FormGroup sx={{ my: '20px', ml: '15px' }}>
                          {addedEmployees.map((option: GroupEmployees) => (
                            <FormControlLabel
                              key={option.userId}
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleRemovalChange(e, option)
                                  }
                                  sx={{ fontFamily: 'Lato-Regular' }}
                                />
                              }
                              value={option.name}
                              id="userCheckbox"
                              label={
                                <span
                                  style={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                  }}
                                >
                                  {option.name}
                                </span>
                              }
                              labelPlacement="end"
                              checked={selectRemoveArr.some(
                                (employee) => employee.name === option.name,
                              )}
                              sx={{ fontFamily: 'Lato-Regular' }}
                              disabled={
                                defaultDisabled &&
                                groupName.toLowerCase() === 'default'
                              }
                            />
                          ))}
                        </FormGroup>
                      </Paper>
                    </Box>
                    {(groupName.toLowerCase() !== 'default' ||
                      !defaultDisabled) && (
                      <Box>
                        <Grid
                          container
                          direction="row"
                          alignItems="right"
                          sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Grid item sx={{ marginRight: '16px' }}>
                            <ButtonComponent
                              className="btn-primary btn-submit"
                              variantType="contained"
                              type="submit"
                              labelId="addUser.remove"
                              defaultLabelId="Remove"
                              disabled={removebtnDisable}
                              onClick={removeEmployees}
                            />
                          </Grid>
                          <Grid item>
                            <ButtonComponent
                              className="btn-primary btn-cancel"
                              variantType="contained"
                              defaultLabelId="Clear"
                              labelId="clear.btn"
                              onClick={removeAllAddedEmployees}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </div>
                </div>
              </TabPanel>
            </Box>
          </Card>

          <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
            {(groupName.toLowerCase() !== 'default' || !defaultDisabled) && (
              <Grid
                container
                direction="row"
                alignItems="right"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item sx={{ marginRight: '16px' }}>
                  <ButtonComponent
                    className="btn-primary btn-cancel"
                    variantType="contained"
                    defaultLabelId="Cancel"
                    labelId="Contacts.cancelbtn"
                    onClick={() => navigate(USER_GROUPS)}
                  />
                </Grid>
                <Grid item>
                  {!location.state?.groupId && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="parentGuardian.save"
                      defaultLabelId="Save"
                      disabled={groupName === ''}
                      onClick={saveWithDebounce}
                    />
                  )}
                  {location.state?.groupId && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="parentGuardian.update"
                      defaultLabelId="Update"
                      disabled={groupName === ''}
                      onClick={updateWithDebounce}
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
};

export default AddUserGroup;
