import { Formik, FormikProps } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { FamilyContactType, OptionType } from '../../utils/type';
import * as yup from 'yup';
import { Box, Grid, AlertColor, Card } from '@mui/material';
import Textinput from '../../components/formlib/Textinput';
import SelectComponent from '../../components/formlib/SelectComponent';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';

import {
  ContactsTypes,
  addContacts,
  getContactsBaedOnType,
  updateContacts,
} from '../../services/configApi/User';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import SmallTypography from '../../components/formlib/SmallTypography';
import ModalPopup from '../../components/formlib/ModalPopup';
import { Gender, RelationshipFamily, maritalStatusTypes } from './../Dropdown';
import AddressForm from '../../components/formlib/AddressForm';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import _ from 'lodash';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import {
  formatPhoneNumber,
  nameValidationWithNumbers,
} from '../../utils/formValidationUtil';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { useIntl } from 'react-intl';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import {
  convertGenderToBackend,
  convertGenderToFrontend,
} from '../../utils/genderUtil';

const contactValues: FamilyContactType = {
  otherRelationshipName: '',
  contactType: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: '',
  relationship: '',
  contactTypeOthers: '',
  street: '',
  apartment: '',
  zipCode: { id: '', label: '' },
  city: '',
  state: '',
  birthPlace: '',
  maritalStatus: '',
  education: '',
  occupation: '',
  isFullTimeEmployee: null,
  startDate: dayjs(new Date()),
  endDate: null,
  isActive: true,
  homeNumber: '',
  dateOfBirth: null,
  sex: '',
  contactId: '',
  isEdit: false,
  links: [''],
  isPrimary: false,
  notes: '',
  isHouseHoldMember: false,
  fax: '',
};
const initialValidationSchema = yup.object().shape({
  contactType: yup.string(),
  firstName: yup.string().required('family.firstnamemessage'),
  lastName: yup.string().required('family.lastnameMessage'),
  phoneNumber: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  relationship: yup.string().required('family.relationshipMessage').nullable(),
  sex: yup.string().nullable(),
  contactTypeOthers: yup.string().when('relationship', {
    is: 'Other - specify',
    then: yup.string().required('family.otherRelationshipMessrerfage'),
    otherwise: yup.string().nullable(),
  }),
  birthPlace: yup.string().nullable(),
  email: yup.string().email('SignIn.validation.inValidEmail'),
  apartment: yup.string().nullable(),
  street: yup.string().required('addressLine1RequiredMessage'),
  city: yup.string().required('cityRequiredMessage'),
  state: yup.string().required('stateRequiredMessage'),
  zipCode: yup.object().shape({
    label: yup.string().required('zipCodeRequiredMessage'),
  }),
  maritalStatus: yup.string().nullable(),
  education: yup.string().nullable(),
  occupation: yup.string().nullable(),
  isFullTimeEmployee: yup.string().nullable(),
  startDate: yup.date().required('contact.startDateRequiredMessage').nullable(),
  endDate: yup.date().nullable(),
  isActive: yup.string().nullable(),
  homeNumber: yup
    .string()
    .min(12, 'PhoneNumber.Validation')
    .required('parent.primarynumberRequired'),
});

const validateForm = (value: FamilyContactType) => {
  const errors: Partial<FamilyContactType> = {};

  if (value.dateOfBirth) {
    if (dayjs(value.dateOfBirth).isAfter(dayjs())) {
      errors.dateOfBirth = 'validDate';
    }
  }
  if (value.startDate) {
    if (dayjs(value.startDate).isAfter(dayjs())) {
      errors.startDate = 'validDate';
    }
  }

  if (value.endDate) {
    if (dayjs(value.endDate).isBefore(dayjs(value.startDate))) {
      errors.endDate = 'effectiveEndDateValidationMessage';
    }
  }

  if (
    datePickerMinDateMaxDateValidate(value.dateOfBirth) ||
    datePickerMinDateMaxDateValidate(value.endDate)
  ) {
    errors.dateOfBirth = 'datePickerMinDateMaxDateValidate';
    errors.endDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

const FamilyContact = (props: {
  contacttype: string;
  onSuccess: (successerror: AlertColor, id: string, message: string) => void;
  familyValues: FamilyContactType;
  editable: boolean;
}) => {
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [familyVals, setFamilyVals] =
    React.useState<FamilyContactType>(contactValues);
  const [hideBtn, setHideBtn] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const rctl = useIntl();
  const formRef = React.useRef<FormikProps<FamilyContactType>>(null);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    const clientId: string = localStorage.getItem('ClientId') ?? '';

    if (!props.editable && props.familyValues.contactId !== '') {
      getContactsBaedOnType(clientId, props.familyValues.contactId)
        .then(async (response: ContactsTypes[]) => {
          const res = response[0] as FamilyContactType;
          setFamilyVals({
            firstName: res.firstName,
            lastName: res.lastName,
            phoneNumber: res.phoneNumber,
            email: res.email !== null ? res.email : '',
            relationship: res.relationship,
            contactTypeOthers: res.contactTypeOthers,
            street: res.street,
            apartment: res.apartment,
            zipCode: { id: 'aa', label: res.zipCode } as OptionType,
            city: res.city,
            state: res.state,
            birthPlace: res.birthPlace,
            dateOfBirth: res.dateOfBirth,
            sex:
              convertGenderToFrontend(
                res.sex && res.sex !== null ? res.sex : '',
              ) ?? '',
            maritalStatus: res.maritalStatus,
            education: res.education,
            occupation: res.occupation,
            isFullTimeEmployee: res.isFullTimeEmployee,
            startDate:
              res.startDate === null ? dayjs(new Date()) : res.startDate,
            endDate: res.endDate,
            isActive: res.isActive,
            homeNumber: res.homeNumber,
            contactId: props.familyValues.contactId,
            contactType: props.contacttype,
            isEdit: true,
            links: res.links,
            isPrimary: res.isPrimary,
            notes: res.notes,
            isHouseHoldMember: res.isHouseHoldMember,
            fax: res.fax,
            otherRelationshipName: res.otherRelationshipName,
          });
        })
        .catch((error) => {
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('Family.gettoastrError');
            setToastrDefaultMessage('Failed to get contact details');
          }
        });
      setHideBtn(true);
    }
  }, [props.familyValues, props.editable]);

  const addOrUpdateContact = useCallback(
    debounce((formVals: FamilyContactType) => {
      toggleLoader(true);
      formVals.endDate =
        formVals.endDate === null
          ? null
          : dayjs(formVals.endDate).format('MM/DD/YYYY');
      formVals.zipCode = formVals.zipCode
        ? (formVals.zipCode as OptionType).label
        : '';

      formVals.sex =
        formVals.sex?.length === 1
          ? formVals.sex
          : convertGenderToBackend(
              formVals.sex && formVals.sex !== null ? formVals.sex : '',
            ) ?? '';

      formVals.contactType = props.contacttype;
      formVals.startDate =
        formVals.startDate === null
          ? null
          : dayjs(formVals.startDate).format('MM/DD/YYYY');
      if (formVals.isEdit) {
        updateContacts(formVals.contactId, formVals)
          .then(async (response) => {
            if (response) {
              toggleLoader(false);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('Contact.updateMessage');
              setToastrDefaultMessage('Contact Updated Successfully');
              props.onSuccess(
                'success',
                'Contact.updateMessage',
                'Contact Updated Successfully',
              );
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              props.onSuccess(
                'error',
                'Contact.updateFailureMessage',
                'Failed to update contact details',
              );
              setToastrId('Contact.updateFailureMessage');
              setToastrDefaultMessage('Failed to update contact details');
            }
          });
      } else {
        const clientId: string = localStorage.getItem('ClientId') ?? '';

        addContacts(clientId, formVals)
          .then(async (response) => {
            if (response) {
              toggleLoader(false);
              setOpen(true);
              setToastrVariable('success');
              setToastrId('Contact.createdMessage');
              setToastrDefaultMessage(response.message);
              props.onSuccess(
                'success',
                'Contact.createdMessage',
                'Contact Added Successfully',
              );
            }
          })
          .catch(async (error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              props.onSuccess(
                'error',
                'Contact.updateCreateMessage',
                'Failed to create contact details',
              );
              setToastrId('Contact.updateCreateMessage');
              setToastrDefaultMessage('Failed to create contact details');
            }
          });
      }
    }, 1000),
    [],
  );
  const alphaNumericValidation = (value: string) => {
    let trimmedValue = value.replace(/^\s+/, '');
    const maxLength = 50;

    if (trimmedValue.length > maxLength) {
      trimmedValue = trimmedValue.slice(0, maxLength);
    }

    const regex = /^[A-Za-z.,'“!#_ -]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const clearForm = () => {
    setOpenModal(false);
    formRef.current?.resetForm();
    setFamilyVals(contactValues);
  };

  const NumValidation = (value: string) => {
    const cleanValue = value.replace(/\D/g, '');
    const formattedValue = cleanValue.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      (match, p1, p2, p3) => {
        let formatted = p1;
        if (p2) formatted += '-' + p2;
        if (p3) formatted += '-' + p3;
        return formatted;
      },
    );
    return formattedValue.slice(0, 12);
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />

      <Formik
        innerRef={formRef}
        initialValues={familyVals}
        validationSchema={initialValidationSchema}
        enableReinitialize={true}
        validateOnChange={true}
        validate={validateForm}
        onSubmit={(values) => {
          const finalValues = JSON.parse(JSON.stringify(values));
          if (finalValues.endDate) {
            const endDate = dayjs(finalValues.endDate, 'MM/DD/YYYY'); // Parse endDate
            const currentDate = dayjs(); // Current date

            if (endDate.isSame(currentDate, 'day')) {
              finalValues.isActive = true;
            } else if (endDate.isBefore(currentDate, 'day')) {
              finalValues.isActive = false;
            } else {
              finalValues.isActive = true; // Optional: For future dates
            }
          }
          addOrUpdateContact(finalValues);
        }}
      >
        {({
          setFieldValue,
          handleSubmit,
          errors,
          touched,
          values,
          initialValues,
        }) => (
          <>
            <Card
              sx={{
                padding: '10px 24px 24px 24px',
                my: '0px',
                ml: '0px',
                height: '100%',
                overflow: 'hidden',
                borderTopLeftRadius: '0px',
                borderTopRightRadius: '0px',
                boxShadow:
                  '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 4px 3px 0px rgba(0,0,0,0.12)',
              }}
            >
              <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`firstName`}
                    labelid="Family.firstName"
                    defaultlabelid="First Name"
                    handlechange={(value: string) => {
                      const inputValue = value;
                      const validatedValue = alphaNumericValidation(inputValue);
                      if (validatedValue !== undefined) {
                        setFieldValue('firstName', validatedValue);
                      }
                    }}
                    Value={values.firstName}
                    Required={true}
                    inputProps={{
                      maxLength: 50,
                    }}
                  />
                  {errors.firstName && touched.firstName && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.firstName}
                      defaultLabelId="First Name is required"
                    />
                  )}
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`lastName`}
                    labelid="Family.lastName"
                    defaultlabelid="Last Name"
                    handlechange={(value: string) => {
                      const inputValue = value;
                      const validatedValue = alphaNumericValidation(inputValue);
                      if (validatedValue !== undefined) {
                        setFieldValue('lastName', validatedValue);
                      }
                    }}
                    Value={values.lastName}
                    Required={true}
                    inputProps={{
                      maxLength: 50,
                      pattern: '[A-Za-z .,!]+',
                    }}
                  />
                  {errors.lastName && touched.lastName && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.lastName}
                      defaultLabelId="Last Name is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5}>
                  {values.dateOfBirth && (
                    <DatePickerComponent
                      name={`dateOfBirth`}
                      labelid="Family.dateOfBirth"
                      defaultlabelid="Date of Birth"
                      disableFuture={true}
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date === null) {
                          setFieldValue('dateOfBirth', null);
                        } else {
                          setFieldValue('dateOfBirth', formattedDate);
                        }
                      }}
                      value={
                        values.dateOfBirth ? dayjs(values.dateOfBirth) : null
                      }
                      disableManualInput
                      disabledDate={familyVals.dateOfBirth ? true : false}
                    />
                  )}
                  {!values.dateOfBirth && (
                    <DatePickerComponent
                      name={`dateOfBirth`}
                      labelid="Family.dateOfBirth"
                      defaultlabelid="Date of Birth"
                      disableFuture={true}
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date === null) {
                          setFieldValue('dateOfBirth', null);
                        } else {
                          setFieldValue('dateOfBirth', formattedDate);
                        }
                      }}
                      value={null}
                    />
                  )}
                  {errors.dateOfBirth && touched.dateOfBirth && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.dateOfBirth}
                      defaultLabelId="Please enter valid Date of Birth"
                    />
                  )}
                </Grid>
                <Grid item xs={4.5} sx={{ marginTop: '0.5px' }}>
                  <SelectComponent
                    labelid="Clientpage.Sex"
                    defaultlabelid="sex"
                    name="sex"
                    value={values.sex}
                    handleChange={(value: string) => {
                      setFieldValue('sex', value);
                    }}
                    names={Gender}
                    disabled={familyVals.sex ? true : false}
                  />
                  {errors.sex && touched.sex && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.sex}
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} sx={{ marginTop: '0.5px' }}>
                  <SelectComponent
                    Required={true}
                    name={`relationship`}
                    labelid="Family.relationship"
                    defaultlabelid="Guardian Type"
                    handleChange={(value: string) => {
                      setFieldValue(`relationship`, value);
                    }}
                    value={values.relationship}
                    names={RelationshipFamily}
                  />
                  {errors.relationship && touched.relationship && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.relationship}
                      defaultLabelId="RelationShip is required"
                    />
                  )}
                  {values.relationship === 'Other - specify' && (
                    <Box component={'div'}>
                      <Textinput
                        name={`others`}
                        labelid="Family.others"
                        defaultlabelid="Others Specify"
                        Value={values.contactTypeOthers}
                        handlechange={(value: string) => {
                          setFieldValue('contactTypeOthers', value);
                        }}
                        Required={true}
                        sxProps={{ marginTop: '24px' }}
                      />
                      {errors.contactTypeOthers &&
                        touched.contactTypeOthers && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.contactTypeOthers}
                            defaultLabelId="Please specify the relationship"
                          />
                        )}
                    </Box>
                  )}
                </Grid>

                {values.relationship === 'Other' && (
                  <Grid item xs={4.5} lg={4.5}>
                    <Textinput
                      name={`otherRelationshipName`}
                      labelid="family.otherRelationship"
                      defaultlabelid="Guardian Type"
                      handlechange={(value: string) => {
                        setFieldValue('otherRelationshipName', value);
                      }}
                      Required={false}
                      Value={values.otherRelationshipName}
                    />
                  </Grid>
                )}

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`home`}
                    labelid="Family.home"
                    defaultlabelid="Primary Phone Number"
                    handlechange={(value: string) => {
                      setFieldValue('homeNumber', NumValidation(value));
                    }}
                    Required={true}
                    inputProps={{
                      pattren: ['0-9'],
                    }}
                    Value={values.homeNumber}
                  />
                  {errors.homeNumber && touched.homeNumber && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId="parent.primarynumberRequired"
                      defaultLabelId="Primary Phone Number is required"
                    />
                  )}
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`phoneNumber`}
                    labelid="Family.phoneNumber"
                    defaultlabelid="Mobile Number"
                    handlechange={(value: string) => {
                      setFieldValue('phoneNumber', NumValidation(value));
                    }}
                    inputProps={{
                      pattren: ['0-9'],
                    }}
                    Value={values.phoneNumber}
                  />
                  {errors.phoneNumber && touched.phoneNumber && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId="PhoneNumber.Validation"
                      defaultLabelId="must have a minimum of 10 digits"
                    />
                  )}
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`email`}
                    labelid="Family.email"
                    defaultlabelid="Email Address"
                    handlechange={(value: string) => {
                      setFieldValue('email', value);
                    }}
                    inputProps={{
                      maxLength: 320,
                    }}
                    Value={values.email}
                  />
                  {errors.email && touched.email && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.email}
                      defaultLabelId="Invalid email"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="referralContactFax"
                    Value={values.fax}
                    labelid="AddNewReferralDetails.Fax"
                    defaultlabelid="Fax"
                    Required={false}
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('fax', formatPhoneNumber(value));
                    }}
                  />
                </Grid>
                <Grid item xs={4.5} sx={{ marginTop: '0%' }}>
                  <SelectComponent
                    labelid="Family.maritalStatus"
                    defaultlabelid="Marital Status"
                    name={`maritalStatus`}
                    handleChange={(value: string) => {
                      setFieldValue(`maritalStatus`, value);
                    }}
                    value={values.maritalStatus}
                    names={maritalStatusTypes}
                  />
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`education`}
                    labelid="Family.education"
                    defaultlabelid="Education"
                    handlechange={(value: string) => {
                      const inputValue = value;
                      setFieldValue('education', inputValue);
                    }}
                    Value={values.education}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`occupation`}
                    labelid="Family.occupation"
                    defaultlabelid="Occupation"
                    handlechange={(value: string) => {
                      const inputValue = value;
                      setFieldValue('occupation', inputValue);
                    }}
                    Value={values.occupation}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>
                <Grid item xs={4.5}>
                  {' '}
                  <Textinput
                    name={`birthPlace`}
                    labelid="Family.birthPlace"
                    defaultlabelid="Enter Birthplace"
                    handlechange={(value: string) => {
                      const inputValue = value;
                      setFieldValue('birthPlace', inputValue);
                    }}
                    Value={values.birthPlace}
                    inputProps={{
                      maxLength: 100,
                    }}
                  />
                </Grid>

                <Grid item xs={9}>
                  <AddressForm
                    address1PlaceholderId="address1Text"
                    address2PlaceholderId="address2Text"
                    zipCodePlaceholderId="Clientpage.Zip"
                    cityPlaceholderId="Clientpage.City"
                    statePlaceholderId="Clientpage.State"
                    disableForm={false}
                    formRequired={true}
                    value={{
                      address1: values.street,
                      address2: values.apartment,
                      zipCode: values.zipCode,
                      city: values.city,
                      state: values.state,
                    }}
                    isClient={false}
                    handleAddressUpdate={(newAddress) => {
                      setFieldValue('street', newAddress.address1);
                      setFieldValue('apartment', newAddress.address2);
                      setFieldValue('zipCode', newAddress.zipCode);
                      setFieldValue('city', newAddress.city);
                      setFieldValue('state', newAddress.state);
                      formRef.current?.setValues({
                        ...values,
                        street: newAddress.address1,
                        apartment: newAddress.address2,
                        zipCode: newAddress.zipCode,
                        city: newAddress.city,
                        state: newAddress.state,
                      });
                    }}
                    formikErrors={{
                      address1: errors.street,
                      address2: errors.apartment,
                      zipCode: errors.zipCode,
                      city: errors.city,
                      state: errors.state,
                    }}
                    formikTouched={{
                      address1: touched.street,
                      address2: touched.apartment,
                      zipCode: touched.zipCode,
                      city: touched.city,
                      state: touched.state,
                    }}
                  />
                </Grid>

                <Grid xs={4.5} item>
                  <DatePickerComponent
                    name={`startDate`}
                    labelid="Family.startDate"
                    defaultlabelid="Start Date"
                    disableFuture={true}
                    handlechange={(date: Dayjs | null) => {
                      const formattedDate = dayjs(date).format('MM/DD/YYYY');
                      if (date === null) {
                        setFieldValue('startDate', null);
                      } else {
                        formRef.current?.setValues({
                          ...values,
                          startDate: formattedDate,
                          endDate: null,
                        });
                        // setFieldValue('startDate', formattedDate);
                        // setFieldValue('endDate', null);
                      }
                    }}
                    value={dayjs(values.startDate)}
                  />
                  {errors.startDate && touched.startDate && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.startDate}
                      defaultLabelId="Please enter valid Start Date"
                    />
                  )}
                </Grid>
                <Grid xs={4.5} item>
                  {values.endDate && (
                    <DatePickerComponent
                      name={`endDate`}
                      labelid="Family.endDate"
                      defaultlabelid="End Date"
                      minDate={
                        values.startDate !== null
                          ? dayjs(values.startDate).add(1, 'day')
                          : undefined
                      }
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date === null) {
                          setFieldValue('endDate', null);
                        } else {
                          setFieldValue('endDate', formattedDate);
                        }
                      }}
                      value={dayjs(values.endDate)}
                    />
                  )}
                  {!values.endDate && (
                    <DatePickerComponent
                      name={`endDate`}
                      labelid="Family.endDate"
                      defaultlabelid="End Date"
                      minDate={
                        values.startDate !== null
                          ? dayjs(values.startDate).add(1, 'day')
                          : undefined
                      }
                      handlechange={(date: Dayjs | null) => {
                        const formattedDate = dayjs(date).format('MM/DD/YYYY');
                        if (date === null) {
                          setFieldValue('endDate', null);
                        } else {
                          setFieldValue('endDate', formattedDate);
                        }
                      }}
                      value={null}
                    />
                  )}
                  {errors.endDate && touched.endDate && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.endDate}
                      defaultLabelId="Please enter valid End Date"
                    />
                  )}
                </Grid>
                <Grid
                  xs={4.5}
                  item
                  sx={{ marginTop: '-1%', marginBottom: '-1%' }}
                >
                  <CheckBoxComponent
                    name="isHouseHoldMember"
                    labelid="Contacts.HouseHold"
                    defaultlabelid="Household Member"
                    fontWeight={400}
                    fontFamily="Lato-Regular"
                    ischecked={values.isHouseHoldMember}
                    CheckHandleChange={(e) => {
                      setFieldValue('isHouseHoldMember', e.target.checked);
                    }}
                  />
                </Grid>
                <Grid xs={4.5} item></Grid>
                <Grid item xs={9} lg={9}>
                  <CommentTextbox
                    name="notes"
                    placeholder={rctl.formatMessage({
                      id: 'AddNewReferralDetails.notes',
                      defaultMessage: 'Notes',
                    })}
                    Value={values.notes}
                    maxLength={2000}
                    handlechange={(value: string) => {
                      const validatedValue = nameValidationWithNumbers(value);
                      if (validatedValue !== undefined) {
                        setFieldValue('notes', validatedValue);
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
            <Box sx={{ marginTop: '20px' }}>
              <Grid
                container
                direction="row"
                alignItems="right"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item sx={{ marginRight: '16px' }}>
                  <ButtonComponent
                    className="btn-primary btn-cancel"
                    variantType="contained"
                    labelId="Contacts.cancelbtn"
                    defaultLabelId="Cancel"
                    onClick={() => {
                      if (initialValues.contactId !== '') {
                        if (_.isEqual(initialValues, values)) {
                          formRef.current?.resetForm();
                          props.onSuccess('info', '', '');
                        } else {
                          setOpenModal(true);
                        }
                      } else {
                        if (!_.isEqual(initialValues, values)) {
                          setOpenModal(true);
                        } else {
                          setOpenModal(false);
                          formRef.current?.resetForm();
                          setFamilyVals(contactValues);
                          props.onSuccess('info', '', '');
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  {!hideBtn && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="Contacts.save"
                      defaultLabelId="Add Contact"
                      onClick={handleSubmit}
                    />
                  )}
                  {hideBtn && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="Contacts.update"
                      defaultLabelId="Update"
                      onClick={handleSubmit}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Formik>
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          if (familyVals.contactId !== '') {
            setOpenModal(false);
            formRef.current?.setValues(formRef.current?.initialValues);
          } else {
            clearForm();
          }
        }}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
    </>
  );
};

export default FamilyContact;
