import { Dayjs } from 'dayjs';
import { MasterProviderType, OptionType } from '../../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export type OrganizationListType = {
  id?: string;
  organizationName: string;
  treatmentType: string;
  treatmentName: string;
  areaServed: string;
  address1: string;
  address2: string;
  city: string;
  zipCode: string | OptionType;
  state: string;
  startDate: null | string | Dayjs;
  endDate: Date | null | string;
  isActive: boolean;
};

export type SspDetailsListType = {
  id?: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  type: string;
  email: string;
  phone: string;
  comments: string;
  startDate: Dayjs | null | string;
  endDate: Date | null | string;
  isContactActive: boolean;
  organizationId: string;
};

export type SspContactListDto = {
  sspOrganizationDtoList: OrganizationListType[];
  count: number;
};
export type SspContactProviderListDto = {
  sspProviderDtoList: SspDetailsListType[];
  count: number;
};
export const getAllOrganizationListAPI = async (
  page: number,
  size: number,
  sort: string,
  sortType: string,
  searchquery: string,
): Promise<SspContactListDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<SspContactListDto>(
      `organization/all?page=${page}&size=${size}&sort=${sort}&sortType=${sortType}&search=${searchquery}`,
    ),
  );
};

export const addOrganizationApi = async (
  requestBody: OrganizationListType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<OrganizationListType, ApiMessage>(
      `organization`,
      requestBody,
    ),
  );
};

export const editOrganizationApi = async (
  id: string,
  requestBody: OrganizationListType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<OrganizationListType, ApiMessage>(
      `organization?id=${id}`,
      requestBody,
    ),
  );
};

export const deleteOrgById = async (id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `organization?id=${id}`,
    ),
  );
};

export const getAllProviderListAPI = async (
  page: number,
  size: number,
  sort: string,
  sortType: string,
  searchquery: string,
  organizationId: string,
): Promise<SspContactProviderListDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<SspContactProviderListDto>(
      `provider/all?organizationId=${organizationId}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType}&search=${searchquery}`,
    ),
  );
};

export const addProviderApi = async (
  requestBody: SspDetailsListType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<SspDetailsListType, ApiMessage>(
      `provider`,
      requestBody,
    ),
  );
};

export const editProviderApi = async (
  id: string,
  requestBody: SspDetailsListType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<SspDetailsListType, ApiMessage>(
      `provider?id=${id}`,
      requestBody,
    ),
  );
};

export const deleteProviderById = async (id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(`provider?id=${id}`),
  );
};

export const GetMasterSSPProviderList = async (
  page: number,

  size: number,
  sortName: string,
  sortType: string,
  search: string,


): Promise<MasterProviderType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<MasterProviderType>(
      `provider/organization?page=${page}&size=${size}&sort=${sortType}&sortType=${sortName}&search=${search}`,
    ),
  );
};
