import { Box } from '@mui/system';
import IntakeTabNavigationComponent from './IntakeTabNavigationComponent';

const IntakeLandingPage = () => {
  return (
    <Box component="main">
      <Box component="section">
        <Box>
          <IntakeTabNavigationComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default IntakeLandingPage;
