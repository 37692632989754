import { EmailData, EmailGetList } from "../../../utils/type";
import { ApiMessage, ApiResponseHandler } from "../../ApiResponseHandler";
import ThomApiClient from "../../ThomApiClient";



export const getEmailTemplate = async (
  emailType: string,
): Promise<EmailGetList> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `email/template?templateType=${emailType}&emailType=email`,
    ),
  );
};

export const emailTemplateSubmit = async (
  params: EmailData,
  id: string | null,
): Promise<ApiMessage> => {
  const url = `email/template?employeeId=${id}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(url, params),
  );
};