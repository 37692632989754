import { Card, Modal } from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';

interface ModalFieldProps {
  info: string;
  open: boolean;
  titleLabel: string;
  titleDefaultLabel: string;
  buttonLabel: string;
  buttonDefaultLabel: string;
  onClose: (openFromView: boolean) => void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '650px',
  height: 'auto',
  bgcolor: 'background.paper',
  padding: '10px 20px 20px 20px',
  maxHeight: '450px',
  borderRadius: '6px',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
    background: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
  },
  outline: 'none',
  fontFamily: 'Lato-Regular',
};
const InformationModal: FC<ModalFieldProps> = ({
  open,
  onClose,
  info,
  titleLabel,
  titleDefaultLabel,
  buttonLabel,
  buttonDefaultLabel,
}) => {
  return (
    <Box component="main">
      <Modal open={open}>
        <Card sx={style} className="p-lg">
          <MediumTypography
            labelid={titleLabel}
            defaultlabel={titleDefaultLabel}
            className="mb-lg"
            fontSize="18px"
            fontweight={700}
            sxProps={{
              textAlign: 'center',
            }}
          />
          <MediumTypography
            label={info}
            sxProps={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
          />
          <Box
            component="div"
            className="flex__ mt-lg"
            justifyContent={'flex-end'}
          >
            <ButtonComponent
              className="btn-primary btn-submit "
              labelId={buttonLabel}
              defaultLabelId={buttonDefaultLabel}
              onClick={() => {
                onClose(open);
              }}
            />
          </Box>
        </Card>
      </Modal>
    </Box>
  );
};

export default InformationModal;
