import { StatusType } from '../pages/activitySheet/ActivityTableRow';
import { GroupPermissions } from '../services/configApi/userGroups/userGroupServices';
import { UAT_HOST_NAME } from '../services/Constant';

// Get Sunday of current week in ISO format and return it
export const getCurrentWeekSundayInISO = (today: Date) => {
  const firstDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay(),
  );
  return firstDay.toISOString();
};

export const getCurrentWeekSundayAsDate = () => {
  const today = new Date();
  const firstDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay(),
  );

  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  return firstDay;
};

export const getWeekSundayAsDate = (today: Date) => {
  const firstDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - today.getDay(),
  );

  firstDay.setHours(0);
  firstDay.setMinutes(0);
  firstDay.setSeconds(0);
  return firstDay;
};

export const getCurrentMonthDateTimeStart = () => {
  const today = new Date();
  today.setDate(1);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  return today;
};
export const getMonthDateTimeStart = (today: Date) => {
  today.setDate(1);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  return today;
};

//get date time start of day in ISO format based on the date passed in MM/DD/YYYY
export const getISODateTimeByDate = (date: string) => {
  const today = new Date(date);
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  return today.toISOString();
};
export const getISODateTimeEODByDate = (date: string) => {
  const today = new Date(date);
  today.setHours(23);
  today.setMinutes(59);
  today.setSeconds(59);
  return today.toISOString();
};

// get date in ISO format based on the day of week passed as number 0-6 where 0 is Sunday and time being 00:00:00 of current timezone
export const getISODateByDayOfWeek = (
  dayOfWeek: number,
  isoDate: string,
  sunday: string,
) => {
  const today = new Date(sunday);
  const firstDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + dayOfWeek,
  );
  // convert ISO format to date isoDate to local date
  const ourDate = new Date(isoDate);
  firstDay.setHours(ourDate.getHours());
  firstDay.setMinutes(ourDate.getMinutes());
  firstDay.setSeconds(ourDate.getSeconds());
  return firstDay.toISOString();
};

// get date time of end of the day in ISO format based on the day of week passed as number 0-6
export const getISODateTimeEODByDayOfWeek = (
  dayOfWeek: number,
  isoDate: string,
  sunday: string,
) => {
  const today = new Date(sunday);
  const firstDay = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() + dayOfWeek,
  );
  const ourDate = new Date(isoDate);
  firstDay.setHours(ourDate.getHours());
  firstDay.setMinutes(ourDate.getMinutes());
  firstDay.setSeconds(ourDate.getSeconds());
  return firstDay.toISOString();
};

export const getStatusColor = (statusType: StatusType | null) => {
  switch (statusType) {
    case 'Submitted':
      return '#017AFF';
    case 'Not Submitted':
      return '#97A6A5';
    case 'Approved':
      return '#37D183';
    case 'Pending':
      return '#FFA500';
    case 'In Progress':
      return '#FFA500';
    case 'Rejected':
      return '#EB4C60';
    case 'Completed':
    case 'Active':
      return '#37D183';
    case 'Canceled':
      return '#EB4C60';
    case 'Inactive':
      return 'red';
    default:
      return 'transparent';
  }
};

export const getEmployeeDashboardColor = (status: string) => {
  switch (status) {
    case 'backend.task_status.open':
      return '#017AFF';
    case 'backend.task_status.due':
      return '#F96300';
    case 'backend.task_status.completed':
      return '#37D183';
    case 'backend.task_status.overdue':
      return '#EB4C60';
    case 'backend.task_status.warning':
      return '#F9B803';
    case 'backend.task_status.pending':
      return '#F9B803';
    default:
      return 'transparent';
  }
};

export const isSuperSuperAdmin = () => {
  const selectedProgram = localStorage.getItem('selectedProgram');
  let permissions: GroupPermissions = JSON.parse('[]');
  if (selectedProgram && selectedProgram !== null) {
    const selectedPermission = localStorage.getItem(
      JSON.parse(selectedProgram).id,
    );
    permissions = JSON.parse(selectedPermission ?? '[]');
  }

  if (permissions.superSuperAdmin) {
    return true;
  } else {
    return false;
  }
};

export const getSundayOfWeek = (date: Date): Date => {
  const day = date.getDay();
  const sunday = new Date(date);
  sunday.setDate(date.getDate() - day);
  return sunday;
};

export const getSaturdayOfWeek = (date: Date): Date => {
  const day = date.getDay();
  const difference = 7 - day;
  const saturday = new Date(date);
  saturday.setDate(date.getDate() + difference);
  return saturday;
};

export const isPointingToUAT = () => {
  return UAT_HOST_NAME.includes(window.location.hostname);
};

export const getPhoneNumber = (
  cellPhone: string | null,
  homePhone: string | null,
) => {
  if (cellPhone) {
    return cellPhone;
  } else if (homePhone) {
    return homePhone;
  } else {
    return '-';
  }
};
export const clearClientStorageDetails = () => {
  localStorage.removeItem('referralId');
  localStorage.removeItem('clientReferralDetails');
  localStorage.removeItem('ClientId');
  localStorage.removeItem('defaultReferralId');
  localStorage.removeItem('clientName');
  localStorage.removeItem('Demographics');
  localStorage.removeItem('consentDataJSON');
  localStorage.removeItem('selectedVersionNo');
};
