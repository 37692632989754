import { RiskFactorType } from "../../../utils/type";
import { ApiMessage, ApiResponseHandler } from "../../ApiResponseHandler";
import ThomApiClient from "../../ThomApiClient";

export const getRiskFactorData = async (
    evaulationId: string,
    clientId: string | null,
    evaluationType: string
    ): Promise<RiskFactorType> => {
      return ApiResponseHandler(
        await ThomApiClient.getInstance().get(
          `riskFactor?evaluationId=${evaulationId}&clientId=${clientId}&evaluationType=${evaluationType}`,
        ),
      );
    };


    export const addOrUpdateRiskFactor = async (
        params: RiskFactorType,
        clientId: string | null
      ): Promise<ApiMessage> => {
        const url = `riskFactor?clientId=${clientId}`;
        return ApiResponseHandler(
          await ThomApiClient.getInstance().put(url, params),
        );
      };
    