export const extractFileType = (fileType: string): string => {
  const typeArray = fileType.split('/');
  if (typeArray.length > 1) {
    if (typeArray[0] === 'application') {
      return fileType.includes('pdf') ? 'pdf' : 'document';
    } else if (typeArray[0] === 'audio') {
      return 'audio';
    } else if (typeArray[0] === 'image') {
      return 'photo';
    } else if (typeArray[0] === 'video') {
      return 'video';
    }
  }
  return 'other';
};

export const getFileNameFromURL = (url: string) => {
  return url.split('/').pop();
};

export const extractFileExtension = (fileUrl: string): string => {
  const newArray = fileUrl.split('?');
  return newArray[0].substring(newArray[0].lastIndexOf('.'));
};

export const dataURItoFile = (dataURI: string | null | undefined) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  if (dataURI === null || dataURI === undefined) {
    return null;
  } else {
    let byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[1]);
    else byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ia], { type: mimeString });
    const milliseconds = new Date().getUTCMilliseconds();
    return new File([blob], milliseconds + '.jpg', { type: 'image/jpeg' });
  }
};

export const convertFileToBase64 = (file: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };
    reader.readAsDataURL(file);
  });
};
