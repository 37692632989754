import { FC, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FormattedMessage } from 'react-intl';
import './Textinput.css';
import { Dayjs } from 'dayjs';

interface DateTimePickerComponentProps {
  labelid: string;
  defaultlabelid: string;
  disabledDate?: boolean;
  value: Dayjs | null;
  handlechange?: (value: Dayjs) => void;
  disableFuture?: boolean;
  inputFormat?: string;
  handleClose?: () => void;
  minDateTime?: Dayjs;
  minuteStep?: number;
}
const DateTimePickerComponent: FC<DateTimePickerComponentProps> = (props) => {
  const {
    labelid,
    defaultlabelid,
    disabledDate,
    minDateTime,
    value,
    handlechange,
    handleClose,
    disableFuture,
    inputFormat,
    minuteStep,
  } = props;
  const internalHandleDateChange = (date: Dayjs | null) => {
    if (handlechange && date != null) {
      handlechange(date);
    }
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const closeDatePicker = () => {
    setOpen(false);
    if (handleClose) {
      handleClose();
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        slotProps={{
          field: {
            clearable: true,
            readOnly: disabledDate,
          },
          textField: {
            disabled: true,
          },
        }}
        selectedSections={null}
        sx={{
          width: '100%',
          color: '#97a6a5',
          fontSize: '14px',
          fontFamily: 'Lato-Regular',
        }}
        className={`dateTimePickerStyle datepicker ${
          disabledDate ? 'disabledTextColor' : 'notDisabledTextColor'
        }`}
        label={
          <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
        }
        disabled={disabledDate}
        open={open}
        minDateTime={minDateTime}
        onOpen={handleOpen}
        onClose={closeDatePicker}
        value={value}
        ampm={true}
        onChange={internalHandleDateChange}
        disableFuture={disableFuture}
        format={inputFormat ?? 'MM/DD/YYYY hh:mm a'}
        minutesStep={minuteStep}
        views={['year', 'month', 'day', 'hours', 'minutes']}
      />
    </LocalizationProvider>
  );
};
export default DateTimePickerComponent;
