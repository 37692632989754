import { AlertColor, Box, Card, Grid, SxProps, Tooltip } from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import TitleText from '../../components/formlib/TitleText';
import { Formik, FormikProps } from 'formik';
import MediumTypography from '../../components/formlib/MediumTypography';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useLocation, useNavigate } from 'react-router';
import ProgramPanel from '../../components/forms/ProgramPanel';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { BATELLE_DEVELOPMENT_INVENTORY_2 } from '../../routes/Routing';
import { RiskFactorType } from '../../utils/type';
import Textinput from '../../components/formlib/Textinput';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import {
  addOrUpdateRiskFactor,
  getRiskFactorData,
} from '../../services/configApi/riskFactor/riskFactor';
import ModalPopup from '../../components/formlib/ModalPopup';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { debounce } from 'lodash';
import { EICSRadioData } from '../intake/IntakeClientsInfo';

const initialValues: RiskFactorType = {
  parent: {
    name: '',
    contactType: '',
    relationship: '',
    phone: '',
    email: '',
    address: '',
  },
  client: {
    name: '',
    sex: '',
    dphId: '',
    dateOfBirth: '',
    referralBeginDate: '',
    dateOfEvaluation: '',
    intakeFacilitator: '',
    serviceCoordinator: {
      id: '',
      name: '',
    },
  },
  childCharacteristics: {
    gestationalAge: '',
    childInNICU: '',
    daysInNICU: '',
    birthWeight: '',
    weightInLbs: '',
    weightInOz: '',
    weightInGrams: '',
    childHospitalized: '',
    childDiagnosedAtBirth: '',
    smallSize: '',
    childChronicFeeding: '',
    childBloodLevel: '',
    childInsecureDifficulties: '',
    childNervousSystem: '',
    childMultipleTrauma: '',
    childDiagnosis: '',
  },
  familyCharacteristics: {
    birthMotherParity: '',
    mothersEducationYear: '',
    parentalChronicIllness: '',
    socialSupport: '',
    lackingAdequate: '',
    protectiveServiceInvestigation: '',
    affectingChild: '',
    abuseAffectingChild: '',
    homelessness: '',
    homelessnessInPast: '',
    siblingWithSEN: '',
  },
};

const RiskFactor = () => {
  const location = useLocation();
  const [evaluationId] = useState<string>(location.state?.evaluationId);
  const [evaluationType] = useState<string>(location.state?.evaluationType);
  const [uploadedToTBR] = useState<boolean>(location.state.isUploadedToTBR);
  const formikRef = useRef<FormikProps<RiskFactorType>>(null);
  const [riskfactorData, setRiskFactorData] = useState<RiskFactorType | null>(
    null,
  );
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [signaturePartitionKey, setSignaturePartitionKey] =
    useState<string>('');
  const [gestationalAgeUnknown, setGestationalAgeUnknown] = useState(false);
  const [NICUDaysUnknown, setNICUDaysUnknown] = useState(false);
  const [birthWeightPoundsUnknown, setBirthWeightPoundsUnknown] =
    useState(false);
  const [birthWeightOuncesUnknown, setBirthWeightOuncesUnknown] =
    useState(false);
  const [gramsUnknown, setGramsUnknown] = useState(false);
  const [userModified, setUserModified] = useState(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [
    birthMotherEducationYearsUnknown,
    setBirthMotherEducationYearsUnknown,
  ] = useState(false);
  const [riskFactorInitialValues] = useState<RiskFactorType>(initialValues);
  const navigate = useNavigate();
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const clientID = localStorage.getItem('ClientId');

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setSignaturePartitionKey(signaturePartitionKey);
  }, [signaturePartitionKey]);

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  useEffect(() => {
    toggleLoader(true);
    riskFactorGetFunction();
  }, []);

  const riskFactorGetFunction = () => {
    getRiskFactorData(
      evaluationId,
      localStorage.getItem('ClientId'),
      evaluationType,
    )
      .then((data) => {
        toggleLoader(false);
        setRiskFactorData(data);
        if (formikRef.current) {
          formikRef.current.setValues({
            ...data,
          });
        }
        setGestationalAgeUnknown(
          data.childCharacteristics.gestationalAge == 'Unknown',
        );
        setNICUDaysUnknown(data.childCharacteristics.daysInNICU == 'Unknown');
        setBirthMotherEducationYearsUnknown(
          data.familyCharacteristics.mothersEducationYear == 'Unknown',
        );
        setBirthWeightPoundsUnknown(
          data.childCharacteristics.weightInLbs == 'Unknown',
        );
        setBirthWeightOuncesUnknown(
          data.childCharacteristics.weightInOz == 'Unknown',
        );
        setGramsUnknown(data.childCharacteristics.weightInGrams == 'Unknown');
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenSnackBar(true);
        setToastrVariable('error');
        setToastrId('failed');
        setToastrDefaultMessage(error);
      });
  };

  const allFieldsEnteredCheck = (values: RiskFactorType) => {
    if (
      values.childCharacteristics.gestationalAge === '' ||
      values.childCharacteristics.childInNICU === '' ||
      values.childCharacteristics.daysInNICU === '' ||
      values.childCharacteristics.childHospitalized === '' ||
      values.childCharacteristics.childDiagnosedAtBirth === '' ||
      values.childCharacteristics.smallSize === '' ||
      values.childCharacteristics.childChronicFeeding === '' ||
      values.childCharacteristics.childBloodLevel === '' ||
      values.childCharacteristics.childInsecureDifficulties === '' ||
      values.childCharacteristics.childNervousSystem === '' ||
      values.childCharacteristics.childMultipleTrauma === '' ||
      values.childCharacteristics.childDiagnosis === '' ||
      values.familyCharacteristics.siblingWithSEN === '' ||
      values.familyCharacteristics.birthMotherParity === '' ||
      values.familyCharacteristics.mothersEducationYear === '' ||
      values.familyCharacteristics.parentalChronicIllness === '' ||
      values.familyCharacteristics.socialSupport === '' ||
      values.familyCharacteristics.lackingAdequate === '' ||
      values.familyCharacteristics.protectiveServiceInvestigation === '' ||
      values.familyCharacteristics.affectingChild === '' ||
      values.familyCharacteristics.abuseAffectingChild === '' ||
      values.familyCharacteristics.homelessness === '' ||
      values.familyCharacteristics.homelessnessInPast === '' ||
      values.childCharacteristics.birthWeight === '' ||
      (values.childCharacteristics.birthWeight === 'Pounds' &&
        (values.childCharacteristics.weightInLbs === '' ||
          values.childCharacteristics.weightInOz === '')) ||
      (values.childCharacteristics.birthWeight === 'Grams' &&
        values.childCharacteristics.weightInGrams === '')
    ) {
      return false;
    }
    return true;
  };

  const ellipsisCellSX: SxProps = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
    zIndex: 9999,
  };

  const saveDebounce = useCallback(
    debounce((riskFactorData) => {
      addOrUpdateRiskFactor(riskFactorData, clientID)
        .then(async (response) => {
          toggleLoader(false);
          riskFactorGetFunction();
          setOpenSnackBar(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
        })
        .catch(async (error) => {
          toggleLoader(false);
          setOpenSnackBar(true);
          setToastrId(error.message);
          setToastrDefaultMessage(error.message);
          setToastrVariable('error');
        });
    }, 500),
    [],
  );

  const handleSaveRiskFactor = (values: RiskFactorType) => {
    const riskFactorData = {
      id: riskfactorData?.id ? riskfactorData?.id : '',
      evaluationId: evaluationId,
      evaluationType: evaluationType,
      client: values.client,
      parent: values.parent,
      childCharacteristics: values.childCharacteristics,
      familyCharacteristics: values.familyCharacteristics,
      completed: allFieldsEnteredCheck(values),
    };
    toggleLoader(true);
    saveDebounce(riskFactorData);
  };

  return (
    <Box component="main">
      <div
        ref={parentRef}
        style={{
          pointerEvents: isScreenLocked ? 'none' : 'auto',
          opacity: isScreenLocked ? '0.5' : '1 ',
        }}
        onKeyDownCapture={(e) => {
          if (isScreenLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (isScreenLocked) {
            if (parentRef.current) parentRef.current.focus();
          }
        }}
      >
        <SnackBarComponent
          open={openSnackBar}
          handleClose={handleCloseSnakBar}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box component="section">
          <Box component="div" className="rowContainer">
            <CustomBackArrow
              onClick={() => navigate(BATELLE_DEVELOPMENT_INVENTORY_2)}
            />
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Box className="flex__  align__items__center">
                  <TitleText
                    labelid="RiskFactor.TitleText"
                    defaultlabel="Risk Factor Evaluation"
                    Sxprops={{ ml: 1 }}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                lg={6}
                className="flex__ align__items__center justifyContent-FlexEnd"
              ></Grid>
            </Grid>
          </Box>
          <Box component="div">
            <Formik
              initialValues={riskFactorInitialValues}
              validateOnMount={true}
              innerRef={formikRef}
              validateOnChange={true}
              enableReinitialize
              validationSchema={''}
              onSubmit={(values) => {
                handleSaveRiskFactor(values);
              }}
            >
              {({ values, setFieldValue }) => (
                <>
                  <Card className="formCardview">
                    {riskfactorData && (
                      <ProgramPanel
                        program={riskfactorData.program}
                        client={{
                          id: 0,
                          clientId: '',
                          name: riskfactorData.client.name,
                          firstName: '',
                          lastName: '',
                          sex: riskfactorData.client.sex,
                          dob: riskfactorData.client.dateOfBirth,
                          dphId: riskfactorData.client.dphId,
                          referralBeginDate:
                            riskfactorData.client.referralBeginDate,
                          dateOfEvaluation:
                            riskfactorData.client.dateOfEvaluation,
                          serviceCoordinatorId: '',
                          intakeFacilitatorId: '',
                          serviceCoordinatorName:
                            riskfactorData.client.serviceCoordinator.name,
                          serviceCoordinatorPhone: '',
                          serviceCoordinatorPhoneExtension: '',
                          primarySpokenLanguage: '',
                          primaryWrittenLanguage: '',
                          race: '',
                          ethnicity: '',
                          pediatricianName: '',
                          pediatricianPhoneNumber: '',
                        }}
                        parent={{
                          contactId: '',
                          name: riskfactorData.parent.name,
                          homePhoneNumber: riskfactorData.parent.phone,
                          isPrimary: false,
                          type: '',
                          relationshipToClient:
                            riskfactorData.parent.relationship,
                          email: riskfactorData.parent.email,
                          address: riskfactorData.parent.address,
                        }}
                      />
                    )}
                    <Box>
                      <Grid container className="mt-md">
                        <Grid
                          item
                          xs={12}
                          lg={12}
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <Box className="p-sm">
                            <MediumTypography
                              labelid={'RiskFactor.ChildCharacteristics'}
                              defaultlabel="Child Characteristics"
                              fontSize="18px"
                              fontweight={600}
                              textColor="#2a4241"
                            />
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '12px' }}
                            />
                            <Box className="rowContainer">
                              <Grid container>
                                <Grid item xs={3.5} lg={3.5}>
                                  <Textinput
                                    name="gestationalAge"
                                    labelid={'RiskFactor.GestationalAge'}
                                    defaultlabelid="Gestational age (In Weeks)"
                                    Required={false}
                                    inputProps={{
                                      maxLength: 2,
                                    }}
                                    sxProps={{ background: 'white' }}
                                    handlechange={(value) => {
                                      setUserModified(true);
                                      const numericValue = value.replace(
                                        /\D/g,
                                        '',
                                      );
                                      setFieldValue(
                                        'childCharacteristics.gestationalAge',
                                        numericValue,
                                      );
                                    }}
                                    type="text"
                                    Value={
                                      values.childCharacteristics.gestationalAge
                                    }
                                    disabled={
                                      gestationalAgeUnknown ||
                                      evaluationType == 'INITIALEVALUATION' ||
                                      uploadedToTBR
                                    }
                                  />
                                  <CheckBoxComponent
                                    disabled={
                                      evaluationType == 'INITIALEVALUATION' ||
                                      uploadedToTBR
                                    }
                                    defaultlabelid="Unknown"
                                    labelid="RiskFactor.Unknown"
                                    ischecked={gestationalAgeUnknown}
                                    CheckHandleChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      setGestationalAgeUnknown(
                                        e.target.checked,
                                      );
                                      setUserModified(true);
                                      setFieldValue(
                                        'childCharacteristics.gestationalAge',
                                        e.target.checked ? 'Unknown' : '',
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={0.5} lg={0.5}></Grid>
                                <Grid item xs={3.5} lg={3.5}>
                                  <DropdownComponent
                                    disabled={
                                      evaluationType == 'INITIALEVALUATION' ||
                                      uploadedToTBR
                                    }
                                    name="nicuStay"
                                    names={[
                                      {
                                        id: 'Yes',
                                        label: 'Yes',
                                      },
                                      {
                                        id: 'No',
                                        label: 'No',
                                      },
                                      {
                                        id: 'Unknown',
                                        label: 'Unknown',
                                      },
                                    ]}
                                    labelid="RiskFactor.NICUStay"
                                    handleChange={(e) => {
                                      setUserModified(true);
                                      setFieldValue(
                                        'childCharacteristics.childInNICU',
                                        e,
                                      );
                                    }}
                                    defaultlabelid="NICU Stay"
                                    value={
                                      values.childCharacteristics.childInNICU
                                    }
                                    placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                  />
                                </Grid>
                                <Grid item xs={0.5} lg={0.5}></Grid>
                                <Grid item xs={3.5} lg={3.5}>
                                  {' '}
                                  <Textinput
                                    name="daysInNICU"
                                    labelid={'RiskFactor.NICUDays'}
                                    defaultlabelid="NICU Days"
                                    Required={false}
                                    inputProps={{
                                      maxLength: 3,
                                    }}
                                    sxProps={{ background: 'white' }}
                                    handlechange={(value) => {
                                      setUserModified(true);
                                      const numericValue = value.replace(
                                        /\D/g,
                                        '',
                                      );
                                      setFieldValue(
                                        'childCharacteristics.daysInNICU',
                                        numericValue,
                                      );
                                    }}
                                    type="text"
                                    Value={
                                      values.childCharacteristics.daysInNICU
                                    }
                                    disabled={
                                      NICUDaysUnknown ||
                                      evaluationType == 'INITIALEVALUATION' ||
                                      uploadedToTBR
                                    }
                                  />
                                  <CheckBoxComponent
                                    disabled={
                                      evaluationType == 'INITIALEVALUATION' ||
                                      uploadedToTBR
                                    }
                                    defaultlabelid="Unknown"
                                    labelid="RiskFactor.Unknown"
                                    ischecked={NICUDaysUnknown}
                                    CheckHandleChange={(
                                      e: React.ChangeEvent<HTMLInputElement>,
                                    ) => {
                                      setNICUDaysUnknown(e.target.checked);
                                      setUserModified(true);
                                      setFieldValue(
                                        'childCharacteristics.daysInNICU',
                                        e.target.checked ? 'Unknown' : '',
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  container
                                  rowSpacing={'24px'}
                                  className="pt-md align__items__center"
                                >
                                  <Box className="rowContainer">
                                    <Grid>
                                      <MediumTypography
                                        labelid="RiskFactor.BirthWeight"
                                        defaultlabel={`Birth Weight`}
                                        fontSize="14px"
                                      />
                                    </Grid>
                                    <Grid className="ml-sm">
                                      <CustomRadioButton
                                        disable={
                                          evaluationType ==
                                            'INITIALEVALUATION' || uploadedToTBR
                                        }
                                        data={[
                                          {
                                            value: 'In Pounds',
                                            labelId: 'RiskFactor.Pounds',
                                            defaultLabel: 'In Pounds',
                                          },
                                          {
                                            value: 'In Grams',
                                            labelId: 'RiskFactor.Grams',
                                            defaultLabel: 'In Grams',
                                          },
                                          {
                                            value: 'Unknown',
                                            labelId: 'Unknown',
                                            defaultLabel: 'Unknown',
                                          },
                                        ]}
                                        value={
                                          values.childCharacteristics
                                            .birthWeight
                                        }
                                        onSelectItem={(value) => {
                                          setUserModified(true);
                                          setFieldValue(
                                            'childCharacteristics.weightInGrams',
                                            '',
                                          );
                                          setGramsUnknown(false);
                                          setFieldValue(
                                            'childCharacteristics.weightInLbs',
                                            '',
                                          );
                                          setBirthWeightOuncesUnknown(false);
                                          setFieldValue(
                                            'childCharacteristics.weightInOz',
                                            '',
                                          );
                                          setBirthWeightPoundsUnknown(false);
                                          setFieldValue(
                                            'childCharacteristics.birthWeight',
                                            value,
                                          );
                                        }}
                                        row={false}
                                      />
                                    </Grid>
                                  </Box>
                                </Grid>
                                <Grid container>
                                  {values.childCharacteristics.birthWeight ==
                                    'In Pounds' && (
                                    <Grid container className="mb-md">
                                      <Grid item xs={5} lg={5}>
                                        <Textinput
                                          name="weightInLbs"
                                          labelid={
                                            'RiskFactor.BirthWeightPounds'
                                          }
                                          defaultlabelid="Lbs"
                                          Required={false}
                                          inputProps={{
                                            maxLength: 2,
                                          }}
                                          sxProps={{ background: 'white' }}
                                          handlechange={(value) => {
                                            setUserModified(true);
                                            const numericValue = value.replace(
                                              /\D/g,
                                              '',
                                            );
                                            setFieldValue(
                                              'childCharacteristics.weightInLbs',
                                              numericValue,
                                            );
                                          }}
                                          type="text"
                                          Value={
                                            values.childCharacteristics
                                              .weightInLbs
                                          }
                                          disabled={
                                            birthWeightPoundsUnknown ||
                                            evaluationType ==
                                              'INITIALEVALUATION' ||
                                            uploadedToTBR
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={0.5} lg={0.5}></Grid>
                                      <Grid item xs={5} lg={5}>
                                        <Textinput
                                          name="weightInOz"
                                          labelid={
                                            'RiskFactor.BirthWeightOunces'
                                          }
                                          defaultlabelid="Oz"
                                          Required={false}
                                          inputProps={{
                                            maxLength: 2,
                                          }}
                                          sxProps={{ background: 'white' }}
                                          handlechange={(value) => {
                                            setUserModified(true);
                                            const numericValue = value.replace(
                                              /\D/g,
                                              '',
                                            );
                                            setFieldValue(
                                              'childCharacteristics.weightInOz',
                                              numericValue,
                                            );
                                          }}
                                          type="text"
                                          Value={
                                            values.childCharacteristics
                                              .weightInOz
                                          }
                                          disabled={
                                            birthWeightOuncesUnknown ||
                                            evaluationType ==
                                              'INITIALEVALUATION' ||
                                            uploadedToTBR
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  )}

                                  {values.childCharacteristics.birthWeight ==
                                    'In Grams' && (
                                    <Grid container className="mb-md">
                                      <Grid item xs={5} lg={5}>
                                        <Textinput
                                          name="weightInLbs"
                                          labelid={
                                            'RiskFactor.BirthWeightGrams'
                                          }
                                          defaultlabelid="Grams"
                                          Required={false}
                                          inputProps={{
                                            maxLength: 4,
                                          }}
                                          sxProps={{ background: 'white' }}
                                          handlechange={(value) => {
                                            setUserModified(true);
                                            const numericValue = value.replace(
                                              /\D/g,
                                              '',
                                            );
                                            setFieldValue(
                                              'childCharacteristics.weightInGrams',
                                              numericValue,
                                            );
                                          }}
                                          type="text"
                                          Value={
                                            values.childCharacteristics
                                              .weightInGrams
                                          }
                                          disabled={
                                            gramsUnknown ||
                                            evaluationType ==
                                              'INITIALEVALUATION' ||
                                            uploadedToTBR
                                          }
                                        />
                                      </Grid>
                                    </Grid>
                                  )}
                                </Grid>
                                <Grid
                                  container
                                  className="pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <Tooltip
                                      id="tool-tip"
                                      title={
                                        'Was the child hospitalized for more than 25 days during a 6-month period, excluding the initial birth admission to a NICU ?'
                                      }
                                      placement="top"
                                    >
                                      <Box>
                                        <MediumTypography
                                          labelid="IntakePregnancy.childHospitalized"
                                          defaultlabel={`Was the child hospitalized for more than 25 days during a 6-month period, excluding the initial birth admission to a NICU ?`}
                                          fontSize="14px"
                                          sxProps={{ ...ellipsisCellSX }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childHospitalized
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);
                                        setFieldValue(
                                          'childCharacteristics.childHospitalized',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <Tooltip
                                      id="tool-tip"
                                      title={
                                        'Was Child Diagnosed at Birth with Intrauterine Growth Restriction(IUGR) or Small for the Gestational Age (SGA) ?'
                                      }
                                      placement="top"
                                    >
                                      <Box>
                                        <MediumTypography
                                          labelid="RiskFactor.IURRORSGA"
                                          defaultlabel={`Was Child Diagnosed at Birth with Intrauterine Growth Restriction(IUGR) or Small for the Gestational Age (SGA) ?`}
                                          fontSize="14px"
                                          sxProps={{ ...ellipsisCellSX }}
                                        />
                                      </Box>
                                    </Tooltip>
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childDiagnosedAtBirth
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);

                                        setFieldValue(
                                          'childCharacteristics.childDiagnosedAtBirth',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />{' '}
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.SmallSize"
                                      defaultlabel={`Is child's weight, weight for height, or height for age less than the 5th percentile (Small size) ?`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics.smallSize
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);

                                        setFieldValue(
                                          'childCharacteristics.smallSize',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />{' '}
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.FeedingDifficulty"
                                      defaultlabel={`Chronic Feeding Difficulties?`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childChronicFeeding
                                      }
                                      onSelectItem={(value) => {
                                        setFieldValue(
                                          'childCharacteristics.childChronicFeeding',
                                          value,
                                        );
                                        setUserModified(true);
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />{' '}
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.ElevatedLeadLevels"
                                      defaultlabel={`Blood Lead Levels Measured 5 Micrograms/dl ?`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childBloodLevel
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);
                                        setFieldValue(
                                          'childCharacteristics.childBloodLevel',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />{' '}
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.AttachmentDifficulty"
                                      defaultlabel={`Difficulty with insecure attachment/interactional difficulties?`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childInsecureDifficulties
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);
                                        setFieldValue(
                                          'childCharacteristics.childInsecureDifficulties',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />{' '}
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.CNSAbnormality"
                                      defaultlabel={`Suspected Central Nervous System Abnormality?`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childNervousSystem
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);
                                        setFieldValue(
                                          'childCharacteristics.childNervousSystem',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />{' '}
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.MultipleTraumas"
                                      defaultlabel={`Multiple trauma/losses experienced by child?`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childMultipleTrauma
                                      }
                                      onSelectItem={(value) => {
                                        setFieldValue(
                                          'childCharacteristics.childMultipleTrauma',
                                          value,
                                        );
                                        setUserModified(true);
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />{' '}
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.SubstanceExposed"
                                      defaultlabel={`Diagnosis of Substance Exposed Newborn (SEN)?`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.childCharacteristics
                                          .childDiagnosis
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);
                                        setFieldValue(
                                          'childCharacteristics.childDiagnosis',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                                <Box
                                  className="horizontalLine"
                                  sx={{ marginY: '0px' }}
                                />
                                <Grid
                                  container
                                  className="pt-md pb-md align__items__center"
                                >
                                  <Grid item xs={8} lg={8}>
                                    <MediumTypography
                                      labelid="RiskFactor.SENorNASSibling"
                                      defaultlabel={`Sibling with a diagnosis of Substance Exposed Newborn (SEN)`}
                                      fontSize="14px"
                                    />
                                  </Grid>
                                  <Grid item xs={3} lg={3}>
                                    <CustomRadioButton
                                      disable={
                                        evaluationType == 'INITIALEVALUATION' ||
                                        uploadedToTBR
                                      }
                                      data={EICSRadioData}
                                      value={
                                        values.familyCharacteristics
                                          .siblingWithSEN
                                      }
                                      onSelectItem={(value) => {
                                        setUserModified(true);
                                        setFieldValue(
                                          'familyCharacteristics.siblingWithSEN',
                                          value,
                                        );
                                      }}
                                      row={false}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>

                      <Grid container className="mt-xlg">
                        <Grid
                          item
                          xs={12}
                          xl={12}
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <Box className="p-sm">
                            <MediumTypography
                              labelid={'RiskFactor.FamilyCharacteristics'}
                              defaultlabel="Family Characteristics"
                              fontSize="18px"
                              fontweight={600}
                              textColor="#2a4241"
                            />
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '12px' }}
                            />
                            <Grid container className="align__items__center">
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.BirthMotherParity"
                                  defaultlabel={`Mother less than 17 years of age and/or with 3 or more children before 20 (Birth Mother Parity) ?`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics
                                      .birthMotherParity
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.birthMotherParity',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={5} lg={5} className="pt-sm">
                              {' '}
                              <Textinput
                                name="birthMotherEducationYears"
                                labelid={'RiskFactor.BirthMotherEducationYears'}
                                defaultlabelid="Birth Mother Education Years"
                                Required={false}
                                inputProps={{
                                  maxLength: 5,
                                }}
                                sxProps={{ background: 'white' }}
                                handlechange={(value) =>
                                  setFieldValue(
                                    'familyCharacteristics.mothersEducationYear',
                                    value,
                                  )
                                }
                                type="text"
                                Value={
                                  values.familyCharacteristics
                                    .mothersEducationYear
                                }
                                disabled={
                                  birthMotherEducationYearsUnknown ||
                                  evaluationType == 'INITIALEVALUATION' ||
                                  uploadedToTBR
                                }
                              />
                              <CheckBoxComponent
                                disabled={
                                  evaluationType == 'INITIALEVALUATION' ||
                                  uploadedToTBR
                                }
                                defaultlabelid="Unknown"
                                labelid="RiskFactor.Unknown"
                                ischecked={birthMotherEducationYearsUnknown}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setUserModified(true);
                                  setBirthMotherEducationYearsUnknown(
                                    e.target.checked,
                                  );

                                  setFieldValue(
                                    'familyCharacteristics.mothersEducationYear',
                                    e.target.checked ? 'Unknown' : '',
                                  );
                                }}
                              />
                            </Grid>
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.ParentalCondition"
                                  defaultlabel={`Parental Chronic Illness or Disability that Affects Caregiving Ability
                                `}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics
                                      .parentalChronicIllness
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.parentalChronicIllness',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '0px' }}
                            />
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.LackingSupport"
                                  defaultlabel={`Family lacking social supports?`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics.socialSupport
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.socialSupport',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '0px' }}
                            />{' '}
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.LackingGoods"
                                  defaultlabel={`Lacking Adequate Food or Clothing`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics.lackingAdequate
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.lackingAdequate',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '0px' }}
                            />{' '}
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.ProtectiveServices"
                                  defaultlabel={`Open/Confirmed Protective Service Investigation`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics
                                      .protectiveServiceInvestigation
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.protectiveServiceInvestigation',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '0px' }}
                            />{' '}
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.SubstanceAbuse"
                                  defaultlabel={`Substance Abuse Affecting Child`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics.affectingChild
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.affectingChild',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '0px' }}
                            />{' '}
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.PhysicalorEmotionalAbuse"
                                  defaultlabel={`Abuse (physical or emotional) affecting child?`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics
                                      .abuseAffectingChild
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.abuseAffectingChild',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '0px' }}
                            />{' '}
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.Homelessness"
                                  defaultlabel={`Homelessness`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics.homelessness
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.homelessness',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                            <Box
                              className="horizontalLine"
                              sx={{ marginY: '0px' }}
                            />{' '}
                            <Grid
                              container
                              className="pt-md pb-md align__items__center"
                            >
                              <Grid item xs={8} lg={8}>
                                <MediumTypography
                                  labelid="RiskFactor.HomelessLast12Months"
                                  defaultlabel={` Homeless in the past 12 months`}
                                  fontSize="14px"
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <CustomRadioButton
                                  disable={
                                    evaluationType == 'INITIALEVALUATION' ||
                                    uploadedToTBR
                                  }
                                  data={EICSRadioData}
                                  value={
                                    values.familyCharacteristics
                                      .homelessnessInPast
                                  }
                                  onSelectItem={(value) => {
                                    setUserModified(true);
                                    setFieldValue(
                                      'familyCharacteristics.homelessnessInPast',
                                      value,
                                    );
                                  }}
                                  row={false}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Card>
                </>
              )}
            </Formik>
          </Box>
        </Box>
        <Box>
          <Grid
            className="flex__ justifyContent-FlexEnd pt-md pb-lg "
            container
            direction="row"
            alignItems="right"
          >
            <Grid item>
              <ButtonComponent
                className="btn-primary btn-cancel mr-md"
                variantType="contained"
                defaultLabelId="Cancel"
                labelId="Contacts.cancelbtn"
                onClick={() => {
                  if (userModified) {
                    setCancelModal(true);
                  } else {
                    navigate(BATELLE_DEVELOPMENT_INVENTORY_2);
                  }
                }}
              />

              <ButtonComponent
                className="btn-primary btn-submit"
                variantType="contained"
                type="submit"
                labelId="Insurance.save"
                defaultLabelId="Save"
                disabled={
                  evaluationType == 'INITIALEVALUATION' || uploadedToTBR
                }
                onClick={() => {
                  formikRef.current?.handleSubmit();
                }}
              />
            </Grid>
          </Grid>
        </Box>
        {cancelModal && (
          <ModalPopup
            open={cancelModal}
            onCancel={() => {
              setCancelModal(false);
            }}
            description="formUnsavedChangesMessage"
            onOk={() => {
              riskFactorGetFunction();
              setCancelModal(false);
              setUserModified(false);
            }}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}
      </div>
    </Box>
  );
};

export default RiskFactor;
