import { TeamMembersType } from '../../../../pages/ifsp/ifspContextApi/IfspContex';
import { InsuranceType, ResourceRepoDTO } from '../../../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../../../ApiResponseHandler';
import { THOM_BASE_URL } from '../../../Constant';
import ThomApiClient from '../../../ThomApiClient';
import { SiblingTypeResponse } from '../../User';
import { ConsentClientSignature } from '../Consent/ConsentServices';

export type IntakeClientInfoResponseType = {
  demographics: {
    clientId?: string;
    name: string;
    dphId: string;
    dateOfBirth: string;
    ageInMonths: string;
    sex: string;
    address: string;
  };
  parents: InatkeParentType[];
  insurance: InsuranceType[];
  teamMembers: {
    others: TeamMembersType[];
  };
  biologicalParents: string;
  eics: {
    primaryResidence: string;
    childBorn: string;
    childAdopted: string;
  };
  descriptions: {
    concernsDescription: string;
    prioritiesDescription: string;
    gainDescription: string;
  };
  comment: string;
  isCurrentlyHeld?: boolean;
  heldBy?: string | null;
  readyToDownload?: boolean;
  isSigned?: boolean;
  reviewdBy?: string;
  reviewdDate?: string;
};

export type IntakePregnancyInfoResponseType = {
  prenatal: {
    prenatalCare: string;
    prenatalCareStart: string;
    prenatalCareRecievedAt: string;
    prenatalTests: string;
  };
  pregnancy: {
    pregnancyProblems: string;
    pregnancyProblemName: string[];
    pregnancyProblemOther: string;
    complicationWithPregnancy: string;
    neonatalCourse: string;
  };
  childBorn: {
    problemWhenChildBorn: string;
    problemWhenChildBornDeal: string;
    problemWhenChildBornNow: string;
    babyBornOnTime: string;
    babyBornEarlyOrLate: string;
  };
  drugUse: {
    alcoholUse: string;
    tobaccoUse: string;
    prescriptionDrugUse: string;
    recreationalDrugUse: string;
    medicationDuringTrimester: string;
    medicationsAnesthesia: string;
  };
  delivery: {
    labor: string;
    deliveryMethodNames: string[];
    caesareanExplain: string;
    considerationsAboutLaborOther: string;
    motherInHospital: string;
    dischargeDate: string | null;
  };
  tests: {
    treatmentName: string[];
    treatmentOther: string;
    newbornHearingScreening: string;
    specialNeedsMedicalEquipment: string;
    hearingEvaluation: string;
    hearingEvaluationDesc: string;
    visionScreening: string;
    visionScreeningDesc: string;
    hearingVisionConcerns: string;
  };
  specialCare: {
    specialCareAfterBirthAt: string[];
    hospitalNameNICU: string;
    hospitalPhoneNoNICU: string;
    howLongNICU: string;
    noOfDaysCCN: string;
    hospitalNameSC: string;
    hospitalPhoneNoSC: string;
    howLongSC: string;
    hospitalNameRN: string;
    hospitalPhoneNoRN: string;
    howLongRN: string;
    roomedInRN: string;
  };
  eics: {
    diagnosisOfSubstanceExposedNewborn: string;
    gestationalAge: string;
    birthWeight: string;
    weightInLbs: string;
    weightInOz: string;
    weightInGrams: string;
    multipleBirth: string;
    specialCareAfterBirth: string;
    childInNICU: string;
    daysInNICU: string;
  };
  comment: string;
  readyToDownload?: boolean;
  isCurrentlyHeld?: boolean;
  heldBy?: string | null;
  isSigned?: boolean;
  reviewdBy?: string;
  reviewdDate?: string;
};

export type IntakeCurrentHealthInfoResponseType = {
  medication: {
    medicalSpecialtyNames: string[];
    medicalSpecialtyOther: string;
    medicalProcedureNames: string[];
    medicalProcedureOther: string;
    medicationExplain: string;
    hospitals: HospitalsJSONType[];
  };
  healthIssue: {
    childOralHealthExplain: string;
    healthIssueNames: string[];
    seizureDisorderExplain: string;
    bloodLevel: string;
    bloodLevelDesc: string;
    heartDefectDesc: string;
    allergiesDesc: string;
    foodIntolerancesDesc: string;
    brokenBonesDesc: string;
    healthIssueOthers: string;
  };
  doctor: {
    immunizationsUpToDate: string;
    immunizationExplain: string;
    seenDentist: string;
    dentistFirstName: string;
    dentistlastName: string;
    dentistPhoneNumber: string;
    therapies: string;
    theraphyDesc: string;
    temperamentDesc: string;
  };
  childDesc: {
    childDesc: string;
    childEatingDesc: string;
    childFeedDesc: string;
    childGrowthDesc: string;
    childSleepDesc: string;
    activitiesDesc: string;
    childBehaviourDesc: string;
    socialRelationshipDesc: string;
    childSoundsDesc: string;
    childNeedsDesc: string;
    childFollowsDesc: string;
    childTolerateDesc: string;
    childlookSmileDesc: string;
    childUnderstandDesc: string;
  };
  childActivities: {
    childActivities: string;
    socialRelationship: string;
    childSmile: string;
    childRollOver: string;
    childCrawl: string;
    childWalkAlone: string;
    childSitIndependently: string;
    childImitateActions: string;
    childImitateSounds: string;
    childUseFirstWords: string;
    childMakeNoise: string;
    supportSystems: string;
  };
  eics: {
    hospitalization: string;
    childBloodLevel: string;
    childInsecureDifficulties: string;
    childNervousSystem: string;
    childMultipleTrauma: string;
    childHospitalized: string;
    childDiagnosedAtBirth: string;
    childChronicFeeding: string;
    smallSize: string;
  };
  comment: string;
  isCurrentlyHeld?: boolean;
  heldBy?: string | null;
  readyToDownload?: boolean;
  isSigned?: boolean;
  reviewdBy?: string;
  reviewdDate?: string;
};

export interface InatkeParentType {
  id: string;
  name: string;
  relationshipToClient: string;
  phoneNumber: string;
  address: string;
  email?: string;
}

export interface HospitalsJSONType {
  id: number;
  dateOfAdmitted: string | null;
  hospitalName: string;
  phoneNumber: string;
  comment: string;
}

export interface IntakeFamilyHistoryInfoResponseType {
  schoolCompleted: string;
  qualification: string[];
  occupation: string;
  employeedBy: string;
  employerAddress: string;
  workPhone: string;
  jobType: string;
  jobTypeOther: string;
  parentsMaritalStatus: string;
  kinshipCare: string;
  fosterParents: InatkeParentType[] | [];
  withWhomChildLive: string;
  siblings: SiblingTypeResponse[] | [];
  extendedFamily: string;
  compromisedImmuneSystem: string;
  familyMemberHistory: string[];
  familyMemberHistoryOther: string;
  motherRace: string;
  motherEthnicity: string;
  fatherRace: string;
  fatherEthnicity: string;
  eics: {
    parent: InatkeParentType;
    parentalChronicIllness: string;
    affectingChild: string;
    race: string;
    ethnicity: string;
    primarySpokenLanguage: string;
    primarySpokenLanguageOther: string;
    primaryWrittenLanguage: string;
    primaryWrittenLanguageOther: string;
    pets: string;
    smoking: string;
    unsecuredWeapons: string;
    siblingWithSEN: string;
    mothersEducationYear: string;
    povertyLevel: string;
    lackingAdequate: string;
    protectiveServiceInvestigation: string;
    homelessness: string;
    homelessnessInPast: string;
    education: string;
    educationYear: string;
    birthMotherParity: string;
    abuseAffectingChild: string;
    socialSupport: string;
  };
  comment: string;
  isCurrentlyHeld?: boolean;
  heldBy?: string | null;
  readyToDownload?: boolean;
  isSigned?: boolean;
  reviewdBy?: string;
  reviewdDate?: string;
}

export interface HeartBeatRequestType {
  clientId: string | null;
  tabName: string;
  userId: string | null;
}

export interface HeartBeatResponseType {
  clientId: string;
  userId: string;
  currentEmpName: string | null;
  currentEmpId: string | null;
  clientEmpId: string | null;
  clientEmpName: string | null;
  familyEmpId: string | null;
  familyEmpName: string | null;
  pregnancyEmpId: string | null;
  pregnancyEmpName: string | null;
  q1EmpId: string | null;
  q1EmpName: string | null;
  q2EmpId: string | null;
  q2EmpName: string | null;
  q3EmpId: string | null;
  q3EmpName: string | null;
  code: string | null;
  errorMessage: string | null;
  isSigned: boolean;
}

export interface IntakeReviewResponseType {
  demographics: DemographicResponseType;
  pregnancy: PreganancyEICSResponseType;
  currentHealth: CurrentHealthEICSResponseType;
  familyHistory: FamilyHistoryEICSResponseType;
  signature: ConsentClientSignature[];
  isSigned: boolean;
  reviewdBy: string;
  reviewdDate: string;
  canSign: boolean;
}

export type DemographicResponseType = {
  race: string;
  ethnicity: string;
  multipleBirth: string;
  childBorn: string;
  childAdopted: string;
  primarySpokenLanguage: string;
  primarySpokenLanguageOther: string;
  primaryWrittenLanguage: string;
  primaryWrittenLanguageOther: string;
};

export type PreganancyEICSResponseType = {
  diagnosisOfSubstanceExposedNewborn: string;
  gestationalAge: string;
  gestationalAgeCheck?: boolean;
  birthWeight: string;
  weightInLbs: string;
  weightInOz: string;
  weightInGrams: string;
  specialCareAfterBirth: string;
  childInNICU: string;
  daysInNICU: string;
  daysInNICUCheck?: boolean;
};

export type CurrentHealthEICSResponseType = {
  childHospitalized: string;
  childBloodLevel: string;
  childInsecureDifficulties: string;
  childNervousSystem: string;
  childMultipleTrauma: string;
  childDiagnosedAtBirth: string;
  childChronicFeeding: string;
  smallSize: string;
};

export type FamilyHistoryEICSResponseType = {
  parentalChronicIllness: string;
  affectingChild: string;
  siblingWithSEN: string;
  mothersEducationYear: string;
  mothersEducationYearCheck?: boolean;
  lackingAdequate: string;
  povertyLevel: string;
  protectiveServiceInvestigation: string;
  homelessness: string;
  homelessnessInPast: string;
  birthMotherParity: string;
  abuseAffectingChild: string;
  socialSupport: string;
  resourceDto: ResourceRepoDTO;
};
export const heartBeat = async (hearBeatRequest: HeartBeatRequestType) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<
      HeartBeatRequestType,
      HeartBeatResponseType
    >(`heartbeat`, hearBeatRequest),
  );
};

export const getIntakeDetailsByTab = async (clientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<IntakeClientInfoResponseType>(
      `intake/clientInfo?clientId=${clientId}`,
    ),
  );
};

export const getIntakePregnancyInfo = async (clientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<IntakePregnancyInfoResponseType>(
      `intake/pregnancy?clientId=${clientId}`,
    ),
  );
};

export const getIntakeCurrentHealthInfoDetails = async (clientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<IntakeCurrentHealthInfoResponseType>(
      `intake/currentHealth?clientId=${clientId}`,
    ),
  );
};

export const getIntakeFamilyHistoryInfoDetails = async (clientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<IntakeFamilyHistoryInfoResponseType>(
      `intake/family?clientId=${clientId}`,
    ),
  );
};

export const getIntakeReviewDetails = async (clientId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<IntakeReviewResponseType>(
      `intake/review?clientId=${clientId}`,
    ),
  );
};

export const putIntakeClientInfo = async (
  clientId: string,
  params: IntakeClientInfoResponseType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<
      IntakeClientInfoResponseType,
      ApiMessage
    >(`intake/clientInfo?clientId=${clientId}`, params),
  );
};

export const putIntakePregnancyInfo = async (
  clientId: string,
  params: IntakePregnancyInfoResponseType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<
      IntakePregnancyInfoResponseType,
      ApiMessage
    >(`intake/pregnancy?clientId=${clientId}`, params),
  );
};

export const putIntakeCurrentHealthInfo = async (
  clientId: string,
  params: IntakeCurrentHealthInfoResponseType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<
      IntakeCurrentHealthInfoResponseType,
      ApiMessage
    >(`intake/currentHealth?clientId=${clientId}`, params),
  );
};

export const putIntakeFamilyHistoryInfo = async (
  clientId: string,
  params: IntakeFamilyHistoryInfoResponseType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<
      IntakeFamilyHistoryInfoResponseType,
      ApiMessage
    >(`intake/family?clientId=${clientId}`, params),
  );
};

export const putIntakeReviewDetails = async (
  clientId: string,
  params: IntakeReviewResponseType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<IntakeReviewResponseType, ApiMessage>(
      `intake/review?clientId=${clientId}`,
      params,
    ),
  );
};

export const downloadIntakeData = async (
  clientId: string,
  localTimezone: string,
): Promise<Blob> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Blob>(
      `intake/download?clientId=${clientId}&timezone=${localTimezone}`,
      {},
      {
        baseURL: THOM_BASE_URL,
        responseType: 'blob',
      },
    ),
  );
};
