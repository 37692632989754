import { useEffect, useRef, useState } from 'react';
import AddClientFiles from './AddClientFiles';
import ClientFilesTableView from './ClientFilesTableView';
import { checkPermissionForFeature } from '../../utils/checkPermission';

const ClientFilesPage = () => {
  const [reload, setReload] = useState<boolean>(true);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div
        ref={parentRef}
        style={{
          pointerEvents: isScreenLocked ? 'none' : 'auto',
          opacity: isScreenLocked ? '0.5' : '1 ',
        }}
        onKeyDownCapture={(e) => {
          if (isScreenLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (isScreenLocked) {
            if (parentRef.current) parentRef.current.focus();
          }
        }}
      >
        <AddClientFiles updateReload={setReload} />
      </div>
      <ClientFilesTableView reload={reload} />
    </>
  );
};

export default ClientFilesPage;
