import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import InformationModal from './InformationModal';
import TransitionPreparationData from './IFSP/TransitionPreparation.json';
import SimpleTextAndTextArea from '../../components/forms/SimpleTextAndTextArea';
import IfspContext from './ifspContextApi/IfspContex';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface TransitionPreparationProps {
  info: boolean;
  open: boolean;
  isIfspScreenLocked: boolean;
  selectedLanguage: string;
  onClose: (open: boolean) => void;
  closeInfo: (info: boolean) => void;
  onOpen: (open: boolean) => void;
  openInfo: (info: boolean) => void;
}
const TransitionPreparation: FC<TransitionPreparationProps> = ({
  open,
  onOpen,
  isIfspScreenLocked,
  onClose,
  info,
  openInfo,
  closeInfo,
  selectedLanguage,
}) => {
  const { ifsp, updateIfspContext } = useContext(IfspContext);
  const [transistionPrepQuestion, setTransistionPrepQuestion] = useState<
    { commentId: number; comments: string }[]
  >(
    TransitionPreparationData.Questions.map((_, index) => ({
      commentId: index,
      comments: '',
    })),
  );

  const [transitionPreparation, setTransitionPreparation] = useState<{
    changesComingUp: string;
    preparationHelp: string;
    supports: string;
    communityOpportunities: string;
    serviceChangesNeeded: string;
  }>({
    changesComingUp: '',
    preparationHelp: '',
    supports: '',
    communityOpportunities: '',
    serviceChangesNeeded: '',
  });

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      selectedLanguage === 'en'
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, [selectedLanguage]);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTransistionPrepQuestion((prev) => {
      const temp = [...prev];
      temp[0].comments =
        ifsp.ifspTeam.forms.transitionPreparation.changesComingUp;
      temp[1].comments =
        ifsp.ifspTeam.forms.transitionPreparation.preparationHelp;
      temp[2].comments = ifsp.ifspTeam.forms.transitionPreparation.supports;
      temp[3].comments =
        ifsp.ifspTeam.forms.transitionPreparation.communityOpportunities;
      temp[4].comments =
        ifsp.ifspTeam.forms.transitionPreparation.serviceChangesNeeded;
      return temp;
    });
  }, [ifsp]);

  return (
    <Box component="main">
      {info && (
        <InformationModal
          open={info}
          titleLabel="IFSP.Information"
          titleDefaultLabel="Information"
          buttonLabel="IFSP.InfoOkButton"
          buttonDefaultLabel="Ok"
          onClose={() => {
            closeInfo(info);
          }}
          info={TransitionPreparationData.info}
        />
      )}
      <Box
        component="section"
        className="p-lg gray3 borderRadius4"
        sx={{
          border: '1px solid #00C6B8',
        }}
      >
        <Box component="div" className="flex__justify__space-between">
          {open ? (
            <Box component="div" className="flex__justify__space-between">
              <MediumTypography
                labelid="IFSP.session8"
                defaultlabel="Transition Preparation"
                fontSize="18px"
                fontweight={600}
                textColor="#2A4241"
                className="pr-md"
              />
              <InfoIcon
                className="cursorPointer"
                onClick={() => {
                  openInfo(info);
                }}
              />
            </Box>
          ) : (
            <MediumTypography
              labelid="IFSP.session8"
              defaultlabel="Transition Preparation"
              fontSize="18px"
              fontweight={600}
              textColor="#2A4241"
            />
          )}
          {!open ? (
            <DownArrow
              className="cursorPointer"
              style={{ color: '#008C82' }}
              onClick={() => {
                onOpen(open);
              }}
            />
          ) : (
            <UpArrow
              className="cursorPointer"
              onClick={() => {
                onClose(open);
              }}
            />
          )}
        </Box>
        {open && (
          <Box component="div" className="mt-lg">
            <div
              ref={parentRef}
              style={{
                pointerEvents: isScreenLocked ? 'none' : 'auto',
                opacity: isScreenLocked ? '0.5' : '1 ',
              }}
              onKeyDownCapture={(e) => {
                if (isScreenLocked) {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
              onFocus={() => {
                if (isScreenLocked) {
                  if (parentRef.current) parentRef.current.focus();
                }
              }}
            >
              <SimpleTextAndTextArea
                disabled={isIfspScreenLocked}
                placeholder="Enter your comments"
                questions={TransitionPreparationData.Questions}
                rows={3}
                customCommentStyles={{
                  backgroundColor: '#ffffff',
                  height: '80px',
                  minHeight: '80px',
                  minWidth: '840px',
                  width: '840px',
                }}
                maxLength={5000}
                Value={transistionPrepQuestion}
                handlechange={(value: {
                  commentId: number;
                  comments: string;
                }) => {
                  const updatedComments = [...transistionPrepQuestion];
                  updatedComments[value.commentId].comments = value.comments;
                  setTransistionPrepQuestion(updatedComments);
                  const updatedData = {
                    ...transitionPreparation,
                    changesComingUp: updatedComments[0].comments,
                    preparationHelp: updatedComments[1].comments,
                    supports: updatedComments[2].comments,
                    communityOpportunities: updatedComments[3].comments,
                    serviceChangesNeeded: updatedComments[4].comments,
                  };
                  updateIfspContext({
                    ...ifsp,
                    ifspTeam: {
                      ...ifsp.ifspTeam,
                      forms: {
                        ...ifsp.ifspTeam.forms,
                        transitionPreparation: {
                          ...ifsp.ifspTeam.forms.transitionPreparation,
                          changesComingUp: updatedComments[0].comments,
                          preparationHelp: updatedComments[1].comments,
                          supports: updatedComments[2].comments,
                          communityOpportunities: updatedComments[3].comments,
                          serviceChangesNeeded: updatedComments[4].comments,
                        },
                      },
                    },
                  });
                  setTransitionPreparation(updatedData);
                }}
              />
            </div>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TransitionPreparation;
