import { Box, Grid, Tooltip } from '@mui/material';
import { FC } from 'react';
import MediumTypography from '../formlib/MediumTypography';

export type DataJsonType = {
  id: string;
  labelid: string;
  defaultLabel: string;
  value: string;
};
export interface KeyValuePairProps {
  data: DataJsonType[];
}

const KeyValueComponent: FC<KeyValuePairProps> = ({ data }) => {
  return (
    <Grid container direction={'row'}>
      {data.map((item) => {
        return (
          <Grid item key={item.id}>
            <Box className="flex__">
              <MediumTypography
                labelid={item.labelid}
                defaultlabel={item.defaultLabel}
                sxProps={{ color: '#97A6A5' }}
              />
              <Tooltip
                title={item.value && item.value.length > 50 ? item.value : ''}
                arrow={true}
                followCursor
              >
                <Box>
                  <MediumTypography
                    className="pr-lg pl-sm"
                    sxProps={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: item.value.length > 50 ? '300px' : 'auto',
                      color: '#2A4241',
                    }}
                    label={
                      item.value === '' || item.value === null
                        ? '-'
                        : item.value
                    }
                  />
                </Box>
              </Tooltip>
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default KeyValueComponent;
