import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import React, { FC, useContext, useRef, useState } from 'react';
import MediumTypography from '../MediumTypography';
import SignatureCanvas from 'react-signature-canvas';
import { right } from '@popperjs/core';
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonComponent from '../ButtonComponent';
import { CustomOptionsType } from '../../../pages/Insurance';
import { convertFileToBase64 } from '../../../utils/fileUtils';
import imageCompression from 'browser-image-compression';
import { ReactComponent as CloseIcon } from '../../../assets/images/x.svg';
import { EmployeeFormData } from '../../../pages/employees/types';
import { ChangeSignatureBodyType, ResponseType } from '../../../utils/type';
import { changeSignatureApi } from '../../../services/SignatureApi';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import Textinput from '../Textinput';
import SmallTypography from '../SmallTypography';

interface EmployeeSignatureInterface {
  open: boolean;
  handleClose: () => void;
  details: EmployeeSignatureType;
  modalType: string;
  employeeDetails: EmployeeFormData;
  signCanChage?: boolean;
  onSave: (signature: string, pin: string) => void;
  onSuccess: (successerror: ResponseType, id: string, message: string) => void;
}

export interface EmployeeSignatureType {
  pin: string;
  employeeSignature: string;
}

const EmployeeSignatureModal: FC<EmployeeSignatureInterface> = ({
  open,
  handleClose,
  details,
  modalType,
  employeeDetails,
  signCanChage,
  onSave,
  onSuccess,
}) => {
  const rctl = useIntl();
  const sigRef = useRef<SignatureCanvas>(null);
  const [pin, setPin] = useState<string>('');
  const [confirmPin, setConfirmPin] = useState<string>('');
  const [error, setError] = useState<boolean>(true);
  const [signedSignature, setSignedSignature] = useState<string>('');
  const [isSignatureDrawn, setIsSignatureDrawn] = React.useState(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [modalShowType, setModalShowType] = useState<string>(modalType);

  const pinPlaceholder = (
    <FormattedMessage
      id="employeeSig.enterPinText"
      defaultMessage="Enter PIN"
    />
  );

  const confirmPinPlaceholder = (
    <FormattedMessage
      id="employeeSig.enterConfirmPinText"
      defaultMessage="Enter Confirm PIN"
    />
  );

  const handleChange = (text: string) => {
    const newValue = text.trimStart().replace(/\D/g, '');

    setPin(newValue);
    setConfirmPin('');
  };

  const handleConfirmPinChange = (value: string) => {
    const newValue = value.trimStart().replace(/\D/g, '');

    setConfirmPin(newValue);

    if (value.length <= 4) {
      if (value !== pin) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(true);
    }
  };

  const handleSignatureEnd = async () => {
    const canvas = sigRef.current;
    if (canvas) {
      const isCanvasEmpty = canvas.isEmpty();
      setIsSignatureDrawn(!isCanvasEmpty);
    }

    if (canvas && canvas != null) {
      const dataURL = canvas.toDataURL('image/png');
      const blob = await fetch(dataURL).then((res) => res.blob());
      const file = new File([blob], 'compressed-image.png', {
        type: 'image/png',
      });
      const compressedDataURL = await imageCompression(file, {
        maxSizeMB: 0.03, // Adjust the maximum file size as needed
        maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
        fileType: 'image/jpeg',
      } as CustomOptionsType);

      const base64String = await convertFileToBase64(compressedDataURL);
      setSignedSignature(base64String);
    }
  };

  const clearEmployeeSignature = () => {
    setIsSignatureDrawn(false);

    if (sigRef.current) {
      sigRef.current.clear();
    }
  };

  const saveSignature = () => {
    onSave(signedSignature, pin);
    handleClose();
  };

  const changeSignature = () => {
    toggleLoader(true);
    onSave(signedSignature, pin);
    if (employeeDetails) {
      const changeSignatureObj: ChangeSignatureBodyType = {
        signatureData: signedSignature,
        employeeId: employeeDetails.id,
        partitionKey: employeeDetails.signaturePartitionKey,
        rowKey: employeeDetails.signatureRowKey,
      };
      changeSignatureApi(pin, changeSignatureObj)
        .then(() => {
          onSuccess('success', 'updateSuccessMessage', 'Updated successfully');
          toggleLoader(false);

          handleClose();
        })
        .catch((errors) => {
          toggleLoader(false);
          handleClose();

          if (isCustomError(errors)) {
            const apiError = errors as ApiError;
            onSuccess('error', apiError.id, apiError.message);
          } else {
            const errorMessage = errors as ApiMessage;
            if (errorMessage.code === 401) {
              onSuccess('error', 'invalidPinErrorMesg', 'Invalid Pin');
            } else {
              onSuccess(
                'error',
                'updateFailureMessage',
                'Failed to update employee Signature',
              );
            }
          }
        });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation(); // Stop event propagation to prevent closing
    }
  };

  return (
    <Box component="main">
      <Box component="section">
        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              return;
            } else {
              handleClose();
            }
          }}
          fullWidth
          maxWidth={'sm'}
          onKeyDown={handleKeyPress} // Handle key press events
        >
          <DialogTitle>
            <Box>
              <Grid container>
                <Grid item xs={6}>
                  {modalShowType === 'add' && (
                    <MediumTypography
                      labelid={'Insurance.Addsignature'}
                      defaultlabel="Add Signature"
                      fontweight={700}
                      fontSize="24px"
                      textColor="#2A4241"
                    />
                  )}
                  {modalShowType === 'view' && (
                    <MediumTypography
                      labelid={'ProgressNoteViewSignature'}
                      defaultlabel="View Signature"
                      fontweight={700}
                      fontSize="24px"
                      textColor="#2A4241"
                    />
                  )}
                  {modalShowType === 'change' && (
                    <MediumTypography
                      labelid={'changeSignatureText'}
                      defaultlabel="Change Signature"
                      fontweight={700}
                      fontSize="24px"
                      textColor="#2A4241"
                    />
                  )}
                </Grid>
                <Grid item xs={6} textAlign={right}>
                  {modalShowType !== 'view' && (
                    <ButtonComponent
                      className="resetLinkSignature pr-xs"
                      variantType="contained"
                      type="submit"
                      labelId="resetSignatureText"
                      defaultLabelId="Reset Signature"
                      onClick={clearEmployeeSignature}
                    />
                  )}
                  {modalShowType === 'view' && (
                    <CloseIcon
                      style={{ bottom: '5px' }}
                      className="cursorPointer position__relative "
                      onClick={handleClose}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box>
              {details.employeeSignature !== '' && modalShowType === 'view' ? (
                <Button>
                  <img
                    src={`${details.employeeSignature}`}
                    style={{
                      width: '530px',
                      height: '150px',
                      border: 'none',
                    }}
                    alt={rctl.formatMessage({
                      id: 'EmployeeSignatureText',
                      defaultMessage: "Employee's Signature",
                    })}
                  />
                </Button>
              ) : (
                <SignatureCanvas
                  penColor="green"
                  canvasProps={{ className: 'signature_employee' }}
                  ref={sigRef}
                  onEnd={handleSignatureEnd}
                  clearOnResize={false}
                />
              )}

              {modalShowType === 'add' && (
                <Grid container>
                  <Grid item xs={6} lg={6}>
                    <Textinput
                      type="password"
                      name="pin"
                      labelid=""
                      defaultlabelid=""
                      Value={pin}
                      inputProps={{ maxLength: 4 }}
                      handlechange={(text: string) => {
                        handleChange(text);
                      }}
                      sxProps={{
                        backgroundColor: 'white',
                        width: '270px',
                      }}
                      placeHolder={pinPlaceholder.props.defaultMessage}
                    />
                  </Grid>
                  <Grid item xs={6} lg={6}>
                    <Textinput
                      type="password"
                      name="confirmPin"
                      labelid=""
                      defaultlabelid=""
                      Value={confirmPin}
                      inputProps={{ maxLength: 4 }}
                      handlechange={(text: string) => {
                        handleConfirmPinChange(text);
                      }}
                      sxProps={{
                        backgroundColor: 'white',
                      }}
                      placeHolder={confirmPinPlaceholder.props.defaultMessage}
                    />
                    {error && confirmPin.length === 4 && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId="employeeSig.pinDoesnotMatchText"
                        defaultLabelId="Pins do not match"
                      />
                    )}
                  </Grid>
                </Grid>
              )}
              {modalShowType === 'change' && (
                <Grid container>
                  <Textinput
                    type="password"
                    name="pin"
                    labelid=""
                    defaultlabelid=""
                    Value={pin}
                    inputProps={{ maxLength: 4 }}
                    handlechange={(text: string) => {
                      const newValue = text.trimStart().replace(/\D/g, '');
                      setPin(newValue);
                      setConfirmPin('');
                    }}
                    sxProps={{
                      backgroundColor: 'white',
                      width: '550px',
                    }}
                    placeHolder={pinPlaceholder.props.defaultMessage}
                  />
                </Grid>
              )}
              {modalShowType === 'view' && signCanChage && (
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd pt-md ml-md"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        className="btn-add-code ml-md"
                        variantType="contained"
                        type="submit"
                        labelId="employeeSig.changeSignatureText"
                        defaultLabelId="Change Signature"
                        onClick={() => {
                          if (employeeDetails.signaturePartitionKey) {
                            setModalShowType('change');
                          } else {
                            setModalShowType('add');
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {modalShowType !== 'view' && (
                <Box>
                  <Grid
                    className="flex__ justifyContent-FlexEnd pt-md"
                    container
                    direction="row"
                    alignItems="right"
                  >
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        variantType="contained"
                        labelId="Contacts.cancelbtn"
                        defaultLabelId="Cancel"
                        onClick={handleClose}
                      />

                      {modalShowType === 'add' ? (
                        <ButtonComponent
                          className="btn-primary btn-submit ml-sm"
                          variantType="contained"
                          disabled={
                            error ||
                            !isSignatureDrawn ||
                            pin.length !== 4 ||
                            confirmPin.length !== 4
                          }
                          type="submit"
                          labelId="Insurance.save"
                          defaultLabelId="Save"
                          onClick={saveSignature}
                        />
                      ) : (
                        <ButtonComponent
                          className="btn-primary btn-submit ml-sm"
                          variantType="contained"
                          disabled={pin.length !== 4 || !isSignatureDrawn}
                          type="submit"
                          labelId="clientpage.Update"
                          defaultLabelId="Update"
                          onClick={changeSignature}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
};

export default EmployeeSignatureModal;
