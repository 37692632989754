import { EICSFormType } from '../../pages/eicsTBRReferralToEvaluation/types';
import { ApiResponseHandler } from '../ApiResponseHandler';
import { THOM_BASE_URL } from '../Constant';
import ThomApiClient from '../ThomApiClient';

export const getEicsForm = async (clientId: string): Promise<EICSFormType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EICSFormType>(
      `client/eics?clientId=${clientId}`,
    ),
  );
};

export const downloadEICSData = async (
  clientId: string,
  localTimezone: string,
): Promise<Blob> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Blob>(
      `client/eics/download?clientId=${clientId}&timezone=${localTimezone}`,
      {},
      {
        baseURL: THOM_BASE_URL,
        responseType: 'blob',
      },
    ),
  );
};
