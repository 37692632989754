import { Box, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';

import { ReactComponent as EditIcon } from '../../assets/images/highlight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';

import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { SiblingTypeResponse } from '../../services/configApi/User';

interface SiblingTable {
  data: SiblingTypeResponse;
  onDelete?: (id: string) => void;
  onEdit?: (data: SiblingTypeResponse) => void;
}

const IntakeSiblingsTableRow: FC<SiblingTable> = ({
  data,
  onDelete,
  onEdit,
}) => {
  const handleDeleteClick = () => {
    if (onDelete) {
      onDelete(data.id);
    }
  };
  const handleEdit = () => {
    if (onEdit) {
      onEdit(data);
    }
  };

  return (
    <TableRow
      className="tableRowcss"
      sx={{
        cursor: 'pointer',
        alignContent: 'flex-start',
        '&:nth-of-type(odd)': {
          backgroundColor: '#ECF9F8',
        },
        '&:nth-of-type(even)': {
          backgroundColor: 'white',
        },
      }}
    >
      <TableCell>
        <TooltipTableCell
          value={data.name ? data.name : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.dateOfBirth ? data.dateOfBirth : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.school ? data.school : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.grade ? data.grade : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.siblingType ? data.siblingType : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <Box component="div" className="flex__">
          <Box
            component="div"
            className="cursor__pointer pointerEvents pr-sm pt-xs"
          >
            <ActionToolTip labelId="BlockTime.edit" defaultLabel="Edit">
              <EditIcon onClick={handleEdit} />
            </ActionToolTip>
          </Box>

          <Box
            component="div"
            className="cursor__pointer pt-xs"
            sx={{
              opacity: 1,
              pointerEvents: 'auto',
            }}
          >
            <ActionToolTip labelId="BlockTime.delete" defaultLabel="Delete">
              <DeleteIcon onClick={handleDeleteClick} />
            </ActionToolTip>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default IntakeSiblingsTableRow;
