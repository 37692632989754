import { Box, Tooltip } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC } from 'react';
import { removeDoubleCommas } from '../../utils/nameUtils';

export type ParentDetailsType = {
  name: string;
  relationshipToClient: string;
  email: string;
  homePhoneNumber: string;
  address: string;
};

interface ParentDetailsData {
  parentDetails: ParentDetailsType[];
  nameLabelid: string;
  nameDefaultlabel: string;
  relationLabelid: string;
  relationDefaultlabel: string;
  emailLabelid: string;
  emailDefaultlabel: string;
  phoneLabelid: string;
  phoneDefaultlabel: string;
  addressLabelid: string;
  addressDefaultlabel: string;
}
const ParentDetails: FC<ParentDetailsData> = ({
  parentDetails,
  nameLabelid,
  nameDefaultlabel,
  relationLabelid,
  relationDefaultlabel,
  emailLabelid,
  emailDefaultlabel,
  phoneLabelid,
  phoneDefaultlabel,
  addressLabelid,
  addressDefaultlabel,
}) => {
  return (
    <Box sx={{ borderBottom: '1px solid #CFDEDE' }} className="pt-lg pb-xs">
      {parentDetails?.map((item, index) => {
        return (
          <Box key={index}>
            <Box className="flex__">
              <Box className="flex__">
                <MediumTypography
                  labelid={nameLabelid}
                  defaultlabel={nameDefaultlabel}
                  textColor="#97A6A5"
                  fontSize="16px"
                />

                <Tooltip
                  title={
                    item.name && item.name.length > 10 ? `${item.name}` : ''
                  }
                  followCursor
                  arrow={true}
                >
                  <Box className="width150px ">
                    <MediumTypography
                      fontSize="16px"
                      textColor="#2A4241"
                      sxProps={{
                        paddingLeft:
                          item.name === '' || item.name === null
                            ? '24px'
                            : '6px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                      label={
                        item.name === '' || item.name === null
                          ? '-'
                          : `${item.name}`
                      }
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Box className="flex__">
                <MediumTypography
                  className="ml-sm"
                  labelid={relationLabelid}
                  defaultlabel={relationDefaultlabel}
                  textColor="#97A6A5"
                  fontSize="16px"
                />
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  className="pr-lg"
                  sxProps={{
                    paddingLeft:
                      item.relationshipToClient === '' ||
                      item.relationshipToClient === null
                        ? '24px'
                        : '6px',
                  }}
                  label={
                    item.relationshipToClient === '' ||
                    item.relationshipToClient === null
                      ? '-'
                      : item.relationshipToClient
                  }
                />
              </Box>
              <Box className="flex__ ">
                <MediumTypography
                  labelid={emailLabelid}
                  defaultlabel={emailDefaultlabel}
                  textColor="#97A6A5"
                  fontSize="16px"
                />

                <Tooltip
                  title={
                    item.email && item.email.length > 10 ? `${item.email}` : ''
                  }
                  followCursor
                  arrow={true}
                >
                  <Box className="width200px ">
                    <MediumTypography
                      fontSize="16px"
                      textColor="#2A4241"
                      sxProps={{
                        paddingLeft:
                          item.email === '' || item.email === null
                            ? '24px'
                            : '6px',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                      label={
                        item.email === '' || item.email === null
                          ? '-'
                          : `${item.email}`
                      }
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Box className="flex__">
                <MediumTypography
                  labelid={phoneLabelid}
                  defaultlabel={phoneDefaultlabel}
                  textColor="#97A6A5"
                  fontSize="16px"
                  className="pl-lg"
                />
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  sxProps={{
                    paddingLeft:
                      item.homePhoneNumber === '' ||
                      item.homePhoneNumber === null
                        ? '24px'
                        : '6px',
                  }}
                  label={
                    item.homePhoneNumber === '' || item.homePhoneNumber === null
                      ? '-'
                      : item.homePhoneNumber
                  }
                />
              </Box>
            </Box>

            <Box className="flex__ pt-md pb-md ">
              <MediumTypography
                labelid={addressLabelid}
                defaultlabel={addressDefaultlabel}
                textColor="#97A6A5"
                fontSize="16px"
              />

              <Tooltip
                title={
                  item.address && item.address.length > 10
                    ? `${removeDoubleCommas(item.address)}`
                    : ''
                }
                followCursor
                arrow={true}
              >
                <Box className="width300px">
                  <MediumTypography
                    fontSize="16px"
                    textColor="#2A4241"
                    sxProps={{
                      paddingLeft:
                        item.address === '' || item.address === null
                          ? '24px'
                          : '6px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                    label={
                      item.address === '' || item.address === null
                        ? '-'
                        : `${removeDoubleCommas(item.address)}`
                    }
                  />
                </Box>
              </Tooltip>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default ParentDetails;
