import { CircularProgress } from '@mui/material';
import Box from '@mui/joy/Box';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Tooltip } from '@mui/material';

interface Hours {
  workingHours: string | null;
  scheduledHours: string | null;
}
function convertH2M(timeInHour: string) {
  const timeParts = timeInHour.split(':');
  return Number(timeParts[0]) * 60 + Number(timeParts[1]);
}

const ProfileBusssinesschart: FC<Hours> = ({
  workingHours,
  scheduledHours,
}) => {
  let numberValue: number | null;
  let actualNumberValue: number | null = null;

  if (scheduledHours !== null && workingHours !== null) {
    const scheduledMins = convertH2M(scheduledHours);
    const workingMins = convertH2M(workingHours);
    if (workingMins > 0) {
      numberValue = (scheduledMins / workingMins) * 100;
      actualNumberValue = (scheduledMins / workingMins) * 100;
      numberValue = Math.round(numberValue);
      if (numberValue > 100) {
        numberValue = 100;
      }
    } else {
      numberValue = 0;
    }
  } else {
    numberValue = null;
  }

  return (
    <>
      <Tooltip
        title={
          actualNumberValue != null
            ? `${actualNumberValue?.toFixed(2).replace('.00', '')}%`
            : '00.00%'
        }
        placement="bottom"
        sx={{ marginTop: 24 }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 5,
            paddingBottom: 5,
            flexWrap: 'wrap',
            gap: 2,
          }}
        >
          <CircularProgress
            size={90}
            thickness={2}
            sx={{ color: '#D9D9D9', position: 'absolute' }}
            variant="determinate"
            value={100}
          />
          <CircularProgress
            size={90}
            thickness={4}
            sx={{ color: '#37D183', position: 'absolute' }}
            variant="determinate"
            value={
              numberValue != null || numberValue != undefined ? numberValue : 0
            }
          />
          <Box sx={{ position: 'absolute' }}>
            <MediumTypography
              label={
                actualNumberValue != null
                  ? actualNumberValue?.toFixed(0) + '%'
                  : ''
              }
              sxProps={{
                fontSize: '14px',
                fontWeight: '700',
                color: '#2A4241',
              }}
            />
          </Box>
        </Box>
      </Tooltip>
    </>
  );
};

export default ProfileBusssinesschart;
