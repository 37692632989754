import { ChangeEvent, FC, HTMLInputTypeAttribute, ReactNode } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { OptionType } from '../../utils/type';
import TextField, {
  TextFieldVariants,
} from '@mui/material/TextField/TextField';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import { FormattedMessage } from 'react-intl';
import { SxProps } from '@mui/system';
import { ReactComponent as SearchIcon } from '../../assets/images/Search.svg';

interface DropdownFieldProps {
  labelId: string;
  defaultId: string;
  selectedValue: OptionType | null;
  optionHintId?: string;
  data: OptionType[];
  onSelectItem: (value: OptionType | null) => void;
  disableClearable?: boolean;
  sxProps?: SxProps;
  textFieldVariant?: TextFieldVariants;
  disableLabel: boolean;
  disabled?: boolean;
  freeSolo?: boolean;
  autoSelect?: boolean;
  type?: HTMLInputTypeAttribute;
  onTextChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  Required?: boolean;
  className?: string;
  listBoxSxprops?: SxProps;
  placeholderOnfloatBackgroundColor?: string;
  textDecoration?: string;
  renderOption?: (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: OptionType,
  ) => ReactNode;
}

const AutocompleteSearch: FC<DropdownFieldProps> = ({
  labelId,
  defaultId,
  optionHintId,
  data,
  type,
  freeSolo,
  selectedValue,
  autoSelect,
  onSelectItem,
  sxProps,
  textFieldVariant,
  disableLabel,
  disabled,
  onTextChange,
  disableClearable,
  Required,
  className,
  listBoxSxprops,
  placeholderOnfloatBackgroundColor,
  textDecoration,
  renderOption,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      e.target.value = e.target.value.slice(0, 5);
    }
  };

  return (
    <Autocomplete
      className={`consentAutoSearch ${className}`}
      classes={{
        popupIndicator: disabled ? 'disablePopupIcon' : 'enablePopupIcon',
      }}
      sx={{
        width: '100%',
        height: '36px',
        display: 'flex',
        '& .MuiInputLabel-root': {
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
          paddingTop: '2px',
          color: '#97A6A5',
        },
        '& .MuiInput-root .MuiInput-input': {
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
        },
        '& .MuiOutlinedInput-input': {
          padding: '7.5px 4px 7.5px 7px !important',
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
          cursor: disabled ? 'not-allowed' : 'auto',
        },
        '& label.Mui-focused': {
          color: '#00C6B8',
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#00C6B8',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: '#00C6B8',
          },
        },
        '& .MuiAutocomplete-inputRoot': {
          height: '36px',
          padding: '2px 10px',
        },
        '& label.MuiFormLabel-filled': {
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
          color: '#97A6A5',
        },
        ...sxProps,
      }}
      disabled={disabled}
      freeSolo={freeSolo}
      disableClearable={disableClearable}
      options={data}
      autoHighlight
      autoSelect={autoSelect}
      onKeyDown={(event) => {
        if (event.key === 'Enter' && data.length == 0) {
          event.defaultMuiPrevented = true;
        }
      }}
      title={selectedValue !== null ? selectedValue?.label : ''}
      id="combo-box-dropdown"
      value={selectedValue}
      noOptionsText={
        optionHintId && (
          <FormattedMessage id={optionHintId} defaultMessage={defaultId} />
        )
      }
      popupIcon={
        disableLabel ? (
          <SearchIcon style={{ marginRight: '4px' }} />
        ) : (
          <KeyboardArrowDownIcon
            style={{
              color: '#97a6a5',
            }}
          />
        )
      }
      onChange={(
        _: React.SyntheticEvent,
        value: string | OptionType | null,
      ) => {
        if (typeof value !== 'string') {
          onSelectItem(value);
        }
      }}
      ListboxProps={{
        sx: {
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
          wordBreak: 'break-word',
          ...listBoxSxprops,
        },
      }}
      renderOption={renderOption}
      renderInput={({ ...params }) => (
        <TextField
          className="inputSelectFormLabel"
          sx={{
            height: '36px',
            padding: '0px',
          }}
          {...params}
          label={<FormattedMessage id={labelId} defaultMessage={defaultId} />}
          variant={textFieldVariant}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            sx: {
              textDecoration: textDecoration,
            },
          }}
          disabled={disabled}
          type={type}
          onInput={handleInputChange}
          onChange={onTextChange}
          required={Required}
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
        />
      )}
    />
  );
};

export default AutocompleteSearch;
