import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import ButtonComponent from '../ButtonComponent';
import {
  AlertColor,
  Badge,
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  styled,
} from '@mui/material';
import { ReactComponent as EditIcon } from '../../../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/DeleteIcon.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/x.svg';

import { HeadCell, TableHeader } from '../TableHeader';
import TooltipTableCell from '../TooltipTableCell';
import {
  OrganizationListType,
  SspDetailsListType,
  addProviderApi,
  deleteProviderById,
  editProviderApi,
  getAllProviderListAPI,
} from '../../../services/configApi/sspContactList/sspContact';
import { Order } from '../../../utils/type';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import SnackBarComponent from '../SnackBarComponent';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import { EMAIL_REGEX } from '../../../services/Constant';
import { Formik, FormikProps } from 'formik';
import Textinput from '../Textinput';
import {
  formatPhoneNumber,
  nameValidation,
} from '../../../utils/formValidationUtil';
import MediumTypography from '../MediumTypography';
import DropdownComponent from '../DropdownComponent';
import DatePickerComponent from '../DatePickerComponent';
import _, { debounce } from 'lodash';
import ModalPopup from '../ModalPopup';
import SearchBox from '../SearchBox';
import CommentTextbox from '../CommentTextbox';
import { useIntl } from 'react-intl';
import { checkPermissionForFeature } from '../../../utils/checkPermission';
import { datePickerMinDateMaxDateValidate } from '../../../utils/dateUtil';

const CustomBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#37D183',
    marginRight: '10px',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
  },
}));

const CustomBadges = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#CF273C',
    marginRight: '10px',
    height: '7px',
    width: '7px',
    top: 0,
    bottom: 0,
  },
}));

interface ModalProviderProps {
  modalOpen: boolean;
  handleCloses: () => void;
  organizationDetails?: OrganizationListType;
}

interface Data {
  firstName: string;
  jobTitle: string;
  type: string;
  email: string;
  phoneNumber: string;
  isContactActive: string;
  actions: string;
}

const initialHeadCells: HeadCell<Data>[] = [
  {
    id: 'firstName',
    labelId: 'ssp.providerName',
    defaultLabelId: 'Provider Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'jobTitle',
    labelId: 'ssp.jobTitle',
    defaultLabelId: 'Job Title',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'type',
    labelId: 'ssp.type',
    defaultLabelId: 'Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'AddNewReferralDetails.Email',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'Contacts.phone',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'isContactActive',
    labelId: 'ssp.status',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: false,
  },
];

export const Types = [
  {
    id: 'administrative',
    label: 'Administrative',
  },
  {
    id: 'referral',
    label: 'Referral',
  },
  {
    id: 'other',
    label: 'Other',
  },
  {
    id: 'sendIntakes',
    label: 'Send Intakes',
  },
  {
    id: 'catchmentSpecific',
    label: 'Catchment-specific',
  },
];

const initialValue: SspDetailsListType = {
  id: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  type: '',
  email: '',
  phone: '',
  comments: '',
  startDate: dayjs(new Date()),
  endDate: null,
  isContactActive: false,
  organizationId: '',
};

const validationSchema = yup.object().shape({
  firstName: yup.string().required('ssp.providerFirstNameRequiredText'),
  lastName: yup.string().required('ssp.providerLastNameRequiredText'),
  type: yup.string().required('ssp.typeRequiredText'),
  jobTitle: yup.string().nullable().required('ssp.jobTitleRequiredText'),
  comments: yup.string().nullable(),
  email: yup
    .string()
    .matches(EMAIL_REGEX, 'EmailFormat.Validation')
    .email('EmailFormat.Validation')
    .required('SignIn.validation.emailRequired'),
  phone: yup
    .string()
    .min(12, 'PhoneNumber.Validation')
    .required('ContactOthers.phonenumberMandatory'),
  startDate: yup.date().required('startDateRequiredMessage').nullable(),
  endDate: yup.date().nullable(),
});

const validateForm = (value: SspDetailsListType) => {
  const errors: Partial<SspDetailsListType> = {};
  if (value.endDate) {
    if (dayjs(value.endDate).isBefore(dayjs(value.startDate))) {
      errors.endDate = 'endDateGreaterThanStartDate';
    }
    if (datePickerMinDateMaxDateValidate(value.endDate)) {
      errors.endDate = 'datePickerMinDateMaxDateValidate';
    }
  }
  if (value.startDate) {
    if (datePickerMinDateMaxDateValidate(value.startDate)) {
      errors.startDate = 'datePickerMinDateMaxDateValidate';
    }
  }
  return errors;
};

const ProviderList: FC<ModalProviderProps> = ({
  modalOpen,
  handleCloses,
  organizationDetails,
}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('firstName');
  const [providerList, setProviderList] = useState<SspDetailsListType[]>();
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const PAGE_SIZE = 5;
  const [onsearchquery, setOnsearchquery] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [searchquery, setSearchquery] = useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [providerId, setProviderId] = React.useState('');
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [headCells, setHeadCells] = useState(initialHeadCells);
  const [providerValue, setProviderValue] =
    useState<SspDetailsListType>(initialValue);
  const [hideTable, setHideTable] = useState(false);
  const rctl = useIntl();

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getAllProviders = () => {
    toggleLoader(true);
    if (organizationDetails?.id) {
      getAllProviderListAPI(
        pageNumber,
        PAGE_SIZE,
        orderBy,
        order,
        onsearchquery,
        organizationDetails.id,
      )
        .then((response) => {
          setProviderList(response.sspProviderDtoList);
          setPaginationCount(response.count);
          toggleLoader(false);
        })
        .catch(() => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('failedApiMsg');
          setToastrDefaultMessage(
            'Oops, something went wrong. Please try again later.',
          );
        });
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage - 1 !== pageNumber) {
      setPageNumber(newPage - 1);
      setProviderList([]);
    }
  };

  useEffect(() => {
    if (searchTriggered) {
      setOnsearchquery(searchquery);
      setSearchTriggered(false);
    }
    if (!searchquery) {
      setOnsearchquery(searchquery);
    }
  }, [searchquery, searchTriggered]);

  useEffect(() => {
    if (
      headCells.length === 6 &&
      (checkPermissionForFeature('backend.ssp_management', 'editPermission') ||
        checkPermissionForFeature('backend.ssp_management', 'deletePermission'))
    ) {
      setHeadCells((prevHeadCells) => [
        ...prevHeadCells,
        {
          id: 'actions',
          labelId: 'actions',
          defaultLabelId: 'Actions',
          numeric: false,
          requiredSorting: false,
        },
      ]);
    }
    getAllProviders();
  }, [order, orderBy, pageNumber, onsearchquery]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const formHandler = (formValues: SspDetailsListType) => {
    toggleLoader(true);
    addProviderApiCall(formValues);
  };

  const addProviderApiCall = useCallback(
    debounce((formVals: SspDetailsListType) => {
      formVals.startDate = dayjs(formVals.startDate).format('MM/DD/YYYY');
      formVals.organizationId = organizationDetails?.id
        ? organizationDetails.id
        : '';
      addProviderApi(formVals)
        .then(async (response) => {
          if (response) {
            toggleLoader(false);
            setHideTable(false);
            getAllProviders();
            setOpen(true);
            setToastrVariable('success');
            setToastrId('provider.addMessage');
            setToastrDefaultMessage('Provider Added Successfully');
          }
        })
        .catch(async () => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('failedApiMsg');
          setToastrDefaultMessage(
            'Oops, something went wrong. Please try again later.',
          );
        });
    }, 1000),
    [],
  );

  const handleEdit = (editValues: SspDetailsListType) => {
    setHideTable(true);
    setProviderValue(editValues);
  };

  const updateHandler = (updateHandlerVals: SspDetailsListType) => {
    toggleLoader(true);
    updateProviderApiCall(updateHandlerVals);
  };

  const updateProviderApiCall = useCallback(
    debounce((updateVals: SspDetailsListType) => {
      updateVals.startDate = dayjs(updateVals.startDate).format('MM/DD/YYYY');
      const updateId = updateVals.id ? updateVals.id : '';
      editProviderApi(updateId, updateVals)
        .then(async (response) => {
          if (response) {
            toggleLoader(false);
            setHideTable(false);
            getAllProviders();
            setOpen(true);
            setToastrVariable('success');
            setToastrId('provider.updateMessage');
            setToastrDefaultMessage('Provider Updated Successfully');
          }
        })
        .catch(async () => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId('failedApiMsg');
          setToastrDefaultMessage(
            'Oops, something went wrong. Please try again later.',
          );
        });
    }, 1000),
    [],
  );

  const handleDelete = (id: string) => {
    setProviderId(id);
    setShowAlertDialog(true);
  };

  const deleteLog = () => {
    setShowAlertDialog(false);
    toggleLoader(true);
    if (providerId !== '') {
      deleteProviderById(providerId)
        .then(() => {
          getAllProviders();
          setOpen(true);
          setToastrVariable('success');
          setToastrId('Contacts.deleteSuccessToastr');
          setToastrDefaultMessage('Record deleted Successfully');
        })
        .catch(() => {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('Contacts.deleteToastr');
          setToastrDefaultMessage('Failed to delete');
        });
    }
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPageNumber(0);
        setSearchquery(value);
        setSearchTriggered(true);
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
        setToastrDefaultMessage(
          'Only alphanumeric characters, commas, periods, spaces, exclamation marks, and hyphens are allowed to be searched.',
        );
      }
    }, 500),
    [],
  );
  const formRef = useRef<FormikProps<SspDetailsListType>>(null);
  const clearForm = () => {
    formRef.current?.setValues(formRef.current?.initialValues);
    if (formRef.current?.initialValues) {
      setProviderValue(formRef.current?.initialValues);
    }
    setShowAlertDialog(false);
    setOpenModal(false);
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />

      <Box component="main">
        <Dialog
          open={modalOpen}
          onClose={handleCloses}
          fullWidth
          maxWidth={'md'}
        >
          <ModalPopup
            open={openModal}
            description="formUnsavedChangesMessage"
            onCancel={() => setOpenModal(false)}
            onOk={() => clearForm()}
            labelId1="Clientpage.Nobtn"
            negativeActionLabel="No"
            labelId2="Clientpage.Yesbtn"
            positiveActionLabel="Yes"
          />
          {showAlertDialog && (
            <ModalPopup
              open={showAlertDialog}
              onCancel={() => {
                setShowAlertDialog(false);
              }}
              description="CommunicationLogTableView.deleteDescription"
              onOk={() => deleteLog()}
              labelId1="Clientpage.cancelbtn"
              negativeActionLabel="cancelText"
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="deleteText"
            />
          )}
          <DialogTitle>
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Tooltip title={organizationDetails?.organizationName} arrow>
                  <Box>
                    <MediumTypography
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        cursor: 'pointer',
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '26px',
                        alignItems: 'center',
                      }}
                      label={`SSP List - ${organizationDetails?.organizationName}`}
                    />
                  </Box>
                </Tooltip>
              </Grid>
              {!hideTable && (
                <Grid
                  item
                  xs={6}
                  lg={6}
                  className="text-align-end justifyContent-FlexEnd flex__"
                >
                  {(providerList?.length !== 0 ||
                    (providerList?.length === 0 && searchquery !== '')) && (
                    <SearchBox
                      className="mr-md"
                      labelId="provider.searchText"
                      defaultlabel="Search SSP Provider"
                      sxProps={{ minWidth: '250px' }}
                      backgroundColor="rgba(231, 240, 240, 1)"
                      onChange={handleSearchChange}
                    />
                  )}
                  {checkPermissionForFeature(
                    'backend.ssp_management',
                    'addPermission',
                  ) && (
                    <ButtonComponent
                      className="btn-primary btn-submit mr-md"
                      variantType="contained"
                      type="submit"
                      labelId="sspProviderAddBtnText"
                      defaultLabelId="Add SSP Provider"
                      disabled={
                        !checkPermissionForFeature(
                          'backend.ssp_management',
                          'addPermission',
                        )
                      }
                      onClick={() => {
                        setProviderValue(initialValue);
                        setHideTable(true);
                      }}
                    />
                  )}
                  <Grid item className="text-align-end">
                    <CloseIcon
                      className="cursorPointer"
                      onClick={handleCloses}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </DialogTitle>
          <DialogContent>
            {!hideTable && providerList?.length === 0 && searchquery !== '' && (
              <MediumTypography
                labelid="searchnotfound.smallText"
                defaultlabel="We could not find any search results. Give it another go"
                className="text-center"
                sxProps={{ textAlign: 'center' }}
              />
            )}
            {!hideTable && providerList?.length === 0 && searchquery === '' && (
              <MediumTypography
                labelid="provider.noText"
                defaultlabel="No SSP Provider Added Yet."
                className="text-center"
                sxProps={{ textAlign: 'center' }}
              />
            )}
            {!hideTable && providerList && providerList?.length > 0 && (
              <Box>
                <Card>
                  <TableContainer>
                    <Table>
                      <TableHeader
                        className="listDataTableHead"
                        headerNames={headCells}
                        checkBoxRequired={false}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                      />
                      <TableBody className="tableRowcss">
                        {providerList?.map((ssp, sspIndex: number) => {
                          return (
                            <TableRow
                              hover
                              onClick={() => {}}
                              key={ssp.id}
                              sx={{
                                cursor: 'pointer',
                                backgroundColor:
                                  sspIndex % 2 === 0 ? '#ECF9F8' : '#ffffff',
                              }}
                            >
                              <TooltipTableCell
                                value={`${ssp.firstName}  ${ssp.lastName}`}
                                visible={true}
                              />
                              <TooltipTableCell
                                value={ssp.jobTitle ? ssp.jobTitle : '-'}
                                visible={true}
                              />
                              <TooltipTableCell
                                value={
                                  ssp.type
                                    ? Types.find((t) => t.id === ssp.type)
                                        ?.label
                                    : '-'
                                }
                                visible={true}
                              />
                              <TooltipTableCell
                                value={ssp.email ? ssp.email : '-'}
                                visible={true}
                              />
                              <TooltipTableCell
                                value={ssp.phone ? ssp.phone : '-'}
                                visible={true}
                              />
                              <TableCell>
                                <div>
                                  {ssp.endDate &&
                                  ssp.startDate &&
                                  dayjs(ssp.endDate).isBefore(
                                    dayjs().format('MM/DD/YYYY'),
                                  ) ? (
                                    <CustomBadges variant="dot" />
                                  ) : (
                                    <CustomBadge variant="dot" />
                                  )}
                                  {ssp.endDate &&
                                  ssp.startDate &&
                                  dayjs(ssp.endDate).isBefore(
                                    dayjs().format('MM/DD/YYYY'),
                                  )
                                    ? 'In-Active'
                                    : 'Active'}
                                </div>
                              </TableCell>
                              {checkPermissionForFeature(
                                'backend.ssp_management',
                                'editPermission',
                              ) ||
                              checkPermissionForFeature(
                                'backend.ssp_management',
                                'deletePermission',
                              ) ? (
                                <TableCell>
                                  <Box className="flex__ alignItemCenter cursorPointer">
                                    {checkPermissionForFeature(
                                      'backend.ssp_management',
                                      'editPermission',
                                    ) && (
                                      <Box>
                                        <Tooltip title="Edit">
                                          <EditIcon
                                            onClick={() => handleEdit(ssp)}
                                          />
                                        </Tooltip>
                                      </Box>
                                    )}
                                    {checkPermissionForFeature(
                                      'backend.ssp_management',
                                      'deletePermission',
                                    ) && (
                                      <Box className="ml-md cursorPointer">
                                        <DeleteIcon
                                          onClick={() =>
                                            handleDelete(ssp.id ? ssp.id : '')
                                          }
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </TableCell>
                              ) : null}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
                {providerList &&
                  providerList?.length === 0 &&
                  searchquery === '' && (
                    <Box className="bg__white">
                      <MediumTypography
                        textstyle={'center'}
                        labelid="provider.noText"
                        defaultlabel="No SSP Provider Added Yet."
                        paddingstyle="16px"
                      />
                    </Box>
                  )}
                {searchquery !== '' &&
                  providerList &&
                  providerList.length === 0 && (
                    <Box className="bg__white">
                      <MediumTypography
                        textstyle={'center'}
                        labelid="provider.nosearchText"
                        defaultlabel="No Search Found"
                        paddingstyle="16px"
                      />
                    </Box>
                  )}
                {paginationCount > 5 && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      height: '50px',
                    }}
                  >
                    <Grid item>
                      <Pagination
                        boundaryCount={1}
                        page={pageNumber + 1}
                        onChange={handleChangePage}
                        sx={{
                          '& .MuiPaginationItem-root': {
                            fontFamily: 'Lato-Regular',
                            fontWeight: 400,
                            fontSize: '16px',
                            margin: '16px 0px 20px 0px',
                          },
                          '& .MuiPaginationItem-root.Mui-selected': {
                            backgroundColor: '#008C82',
                            color: '#ffffff',
                          },
                        }}
                        shape="circular"
                        count={Math.ceil(paginationCount / 5)}
                        siblingCount={1}
                      />
                    </Grid>
                  </div>
                )}
              </Box>
            )}
            {hideTable && (
              <Box component="div" className="mt-sm">
                <Formik
                  validate={validateForm}
                  initialValues={providerValue}
                  enableReinitialize
                  validateOnChange
                  innerRef={formRef}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    if (values.id) {
                      updateHandler(values);
                    } else {
                      formHandler(values);
                    }
                  }}
                >
                  {({
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    initialValues,
                    handleSubmit,
                  }) => (
                    <Box component="div">
                      <Grid
                        container
                        rowSpacing={'24px'}
                        columnSpacing={'40px'}
                      >
                        <Grid item xs={6}>
                          <Textinput
                            name="firstName"
                            labelid="parentGuardian.firstname"
                            defaultlabelid="First Name"
                            Value={values.firstName}
                            inputProps={{ maxLength: 100 }}
                            handlechange={(text) => {
                              setFieldValue('firstName', nameValidation(text));
                            }}
                            Required
                          />
                          {errors.firstName && touched.firstName && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.firstName}
                              defaultlabel="First Name is Required"
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Textinput
                            name="providerLastName"
                            labelid="parentGuardian.lastname"
                            defaultlabelid="Last Name"
                            Value={values.lastName}
                            inputProps={{ maxLength: 100 }}
                            handlechange={(text) => {
                              setFieldValue('lastName', nameValidation(text));
                            }}
                            Required
                          />
                          {errors.lastName && touched.lastName && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.lastName}
                              defaultlabel="Last Name is Required"
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Textinput
                            name="jobTitle"
                            labelid="ssp.jobTitle"
                            defaultlabelid="Job Title"
                            Value={values.jobTitle}
                            inputProps={{ maxLength: 50 }}
                            handlechange={(text) => {
                              setFieldValue('jobTitle', nameValidation(text));
                            }}
                            Required
                          />
                          {errors.jobTitle && touched.jobTitle && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.jobTitle}
                              defaultlabel="Job Title is Required"
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <DropdownComponent
                            names={Types}
                            Required
                            labelid={'ssp.type'}
                            value={values.type}
                            defaultlabelid="Type"
                            handleChange={(e) => {
                              setFieldValue('type', e);
                            }}
                          />
                          {errors.type && touched.type && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.type}
                              defaultlabel="Type is Required"
                            />
                          )}
                        </Grid>

                        <Grid item xs={6} lg={6}>
                          <Textinput
                            name="email"
                            Value={values.email}
                            labelid="AddNewReferralDetails.Email"
                            defaultlabelid="Email"
                            Required={true}
                            inputProps={{
                              maxLength: 50,
                            }}
                            handlechange={(value: string) => {
                              setFieldValue(`email`, value);
                            }}
                          />
                          {errors.email && touched.email && (
                            <MediumTypography
                              labelid={errors.email}
                              defaultlabel="Invalid Email Address"
                              className="errorText-md"
                            />
                          )}
                        </Grid>

                        <Grid item xs={6} lg={6}>
                          <Textinput
                            name="phoneNo"
                            Value={values.phone}
                            labelid="AddNewReferralDetails.referralPhone"
                            defaultlabelid="Phone"
                            Required
                            inputProps={{
                              maxLength: 50,
                            }}
                            handlechange={(value: string) => {
                              setFieldValue('phone', formatPhoneNumber(value));
                            }}
                          />
                          {errors?.phone && touched && touched.phone && (
                            <MediumTypography
                              labelid={errors.phone}
                              defaultlabel="Phone is Required"
                              className="errorText-md"
                            />
                          )}
                        </Grid>

                        <Grid xs={6} item>
                          <DatePickerComponent
                            name={`startDate`}
                            labelid="Family.startDate"
                            disabledDate={false}
                            defaultlabelid="Start Date"
                            disableFuture={true}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('startDate', null);
                              } else {
                                setFieldValue('startDate', formattedDate);
                              }
                            }}
                            value={dayjs(values.startDate)}
                          />
                          {errors.startDate && touched.startDate && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.startDate}
                              defaultlabel="Start Date is Required"
                            />
                          )}
                        </Grid>
                        <Grid xs={6} item>
                          <DatePickerComponent
                            name={`endDate`}
                            labelid="Family.endDate"
                            defaultlabelid="End Date"
                            minDate={dayjs(values.startDate)}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('endDate', null);
                              } else {
                                setFieldValue('endDate', formattedDate);
                              }
                            }}
                            value={dayjs(values.endDate)}
                          />
                          {errors.endDate && touched.endDate && (
                            <MediumTypography
                              className="errorText-md"
                              labelid={errors.endDate}
                              defaultlabel="End date should be greater than start date"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <CommentTextbox
                            maxLength={1000}
                            rows={2}
                            Value={values.comments}
                            placeholder={rctl.formatMessage({
                              id: 'commentsText',
                            })}
                            handlechange={(value) =>
                              setFieldValue('comments', value)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Box>
                        <Grid
                          className="flex__ justifyContent-FlexEnd pt-md pb-xs"
                          container
                          direction="row"
                          alignItems="right"
                        >
                          <Grid item>
                            <ButtonComponent
                              className="btn-primary btn-cancel"
                              variantType="contained"
                              labelId="Contacts.cancelbtn"
                              defaultLabelId="Cancel"
                              onClick={() => {
                                if (_.isEqual(initialValues, values)) {
                                  setHideTable(false);
                                } else {
                                  setOpenModal(true);
                                }
                              }}
                            />

                            {providerValue.id && (
                              <ButtonComponent
                                className="btn-primary btn-submit ml-md"
                                variantType="contained"
                                type="submit"
                                labelId="clientpage.Update"
                                defaultLabelId="Update"
                                onClick={handleSubmit}
                              />
                            )}
                            {!providerValue.id && (
                              <ButtonComponent
                                className="btn-primary btn-submit ml-md"
                                variantType="contained"
                                type="submit"
                                labelId="Insurance.submit"
                                defaultLabelId="Save & Submit"
                                onClick={handleSubmit}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </Formik>
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </>
  );
};

export default ProviderList;
