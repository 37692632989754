import React, { FC, useEffect, useRef, useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide, { SlideProps } from '@mui/material/Slide';
import MediumTypography from './MediumTypography';
import ButtonComponent from './ButtonComponent';
import { DialogContent } from '@mui/material';

type Direction = 'left' | 'right' | 'up' | 'down';
const Transition = React.forwardRef<unknown, SlideProps>(function Transition(
  props: SlideProps,
  ref: React.Ref<unknown>,
) {
  const { direction: initialDirection = 'right', ...otherProps } = props;
  const [direction, setDirection] = useState<Direction>(initialDirection);
  const directions: Direction[] = ['right', 'left', 'up', 'down'];
  const directionIndexRef = useRef(0);

  useEffect(() => {
    const interval = setInterval(() => {
      directionIndexRef.current =
        (directionIndexRef.current + 1) % directions.length;
      setDirection(directions[directionIndexRef.current]);
    }, 6000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  return <Slide direction={direction || 'right'} ref={ref} {...otherProps} />;
});
interface Dialogtypes {
  open: boolean;
  description?: string;
  contentdescription?: string;
  labelId2: string;
  onOk?: () => void;
}
const AlertDialogSlide: FC<Dialogtypes> = ({
  open,
  description,
  contentdescription,
  labelId2,
  onOk,
}) => {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <MediumTypography
            labelid={description}
            defaultlabel="Clientpage.ModalText"
            fontweight={700}
            textColor="#2a4241"
          />
        </DialogTitle>
        <DialogContent>
          <MediumTypography
            labelid={contentdescription}
            defaultlabel="Clientpage.ModalText"
            fontweight={500}
            textColor="#2a4241"
          />
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            className="btn-primary btn-submit"
            variantType="contained"
            type="submit"
            labelId={labelId2}
            onClick={onOk}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialogSlide;
