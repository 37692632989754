import { Box, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';

import { ReactComponent as EditIcon } from '../../assets/images/highlight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { ServiceDeliveryPlanPropsData } from './ServiceDeliveryPlan';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { OptionType } from '../../utils/type';

interface IfspTable {
  data: ServiceDeliveryPlanPropsData;
  onEdit: () => void;
  onDelete: () => void;
  isIfspScreenLocked?: boolean;
  actionRequired: boolean;
  actionMode?: string;
  reasonIfLate: OptionType[];
  eiService: OptionType[];
  providedBy: OptionType[];
  locationOfService: OptionType[];
  period: OptionType[];
  intensity: OptionType[];
}

const EIServiceTableRow: FC<IfspTable> = ({
  data,
  onEdit,
  onDelete,
  isIfspScreenLocked,
  actionRequired,
  actionMode,
  reasonIfLate,
  eiService,
  intensity,
  locationOfService,
  providedBy,
  period,
}) => {
  return (
    <TableRow>
      <TableCell>
        <TooltipTableCell
          value={
            eiService.find((item) => item.id === data.eiService)?.label ?? '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            providedBy.find((item) => item.id === data.providedBy)?.label ?? '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            locationOfService.find((item) => item.id === data.locationOfService)
              ?.label ?? '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.length ?? '0.00'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={period.find((item) => item.id === data.period)?.label ?? '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.frequency ?? '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            intensity.find((item) => item.id === data.intensity)?.label ?? '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.duration ?? '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          label={data.startDate ? (data.startDate as string) : '-'}
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          label={data.endDate ? (data.endDate as string) : '-'}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            reasonIfLate.find((item) => item.id === data.reasonIfLate)?.label ??
            '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      {actionRequired && (
        <TableCell>
          <Box className="flex__ mt-xs">
            <Box className="mr-md">
              <ActionToolTip labelId="BlockTime.edit" defaultLabel="Edit">
                <EditIcon
                  cursor={
                    (data.endDate &&
                      data.reasonIfLate &&
                      data.isRecordSubmitted) ||
                    isIfspScreenLocked
                      ? 'not-allowed'
                      : 'pointer'
                  }
                  onClick={() =>
                    (data.endDate &&
                      data.reasonIfLate &&
                      data.isRecordSubmitted) ||
                    isIfspScreenLocked
                      ? null
                      : onEdit()
                  }
                />
              </ActionToolTip>
            </Box>
            {actionMode === 'add' ? (
              <Box component="div">
                <ActionToolTip
                  labelId="ConsentForms.listActionDeleteToolTipLabel"
                  defaultLabel="Delete"
                >
                  <DeleteIcon
                    cursor={
                      data.tbrServicePlanId > 0 ||
                      data.isRecordSubmitted ||
                      isIfspScreenLocked
                        ? 'not-allowed'
                        : 'pointer'
                    }
                    onClick={() =>
                      data.tbrServicePlanId > 0 ||
                      data.isRecordSubmitted ||
                      (data.endDate &&
                        data.reasonIfLate &&
                        data.isRecordSubmitted) ||
                      isIfspScreenLocked
                        ? null
                        : onDelete()
                    }
                  />
                </ActionToolTip>
              </Box>
            ) : null}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

export default EIServiceTableRow;
