// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dots-5 {
  width: 6px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: d5 1s infinite linear alternate;
}
@keyframes d5 {
  0% {
    box-shadow: 20px 0 #97a6a5, -20px 0 #37d183;
    background: #97a6a5;
  }
  33% {
    box-shadow: 20px 0 #97a6a5, -20px 0 #37d183;
    background: #37d183;
  }
  66% {
    box-shadow: 20px 0 #37d183, -20px 0 #97a6a5;
    background: #37d183;
  }
  100% {
    box-shadow: 20px 0 #37d183, -20px 0 #97a6a5;
    background: #97a6a5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProgressNote/listeningDot.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe;EACf,kBAAkB;EAClB,0CAA0C;AAC5C;AACA;EACE;IACE,2CAA2C;IAC3C,mBAAmB;EACrB;EACA;IACE,2CAA2C;IAC3C,mBAAmB;EACrB;EACA;IACE,2CAA2C;IAC3C,mBAAmB;EACrB;EACA;IACE,2CAA2C;IAC3C,mBAAmB;EACrB;AACF","sourcesContent":[".dots-5 {\n  width: 6px;\n  aspect-ratio: 1;\n  border-radius: 50%;\n  animation: d5 1s infinite linear alternate;\n}\n@keyframes d5 {\n  0% {\n    box-shadow: 20px 0 #97a6a5, -20px 0 #37d183;\n    background: #97a6a5;\n  }\n  33% {\n    box-shadow: 20px 0 #97a6a5, -20px 0 #37d183;\n    background: #37d183;\n  }\n  66% {\n    box-shadow: 20px 0 #37d183, -20px 0 #97a6a5;\n    background: #37d183;\n  }\n  100% {\n    box-shadow: 20px 0 #37d183, -20px 0 #97a6a5;\n    background: #97a6a5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
