import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  OutlinedInput,
} from '@mui/material';
import React, { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SmallTypography from '../SmallTypography';
import MediumTypography from '../MediumTypography';
import { right } from '@popperjs/core';
import ButtonComponent from '../ButtonComponent';
import { ChangePinType, changePinApi } from '../../../services/SignatureApi';
import { EmployeeFormData } from '../../../pages/employees/types';
import { ResponseType } from '../../../utils/type';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';

interface ForgotPinInterface {
  open: boolean;
  handleClose: () => void;
  employeeDetails: EmployeeFormData;
  onSuccess: (successerror: ResponseType, id: string, message: string) => void;
  handlePin: (pin: string) => void;
}

const temporaryPinPlaceholder = (
  <FormattedMessage id="temporaryPinText" defaultMessage="Temporary PIN" />
);

const confirmPinPlaceholder = (
  <FormattedMessage
    id="employeeSig.enterConfirmPinText"
    defaultMessage="Confirm PIN"
  />
);

const pinPlaceholder = (
  <FormattedMessage id="employeeSig.enterPinText" defaultMessage="New PIN" />
);

const ResetPinModal: FC<ForgotPinInterface> = ({
  open,
  handleClose,
  employeeDetails,
  onSuccess,
  handlePin,
}) => {
  const [pin, setPin] = useState<string>('');
  const [confirmPin, setConfirmPin] = useState<string>('');
  const [temporaryPin, setTemporaryPin] = useState<string>('');
  const [error, setError] = useState<boolean>(true);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.trimStart().replace(/\D/g, '');
    setPin(value);
    setConfirmPin('');
  };

  const handleConfirmPinChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event.target.value.trimStart().replace(/\D/g, '');
    setConfirmPin(value);

    if (value.length <= 4) {
      if (value !== pin) {
        setError(true);
      } else {
        setError(false);
      }
    } else {
      setError(true);
    }
  };

  const handleTemporaryPinChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const tempValue = event.target.value.trimStart().replace(/\D/g, '');
    setTemporaryPin(tempValue);
  };

  const resetPinFunc = () => {
    const resetObj: ChangePinType = {
      oldPin: temporaryPin,
      newPin: pin,
      reset: true,
    };
    toggleLoader(true);
    changePinApi(employeeDetails.id, resetObj)
      .then(() => {
        toggleLoader(false);

        onSuccess('success', 'resetPinSuccessMsg', 'Reset Pin Successfully');
        handlePin(pin);
        handleClose();
      })
      .catch((err) => {
        toggleLoader(false);

        if (isCustomError(err)) {
          const apiError = err as ApiError;
          onSuccess('error', apiError.id, apiError.message);
        } else {
          const errorMessage = err as ApiMessage;
          if (errorMessage.code === 401) {
            onSuccess('error', 'invalidPinErrorMesg', 'Invalid Pin');
          } else {
            onSuccess('error', 'resetPinErrorMsg', 'Failed to Reset Pin');
          }
        }
      });
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <Box>
          <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
            <Grid item xs={6}>
              <MediumTypography
                labelid={'resetDailogText'}
                defaultlabel="Reset / Forgot PIN"
                fontweight={700}
                fontSize="24px"
                textColor="#2A4241"
              />
            </Grid>
            <Grid item xs={6} textAlign={right}></Grid>
            <Grid item xs={12}>
              <MediumTypography
                labelid={'temporaryPinModalText'}
                defaultlabel="Your temporary PIN has sent to your registered email"
                fontweight={600}
                fontSize="18px"
                textColor="#2A4241"
              />
            </Grid>
          </Grid>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Grid container rowSpacing={'24px'}>
            <Grid item xs={6} lg={6}>
              <form noValidate autoComplete="off">
                <FormControl
                  sx={{
                    '&:hover': {
                      border: 'none',
                    },
                  }}
                >
                  <OutlinedInput
                    type="password"
                    placeholder={temporaryPinPlaceholder.props.defaultMessage}
                    value={temporaryPin}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      maxLength: 4,
                    }}
                    onChange={handleTemporaryPinChange}
                    sx={{
                      fontSize: '14px',
                      width: '270px',
                      fontFamily: 'Lato-Regular',
                      fontWeight: '500',
                      height: '40px',
                      background: 'rgba(255, 255, 255, 1)',
                      '.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notChedOutline':
                        {
                          borderColor: 'none',
                        },
                      '&:hover': {
                        borderColor: 'none',
                      },

                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00c6b8',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00c6b8',
                      },
                    }}
                  />
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6} lg={6}>
              <form noValidate autoComplete="off">
                <FormControl
                  sx={{
                    '&:hover': {
                      border: 'none',
                    },
                  }}
                >
                  <OutlinedInput
                    type="password"
                    placeholder={pinPlaceholder.props.defaultMessage}
                    value={pin}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      maxLength: 4,
                    }}
                    onChange={handleChange}
                    sx={{
                      fontSize: '14px',
                      width: '270px',
                      fontFamily: 'Lato-Regular',
                      fontWeight: '500',
                      height: '40px',
                      background: 'rgba(255, 255, 255, 1)',
                      '.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notChedOutline':
                        {
                          borderColor: 'none',
                        },
                      '&:hover': {
                        borderColor: 'none',
                      },

                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00c6b8',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00c6b8',
                      },
                    }}
                  />
                </FormControl>
              </form>
            </Grid>
            <Grid item xs={5} lg={5}>
              <form noValidate autoComplete="off">
                <FormControl
                  sx={{
                    '&:hover': {
                      border: 'none',
                    },
                  }}
                >
                  <OutlinedInput
                    type="password"
                    placeholder={confirmPinPlaceholder.props.defaultMessage}
                    value={confirmPin}
                    inputProps={{
                      autoComplete: 'one-time-code',
                      maxLength: 4,
                    }}
                    onChange={handleConfirmPinChange}
                    sx={{
                      fontSize: '14px',
                      width: '275px',
                      fontFamily: 'Lato-Regular',
                      fontWeight: '500',
                      height: '40px',
                      background: 'rgba(255, 255, 255, 1)',
                      '.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notChedOutline':
                        {
                          borderColor: 'none',
                        },
                      '&:hover': {
                        borderColor: 'none',
                      },

                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00c6b8',
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#00c6b8',
                      },
                    }}
                  />
                  {error && confirmPin.length === 4 && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId="employeeSig.pinDoesnotMatchText"
                      defaultLabelId="Pins do not match"
                    />
                  )}
                </FormControl>
              </form>
            </Grid>
          </Grid>
          <Box>
            <Grid
              className="flex__ justifyContent-FlexEnd pt-md"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-cancel  mr-md"
                  variantType="contained"
                  labelId="Contacts.cancelbtn"
                  defaultLabelId="Cancel"
                  onClick={handleClose}
                />
                <ButtonComponent
                  className="btn-primary btn-submit"
                  variantType="contained"
                  type="submit"
                  labelId="buttonChangeText"
                  disabled={
                    error ||
                    pin.length !== 4 ||
                    confirmPin.length !== 4 ||
                    temporaryPin.length !== 4
                  }
                  defaultLabelId="Change"
                  onClick={resetPinFunc}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ResetPinModal;
