// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_padding > div > div > table > thead > tr > th:first-child {
  padding-left: 20px;
}
.table_padding > div > div > table > tbody > tr > td:first-child {
  padding-left: 22px;
}
.textarea > div > div > textarea {
  padding-left: 0px !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/communicationLog/CommunicationLogPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".table_padding > div > div > table > thead > tr > th:first-child {\n  padding-left: 20px;\n}\n.table_padding > div > div > table > tbody > tr > td:first-child {\n  padding-left: 22px;\n}\n.textarea > div > div > textarea {\n  padding-left: 0px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
