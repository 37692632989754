import React, { FC, useContext } from 'react';
import { Modal, Grid, Box } from '@mui/material';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { FormattedMessage } from 'react-intl';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';

interface UnavailabilityCheckFieldProps {
  openAvailabilityCheckModel: boolean;
  closeUnavailabilityModal: (
    justCloseWithoutAnySelection: boolean,
    proceedWithUnavailabilites: boolean,
  ) => void;
}

const styledelmodel = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 410,
  height: 'auto',
  bgcolor: 'background.paper',
  padding: '20px 20px 20px 20px',
  maxHeight: '225px',

  outline: 'none',
  fontFamily: 'Lato-Regular',
};

const UnavailabilityCheckModal: FC<UnavailabilityCheckFieldProps> = ({
  openAvailabilityCheckModel,
  closeUnavailabilityModal,
}) => {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  return (
    <>
      <Modal
        open={openAvailabilityCheckModel}
        onClose={() => {
          closeUnavailabilityModal(true, false);
        }}
      >
        <Box sx={styledelmodel}>
          <h2
            style={{
              color: '#2A4241',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '16px',
              marginBottom: '0px',
              marginTop: '5px',
              fontFamily: 'Lato-Bold',
            }}
          >
            <FormattedMessage
              id="AddNewSchedulerEvent.unavailableAttendeesModelTitle"
              defaultMessage="Some Attendees are not available at the selected time. Do you still want to proceed?"
            />
          </h2>

          <Grid
            container
            direction="row"
            alignItems="right"
            textAlign={'right'}
          >
            <Grid item xs={12} textAlign={'right'}>
              <Grid mr={2} mt={5}>
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  variantType="contained"
                  labelId="AddNewSchedulerEvent.unavailableAttendeesModelTitlenoButton"
                  defaultLabelId="No"
                  onClick={() => {
                    closeUnavailabilityModal(false, false);
                  }}
                />
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  variantType="contained"
                  type="submit"
                  labelId="AddNewSchedulerEvent.unavailableAttendeesModelTitleyesButton"
                  defaultLabelId="Yes"
                  onClick={() => {
                    toggleLoader(true);
                    closeUnavailabilityModal(false, true);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
export default UnavailabilityCheckModal;
