import { Box, Chip } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC } from 'react';
import { ServiceCoordinator } from './CaseloadEmployeesDropdown';
import { ReactComponent as CloseIcon } from '../../assets/images/Close.svg';

interface CaseloadFilterProps {
  items: ServiceCoordinator[];
  onItemDelete: (item: ServiceCoordinator) => void;
  clearAll: () => void;
}

const CaseloadFilter: FC<CaseloadFilterProps> = ({
  clearAll,
  items,
  onItemDelete,
}) => {
  return (
    <Box display="flex">
      <MediumTypography
        labelid="clear.btn"
        defaultlabel="Clear"
        sxProps={{
          color: '#008C82',
          fontWeight: 'bold',
          cursor: 'pointer',
        }}
        onClick={clearAll}
      />

      <Box display="flex" className="pl-md">
        <MediumTypography
          labelid="staffText"
          defaultlabel="Staff:"
          sxProps={{ color: '#97A6A5' }}
        />
        <Box>
          {items.map((item) => (
            <Chip
              key={item.id}
              label={item.label}
              deleteIcon={<CloseIcon />}
              size="small"
              onDelete={() => {
                onItemDelete(item);
              }}
              sx={{ backgroundColor: 'transparent' }}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default CaseloadFilter;
