import {
  AlertColor,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from '@mui/material';
import React, { FC, useContext, useEffect, useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import ButtonComponent from '../ButtonComponent';
import MediumTypography from '../MediumTypography';
import imageCompression from 'browser-image-compression';

import { convertFileToBase64 } from '../../../utils/fileUtils';
import {
  getEmployeeSignatureBasedOnPin,
  getSignature,
} from '../../../services/SignatureApi';
import { SignatureStateType } from '../../../utils/type';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import { FormattedMessage } from 'react-intl';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../../services/ApiResponseHandler';
import Textinput from '../Textinput';
import Loader from '../../shared/Loader';

interface CustomOptionsType {
  maxSizeMB?: number;
  maxWidthOrHeight?: number;
  useWebWorker?: boolean;
  maxIteration?: number;
  exifOrientation?: number;
}

interface SignatureModule {
  labelId?: string;
  defaultLabelId?: string;
  signature?: string;
  onCancelClick: () => void;
  signaturePartitionKey: string;
  id?: string;
  signatureRowKey: string;
  signatureCallBack?: (res: SignatureStateType[]) => void;
  signatureSaveOrUPdate?: (value: string) => void;
  type?: string;
  handleSuccess: (
    successerror: AlertColor,
    id: string,
    message: string,
  ) => void;
  resetCallBackForTypeEmployee?: () => void;
  signClicked: boolean;
  hideRemoveButton?: boolean;
  hideResetButton?: boolean;
}

const SignatureComponent: FC<SignatureModule> = ({
  labelId,
  defaultLabelId,
  signature,
  onCancelClick,
  signaturePartitionKey,
  id,
  signatureRowKey,
  signatureCallBack,
  signatureSaveOrUPdate,
  type,
  handleSuccess,
  resetCallBackForTypeEmployee,
  signClicked,
  hideRemoveButton,
  hideResetButton,
}) => {
  const sigRef = useRef<SignatureCanvas>(null);
  const [isSignatureDrawn, setIsSignatureDrawn] =
    React.useState<boolean>(false);
  const [signatureBase64, setSignatureBase64] = React.useState<string>(
    signature ?? '',
  );
  const [signatureLoading, setSignatureLoading] =
    React.useState<boolean>(false);
  const [resetClick, setResetClick] = React.useState<boolean>(false);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [showSignatureModule, setShowSignatureModule] =
    React.useState<boolean>(false);
  const [pin, setPin] = React.useState<string>('');
  const [loader, setLoader] = React.useState<boolean>(false);
  const pinPlaceholder = (
    <FormattedMessage
      id="employeeSig.enterPinText"
      defaultMessage="Enter PIN"
    />
  );

  const onSignClicked = () => {
    setResetClick(false);

    if (
      signaturePartitionKey !== '' &&
      signatureRowKey !== '' &&
      signaturePartitionKey &&
      signatureRowKey &&
      signature === ''
    ) {
      toggleLoader(true);
      const userId = id ?? localStorage.getItem('userId');
      if (userId) {
        setSignatureLoading(true);
        getSignature([
          {
            id: userId,
            partitionkey: signaturePartitionKey,
            rowkey: signatureRowKey,
          },
        ])
          .then((res: SignatureStateType[]) => {
            toggleLoader(false);
            setSignatureLoading(false);
            setSignatureBase64(res[0].signaturedata);
            if (signatureCallBack) {
              signatureCallBack(res);
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setSignatureLoading(false);
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              handleSuccess('error', apiError.id, apiError.message);
            } else {
              handleSuccess(
                'error',
                'Ifsp.failedToGetSignature',
                'Failed to get signature',
              );
            }
          });
      }
    }
  };

  const clearSignature = () => {
    if (type === 'Employee') {
      if (resetCallBackForTypeEmployee) {
        resetCallBackForTypeEmployee();
      }
    }
    setResetClick(true);
    setIsSignatureDrawn(false);

    if (sigRef.current) {
      sigRef.current.clear();
    }
  };

  useEffect(() => {
    if (type === 'Employee') {
      if (signatureBase64 === '' && signaturePartitionKey === '') {
        setShowSignatureModule(true);
      } else {
        setShowSignatureModule(false);
      }
    } else {
      setShowSignatureModule(false);
    }
    onSignClicked();
  }, [type]);

  useEffect(() => {
    if (signature) {
      setSignatureBase64(signature);
    }
  }, [signature]);

  const onSignatureEnd = async () => {
    if (sigRef.current) {
      setIsSignatureDrawn(true);
      const dataURL = sigRef.current.toDataURL();

      const blob = await fetch(dataURL).then((res) => res.blob());
      const files = new File([blob], 'compressed-image.png', {
        type: 'image/png',
      });
      const compressedDataURL = await imageCompression(files, {
        maxSizeMB: 0.03, // Adjust the maximum file size as needed
        maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
        fileType: 'image/jpeg',
      } as CustomOptionsType);

      const base64String = await convertFileToBase64(compressedDataURL);
      setResetClick(true);
      setSignatureBase64(base64String);
    }
  };

  const handlePinChange = (value: string) => {
    const newValue = value.trimStart().replace(/\D/g, '');
    setPin(newValue);
  };

  const confirmPin = () => {
    toggleLoader(true);
    setLoader(true);
    setShowSignatureModule(false);

    const userId = id ?? localStorage.getItem('userId');
    if (userId) {
      getEmployeeSignatureBasedOnPin(userId, pin)
        .then((res) => {
          toggleLoader(false);
          setLoader(false);

          setIsSignatureDrawn(true);
          setSignatureBase64(res.signaturedata);
        })
        .catch((error) => {
          toggleLoader(false);
          setLoader(false);

          onCancelClick();
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            handleSuccess('error', apiError.id, apiError.message);
          } else {
            const errorMessage = error as ApiMessage;
            if (errorMessage.code === 401) {
              handleSuccess('error', errorMessage.message, 'Invalid Pin');
            } else {
              handleSuccess(
                'error',
                'Ifsp.failedToGetSignature',
                'Failed to get signature',
              );
            }
          }
        });
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && pin.length === 4) {
      confirmPin();
    }
    if (event.key === 'Enter') {
      event.stopPropagation(); // Stop event propagation to prevent closing
    }
  };

  return (
    <Dialog
      open={signClicked}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={showSignatureModule ? 'sm' : 'lg'}
      keepMounted
      className={signClicked ? 'custom-dialog' : ''}
      onKeyDown={handleKeyPress}
    >
      <DialogContent
        id="dailogContent"
        sx={{
          overflow: 'hidden',
          width: showSignatureModule ? '600px ' : '926px ',
          maxWidth: showSignatureModule ? '600px ' : '926px',
        }}
      >
        <DialogContentText
          id="alert-dialog-description"
          sx={{
            overflowY: 'hidden',
            overflowX: 'hidden',
          }}
        ></DialogContentText>

        {!showSignatureModule && (
          <Box component="div">
            {loader && <Loader />}
            <Box
              component="div"
              className="flex__justify__space-between pb-xs "
            >
              <MediumTypography
                labelid={
                  type === 'Employee'
                    ? 'EmployeeSignatureText'
                    : 'Insurance.Parentsignature'
                }
                defaultlabel={
                  type === 'Employee'
                    ? 'Employee Signature'
                    : 'Parent Signature'
                }
              />
              {type !== 'Employee' && !hideResetButton && (
                <ButtonComponent
                  className="resetLinkSignature pr-xs"
                  variantType="contained"
                  type="submit"
                  labelId="resetSignatureText"
                  defaultLabelId="Reset Signature"
                  onClick={clearSignature}
                />
              )}
              {type === 'Employee' &&
                !isSignatureDrawn &&
                !hideRemoveButton && (
                  <ButtonComponent
                    className="resetLinkSignature pr-xs"
                    variantType="contained"
                    type="submit"
                    labelId="removeSignatureText"
                    defaultLabelId="Remove Signature"
                    onClick={clearSignature}
                  />
                )}
            </Box>

            {signatureBase64 !== null &&
              signatureBase64 !== '' &&
              !resetClick && (
                <Button id="signaturePreview">
                  <img
                    src={signatureBase64}
                    alt="Signature"
                    style={{ width: '100%', height: '110px' }}
                  />
                </Button>
              )}

            {(signatureBase64 === null ||
              signatureBase64 === '' ||
              resetClick) && (
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className: `signature_employee ${
                    signatureLoading ? 'disablePointerEvents' : 'pointerEvents'
                  }`,
                }}
                ref={sigRef}
                onEnd={onSignatureEnd}
                clearOnResize={false}
              />
            )}
            <DialogActions sx={{ padding: '20px 0px' }} className="pb-none">
              <ButtonComponent
                className="btn-primary btn-cancel btn_width_90px"
                labelId={'cancelId'}
                defaultLabelId={'Cancel'}
                onClick={onCancelClick}
              />

              {signatureBase64 && !resetClick && (
                <ButtonComponent
                  className="btn-primary btn-submit ml-md btn_width_90px"
                  labelId={'ProgressNote.Save'}
                  defaultLabelId={'Save'}
                  onClick={() => {
                    if (signatureSaveOrUPdate) {
                      signatureSaveOrUPdate(signatureBase64);
                    }
                  }}
                  disabled={!isSignatureDrawn}
                />
              )}
              {signatureBase64 && resetClick && (
                <ButtonComponent
                  className="btn-primary btn-submit ml-md btn_width_90px"
                  labelId={'ProgressNote.Update'}
                  defaultLabelId={'Update'}
                  onClick={() => {
                    if (signatureSaveOrUPdate) {
                      signatureSaveOrUPdate(signatureBase64);
                    }
                  }}
                  disabled={!isSignatureDrawn}
                />
              )}
            </DialogActions>
          </Box>
        )}
        {showSignatureModule && (
          <Box component="div">
            <Box
              component="div"
              className="flex__justify__space-between pb-xs "
            >
              <MediumTypography
                labelid="employeeSig.enterPinText"
                defaultlabel="Enter Pin"
                fontweight={700}
              />
            </Box>
            <Grid
              container
              className="flex__ align__items__center justifyContent-Center"
              sx={{ marginTop: '3%' }}
            >
              <Textinput
                autoFocus={true}
                name="pin"
                labelid={labelId}
                defaultlabelid={defaultLabelId}
                Value={pin}
                type="password"
                inputProps={{ maxLength: 4 }}
                handlechange={(text: string) => {
                  handlePinChange(text);
                }}
                sxProps={{
                  backgroundColor: 'white',
                }}
                placeHolder={pinPlaceholder.props.defaultMessage}
              />
            </Grid>
            <DialogActions sx={{ padding: '20px 0px' }} className="pb-none">
              <ButtonComponent
                className="btn-primary btn-cancel btn_width_90px"
                labelId={'cancelId'}
                variantType="contained"
                type="submit"
                defaultLabelId={'Cancel'}
                onClick={onCancelClick}
              />

              <ButtonComponent
                className="btn-primary btn-submit ml-md btn_width_90px"
                labelId={'confirmTextMsg'}
                variantType="contained"
                type="submit"
                defaultLabelId={'Confirm'}
                onClick={() => {
                  confirmPin();
                }}
                disabled={pin.length !== 4}
              />
            </DialogActions>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SignatureComponent;
