import { Dayjs } from 'dayjs';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export interface ReferralFormData {
  dateOfReferral: string | null | Dayjs;
  referralFor: string;
  othersDesc: string;
  referralToName: string[];
  sspAgency: string;
}
export interface ReferralContact {
  contactId?: string;
  name: string;
  homePhoneNumber: string;
  isPrimary?: boolean;
  type?: string;
  relationshipToClient: string;
  email: string;
  address: string;
}
export interface ReferralToSSPData {
  programDetails: {
    name: string;
    imageUrl: string;
    email?: string;
    phoneNumber: string;
    faxNumber: string;
    addressLine1?: string;
    addressLine2?: string;
    website: string;
    programDirector?: string;
  };
  client: {
    clientId?: string;
    name: string;
    sex: string;
    dphId: string;
    dob: string;
    referralBeginDate: string;
    doe: string;
    serviceCoordinator: {
      name: string;
      email: string;
      phone: string;
    };
    teamLeader?: string;
  };
  contact: ReferralContact[];
  referral: ReferralFormData[];
  formToAdd: string[];
  comments: string;
  selectedContact: string;
}

export interface ReferralToSSPById {
  specify: string;
  id: string;
  programDetails: ProgramDetails;
  clientInfo: ClientInfo;
  parent: Parent;
  selectedContact: string;
  dateOfReferral: string;
  referralFor: string;
  agency: string;
  ssp: string;
  comment: string;
  contact: Contact[];
  organization?: Organization[];
  agencyId: string,
  sspId: string
}

export interface ProgramDetails {
  name: string;
  imageUrl: string;
  email: string;
  phoneNumber: string;
  faxNumber: string;
  addressLine1: string;
  addressLine2: string;
  website: string;
  programDirector: string;
}

export interface ClientInfo {
  name: string;
  sex: string;
  enrollmentId: string;
  dob: string;
  referralBeginDate: string;
  doe: string;
  teamLeader: string;
  serviceCoordinator: string;
  email: string;
  phone: string;
}

export interface Parent {
  id: string;
  name: string;
  relationship: string;
  email: string;
  phone: string;
  address: string;
}

export interface Contact {
  contactId: string;
  name: string;
  homePhoneNumber: string;
  isPrimary: boolean;
  type: string;
  relationshipToClient: string;
  email: string;
  address: string;
}

export interface Organization {
      organizationId: string;
      organizationName: string;
      treatmentType: string;
      treatmentName: string;
      providers: [
        {
          providerId: string;
          firstName: string;
          lastName: string;
        },
      ];
}

export interface SSPList {
  organization: [
    {
      organizationId: string;
      organizationName: string;
      treatmentType: string;
      treatmentName: string;
      providers: [
        {
          providerId: string;
          firstName: string;
          lastName: string;
        },
      ];
    },
  ];
}

export interface ReferralSSP {
  ssp: string;
  id: string;
  dateOfReferral: string; 
  referralFor: string;
  othersDesc: string;
  agency: string;
  referralToName: string[];
  selectedContact: string | null;
}

export interface SSPListGrid {
  count: number;
  sspNotificationList: ReferralSSP[];
}
export const getSSPList = async (localTimezone: string): Promise<SSPList> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<SSPList>(`provider?timezone=${localTimezone}`),
  );
};

export const getAllSSPList = async (
  page: number,
  size: number,
  sortType: string,
  search: string,
  sortName: string,
) : Promise<SSPListGrid> => { 
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `ssp/notification/all?&page=${page}&size=${size}&sortField=${sortName}&sortType=${sortType}&search=${search}`,
    ),
  );
};

export const getReferralToSSPForm = async (id: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ReferralToSSPData>(
      `client/referralToSsp?clientId=${id}`,
    ),
  );
};

export const getSSPNotificationById = async (clientId: string, id: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ReferralToSSPById>(
      `ssp/notification?clientId=${clientId}&id=${id}`,
    ),
  );
};

export const defaultNotificationSSP = async (clientId: string | null,   localTimezone: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ReferralToSSPById>(
      `ssp/notification/default-data?clientId=${clientId}&timezone=${localTimezone}`,
    ),
  );
};

export const defaultNotificationGetById = async (id: string, clientId: string | null,   localTimezone: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ReferralToSSPById>(
      `ssp/notification/${id}?clientId=${clientId}&timezone=${localTimezone}`,
    ),
  );
};

export const addReferralToSSPForm = async (
  id: string,
  data: ReferralToSSPData,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `client/referralToSsp?clientId=${id}`,
      data,
    ),
  );
};

export const addReferralToSSpID = async (
  clientId: string,
  id: string,
  data: ReferralToSSPById,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `ssp/notification?clientId=${clientId}&id=${id}`,
      data,
    ),
  );
};

export const deleteSSPGridLineItem = async (id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `ssp/notification?id=${id}`,
    ),
  );
};