import { Box, Button, Menu, MenuItem } from '@mui/material';
import { ReactComponent as Filtericon } from '../../assets/images/FilterIconsvg.svg';
import { ReactComponent as DownChevron } from '../../assets/images/downChevron.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/x.svg';

import MediumTypography from './MediumTypography';
import { useState, FC } from 'react';

interface FilterProp {
  items: string[];
  hintTextId: string;
  value: string | null;
  onSelect?: (value: string | null) => void;
}

const FilterSelection: FC<FilterProp> = ({
  hintTextId,
  value,
  items,
  onSelect,
}) => {
  const [selectedValue, setSelectedValue] = useState<string | null>(value);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterClose = () => {
    setAnchorEl(null);
  };

  const handleCancelClick = () => {
    setSelectedValue(null);
    if (onSelect !== undefined) {
      onSelect(null);
    }
  };

  return (
    <Box display={'flex'} alignItems={'center'}>
      <Button
        sx={{
          borderRadius: '4px',
          border: '1px solid #008C82',
          backgroundColor: 'white',
          cursor: 'pointer',
          height: '32px',
          textTransform: 'none',
          justifyContent: 'center',
          padding: '16px',
        }}
        onClick={handleFilterClick}
        startIcon={<Filtericon />}
        endIcon={<DownChevron />}
      >
        <MediumTypography
          labelid={selectedValue ?? hintTextId}
          defaultlabel="Filter"
          sxProps={{
            color: '#2A4241',
            fontSize: '14px',
          }}
        />
      </Button>
      <Menu
        id="menu-filter"
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleFilterClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              setSelectedValue(item);
              handleFilterClose();
              if (onSelect !== undefined) {
                onSelect(item);
              }
            }}
          >
            <MediumTypography labelid={item} />
          </MenuItem>
        ))}
      </Menu>
      {selectedValue && (
        <Box
          display={'flex'}
          alignItems={'center'}
          padding={'4px 4px 4px 12px'}
          sx={{ cursor: 'pointer' }}
          onClick={handleCancelClick}
        >
          <CloseIcon />
        </Box>
      )}
    </Box>
  );
};

export default FilterSelection;
