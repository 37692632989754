import React, { createContext, useEffect } from 'react';
import { Box, Toolbar, Typography } from '@mui/material';

import TitleText from '../../components/formlib/TitleText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useLocation, useNavigate } from 'react-router';
import {
  DIAGNOSTIC_ADD_CATEGORY_NEW,
  SERVICE_ADD_CODES,
  SETTING_ADD_CODES,
} from '../../routes/Routing';
import ServiceCodesList from './serviceCodes/ServiceCodesList';
import SettingCodesList from './settingCodes/SettingCodeList';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { EventBusProvider } from '../../context/EventBusProvider';
import DiagnosisCodesList from './serviceCodes/DiagnosisCodesListNew';

export interface DiagnosticSearchValueType {
  diagnosticSearchValue: (value: string) => void;
}

export const ClientContext = createContext<
  DiagnosticSearchValueType | undefined
>(undefined);

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00C6B8',
          height: 2,
          bottom: '0',
        },
      },
    },
  },
});

export interface TabProps {
  tabPosition: number;
}

const CustomTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    color: '#00C6B8',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

const CustomTabs = styled(Tabs)({
  '& .MuiButtonBase-root.MuiTab-root': {
    padding: '15px 0px',
    maxWidth: '100%',
    minWidth: 'auto',
    margin: '0px 12px',
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    lineHeight: '20px',
  },
  '&.Mui-selected': {
    color: '#008C82',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
  },
});

function TabPanel(props: TabPanelProps) {
  const { children, value, className, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={className}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

function CodelistSetup() {
  const [tabProp, setTabProp] = React.useState<TabProps>({
    tabPosition: 0,
  });

  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (
    _event: React.ChangeEvent<object>,
    position: number,
  ) => {
    setTabProp({ ...tabProp, tabPosition: position });
  };

  useEffect(() => {
    if (location.state?.tabPropValue !== undefined) {
      setTabProp({ ...tabProp, tabPosition: location.state?.tabPropValue });
    }
  }, [location.state?.tabPropValue]);

  const hasDiagnosticPermission = checkPermissionForFeature(
    'backend.code_list',
    'viewPermission',
  );
  const hasServicePermission = checkPermissionForFeature(
    'backend.code_list',
    'viewPermission',
  );
  const hasSettingPermission = checkPermissionForFeature(
    'backend.code_list',
    'viewPermission',
  );

  const getAvailableTabs = () => {
    const tabs = [];

    if (hasDiagnosticPermission) tabs.push('Diagnosis Codes');
    if (hasServicePermission) tabs.push('Service Codes');
    if (hasSettingPermission) tabs.push('Settings Codes');

    if (tabs.length === 0) {
      localStorage.setItem('permissionDenied', 'true');
      const keyToKeep = 'permissionDenied';
      const valueToKeep = localStorage.getItem(keyToKeep);
      sessionStorage.clear();
      localStorage.clear();
      if (valueToKeep !== null) {
        localStorage.setItem(keyToKeep, valueToKeep);
      }
      EventBusProvider.publish('logout');
    }

    return tabs;
  };

  const availableTabs = getAvailableTabs();

  return (
    <ThemeProvider theme={theme}>
      <Box component="main">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <TitleText labelid="codelist.header" defaultlabel="Code List" />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {checkPermissionForFeature(
                'backend.code_list',
                'addPermission',
              ) &&
                availableTabs[tabProp.tabPosition] === 'Diagnosis Codes' && (
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    variantType="contained"
                    type="submit"
                    labelId="codeList.addDiagnosisCodeNameBtn"
                    defaultLabelId="Add Diagnosis Code"
                    onClick={() => {
                      navigate(DIAGNOSTIC_ADD_CATEGORY_NEW);
                    }}
                  />
                )}
              {checkPermissionForFeature(
                'backend.code_list',
                'addPermission',
              ) &&
                availableTabs[tabProp.tabPosition] === 'Service Codes' && (
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    variantType="contained"
                    type="submit"
                    labelId="codeList.addServiceCodeNameBtn"
                    defaultLabelId="Add Service Code"
                    onClick={() => {
                      navigate(SERVICE_ADD_CODES);
                    }}
                  />
                )}
              {checkPermissionForFeature(
                'backend.code_list',
                'addPermission',
              ) &&
                availableTabs[tabProp.tabPosition] === 'Settings Codes' && (
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    variantType="contained"
                    type="submit"
                    labelId="codeList.addSettingCodeNameBtn"
                    defaultLabelId="Add Setting Code"
                    onClick={() => {
                      navigate(SETTING_ADD_CODES);
                    }}
                  />
                )}
            </Box>
          </Box>
        </Toolbar>
        <Box
          sx={{
            width: '100%',
            my: '0px',
            position: 'relative',
            right: '10px',
            bottom: '24px',
          }}
        >
          <CustomTabs
            value={tabProp.tabPosition}
            onChange={handleChange}
            aria-label="secondary tabs example"
          >
            {hasDiagnosticPermission && <CustomTab label="Diagnosis Codes" />}
            {hasServicePermission && <CustomTab label="Service Codes" />}
            {hasSettingPermission && <CustomTab label="Settings Codes" />}
          </CustomTabs>
        </Box>
        {hasDiagnosticPermission && (
          <TabPanel
            value={tabProp.tabPosition}
            index={availableTabs.indexOf('Diagnosis Codes')}
            className=""
          >
            <DiagnosisCodesList />
          </TabPanel>
        )}
        {hasServicePermission && (
          <TabPanel
            value={tabProp.tabPosition}
            index={availableTabs.indexOf('Service Codes')}
            className=""
          >
            <ServiceCodesList />
          </TabPanel>
        )}
        {hasSettingPermission && (
          <TabPanel
            value={tabProp.tabPosition}
            index={availableTabs.indexOf('Settings Codes')}
            className=""
          >
            <SettingCodesList />
          </TabPanel>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default CodelistSetup;
