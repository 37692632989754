import React, { useState, useEffect } from 'react';
import { HeadCell, TableHeader } from '../../../components/formlib/TableHeader';
import {
  SubServiceCodes,
  deleteServiceCodes,
  getAllServiceCodes,
} from '../../../services/configApi/codelist/serviceCodes';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Tooltip,
  Grid,
  AlertColor,
  Card,
} from '@mui/material';
import SnackBarComponent from '../../../components/formlib/SnackBarComponent';
import MediumTypography from '../../../components/formlib/MediumTypography';
import { ReactComponent as EditIcon } from '../../../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/DeleteIcon.svg';
import { useNavigate } from 'react-router';
import { SERVICE_ADD_CODES } from '../../../routes/Routing';
import ModalPopup from '../../../components/formlib/ModalPopup';
import { styled } from '@mui/system';
import './ServiceCodesList.css';
import { ApiError, isCustomError } from '../../../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../../../utils/checkPermission';

const CustomTableCell = styled(TableCell)(() => ({
  fontFamily: 'Lato-Regular',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '17px',
  padding: '6px 24px',
}));

const headCells: HeadCell<Data>[] = [
  {
    id: 'code',
    labelId: 'code',
    defaultLabelId: 'Code',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'description',
    labelId: 'description',
    defaultLabelId: 'Description',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'Actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];
export type Data = {
  code: string;
  description: string;
  actions: string;
};

export type Order = 'asc' | 'desc';
const PAGE_SIZE = 10;

const ServiceCodesList = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('code');
  const [pageNumber, setPageNumber] = useState(0);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [logs, setLogs] = useState<SubServiceCodes[]>([]);
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [onsearchquery] = useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const [getServiceCodeId, setServiceCodeId] = React.useState('');
  const [showLoader, setShowLoader] = React.useState<boolean>(false);

  const navigate = useNavigate();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    toggleLoader(true);
    setShowLoader(true);
    getServiceCode();
  }, [order, orderBy, pageNumber, onsearchquery]);

  const getServiceCode = () => {
    getAllServiceCodes('', pageNumber, PAGE_SIZE, orderBy, order)
      .then((response) => {
        toggleLoader(false);
        setShowLoader(false);

        setLogs(response.serviceCode);
        setPaginationCount(response.count);
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setShowLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('failedApiMsg');
          setToastrDefaultMessage(
            'Oops, something went wrong. Please try again later.',
          );
        }
      });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage - 1);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleDelete = (id: string) => {
    if (id) {
      setServiceCodeId(id);
      setShowAlertDialog(true);
    }
  };

  const deleteLog = () => {
    setShowAlertDialog(false);
    toggleLoader(true);
    setShowLoader(true);

    if (getServiceCodeId !== '') {
      deleteServiceCodes(getServiceCodeId)
        .then(() => {
          if (logs.length === 1 && pageNumber > 0) {
            setPageNumber(pageNumber - 1);
          } else {
            getServiceCode();
          }
          setShowLoader(false);
          setOpen(true);
          setToastrVariable('success');
          setToastrId('Contacts.deleteSuccessToastr');
          setToastrDefaultMessage('Record deleted Successfully');
        })
        .catch(() => {
          setOpen(true);
          setShowLoader(false);
          setToastrVariable('error');
          setToastrId('Contacts.deleteToastr');
          setToastrDefaultMessage('Failed to delete');
        });
    }
  };

  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog}
          onCancel={() => {
            setShowAlertDialog(false);
          }}
          description="CommunicationLogTableView.deleteDescription"
          onOk={() => deleteLog()}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}
      <Box component="div">
        {logs.length > 0 && (
          <Card>
            <TableContainer>
              <Table>
                <TableHeader
                  className="listDataTableHead"
                  headerNames={
                    checkPermissionForFeature(
                      'backend.code_list',
                      'editPermission',
                    ) ||
                    checkPermissionForFeature(
                      'backend.code_list',
                      'deletePermission',
                    )
                      ? headCells
                      : headCells.slice(0, 2)
                  }
                  checkBoxRequired={false}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody className="tableRowcss">
                  {logs.map((row) => {
                    return (
                      <TableRow
                        hover
                        key={row.id}
                        tabIndex={-1}
                        sx={{
                          cursor: 'pointer',
                          alignContent: 'flex-start',
                          '&:nth-of-type(odd)': {
                            backgroundColor: '#ECF9F8',
                          },
                        }}
                      >
                        <CustomTableCell className="codelist">
                          {row.code}
                        </CustomTableCell>
                        <CustomTableCell>{row.description}</CustomTableCell>
                        {checkPermissionForFeature(
                          'backend.code_list',
                          'editPermission',
                        ) ||
                        checkPermissionForFeature(
                          'backend.code_list',
                          'deletePermission',
                        ) ? (
                          <>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>
                            <CustomTableCell></CustomTableCell>

                            <CustomTableCell>
                              <Box display={'flex'}>
                                {checkPermissionForFeature(
                                  'backend.code_list',
                                  'editPermission',
                                ) && (
                                  <Box>
                                    <Tooltip title="Edit Service Code">
                                      <EditIcon
                                        className="cursorPointer"
                                        onClick={() =>
                                          navigate(SERVICE_ADD_CODES, {
                                            state: {
                                              categoryDetails: row,
                                            },
                                          })
                                        }
                                      />
                                    </Tooltip>
                                  </Box>
                                )}
                                {checkPermissionForFeature(
                                  'backend.code_list',
                                  'deletePermission',
                                ) && (
                                  <Box ml={2}>
                                    <Tooltip title="Delete Service Code">
                                      <DeleteIcon
                                        className="cursorPointer"
                                        onClick={() =>
                                          handleDelete(row.id ? row.id : '')
                                        }
                                      />
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            </CustomTableCell>
                          </>
                        ) : null}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {paginationCount > 10 && (
            <Grid item>
              <Pagination
                boundaryCount={1}
                page={pageNumber + 1}
                onChange={handleChangePage}
                sx={{
                  '& .MuiPaginationItem-root': {
                    fontFamily: 'Lato-Regular',
                    fontWeight: 400,
                    fontSize: '16px',
                    margin: '16px 0px 20px 0px',
                  },
                  '& .MuiPaginationItem-root.Mui-selected': {
                    backgroundColor: '#008C82',
                    color: '#ffffff',
                  },
                }}
                shape="circular"
                count={Math.ceil(paginationCount / 10)}
                siblingCount={1}
              />
            </Grid>
          )}
        </div>
        {pageNumber === 0 && logs.length === 0 && !showLoader && (
          <Box sx={{ width: '100%' }}>
            <MediumTypography
              textstyle={'center'}
              labelid="codelist.serviceCode.lengthText"
              defaultlabel="No Service Codes Added Yet"
              paddingstyle="16px"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServiceCodesList;
