import { SxProps, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Box } from '@mui/system';
import MediumTypography from '../formlib/MediumTypography';

export type ConsiderationQuestionType = {
  id: string;
  displayQueId: string;
  displayQuetext: string;
};

type ASQQuestionConsiderationComponentType = {
  considerationQuestion: ConsiderationQuestionType[];
  onValueChange: (
    value: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
  initialCheck?: { answer: string }[];
  sxContainerProps?: SxProps;
  sxQuestionProps?: SxProps;
  labelidA?: string;
  defaultlabelidA?: string;
  labelidB?: string;
  defaultlabelidB?: string;
  labelidC?: string;
  defaultlabelidC?: string;
};

const SimpleTextAndTrippleRadioButton = ({
  considerationQuestion,
  onValueChange,
  initialCheck,
  sxContainerProps,
  sxQuestionProps,
  labelidA,
  labelidB,
  labelidC,
  defaultlabelidA,
  defaultlabelidB,
  defaultlabelidC,
}: ASQQuestionConsiderationComponentType) => {
  return (
    <Box
      sx={{
        paddingLeft: '12px',
        ...sxContainerProps,
      }}
    >
      {considerationQuestion.map((questiondetails, index) => (
        <ul style={{ margin: 0, padding: '0px' }}>
          <li>
            <MediumTypography
              sxProps={{
                display: 'inline',
                marginRight: '24px',
                ...sxQuestionProps,
              }}
              labelid={questiondetails.displayQueId}
              defaultlabel={questiondetails.displayQuetext}
            />
            <Box sx={{ display: 'inline-grid' }}>
              <RadioGroup
                onChange={(value) => onValueChange(value, index)}
                row
                value={initialCheck && initialCheck[index].answer}
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                sx={{ display: 'inline' }}
              >
                <FormControlLabel
                  value="yes"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': { color: '#00C6B8' },
                        borderRadius: '14px',
                        color: '#97A6A5',
                      }}
                    />
                  }
                  label={
                    <MediumTypography
                      sxProps={{
                        fontWeight: 400,
                      }}
                      labelid={labelidA}
                      defaultlabel={defaultlabelidA}
                    />
                  }
                />
                <FormControlLabel
                  value="no"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': { color: '#00C6B8' },
                        borderRadius: '14px',
                        color: '#97A6A5',
                      }}
                    />
                  }
                  label={
                    <MediumTypography
                      sxProps={{
                        fontWeight: 400,
                      }}
                      labelid={labelidB}
                      defaultlabel={defaultlabelidB}
                    />
                  }
                />
                <FormControlLabel
                  value="unsure"
                  control={
                    <Radio
                      sx={{
                        '&.Mui-checked': { color: '#00C6B8' },
                        borderRadius: '14px',
                        color: '#97A6A5',
                      }}
                    />
                  }
                  label={
                    <MediumTypography
                      sxProps={{
                        fontWeight: 400,
                      }}
                      labelid={labelidC}
                      defaultlabel={defaultlabelidC}
                    />
                  }
                />
              </RadioGroup>
            </Box>
          </li>
        </ul>
      ))}
    </Box>
  );
};

export default SimpleTextAndTrippleRadioButton;
