import { ReviewTravelExpenseTabelType } from '../../pages/travelExpenses/ReviewTravelExpense';
import { DateString } from '../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export type ExpenseGetData = {
  status: string;
  mileageRate: string;
  expenses: ExpenseListData[];
  travelId: string;
  signatureRowKey: string;
  totalAmount: string;
};
export type ExpensePostData = {
  expenses: TravelExpense[];
  travelId: string;
  signature: string;
};

export type Attachment = {
  id: string;
  name: string;
  url: string;
  attachmentId: string;
};
export type ExpenseListData = {
  id: string;
  expenseId?: string;
  expenseDate: string | null;
  expenseType: string;
  origin: string;
  destination: string;
  destinationCode: string;
  purpose: string;
  miles: string;
  amount: string;
  note?: string;
  totalAmount?: string;
  attachments: Attachment[];
};

export interface TravelExpense {
  description: string;
  mileageRate: string;
  id: string;
  isLocal: boolean;
  notModified: boolean;
  expenseDate: DateString;
  expenseType: string;
  origin: string;
  destination: string;
  destinationCode: string;
  purpose: string;
  miles: string;
  amount: string;
  note?: string;
  attachments: Attachment[];
}

export interface GetTravelExpenseResponse {
  expenses: TravelExpense[];
  status: string;
  rejectionReason: string;
  mileageRate: string;
  travelId: string;
  signatureRowKey: string;
  totalAmount: string;
  managerSignatureRowKey?: string | null;
  expensesCount?: number;
}

export const getTravelExpenses = async (
  employeeId: string,
  month: number,
  year: number,
): Promise<GetTravelExpenseResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GetTravelExpenseResponse>(
      `travel-expenses?employeeId=${employeeId}&month=${month}&year=${year}`,
    ),
  );
};

export const addEmployeeTravelExpenses = async (
  employeeId: string,
  requestBody: ExpensePostData,
): Promise<ExpensePostData> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `travel-expenses?employeeId=${employeeId}`,
      requestBody,
    ),
  );
};

export const deleteEmployeeTravelExpenses = async (
  id: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(`travel-expenses/expense/${id}`),
  );
};

export const addAttachmentTravelExpenses = async (
  employeeId: string,
  files: File[],
): Promise<Attachment[]> => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('files', file, file.name.toLowerCase());
  });

  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `travel-expenses/attachment?employeeId=${employeeId}`,
      formData,
    ),
  );
};

export const deleteAttachmentTravelExpenses = async (
  employeeId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(
      `travel-expenses/attachment?employeeId=${employeeId}`,
    ),
  );
};

export const deleteAttachmentTravelExpensesById = async (
  id: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(
      `travel-expenses/attachment/${id}`,
    ),
  );
};

export const getReviewTravelExpenses = async (
  expenseMonth: string,
  expenseYear: string,
  isSentToFinance: boolean,
): Promise<ReviewTravelExpenseTabelType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ReviewTravelExpenseTabelType>(
      `travel-expenses/review?expenseMonth=${expenseMonth}&expenseYear=${expenseYear}&isSentToFinance=${isSentToFinance}`,
    ),
  );
};

export const getTravelExpenseByEmployeeId = async (
  employeeId: string,
  expenseMonth: number,
  expenseYear: number,
  page: number,
): Promise<GetTravelExpenseResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GetTravelExpenseResponse>(
      `travel-expenses/review/employee?employeeId=${employeeId}&expenseMonth=${expenseMonth}&expenseYear=${expenseYear}&page=${page}&size=7`,
    ),
  );
};

export const approveOrRejectTravelExpenses = async (
  status: string,
  rejectionReason: string,
  signature: string,
  travelIds: string[],
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().patch(`travel-expenses/review`, {
      status,
      rejectionReason,
      signature,
      travelIds,
    }),
  );
};
