import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';

interface IfspUpdateModalPopupProps {
  onOk: () => void;
  onCancel: () => void;
  positiveActionLabel: string;
  labelId1: string;
  negativeActionLabel: string;
  labelId2: string;
  openIfspUpdateModal: boolean;
}

const IfspUpdateModalPopup = ({
  labelId1,
  labelId2,
  positiveActionLabel,
  negativeActionLabel,
  onOk,
  onCancel,
  openIfspUpdateModal,
}: IfspUpdateModalPopupProps) => {
  return (
    <Dialog open={openIfspUpdateModal}>
      <DialogTitle>
        <MediumTypography
          labelid="IFSP.DoYouWantToStartASession"
          defaultlabel="Do you want to start a new review session?"
          fontweight={600}
          fontSize="16px"
          textColor="#2A4241"
        />
      </DialogTitle>
      <DialogContent>
        <DialogActions className="pt-sm" sx={{ p: 0 }}>
          <Box>
            <ButtonComponent
              className="btn-primary btn-cancel alrt_Width_btn"
              variantType="contained"
              labelId={labelId1}
              defaultLabelId={negativeActionLabel}
              onClick={onCancel}
            />
            <ButtonComponent
              variantType="contained"
              type="submit"
              className="btn-primary btn-submit alrt_Width_btn ml-md"
              labelId={labelId2}
              defaultLabelId={positiveActionLabel}
              onClick={onOk}
            />
          </Box>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default IfspUpdateModalPopup;
