import {
  create,
  ApisauceInstance,
  ApiResponse,
  ApisauceConfig,
} from 'apisauce';
import { THOM_BASE_URL, USER_BASE_URL } from '../services/Constant';
import { RefreshTokenResponse } from '../utils/type';
import {
  checkIfOfflineIsAddedByReferralId,
  getCustomParamsApis,
  getProgramBasedOnReferralId,
  getResponse,
  isExcludeCommonParamsApis,
  isIncludeParamsApis,
  isOfflinePostApis,
  saveResponsePayload,
} from './Offline';
import { ConsentDetailsType } from './configApi/forms/Consent/ConsentServices';
import { isExistInDB } from './storageDB';

export default class ThomApiClient {
  private static _instance: ThomApiClient;

  private api: ApisauceInstance;

  constructor() {
    this.api = create({
      baseURL: THOM_BASE_URL,
    });

    this.api.addRequestTransform((request) => {
      const requestConfig = request;
      if (requestConfig && requestConfig.headers) {
        requestConfig.headers = {
          ...requestConfig.headers,
          appSource: `TCS`,
          Authorization: `Bearer ${localStorage?.getItem('accessToken')}`,
        };
      }
      return requestConfig;
    });

    this.api.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;
        if (
          error.response &&
          error.response.status == 403 &&
          (error.response.data.code == 1045 || error.response.data.code == 1039)
        ) {
          if (error.response.data.code == 1039) {
            localStorage.setItem('permissionDenied', 'true');
          }
          return Promise.reject(error.response);
        } else if (error.response && error.response.status == 403) {
          const api = create({
            baseURL: USER_BASE_URL,
          });
          const response = await api.post(`login`, {
            refreshToken: localStorage.getItem('refreshToken'),
            grantType: 'refresh_token',
          });
          if (response.ok) {
            const data = response.data as RefreshTokenResponse;
            localStorage.setItem('accessToken', data.accessToken);
            localStorage.setItem('refreshToken', data.refreshToken);
            originalConfig.headers.Authorization = data.accessToken;
            return this.api.axiosInstance.request(originalConfig);
          } else {
            return Promise.reject(response.originalError);
          }
        }
        return Promise.reject(error);
      },
    );
  }

  public static getInstance(): ThomApiClient {
    if (ThomApiClient._instance == null) {
      ThomApiClient._instance = new ThomApiClient();
    }
    return this._instance;
  }

  appendProgramIdQueryParam = (url: string) => {
    const selectedRefferalId: string | null =
      localStorage.getItem('referralId');
    const selectedProgram: string | null =
      getProgramBasedOnReferralId(selectedRefferalId);
    if (selectedProgram && selectedProgram !== null) {
      const separator = url.includes('?') ? '&' : '?';
      return `${url}${separator}programId=${
        JSON.parse(selectedProgram).id
      }&referralId=${selectedRefferalId}`;
    } else {
      return url;
    }
  };

  async post<T, U>(url: string, payload?: T): Promise<ApiResponse<T, U>> {
    const urlType = this.appendProgramIdQueryParam(url).split('?')[0];
    const savedUrl = getSavedUrl(this.appendProgramIdQueryParam(url));

    if (isOfflinePostApis(urlType) || (await isExistInDB(savedUrl))) {
      const saved = await saveResponsePayload(
        'post',
        this.api.getBaseURL(),
        this.appendProgramIdQueryParam(url),
        payload,
      );
      if (saved) {
        return { ok: true, data: {}, status: 200 } as ApiResponse<T, U>;
      }
    }
    const response = await this.api.post<T, U>(
      this.appendProgramIdQueryParam(url),
      payload,
    );
    return response;
  }

  async get<T>(
    url: string,
    params?: object,
    config?: ApisauceConfig,
  ): Promise<ApiResponse<T>> {
    const response = await this.api.get<T>(
      this.appendProgramIdQueryParam(url),
      params,
      config,
    );
    const urlKey =
      this.api.getBaseURL() +
      this.appendProgramIdQueryParam(url) +
      (params || '');

    const urlType = this.appendProgramIdQueryParam(url).split('?')[0];
    const paramsUrl = this.appendProgramIdQueryParam(url).split('?')[1];
    const paramsget = paramsUrl.split('&');
    const savedurl = getSavedUrl(this.appendProgramIdQueryParam(url));

    console.log('savedurl', savedurl);

    let offlineAvailable = await isExistInDB(savedurl);
    if (!response.ok) {
      offlineAvailable = true;
    }

    if (urlType === 'consent') {
      const urltemp = new URL(urlKey);
      const referralId = urltemp.searchParams.get('referralId') || '';
      await checkIfOfflineIsAddedByReferralId(referralId).then((res) => {
        if (res) {
          offlineAvailable = true;
        }
      });
    }

    if (
      savedurl === 'clients?' + paramsget[paramsget.length - 2] &&
      response.ok
    ) {
      offlineAvailable = false;
    }

    if (
      offlineAvailable &&
      (!response.ok || (await isExistInDB(savedurl)) || urlType === 'consent')
    ) {
      let urlget;
      if (isExcludeCommonParamsApis(urlType)) {
        urlget = urlType + '?' + paramsget[paramsget.length - 2];
      } else if (isIncludeParamsApis(urlType)) {
        urlget = urlType + '?' + paramsUrl;
      } else {
        urlget =
          urlType +
          '?' +
          paramsget[paramsget.length - 2] +
          '&' +
          paramsget[paramsget.length - 1];
      }

      if (urlType === 'employee/listByRole') {
        urlget =
          getCustomParamsApis(urlKey) + '&' + paramsget[paramsget.length - 2];
      }

      let cachedData = (await getResponse(urlget)) as T;
      console.log('cachedData for ' + urlget, cachedData);
      if (cachedData) {
        return { ok: true, data: cachedData } as ApiResponse<T>;
      } else {
        let consentType = '' as string | null;
        if (urlType === 'consent') {
          const urltemp = new URL(urlKey);
          consentType = urltemp.searchParams.get('type');

          if (consentType === 'PriorWrittenNoticeForm') {
            urlget =
              'defaultEmployeeConsent?' +
              paramsget[paramsget.length - 2] +
              '&' +
              paramsget[paramsget.length - 1];
          } else {
            urlget =
              'defaultClientConsent?' +
              paramsget[paramsget.length - 2] +
              '&' +
              paramsget[paramsget.length - 1];
          }
        }
        cachedData = (await getResponse(urlget)) as T & {
          type: ConsentDetailsType;
        };
        if (urlType === 'consent' && consentType) {
          (cachedData as ConsentDetailsType).type = consentType;
          (cachedData as ConsentDetailsType).consentId = '';
        }
        if (cachedData) {
          return { ok: true, data: cachedData } as ApiResponse<T>;
        }
      }
    } else {
      // udpate the obj if already exist(handled in offline file)
    }
    return response;
  }

  async put<T, U>(url: string, payload?: T): Promise<ApiResponse<T, U>> {
    const urlType = this.appendProgramIdQueryParam(url).split('?')[0];
    const savedUrl = getSavedUrl(this.appendProgramIdQueryParam(url));

    if (isOfflinePostApis(urlType) || (await isExistInDB(savedUrl))) {
      const saved = await saveResponsePayload(
        'put',
        this.api.getBaseURL(),
        this.appendProgramIdQueryParam(url),
        payload,
      );
      if (saved) {
        return { ok: true, data: {}, status: 200 } as ApiResponse<T, U>;
      }
    }
    const response = await this.api.put<T, U>(
      this.appendProgramIdQueryParam(url),
      payload,
    );
    return response;
  }

  async delete<T>(url: string): Promise<ApiResponse<T, T>> {
    const urlType = this.appendProgramIdQueryParam(url).split('?')[0];
    if (urlType === 'clients/communicationLogs') {
      const savedurl = getSavedUrl(this.appendProgramIdQueryParam(url));

      const offlineAvailable = await isExistInDB(savedurl);
      if (offlineAvailable) {
        return { ok: false, data: {}, status: 400 } as ApiResponse<T>;
      }
    }
    const response = this.api.delete<T>(this.appendProgramIdQueryParam(url));
    return response;
  }

  async patch<T, U>(url: string, payload?: T): Promise<ApiResponse<T, U>> {
    const response = await this.api.patch<T, U>(
      this.appendProgramIdQueryParam(url),
      payload,
    );

    return response;
  }
}

const getSavedUrl = (url: string) => {
  const urlType = url.split('?')[0];
  const paramsUrl = url.split('?')[1];
  const params = paramsUrl.split('&');
  const separator = urlType.includes('?') ? '&' : '?';
  let urlKey;
  if (isExcludeCommonParamsApis(urlType)) {
    urlKey = urlType + separator + params[params.length - 2];
  } else if (isIncludeParamsApis(urlType)) {
    urlKey = urlType + '?' + paramsUrl;
  } else {
    urlKey =
      urlType +
      '?' +
      params[params.length - 2] +
      '&' +
      params[params.length - 1];
  }
  return urlKey;
};
