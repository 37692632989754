import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useState } from 'react';

interface CancelVisitsProps {
  openModal: boolean;
  handelLockScreen: (reason: string) => void;
  onClose: () => void;
}

const reasons = [
  {
    id: 'Family canceled - child or someone else in the home sick',
    label: 'Family canceled - Child or someone else in the home sick',
  },
  {
    id: 'Family canceled - on vacation',
    label: 'Family canceled - On vacation',
  },
  {
    id: 'Family canceled - family living in a shelter or child in foster care abruptly moved',
    label:
      'Family canceled - Family living in a shelter or child in foster care abruptly moved',
  },
  { id: 'Family canceled - not home', label: 'Family canceled - Not home' },
  {
    id: 'Family canceled - weather related',
    label: 'Family canceled - Weather related',
  },
  {
    id: 'Family canceled - scheduling conflict',
    label: 'Family canceled - Scheduling conflict',
  },
  {
    id: 'Family canceled - Family was a no show for the visit or didn’t respond to the call or text asking them to confirm the visit',
    label:
      'Family canceled - Family was a no show for the visit or didn’t respond to the call or text asking them to confirm the visit',
  },
  {
    id: 'Staff canceled - illness (self or family)',
    label: 'Staff canceled - Illness (self or family)',
  },
  {
    id: 'Staff canceled - vacation or holiday',
    label: 'Staff canceled - Vacation or holiday',
  },
  {
    id: 'Staff canceled - training day',
    label: 'Staff canceled - Training day',
  },
  {
    id: 'Staff canceled - weather related',
    label: 'Staff canceled - Weather related',
  },
  { id: 'Other', label: 'Other' },
];

const CancelVisits = ({
  onClose,
  handelLockScreen,
  openModal,
}: CancelVisitsProps) => {
  const [cancelReason, setCancelReason] = useState<string>('');
  const handleCancelVisit = () => {
    handelLockScreen(cancelReason);
    setTimeout(() => {
      setCancelReason('');
      onClose();
    }, 1000);
  };

  return (
    <Dialog open={openModal} fullWidth>
      <DialogTitle>
        <MediumTypography
          labelid="ProgressNote.cancelVisitModalTitle"
          defaultlabel="Cancelation Reason"
          textColor="#2A4241"
          fontSize="24px"
          fontweight={700}
        />
      </DialogTitle>
      <DialogContent>
        <Box className="pt-sm" component={'div'}>
          <DropdownComponent
            names={reasons}
            labelid="ProgressNote.cancelVisitModalDropdown"
            defaultlabelid="Select Reason"
            value={cancelReason}
            handleChange={(e) => setCancelReason(e)}
          />
        </Box>
        <Box className="pt-lg flex__ justifyContent-FlexEnd">
          <ButtonComponent
            className="btn-primary btn-cancel alrt_Width_btn"
            variantType="contained"
            labelId="ProgressNote.Cancel"
            defaultLabelId="Cancel"
            onClick={() => {
              setCancelReason('');
              onClose();
            }}
          />
          <ButtonComponent
            variantType="contained"
            type="submit"
            className="btn-primary btn-submit alrt_Width_btn  ml-md"
            labelId="ProgressNote.Confirm"
            defaultLabelId="Confirm"
            onClick={handleCancelVisit}
            disabled={cancelReason === ''}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CancelVisits;
