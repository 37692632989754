import _ from 'lodash';
import {
  ChangeSignatureBodyType,
  SignatureStateType,
  SignatureType,
} from '../utils/type';
import { ApiMessage, ApiResponseHandler } from './ApiResponseHandler';
import { USER_BASE_URL } from './Constant';
import UserApiClient from './UserApiClient';

export type ChangePinType = {
  oldPin: string;
  newPin: string;
  reset: boolean;
};

export const getSignature = async (
  params: SignatureType[],
): Promise<SignatureStateType[]> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<
      SignatureType[],
      SignatureStateType[]
    >('signature/fetch', params, {
      baseURL: USER_BASE_URL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }),
  );
};

export const forgetPinApi = async (employeeId: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post(
      `signature/pin/forget?employeeId=${employeeId}`,
      _,
      {
        baseURL: USER_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      },
    ),
  );
};

export const changeSignatureApi = async (
  pin: string,
  params: ChangeSignatureBodyType,
): Promise<ChangeSignatureBodyType> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().put(
      `employee/signature?pin=${pin}`,
      params,
      {
        baseURL: USER_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      },
    ),
  );
};

export const changePinApi = async (
  employeeId: string,
  params: ChangePinType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post(
      `signature/pin/change?employeeId=${employeeId}`,
      params,
      {
        baseURL: USER_BASE_URL,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      },
    ),
  );
};

export const getEmployeeSignatureBasedOnPin = async (
  employeeId: string,
  pin: string,
): Promise<SignatureStateType> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().get<SignatureStateType>(
      `employee/signature?employeeId=${employeeId}&pin=${pin}`,
    ),
  );
};
