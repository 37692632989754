import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export type SubSettingCodes = {
  id?: string;
  codeId: number;
  code: string;
  description: string;
  isAddedCode?: boolean;
  isDuplicateCode?: boolean;
};

export type SettingCode = {
  settingCode: SubSettingCodes[];
};

export type SettingCodeDto = {
  settingCode: SubSettingCodes[];
  count: number;
};

export type SerivceAddDto = {
  addedCode: SubSettingCodes[];
  duplicateCode?: SubSettingCodes[];
};
export const getAllSettingCodes = async (
  searchquery: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
): Promise<SettingCodeDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<SettingCodeDto>(
      `settingCode/all?search=${searchquery}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType}`,
    ),
  );
};

export const addSettingCodes = async (
  requestBody: SettingCode,
): Promise<SerivceAddDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<SettingCode, SerivceAddDto>(
      `settingCode`,
      requestBody,
    ),
  );
};

export const editSettingCodes = async (
  requestBody: SubSettingCodes,
  id: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<SubSettingCodes, ApiMessage>(
      `settingCode?id=${id}`,
      requestBody,
    ),
  );
};

export const deleteSettingCodes = async (id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `settingCode?id=${id}`,
    ),
  );
};
