import React from 'react';
import { ListItemButton, ListItemText } from '@mui/material';
import './Textinput.css';
import { Box } from '@mui/system';

interface VerticalTabsProps {
  fields: FieldItems[];
  onClickCallback?: (data: string, index: number) => void;
}

interface FieldItems {
  id?: number;
  itemText?: string;
  label?: string;
}
const VerticalTabs: React.FC<VerticalTabsProps> = (props) => {
  const { fields, onClickCallback } = props;

  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  return (
    <Box component="div">
      {fields.map((data, index) => {
        return (
          <ListItemButton
            className="verticalTabButton"
            key={data.id}
            selected={selectedIndex === index}
            onClick={(event) => {
              handleListItemClick(event, index);
              if (onClickCallback && selectedIndex !== index) {
                onClickCallback(data.label ?? '', index);
              }
            }}
          >
            <ListItemText primary={data.itemText} />
          </ListItemButton>
        );
      })}
    </Box>
  );
};
export default VerticalTabs;
