import { Box, Grid } from '@mui/material';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { useIntl } from 'react-intl';
import { ReactComponent as DeleteAlertIcon } from '../../assets/images/deleteAlertIcon.svg';
import { FC, useState } from 'react';
import { OptionType } from '../../utils/type';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { AreaOfConcerns } from './types';

interface AreaOfConcernProps {
  employees: OptionType[];
  onDelete: (index: number) => void;
  disableLine: boolean;
  index: number;
  areaOfConcernValue: AreaOfConcerns;
  values: AreaOfConcerns[];
  handleDataChange: (index: number, concerns: AreaOfConcerns) => void;
  uploadedToTBR?: boolean;
}

const AreaOfConcern: FC<AreaOfConcernProps> = ({
  index,
  values,
  onDelete,
  employees,
  disableLine,
  handleDataChange,
  areaOfConcernValue,
  uploadedToTBR,
}) => {
  const rctl = useIntl();
  const [concerns] = useState<OptionType[]>([
    { id: 'Adaptive (ADP)', label: 'Adaptive (ADP)' },
    { id: 'Communication (COM)', label: 'Communication (COM)' },
    { id: 'Personal – Social (P-S)', label: 'Personal – Social (P-S)' },
    { id: 'Motor (MOT)', label: 'Motor (MOT)' },
    { id: 'Cognitive (COG)', label: 'Cognitive (COG)' },
  ]);

  // filter concerns that's already added
  const filteredConcerns = concerns.filter(
    (item) => !values.find((value) => value.areaOfConcern === item.id),
  );
  if (areaOfConcernValue.areaOfConcern)
    filteredConcerns.push({
      id: areaOfConcernValue.areaOfConcern ?? '',
      label: areaOfConcernValue.areaOfConcern ?? '',
    });

  return (
    <Grid
      container
      xs={12}
      columnSpacing={'24px'}
      rowSpacing={'16px'}
      padding={1}
    >
      <Grid item xs={4}>
        <DropdownComponent
          disabled={uploadedToTBR}
          names={filteredConcerns}
          value={areaOfConcernValue.areaOfConcern ?? ''}
          labelid="areaOfConcernText"
          defaultValue="Area of Concern"
          handleChange={(value) => {
            const foundItem = values.find(
              (item) => item.areaOfConcern === value,
            );
            if (foundItem === undefined) {
              handleDataChange(index, {
                ...areaOfConcernValue,
                areaOfConcern: value,
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <DropdownComponent
          disabled={uploadedToTBR || areaOfConcernValue.areaOfConcern === null}
          names={employees}
          labelid="teamMemberText"
          defaultValue="Team Member"
          value={areaOfConcernValue.teamMember ?? ''}
          handleChange={(value) => {
            handleDataChange(index, {
              ...areaOfConcernValue,
              teamMember: value,
            });
          }}
        />
      </Grid>

      <Grid item xs={8}>
        <CommentTextbox
          disabled={uploadedToTBR || areaOfConcernValue.areaOfConcern === null}
          placeholder={rctl.formatMessage({
            id: 'commentsText',
            defaultMessage: 'Comments',
          })}
          maxLength={3000}
          rows={1}
          Value={areaOfConcernValue.comments}
          handlechange={(text) => {
            handleDataChange(index, {
              ...areaOfConcernValue,
              comments: text,
            });
          }}
        />
      </Grid>
      <Grid item xs={1} display={'flex'}>
        {values.length > 1 && (
          <Box
            sx={{
              alignSelf: 'center',
              cursor: 'pointer',
            }}
            onClick={() => {
              onDelete(index);
            }}
          >
            <DeleteAlertIcon />
          </Box>
        )}
      </Grid>
      {disableLine && <Box className="blueHorizontalLine" />}
    </Grid>
  );
};

export default AreaOfConcern;
