// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main.MuiBox-root {
  padding: 0px 16px;
}
main.MuiBox-root > div.MuiToolbar-root.MuiToolbar-regular {
  padding: 20px 0px;
}

div.SearchBox > div.MuiInputBase-root > input.MuiInputBase-input {
  padding: 6px 14px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ComonStyle.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["main.MuiBox-root {\n  padding: 0px 16px;\n}\nmain.MuiBox-root > div.MuiToolbar-root.MuiToolbar-regular {\n  padding: 20px 0px;\n}\n\ndiv.SearchBox > div.MuiInputBase-root > input.MuiInputBase-input {\n  padding: 6px 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
