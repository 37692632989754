import { Grid, AlertColor } from '@mui/material';
import { Box } from '@mui/system';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { useContext, useEffect, useState } from 'react';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import dayjs, { Dayjs } from 'dayjs';
import IfspContext from './ifspContextApi/IfspContex';
import { SignatureStateType } from '../../utils/type';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';

export interface Signature {
  id: string;
  signature: string;
  verbalConsent: boolean;
  date: string | null | Dayjs;
  isUpdated: boolean;
  partitionKey: string;
  rowKey: string;
}

interface IfspSignaturePropsType {
  idIndication: string;
  signatureNeed: string;
  openAccordianIndex: number;
  disabled: boolean;
  onSignatureChange: (updatedSignatures: Signature[], index: number) => void;
}

const IfspSignature = ({
  idIndication,
  onSignatureChange,
  openAccordianIndex,
  signatureNeed,
  disabled,
}: IfspSignaturePropsType) => {
  const { ifsp } = useContext(IfspContext);

  const [signClicked, setSignClicked] = useState<boolean>(false);
  const [signatures, setSignatures] = useState<Signature[]>(
    ifsp.ifspTeam.forms.consentForChangeForSDP[openAccordianIndex]
      .parentSignature,
  );
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [clickedIndex, setClickedIndex] = useState<number>(0);

  const onCancelClick = () => {
    setSignClicked(false);
  };

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  useEffect(() => {
    if (signatureNeed === 'parent') {
      setSignatures(
        ifsp.ifspTeam.forms.consentForChangeForSDP[openAccordianIndex]
          .parentSignature,
      );
    } else if (signatureNeed === 'consent') {
      setSignatures(
        ifsp.ifspTeam.forms.consentForChangeForSDP[openAccordianIndex]
          .consentSignature,
      );
    }
  }, []);

  const signatureCallBack = (response: SignatureStateType[]) => {
    const updatedSignatures: Signature[] = [];
    signatures.forEach((sign: Signature, signatureIndex: number) => {
      if (clickedIndex === signatureIndex) {
        const signatureData: Signature = {
          id: sign.id,
          signature: response[0].signaturedata,
          verbalConsent: response[0].verbalConsent,
          date: signatures[signatureIndex].date || dayjs().format('MM/DD/YYYY'),
          isUpdated: sign.isUpdated,
          partitionKey: sign.partitionKey,
          rowKey: sign.rowKey,
        };
        updatedSignatures.push(signatureData);
      } else {
        const signatureData: Signature = {
          id: sign.id,
          signature: sign.signature,
          verbalConsent: sign.verbalConsent,
          date: sign.date,
          isUpdated: sign.isUpdated,
          partitionKey: sign.partitionKey,
          rowKey: sign.rowKey,
        };
        updatedSignatures.push(signatureData);
      }
      return sign;
    });
    setSignatures(updatedSignatures);
  };

  const signatureSaveOrUPdate = (signed: string) => {
    if (onSignatureChange) {
      const updatedSignatures = signatures;
      updatedSignatures[clickedIndex] = {
        ...updatedSignatures[clickedIndex],
        partitionKey: '',
        signature: signed,
      };
      setSignatures(updatedSignatures);
      onSignatureChange(signatures, clickedIndex);
    }
    setSignClicked(!signClicked);
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpenSnackBar(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  return (
    <Box component="main">
      <SnackBarComponent
        open={openSnackBar}
        handleClose={handleCloseSnakBar}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {signatures.map((sign, index) => (
        <Box component="section" className="pb-lg pt-lg" key={sign.id}>
          {signatures[index].signature === '' &&
            signatures[index].rowKey === '' && (
              <>
                <Box component="div" className="flex__ align__items__center">
                  <ButtonComponent
                    className="btn-primary btn-cancel signature_add_Box"
                    labelId="Insurance.Addsignature"
                    defaultLabelId="Add Signature"
                    variantType="outlined"
                    onClick={() => {
                      setClickedIndex(index);
                      setSignClicked(!signClicked);
                    }}
                    disabled={disabled || signatures[index].verbalConsent}
                  />
                  <Grid container flexDirection={'column'}>
                    <Grid item className="pl-lg flex__ align__items__center">
                      <CustomCheckBox
                        value={signatures[index].verbalConsent}
                        onCheckBoxClick={(val) => {
                          const updatedSignatures = signatures;
                          updatedSignatures[index].verbalConsent = val;
                          setSignatures(updatedSignatures);
                          onSignatureChange(signatures, index);
                        }}
                        disable={
                          disabled ||
                          signatures[index].signature !== '' ||
                          signatures[index].rowKey !== ''
                        }
                      />
                      <MediumTypography
                        defaultlabel="Paper Consent/Verbal Consent"
                        labelid="IFSP.PaperConsent"
                        fontweight={400}
                        textColor="#2A4241"
                      />
                    </Grid>
                    <Grid
                      item
                      className="pl-lg"
                      sx={{ marginLeft: '8px' }}
                      xs={3}
                    >
                      <DatePickerComponent
                        className="bg__white"
                        labelid={'Date'}
                        defaultlabelid={'Date'}
                        value={
                          signatures[index].date === '' ||
                          signatures[index].date === null
                            ? dayjs()
                            : (dayjs(signatures[index].date) as Dayjs)
                        }
                        handlechange={(dateValue: Dayjs | null) => {
                          if (dateValue !== null) {
                            const formattedDate =
                              dayjs(dateValue).format('MM/DD/YYYY');
                            const updatedSignatures = signatures;
                            updatedSignatures[index].date = formattedDate;
                            setSignatures(updatedSignatures);
                            onSignatureChange(signatures, index);
                          } else {
                            const updatedSignatures = signatures;
                            updatedSignatures[index].date = null;
                            setSignatures(updatedSignatures);
                            onSignatureChange(signatures, index);
                          }
                        }}
                        disabledDate={true}
                      />
                    </Grid>
                  </Grid>
                </Box>
                {index === 1 ? (
                  <MediumTypography
                    labelid="IFSP.Optional"
                    defaultlabel="(Optional)"
                    className="pt-md"
                    textColor="#97a6a5"
                  />
                ) : null}
              </>
            )}
          {(signatures[index].signature !== '' ||
            signatures[index].rowKey !== '') && (
            <Box component="div" className="flex__ align__items__center">
              <ButtonComponent
                className="btn-primary btn-cancel signature_view_Box"
                labelId="Insurance.Viewsignature"
                defaultLabelId="View/update signature"
                variantType="outlined"
                LeftIcon={<Editsignature />}
                onClick={() => {
                  setClickedIndex(index);

                  setSignClicked(!signClicked);
                }}
              />
              <Grid container flexDirection={'column'}>
                <Grid item className="pl-lg flex__ align__items__center">
                  <CustomCheckBox
                    value={signatures[index].verbalConsent}
                    onCheckBoxClick={(val) => {
                      const updatedSignatures = signatures;
                      updatedSignatures[index].verbalConsent = val;
                      setSignatures(updatedSignatures);
                      onSignatureChange(signatures, index);
                    }}
                    disable={
                      disabled ||
                      signatures[index].signature !== '' ||
                      signatures[index].rowKey !== ''
                    }
                  />
                  <MediumTypography
                    defaultlabel="Paper Consent/Verbal Consent"
                    labelid="IFSP.PaperConsent"
                    fontweight={400}
                    textColor="#2A4241"
                  />
                </Grid>
                <Grid item className="pl-lg" sx={{ marginLeft: '8px' }} xs={3}>
                  <DatePickerComponent
                    className="bg__white"
                    labelid={'Date'}
                    defaultlabelid={'Date'}
                    value={
                      signatures[index].date === '' ||
                      signatures[index].date === null
                        ? dayjs()
                        : (dayjs(signatures[index].date) as Dayjs)
                    }
                    handlechange={(dateValue: Dayjs | null) => {
                      if (dateValue !== null) {
                        const formattedDate =
                          dayjs(dateValue).format('MM/DD/YYYY');
                        const updatedSignatures = signatures;
                        updatedSignatures[index].date = formattedDate;
                        setSignatures(updatedSignatures);
                        onSignatureChange(signatures, index);
                      } else {
                        const updatedSignatures = signatures;
                        updatedSignatures[index].date = null;
                        setSignatures(updatedSignatures);
                        onSignatureChange(signatures, index);
                      }
                    }}
                    disabledDate={true}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      ))}
      {/* Signature pad */}

      {signClicked && (
        <Box component="div">
          <SignatureComponent
            hideResetButton={disabled || signatures[clickedIndex].verbalConsent}
            signClicked={signClicked}
            signature={signatures[clickedIndex].signature}
            labelId="parentSignatureId"
            defaultLabelId="Parent Signature"
            onCancelClick={onCancelClick}
            signaturePartitionKey={signatures[clickedIndex].partitionKey}
            id={`${idIndication + clickedIndex}`}
            signatureRowKey={signatures[clickedIndex].rowKey}
            signatureCallBack={signatureCallBack}
            signatureSaveOrUPdate={signatureSaveOrUPdate}
            handleSuccess={handleSuccess}
            type="Others"
          />
        </Box>
      )}
    </Box>
  );
};

export default IfspSignature;
