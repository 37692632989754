import dayjs from 'dayjs';
import { FaceSheetFormType } from '../../pages/faceSheet/FaceSheetScreen';
import {
  Client,
  ClientsListType,
  DashboardOfflineData,
  FaceSheetType,
  OfflineData,
  ServiceCoordinatorsType,
  SyncOfflineData,
} from '../../utils/type';
import { getCurrentWeekSundayAsDate } from '../../utils/utilities';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export type DashboardDetailsResponse = {
  totalClientsCount: number;
  clients: ClientsListType[];
};

export type ReferralResponseType = {
  referralId: string;
  dphId: string | null;
  programId: string;
};

export const getClientsDetails = async (
  page: number,
  size: number,
  filterData: string,
  sort: string,
  sortType: string,
  searchclient: string,
): Promise<DashboardDetailsResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<DashboardDetailsResponse>(
      `clients?page=${page}&size=${size}&filter=${filterData}&sort=${sort}&sortType=${sortType}&search=${searchclient}&appSource=TCS`,
    ),
  );
};

export const getAllClients = async (active: boolean): Promise<Client[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Client[]>(
      `clients/all?active=${active}`,
    ),
  );
};

export const getFacesheetDetails = async (
  clientId: string,
): Promise<FaceSheetType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<FaceSheetType>(
      `client/faceSheet?clientId=${clientId}`,
    ),
  );
};

export const getAllReferrals = async (
  clientId: string,
  defaultReferralId: string,
): Promise<ReferralResponseType[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ReferralResponseType[]>(
      `client/referral_info?defaultReferralId=${defaultReferralId}&clientId=${clientId}`,
    ),
  );
};

export const getListByRole = async (
  type: string,
): Promise<ServiceCoordinatorsType[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ServiceCoordinatorsType[]>(
      `employee/listByRole?employeeRole=${type}`,
    ),
  );
};

export const updateFacesheetDetails = async (
  param: Partial<FaceSheetFormType>,
  clientId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `client/faceSheet?clientId=${clientId}`,
      param,
    ),
  );
};

export const postServiceCoordinators = async (
  serviceCoordinatorId: object,
  clientId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `client/serviceCoordinator?clientId=${clientId}`,
      serviceCoordinatorId,
    ),
  );
};

export const getOfflineData = async (): Promise<DashboardOfflineData> => {
  const timezone = encodeURIComponent(
    dayjs(getCurrentWeekSundayAsDate()).format('Z'),
  );
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<DashboardOfflineData>(
      `offline?timezone=${timezone}&zoneName=${zoneName}`,
    ),
  );
};

export const syncOfflineData = async (
  offlineData: OfflineData,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(`offline?`, offlineData),
  );
};

export const syncEmployeeOfflineData = async (
  offlineData: SyncOfflineData,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `offline?isLastSync=` + offlineData.isLastSync,
      offlineData,
    ),
  );
};

export const deleteSession = async (): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(`/offline/session`),
  );
};
