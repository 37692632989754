import React, { useState, useRef, useEffect } from 'react';
import { Box, Dialog, DialogContent, Grid } from '@mui/material';
import { createRef } from 'react';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import Textinput from '../../components/formlib/Textinput';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Formik, FormikProps } from 'formik';
import { AlertColor } from '@mui/material';
import {
  CheckExistFormName,
  CreateForm,
  FormTypeBuilder,
} from '../../utils/type';
import {
  addForm,
  checkDuplicate,
} from '../../services/configApi/forms/FormBuilder/FormBuilderServices';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { FORM_BUILDER_LIST_DISPLAY } from '../../routes/Routing';
import checkB from '../../assets/images/checkBoxIcon.svg';
import dateIcon from '../../assets/images/calendarFormBuilder.svg';
import radioIcon from '../../assets/images/radioFormBuilder.svg';
import textAreaIcon from '../../assets/images/textAreaFomBuilder.svg';
import headerIcon from '../../assets/images/headerFormBuilder.svg';
import ModalPopup from '../../components/formlib/ModalPopup';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';

declare global {
  interface JQuery {
    formBuilder: (options: FormbuilderOptions) => FormBuilderInstance;
  }
  interface Window {
    jQuery: JQueryStatic;
    $: JQueryStatic;
  }
}

interface FormbuilderOptions {
  controlPosition: string;
  disableFields: string[];
  disabledActionButtons: string[];
  disabledAttrs: string[];
  formData: FormDataItem[];
  i18n: object;
  typeUserDisabledAttrs: object;
  fields: CustomFieldData[];
  onAddField: (fieldId: number) => void;
}

interface CustomFieldData {
  label: string;
  type: string;
  subtype: string;
  icon: string;
}

interface FormDataItem {
  type?: string;
  subtype?: string;
  label?: string;
  access?: boolean;
  required?: boolean;
  inline?: boolean;
  name?: string;
  other?: boolean;
  values?: Array<{ label: string; value: string; selected: boolean }>;
}

interface FormBuilderInstance {
  formData: string;
  actions: {
    clearFields: () => void;
  };
}

const intialState: CreateForm = {
  type: 'OTHERS',
  name: '',
  template: [],
};

const initialValues: FormTypeBuilder = {
  name: '',
};

function FormBuilder() {
  const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
  const fb = createRef<HTMLDivElement>();
  const formRef = useRef<FormikProps<FormTypeBuilder>>(null);
  const [formBuilder, setFormBuilder] = useState<FormBuilderInstance | null>(
    null,
  );
  const [formRender, setFormRender] = useState<object>({});
  const [formDetails, setFormDetails] = useState<CreateForm>(intialState);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState<string>('');
  const [toastrId, setToastrId] = useState<string>('Client.addtoastr');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [open, setOpen] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(true);
  const [isExist, setIsExist] = useState<boolean>(false);
  const [clearModal, setClearModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [cancelButtonLabelId, setCancelButtonLabelId] =
    useState<string>('cancelText');
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [numFieldd, setNumFieldd] = useState(0);
  const [fieldRemoved, setFieldRemoved] = useState<boolean>(false);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const navigate = useNavigate();

  const validationSchema = Yup.object({
    name: Yup.string().required('formBuilder.Name'),
  });

  const nameValidation = (value: string): string | undefined => {
    const trimmedValue = value?.replace(/^\s+/, '');
    const regex = /^[A-Za-z. -\d]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  useEffect(() => {
    if (numFieldd > 40 && !fieldRemoved) {
      setOpen(true);
      setToastrVariable('error');
      setToastrId('formBuilder.ElementsRestriction');
      setToastrDefaultMessage('Cannot add more than 40 elements to a form');
      setSaveDisabled(true);
    }
  }, [numFieldd]);

  useEffect(() => {
    const options: FormbuilderOptions = {
      controlPosition: 'left',
      disableFields: [
        'paragraph',
        'number',
        'hidden',
        'file',
        'button',
        'autocomplete',
        'classname',
        'select',
        'text',
        'checkbox-group',
        'textarea',
        'radio-group',
        'date',
        'header',
      ],
      disabledActionButtons: ['data', 'clear', 'save'],
      disabledAttrs: [
        'className',
        'required',
        'name',
        'access',
        'inline',
        'max',
        'min',
        'step',
        'description',
      ],
      formData: [],
      i18n: {
        override: {
          'en-US': {
            getStarted: 'Drag and drop items here from the left panel',
          },
        },
      },
      typeUserDisabledAttrs: {
        date: ['placeholder', 'min', 'max', 'step'],
        textarea: ['rows', 'subtype'],
      },
      onAddField: function () {
        setCancelButtonLabelId('clear.btn');
        setSaveDisabled(false);
        setFieldRemoved(false);
        setNumFieldd((prevNumFieldd) => {
          const newNumFieldd = prevNumFieldd + 1;
          return newNumFieldd;
        });
      },
      fields: [
        {
          label: 'Checkbox Group',
          type: 'checkbox-group',
          subtype: 'checkbox-group',
          icon: `<img src="${checkB}" style="margin-bottom: -14%;" />`,
        },
        {
          label: 'Date Field',
          type: 'date',
          subtype: 'date',
          icon: `<img src="${dateIcon}" style="margin-bottom: -14%;" />`,
        },
        {
          label: 'Radio Group',
          type: 'radio-group',
          subtype: 'radio-group',
          icon: `<img src= "${radioIcon}" style="margin-bottom: -14%;"/>`,
        },
        {
          label: 'Text Area',
          type: 'textarea',
          subtype: 'textarea',
          icon: `<img src= "${textAreaIcon}"  style="margin-bottom: -14%;"/>`,
        },
        {
          label: 'Header',
          type: 'header',
          subtype: 'header',
          icon: `<img src= "${headerIcon}" style="margin-bottom: -14%;"/>`,
        },
      ],
    };

    if (!formBuilder?.formData) {
      setFormBuilder(
        window.$(fb.current!).formBuilder(options) as FormBuilderInstance,
      );
    }
  }, []);

  function convertHeaderLabelToString(
    data: Array<Record<string, string>>,
  ): Array<Record<string, string>> {
    return data.map((item) => {
      if (item.type === 'header') {
        const div = document.createElement('div');
        div.innerHTML = item.label;
        return {
          ...item,
          label: div.textContent || div.innerText || '',
        };
      }
      return item;
    });
  }

  async function saveData() {
    if (formBuilder) {
      const parsedFormData = JSON.parse(formBuilder.formData);
      const convertedData = convertHeaderLabelToString(parsedFormData);
      if (Object.entries(parsedFormData).length > 40) {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('formBuilder.ElementsRestriction');
        setToastrDefaultMessage('Cannot add more than 40 elements to a form');
        setSaveDisabled(true);
      } else {
        setFormRender(JSON.parse(formBuilder.formData));
        setFormDetails((prevFormDetails) => ({
          ...prevFormDetails,
          template: convertedData,
        }));
      }
    }
  }

  document.addEventListener('fieldRemoved', () => {
    setFieldRemoved(true);
    setNumFieldd(numFieldd - 1);
    if (formBuilder) {
      const parsedFormData = JSON.parse(formBuilder.formData);
      if (Object.entries(parsedFormData).length === 1) {
        setCancelButtonLabelId('cancelText');
        setSaveDisabled(true);
      }

      if (Object.entries(parsedFormData).length <= 41) {
        setSaveDisabled(false);
      }
    }
  });

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (formDetails.template.length > 0) {
      for (const obj of formDetails.template) {
        if (
          !obj.hasOwnProperty('label') ||
          /^\s*$/.test((obj.label ?? '').replace(/&nbsp;/g, ''))
        ) {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('formBuilder.LabelMandatory');
          setToastrDefaultMessage('Label is mandatory for all the fields');
          setTimeout(() => {
            setOpen(false);
          }, 2000);
          return;
        }
      }

      if (formDetails.template.length > 0) {
        setSaveDisabled(true);
        toggleLoader(true);
        addForm(formDetails, employeeId)
          .then(async (response) => {
            toggleLoader(false);
            setOpen(true);
            setToastrId(response.message);
            setToastrDefaultMessage(response.message);
            setToastrVariable('success');
            setTimeout(() => {
              navigate(FORM_BUILDER_LIST_DISPLAY);
            }, 1000);
          })
          .catch(async (error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            setToastrId('failed');
            setToastrDefaultMessage(error);
          });
      }
    }
  }, [formRender]);

  function clearData() {
    if (formBuilder) {
      const parsedFormData = JSON.parse(formBuilder.formData);
      if (Object.entries(parsedFormData).length === 0) {
        setCancelModal(true);
      } else {
        setClearModal(true);
      }
    }
  }

  const duplicateCheck = () => {
    if (formRef.current && formRef.current?.isValid) {
      toggleLoader(true);
      if (formDetails) {
        checkDuplicate(formDetails.name)
          .then(async (response: CheckExistFormName) => {
            if (response.nameExists === true) {
              setIsExist(true);
              toggleLoader(false);
            } else {
              setIsExist(false);
              setModalVisible(false);
              toggleLoader(false);
            }
          })
          .catch(async (error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            setToastrId('internetCheckConnectionError');
            setToastrDefaultMessage(error);
          });
      }
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box component="main">
      <Box component="section">
        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={0.2} lg={0.2} className="mt-xs">
              <CustomBackArrow
                onClick={() => {
                  if (formBuilder) {
                    const parsedFormData = JSON.parse(formBuilder.formData);
                    if (Object.entries(parsedFormData).length === 0) {
                      navigate(FORM_BUILDER_LIST_DISPLAY);
                    } else {
                      setCancelModal(true);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={1.7} lg={1.7}>
              <TitleText
                labelid="formBuilder.CreateForm"
                defaultlabel="Create Form"
              />
            </Grid>
            <Grid item xs={10} lg={10}>
              <Box
                component="div"
                sx={{ wordBreak: 'break-all', wordWrap: 'break-word' }}
              >
                <TitleText label={`${formDetails.name}`} defaultlabel="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box component="section">
          <Box component="div" ref={fb}></Box>
        </Box>
        <Box component="div">
          <Grid
            className="flex__ justifyContent-FlexEnd pt-md pb-lg "
            container
            direction="row"
            alignItems="right"
          >
            <Grid item>
              <Box component="div">
                <ButtonComponent
                  className="btn-primary btn-cancel btn_width_90px mr-md"
                  variantType="outlined"
                  labelId={cancelButtonLabelId}
                  defaultLabelId="Clear"
                  onClick={clearData}
                />

                <ButtonComponent
                  className="btn-primary btn-submit btn_width_90px"
                  variantType="contained"
                  type="submit"
                  labelId="formBuilder.Save"
                  defaultLabelId="Save"
                  onClick={saveData}
                  disabled={saveDisabled}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box component="div">
          <Dialog
            open={modalVisible}
            keepMounted
            onClose={() => ({})}
            fullWidth
            aria-describedby="alert-dialog-slide-description"
            className="formBuilderModal"
          >
            <DialogContent className="p-md">
              <MediumTypography
                labelid={'formBuilder.FormNameEnter'}
                defaultlabel="Enter Form Name"
                fontweight={1000}
                textColor="#2a4241"
              />
              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                innerRef={formRef}
                enableReinitialize={true}
                onSubmit={() => duplicateCheck()}
                validationSchema={validationSchema}
              >
                {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                  <Box component="div">
                    <Box component="div" className="mt-lg">
                      <Textinput
                        name="name"
                        labelid={'formBuilder.NameText'}
                        defaultlabelid="Name"
                        Required={true}
                        inputProps={{
                          maxLength: 100,
                        }}
                        handlechange={(value) => {
                          setIsExist(false);
                          const inputValue = value;
                          const validatedValue = nameValidation(inputValue);
                          if (validatedValue !== undefined) {
                            const str = validatedValue;
                            const trimmedString = str.trimEnd();
                            setFormDetails((prevFormDetails) => ({
                              ...prevFormDetails,
                              name: trimmedString,
                            }));
                            setFieldValue('name', value);
                          }
                        }}
                        type="text"
                        Value={values.name}
                        disabled={false}
                      />
                      {(errors.name && touched.name) || isExist ? (
                        <Box className="loginError">
                          <MediumTypography
                            labelid={
                              errors.name && touched.name
                                ? errors.name
                                : 'Name already exists'
                            }
                            defaultlabel="Enter Name"
                            sxProps={{
                              color: 'red',
                              marginLeft: '10px',
                              fontSize: '14px',
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                    <Box>
                      <Grid
                        className="flex__ justifyContent-FlexEnd pt-lg "
                        container
                        direction="row"
                        alignItems="right"
                      >
                        <Grid item>
                          <ButtonComponent
                            className="btn-primary btn-cancel btn_width_90px mr-md"
                            variantType="outlined"
                            labelId="cancelText"
                            defaultLabelId="Cancel"
                            onClick={() => navigate(FORM_BUILDER_LIST_DISPLAY)}
                          />

                          <ButtonComponent
                            className="btn-primary btn-submit"
                            variantType="contained"
                            labelId="formBuilder.Continue"
                            defaultLabelId="Save"
                            onClick={handleSubmit}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
      {clearModal && (
        <ModalPopup
          open={clearModal}
          onCancel={() => {
            setClearModal(false);
          }}
          description={'formBuilder.FormContentClear'}
          onOk={() => {
            if (formBuilder) {
              setCancelButtonLabelId('cancelText');
              formBuilder.actions.clearFields();
              setFormRender({});
              setClearModal(false);
              setSaveDisabled(true);
              setNumFieldd(0);
              setFieldRemoved(false);
            }
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}

      {cancelModal && (
        <ModalPopup
          open={cancelModal}
          onCancel={() => {
            setCancelModal(false);
          }}
          description={'formBuilder.NaviGateBack'}
          onOk={() => navigate(FORM_BUILDER_LIST_DISPLAY)}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}
    </Box>
  );
}

export default FormBuilder;
