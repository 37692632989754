import { Box, Card } from '@mui/material';
import { ReactComponent as GraphicBottomRight } from '../../assets/images/Graphic-BottomRight.svg';
import { ReactComponent as GraphicBottomLeft } from '../../assets/images/Graphic-righttop.svg';
import MediumTypography from '../formlib/MediumTypography';
import { FC } from 'react';
import ButtonComponent from '../formlib/ButtonComponent';

interface EmptyScreenProps {
  titleLabelId: string;
  defaultTitleText?: string;
  buttonLabelId?: string;
  defaultButtonTitleText?: string;
  className?: string;
  showButton: boolean;
  onButtonClick?: () => void;
}

const EmptyScreen: FC<EmptyScreenProps> = ({
  titleLabelId,
  defaultTitleText,
  buttonLabelId,
  defaultButtonTitleText,
  className,

  onButtonClick,
  showButton,
}) => {
  return (
    <Card sx={{ height: '95vh', alignContent: 'center' }} className={className}>
      <GraphicBottomLeft className="GraphicBottomRightEmty SSP_List_EmptyscreenLeft" />

      <GraphicBottomRight className="GraphicBottomLeftEmty SSP_List_EmptyscreenRight" />

      <Box className="textCenter">
        <MediumTypography
          labelid={titleLabelId}
          defaultlabel={defaultTitleText}
          sxProps={{
            fontSize: '24px',
            color: '#97a6a5',
            zIndex: 1,
            lineHeight: '30x',
            margin: 0,
          }}
        />
        {showButton && (
          <Box className="textCenter">
            <ButtonComponent
              className="btn-primary btn-submit mt-md"
              variantType="contained"
              labelId={buttonLabelId}
              defaultLabelId={defaultButtonTitleText}
              onClick={onButtonClick}
            />
          </Box>
        )}
      </Box>

      {/* <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <MediumTypography
          labelid={titleLabelId}
          defaultMessage={defaultTitleText}
          sxProps={{
            fontSize: '24px',
            color: '#97a6a5',
            zIndex: 1,
          }}
        />
        {showButton && (
          <ButtonComponent
            className="btn-primary btn-submit mt-md"
            variantType="contained"
            labelId={buttonLabelId}
            defaultLabelId={defaultButtonTitleText}
            onClick={onButtonClick}
          />
        )}
      </Box> */}
    </Card>
  );
};

export default EmptyScreen;
