import { Box, Tooltip } from '@mui/material';
import { TaskManagerItem } from '../../services/configApi/employees/employeeServices';
import { FC } from 'react';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import { useIntl } from 'react-intl';
import { getEmployeeDashboardColor } from '../../utils/utilities';

interface TaskManagerProps {
  tasks: TaskManagerItem[];
  handleNavigation: () => void;
}

const TaskManagerRow: FC<{ task: TaskManagerItem; index: number }> = ({
  task,
  index,
}) => {
  const rctl = useIntl();

  const handleName = task.firstName
    ?.concat(' ')
    .concat(task.lastName ? task.lastName : '');

  return (
    <Box sx={{ width: '98%' }}>
      {index !== 0 && <Box className={'defaultHorizontalLine'} />}
      <Box className={'flex__'}>
        <Box sx={{ flex: 0.3 }} className="pr-sm">
          <Box sx={{ width: '180px' }}>
            <Tooltip
              title={handleName && handleName.length > 50 ? handleName : ''}
              followCursor
              arrow={true}
              sx={{ maxWidth: 'min-content' }}
            >
              <Box>
                <MediumTypography
                  label={handleName && handleName !== null ? handleName : '-'}
                  textColor="#2A4241"
                  sxProps={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box sx={{ flex: 0.5 }} className="pr-sm">
          <Box sx={{ width: '300px' }}>
            <Tooltip
              title={task.name && task.name.length > 50 ? task.name : ''}
              followCursor
              arrow={true}
              sx={{ maxWidth: 'min-content' }}
            >
              <Box>
                <MediumTypography
                  label={task.name !== null ? task.name : '-'}
                  textColor="#2A4241"
                  sxProps={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box className={'flex__'} sx={{ flex: 0.2 }}>
          <MediumTypography
            label={task.dueDate !== null ? task.dueDate : '-'}
          />
        </Box>
        <Box sx={{ flex: 0.2, textAlign: 'right' }}>
          <MediumTypography
            sxProps={{
              color: getEmployeeDashboardColor(
                task.status !== null ? task.status : '',
              ),
            }}
            label={
              task.status !== null
                ? rctl.formatMessage({ id: task.status })
                : '-'
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

const TaskManager: FC<TaskManagerProps> = ({ tasks, handleNavigation }) => {
  return (
    <Box className={'p-md'}>
      <Box className={'flex__ pt-xm'}>
        <MediumTypography
          sxProps={{ flexGrow: 1 }}
          className="mainText-lg"
          labelid="taskManagerText"
          defaultlabel="Task Manager"
        />
        <Box sx={{ cursor: 'pointer' }} onClick={handleNavigation}>
          <ArrowRight />
        </Box>
      </Box>

      <Box
        className={'pt-md'}
        sx={{
          overflowY: 'scroll',
          height: '195px',
        }}
      >
        {tasks.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '8%',
            }}
          >
            <MediumTypography
              sxProps={{ fontWeight: 'bold' }}
              labelid="noTasksAddedText"
              defaultlabel="No tasks added yet"
            />
          </Box>
        )}
        {tasks.map((task, index) => (
          <TaskManagerRow task={task} index={index} />
        ))}
      </Box>
    </Box>
  );
};

export default TaskManager;
