// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#viewEmployees.MuiTypography-root {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 12px;
  line-height: 14.4px;
  color: #008c82;
}

#icon-view {
  display: flex;
}
#eye-icon {
  position: relative;
  right: 4.67px;
}

#view-icon-view {
  display: flex;
  position: relative;
  right: 6%;
  margin-top: 3%;
  cursor: pointer;
}
.view-eye-icon {
  position: relative;
  right: 4.67px;
  width: 16px;
  height: 16px;
}

#edit-icon {
  position: relative;
  right: 6px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProgramManagmentFiles/ProgramManagmentTable.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,eAAe;EACf,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,SAAS;EACT,cAAc;EACd,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ","sourcesContent":["#viewEmployees.MuiTypography-root {\n  font-family: 'Lato-Regular', sans-serif;\n  font-size: 12px;\n  line-height: 14.4px;\n  color: #008c82;\n}\n\n#icon-view {\n  display: flex;\n}\n#eye-icon {\n  position: relative;\n  right: 4.67px;\n}\n\n#view-icon-view {\n  display: flex;\n  position: relative;\n  right: 6%;\n  margin-top: 3%;\n  cursor: pointer;\n}\n.view-eye-icon {\n  position: relative;\n  right: 4.67px;\n  width: 16px;\n  height: 16px;\n}\n\n#edit-icon {\n  position: relative;\n  right: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
