import { Bar } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  TooltipItem,
} from 'chart.js';
import { FC } from 'react';
import MediumTypography from '../formlib/MediumTypography';
import { Box } from '@mui/material';

ChartJS.register(annotationPlugin, CategoryScale, LinearScale, BarElement);

export interface GradientColor {
  startColor: string;
  endColor: string;
}

export interface ProductivityProps {
  labels: string[];
  data: string[];
  backgroundColors: string[];
  barPercentage: number;
  horizontalValue: number;
  barHeight: string;
}

const BarChart: FC<ProductivityProps> = ({
  data,
  labels,
  barHeight,
  horizontalValue,
  backgroundColors,
  barPercentage,
}) => {
  return (
    <Box>
      <Box>
        <MediumTypography
          labelid={'productivityText'}
          defaultlabel="Productivity"
          sxProps={{
            fontSize: '16px',
            fontWeight: 'bold',
          }}
        />
        <MediumTypography
          label="100%"
          sxProps={{
            fontSize: '12px',
          }}
        />
      </Box>

      <Bar
        height={barHeight}
        options={{
          plugins: {
            annotation: {
              annotations: [
                {
                  type: 'line',
                  value: horizontalValue,
                  scaleID: 'y',
                  borderColor: '#F96300',
                  borderWidth: 1,
                  borderDash: [5, 5], // Adjust the dash pattern to control the style of the dotted line
                },
              ],
            },
            tooltip: {
              caretSize: 0,
              displayColors: false,
              backgroundColor: '#FFF',
              titleColor: '#37D183',
              bodyFont: {
                size: 14,
                family: 'Lato-Regular',
              },
              titleFont: {
                size: 14,
                family: 'Lato-Regular',
              },
              callbacks: {
                label: function (tooltipItem: TooltipItem<'bar'>) {
                  if (tooltipItem.dataset.data[tooltipItem.dataIndex] === 0) {
                    return '';
                  }
                  return `${tooltipItem.dataset.data[tooltipItem.dataIndex]} %`;
                },
                labelTextColor: function () {
                  return '#2A4241';
                },
              },
            },
          },
          scales: {
            y: {
              max: 100,
              ticks: {
                display: false,
                maxTicksLimit: 8,
              },
            },
            x: {
              ticks: {
                font: {
                  size: 10,
                },
              },
              grid: {
                display: false,
              },
            },
          },
        }}
        data={{
          labels: labels,
          datasets: [
            {
              type: 'bar',
              label: '',
              data: data,
              backgroundColor: backgroundColors,
              barPercentage: barPercentage,
            },
          ],
        }}
      />
    </Box>
  );
};

export default BarChart;
