import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export type SubServiceCodes = {
  id?: string;
  codeId: number;
  code: string;
  description: string;
  isAddedCode?: boolean;
  isDuplicateCode?: boolean;
};

export type ServiceCode = {
  serviceCode: SubServiceCodes[];
};

export type ServiceCodeDto = {
  serviceCode: SubServiceCodes[];
  count: number;
};

export type SerivceAddDto = {
  addedCode: SubServiceCodes[];
  duplicateCode?: SubServiceCodes[];
};

export const getAllServiceCodes = async (
  searchquery: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
): Promise<ServiceCodeDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ServiceCodeDto>(
      `serviceCode/all?search=${searchquery}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType}`,
    ),
  );
};

export const addServiceCodes = async (
  requestBody: ServiceCode,
): Promise<SerivceAddDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<ServiceCode, SerivceAddDto>(
      `serviceCode`,
      requestBody,
    ),
  );
};

export const editServiceCodes = async (
  requestBody: SubServiceCodes,
  id: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<SubServiceCodes, ApiMessage>(
      `serviceCode?id=${id}`,
      requestBody,
    ),
  );
};

export const deleteServiceCodes = async (id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `serviceCode?id=${id}`,
    ),
  );
};
