import React, { useState, useEffect, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import French from './languagelocalisation/fr.json';
import English from './languagelocalisation/en-us.json';
import { useNavigate } from 'react-router-dom';
import { PROGRAMS_KEY, SELECTED_PROGRAM_KEY } from './services/Constant';
import { OptionType } from './utils/type';
const local: string = navigator.language;

export interface ContextType {
  locale: string;
  selectLanguage: () => void;
  programs: OptionType[];
  updatePrograms: (newPrograms: OptionType[]) => void;
  selectedProgram: OptionType | undefined;
  updateSelectedProgram: (
    newSelectedProgram: OptionType,
    canNavigate: boolean,
  ) => void;
}

export const Context = React.createContext<ContextType>({
  locale: 'en-US',

  selectLanguage: () => {},

  programs: [],

  updatePrograms: () => {},

  selectedProgram: undefined,

  updateSelectedProgram: () => {},
});
interface WrapperProps {
  children: ReactNode;
}

const Wrapper = (props: WrapperProps) => {
  const { children } = props;
  const [myLanguage, setMyLanguage] = useState({});
  const [locale, setLocale] = useState(local);
  const [programs, setPrograms] = useState<OptionType[]>([]);
  const [selectedProgram, setSelectedProgram] = useState<OptionType>();
  const myCustomErrorFunction = () => {};

  const navigate = useNavigate();

  function updatePrograms(newPrograms: OptionType[]) {
    setPrograms(newPrograms);
    localStorage.setItem(PROGRAMS_KEY, JSON.stringify(newPrograms));
  }

  function updateSelectedProgram(
    newSelectedProgram: OptionType,
    canNavigate: boolean,
  ) {
    setSelectedProgram(newSelectedProgram);
    localStorage.setItem(
      SELECTED_PROGRAM_KEY,
      JSON.stringify(newSelectedProgram),
    );
    if (canNavigate) {
      navigate('/');
    }
  }

  function selectLanguage() {
    const newlocale = local;
    setLocale(newlocale);
    if (local === 'fr') {
      setMyLanguage(French);
    } else {
      setMyLanguage(English);
    }
  }

  useEffect(() => {
    const programsFromLocalStorage = localStorage.getItem(PROGRAMS_KEY);
    const selectedProgramFromLocalStorage =
      localStorage.getItem(SELECTED_PROGRAM_KEY);

    if (
      programsFromLocalStorage != undefined &&
      programsFromLocalStorage != '' &&
      programsFromLocalStorage != null &&
      programsFromLocalStorage.length != 0 &&
      selectedProgramFromLocalStorage != undefined &&
      selectedProgramFromLocalStorage != '' &&
      selectedProgramFromLocalStorage != null
    ) {
      updatePrograms(JSON.parse(programsFromLocalStorage));
      updateSelectedProgram(JSON.parse(selectedProgramFromLocalStorage), false);
    }
  }, []);

  return (
    <Context.Provider
      value={{
        locale,
        selectLanguage,
        programs,
        updatePrograms,
        selectedProgram,
        updateSelectedProgram,
      }}
    >
      <IntlProvider
        locale={local}
        messages={myLanguage}
        onError={myCustomErrorFunction}
      >
        {children}
      </IntlProvider>
    </Context.Provider>
  );
};

export default Wrapper;
