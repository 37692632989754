import { Box } from '@mui/system';
import React, { useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as BackArrow } from '../../../src/assets/images/calenderBackArrow.svg';
import { ReactComponent as FrontArrow } from '../../../src/assets/images/calenderFrontArrow.svg';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

type MonthViewSelectorProps = {
  initialMonth: number;
  onMonthChange: (month: number) => void;
  height?: number | string;
};

const MonthViewSelector = ({
  initialMonth,
  onMonthChange,
  height,
}: MonthViewSelectorProps) => {
  const [selectedMonth, setSelectedMonth] = useState(initialMonth);

  const handlePrevious = () => {
    if (selectedMonth > 0) {
      const newMonth = selectedMonth - 1;
      setSelectedMonth(newMonth);
      if (onMonthChange) {
        onMonthChange(newMonth);
      }
    }
  };

  const handleNext = () => {
    if (selectedMonth < monthNames.length - 1) {
      const newMonth = selectedMonth + 1;
      setSelectedMonth(newMonth);
      if (onMonthChange) {
        onMonthChange(newMonth);
      }
    }
  };

  return (
    <Box
      className="flex__ justifyContent-Center align__items__center"
      gap={0.3}
    >
      <Box
        sx={{
          backgroundColor: '#ffffff',
          height: height ? height : '32px',
          cursor: selectedMonth > 0 ? 'pointer' : 'not-allowed',
        }}
        className="flex__ justifyContent-Center align__items__center p-xs borderTopLeftRadius4 borderBottomLeftRadius4"
        onClick={handlePrevious}
      >
        <BackArrow />
      </Box>
      <Box className="width100px" sx={{ backgroundColor: '#ffffff' }}>
        <MediumTypography
          label={monthNames[selectedMonth]}
          fontweight={500}
          fontSize="12px"
          textstyle="center"
          sxProps={{
            height: height ? height : '32px',
          }}
          className="flex__ width__100 justifyContent-Center align__items__center"
          lineHeight="16px"
        />
      </Box>
      <Box
        sx={{
          backgroundColor: '#ffffff',
          height: height ? height : '32px',
          cursor: selectedMonth < 11 ? 'pointer' : 'not-allowed',
        }}
        className="flex__ justifyContent-Center align__items__center p-xs borderTopRightRadius4 borderBottomRightRadius4"
        onClick={handleNext}
      >
        <FrontArrow />
      </Box>
    </Box>
  );
};

export default MonthViewSelector;
