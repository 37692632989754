import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Paper,
  Grid,
  AlertColor,
} from '@mui/material';
import { FC, useEffect, useState, useContext } from 'react';
import MediumTypography from '../MediumTypography';
import ButtonComponent from '../ButtonComponent';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchBox from '../SearchBox';
import Checkbox from '@mui/material/Checkbox';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import SnackBarComponent from '../SnackBarComponent';
import {
  EditGroups,
  GroupEmployees,
  ScreenPermission,
  UserGroupsType,
  editGroups,
  getAllEmployeesWithGroupName,
  getGroupsById,
} from '../../../services/configApi/userGroups/userGroupServices';
import _ from 'lodash';

interface AddEmployeeGroup {
  open: boolean;
  handleClose: () => void;
  groupId: string;
  successOrErrorText: (val: string) => void;
}

const AddEmployeeGroupModal: FC<AddEmployeeGroup> = ({
  open,
  handleClose,
  groupId,
  successOrErrorText,
}) => {
  const [employees, setEmployees] = useState<GroupEmployees[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<
    GroupEmployees[]
  >([]);
  const [addedEmployees, setAddedEmployees] = useState<GroupEmployees[]>([]);
  const [selectRemoveArr, setSelectRemoveArr] = useState<GroupEmployees[]>([]);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [mainEmployeeList, setMainEmployeeList] = useState<GroupEmployees[]>(
    [],
  );
  const [backUpAddedEmp, setBackUpAddedEmp] = useState<GroupEmployees[]>([]);
  const [employeeId, setEmployeeId] = useState<string[]>([]);
  const [removedEmployeeId, setRemovedEmployeeId] = useState<string[]>([]);
  const [groupName, setGroupName] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [permissions, setPermissions] = useState<ScreenPermission[]>([]);
  const [openToastr, setOpenToastr] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [removeButtonDisable, setRemoveButtonDisable] = useState(true);

  const filterEmployeeList = (allEmployees: GroupEmployees[]) => {
    const filterEmployees = mainEmployeeList.filter(
      (employee: GroupEmployees) =>
        !allEmployees.some(
          (selectedEmployee) => selectedEmployee.name === employee.name,
        ),
    );
    return filterEmployees;
  };

  const handleSearchInputChange = (val: string) => {
    const inputValue = val;

    if (val !== '') {
      const filtered = employees.filter((employee) =>
        employee.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setEmployees(filtered);
    } else {
      const getFilteredEmpl = filterEmployeeList(backUpAddedEmp);
      setEmployees(getFilteredEmpl);
    }
  };

  const handleSearhAddedEmpl = (val: string) => {
    const inputValue = val;

    if (val !== '') {
      const filtered = backUpAddedEmp.filter((employee) =>
        employee.name.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setAddedEmployees([...new Set(filtered)]);
    } else {
      setAddedEmployees(backUpAddedEmp);
    }
  };

  const getEmployeeLists = () => {
    toggleLoader(true);
    getAllEmployeesWithGroupName()
      .then((response) => {
        toggleLoader(false);
        setMainEmployeeList(response.employees);

        getGroupInfo(response.employees);
      })
      .catch(() => {
        toggleLoader(false);
        setToastrVariable('error');
      });
  };

  const filterEmployees = (emp: GroupEmployees[], response: UserGroupsType) => {
    return emp.filter(
      (employee: GroupEmployees) =>
        !response.employees.some(
          (selectedEmployee) => selectedEmployee.userId === employee.userId,
        ),
    );
  };

  const getGroupInfo = (emp: GroupEmployees[]) => {
    if (groupId) {
      toggleLoader(true);
      getGroupsById(groupId)
        .then((response) => {
          toggleLoader(false);
          setGroupName(response.groupName);
          setCreatedBy(response.createdBy);
          const responsePermissions: ScreenPermission[] = response.screens.map(
            (screen) => ({
              id: screen.id,
              screenBackendId: screen.screenBackendId,
              permissionId: screen.permissionId,
              viewPermission: screen.viewPermission,
              addPermission: screen.addPermission,
              editPermission: screen.editPermission,
              deletePermission: screen.deletePermission,
            }),
          );
          setPermissions(responsePermissions);
          setEmployees(filterEmployees(emp, response));
          setAddedEmployees(response.employees);
          setBackUpAddedEmp(response.employees);
        })
        .catch((err) => {
          toggleLoader(false);
          throw err;
        });
    }
  };

  useEffect(() => {
    getEmployeeLists();
  }, []);

  const handleChanges = (
    event: React.ChangeEvent<HTMLInputElement>,
    employee: GroupEmployees,
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setButtonDisable(false);
      setRemoveButtonDisable(true);
      setSelectedCheckboxes((prevSelected) => [...prevSelected, employee]);
      setSelectRemoveArr([]);
    } else {
      setSelectedCheckboxes((prevSelected) => {
        const updatedSelected = prevSelected.filter(
          (item) => item.userId !== employee.userId,
        );

        if (updatedSelected.length === 0) {
          setButtonDisable(true);
        }

        return updatedSelected;
      });

      setEmployeeId((prevSelected) =>
        prevSelected.filter((item) => item !== employee.userId),
      );
    }
  };

  const clearAllEmployees = () => {
    setSelectedCheckboxes([]);
    setButtonDisable(true);
  };

  const removeAllAddedEmployees = () => {
    setSelectRemoveArr([]);
    setRemoveButtonDisable(true);
  };

  const updateEmployeeIds = (employee: GroupEmployees) => {
    setRemovedEmployeeId((prevRemoved) =>
      prevRemoved.filter((id) => id !== employee.userId),
    );
    setEmployeeId((prevSelected) => {
      if (!prevSelected.includes(employee.userId)) {
        return [...prevSelected, employee.userId];
      }
      return prevSelected;
    });
  };

  const addEmployeeToGroup = () => {
    setButtonDisable(true);
    selectedCheckboxes.forEach((employee) => {
      updateEmployeeIds(employee);
    });

    setBackUpAddedEmp((prevSelected) => [
      ...prevSelected,
      ...selectedCheckboxes,
    ]);

    const updatedAddedEmployees = [
      ...addedEmployees,
      ...selectedCheckboxes.filter(
        (employee) =>
          !addedEmployees.some((added) => added.userId === employee.userId),
      ),
    ];

    setAddedEmployees([...new Set(updatedAddedEmployees)]);

    const updatedEmployees = employees.filter(
      (employee: GroupEmployees) =>
        !selectedCheckboxes.some(
          (selectedEmployee) => selectedEmployee.userId === employee.userId,
        ),
    );

    setEmployees(updatedEmployees);

    const reAddedForRemovalEmpl = employees.map((employee) => employee.userId);

    const updatedSelectedCheckboxes = selectRemoveArr.filter(
      (employee) => !reAddedForRemovalEmpl.includes(employee.userId),
    );

    setSelectRemoveArr(updatedSelectedCheckboxes);
  };

  const handleRemovalChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    removedEmployees: GroupEmployees,
  ) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setButtonDisable(true);
      setRemoveButtonDisable(false);
      setSelectRemoveArr((prevSelected) => [...prevSelected, removedEmployees]);
      setSelectedCheckboxes([]);
    } else {
      setSelectRemoveArr((prevSelected) => {
        const updatedSelected = prevSelected.filter(
          (item) => item.userId !== removedEmployees.userId,
        );

        // Disable remove button if no items are selected
        if (updatedSelected.length === 0) {
          setRemoveButtonDisable(true);
        }

        return updatedSelected;
      });
      setRemovedEmployeeId((prevSelected) =>
        prevSelected.filter((item) => item !== removedEmployees.userId),
      );
    }
  };

  const removeEmployees = () => {
    setRemoveButtonDisable(true);

    selectRemoveArr.forEach((employee) => {
      setRemovedEmployeeId((prevSelected) => [
        ...prevSelected,
        employee.userId,
      ]);
    });

    const updatedEmployeesss = [
      ...employees,
      ...selectRemoveArr.filter(
        (selectedEmployee) =>
          !employees.some(
            (employee) => employee.userId === selectedEmployee.userId,
          ),
      ),
    ];

    setEmployees(updatedEmployeesss);

    const updatedEmployees = addedEmployees.filter(
      (employee: GroupEmployees) =>
        !selectRemoveArr.some(
          (selectedEmployee) => selectedEmployee.userId === employee.userId,
        ),
    );

    setAddedEmployees([...new Set(updatedEmployees)]);

    const getSelectedBackup = backUpAddedEmp.filter(
      (selectedEmployees) =>
        !updatedEmployeesss.some(
          (selectedEmployee) =>
            selectedEmployee.userId === selectedEmployees.userId,
        ),
    );

    setBackUpAddedEmp(getSelectedBackup);
  };

  const update = () => {
    toggleLoader(true);
    const filteredEmployeeId = employeeId.filter(
      (id) => !removedEmployeeId.includes(id),
    );

    const obj: EditGroups = {
      groupName: groupName,
      screens: createdBy.toLowerCase() === 'default' ? [] : permissions,
      addEmployee: filteredEmployeeId,
      removeEmployee: [...new Set(removedEmployeeId)],
    };
    editGroups(groupId, obj)
      .then(() => {
        handleClose();
        successOrErrorText('Success');
        toggleLoader(false);
      })
      .catch(() => {
        setOpenToastr(true);
        setToastrVariable('error');
        setToastrId('UserGroups.updateError');
        setToastrDefaultMessage('Failed to Update Group');
        toggleLoader(false);
      });
  };

  const updateWithDebounce = _.debounce(update, 500);

  return (
    <>
      <SnackBarComponent
        open={openToastr}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <DialogTitle>
          <MediumTypography
            label={groupName}
            sxProps={{
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '16px',
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <div style={{ display: 'flex' }}>
              <div>
                <Box sx={{ flexGrow: 0, my: '10px' }}>
                  <MediumTypography
                    labelid="userGroup.employeeList"
                    defaultlabel="Employees List"
                    fontSize="16px"
                    fontweight={700}
                    textColor="rgba(151, 166, 165, 1)"
                  />
                </Box>
                <Box
                  sx={{
                    width: '40%',
                    my: '20px',
                    ml: '0px',
                  }}
                >
                  <Paper
                    sx={{
                      width: '350px',
                      border: '1px solid rgba(207, 222, 222, 1)',
                      boxShadow: 'none',
                      height: '300px',
                      overflowY: 'scroll',
                    }}
                  >
                    <Box sx={{ flexGrow: 0 }}>
                      <SearchBox
                        labelId="addUserGroup.employeeSearch"
                        defaultlabel="Search Employees"
                        backgroundColor="rgba(231, 240, 240, 1)"
                        sxProps={{
                          position: 'relative',
                          transform: 'translate(15px, 10px)',
                        }}
                        onChange={(e) => handleSearchInputChange(e)}
                      />
                    </Box>
                    <FormGroup sx={{ my: '20px', ml: '15px' }}>
                      {employees.map((option: GroupEmployees) => (
                        <FormControlLabel
                          key={option.userId}
                          control={
                            <Checkbox
                              onChange={(e) => handleChanges(e, option)}
                              sx={{ fontFamily: 'Lato-Regular' }}
                            />
                          }
                          value={option.name}
                          id="userCheckbox"
                          label={
                            <span
                              style={{
                                fontFamily: 'Lato-Regular',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '17px',
                              }}
                            >
                              {option.groupName !== null &&
                              option.groupName !== undefined
                                ? `${option.name} - ${option.groupName}`
                                : option.name}
                            </span>
                          }
                          labelPlacement="end"
                          checked={selectedCheckboxes.some(
                            (employee) => employee.name === option.name,
                          )}
                          sx={{ fontFamily: 'Lato-Regular' }}
                          disabled={
                            createdBy.toLowerCase() === 'default' &&
                            groupName.toLowerCase() === 'default'
                          }
                        />
                      ))}
                    </FormGroup>
                  </Paper>
                </Box>
                {(groupName.toLowerCase() !== 'default' ||
                  createdBy.toLowerCase() !== 'default') && (
                  <Box>
                    <Grid
                      container
                      direction="row"
                      alignItems="right"
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <Grid item sx={{ marginRight: '16px' }}>
                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="BlockTime.addButton"
                          defaultLabelId="Add"
                          disabled={buttonDisable}
                          onClick={addEmployeeToGroup}
                        />
                      </Grid>
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel"
                          variantType="contained"
                          defaultLabelId="Clear"
                          labelId="clear.btn"
                          onClick={clearAllEmployees}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </div>
              <div style={{ marginLeft: '10%' }}>
                <Box sx={{ flexGrow: 0, my: '10px' }}>
                  <MediumTypography
                    labelid="userGroup.addedemployeeList"
                    defaultlabel="Added Employees List"
                    fontSize="16px"
                    fontweight={700}
                    textColor="rgba(151, 166, 165, 1)"
                  />
                </Box>
                <Box
                  sx={{
                    width: '35%',
                    my: '20px',
                    ml: '0px',
                  }}
                >
                  <Paper
                    sx={{
                      width: '350px',
                      border: '1px solid rgba(207, 222, 222, 1)',
                      boxShadow: 'none',
                    }}
                  >
                    <Box sx={{ flexGrow: 0 }}>
                      <SearchBox
                        labelId="addUserGroup.employeeSearch"
                        defaultlabel="Search Employees"
                        backgroundColor="rgba(231, 240, 240, 1)"
                        sxProps={{
                          position: 'relative',
                          transform: 'translate(15px, 10px)',
                        }}
                        onChange={(e) => handleSearhAddedEmpl(e)}
                      />
                    </Box>
                    <FormGroup sx={{ my: '20px', ml: '15px' }}>
                      {addedEmployees.map((option: GroupEmployees) => (
                        <FormControlLabel
                          key={option.userId}
                          control={
                            <Checkbox
                              onChange={(e) => handleRemovalChange(e, option)}
                              sx={{ fontFamily: 'Lato-Regular' }}
                            />
                          }
                          value={option.name}
                          id="userCheckbox"
                          label={
                            <span
                              style={{
                                fontFamily: 'Lato-Regular',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '14px',
                                lineHeight: '17px',
                              }}
                            >
                              {option.name}
                            </span>
                          }
                          labelPlacement="end"
                          checked={selectRemoveArr.some(
                            (employee) => employee.name === option.name,
                          )}
                          sx={{ fontFamily: 'Lato-Regular' }}
                          disabled={
                            createdBy.toLowerCase() === 'default' &&
                            groupName.toLowerCase() === 'default'
                          }
                        />
                      ))}
                    </FormGroup>
                  </Paper>
                </Box>
                {(groupName.toLowerCase() !== 'default' ||
                  createdBy.toLowerCase() !== 'default') && (
                  <Box>
                    <Grid
                      container
                      direction="row"
                      alignItems="right"
                      sx={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <Grid item sx={{ marginRight: '16px' }}>
                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="addUser.remove"
                          defaultLabelId="Remove"
                          disabled={removeButtonDisable}
                          onClick={removeEmployees}
                        />
                      </Grid>
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel"
                          variantType="contained"
                          defaultLabelId="Clear"
                          labelId="clear.btn"
                          onClick={removeAllAddedEmployees}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </div>
            </div>
            <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
              <Grid
                container
                direction="row"
                alignItems="right"
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Grid item sx={{ marginRight: '16px' }}>
                  <ButtonComponent
                    className="btn-primary btn-cancel"
                    variantType="contained"
                    defaultLabelId="Cancel"
                    labelId="Contacts.cancelbtn"
                    onClick={handleClose}
                  />
                </Grid>
                {(groupName.toLowerCase() !== 'default' ||
                  createdBy.toLowerCase() !== 'default') && (
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="parentGuardian.update"
                      defaultLabelId="Update"
                      onClick={updateWithDebounce}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddEmployeeGroupModal;
