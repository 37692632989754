import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ASQSCORINGCHART } from './questionnaireUtils/ASQScoringChart';
import MediumTypography from '../../components/formlib/MediumTypography';
import ProgressLine from './questionnaireUtils/progressBar/ProgressLine';

type RowDataType = {
  cutOff: number;
  totalCutOff: number;
};

type ProgressDataType = {
  lowRisk: number;
  mediumRisk: number;
  highRisk: number;
};

type ASQScoreComponentProps = {
  rows: RowDataType[];
  progressValues: ProgressDataType;
};

const CellStyle = {
  border: '1px solid #97A6A5',
  width: '200px',
  height: '5px',
  padding: '8px',
};

const ASQScoreComponent = ({
  rows,
  progressValues,
}: ASQScoreComponentProps) => {
  return (
    <Box>
      <Box sx={{}}>
        <MediumTypography
          textColor="#2A4241"
          fontweight={600}
          fontSize="18px"
          labelid={'ASQConcernQuestionnairesInfoSummary.ScoringChartTitle'}
          defaultlabel="1. ASQ:SE-2 SCORING CHART:"
        />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ paddingLeft: '32px', marginTop: '8px' }}>
            {ASQSCORINGCHART.map((details) => (
              <ul style={{ margin: 0, padding: '0px' }}>
                <li>
                  <Typography
                    sx={{
                      display: 'inline',
                      marginRight: '24px',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    {details.chartDetails}
                  </Typography>
                </li>
              </ul>
            ))}
          </Box>
          <Box sx={{ marginLeft: '80px' }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    padding="none"
                    style={{ fontSize: 14, fontWeight: 600, ...CellStyle }}
                  >
                    Cutoff
                  </TableCell>
                  <TableCell
                    padding="none"
                    style={{ fontSize: 14, fontWeight: 600, ...CellStyle }}
                  >
                    Total Score
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.cutOff}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell
                      padding="none"
                      style={{ fontSize: 20, fontWeight: 700, ...CellStyle }}
                      component="th"
                      scope="row"
                    >
                      {row.cutOff}
                    </TableCell>
                    <TableCell
                      padding="none"
                      style={{ fontSize: 20, fontWeight: 700, ...CellStyle }}
                    >
                      {row.totalCutOff}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </Box>
      <Box>
        <MediumTypography
          textColor="#2A4241"
          fontweight={600}
          marginTop="22px"
          fontSize="18px"
          labelid={
            'ASQConcernQuestionnairesInfoSummary.ScoreInterpretationTitle'
          }
          defaultlabel="2. ASQ:SE-2 SCORE INTERPRETATION:"
        />
        <MediumTypography
          sxProps={{
            marginTop: '8px',
            marginBottom: '16px',
            marginLeft: '18px',
          }}
          textColor="#2A4241"
          fontweight={500}
          fontSize="14px"
          labelid={
            'ASQConcernQuestionnairesInfoSummary.ScoreInterpretationDescription'
          }
          defaultlabel="Review the approximate location of the client’s total score on the scoring graphic. Then, check off the area for the score results below."
        />
        <ProgressLine
          highRiskValue={progressValues.highRisk}
          visualParts={[
            {
              percentage: '60%',
              message: 'no or low risk',
              color: '#fff',
            },
            {
              percentage: '20%',
              message: 'monitor',
              color: '#97A6A5',
            },
            {
              percentage: '20%',
              message: 'refer',
              color: '#2A4241',
            },
          ]}
          data={[
            {
              value: progressValues.lowRisk,
            },
            {
              value: progressValues.mediumRisk,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

export default ASQScoreComponent;
