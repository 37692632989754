import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  Box,
  AlertColor,
  Grid,
  styled,
  Button,
  MenuProps,
  Theme,
  Menu,
  autocompleteClasses,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import Tooltip from '@mui/material/Tooltip';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import listPlugin from '@fullcalendar/list';
import business from './business.json';
import {
  DateSelectArg,
  EventContentArg,
  EventApi,
  EventInput,
  EventClickArg,
  DatesSetArg,
} from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import SchedulerEventModal from './SchedulerEventModal';
import EditSchedulerEventModal from './EditSchedulerEventModal';
import AddNewSchedulerEventModal from './AddNewSchedulerEventModal';

import './SchedulerDashboard.css';
import { Client, BusinessHour, OptionType } from '../../utils/type';
import { getAllClients } from '../../services/configApi/Clients';
import {
  getAllSchedulerEvents,
  getBusinessHoursOfEmployee,
} from '../../services/schedulerEventsApi';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  LOCAL_TIME_12_HOUR_FORMAT,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import { useLocation } from 'react-router-dom';
import EventModal from './EventModal';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { getTimeString } from '../../utils/dateUtil';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as Filtericon } from '../../assets/images/filtericon.svg';
import { ReactComponent as Clients } from '../../assets/images/users.svg';
import { ReactComponent as Attendees } from '../../assets/images/User.svg';
import { v4 as uuidv4 } from 'uuid';
import {
  getCurrentWeekSundayInISO,
  getSaturdayOfWeek,
  getSundayOfWeek,
} from '../../utils/utilities';
import MediumTypography from '../../components/formlib/MediumTypography';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import SwitchComponent from '../../components/formlib/SwitchComponent';
import { right } from '@popperjs/core';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { appointmentTypesFilter } from './schedulerUtils';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import {
  EmployeeFilter,
  fetchAllEmployees,
} from '../../services/configApi/employees/employeeServices';
import DisplayFilter, { FilterBy } from '../../components/shared/DisplayFilter';
import DateRangeComponent from '../../components/formlib/DateRangeComponent';
import { schedulerViewType } from '../Dropdown';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import ChipDisplay from '../../components/formlib/ChipDisplay';
import { formatName } from '../../utils/nameUtils';

export interface TabProps {
  tabPosition: number;
}

interface AddEventInfo {
  event: EventApi;
}

interface SchedularNavigationParam {
  date: string;
  appointmentId?: string;
  screenFrom?: string;
}

export default function SchedulerDashboard() {
  const CustomMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }: { theme: Theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 100,
      maxWidth: 490,
      padding: '20px 25px',
      boxShadow: '10px 10px 50px 0px rgba(0, 71, 66, 0.3)',
    },
  }));

  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const loggedInClinicianId = localStorage.getItem(STORAGE_USER_ID_KEY);

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const rctl = useIntl();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openit = Boolean(anchorEl);
  const [open, setOpen] = React.useState(false);
  const clientFilter = [
    { id: 'all', label: 'All' },
    { id: 'withoutclient', label: 'Without Client' },
  ];
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrId, setToastrId] = useState<string>();
  const [selectedClientFilter, setSelectedClientFilter] =
    useState<string>('all');
  const [toastrDefaultMessage, setToastrDefaultMessage] =
    React.useState<string>();
  const [toastrMessage, setToastrMessage] = React.useState<string>();
  const { loader, toggleLoader } = React.useContext(
    LoaderContext,
  ) as LoaderContextType;
  const [schedulerEvents, setSchedulerEvents] = useState<EventInput[]>();
  const [navParam, setNavParam] = useState<
    SchedularNavigationParam | undefined
  >();
  const [calenderStart, setCalenderStart] = useState<Date>();
  const [calenderEnd, setCalenderEnd] = useState<Date>();
  const [showAddEvent, setShowAddEvent] = useState(false);
  const [dateSelected, setDateSelected] = useState<DateSelectArg>();
  const [showViewEventModal, setShowViewEventModal] = useState(false);
  const [clickedEventInfo, setClickedEventInfo] = useState<EventClickArg>();
  const [clients, setClients] = useState<Client[]>([]);
  const [showEditEvent, setShowEditEvent] = useState(false);
  const [calenderView, setCalenderView] = useState(
    location.state?.screenFrom === 'programschedule'
      ? 'listWeek'
      : 'timeGridWeek',
  );
  // const [dateSetInfo, setDateSetInfo] = useState<DatesSetArg>();
  const [businessHours] = useState<BusinessHour[]>(business.businessHours);
  const [eventDelete, setEventDelete] = useState(false);
  const eventIsClicked = useRef(false);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = useState<
    OptionType[]
  >([]);
  const [employeeFilter, setEmployeeFilter] = useState<OptionType[]>([]);
  const [selectedEmployeeFilter, setSelectedEmployeeFilter] =
    useState<OptionType | null>();
  const [businessHoursMap, setBusinessHoursMap] =
    useState<Map<number, BusinessHour[]>>();
  const [filterBy, setFilterBy] = React.useState<FilterBy[]>([]);
  const calendarRef = useRef<FullCalendar>(null);
  const intl = useIntl();
  const [selectedValue, setSelectedValue] = React.useState(
    location.state?.screenFrom === 'programschedule'
      ? 'listWeek'
      : 'timeGridWeek',
  );
  const [selectScheduler, setSelectScheduler] = useState<string>(
    location.state?.screenFrom === 'programschedule'
      ? 'programschedule'
      : 'myAppointment',
  );
  const [openQuickModal, setOpenQuickModal] = useState(false);
  const dateRangeComponentRef = useRef<{
    startDate: Date | null;
    endDate: Date | null;
  }>({ startDate: null, endDate: null });
  const chipRef = useRef(0);
  const isInitialMountForCalenderView = useRef(true);
  const isInitialMountForfilter = useRef(true);
  const isRestrictAPICallforCalendar = useRef('');

  setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  const handleTabChange = (view: string) => {
    setCalenderView(view);
  };

  useEffect(() => {
    if (isInitialMountForCalenderView.current) {
      isInitialMountForCalenderView.current = false;
    } else {
      const calendarApi = calendarRef.current?.getApi();

      if (calendarApi && isRestrictAPICallforCalendar.current === '') {
        calendarApi.changeView(calenderView);
      } else if (calendarApi && isRestrictAPICallforCalendar.current !== '') {
        if (isRestrictAPICallforCalendar.current === 'change') {
          calendarApi.changeView(calenderView, new Date());
        } else {
          calendarApi.changeView(
            calenderView,
            new Date(isRestrictAPICallforCalendar.current),
          );
        }
        isRestrictAPICallforCalendar.current = '';
      }
    }
  }, [calenderView]);

  setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

  const styleToolbar = () => {
    let marginValue = '20px';
    if (selectScheduler === 'programschedule' && filterBy.length > 0) {
      marginValue = '50px';
    } else {
      marginValue = '20px';
    }
    const toolbarElements = document.querySelectorAll(
      '.fc .fc-toolbar.fc-header-toolbar',
    );
    toolbarElements.forEach((element) => {
      (element as HTMLElement).style.marginBottom = marginValue;
    });
  };

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    const navParamState = location.state as SchedularNavigationParam;
    if (calendarApi && navParamState !== null && navParamState.date !== null) {
      handleScheduleViewChange(
        navParamState.screenFrom === 'programschedule'
          ? 'programschedule'
          : 'myAppointment',
      );
      setNavParam(navParamState);
      calendarApi.changeView(calenderView, navParamState.date);
      if (navParamState.appointmentId) {
        setShowViewEventModal(true);
      }
    }
    styleToolbar();
  }, []);

  const handleShowAddEvent = () => setShowAddEvent(true);
  const handleCloseAddEvent = async (
    openFromAdd: boolean,
    toastrVariableFromAdd?: AlertColor,
    toastrMessageFromAdd?: string,
    toastrIdFromAdd?: string,
    toastrDefaultMessageFromAdd?: string,
  ) => {
    if (openFromAdd && toastrVariableFromAdd) {
      setOpen(openFromAdd);
      setToastrVariable(toastrVariableFromAdd);
      setToastrId(toastrIdFromAdd);
      setToastrDefaultMessage(toastrDefaultMessageFromAdd);
      setToastrMessage(toastrMessageFromAdd);
      if (
        dateRangeComponentRef.current.startDate !== null &&
        dateRangeComponentRef.current.endDate !== null &&
        toastrVariableFromAdd === 'success'
      ) {
        let startDateToBePassed = dateRangeComponentRef.current.startDate;
        let endDateToBePassed = dateRangeComponentRef.current.endDate;
        if (calenderView === 'dayGridMonth') {
          startDateToBePassed = getSundayOfWeek(startDateToBePassed);
          endDateToBePassed = getSaturdayOfWeek(endDateToBePassed);
        }
        await handleDatessSet(startDateToBePassed, endDateToBePassed);
      }
    }
    setShowAddEvent(false);
  };

  const handleCloseViewEventModal = (
    openFromView: boolean,
    toastrVariableFromView?: AlertColor,
    toastrMessageFromView?: string,
    toastrIdFromView?: string,
    toastrDefaultMessageFromView?: string,
    appoitnmentId?: string,
  ) => {
    if (openFromView && toastrVariableFromView) {
      setOpen(openFromView);
      setToastrVariable(toastrVariableFromView);
      setToastrId(toastrIdFromView);
      setToastrDefaultMessage(toastrDefaultMessageFromView);
      setToastrMessage(toastrMessageFromView);
      if (
        dateRangeComponentRef.current.startDate !== null &&
        dateRangeComponentRef.current.endDate !== null &&
        toastrVariableFromView === 'success'
      ) {
        handleDatessSet(
          dateRangeComponentRef.current.startDate,
          dateRangeComponentRef.current.endDate,
        );
      }
    }
    const schedulerEventsUpdates: EventInput[] | undefined =
      schedulerEvents?.filter(
        (event) =>
          event.id !== appoitnmentId &&
          event.commonAppointmentId !== appoitnmentId,
      );
    if (appoitnmentId) {
      setSchedulerEvents(schedulerEventsUpdates);
    }
    setShowViewEventModal(false);
    setNavParam(undefined);
  };

  const handleCloseEditEvent = async (
    openFromEdit: boolean,
    toastrVariableFromEdit?: AlertColor,
    toastrMessageFromEdit?: string,
    toastrIdFromEdit?: string,
    toastrDefaultMessageFromEdit?: string,
  ) => {
    if (openFromEdit && toastrVariableFromEdit) {
      setOpen(openFromEdit);
      setToastrVariable(toastrVariableFromEdit);
      setToastrId(toastrIdFromEdit);
      setToastrDefaultMessage(toastrDefaultMessageFromEdit);
      setToastrMessage(toastrMessageFromEdit);
      if (
        dateRangeComponentRef.current.startDate !== null &&
        dateRangeComponentRef.current.endDate !== null &&
        toastrVariableFromEdit === 'success'
      ) {
        await handleDatessSet(
          dateRangeComponentRef.current.startDate,
          dateRangeComponentRef.current.endDate,
        );
      }
    }
    setShowEditEvent(false);
    setNavParam(undefined);
  };

  const handleEventClick = (eventClickInfo: EventClickArg) => {
    if (
      eventClickInfo.event.extendedProps.eventType == 'holiday' ||
      eventClickInfo.event.extendedProps.eventType == 'LunchTime' ||
      eventClickInfo.event.extendedProps.eventType == 'timeOff'
    ) {
      return;
    }
    eventIsClicked.current = true;
    const moreLinks: NodeListOf<HTMLElement> =
      document.querySelectorAll('.fc-more-popover');

    moreLinks.forEach((link) => {
      link.style.display = 'none';
    });
    // comparing the eventDate with current date to hide delete button for blockTime in scheduler
    const eventDate = dayjs(eventClickInfo.event.start);
    const currentDate = dayjs();
    if (eventDate.isBefore(currentDate)) {
      setEventDelete(true);
    } else {
      setEventDelete(false);
    }
    setShowViewEventModal(true);
    setClickedEventInfo(eventClickInfo);
  };

  const showEdit = () => {
    setShowEditEvent(true);
    setShowViewEventModal(false);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleDateSelect = (selectInfo: DateSelectArg) => {
    const hoursDifference = dayjs(selectInfo.end).diff(
      dayjs(selectInfo.start),
      'hour',
    );

    if (calenderView === 'dayGridMonth') {
      if (hoursDifference == 24) {
        selectInfo.end = new Date(selectInfo.start);
      } else if (hoursDifference >= 24) {
        const copiedDate = new Date(selectInfo.end);
        selectInfo.end.setDate(copiedDate.getDate() - 1);
      }

      if (businessHoursMap && businessHoursMap.size != 0) {
        const startDateBusinessHours = businessHoursMap.get(
          getDayOfWeekNumber(dayjs(selectInfo.start)),
        );
        const endDateBusinessHours = businessHoursMap.get(
          getDayOfWeekNumber(dayjs(selectInfo.end)),
        );
        if (startDateBusinessHours && startDateBusinessHours.length != 0) {
          const [earliestHours, earliestMinutes] =
            startDateBusinessHours[0].startTime.split(':').map(Number);
          selectInfo.start.setHours(earliestHours, earliestMinutes);
        } else {
          selectInfo.start.setHours(9, 0);
        }

        if (
          endDateBusinessHours &&
          endDateBusinessHours.length != 0 &&
          endDateBusinessHours[1]
        ) {
          const [latestHours, latestMinutes] = endDateBusinessHours[1].endTime
            .split(':')
            .map(Number);
          selectInfo.end.setHours(latestHours, latestMinutes);
        } else {
          selectInfo.end.setHours(18, 0);
        }
      } else {
        selectInfo.start.setHours(9, 0);
        selectInfo.end.setHours(18, 0);
      }
    } else if (
      calenderView === 'timeGridWeek' ||
      calenderView === 'timeGridDay'
    ) {
      const minutesDifference = dayjs(selectInfo.end).diff(
        dayjs(selectInfo.start),
        'minutes',
      );
      if (minutesDifference === 15) {
        selectInfo.end.setHours(selectInfo.start.getHours() + 1, 0);
        selectInfo.end.setMinutes(selectInfo.start.getMinutes(), 0);
      }
    }
    setDateSelected(selectInfo);
    handleShowAddEvent();
  };

  const getClientsLists = async (dateValue: Date | undefined) => {
    toggleLoader(true);
    await getAllClients(true)
      .then(async (response) => {
        setClients(response);
        toggleLoader(false);
        await getBusinessHours(dateValue);
      })
      .catch((error) => {
        setOpen(true);
        setToastrVariable('error');
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrMessage(
            rctl.formatMessage({
              id: apiError.id,
              defaultMessage: apiError.message,
            }),
          );
        } else {
          const responseError = error as ApiMessage;
          setToastrMessage(responseError.message);
        }
      });
  };

  function getDayOfWeekNumber(date: dayjs.Dayjs): number {
    return date.day();
  }

  const convertToOptionType = (inputArray: EmployeeFilter[]): OptionType[] =>
    inputArray.map(({ id, firstName, lastName }) => ({
      id,
      label: `${firstName} ${lastName}`,
    }));

  const getBusinessHours = async (date?: Date) => {
    if (loggedInClinicianId && date) {
      toggleLoader(true);
      const resultMap = new Map<number, BusinessHour[]>();
      const todaysWeekNumber = getDayOfWeekNumber(dayjs());
      const calendarApi = calendarRef.current?.getApi();

      await getBusinessHoursOfEmployee(
        loggedInClinicianId,
        getCurrentWeekSundayInISO(date),
      )
        .then(async (response) => {
          // filter out business hours that has start time and end time not null
          response.businessHours = response?.businessHours.filter(
            (item) => item.startTime && item.endTime,
          );
          if (response?.businessHours && response?.businessHours.length != 0) {
            response?.businessHours.forEach((item) => {
              item.startTime = getTimeString(item.startTime, 'HH:mm', true);
              item.endTime = getTimeString(item.endTime, 'HH:mm', true);
            });

            response?.businessHours.forEach((item) => {
              const dayOfWeek = item.daysOfWeek[0];
              if (!resultMap.has(dayOfWeek)) {
                resultMap.set(dayOfWeek, []);
              }
              resultMap.get(dayOfWeek)!.push(item);
            });
            setBusinessHoursMap(resultMap);
            if (calendarApi) {
              if (resultMap && resultMap.size != 0) {
                const todaysBusinessHours = resultMap.get(todaysWeekNumber);
                if (todaysBusinessHours && todaysBusinessHours.length != 0) {
                  calendarApi.scrollToTime(todaysBusinessHours[0].startTime);
                } else {
                  calendarApi.scrollToTime('9:00');
                }
              } else {
                calendarApi.scrollToTime('9:00');
              }
            }
          }
          await fetchAllEmployees()
            .then((employeeFilterDataAPI: EmployeeFilter[]) => {
              const targetElement = document.querySelector(
                'td[data-time="08:00:00"]',
              ) as HTMLElement | null;

              if (targetElement) {
                // Scroll the target element into view
                targetElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                });
              }
              setEmployeeFilter(convertToOptionType(employeeFilterDataAPI));
              setOpenQuickModal(true);
              toggleLoader(false);
            })
            .catch((error) => {
              setOpen(true);
              setToastrVariable('error');
              toggleLoader(false);
              if (isCustomError(error)) {
                const apiError = error as ApiError;
                setToastrMessage(
                  rctl.formatMessage({
                    id: apiError.id,
                    defaultMessage: apiError.message,
                  }),
                );
              } else {
                const responseError = error as ApiMessage;
                setToastrMessage(responseError.message);
              }
            });
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrMessage(
              rctl.formatMessage({
                id: apiError.id,
                defaultMessage: apiError.message,
              }),
            );
          } else {
            const responseError = error as ApiMessage;
            setToastrMessage(responseError.message);
          }
        });
    }
  };

  useEffect(() => {
    console.log('loader = ' + loader + ' eventIsClicked = ' + eventIsClicked.current);
    if(!loader && !eventIsClicked.current){
      const targetElement = document.querySelector(
        'td[data-time="08:00:00"]',
      ) as HTMLElement | null;

      if (targetElement) {
        // Scroll the target element into view
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
    if(!loader) {
      eventIsClicked.current = false;
    }
    
  }, [loader]);


  const handleDatessSet = async (start: Date, end: Date) => {
    setSchedulerEvents(undefined);
    toggleLoader(true);
    const startDate = start;
    const endDate = end;
    setCalenderStart(startDate);
    setCalenderEnd(endDate);
    let todaysWeekNumber = getDayOfWeekNumber(dayjs());
    const calendarApi = calendarRef.current?.getApi();
    if (loggedInClinicianId) {
      toggleLoader(true);
      if (calenderView === 'timeGridDay') {
        todaysWeekNumber = getDayOfWeekNumber(dayjs(startDate));
      } else {
        todaysWeekNumber = getDayOfWeekNumber(dayjs());
      }
      if (calendarApi) {
        if (businessHoursMap && businessHoursMap.size != 0) {
          const todaysBusinessHours = businessHoursMap.get(todaysWeekNumber);
          if (todaysBusinessHours && todaysBusinessHours.length != 0) {
            calendarApi.scrollToTime(todaysBusinessHours[0].startTime);
          } else {
            calendarApi.scrollToTime('9:00');
          }
        } else {
          calendarApi.scrollToTime('9:00');
        }
      }
      let calendarUnit = 'Week';
      if (calenderView === 'timeGridDay') {
        calendarUnit = 'Day';
      } else if (calenderView === 'dayGridMonth') {
        calendarUnit = 'Month';
      }
      let clinicianIdToPass: string | null | undefined = null;
      if (selectScheduler === 'programschedule') {
        clinicianIdToPass =
          selectedEmployeeFilter === null ? null : selectedEmployeeFilter?.id;
      } else {
        clinicianIdToPass = loggedInClinicianId;
      }
      await getAllSchedulerEvents(
        clinicianIdToPass,
        dayjs(startDate).utc().format(),
        dayjs(endDate).utc().format(),
        calendarUnit,
        selectScheduler,
        selectedAppointmentTypes,
        selectedClientFilter,
      )
        .then(async (data) => {
          const eventData = data.map((item) => {
            item.overlap = true;
            if (item.eventType === 'offTime') {
              item.id = uuidv4();
              item.color = '#D3D3D3';
              item.display = 'background';
            } else if (item.eventType === 'holiday') {
              item.color = '#f96400';
              item.display = 'background';
            } else if (item.eventType === 'lunchTime') {
              item.id = uuidv4();
              item.color = '#008000';
              item.display = 'background';
            } else if (item.eventType === 'timeOff') {
              item.id = uuidv4();
              item.color = '#008080';
              item.display = 'background';
            }
            return item;
          });
          setSchedulerEvents(eventData);
          // toggleLoader(false);
          await getClientsLists(start);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrMessage(
              rctl.formatMessage({
                id: apiError.id,
                defaultMessage: apiError.message,
              }),
            );
          } else {
            setToastrMessage(error);
          }
        });
    }
  };
  let hasCalledHandleDatesSet = false;

  const handleDatesSet = (dateInfo: DatesSetArg) => {
    if (!hasCalledHandleDatesSet) {
      handleDatessSet(dateInfo.start, dateInfo.end);
      hasCalledHandleDatesSet = true;
    }
  };

  const recallTheAppointmentAPI = () => {
    toggleLoader(true);
    let clinicianIdToPass: string | null | undefined = null;
    if (selectScheduler === 'programschedule') {
      clinicianIdToPass =
        selectedEmployeeFilter === null ? null : selectedEmployeeFilter?.id;
      getAllSchedulerEvents(
        clinicianIdToPass,
        dayjs(calenderStart).utc().format(),
        dayjs(calenderEnd).utc().format(),
        null,
        selectScheduler,
        selectedAppointmentTypes,
        selectedClientFilter,
      )
        .then((data) => {
          const eventData = data.map((item) => {
            item.overlap = true;
            if (item.eventType === 'offTime') {
              item.id = uuidv4();
              item.color = '#D3D3D3';
              item.display = 'background';
            } else if (item.eventType === 'holiday') {
              item.color = '#f96400';
              item.display = 'background';
            } else if (item.eventType === 'lunchTime') {
              item.id = uuidv4();
              item.color = '#008000';
              item.display = 'background';
            } else if (item.eventType === 'timeOff') {
              item.id = uuidv4();
              item.color = '#008080';
              item.display = 'background';
            }
            return item;
          });
          setSchedulerEvents(eventData);
          toggleLoader(false);
          getClientsLists(calenderStart);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrMessage(
              rctl.formatMessage({
                id: apiError.id,
                defaultMessage: apiError.message,
              }),
            );
          } else {
            setToastrMessage(error);
          }
        });
    }
  };

  let attendeeNames = '';

  function renderEventContent(eventInfo: EventContentArg) {
    if (eventInfo.event.extendedProps.eventType == 'appointment') {
      let appointmentTypeLabel = '';

      if (eventInfo.event.extendedProps.appointmentType != undefined) {
        appointmentTypeLabel = intl.formatMessage({
          id: eventInfo.event.extendedProps.appointmentType,
          defaultMessage: eventInfo.event.extendedProps.appointmentType,
        });
      }

      if (
        calenderView == 'listWeek' &&
        eventInfo.event.extendedProps.attendeeList &&
        Array.isArray(eventInfo.event.extendedProps.attendeeList)
      ) {
        const attendees = eventInfo.event.extendedProps.attendeeList.map(
          (attendee) => attendee.attendeeName,
        );
        const maxAttendeesToShow = 5;
        let attendeesNames = '';

        if (attendees.length > maxAttendeesToShow) {
          attendeesNames = `${attendees
            .slice(0, maxAttendeesToShow)
            .join(', ')}...`;
        } else {
          attendeesNames = attendees.join(', ');
        }
        attendeeNames = attendeesNames;
      }

      const startTime = dayjs(eventInfo.event.start).format(
        LOCAL_TIME_12_HOUR_FORMAT,
      );
      let clientName = '';
      const clientCount = eventInfo.event.extendedProps.clients?.length;

      if (
        eventInfo.event.extendedProps.appointmentType != 'appointmentType5' &&
        eventInfo.event.extendedProps.clients != undefined &&
        eventInfo.event.extendedProps.clients.length == 1
      ) {
        const client = eventInfo.event.extendedProps.clients[0];
        clientName = formatName(
          client.firstName,
          client.middleName,
          client.lastName,
          client.suffix,
        );
      }
      if (selectScheduler === 'myAppointment') {
        return (
          <Grid container>
            {selectScheduler === 'myAppointment' && <b>{startTime}</b>}
            {clientName != '' && (
              <Tooltip title={clientName} arrow>
                <b
                  style={{
                    display: 'inline-block',
                    maxWidth: '80%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#000',
                    paddingRight: '5px',
                  }}
                >
                  {clientName}
                </b>
              </Tooltip>
            )}
            {appointmentTypeLabel != '' && <i> {appointmentTypeLabel}</i>}
            {selectScheduler !== 'myAppointment' && clientCount === 0 && (
              <i> {'- No Clients'}</i>
            )}
            <i> {`- ${attendeeNames}`}</i>
          </Grid>
        );
      } else {
        const name =
          eventInfo.event.extendedProps.appointmentType +
          'ClassNameProgramScheduleDot';
        let attendees = [];
        if (
          calenderView == 'listWeek' &&
          eventInfo.event.extendedProps.attendeeList &&
          Array.isArray(eventInfo.event.extendedProps.attendeeList)
        ) {
          attendees = eventInfo.event.extendedProps.attendeeList.map(
            (attendee) => attendee.attendeeName,
          );
        }
        const clientCoutText = clientCount.toString().concat(' Client(s)');
        const attendeeCountText = attendees.length
          .toString()
          .concat(' Attendee(s)');
        return (
          <Grid container className="programScheduleRow">
            <Box className={name}></Box>
            <Box className="appointmentTypeProgramSchedule">
              <MediumTypography label={appointmentTypeLabel} />
            </Box>
            <Box className="programScheduleClient">
              <Clients />
              <MediumTypography
                label={clientCoutText}
                sxProps={{
                  color: '#97A6A5',
                  marginLeft: '5px',
                  whiteSpace: 'nowrap',
                }}
              />
            </Box>
            <Box className="programScheduleAttendee">
              <Attendees />
              <MediumTypography
                label={attendeeCountText}
                sxProps={{
                  color: '#97A6A5',
                  marginLeft: '5px',
                  whiteSpace: 'nowrap',
                }}
              />
              <Box>
                <ChipDisplay items={attendees} dataRef={chipRef} width={250} />
              </Box>
            </Box>
          </Grid>
        );
      }
    } else if (eventInfo.event.extendedProps.eventType == 'block') {
      const startTime = dayjs(eventInfo.event.start).format(
        LOCAL_TIME_12_HOUR_FORMAT,
      );
      return (
        <Grid
          container
          style={{
            display: 'inline-block',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#000',
          }}
        >
          <b>
            {startTime}
            {''}
          </b>
          <b style={{ marginLeft: '4px' }}>
            <FormattedMessage
              id="BlockTime.blockType"
              defaultMessage="Block Time"
            />
          </b>
        </Grid>
      );
    } else if (eventInfo.event.extendedProps.eventType == 'lunchTime') {
      const idLocalization = 'LunchTime';
      const defaultValue = 'Lunch Time';
      return (
        <Grid
          style={{
            display: 'inline-block',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#ffffff',
          }}
        >
          <MediumTypography
            labelid={idLocalization}
            defaultlabel={defaultValue}
            sxProps={{
              marginLeft: '10px',
            }}
          />
        </Grid>
      );
    } else if (eventInfo.event.extendedProps.eventType == 'holiday') {
      const label = eventInfo.event.extendedProps.description;
      return (
        <Grid
          style={{
            display: 'inline-block',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#ffffff',
          }}
        >
          <MediumTypography
            label={label}
            sxProps={{
              marginLeft: '10px',
            }}
          />
        </Grid>
      );
    } else if (eventInfo.event.extendedProps.eventType == 'timeOff') {
      const idLocalization = 'Time Off';
      const defaultValue = 'Time Off';
      return (
        <Grid
          style={{
            display: 'inline-block',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '#ffffff',
          }}
        >
          <MediumTypography
            labelid={idLocalization}
            defaultlabel={defaultValue}
            sxProps={{
              marginLeft: '10px',
            }}
          />
        </Grid>
      );
    }
  }

  function addEventColorBasedOnAppointmentType(eventInfo: AddEventInfo) {
    if (selectScheduler === 'myAppointment') {
      if (eventInfo.event.extendedProps.eventType == 'block') {
        return eventInfo.event.extendedProps.eventType + 'ClassName';
      } else {
        return eventInfo.event.extendedProps.appointmentType + 'ClassName';
      }
    } else {
      return (
        eventInfo.event.extendedProps.appointmentType +
        'ClassNameProgramSchedule'
      );
    }
  }

  function handleScheduleViewChange(value: string): void {
    toggleLoader(true);
    setSelectScheduler(value);
    setTimeout(() => {
      if (dateRangeComponentRef.current.startDate) {
        isRestrictAPICallforCalendar.current =
          dateRangeComponentRef.current.startDate?.toISOString();
      }
      if (value == 'programschedule') {
        setCalenderView('listWeek');
      } else {
        setCalenderView('timeGridWeek');
        setSelectedValue('timeGridWeek');
      }
    }, 1000);
  }
  const handlefilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlefilterClose = () => {
    setAnchorEl(null);
  };

  const handleApply = () => {
    // initialize array of filterby
    const filterby: FilterBy[] = [];
    if (selectedClientFilter === 'withoutclient') {
      filterby.push({
        key: 'scheduler.filter.appointments.label',
        value: [{ id: '0', label: 'Without Clients' }],
      });
    }
    if (
      selectedAppointmentTypes &&
      selectedAppointmentTypes !== null &&
      selectedAppointmentTypes.length > 0
    ) {
      filterby.push({
        key: 'scheduler.filter.appointmentsTypeKey',
        value: selectedAppointmentTypes,
      });
    }
    if (selectedEmployeeFilter && selectedEmployeeFilter !== null) {
      filterby.push({
        key: 'scheduler.filter.employee',
        value: [
          {
            id: '0',
            label: employeeFilter.filter(
              (emp) => emp.id === selectedEmployeeFilter.id,
            )[0].label,
          },
        ],
      });
    }
    setFilterBy(filterby);
    setAnchorEl(null);
  };

  useEffect(() => {
    styleToolbar();
  }, [selectScheduler]);

  useEffect(() => {
    if (isInitialMountForfilter.current) {
      isInitialMountForfilter.current = false;
    } else {
      styleToolbar();
      recallTheAppointmentAPI();
    }
  }, [filterBy]);

  useEffect(() => {
    const filterAppointment = filterBy.filter(
      (f) => f.key === 'scheduler.filter.appointmentsTypeKey',
    );
    if (
      filterAppointment.length > 0 &&
      filterAppointment[0].value.length === selectedAppointmentTypes.length
    ) {
      recallTheAppointmentAPI();
    }
  }, [selectedAppointmentTypes]);

  const filterCheck = () => {
    if(!loader && !eventIsClicked.current) {
      return 'fullcalender-check-auto'
    } 
    else if(eventIsClicked.current) {
      return 'fullcalender-check-auto'
    } else {
      return 'fullcalender-check'
    }
  }

  return (
    <Box component="main">
      <Box component="section">
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          toastrMessage={toastrMessage}
        />

        <Box component="div">
          {showViewEventModal &&
            clickedEventInfo &&
            (clickedEventInfo?.event.extendedProps.eventType ==
            'appointment' ? (
              <SchedulerEventModal
                openViewEventModel={showViewEventModal}
                eventId={
                  clickedEventInfo.event.extendedProps.commonAppointmentId
                }
                showEdit={showEdit}
                onClose={handleCloseViewEventModal}
                screenFrom={
                  selectScheduler === 'myAppointment'
                    ? 'schedulerEvent'
                    : 'programschedule'
                }
              />
            ) : (
              <EventModal
                openViewEventModel={
                  showViewEventModal &&
                  clickedEventInfo.event.extendedProps.block
                }
                eventId={clickedEventInfo.event.id}
                data={clickedEventInfo.event.extendedProps.block}
                onClose={handleCloseViewEventModal}
                eventDelete={eventDelete}
              />
            ))}

          {showAddEvent && dateSelected && (
            <AddNewSchedulerEventModal
              showModal={showAddEvent}
              dateSelected={dateSelected}
              clients={clients}
              onModalClose={handleCloseAddEvent}
            />
          )}
          {clickedEventInfo && showEditEvent && (
            <EditSchedulerEventModal
              showModal={showEditEvent}
              eventId={clickedEventInfo.event.extendedProps.commonAppointmentId}
              clients={clients}
              onModalClose={handleCloseEditEvent}
            />
          )}
          {navParam && navParam.appointmentId && showEditEvent && (
            <EditSchedulerEventModal
              showModal={showEditEvent}
              eventId={navParam.appointmentId}
              clients={clients}
              onModalClose={handleCloseEditEvent}
            />
          )}
          {navParam && navParam.appointmentId && openQuickModal && (
            <SchedulerEventModal
              openViewEventModel={showViewEventModal}
              eventId={navParam.appointmentId}
              showEdit={showEdit}
              onClose={handleCloseViewEventModal}
              screenFrom={
                selectScheduler === 'myAppointment'
                  ? 'schedulerEvent'
                  : 'programschedule'
              }
            />
          )}
        </Box>
        <Box
          component="div"
          sx={{
            opacity: loader ? 0.5 : 1,
            pointerEvents: loader ? 'none' : 'auto',
          }}
        >
          <Box>
            <Box className="rowContainer">
              <Grid
                item
                xs={2}
                lg={2}
                alignItems="right"
                justifyContent={'flex-end'}
                display={'flex'}
              >
                <TitleText
                  className="shechedulerTitleText"
                  labelid="SchedulerDashboard.Titletext"
                  defaultlabel="Scheduler"
                />
              </Grid>

              <Grid
                container
                direction="row"
                alignItems="right"
                item
                xs={12}
                lg={12}
                justifyContent={'flex-end'}
              >
                <Grid
                  item
                  alignItems="right"
                  justifyContent={'flex-end'}
                  marginLeft={'20px'}
                >
                  {
                    <SwitchComponent
                      Disabled={false}
                      toggle={selectScheduler === 'programschedule'}
                      name="programschedule"
                      labelid="programschedule"
                      defaultlabelid="Program"
                      handlechange={(
                        e: React.ChangeEvent<HTMLInputElement>,
                      ) => {
                        if (e.target.checked) {
                          handleScheduleViewChange('programschedule');
                        } else {
                          handleScheduleViewChange('myAppointment');
                          setFilterBy([]);
                          setSelectedClientFilter('all');
                          setSelectedAppointmentTypes([]);
                          setSelectedEmployeeFilter(null);
                        }
                      }}
                    />
                  }
                </Grid>
                {selectScheduler && selectScheduler === 'myAppointment' && (
                  <Grid
                    item
                    alignItems="center"
                    display={'flex'}
                    marginLeft={'20px'}
                  >
                    <Box className="headerButtonHeight">
                      <DropdownComponent
                        names={schedulerViewType}
                        value={selectedValue}
                        handleChange={(e) => {
                          setSelectedValue(e);
                          if (dateRangeComponentRef.current.startDate) {
                            isRestrictAPICallforCalendar.current =
                              dateRangeComponentRef.current.startDate?.toISOString();
                          } else {
                            isRestrictAPICallforCalendar.current = 'change';
                          }
                          handleTabChange(e);
                        }}
                      />
                    </Box>
                  </Grid>
                )}
                {selectScheduler && selectScheduler === 'programschedule' && (
                  <Grid
                    item
                    alignItems="center"
                    display={'flex'}
                    marginLeft={'20px'}
                  >
                    <Box>
                      <Button
                        className="btn-primary btn-filter"
                        variant="outlined"
                        onClick={handlefilter}
                        endIcon={<KeyboardArrowDownIcon />}
                        startIcon={<Filtericon />}
                      >
                        <FormattedMessage
                          id="FiterText"
                          defaultMessage="Filter"
                        />
                      </Button>
                      <MemorizedCustomMenu
                        id="demo-customized-menu"
                        anchorEl={anchorEl}
                        open={openit}
                        onClose={handlefilterClose}
                      >
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <Grid item xs={12}>
                            <DropdownComponent
                              labelid="scheduler.filter.appointments"
                              defaultlabelid="Appointments"
                              name="appointments"
                              value={selectedClientFilter}
                              handleChange={(value: string) => {
                                setSelectedClientFilter(value);
                              }}
                              names={clientFilter}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <MultipleAutocompleteSearch
                              labelId="scheduler.filter.appointmentsType"
                              defaultId="Select Appointments Type"
                              data={appointmentTypesFilter.filter(
                                (item) =>
                                  !selectedAppointmentTypes.some(
                                    (selectedItem) =>
                                      selectedItem.id === item.id,
                                  ),
                              )}
                              type="string"
                              onSelectItem={(value: OptionType[] | null) => {
                                setSelectedAppointmentTypes(value || []);
                              }}
                              disableLabel={true}
                              optionHintId={'No Appointment Type Found'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                  marginTop: '2px',
                                },
                                backgroundColor: '#fff',
                              }}
                              value={selectedAppointmentTypes}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <AutocompleteSearch
                              labelId={'scheduler.filter.selectEmployee'}
                              defaultId={'Select/Search Employee'}
                              data={employeeFilter}
                              onSelectItem={(value: OptionType | null) => {
                                setSelectedEmployeeFilter(value);
                              }}
                              disableClearable={true}
                              disableLabel={true}
                              optionHintId={'No Search found'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                },
                              }}
                              selectedValue={
                                selectedEmployeeFilter as OptionType
                              }
                            />
                          </Grid>
                          <Grid item xs={12} textAlign={right} mt={1.5}>
                            <Box
                              sx={{
                                justifyContent: 'right',
                                display: 'flex',
                              }}
                            >
                              <Box className="mr-md">
                                <ButtonComponent
                                  className="btn-primary btn-cancel btn_width_90px"
                                  variantType="contained"
                                  labelId="Dashboard.filtercancel"
                                  defaultLabelId="Cancel"
                                  onClick={() => {
                                    setAnchorEl(null);
                                  }}
                                  disabled={loader}
                                />
                              </Box>
                              <Box>
                                <ButtonComponent
                                  className="btn-primary btn-submit btn_width_90px"
                                  variantType="contained"
                                  type="submit"
                                  labelId="Dashboard.filtersubmit"
                                  defaultLabelId="Apply"
                                  onClick={() => {
                                    handleApply();
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </MemorizedCustomMenu>
                    </Box>
                  </Grid>
                )}
                <Grid
                  item
                  alignItems="center"
                  display={'flex'}
                  marginLeft={'20px'}
                >
                  <DateRangeComponent
                    viewType={calenderView}
                    onDateRangeChange={(startDate: Date) => {
                      calendarRef.current?.getApi()?.gotoDate(startDate);
                    }}
                    dataRef={dateRangeComponentRef}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid>
              {filterBy && filterBy !== null && filterBy.length > 0 && (
                <Grid mt={-3.5} container xs={12} lg={12}>
                  <DisplayFilter
                    filteredData={filterBy}
                    handleCloseClickArrayType={(
                      index: number,
                      indexj: number,
                    ) => {
                      const filterType = filterBy[index].key;
                      const lengthOfFilter = filterBy[index].value.length;
                      let filterByBackup = filterBy;
                      if (indexj === 0 && filterBy[index].value.length === 1) {
                        filterByBackup = filterBy.filter(
                          (_, indexf) => indexf !== index,
                        );
                        setFilterBy((prevItems) =>
                          prevItems.filter((_, indexf) => indexf !== index),
                        );
                      } else {
                        const filterValueByIndex: OptionType[] = filterByBackup[
                          index
                        ].value as OptionType[];
                        filterByBackup[index].value = filterValueByIndex.filter(
                          (_, indexf) => indexf !== indexj,
                        );
                        setFilterBy(filterByBackup);
                      }
                      if (
                        filterBy[index].key ===
                        'scheduler.filter.appointments.label'
                      ) {
                        setSelectedClientFilter('all');
                      } else if (
                        filterBy[index].key === 'scheduler.filter.employee'
                      ) {
                        setSelectedEmployeeFilter(null);
                      } else {
                        if (
                          filterType ===
                            'scheduler.filter.appointmentsTypeKey' &&
                          lengthOfFilter > 1
                        ) {
                          setSelectedAppointmentTypes(
                            filterByBackup[index].value as OptionType[],
                          );
                        } else {
                          setSelectedAppointmentTypes([]);
                        }
                      }
                    }}
                    handleClearClick={() => {
                      const filterby: FilterBy[] = [];
                      setFilterBy(filterby);
                      setSelectedClientFilter('all');
                      setSelectedAppointmentTypes([]);
                      setSelectedEmployeeFilter(null);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </Box>

          <Box
            mt={
              filterBy && filterBy !== null && filterBy.length > 0 ? -6.5 : -3.5
            }
            className={filterBy.length > 0 ? 'filterAppliedMargin' : filterCheck()}
          >
            <FullCalendar
              height={'auto'}
              aspectRatio={2.5}
              plugins={[
                timeGridPlugin,
                interactionPlugin,
                dayGridPlugin,
                listPlugin,
              ]}
              editable={false}
              selectable={true}
              dragScroll={false}
              selectMirror={false}
              dayMaxEvents={true}
              nowIndicator={true}
              ref={calendarRef}
              views={{
                timeGridWeek: {
                  eventMaxStack: 1,
                  titleFormat: {
                    day: '2-digit',
                    month: 'short',
                    omitCommas: true,
                  },
                  dayHeaderFormat: {
                    weekday: 'long',
                    day: '2-digit',
                    month: 'short',
                    omitCommas: false,
                  },
                },
                timeGridDay: {
                  eventMaxStack: 2,
                  titleFormat: {
                    day: '2-digit',
                    month: 'short',
                    omitCommas: false,
                  },
                  dayHeaderFormat: {
                    weekday: 'long',
                    month: 'short',
                    day: '2-digit',
                    omitCommas: false,
                  },
                },
                dayGridMonth: {
                  titleFormat: {
                    year: 'numeric',
                    month: 'short',
                    day: undefined,
                    omitCommas: false,
                  },
                  dayHeaderFormat: {
                    weekday: 'long',
                    omitCommas: false,
                  },
                },
              }}
              headerToolbar={{
                left: '',
                center: '',
                right: '',
              }}
              weekends={true}
              events={schedulerEvents}
              datesSet={handleDatesSet} // Attach the event handler
              select={
                checkPermissionForFeature(
                  'backend.appointment',
                  'addPermission',
                )
                  ? handleDateSelect
                  : () => {}
              }
              eventContent={renderEventContent}
              eventClick={handleEventClick}
              eventClassNames={addEventColorBasedOnAppointmentType}
              allDaySlot={false}
              slotDuration={'00:15:00'}
              slotLabelInterval={'01:00'}
              slotLabelFormat={{
                hour: '2-digit', // Display hours as two digits
                minute: '2-digit', // Display minutes as two digits
                hour12: true, // Use 24-hour format
                omitZeroMinute: true, // Include zero minutes (e.g., "5:00PM" instead of "5PM")
              }}
              eventTimeFormat={{
                hour: '2-digit', // Ensure leading zero for hours
                minute: '2-digit',
                hour12: true,
                meridiem: 'short', // Display AM/PM
              }}
              businessHours={businessHours}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
