import { Card, Box } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/images/Searchicon.svg';
import MediumTypography from './MediumTypography';
import './SearchNotFound.css';

const SearchNotFound = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        right: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#E7F0F0',
        display: 'flex',
      }}
    >
      <Card>
        <Box className="cardBox">
          <SearchIcon className="searchId" />
          <MediumTypography
            labelid="searchnotfound.longText"
            defaultlabel="Whoops! No matches."
            id="longText"
            textColor="#2a4241"
            marginTop="8px"
            fontweight={600}
          />
          <MediumTypography
            labelid="searchnotfound.smallText"
            defaultlabel="We could not find any search results. Give it another go"
            textColor="#2a4241"
            id="smallText"
            marginTop="8px"
            fontweight={400}
            fontSize="14px"
          />
        </Box>
      </Card>
    </Box>
  );
};

export default SearchNotFound;
