import { Box, Card, CircularProgress, Grid } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import TitleText from '../../components/formlib/TitleText';
import { Formik, FormikProps } from 'formik';
import Textinput from '../../components/formlib/Textinput';
import { Address } from '../employees/types';
import SmallTypography from '../../components/formlib/SmallTypography';
import {
  formatPhoneNumber,
  nameValidation,
} from '../../utils/formValidationUtil';
import AddressForm from '../../components/formlib/AddressForm';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import * as yup from 'yup';
import {
  InsuranceType,
  createOrUpdateMasterInsurance,
} from '../../services/configApi/insurance/insuranceProvider';
import { useEffect, useRef, useState } from 'react';
import { OptionType, ResponseType } from '../../utils/type';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ReactComponent as BackButton } from '../../assets/images/backarrow.svg';
import ModalPopup from '../../components/formlib/ModalPopup';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import _ from 'lodash';
import { INSURANCE_ROUTE_NAME } from '../../routes/Routing';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';

interface InsuranceTypes {
  id: string | null;
  insuranceCompanyName: string;
  payerId: string;
  phoneNumber: string;
  address: Address;
  startDate: Dayjs | null | string;
  endDate: Dayjs | null | string;
}

const initialValues: InsuranceTypes = {
  id: '',
  insuranceCompanyName: '',
  payerId: '',
  phoneNumber: '',
  address: {
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: { id: '', label: '' },
  },
  startDate: dayjs().format('MM/DD/YYYY'),
  endDate: null,
};

const validationSchema = yup.object().shape({
  insuranceCompanyName: yup
    .string()
    .required('insuranceCompanyNameRequiredMessage'),
  payerId: yup.string().required('playerIdRequiredMessage'),
  phoneNumber: yup
    .string()
    .min(12, 'PhoneNumber.Validation')
    .required('phoneNumberRequiredMessage'),
  address: yup.object().shape({
    address1: yup.string().required('addressLine1RequiredMessage'),
    address2: yup.string().nullable(),
    zipCode: yup.object().shape({
      label: yup.string().required('zipCodeRequiredMessage'),
    }),
    city: yup.string().required('cityRequiredMessage'),
    state: yup.string().required('stateRequiredMessage'),
  }),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
});

const validateForm = (value: InsuranceTypes) => {
  const errors: Partial<InsuranceTypes> = {};

  if (value.startDate) {
    if (dayjs(value.startDate).isAfter(dayjs())) {
      errors.startDate = 'validDate';
    }
  }

  if (value.endDate) {
    if (dayjs(value.endDate).isBefore(dayjs(value.startDate))) {
      errors.endDate = 'effectiveEndDateValidationMessage';
    }
  }

  if (datePickerMinDateMaxDateValidate(value.startDate)) {
    errors.startDate = 'datePickerMinDateMaxDateValidate';
  }
  if (datePickerMinDateMaxDateValidate(value.endDate)) {
    errors.endDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

type ActionType = 'add' | 'edit';

const AddInsurance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const formikRef = useRef<FormikProps<InsuranceTypes>>(null);
  const [action, setAction] = useState<ActionType>('add');
  const [insurance, setInsurance] = useState<InsuranceTypes>(initialValues);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [pointerEvents, setPointerEvents] = useState<'none' | 'auto'>('auto');
  const [toastrId, setToastrId] = useState<string>();
  const [defaultMessage, setToastrDefaultMessage] = useState<string>();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const value = location.state as InsuranceType;
    if (value === null) {
      setAction('add');
    } else {
      setInsurance({
        ...initialValues,
        id: value.insuranceId,
        insuranceCompanyName: value.insuranceCompanyName,
        payerId: value.payerId,
        phoneNumber: value.phoneNumber,
        startDate: value.startDate,
        endDate: value.endDate,
        address: {
          address1: value.address1,
          address2: value.address2,
          zipCode: { id: 'aa', label: value.zipCode },
          city: value.city,
          state: value.state,
        },
      });
      setAction('edit');
    }
  }, []);

  const submitInsurance = (insuranceType: InsuranceTypes) => {
    setShowLoader(true);
    setPointerEvents('none');
    const formValues = dayjs(insuranceType.startDate).format('MM/DD/YYYY');
    const finalInsurance: InsuranceType = {
      insuranceId: action === 'add' ? null : insuranceType.id,
      insuranceCompanyName: insuranceType.insuranceCompanyName,
      payerId: insuranceType.payerId,
      phoneNumber: insuranceType.phoneNumber,
      address1: insuranceType.address.address1,
      address2: insuranceType.address.address2,
      zipCode: (insuranceType.address.zipCode as OptionType).label,
      city: insuranceType.address.city,
      state: insuranceType.address.state,
      startDate: formValues,
      endDate: insuranceType.endDate,
    };
    createOrUpdateMasterInsurance(finalInsurance)
      .then(() => {
        setSuccessOrError('success');
        setToastrId(
          action === 'add' ? 'addSuccessMessage' : 'updateSuccessMessage',
        );
        formikRef.current?.resetForm();
        setShowLoader(false);
        setPointerEvents('auto');
        setTimeout(() => {
          navigate(INSURANCE_ROUTE_NAME);
        }, 500);
      })
      .catch((error) => {
        const errorRes = error as ApiMessage;
        setSuccessOrError('error');
        setShowLoader(false);
        setPointerEvents('auto');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else if (errorRes.code === 1051) {
          setToastrId('Insurance.insuranceAlreadyExists');
          setToastrDefaultMessage(errorRes.message);
        } else if (errorRes.code === 1052) {
          setToastrId('Insurance.insurancePayerExists');
          setToastrDefaultMessage(errorRes.message);
        } else {
          setToastrId(
            action === 'add' ? 'addFailureMessage' : 'updateFailureMessage',
          );
          setToastrDefaultMessage(
            action === 'add' ? 'Failed to Add' : 'Failed to update details',
          );
        }
      });
  };

  const clearForm = () => {
    setOpenModal(false);
    if (action === 'add') {
      formikRef.current?.resetForm();
      setInsurance(initialValues);
    } else {
      const value = location.state as InsuranceType;
      const oldInsurance: InsuranceTypes = {
        id: value.insuranceId,
        insuranceCompanyName: value.insuranceCompanyName,
        payerId: value.payerId,
        phoneNumber: value.phoneNumber,
        address: {
          address1: value.address1,
          address2: value.address2,
          zipCode: { id: 'aa', label: value.zipCode },
          city: value.city,
          state: value.state,
        },
        startDate: value.startDate,
        endDate: value.endDate,
      };
      formikRef.current?.setValues(oldInsurance);
      setInsurance(oldInsurance);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#E7F0F0',
        width: '100%',
        padding: '16px',
        pointerEvents: pointerEvents,
      }}
    >
      <>
        <ModalPopup
          open={openModal}
          description="formUnsavedChangesMessage"
          onCancel={() => setOpenModal(false)}
          onOk={() => {
            clearForm();
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="Cancel"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="Ok"
        />
        <Box
          sx={{
            display: 'flex',
          }}
          alignItems={'center'}
        >
          <Box
            onClick={() => navigate(INSURANCE_ROUTE_NAME)}
            sx={{
              cursor: 'pointer',
              display: 'flex',
            }}
            alignItems={'center'}
          >
            <BackButton />
          </Box>
          <TitleText
            Sxprops={{ paddingLeft: '16px' }}
            labelid={
              action === 'add' ? 'addNewInsuranceText' : 'editNewInsuranceText'
            }
            defaultlabel={
              action === 'add' ? 'Add New Insurance' : 'Edit Insurance'
            }
          />
        </Box>
        <Formik
          innerRef={formikRef}
          initialValues={insurance}
          enableReinitialize
          validateOnChange
          validate={validateForm}
          validationSchema={validationSchema}
          onSubmit={(values) => submitInsurance(values)}
        >
          {({ values, setFieldValue, errors, touched, handleSubmit }) => (
            <Box>
              <Box>
                {' '}
                <Card
                  sx={{
                    marginTop: '16px',
                    padding: '24px',
                    opacity: showLoader ? 0.5 : 1,
                    pointerEvents: showLoader ? 'none' : 'auto',
                  }}
                >
                  {toastrId && (
                    <SnackBarComponent
                      open={toastrId !== undefined}
                      handleClose={() => {
                        setToastrId(undefined);
                      }}
                      labelId={toastrId}
                      defaultMessageId={defaultMessage}
                      successOrError={successOrError}
                    />
                  )}
                  {showLoader && (
                    <Box
                      sx={{
                        position: 'absolute',
                        left: '55%',
                        marginTop: '5%',
                      }}
                    >
                      <CircularProgress sx={{ color: '#00938E' }} />
                    </Box>
                  )}

                  <Grid container direction={'column'} spacing={4}>
                    <Grid container item spacing={2}>
                      <Grid item xs={3}>
                        <Textinput
                          name="companyName"
                          labelid="thirdPartyPayerText"
                          defaultlabelid="Third Party Payer"
                          Value={values.insuranceCompanyName}
                          inputProps={{ maxLength: 100 }}
                          handlechange={(text) => {
                            setFieldValue(
                              'insuranceCompanyName',
                              nameValidation(text),
                            );
                          }}
                        />
                        {errors.insuranceCompanyName &&
                          touched.insuranceCompanyName && (
                            <SmallTypography
                              sxProps={{ color: 'red' }}
                              labelId={errors.insuranceCompanyName}
                              defaultLabelId="Field should not be empty"
                            />
                          )}
                      </Grid>
                      <Grid item xs={3}>
                        <Textinput
                          name="payerId"
                          labelid="payerIdText"
                          defaultlabelid="Payer ID"
                          Value={values.payerId}
                          inputProps={{ maxLength: 20 }}
                          handlechange={(text) => {
                            setFieldValue('payerId', text);
                          }}
                          Required
                        />
                        {errors.payerId && touched.payerId && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.payerId}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Textinput
                          name="phoneNumber"
                          labelid="Contacts.phone"
                          defaultlabelid="Phone Number"
                          Value={values.phoneNumber}
                          handlechange={(text) => {
                            setFieldValue(
                              'phoneNumber',
                              formatPhoneNumber(text),
                            );
                          }}
                          Required
                        />
                        {errors.phoneNumber && touched.phoneNumber && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.phoneNumber}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>

                      <Grid item xs={9}>
                        <AddressForm
                          address1PlaceholderId="address1Text"
                          address2PlaceholderId="address2Text"
                          zipCodePlaceholderId="Clientpage.Zip"
                          cityPlaceholderId="Clientpage.City"
                          statePlaceholderId="Clientpage.State"
                          value={values.address}
                          isClient={false}
                          disableForm={false}
                          formRequired={true}
                          formikErrors={errors.address}
                          formikTouched={touched.address}
                          handleAddressUpdate={(newAddress) => {
                            formikRef.current?.setValues({
                              ...values,
                              address: newAddress,
                            });
                          }}
                        />
                      </Grid>
                      <Grid xs={4.5} item>
                        {values.startDate && (
                          <DatePickerComponent
                            labelid="Family.startDate"
                            disabledDate={true}
                            defaultlabelid="Start Date"
                            disableFuture={true}
                            minDate={dayjs(values.startDate)}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('startDate', null);
                              } else {
                                setFieldValue('startDate', formattedDate);
                              }
                            }}
                            value={dayjs(values.startDate) as Dayjs}
                          />
                        )}
                        {values.startDate === null && (
                          <DatePickerComponent
                            labelid="Family.startDate"
                            disabledDate={true}
                            defaultlabelid="Start Date"
                            disableFuture={true}
                            minDate={dayjs(values.startDate)}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('startDate', null);
                              } else {
                                setFieldValue('startDate', formattedDate);
                              }
                            }}
                            value={null}
                          />
                        )}
                        {errors.startDate && touched.startDate && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.startDate}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>
                      <Grid xs={4.5} item>
                        {values.endDate && (
                          <DatePickerComponent
                            labelid="Family.endDate"
                            defaultlabelid="End Date"
                            minDate={dayjs(values.startDate)}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('endDate', null);
                              } else {
                                setFieldValue('endDate', formattedDate);
                              }
                            }}
                            value={dayjs(values.endDate) as Dayjs}
                          />
                        )}
                        {values.endDate === null && (
                          <DatePickerComponent
                            labelid="Family.endDate"
                            defaultlabelid="End Date"
                            minDate={dayjs(values.startDate)}
                            handlechange={(date: Dayjs | null) => {
                              const formattedDate =
                                dayjs(date).format('MM/DD/YYYY');
                              if (date === null) {
                                setFieldValue('endDate', null);
                              } else {
                                setFieldValue('endDate', formattedDate);
                              }
                            }}
                            value={null}
                          />
                        )}

                        {errors.endDate && touched.endDate && (
                          <SmallTypography
                            sxProps={{ color: 'red' }}
                            labelId={errors.endDate}
                            defaultLabelId="Field should not be empty"
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Box>
              <Box className="mt-md mb-xlg">
                <Grid
                  container
                  direction="row"
                  alignItems="right"
                  sx={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Grid item sx={{ marginRight: '16px' }}>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="outlined"
                      labelId="cancelText"
                      defaultLabelId="Cancel"
                      onClick={() => {
                        if (action === 'edit') {
                          if (_.isEqual(insurance, values)) {
                            navigate(INSURANCE_ROUTE_NAME);
                          } else {
                            setOpenModal(true);
                          }
                        } else {
                          if (!_.isEqual(initialValues, values)) {
                            setOpenModal(true);
                          } else {
                            navigate(INSURANCE_ROUTE_NAME);
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      labelId={
                        action === 'add' ? 'submitText' : 'Insurance.update'
                      }
                      defaultLabelId="Submit"
                      onClick={handleSubmit}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Formik>
      </>
    </Box>
  );
};

export default AddInsurance;
