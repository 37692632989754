import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Toolbar,
  Box,
  Button,
  Badge,
  CircularProgress,
  Tooltip,
  Grid,
  MenuProps,
  Menu,
  AlertColor,
  Card,
  Theme,
  styled,
  autocompleteClasses,
} from '@mui/material';
import * as yup from 'yup';
import TitleText from '../components/formlib/TitleText';
import { ReactComponent as Filtericon } from '../assets/images/FilterIconsvg.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router';
import {
  getClientsDetails,
  getListByRole,
} from '../services/configApi/Clients';
import { ClientsFilterType, ClientsListType, OptionType } from '../utils/type';
import SnackBarComponent from '../components/formlib/SnackBarComponent';
import MediumTypography from '../components/formlib/MediumTypography';
import SearchBox from '../components/formlib/SearchBox';
import dayjs, { Dayjs } from 'dayjs';
import { encodeURI } from 'js-base64';
import DatePickerComponent from '../components/formlib/DatePickerComponent';
import SelectComponent from '../components/formlib/SelectComponent';
import ButtonComponent from '../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import CustomCheckBox from '../components/formlib/CustomCheckBox';
import './ClientsDashboardPage.css';
import { CLIENT_NAME_KEY, REFERRAL_ID_KEY } from '../services/Constant';
import { right } from '@popperjs/core';
import SearchNotFound from '../components/formlib/SearchNotFound';
import { debounce } from 'lodash';
import { FACESHEET } from '../routes/Routing';
import { LoaderContext, LoaderContextType } from '../layouts/AppSidebar';
import { FormattedMessage } from 'react-intl';
import DisplayFilter, { FilterBy } from '../components/shared/DisplayFilter';
import EmptyScreen from '../components/shared/EmptyScreen';
import { visuallyHidden } from '@mui/utils';
import { ApiError, isCustomError } from '../services/ApiResponseHandler';
import AppPagination from '../components/shared/AppPagination';
import { checkPermissionForFeature } from '../utils/checkPermission';
import {
  calculateMonths,
  datePickerMinDateMaxDateValidate,
} from '../utils/dateUtil';
import {
  LookUpContext,
  LookUpContextType,
} from '../context/LookUpContextProvider';
import AutocompleteSearch from '../components/formlib/AutocompleteSearch';
import CheckBoxComponent from '../components/formlib/CheckBoxComponent';
import { clearClientStorageDetails } from '../utils/utilities';
import { formatName } from '../utils/nameUtils';

type Order = 'asc' | 'desc';

type HeadCell = {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  requiredSorting: boolean;
};

const headCells: readonly HeadCell[] = [
  {
    id: 'firstName',
    numeric: false,
    disablePadding: true,
    label: 'Client Name',
    requiredSorting: true,
  },
  {
    id: 'dphId',
    numeric: true,
    disablePadding: false,
    label: 'Enrollment ID',
    requiredSorting: false,
  },
  {
    id: 'dateOfBirth',
    numeric: true,
    disablePadding: false,
    label: 'Date of Birth',
    requiredSorting: true,
  },
  {
    id: 'dateOfBirth',
    numeric: true,
    disablePadding: false,
    label: 'Age',
    requiredSorting: true,
  },
  {
    id: 'ifspDueDate',
    numeric: true,
    disablePadding: false,
    label: 'IFSP Due Date',
    requiredSorting: true,
  },
  {
    id: 'intakeFacilitator',
    numeric: true,
    disablePadding: false,
    label: 'Intake facilitator',
    requiredSorting: false,
  },
  {
    id: 'serviceCoordinator',
    numeric: true,
    disablePadding: false,
    label: 'Service Coordinator',
    requiredSorting: false,
  },
  {
    id: 'Status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
    requiredSorting: false,
  },
  // {
  //   id: 'editElipses',
  //   numeric: true,
  //   disablePadding: false,
  //   label: '',
  //   requiredSorting: false,
  // },
];
export type Data = {
  sno: string;
  firstName: string;
  dateOfBirth: string;
  dphId: string;
  ifspDueDate: string;
  intakeFacilitator: string;
  serviceCoordinator: string;
  Status: string;
  editElipses: string;
  dateOfBirthInYears: string;
};

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data, requiredSort: boolean) =>
    (event: React.MouseEvent<unknown>) => {
      if (requiredSort) {
        onRequestSort(event, property);
      }
    };

  return (
    <TableHead className="listDataTableHead">
      <TableRow>
        <TableCell padding="checkbox">
          <CustomCheckBox value={false} />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              onClick={createSortHandler(headCell.id, headCell.requiredSorting)}
              className="Tableheaderstyle"
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              hideSortIcon={!headCell.requiredSorting}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color || '#37D183',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
    marginRight: '10px',
  },
}));

const ClientsDashboardPage = () => {
  const CustomMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }: { theme: Theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 100,
      maxWidth: 490,
      padding: '20px 25px',
      boxShadow: '10px 10px 50px 0px rgba(0, 71, 66, 0.3)',
    },
  }));
  const [showEmptyScreen, setShowEmptyScreen] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>('asc');
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [clientDetails, setClientDetails] = useState<ClientsListType[]>([]);
  const [clientDetailsCount, setClientDetailsCount] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const formRef = React.useRef<FormikProps<ClientsFilterType>>(null);
  const [orderBy, setOrderBy] = useState<keyof Data | string>('');
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [page, setPage] = useState(0);
  const [loader, setloader] = useState(false);
  const [searchquery, setSearchquery] = useState('');
  const [onsearchquery, setOnsearchquery] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const [minIfsp, setMinIfsp] = React.useState<Date>();
  const [filterData, setFilterData] = React.useState('');
  const [filterBy, setFilterBy] = React.useState<FilterBy[]>([]);
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [serviceCoordinatorsList, setServiceCoordinatorsList] = useState<
    OptionType[]
  >([]);
  const [intakeCoordinatorsList, setIntakeCoordinatorsList] = useState<
    OptionType[]
  >([]);
  const [selectedCoordinator, setSelectedServiceCoordinator] = useState<
    OptionType | null | undefined
  >();

  const [selectedIntakeCoordinator, setSelectedIntakeCoordinator] = useState<
    OptionType | null | undefined
  >();

  const { lookupsDemographics, updateDemographics } = useContext(
    LookUpContext,
  ) as LookUpContextType;

  const [filterPlainData, setFilterPlainData] =
    React.useState<ClientsFilterType>({
      startDate: null,
      endDate: null,
      startAge: '',
      endAge: '',
      ifspDueStartDate: null,
      ifspDueEndDate: null,
      serviceCoordinator: '',
      assesmentStartHour: '',
      assesmentEndHour: '',
      status: '',
      ageMonthStart: '',
      ageMonthEnd: '',
      intakeFacilitator: '',
      noIntakeFacilitator: '',
      noServiceCoordinator: '',
    });

  const openit = Boolean(anchorEl);
  const handlefilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlefilterClose = () => {
    setAnchorEl(null);
  };
  const PostApplyFilter = () => {
    setAnchorEl(null);
  };
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  useEffect(() => {
    sessionStorage.clear();
    clearClientStorageDetails();
    toggleLoader(true);
    getClientsDetails(page, 10, filterData, orderBy, order, onsearchquery)
      .then((response) => {
        toggleLoader(false);
        setClientDetails(response.clients);
        setClientDetailsCount(response.totalClientsCount);
        if (
          response.totalClientsCount === 0 &&
          page === 0 &&
          onsearchquery === ''
        ) {
          setShowEmptyScreen(true);
        } else {
          setShowEmptyScreen(false);
        }
        setloader(false);
        PostApplyFilter();
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  }, [page, order, orderBy, onsearchquery, filterData]);

  useEffect(() => {
    const handleServiceCoordinatorsList = async () => {
      try {
        const serviceCoordinatorsDetails = await getListByRole(
          'backend.role.service_coordinator',
        );
        if (!serviceCoordinatorsDetails) return;
        const serviceCoList: OptionType[] = [];
        serviceCoordinatorsDetails.forEach((coordinator) => {
          serviceCoList.push({
            id: coordinator.id,
            label: `${coordinator.firstName} ${coordinator.lastName}`,
          });
        });
        setServiceCoordinatorsList(serviceCoList);
      } catch (e) {}
    };
    handleServiceCoordinatorsList();

    const handleIntakeCoordinatorsList = async () => {
      try {
        const intakeCoordinatorsDetails = await getListByRole(
          'backend.role.intake_coordinator',
        );
        if (!intakeCoordinatorsDetails) return;
        const intakeCoList: OptionType[] = [];
        intakeCoordinatorsDetails.forEach((coordinator) => {
          intakeCoList.push({
            id: coordinator.id,
            label: `${coordinator.firstName} ${coordinator.lastName}`,
          });
        });
        setIntakeCoordinatorsList(intakeCoList);
      } catch (e) {}
    };
    handleIntakeCoordinatorsList();
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      return;
    }
    setSelected([]);
  };
  const clearForm = () => {
    setPage(0);
    formRef.current?.resetForm();
    setFilterData('');
    setFilterPlainData({
      startDate: null,
      endDate: null,
      startAge: '',
      endAge: '',
      ifspDueStartDate: null,
      ifspDueEndDate: null,
      serviceCoordinator: '',
      assesmentStartHour: '',
      assesmentEndHour: '',
      status: '',
      ageMonthStart: '',
      ageMonthEnd: '',
      intakeFacilitator: '',
      noIntakeFacilitator: '',
      noServiceCoordinator: '',
    });
    setFilterBy([]);
    setAnchorEl(null);
  };
  const validationSchema = yup.object().shape({
    startAge: yup.string(),
    ageMonthEnd: yup.string().when('startAge', {
      is: (startAge: string) => !!startAge,
      then: yup.string().required('Dashboard.endAge').nullable(),
      otherwise: yup.string().nullable(),
    }),
    ifspDueStartDate: yup
      .string()
      .nullable()
      .test(
        'required-if-end-date',
        'Dashboard.ifspDueStarttDate',
        function (value) {
          const { ifspDueEndDate } = this.parent;
          if (ifspDueEndDate && !value) {
            return false;
          }
          return true;
        },
      ),
    ifspDueEndDate: yup
      .string()
      .nullable()
      .test(
        'required-if-start-date',
        'Dashboard.ifspDueEndDate',
        function (value) {
          const { ifspDueStartDate } = this.parent;
          if (ifspDueStartDate && !value) {
            return false;
          }
          return true;
        },
      ),
    serviceCoordinator: yup.string(),
    assesmentStartHour: yup.string(),
    assesmentEndHour: yup.string().when('assesmentStartHour', {
      is: (assesmentStartHour: string) => !!assesmentStartHour,
      then: yup.string().required('Dashboard.assesmentEndHour').nullable(),
      otherwise: yup.string().nullable(),
    }),
    status: yup.string(),
  });
  const validateForm = (value: ClientsFilterType) => {
    const errors: Partial<ClientsFilterType> = {};
    if (
      datePickerMinDateMaxDateValidate(value.ifspDueStartDate) ||
      datePickerMinDateMaxDateValidate(value.ifspDueEndDate)
    ) {
      errors.ifspDueStartDate = 'datePickerMinDateMaxDateValidate';
      errors.ifspDueEndDate = 'datePickerMinDateMaxDateValidate';
    }
    return errors;
  };
  const handleClick = async (
    clientId: string,
    clientName: string,
    referralId: string,
  ) => {
    localStorage.setItem('ClientId', clientId);
    localStorage.setItem(CLIENT_NAME_KEY, clientName);

    if (lookupsDemographics) {
      updateDemographics({
        ...lookupsDemographics,
        firstName: '',
        middleName: '',
        lastName: '',
        ageInMonths: null,
        sex: null,
        dateOfBirth: '',
      });
    }
    localStorage.setItem(REFERRAL_ID_KEY, referralId);

    navigation(FACESHEET, { state: { clientId } });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;
  const navigation = useNavigate();

  const navigateToReferralCreation = () => {
    navigation('/referral-dashboard/add-referral');
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPage(0);
        setSearchquery(value);
        setSearchTriggered(true);
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
        setToastrDefaultMessage(
          'Only alphanumeric characters, commas, periods, spaces, exclamation marks, and hyphens are allowed to be searched.',
        );
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchTriggered) {
      setOnsearchquery(searchquery);
      setSearchTriggered(false);
    }
    if (!searchquery) {
      setOnsearchquery(searchquery);
    }
  }, [searchquery, searchTriggered]);
  // const Coordinator = [''];
  const ClientStatus = ['Active', 'Inactive', 'Pending'];
  const encoded = (values: ClientsFilterType) => {
    if (deepEqual(filterPlainData, values)) {
      return;
    }
    const filterDataBy: FilterBy[] = [];
    setFilterPlainData(values);
    if (values.startAge != null && values.startAge !== '') {
      filterDataBy.push({
        key: 'age',
        value: values.ageMonthStart + ' - ' + values.ageMonthEnd,
      });
    }
    if (values.ifspDueEndDate != null && values.ifspDueEndDate !== '') {
      filterDataBy.push({
        key: 'ifspduedate',
        value:
          values.ifspDueStartDate?.toString() +
          ' - ' +
          values.ifspDueEndDate.toString(),
      });
    }
    if (values.assesmentEndHour != null && values.assesmentEndHour !== '') {
      filterDataBy.push({
        key: 'assessmenthours',
        value:
          getTimeBasedStringValue(values.assesmentStartHour) +
          ' - ' +
          getTimeBasedStringValue(values.assesmentEndHour),
      });
    }
    if (values.status != null && values.status !== '') {
      filterDataBy.push({
        key: 'filtersatus',
        value: values.status,
      });
    }
    if (values.intakeFacilitator != null && values.intakeFacilitator !== '') {
      filterDataBy.push({
        key: 'Facesheet.intakeFacilitator',
        value: values.intakeFacilitator,
      });
    }
    if (values.serviceCoordinator != null && values.serviceCoordinator !== '') {
      filterDataBy.push({
        key: 'Facesheet.serviceCoordinator',
        value: values.serviceCoordinator,
      });
    }
    if (values.noIntakeFacilitator === 'true') {
      filterDataBy.push({
        key: 'noIntakeFacilitator',
        value: values.noIntakeFacilitator,
      });
    }
    if (values.noServiceCoordinator === 'true') {
      filterDataBy.push({
        key: 'noServiceCoordinator',
        value: values.noServiceCoordinator,
      });
    }
    setFilterBy(filterDataBy);
    const encodedAge = `dateOfBirth>=${values.endAge}&dateOfBirth<=${values.startAge}`;
    const encodedifspduedate = `&ifspDueDate>=${
      values.ifspDueStartDate == null ? '' : values.ifspDueStartDate
    }&ifspDueDate<=${
      values.ifspDueEndDate == null ? '' : values.ifspDueEndDate
    }`;

    const encodedStatus = `&status=${values.status}`;
    const encodedIntakeFacilitator = `&intakeFacilitator=${values.intakeFacilitator}`;
    const encodedServiceCoordinator = `&serviceCoordinator=${values.serviceCoordinator}`;
    const encodedNoIntakeFacilitator = `&noIntakeFacilitator=${values.noIntakeFacilitator}`;
    const encodedNoServiceCoordinator = `&noServiceCoordinator=${values.noServiceCoordinator}`;
    const totalEncodedValue = encodeURI(
      `${encodedAge}${encodedifspduedate}${encodedStatus}${encodedIntakeFacilitator}${encodedServiceCoordinator}${encodedNoIntakeFacilitator}${encodedNoServiceCoordinator}`,
    );
    if (page >= 0) {
      setPage(0);
      setFilterData(totalEncodedValue);
    }

    if (filterDataBy.length <= 2) {
      if (filterDataBy.some((item) => item.value === 'false')) {
        clearForm();
        setFilterBy([]);
      }
    }
  };

  const getTimeBasedStringValue = (selectedValue: string) => {
    if (selectedValue) {
      const filteredValue: string =
        +selectedValue > 9
          ? selectedValue.concat(':00')
          : '0'.concat(selectedValue.concat(':00'));
      return filteredValue;
    }
  };

  const dateBuilder = (usermonth: string) => {
    const month = parseInt(usermonth);
    const currentDate = new Date();

    // Calculate the birth date by subtracting the age in months from the current date
    const birthDate = new Date(
      currentDate.setMonth(currentDate.getMonth() - month),
    );
    // Format the birth date in mm/dd/yyyy format
    const formattedBirthDate = `${String(birthDate.getMonth() + 1).padStart(
      2,
      '0',
    )}/${String(birthDate.getDate()).padStart(
      2,
      '0',
    )}/${birthDate.getFullYear()}`;
    return formattedBirthDate;
  };
  const badgePicker = (status: string) => {
    switch (status) {
      case 'Inactive':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'red',
              },
            }}
          />
        );
      case 'Pending':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#FFA500',
              },
            }}
          />
        );
      default:
        return <CustomBadge variant="dot" />;
    }
  };
  // const navigateToClientCreation = (r: ClientsListType) => {
  //   return r;
  // };
  const stringsArray = Array.from({ length: 37 }, (_, index) =>
    index.toString(),
  );
  const generateNumberOptions = (start: number, end: number) => {
    return stringsArray.slice(start, end + 1);
  };

  const getServiceCoordinator = (serviceCoordinator: string) => {
    const displayServiceCoordinator = serviceCoordinatorsList.filter(
      (obj) => obj.id === serviceCoordinator,
    );

    return displayServiceCoordinator.length > 0
      ? displayServiceCoordinator.map((obj) => obj.label)
      : '-';
  };

  const getIntakeFacilitator = (intakeFacilitator: string) => {
    const displayIntakeFacilitator = intakeCoordinatorsList.filter(
      (obj) => obj.id === intakeFacilitator,
    );

    return displayIntakeFacilitator.length > 0
      ? displayIntakeFacilitator.map((obj) => obj.label)
      : '-';
  };

  function deepEqual(
    obj1: ClientsFilterType,
    obj2: ClientsFilterType,
  ): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '24px',
        }}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <TitleText
                  labelid="Dashboard.ClientList"
                  defaultlabel="Client List"
                />
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <SearchBox
                  labelId="Clientdashboard.searchText"
                  defaultlabel="Search Client"
                  onChange={handleSearchChange}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                  mx: '24px',
                }}
              >
                <Button
                  className="btn-primary btn-filter"
                  variant="outlined"
                  onClick={handlefilter}
                  endIcon={<KeyboardArrowDownIcon />}
                  startIcon={<Filtericon />}
                >
                  <FormattedMessage id="FiterText" defaultMessage="Filter" />
                </Button>
                <MemorizedCustomMenu
                  id="demo-customized-menu"
                  anchorEl={anchorEl}
                  open={openit}
                  onClose={handlefilterClose}
                >
                  <Formik
                    innerRef={formRef}
                    initialValues={filterPlainData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      encoded(values);
                    }}
                    validate={validateForm}
                  >
                    {({
                      setFieldValue,
                      handleSubmit,
                      errors,
                      touched,
                      values,
                    }) => (
                      <>
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <Grid item xs={5.5}>
                            <SelectComponent
                              labelid="Dashboard.startDate"
                              defaultlabelid="Age"
                              name="startAge"
                              value={values.ageMonthStart}
                              handleChange={(value: string) => {
                                setFieldValue('endAge', '');
                                setFieldValue('startAge', dateBuilder(value));
                                setFieldValue('ageMonthStart', value);
                                setFieldValue('ageMonthEnd', '');
                              }}
                              names={generateNumberOptions(0, 36)}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <MediumTypography
                              labelid="Dashboard.to"
                              defaultlabel="to"
                              sxProps={{
                                textAlign: 'center',
                                margin: 'revert',
                              }}
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <SelectComponent
                              labelid="Dashboard.endDate"
                              defaultlabelid="Age"
                              name="endAge"
                              value={values.ageMonthEnd}
                              handleChange={(value: string) => {
                                if (values.ageMonthStart !== '') {
                                  setFieldValue('endAge', dateBuilder(value));
                                  setFieldValue('ageMonthEnd', value);
                                }
                              }}
                              names={generateNumberOptions(
                                Number(values.ageMonthStart) + 1,
                                36,
                              )}
                            />
                            {errors.ageMonthEnd && touched.ageMonthEnd && (
                              <MediumTypography
                                labelid={errors.ageMonthEnd}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="ifspDueStartDate"
                              value={
                                values.ifspDueStartDate !== null
                                  ? (dayjs(values.ifspDueStartDate) as Dayjs)
                                  : null
                              }
                              labelid="Dashboard.ifspDueStartDate"
                              defaultlabelid="IFSP Due Start Date"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('ifspDueStartDate', null);
                                } else {
                                  setFieldValue(
                                    'ifspDueStartDate',
                                    formattedDate,
                                  );
                                }
                                if (
                                  values &&
                                  values.ifspDueEndDate !== null &&
                                  values.ifspDueEndDate > formattedDate
                                ) {
                                  setFieldValue('ifspDueEndDate', null);
                                }
                                const newEndDate =
                                  dayjs(formattedDate).toDate();
                                setMinIfsp(newEndDate);
                                setFieldValue('ifspDueEndDate', null);
                              }}
                            />
                            {errors.ifspDueStartDate &&
                              touched.ifspDueStartDate && (
                                <MediumTypography
                                  labelid={errors.ifspDueStartDate}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                          </Grid>
                          <Grid item xs={1}>
                            <MediumTypography
                              labelid="Dashboard.to"
                              defaultlabel="to"
                              sxProps={{
                                textAlign: 'center',
                                margin: 'revert',
                              }}
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="ifspDueEndDate"
                              value={
                                values.ifspDueEndDate !== null
                                  ? (dayjs(values.ifspDueEndDate) as Dayjs)
                                  : null
                              }
                              minDate={dayjs(minIfsp) as Dayjs}
                              labelid="Dashboard.ifspDueEndtDate"
                              defaultlabelid="IFSP Due End Date"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('ifspDueEndDate', null);
                                } else {
                                  setFieldValue(
                                    'ifspDueEndDate',
                                    formattedDate,
                                  );
                                }
                              }}
                            />
                            {errors.ifspDueEndDate &&
                              touched.ifspDueEndDate && (
                                <MediumTypography
                                  labelid={errors.ifspDueEndDate}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                          </Grid>
                          {/* <Grid item xs={12}>
                            <SelectComponent
                              labelid="Dashboard.serviceCoordinator"
                              defaultlabelid="Service Coordinator"
                              name="serviceCoordinator"
                              value={values.serviceCoordinator}
                              handleChange={(value: string) => {
                                setFieldValue('serviceCoordinator', value);
                              }}
                              names={Coordinator}
                            />
                          </Grid> */}
                          <Grid item xs={5.5} style={{ display: 'none' }}>
                            <SelectComponent
                              labelid="Dashboard.Assessmentstart"
                              defaultlabelid="Assessment Hours"
                              name="assesmentStartHour"
                              value={values.assesmentStartHour}
                              handleChange={(value: string) => {
                                setFieldValue('assesmentStartHour', value);
                                setFieldValue('assesmentEndHour', '');
                              }}
                              names={generateNumberOptions(1, 19)}
                            />
                          </Grid>
                          <Grid item xs={1} style={{ display: 'none' }}>
                            <MediumTypography
                              labelid="Dashboard.to"
                              defaultlabel="to"
                              sxProps={{
                                textAlign: 'center',
                                margin: 'revert',
                              }}
                            />
                          </Grid>
                          <Grid item xs={5.5} style={{ display: 'none' }}>
                            <SelectComponent
                              labelid="Dashboard.Assessmentend"
                              defaultlabelid="Assessment Hours"
                              name="assesmentEndHour"
                              value={values.assesmentEndHour}
                              handleChange={(value: string) => {
                                if (values.assesmentStartHour !== '') {
                                  setFieldValue('assesmentEndHour', value);
                                }
                              }}
                              names={generateNumberOptions(
                                Number(values.assesmentStartHour) + 1,
                                20,
                              )}
                            />

                            {errors.assesmentEndHour &&
                              touched.assesmentEndHour && (
                                <MediumTypography
                                  labelid={errors.assesmentEndHour}
                                  textColor="red"
                                  marginTop="8px"
                                />
                              )}
                          </Grid>
                          <Grid item xs={5.5} className="pt-none">
                            <Box>
                              <CheckBoxComponent
                                name="noIntakeFacilitator"
                                labelid="noIntakeFacilitator"
                                defaultlabelid="No Intake Facilitator"
                                ischecked={
                                  values.noIntakeFacilitator === 'true'
                                    ? true
                                    : false
                                }
                                CheckHandleChange={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const checked =
                                    event.target.checked === true
                                      ? 'true'
                                      : 'false';
                                  setFieldValue('noIntakeFacilitator', checked);
                                  setSelectedIntakeCoordinator(null);
                                  setFieldValue('intakeFacilitator', '');
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item xs={1}>
                            <MediumTypography
                              labelid=""
                              defaultlabel=""
                              sxProps={{
                                textAlign: 'center',
                                margin: 'revert',
                              }}
                            />
                          </Grid>
                          <Grid item xs={5.5} className="pt-none">
                            <Box>
                              <CheckBoxComponent
                                className="box_child_label_mr0"
                                name="noServiceCoordinator"
                                labelid="noServiceCoordinator"
                                defaultlabelid="No Service Coordinator"
                                ischecked={
                                  values.noServiceCoordinator === 'true'
                                    ? true
                                    : false
                                }
                                CheckHandleChange={(
                                  event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const checked =
                                    event.target.checked === true
                                      ? 'true'
                                      : 'false';
                                  setFieldValue(
                                    'noServiceCoordinator',
                                    checked,
                                  );
                                  setSelectedServiceCoordinator(null);
                                  setFieldValue('serviceCoordinator', '');
                                }}
                              />
                            </Box>
                          </Grid>

                          <Grid item xs={5.5} className="pt-md">
                            <AutocompleteSearch
                              disabled={values.noIntakeFacilitator == 'true'}
                              labelId={'Facesheet.intakeFacilitator'}
                              defaultId="Intake Facilitator"
                              optionHintId={'nointakeDropdownTextId'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                },
                              }}
                              data={intakeCoordinatorsList}
                              selectedValue={
                                selectedIntakeCoordinator
                                  ? selectedIntakeCoordinator
                                  : { id: '', label: '' }
                              }
                              onSelectItem={(value: OptionType | null) => {
                                if (value) {
                                  setSelectedIntakeCoordinator(value);
                                  setFieldValue('intakeFacilitator', value.id);
                                }
                              }}
                              disableLabel={false}
                            />
                          </Grid>

                          <Grid item xs={1}></Grid>
                          <Grid item xs={5.5} className="pt-md">
                            <AutocompleteSearch
                              disabled={values.noServiceCoordinator == 'true'}
                              labelId={'Facesheet.serviceCoordinator'}
                              defaultId="Service Coordinator"
                              optionHintId={'noserviceDropdownTextId'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                },
                              }}
                              data={serviceCoordinatorsList}
                              selectedValue={
                                selectedCoordinator
                                  ? selectedCoordinator
                                  : { id: '', label: '' }
                              }
                              onSelectItem={(value: OptionType | null) => {
                                if (value) {
                                  setSelectedServiceCoordinator(value);
                                  setFieldValue('serviceCoordinator', value.id);
                                }
                              }}
                              disableLabel={false}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <SelectComponent
                              labelid="Dashboard.Status"
                              defaultlabelid="Status"
                              name="status"
                              value={values.status}
                              handleChange={(value: string) => {
                                setFieldValue('status', value);
                              }}
                              names={ClientStatus}
                            />
                          </Grid>

                          <Grid item xs={12} textAlign={right} mt={1.5}>
                            <Box
                              sx={{ justifyContent: 'right', display: 'flex' }}
                            >
                              <Box className="mr-md">
                                <ButtonComponent
                                  className="btn-primary btn-cancel btn_width_90px"
                                  variantType="contained"
                                  labelId="Dashboard.filtercancel"
                                  defaultLabelId="Cancel"
                                  onClick={() => {
                                    setAnchorEl(null);
                                  }}
                                  disabled={loader}
                                />
                              </Box>
                              <Box>
                                <ButtonComponent
                                  className="btn-primary btn-submit btn_width_90px"
                                  variantType="contained"
                                  type="submit"
                                  labelId="Dashboard.filtersubmit"
                                  defaultLabelId="Apply"
                                  onClick={() => {
                                    if (deepEqual(filterPlainData, values)) {
                                      return;
                                    }
                                    handleSubmit();
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Formik>
                </MemorizedCustomMenu>
              </Box>
              <Box sx={{ flexGrow: 0 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  {checkPermissionForFeature(
                    'backend.referral_dashboard',
                    'addPermission',
                  ) && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="Referraldashboard.AddTitletext"
                      defaultLabelId="Add New Referral"
                      onClick={() => navigateToReferralCreation()}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {filterData !== '' && (
              <DisplayFilter
                filteredData={filterBy}
                selectedIntakeFacilitator={selectedIntakeCoordinator}
                selectedServiceCoordinator={selectedCoordinator}
                handleCloseClick={(index: number, key: string | undefined) => {
                  if (key == 'Facesheet.intakeFacilitator') {
                    setSelectedIntakeCoordinator(null);
                  }

                  if (key == 'Facesheet.serviceCoordinator') {
                    setSelectedServiceCoordinator(null);
                  }

                  if (filterBy.length < 2) {
                    clearForm();
                  } else {
                    encoded({
                      startDate: filterPlainData.startDate,
                      endDate: filterPlainData.endDate,
                      startAge:
                        filterBy[index].key === 'age'
                          ? ''
                          : filterPlainData.startAge,
                      endAge:
                        filterBy[index].key === 'age'
                          ? ''
                          : filterPlainData.endAge,
                      ifspDueStartDate:
                        filterBy[index].key === 'ifspduedate'
                          ? null
                          : filterPlainData.ifspDueStartDate,
                      ifspDueEndDate:
                        filterBy[index].key === 'ifspduedate'
                          ? null
                          : filterPlainData.ifspDueEndDate,
                      assesmentStartHour:
                        filterBy[index].key === 'assessmenthours'
                          ? ''
                          : filterPlainData.assesmentStartHour,
                      assesmentEndHour:
                        filterBy[index].key === 'assessmenthours'
                          ? ''
                          : filterPlainData.assesmentEndHour,
                      status:
                        filterBy[index].key === 'filtersatus'
                          ? ''
                          : filterPlainData.status,
                      ageMonthStart:
                        filterBy[index].key === 'age'
                          ? ''
                          : filterPlainData.ageMonthStart,
                      ageMonthEnd:
                        filterBy[index].key === 'age'
                          ? ''
                          : filterPlainData.ageMonthEnd,
                      intakeFacilitator:
                        filterBy[index].key === 'Facesheet.intakeFacilitator'
                          ? ''
                          : filterPlainData.intakeFacilitator,
                      serviceCoordinator:
                        filterBy[index].key === 'Facesheet.serviceCoordinator'
                          ? ''
                          : filterPlainData.serviceCoordinator,
                      noIntakeFacilitator:
                        filterBy[index].key === 'noIntakeFacilitator'
                          ? ''
                          : filterPlainData.noIntakeFacilitator,
                      noServiceCoordinator:
                        filterBy[index].key === 'noServiceCoordinator'
                          ? ''
                          : filterPlainData.noServiceCoordinator,
                    });
                    const newFilterBy = filterBy.filter((_, i) => i !== index);
                    setFilterBy(newFilterBy);
                  }
                }}
                handleClearClick={() => {
                  setSelectedIntakeCoordinator(null);
                  setSelectedServiceCoordinator(null);
                  clearForm();
                }}
              />
            )}
          </Box>
        </Toolbar>
        {showEmptyScreen && filterData === '' && (
          <EmptyScreen
            titleLabelId="noClientsMessage"
            defaultTitleText="No Clients added yet."
            buttonLabelId="Referraldashboard.AddTitletext"
            defaultButtonTitleText="Add New Referral"
            showButton={true}
            onButtonClick={() => navigateToReferralCreation()}
          />
        )}
        {filterData !== '' && clientDetails.length === 0 && showEmptyScreen && (
          <SearchNotFound />
        )}
        {searchquery != '' && clientDetails.length === 0 && <SearchNotFound />}
        {clientDetailsCount !== 0 && clientDetails.length !== 0 && (
          <Box>
            <Card sx={{ width: '100%' }}>
              <TableContainer>
                {loader && (
                  <Box
                    sx={{
                      position: 'absolute',
                      left: '55%',
                      marginTop: '5%',
                      top: '50%',
                    }}
                  >
                    <CircularProgress sx={{ color: '#00938E' }} />
                  </Box>
                )}
                <Table
                  sx={{ minWidth: 750, maxWidth: '100%' }}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={clientDetailsCount}
                  />
                  <TableBody className="tableRowcss">
                    {clientDetails &&
                      clientDetails.map((row, indexRow) => {
                        const isItemSelected = isSelected(row.firstName);
                        return (
                          <TableRow
                            hover
                            onClick={() => {
                              handleClick(
                                row.clientId,
                                `${formatName(
                                  row.firstName,
                                  row.middleName,
                                  row.lastName,
                                  row.suffix,
                                )}`,
                                row.referralId,
                              );
                            }}
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={indexRow + row.clientId}
                            selected={isItemSelected}
                            sx={{
                              cursor: 'pointer',
                              alignContent: 'flex-start',
                              '&:nth-of-type(odd)': {
                                backgroundColor: '#ECF9F8',
                              },
                            }}
                          >
                            <TableCell padding="checkbox">
                              <CustomCheckBox value={false} />
                            </TableCell>
                            <Tooltip
                              id="tool-tip"
                              arrow
                              followCursor
                              title={formatName(
                                row.firstName,
                                row.middleName,
                                row.lastName,
                                row.suffix,
                              )}
                              placement="top"
                            >
                              <TableCell
                                className="cursorPointer"
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '100px',
                                  fontFamily: 'Lato-Regular',
                                }}
                              >
                                {`${formatName(
                                  row.firstName,
                                  row.middleName,
                                  row.lastName,
                                  row.suffix,
                                )}`}
                              </TableCell>
                            </Tooltip>

                            <TableCell
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '100px',
                                fontFamily: 'Lato-Regular',
                              }}
                            >
                              {row.dphId ? row.dphId.toUpperCase() : '-'}
                            </TableCell>

                            <TableCell
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '100px',
                                fontFamily: 'Lato-Regular',
                              }}
                            >
                              {row.dateOfBirth}
                            </TableCell>

                            <Tooltip
                              className="cursorPointer"
                              id="tool-tip"
                              arrow
                              followCursor
                              title={calculateMonths(row.dateOfBirth)
                                .replace('(', ', ')
                                .replace(')', '')}
                              placement="top"
                            >
                              <TableCell
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '100px',
                                  fontFamily: 'Lato-Regular',
                                }}
                              >
                                {calculateMonths(row.dateOfBirth)
                                  .replace('(', ', ')
                                  .replace(')', '')}
                              </TableCell>
                            </Tooltip>

                            <TableCell
                              sx={{
                                fontFamily: 'Lato-Regular',
                              }}
                            >
                              {row.ifspDueDate ? row.ifspDueDate : '-'}
                            </TableCell>

                            <Tooltip
                              className="cursorPointer"
                              id="tool-tip"
                              arrow
                              followCursor
                              title={getIntakeFacilitator(
                                row.intakeFacilitator,
                              )}
                              placement="top"
                            >
                              <TableCell
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  maxWidth: '100px',
                                  fontFamily: 'Lato-Regular',
                                }}
                              >
                                {getIntakeFacilitator(row.intakeFacilitator)}
                              </TableCell>
                            </Tooltip>
                            <Tooltip
                              className="cursorPointer"
                              id="tool-tip"
                              arrow
                              followCursor
                              title={getServiceCoordinator(
                                row.serviceCoordinator,
                              )}
                              placement="top"
                            >
                              <TableCell
                                sx={{
                                  fontFamily: 'Lato-Regular',
                                }}
                              >
                                {getServiceCoordinator(row.serviceCoordinator)}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              sx={{
                                fontFamily: 'Lato-Regular',
                              }}
                            >
                              {badgePicker(row.status)}
                              {row.status}
                            </TableCell>
                            {/* <TableCell
                            style={{
                              position: 'relative',
                              top: '0px',
                              paddingTop: '11px',
                            }}
                          >
                            {
                              <ActionMenu
                                rootView={<MoreIcon />}
                                items={[
                                  {
                                    labelId: 'viewText',
                                    defaultLabelId: 'View',
                                    visible: true,
                                  },
                                  {
                                    labelId: 'addAsClientText',
                                    defaultLabelId: 'Add as Client',
                                    visible: true,
                                  },
                                ]}
                                onItemClick={(index) => {
                                  if (index == 1) {
                                    navigateToClientCreation(row);
                                  }
                                }}
                              />
                            }
                          </TableCell> */}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>

            {clientDetailsCount > 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={page}
                  paginationCount={clientDetailsCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== page) {
                      setPage(newPage - 1);
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default ClientsDashboardPage;
