import React, { useEffect, useState } from 'react';
import MediumTypography from '../../../../components/formlib/MediumTypography';

const ProgressLine = ({
  backgroundColor = '#fff',
  highRiskValue = 55,
  visualParts = [
    {
      percentage: '0%',
      message: '',
      color: '#fff',
    },
  ],
  data = [
    {
      value: 0,
    },
  ],
}) => {
  const total = highRiskValue;
  const [widths, setWidths] = useState(
    visualParts.map(() => {
      return '';
    }),
  );

  useEffect(() => {
    requestAnimationFrame(() => {
      return setWidths(
        visualParts.map((item) => {
          return item.percentage;
        }),
      );
    });
  }, [visualParts]);

  const containerStyle = {
    display: 'flex',
    height: '15px',
  };

  const partStyle = {
    transition: 'width 0.5s',
  };

  const arrowheadStyle = {
    width: 0,
    alignSelf: 'center',
    height: '25px',
    borderTop: '12.5px solid transparent',
    borderBottom: '12.5px solid transparent',
    borderLeft: '12.5px solid #2A4241',
  };

  return (
    <>
      <div
        style={{
          ...containerStyle,
          backgroundColor,
        }}
      >
        {visualParts.map((item, index) => {
          return (
            <>
              <div
                key={index}
                style={{
                  ...partStyle,
                  width: widths[index],
                  backgroundColor: item.color,
                  border:
                    index === 0 ? '1px solid #97A6A5' : '0px solid #97A6A5',
                  borderRightWidth: index === 0 ? '0px' : 'none',
                }}
              />
              {index < visualParts.length - 1 && (
                <>
                  <div
                    style={{
                      alignSelf: 'center',
                      width: '1px',
                      height: '250%',
                      backgroundColor: '#2A4241',
                    }}
                  >
                    <MediumTypography
                      label={`${data[index].value}`}
                      sxProps={{
                        position: 'absolute',
                        marginTop: '36px',
                        marginLeft: '-8px',
                      }}
                    />
                  </div>
                </>
              )}
            </>
          );
        })}
        <div style={arrowheadStyle}></div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {visualParts.map((item, index) => (
          <div
            style={{
              width: widths[index],
              display: 'flex',
              alignItems: 'center',
              justifyContent:
                index < visualParts.length - 1 ? 'center' : 'normal',
              marginTop: '3px',
            }}
          >
            <MediumTypography
              label={item.message}
              sxProps={{
                marginLeft: index < visualParts.length - 1 ? 'opx' : '8px',
              }}
            />
            {index < visualParts.length - 1 ? null : (
              <>
                <div
                  style={{
                    display: 'flex',
                    width: '50%',
                    paddingLeft: '8px',
                    paddingTop: '3px',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: 2,
                      backgroundColor: '#97A6A5',
                    }}
                  />
                  <div
                    style={{
                      height: '10px',
                      borderTop: '5px solid transparent',
                      borderBottom: '5px solid transparent',
                      borderLeft: '5px solid #97A6A5',
                    }}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    marginLeft: '6px',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      marginTop: '-8px',
                      position: 'absolute',
                    }}
                  >
                    <MediumTypography label={`${total}+`} />
                    <MediumTypography label="(90%ile)" />
                  </div>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ProgressLine;
