import { ProgramConfigurationType } from '../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export const getProgramConfigurationApi = async (id: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ProgramConfigurationType>(
      `program_configuration?id=${id}`,
    ),
  );
};

export const editOrAddProgramConfiigurationApi = async (
  id: string,
  requestBody: ProgramConfigurationType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<ProgramConfigurationType, ApiMessage>(
      `program_configuration?id=${id}`,
      requestBody,
    ),
  );
};
