import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import IntakeClientsInfo from './IntakeClientsInfo';
import IntakePregnancyInfo from './IntakePregnancyInfo';
import IntakeCurrentHealthInfo from './IntakeCurrentHealthInfo';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { useNavigate } from 'react-router';
import { FACESHEET } from '../../routes/Routing';
import { AlertColor, Box, Grid } from '@mui/material';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  deleteResponseIntake,
  getAllForms,
  getAllResponseListIntake,
} from '../../services/configApi/forms/FormBuilder/FormBuilderServices';
import {
  FormDataResponse,
  FormList,
  FormResponseList,
  IntakeFormresponse,
  NewFormresponse,
  Order,
} from '../../utils/type';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import FormRender from '../formBuilder/FormRender';
import IntakeQuestionnaireRenderTableModal from './IntakeQuestionnaireRenderTableModal';
import IntakeFamilyHistoryInfo from './IntakeFamilyHistoryInfo';
// import { THOM_WEBSOCKET_URL } from '../../services/Constant';
import {
  downloadIntakeData,
  heartBeat,
  HeartBeatResponseType,
} from '../../services/configApi/forms/Intake/IntakeServices';

import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import dayjs from 'dayjs';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  checkOffline,
  checkOfflineAvailableForReferral,
} from '../../services/Offline';
import { ReactComponent as StopWhite } from '../../assets/images/stopWhite.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import IntakeReview from './IntakeReview';
import ShareDownloadModal from '../../components/formlib/modal/ShareDownloadModal';

export type Data = {
  name: string;
  createdOn: string;
  createdBy: string;
  program: string;
  actions: string;
  modifiedBy: string;
  modifiedOn: string;
};

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00C6B8',
          height: '2px',
          bottom: '5%',
        },
      },
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export interface TabProps {
  tabPosition: number;
}

const CustomTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    color: '#00C6B8',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
  },
}));
const CustomTabs = styled(Tabs)({
  '& .MuiButtonBase-root.MuiTab-root': {
    padding: '8px 0px 0px',
    maxWidth: '100%',
    minWidth: 'auto',
    margin: '0px 12px',
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
  },
  '&.Mui-selected': {
    color: '#008C82',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
  },
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function handleSelect(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}
// const intakeWsURL = THOM_WEBSOCKET_URL + 'ws-intake';
// const socket = new WebSocket(intakeWsURL);

export interface DownloadContextType {
  downloadIntake: boolean;
  toggleDownload: (value: boolean) => void;
}

export const DownloadContext = React.createContext<
  DownloadContextType | undefined
>(undefined);

export const showCancelToaster = (employeeName: string | null | undefined) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        display: 'flex',
        backgroundColor: '#EB4C60',
        width: '40%',
        padding: '16px 24px 16px 24px',
        justifyContent: 'center',
        borderRadius: '60px',
        alignItems: 'flex-start', // Align all children at the top
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ paddingTop: '2px' }}>
          <StopWhite />
        </Box>
        <Box>
          <MediumTypography
            textColor="#FFFFFF"
            sxProps={{ marginLeft: '8px' }}
            label={`This section has been locked. ${employeeName} is working on this.`}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default function IntakeTabNavigationComponent() {
  const clientId = localStorage.getItem('ClientId');
  const userId = localStorage.getItem('userId');
  const [tabProp, setTabProp] = React.useState<TabProps>({
    tabPosition: 0,
  });
  const [tabToBeLocked, setTabToBeLocked] = React.useState<boolean>(false);
  const [reviewStatus, setReviewStatus] = React.useState<boolean>(true);

  const [downloadIntake, setDowloadEnable] = React.useState<boolean>(false);
  const [employeeUserName, setEmployeeUserName] = React.useState<string | null>(
    '',
  );

  const toggleDownload = (value: boolean) => {
    setDowloadEnable(value);
  };
  const [shareModal, setShareModal] = React.useState<boolean>(false);
  const contextValue: DownloadContextType = {
    downloadIntake,
    toggleDownload,
  };

  // web socket connection
  // const socketref = React.useRef(socket);
  // if (
  //   socketref.current.readyState ===
  //   (socketref.current.CLOSED || socketref.current.CLOSING)
  // ) {
  //   socketref.current = new WebSocket(intakeWsURL);
  // }
  // let intervalRef: NodeJS.Timer;
  // socketref.current.onopen = () => {
  //   console.log('WebSocket connection established.');
  //   intervalRef = setInterval(() => {
  //     if (socketref.current.readyState === WebSocket.OPEN) {
  //       ping();
  //     }
  //   }, 20000);
  // };

  let empId: string | null = '';
  let empName: string | null = '';
  const onMessage = (data: HeartBeatResponseType, tabPos: number) => {
    console.log('Message from server:', data, tabPos);

    if (data.isSigned) {
      setTabToBeLocked(true);
      setReviewStatus(true);
    } else {
      setReviewStatus(false);
      if (data.clientId === clientId && data.userId === userId) {
        if (tabPos === 0) {
          empId = data.clientEmpId;
          empName = data.clientEmpName;
        } else if (tabPos === 1) {
          empId = data.pregnancyEmpId;
          empName = data.pregnancyEmpName;
        } else if (tabPos === 2) {
          empId = data.currentEmpId;
          empName = data.currentEmpName;
        } else if (tabPos === 3) {
          empId = data.familyEmpId;
          empName = data.familyEmpName;
        } else if (tabPos === 4) {
          empId = data.q1EmpId;
          empName = data.q1EmpName;
        } else if (tabPos === 5) {
          empId = data.q2EmpId;
          empName = data.q2EmpName;
        } else if (tabPos === 6) {
          empId = data.q3EmpId;
          empName = data.q3EmpName;
        }
        console.log('empId:', empId, 'empName:', empName);

        if (empId !== null && empId !== userId) {
          // lock the page and show a toaster
          setTabToBeLocked(true);
          setEmployeeUserName(empName);
          showCancelToaster(empName);
        } else {
          // unlock the page
          if (checkPermissionForFeature('backend.clients', 'editPermission')) {
            setTabToBeLocked(false);
            setOpen(false);
          } else {
            setTabToBeLocked(true);
          }
        }
      }
    }
  };
  // socketref.current.onclose = () => {
  //   console.log('WebSocket connection closed.');
  // };
  // socketref.current.onerror = (error) => {
  //   console.error('WebSocket error:', error);
  //   socketref.current.close();
  // };

  // const closeConnection = () => {
  //   socketref.current.close();
  // };

  React.useEffect(() => {
    const interval = setInterval(() => {
      ping();
    }, 20000);

    return () => {
      console.log('Clearing HeatBeat interval');
      clearInterval(interval);
    };
  }, []);

  const ping = () => {
    const tabNames = sessionStorage.getItem('intakeTabName');
    const tabPositionValue = tabNames ? +tabNames : 0;
    let tabName = '';
    if (tabPositionValue === 0) {
      tabName = 'Client';
    } else if (tabPositionValue === 1) {
      tabName = 'Pregnancy';
    } else if (tabPositionValue === 2) {
      tabName = 'Current';
    } else if (tabPositionValue === 3) {
      tabName = 'Family';
    } else if (tabPositionValue === 4) {
      tabName = 'Q1';
    } else if (tabPositionValue === 5) {
      tabName = 'Q2';
    } else if (tabPositionValue === 6) {
      tabName = 'Q3';
    }
    const pingjson = { userId: userId, clientId: clientId, tabName: tabName };
    console.log(
      'pinging server with',
      '',
      JSON.stringify(pingjson),
      tabPositionValue,
    );
    // socketref.current.send('' + JSON.stringify(pingjson));

    heartBeat(pingjson)
      .then((resp: HeartBeatResponseType) => {
        onMessage(resp, tabPositionValue);
      })
      .catch((error) => {
        if (
          window.location.hostname !== 'localhost' &&
          window.location.hostname !== '127.0.0.1'
        ) {
          setOpen(true);
        } else {
          setOpen(false);
        }
        toggleLoader(false);
        setToastrVariable('error');
        setToastrId('formBuilder.ListLoadFail');
        setToastrDefaultMessage(error);
      });
  };

  React.useEffect(() => {
    // if (socketref.current.readyState === 1) {
    ping();
    // }
  }, [tabProp]);

  React.useEffect(() => {
    function changeStatus() {
      if (navigator.onLine) {
        ping();
      }
    }
    const tabNames = sessionStorage.getItem('intakeTabName');
    const tabPositionValue = tabNames ? +tabNames : 0;
    if (tabNames !== null) {
      setTabProp((prevState) => ({
        ...prevState,
        tabPosition: tabPositionValue ? tabPositionValue : 0,
      }));
    } else {
      setTabProp(() => {
        return {
          tabPosition: 0,
        };
      });
    }
    // window.addEventListener('beforeunload', closeConnection);
    window.addEventListener('online', changeStatus);
    window.addEventListener('offline', changeStatus);
    return () => {
      // window.removeEventListener('beforeunload', closeConnection);
      window.removeEventListener('online', changeStatus);
      window.removeEventListener('offline', changeStatus);
      // window.clearInterval(intervalRef);
      // closeConnection();
    };
  }, []);

  React.useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'viewPermission')) {
      setTabToBeLocked(false);
    } else {
      setTabToBeLocked(true);
    }
    const fetchData = async () => {
      const isReferralPresent = await checkReferral();
      if (!isReferralPresent) {
        responseFetch();
      }
    };
    fetchData();
  }, []);

  const [referralPresent, setReferralPresent] = React.useState(false);

  const checkReferral = async () => {
    const referralId = localStorage.getItem('referralId') || '';
    const isReferralPresent = await checkOfflineAvailableForReferral(
      referralId,
    );
    setReferralPresent(isReferralPresent);
    return isReferralPresent;
  };

  const responseFetch = async () => {
    toggleLoader(true);
    if (clientId)
      getAllResponseListIntake(clientId)
        .then((resp: NewFormresponse) => {
          setResponseList(resp.formResponse);
          toggleLoader(false);
        })
        .catch((error) => {
          if (
            window.location.hostname !== 'localhost' &&
            window.location.hostname !== '127.0.0.1'
          ) {
            setOpen(true);
          } else {
            setOpen(false);
          }
          toggleLoader(false);
          setToastrVariable('error');
          setToastrId('formBuilder.ListLoadFail');
          setToastrDefaultMessage(error);
        });
  };

  const initialState: FormDataResponse[] = [];

  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [formList, setFormList] = React.useState<FormList[]>([]);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [modalVisible, setModalVisible] = React.useState(false);
  const [selectedRows, setSelectedRows] = React.useState<(number | string)[]>(
    [],
  );
  const [selectedFormData, setSelectedFormData] =
    React.useState<FormDataResponse[]>(initialState);
  const [responseList, setResponseList] = React.useState<IntakeFormresponse[]>(
    [],
  );
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [deleteRowId, setDeleteRowId] = React.useState<string>('');
  const [page, setPage] = React.useState(0);
  const [orderBy, setOrderBy] = React.useState<keyof Data | string>('');
  const [order, setOrder] = React.useState<Order>('asc');
  const [responseDetailsCount, setResponseDetailsCount] =
    React.useState<number>(0);
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [offlineMode, setOffline] = React.useState(false);

  React.useLayoutEffect(() => {
    const getOfflineMode = async () => {
      const offlineFlag = await checkOffline();
      setOffline(offlineFlag);
    };
    getOfflineMode();
  });

  const handleCheckboxChange = (
    id: string | number,
    name: string,
    url: string,
  ) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));

      setSelectedFormData((prevSelectedForms) =>
        prevSelectedForms.filter((form) => form.id !== id),
      );
    } else {
      setSelectedRows([...selectedRows, id]);

      setSelectedFormData((prevSelectedForms) => [
        ...prevSelectedForms,
        { id, name, url },
      ]);
    }
  };

  const addButton = (id: string | number, name: string, url: string) => {
    setSelectedRows([...selectedRows, id]);
    setSelectedFormData((prevSelectedForms) => [
      ...prevSelectedForms,
      { id, name, url },
    ]);
    setModalVisible(false);

    setTabProp((prevState) => ({
      ...prevState,
      tabPosition: 4 + responseList.length + selectedRows.length,
    }));
  };

  const handleChange = (
    _event: React.ChangeEvent<object>,
    position: number,
  ) => {
    sessionStorage.setItem('intakeTabName', String(position));
    setTabProp({ ...tabProp, tabPosition: position });
  };

  const navigation = useNavigate();

  const Others = 'OTHERS';

  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getInitialFormList();
  }, [page, order, orderBy]);

  const getInitialFormList = () => {
    toggleLoader(true);
    getAllForms(Others, page, 10, order, '', orderBy, false)
      .then((response: FormResponseList) => {
        const { forms } = response;
        setFormList(forms);
        setResponseDetailsCount(response.totalFormsCount);
        if (forms.length > 0) {
          setModalVisible(true);
        } else {
          setOpen(true);
          toggleLoader(false);
          setToastrVariable('error');
          setToastrId('formBuilder.EmptyFormListResponse');
          setToastrDefaultMessage('formBuilder.EmptyFormListResponse');
        }
        toggleLoader(false);
      })
      .catch((error) => {
        if (
          window.location.hostname !== 'localhost' &&
          window.location.hostname !== '127.0.0.1'
        ) {
          setOpen(true);
        } else {
          setOpen(false);
        }
        toggleLoader(false);
        setToastrVariable('error');
        setToastrId('formBuilder.ListLoadFail');
        setToastrDefaultMessage(error.message);
      });
  };

  const handleMainCheckboxChange = () => {
    if (selectedRows.length === formList.length) {
      setSelectedRows([]);
      setSelectedFormData([]);
    } else {
      setSelectedRows(formList.map((row) => row.id));
      const allFormData = formList.map((row) => ({
        id: row.id,
        name: row.name,
        url: row.templateUrl,
      }));
      setSelectedFormData(allFormData);
    }
  };

  const saveFunctionTab = (index: number) => {
    toggleLoader(true);
    setTimeout(() => {
      responseFetch();
      setSelectedFormData((prevSelectedForms) => {
        const updatedSelectedForms = [...prevSelectedForms];
        updatedSelectedForms.splice(index, 1);
        return updatedSelectedForms;
      });
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((_row, i) => i !== index),
      );
    }, 2000);
  };

  const cancelQuestionnaire = (index: number) => {
    setSelectedFormData((prevSelectedForms) => {
      const updatedSelectedForms = [...prevSelectedForms];
      updatedSelectedForms.splice(index, 1);
      return updatedSelectedForms;
    });
    setSelectedRows((prevSelectedRows) =>
      prevSelectedRows.filter((_row, i) => i !== index),
    );
    sessionStorage.setItem('intakeTabName', String(0));
    setTabProp((prevState) => ({
      ...prevState,
      tabPosition: 0,
    }));
  };

  const handleSort = (property: string) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (newPage - 1 !== page) {
      setPage(newPage - 1);
      setFormList([]);
      setResponseDetailsCount(0);
    }
  };

  const handleDownload = () => {
    const localTimezone = dayjs.tz.guess();
    toggleLoader(true);
    if (clientId) {
      downloadIntakeData(clientId, localTimezone)
        .then((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);

          // Open the Blob URL in a new tab
          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.target = '_blank';
          anchor.download = `Intake.pdf`; // Set desired file name here

          // Append anchor to document body
          document.body.appendChild(anchor);

          // Trigger anchor click
          anchor.click();

          window.open(blobUrl, '_blank');

          // Clean up after use
          URL.revokeObjectURL(blobUrl);
          document.body.removeChild(anchor);

          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('ConsentForms.downloadErrorMsg');
            setToastrDefaultMessage('Failed to download');
          }
        });
    }
  };

  const handleReview = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <DownloadContext.Provider value={contextValue}>
        <Box sx={{ position: 'relative' }}>
          {!reviewStatus && tabToBeLocked && employeeUserName && (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 1,
                width: '100%',
                justifyContent: 'center',
                display: 'flex',
                paddingTop: '3px',
              }}
            >
              {showCancelToaster(employeeUserName)}
            </Box>
          )}
          <SnackBarComponent
            open={open}
            handleClose={() => setOpen(false)}
            successOrError={toastrVariable}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
          />

          <Box className="rowContainer pb-none">
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Box className="display-flex-center">
                  <CustomBackArrow onClick={() => navigation(FACESHEET)} />
                  <Box className="addnewclient ml-md">
                    <TitleText
                      labelid="Intake.Titletext"
                      defaultlabel="Inatke"
                    />
                  </Box>
                </Box>
              </Grid>
              {
                <Grid item xs={6} lg={6}>
                  <Box
                    component="div"
                    className="flex__ text-align-end justifyContent-FlexEnd"
                  >
                    {!reviewStatus &&
                      checkPermissionForFeature(
                        'backend.clients',
                        'editPermission',
                      ) && (
                        <ButtonComponent
                          className="btn-primary btn-submit mr-md"
                          variantType="outlined"
                          labelId="Intake.AddQuestionnaire"
                          defaultLabelId="Add Questionnaire"
                          onClick={getInitialFormList}
                          disabled={
                            referralPresent ||
                            selectedFormData.length + responseList.length === 3
                          }
                        />
                      )}
                    {!offlineMode && (
                      <>
                        <Box component="div" className="flex__ ">
                          <ButtonComponent
                            className="btn-primary btn-submit mr-md"
                            labelId="ConsentForms.listActionDownloadToolTipLabel"
                            defaultLabelId="Download"
                            variantType="contained"
                            disabled={!downloadIntake}
                            onClick={handleDownload}
                          />
                        </Box>
                        <Box component="div" className="flex__ ">
                          <ButtonComponent
                            className="btn-primary btn-submit mr-md"
                            labelId="ConsentForms.listActionShareToolTipLabel"
                            defaultLabelId="Share"
                            variantType="contained"
                            disabled={!downloadIntake}
                            onClick={() => setShareModal(true)}
                          />
                        </Box>
                      </>
                    )}
                    {!referralPresent && (
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        labelId={
                          reviewStatus
                            ? 'Intake.ReviewedBtn'
                            : 'Intake.ReviewBtn'
                        }
                        defaultLabelId="Review"
                        variantType="contained"
                        disabled={!downloadIntake && !reviewStatus}
                        onClick={handleReview}
                      />
                    )}
                  </Box>
                </Grid>
              }
            </Grid>
          </Box>
          <ThemeProvider theme={theme}>
            <Box sx={{ width: '100%' }}>
              <CustomTabs
                value={tabProp.tabPosition}
                onChange={handleChange}
                aria-label="secondary tabs example"
              >
                <CustomTab
                  {...handleSelect(0)}
                  label="Client Info"
                  className="ml-none"
                />
                <CustomTab {...handleSelect(1)} label="Pregnancy" />
                <CustomTab {...handleSelect(2)} label="Current Health" />
                <CustomTab {...handleSelect(3)} label="Family History" />
                {responseList.length > 0 &&
                  responseList.map((data, index) => {
                    return (
                      <CustomTab
                        key={index}
                        {...handleSelect(index + 4)}
                        label={`Questionnaire ${index + 1}`}
                      />
                    );
                  })}

                {selectedFormData.length > 0 &&
                  selectedFormData.map((data, index) => {
                    return (
                      <CustomTab
                        key={index}
                        {...handleSelect(index + responseList.length + 4)}
                        label={`Questionnaire ${
                          index + responseList.length + 1
                        }`}
                      />
                    );
                  })}
              </CustomTabs>
              <TabPanel value={tabProp.tabPosition} index={0}>
                {tabToBeLocked ? (
                  <div
                    ref={parentRef}
                    style={{
                      pointerEvents: 'none',
                      opacity: '0.8',
                    }}
                    onKeyDownCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onFocus={() => {
                      if (parentRef.current) parentRef.current.focus();
                    }}
                  >
                    <IntakeClientsInfo setTabProp={setTabProp} />
                  </div>
                ) : (
                  <IntakeClientsInfo setTabProp={setTabProp} />
                )}
              </TabPanel>
              <TabPanel value={tabProp.tabPosition} index={1}>
                {tabToBeLocked ? (
                  <div
                    ref={parentRef}
                    style={{
                      pointerEvents: 'none',
                      opacity: '0.8',
                    }}
                    onKeyDownCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onFocus={() => {
                      if (parentRef.current) parentRef.current.focus();
                    }}
                  >
                    <IntakePregnancyInfo setTabProp={setTabProp} />
                  </div>
                ) : (
                  <IntakePregnancyInfo setTabProp={setTabProp} />
                )}
              </TabPanel>
              <TabPanel value={tabProp.tabPosition} index={2}>
                {tabToBeLocked ? (
                  <div
                    ref={parentRef}
                    style={{
                      pointerEvents: 'none',
                      opacity: '0.8',
                    }}
                    onKeyDownCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onFocus={() => {
                      if (parentRef.current) parentRef.current.focus();
                    }}
                  >
                    <IntakeCurrentHealthInfo setTabProp={setTabProp} />
                  </div>
                ) : (
                  <IntakeCurrentHealthInfo setTabProp={setTabProp} />
                )}
              </TabPanel>
              <TabPanel value={tabProp.tabPosition} index={3}>
                {tabToBeLocked ? (
                  <div
                    ref={parentRef}
                    style={{
                      pointerEvents: 'none',
                      opacity: '0.8',
                    }}
                    onKeyDownCapture={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onFocus={() => {
                      if (parentRef.current) parentRef.current.focus();
                    }}
                  >
                    <IntakeFamilyHistoryInfo setTabProp={setTabProp} />
                  </div>
                ) : (
                  <IntakeFamilyHistoryInfo setTabProp={setTabProp} />
                )}
              </TabPanel>
              {responseList.length > 0 &&
                responseList.map((data, index) => {
                  return (
                    <Box>
                      <TabPanel value={tabProp.tabPosition} index={index + 4}>
                        {tabToBeLocked ? (
                          <div
                            ref={parentRef}
                            style={{
                              pointerEvents: 'none',
                              opacity: '0.8',
                            }}
                            onKeyDownCapture={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onFocus={() => {
                              if (parentRef.current) parentRef.current.focus();
                            }}
                          >
                            <FormRender
                              mainId={data.id}
                              className="p-none"
                              forms={data.forms}
                              name={data.name}
                              clientId={localStorage.getItem('ClientId')}
                              saveCallback={() => {
                                setTimeout(() => {
                                  responseFetch();
                                }, 1000);
                              }}
                              deleteRecord={() => {
                                setDeleteModal(true);
                                setDeleteRowId(data.id);
                              }}
                              deleteModal={deleteModal}
                              setDeleteModal={() => setDeleteModal(false)}
                              onOk={() => {
                                {
                                  toggleLoader(true);
                                  deleteResponseIntake(deleteRowId)
                                    .then(async (response) => {
                                      setOpen(true);
                                      setToastrId(response.message);
                                      setToastrDefaultMessage(
                                        'Response deleted successfully',
                                      );
                                      setToastrVariable('success');
                                      setResponseList([]);
                                      setTabProp((prevState) => ({
                                        ...prevState,
                                        tabPosition: 0,
                                      }));
                                      setDeleteModal(false);
                                      responseFetch();
                                    })
                                    .catch(async (error) => {
                                      setOpen(true);
                                      setToastrVariable('error');
                                      setToastrId(error.message);
                                      setToastrDefaultMessage(error.message);
                                    });
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <FormRender
                            mainId={data.id}
                            className="p-none"
                            forms={data.forms}
                            name={data.name}
                            clientId={localStorage.getItem('ClientId')}
                            saveCallback={() => {
                              sessionStorage.setItem(
                                'intakeTabName',
                                tabProp.tabPosition.toString(),
                              );
                              setTimeout(() => {
                                responseFetch();
                              }, 1000);
                            }}
                            deleteRecord={() => {
                              setDeleteModal(true);
                              setDeleteRowId(data.id);
                            }}
                            deleteModal={deleteModal}
                            setDeleteModal={() => setDeleteModal(false)}
                            onOk={() => {
                              {
                                toggleLoader(true);
                                deleteResponseIntake(deleteRowId)
                                  .then(async (response) => {
                                    setOpen(true);
                                    setToastrId(response.message);
                                    setToastrDefaultMessage(
                                      'Response deleted successfully',
                                    );
                                    setToastrVariable('success');
                                    setResponseList([]);
                                    sessionStorage.setItem(
                                      'intakeTabName',
                                      '0',
                                    );
                                    setTabProp((prevState) => ({
                                      ...prevState,
                                      tabPosition: 0,
                                    }));
                                    setDeleteModal(false);
                                    responseFetch();
                                  })
                                  .catch(async (error) => {
                                    setOpen(true);
                                    setToastrVariable('error');
                                    setToastrId(error.message);
                                    setToastrDefaultMessage(error.message);
                                  });
                              }
                            }}
                          />
                        )}
                      </TabPanel>
                    </Box>
                  );
                })}
              {selectedFormData.length > 0 &&
                selectedFormData.map((data, index) => {
                  return (
                    <Box>
                      <TabPanel
                        value={tabProp.tabPosition}
                        index={index + responseList.length + 4}
                      >
                        {tabToBeLocked ? (
                          <div
                            ref={parentRef}
                            style={{
                              pointerEvents: 'none',
                              opacity: '0.8',
                            }}
                            onKeyDownCapture={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            onFocus={() => {
                              if (parentRef.current) parentRef.current.focus();
                            }}
                          >
                            <FormRender
                              id={data.id}
                              name={data.name}
                              clientId={localStorage.getItem('ClientId')}
                              saveCallback={() => {
                                saveFunctionTab(index);
                              }}
                              deleteModal={deleteModal}
                              setDeleteModal={() => setDeleteModal(false)}
                              deleteRecord={() => {
                                setDeleteModal(true);
                              }}
                              newForm={true}
                              onOk={() => {
                                cancelQuestionnaire(index);
                                setDeleteModal(false);
                              }}
                            />
                          </div>
                        ) : (
                          <FormRender
                            id={data.id}
                            name={data.name}
                            clientId={localStorage.getItem('ClientId')}
                            saveCallback={() => {
                              sessionStorage.setItem(
                                'intakeTabName',
                                tabProp.tabPosition.toString(),
                              );
                              saveFunctionTab(index);
                            }}
                            deleteModal={deleteModal}
                            setDeleteModal={() => setDeleteModal(false)}
                            deleteRecord={() => {
                              setDeleteModal(true);
                            }}
                            newForm={true}
                            onOk={() => {
                              sessionStorage.setItem(
                                'intakeTabName',
                                String(0),
                              );
                              cancelQuestionnaire(index);
                              setDeleteModal(false);
                            }}
                          />
                        )}
                      </TabPanel>
                    </Box>
                  );
                })}
            </Box>
          </ThemeProvider>

          <IntakeQuestionnaireRenderTableModal
            active={orderBy === 'name'}
            direction={orderBy === 'name' ? order : 'asc'}
            handleSort={handleSort}
            page={page}
            count={responseDetailsCount}
            modalVisible={modalVisible}
            setModalVisible={() => setModalVisible(false)}
            selectedRows={selectedRows}
            handleMainCheckboxChange={() => handleMainCheckboxChange()}
            formList={formList}
            selectedFormData={selectedFormData}
            handleCheckboxChange={handleCheckboxChange}
            addButton={addButton}
            responseList={responseList.flatMap((data) => data.forms)}
            handleChangePage={handleChangePage}
          />
        </Box>
      </DownloadContext.Provider>

      {openModal && (
        <IntakeReview
          open={openModal}
          handleClose={() => {
            sessionStorage.setItem('intakeTabName', String(0));
            setTabProp((prevState) => ({
              ...prevState,
              tabPosition: 0,
            }));
            setOpenModal(false);
          }}
        />
      )}

      {shareModal && (
        <ShareDownloadModal
          modalVisible={shareModal}
          closeModal={() => setShareModal(false)}
          screenName={'intake'}
        />
      )}
    </>
  );
}
