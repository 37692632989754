import { AlertColor, Box, Card, Grid } from '@mui/material';
import { useEffect, useRef, useState, useContext } from 'react';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import TitleText from '../../components/formlib/TitleText';
import {
  DropDownList,
  EICSForm,
  EICSTransitionLookupValues,
  SignatureStateType,
} from '../../utils/type';
import * as yup from 'yup';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import MediumTypography from '../../components/formlib/MediumTypography';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import {
  addTransitionData,
  getEICSLookupValues,
  getTransitiondata,
} from '../../services/eicsTransitionApi/EicsTransitionApi';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import { useNavigate } from 'react-router';
import { EICS_TBR, FACESHEET } from '../../routes/Routing';
import dayjs, { Dayjs } from 'dayjs';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { ReactComponent as GoTo } from '../../assets/images/goTo.svg';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import _ from 'lodash';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

function EICSTBRTransitionAndDischargeInformation() {
  const clientId = localStorage.getItem('ClientId');
  const navigation = useNavigate();
  const [signClicked, setSignClicked] = useState<boolean>(false);

  useEffect(() => {
    toggleLoader(true);
    getEICSLookupValues()
      .then((response: EICSTransitionLookupValues) => {
        setReferralDischargeReasonData(response.referralDischargeReason);
        setTransitionReasondata(response.transitionReason);
        setLeaReferralReasonData(response.leaReferralReason);
        setTpcReasonData(response.tpcReason);
        getTransitiondataFunction();
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error.message);
      });
  }, []);

  const getTransitiondataFunction = () => {
    toggleLoader(true);
    getTransitiondata(clientId)
      .then((response: EICSForm) => {
        if (formRef.current) {
          formRef.current.setValues({
            ...response,
          });
        }
        setEicsAndTbrData(response);
        setDateOfBirth(response.dateOfBirth);

        if (response.dischargeApproved) {
          setTcsFlag(true);
        }
        validationFunction(response);
        validationFunctionLEAInformation(response);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error.message);
      });
  };

  const validationFunction = (response: EICSForm) => {
    setTrasitiondateMandatory(response.transitionPlan == 'Yes');

    if (response.transitionPlanDate) {
      if (!response.dateOfBirth) {
        throw new Error('Date of birth is undefined');
      }

      const dob = new Date(response.dateOfBirth);
      const transitionDate = new Date(response.transitionPlanDate);

      const dobYear = dob.getFullYear();
      const dobMonth = dob.getMonth();
      const transitionYear = transitionDate.getFullYear();
      const transitionMonth = transitionDate.getMonth();

      const yearDifferenceInMonths = (transitionYear - dobYear) * 12;
      const monthDifference = transitionMonth - dobMonth;
      const totalMonthDifference = yearDifferenceInMonths + monthDifference;
      const isWithinRange =
        totalMonthDifference >= 27 && totalMonthDifference <= 33;
      setTrransitionReasonMandatory(!isWithinRange);
    }
  };

  const validationFunctionLEAInformation = (response: EICSForm) => {
    if (response.leaReferralConsent == 'Yes') {
      setConsentDatemandatory(true);
      setShowdateOfConsentAndLEAOutsideReferralReason(true);
    } else {
      setConsentDatemandatory(false);
      setShowdateOfConsentAndLEAOutsideReferralReason(false);
    }

    if (response.leaEligible == 'Yes') {
      setConsentProvidedForLEAMandatory(true);
    } else {
      setConsentProvidedForLEAMandatory(false);
    }

    if (response.leaReferralConsentDate) {
      if (!response.dateOfBirth) {
        throw new Error('Date of birth is undefined');
      }

      const dob = new Date(response.dateOfBirth);
      const transitionDate = new Date(response.leaReferralConsentDate);

      const dobYear = dob.getFullYear();
      const dobMonth = dob.getMonth();
      const transitionYear = transitionDate.getFullYear();
      const transitionMonth = transitionDate.getMonth();

      const yearDifferenceInMonths = (transitionYear - dobYear) * 12;
      const monthDifference = transitionMonth - dobMonth;
      const totalMonthDifference = yearDifferenceInMonths + monthDifference;
      const isWithinRange =
        totalMonthDifference >= 27 && totalMonthDifference <= 33;
      setLeaReferralOutsideDropdown(!isWithinRange);
    }
  };
  const initialValues: EICSForm = {
    transitionPlan: '',
    transitionPlanDate: null,
    noTransitionPlanReason: '',
    leaEligible: '',
    leaReferralConsent: '',
    leaReferralConsentDate: null,
    leaReferralReason: '',
    tpcDate: null,
    noTPCReason: '',
    outsideTPCReason: '',
    dischargeDate: null,
    dischargeReason: '',
    includeDischargeInfoInNextEMR: false,
    allowApprove: false,
    signature: {
      name: '',
      relationship: '',
      email: '',
      signature: '',
      rowKey: '',
      partitionKey: '',
      verbal: false,
      id: '',
      date: '',
      license: '',
    },
    appSource: '',
    dischargeApproved: false,
  };

  const formRef = useRef<FormikProps<EICSForm>>(null);
  const [eicsAndTbrData, setEicsAndTbrData] = useState<EICSForm>(initialValues);
  const [referralDischargeReasonData, setReferralDischargeReasonData] =
    useState<DropDownList[]>([]);
  const [transitionReasondata, setTransitionReasondata] = useState<
    DropDownList[]
  >([]);
  const [leaReferralReasonData, setLeaReferralReasonData] = useState<
    DropDownList[]
  >([]);
  const [tpcReasonData, setTpcReasonData] = useState<DropDownList[]>([]);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState<string>();
  const [transitionDateMandatory, setTrasitiondateMandatory] =
    useState<boolean>(false);
  const [transitionReasonMandatory, setTrransitionReasonMandatory] =
    useState<boolean>(false);
  const [leaReferralOutsideDropdown, setLeaReferralOutsideDropdown] =
    useState<boolean>(false);
  const [dateOfBirth, setDateOfBirth] = useState<string | undefined>('');
  const [consentProvidedForLEAMandatory, setConsentProvidedForLEAMandatory] =
    useState<boolean>(false);
  const [consentDatemandatory, setConsentDatemandatory] =
    useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [approveModal, setApproveModal] = useState<boolean>(false);
  const [
    showdateOfConsentAndLEAOutsideReferralReason,
    setShowdateOfConsentAndLEAOutsideReferralReason,
  ] = useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [successOrError, setSuccessOrError] = useState<AlertColor>('success');

  const [tcsFlag, setTcsFlag] = useState<boolean>(false);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  const validationSchema = yup.object().shape({
    transitionPlanDate: yup.string().nullable(),

    dischargeReason: yup
      .string()
      .required('Discharge reason is required')
      .nullable(),

    dischargeDate: yup
      .string()
      .required('Discharge date is required')
      .nullable(),

    includeDischargeInfoInNextEMR: yup.boolean().default(false),

    noTransitionPlanReason: yup.string().nullable(),

    leaReferralReason: yup.string().nullable(),

    leaReferralConsent: yup.string().when('leaEligible', {
      is: 'Yes',
      then: yup.string().nullable(),
      otherwise: yup.string().nullable(),
    }),

    leaReferralConsentDate: yup.string().nullable(),
  });

  const validateForm = (value: EICSForm) => {
    const errors: Partial<EICSForm> = {};

    if (
      datePickerMinDateMaxDateValidate(value.transitionPlanDate) ||
      datePickerMinDateMaxDateValidate(value.dischargeDate) ||
      datePickerMinDateMaxDateValidate(value.leaReferralConsentDate) ||
      datePickerMinDateMaxDateValidate(value.tpcDate)
    ) {
      errors.dischargeDate = 'datePickerMinDateMaxDateValidate';
      errors.tpcDate = 'datePickerMinDateMaxDateValidate';
      errors.transitionPlanDate = 'datePickerMinDateMaxDateValidate';
      errors.leaReferralConsentDate = 'datePickerMinDateMaxDateValidate';
    }
    return errors;
  };

  const transformedArrayDischareReason = referralDischargeReasonData.map(
    (item) => {
      const label = `${item.description}`;

      return {
        id: item.code,
        label,
      };
    },
  );

  const transformedArrayTransitionReason = transitionReasondata.map((item) => {
    const label = `${item.description}`;

    return {
      id: item.code,
      label,
    };
  });

  const transformedArrayLEAReferralReason = leaReferralReasonData.map(
    (item) => {
      const label = `${item.description}`;

      return {
        id: item.code,
        label,
      };
    },
  );

  const transformedArrayTpcReason = tpcReasonData.map((item) => {
    const label = `${item.description}`;

    return {
      id: item.code,
      label,
    };
  });

  const handleSave = (values: EICSForm) => {
    toggleLoader(true);
    addTransitionData(values, clientId)
      .then((response) => {
        toggleLoader(false);
        setSuccessOrError('success');
        setToastrId(response.message);
        setToastrDefaultMessage('Transition saved successfully');
        getTransitiondataFunction();
      })
      .catch((error) => {
        setSuccessOrError('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error.message);
        toggleLoader(false);
      });
  };

  const onSignatureSave = (sign: string) => {
    setSignClicked(!signClicked);
    setEicsAndTbrData({
      ...eicsAndTbrData,
      signature: {
        ...eicsAndTbrData.signature,
        partitionKey: '',
        rowKey: '',
        signature: sign,
      },
    });
  };

  const clearSignature = () => {
    setEicsAndTbrData({
      ...eicsAndTbrData,
      signature: {
        ...eicsAndTbrData.signature,
        partitionKey: '',
        rowKey: '',
        signature: '',
      },
    });
  };

  const signatureCallBack = (response: SignatureStateType[]) => {
    setEicsAndTbrData({
      ...eicsAndTbrData,
      signature: {
        ...eicsAndTbrData.signature,
        partitionKey: response[0].partitionkey,
        rowKey: response[0].rowkey,
        signature: response[0].signaturedata,
      },
    });
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setSuccessOrError(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const onCancelClick = () => {
    setSignClicked(!signClicked);
  };

  return (
    <>
      <Formik
        initialValues={eicsAndTbrData}
        validationSchema={validationSchema}
        validate={validateForm}
        validateOnChange
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={(values) => {
          handleSave(values);
        }}
      >
        {({ values, setFieldValue, errors, touched, handleSubmit }) => (
          <>
            <Box component={'main'}>
              <div
                ref={parentRef}
                style={{
                  pointerEvents: isScreenLocked ? 'none' : 'auto',
                  opacity: isScreenLocked ? '0.5' : '1 ',
                }}
                onKeyDownCapture={(e) => {
                  if (isScreenLocked) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onFocus={() => {
                  if (isScreenLocked) {
                    if (parentRef.current) parentRef.current.focus();
                  }
                }}
              >
                <Box component="section">
                  {toastrId && (
                    <SnackBarComponent
                      open={toastrId !== undefined}
                      handleClose={() => {
                        setToastrId(undefined);
                      }}
                      labelId={toastrId}
                      defaultMessageId={toastrDefaultMessage}
                      successOrError={successOrError}
                    />
                  )}
                  <Box component={'div'} className="rowContainer">
                    <CustomBackArrow onClick={() => navigation(FACESHEET)} />
                    <Box component="div" className="ml-md">
                      <TitleText
                        labelid="DischargeText"
                        defaultlabel="Discharge"
                      />
                    </Box>
                  </Box>
                  <Card className="formCardview">
                    <Box sx={{ display: 'none' }}>
                      <Box>
                        <Box className="flex__ alignItemCenter">
                          <Box component="div" sx={{ whiteSpace: 'nowrap' }}>
                            <MediumTypography
                              labelid="Transition Plan  Information (TPD) - "
                              defaultlabel={`Transition Plan  Information (TPD) - `}
                              fontSize="16px"
                              fontweight={600}
                            />
                          </Box>
                          <Box
                            component="div"
                            className="ml-xs"
                            sx={{ wordBreak: 'break-word' }}
                          >
                            <MediumTypography
                              labelid="required no later than 90 days from 3rd birthday"
                              defaultlabel={`required no later than 90 days from 3rd birthday`}
                              fontSize="14px"
                              fontStyle="italic"
                            />
                          </Box>
                        </Box>
                        <Grid
                          container
                          rowSpacing={'24px'}
                          className="pt-md align__items__center"
                        >
                          <Grid item xs={1.2}>
                            <MediumTypography
                              labelid="EICS.TransitionPlan"
                              defaultlabel={`Transition Plan `}
                              fontSize="14px"
                            />
                          </Grid>
                          <Grid item xs={1.5}>
                            <CustomRadioButton
                              data={[
                                {
                                  value: 'Yes',
                                  labelId: 'Yes',
                                  defaultLabel: 'Yes',
                                },
                                {
                                  value: 'No',
                                  labelId: 'No',
                                  defaultLabel: 'No',
                                },
                              ]}
                              value={eicsAndTbrData.transitionPlan}
                              onSelectItem={(value) => {
                                setTrasitiondateMandatory(
                                  value === 'Yes' ? true : false,
                                );
                                setTrransitionReasonMandatory(
                                  value === 'No' ? true : false,
                                );
                                setEicsAndTbrData((prevValue) => ({
                                  ...prevValue,
                                  transitionPlan: value,
                                }));
                                formRef.current?.setValues((prevValues) => ({
                                  ...prevValues,
                                  transitionPlanDate: null,
                                  noTransitionPlanReason: '',
                                }));

                                setEicsAndTbrData((prevValues) => ({
                                  ...prevValues,
                                  transitionPlanDate: null,
                                }));
                              }}
                              row={false}
                            />
                          </Grid>

                          <Grid item xs={4}>
                            {transitionDateMandatory ? (
                              <DatePickerComponent
                                labelid="EICS.TransitionPlandate"
                                disableFuture={false}
                                required={transitionDateMandatory}
                                defaultlabelid="Date of Transition Plan*"
                                value={dayjs(values.transitionPlanDate)}
                                handlechange={(dateValue: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(dateValue).format('YYYY-MM-DD');
                                  if (dateValue === null) {
                                    setFieldValue('transitionPlanDate', null);
                                    setEicsAndTbrData((prevValue) => ({
                                      ...prevValue,
                                      transitionPlanDate: null,
                                    }));
                                  } else {
                                    setFieldValue(
                                      'transitionPlanDate',
                                      formattedDate,
                                    );
                                    setEicsAndTbrData((prevValue) => ({
                                      ...prevValue,
                                      transitionPlanDate: formattedDate,
                                    }));
                                  }

                                  if (dateValue) {
                                    if (!dateOfBirth) {
                                      throw new Error(
                                        'Date of birth is undefined',
                                      );
                                    }

                                    const dob = new Date(dateOfBirth);
                                    const transitionDate = new Date(
                                      dateValue.toDate(),
                                    );

                                    const dobYear = dob.getFullYear();
                                    const dobMonth = dob.getMonth();
                                    const transitionYear =
                                      transitionDate.getFullYear();
                                    const transitionMonth =
                                      transitionDate.getMonth();

                                    const yearDifferenceInMonths =
                                      (transitionYear - dobYear) * 12;
                                    const monthDifference =
                                      transitionMonth - dobMonth;
                                    const totalMonthDifference =
                                      yearDifferenceInMonths + monthDifference;
                                    const isWithinRange =
                                      totalMonthDifference >= 27 &&
                                      totalMonthDifference <= 33;
                                    setTrransitionReasonMandatory(
                                      !isWithinRange,
                                    );
                                  }
                                }}
                              />
                            ) : null}
                            {errors.transitionPlanDate &&
                            touched.transitionPlanDate &&
                            transitionDateMandatory ? (
                              <Box className="loginError">
                                <MediumTypography
                                  labelid={errors.transitionPlanDate}
                                  className="errorText-md"
                                />
                              </Box>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid
                          container
                          rowSpacing={'24px'}
                          className="pt-md align__items__center"
                        >
                          <Grid item xs={8.3}>
                            <DropdownComponent
                              name="noTransitionPlanReason"
                              Required={transitionReasonMandatory}
                              names={transformedArrayTransitionReason}
                              value={values.noTransitionPlanReason}
                              defaultlabelid="Reason for “No transition plan” or outside “expected” timeframe"
                              labelid="EICS.TransitionPlanDropdown"
                              handleChange={(value: string) => {
                                setFieldValue('noTransitionPlanReason', value);
                                setEicsAndTbrData((prevValue) => ({
                                  ...prevValue,
                                  noTransitionPlanReason: value,
                                }));
                              }}
                            />
                            <MediumTypography
                              labelid="EICS.TransitionPlanDropDownLabel"
                              sxProps={{ fontStyle: 'italic' }}
                              defaultlabel={
                                '“expected” is after child turns 27 months and before child turns 33 months'
                              }
                              fontSize="14px"
                            />
                            {errors.noTransitionPlanReason &&
                            touched.noTransitionPlanReason &&
                            transitionReasonMandatory ? (
                              <Box className="loginError">
                                <MediumTypography
                                  labelid={errors.noTransitionPlanReason}
                                  className="errorText-md"
                                />
                              </Box>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>

                    <Box className="pt-lg" display={'none'}>
                      <Box>
                        <MediumTypography
                          labelid="EICS.LEAReferralInformation"
                          defaultlabel={`LFA Referral Information`}
                          fontSize="16px"
                          fontweight={600}
                        />
                        <Grid
                          container
                          rowSpacing={'24px'}
                          className="pt-md align__items__center"
                        >
                          <Grid item xs={1.7}>
                            <MediumTypography
                              labelid="EICS.PotentiallyLEAEligible"
                              defaultlabel={`Potentially LEA eligible `}
                              fontSize="14px"
                            />
                          </Grid>
                          <Grid item xs={1.5}>
                            <CustomRadioButton
                              data={[
                                {
                                  value: 'Yes',
                                  labelId: 'Yes',
                                  defaultLabel: 'Yes',
                                },
                                {
                                  value: 'No',
                                  labelId: 'No',
                                  defaultLabel: 'No',
                                },
                              ]}
                              value={eicsAndTbrData.leaEligible}
                              onSelectItem={(value) => {
                                setLeaReferralOutsideDropdown(false);
                                setConsentDatemandatory(false);
                                setFieldValue('leaEligible', value);
                                setFieldValue('leaReferralConsentDate', null);
                                setFieldValue('leaReferralReason', '');
                                setEicsAndTbrData((prevValue) => ({
                                  ...prevValue,
                                  leaEligible: value,
                                  leaReferralConsent: '',
                                }));

                                setConsentProvidedForLEAMandatory(
                                  value == 'Yes' ? true : false,
                                );

                                if (value == 'No') {
                                  setShowdateOfConsentAndLEAOutsideReferralReason(
                                    false,
                                  );
                                  setFieldValue(
                                    'tpcDate',
                                    eicsAndTbrData.transitionPlanDate,
                                  );
                                  setEicsAndTbrData((prevValue) => ({
                                    ...prevValue,
                                    tpcDate: eicsAndTbrData.transitionPlanDate,
                                  }));
                                } else {
                                  setFieldValue('tpcDate', null);
                                  setEicsAndTbrData((prevValue) => ({
                                    ...prevValue,
                                    tpcDate: null,
                                  }));
                                }
                              }}
                              row={false}
                            />
                          </Grid>
                        </Grid>
                        {consentProvidedForLEAMandatory && (
                          <Grid
                            container
                            rowSpacing={'24px'}
                            className="pt-md align__items__center"
                          >
                            <Grid item xs={2.4}>
                              <MediumTypography
                                labelid="EICS.ConsentLEAReferral"
                                defaultlabel={`Consent provided for LEA referral`}
                                fontSize="14px"
                              />
                            </Grid>
                            <Grid item xs={1.5}>
                              <CustomRadioButton
                                data={[
                                  {
                                    value: 'Yes',
                                    labelId: 'Yes',
                                    defaultLabel: 'Yes',
                                  },
                                  {
                                    value: 'No',
                                    labelId: 'No',
                                    defaultLabel: 'No',
                                  },
                                ]}
                                value={eicsAndTbrData.leaReferralConsent}
                                onSelectItem={(value) => {
                                  setConsentDatemandatory(
                                    value === 'Yes' ? true : false,
                                  );
                                  setFieldValue('leaEligible', 'No');
                                  setEicsAndTbrData((prevValue) => ({
                                    ...prevValue,
                                    leaReferralConsent: value,
                                  }));
                                  setShowdateOfConsentAndLEAOutsideReferralReason(
                                    value == 'Yes' ? true : false,
                                  );
                                  if (value == 'No') {
                                    setFieldValue(
                                      'leaReferralConsentDate',
                                      null,
                                    );
                                    setFieldValue('leaReferralReason', '');
                                    setEicsAndTbrData((prevValue) => ({
                                      ...prevValue,
                                      leaReferralConsentDate: null,
                                      leaReferralReason: '',
                                    }));
                                  }
                                }}
                                row={false}
                              />
                            </Grid>
                            {showdateOfConsentAndLEAOutsideReferralReason && (
                              <Grid item xs={4}>
                                <DatePickerComponent
                                  labelid="EICS.DateOfConsent"
                                  required={consentDatemandatory}
                                  disableFuture={false}
                                  defaultlabelid="Date of Transition Plan*"
                                  value={dayjs(values.leaReferralConsentDate)}
                                  handlechange={(dateValue: Dayjs | null) => {
                                    if (dateValue == null) {
                                      setFieldValue(
                                        'leaReferralConsentDate',
                                        null,
                                      );
                                      setEicsAndTbrData((prevValue) => ({
                                        ...prevValue,
                                        leaReferralConsentDate: null,
                                      }));
                                    } else {
                                      setFieldValue(
                                        'leaReferralConsentDate',
                                        dateValue.toDate(),
                                      );
                                      setEicsAndTbrData((prevValue) => ({
                                        ...prevValue,
                                        leaReferralConsentDate:
                                          dateValue.format('YYYY-MM-DD'),
                                      }));
                                    }

                                    if (dateValue) {
                                      if (!dateOfBirth) {
                                        throw new Error(
                                          'Date of birth is undefined',
                                        );
                                      }

                                      const dob = new Date(dateOfBirth);
                                      const transitionDate = new Date(
                                        dateValue.toDate(),
                                      );

                                      const dobYear = dob.getFullYear();
                                      const dobMonth = dob.getMonth();
                                      const transitionYear =
                                        transitionDate.getFullYear();
                                      const transitionMonth =
                                        transitionDate.getMonth();

                                      const yearDifferenceInMonths =
                                        (transitionYear - dobYear) * 12;
                                      const monthDifference =
                                        transitionMonth - dobMonth;
                                      const totalMonthDifference =
                                        yearDifferenceInMonths +
                                        monthDifference;
                                      const isWithinRange =
                                        totalMonthDifference >= 27 &&
                                        totalMonthDifference <= 33;
                                      setLeaReferralOutsideDropdown(
                                        !isWithinRange,
                                      );
                                    }
                                  }}
                                />
                                {errors.leaReferralConsentDate &&
                                touched.leaReferralConsentDate &&
                                consentDatemandatory ? (
                                  <Box className="loginError">
                                    <MediumTypography
                                      labelid={errors.leaReferralConsentDate}
                                      className="errorText-md"
                                    />
                                  </Box>
                                ) : null}
                              </Grid>
                            )}
                          </Grid>
                        )}

                        {consentProvidedForLEAMandatory && (
                          <Grid
                            container
                            rowSpacing={'24px'}
                            className="pt-md align__items__center"
                          >
                            {errors.leaReferralConsent &&
                            touched.leaReferralConsent ? (
                              <Box className="loginError">
                                <MediumTypography
                                  labelid={errors.leaReferralConsent}
                                  className="errorText-md"
                                />
                              </Box>
                            ) : null}
                          </Grid>
                        )}
                      </Box>
                      {consentProvidedForLEAMandatory &&
                        showdateOfConsentAndLEAOutsideReferralReason && (
                          <Box>
                            <Grid
                              container
                              rowSpacing={'24px'}
                              className="pt-xlg align__items__center"
                            >
                              <Grid item xs={8.3}>
                                <DropdownComponent
                                  name="leaReferralReason"
                                  Required={leaReferralOutsideDropdown}
                                  names={transformedArrayLEAReferralReason}
                                  value={values.leaReferralReason}
                                  defaultlabelid="Reason if LEA referral is outside of standard timeframe (27-33 mo)"
                                  labelid="EICS.LEADropDown"
                                  handleChange={(value: string) => {
                                    setFieldValue('leaReferralReason', value);
                                    setEicsAndTbrData((prevValue) => ({
                                      ...prevValue,
                                      leaReferralReason: value,
                                    }));
                                  }}
                                />
                                {errors.leaReferralReason &&
                                touched.leaReferralReason &&
                                leaReferralOutsideDropdown ? (
                                  <Box className="loginError">
                                    <MediumTypography
                                      labelid={errors.leaReferralReason}
                                      className="errorText-md"
                                    />
                                  </Box>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                    </Box>
                    <Box
                      className="horizontalLine"
                      sx={{ marginY: '12px' }}
                      display={'none'}
                    />
                    <Box display={'none'}>
                      <Box>
                        <MediumTypography
                          labelid="EICS.TPC"
                          defaultlabel={`Transition Planning Conference (TPC) - (For children not referred to the LEA - this can be the same date as the TPD date)`}
                          fontSize="16px"
                          fontweight={600}
                        />

                        <Grid
                          container
                          rowSpacing={'24px'}
                          className="pt-md align__items__center"
                        >
                          <Grid item xs={4}>
                            <DatePickerComponent
                              labelid="EICS.TransitionPlanConference"
                              disableFuture={false}
                              defaultlabelid="Date of Transition Planning Conference"
                              value={dayjs(values.tpcDate)}
                              handlechange={(dateValue: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(dateValue).format('YYYY-MM-DD');
                                if (dateValue == null) {
                                  setFieldValue('tpcDate', null);
                                  setEicsAndTbrData((prevValue) => ({
                                    ...prevValue,
                                    tpcDate: null,
                                  }));
                                } else {
                                  setFieldValue('tpcDate', formattedDate);
                                  setEicsAndTbrData((prevValue) => ({
                                    ...prevValue,
                                    tpcDate: formattedDate,
                                  }));
                                }
                              }}
                            />
                            {errors.tpcDate && touched.tpcDate && (
                              <Box className="loginError">
                                <MediumTypography
                                  labelid={errors.tpcDate}
                                  className="errorText-md"
                                />
                              </Box>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid
                          container
                          rowSpacing={'24px'}
                          className="pt-md align__items__center"
                        >
                          <Grid item xs={4}>
                            <DropdownComponent
                              name="noTPCReason"
                              names={transformedArrayTpcReason}
                              value={values.noTPCReason}
                              defaultlabelid="Reason No TPC occurred"
                              labelid="EICS.ReasonNoTPCOccoured"
                              handleChange={(value: string) => {
                                setFieldValue('noTPCReason', value);
                                setEicsAndTbrData((prevValue) => ({
                                  ...prevValue,
                                  noTPCReason: value,
                                }));
                              }}
                            />
                          </Grid>
                          <Grid item xs={0.3}></Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      className="horizontalLine"
                      sx={{ marginY: '12px', display: 'none' }}
                    />
                    <Box>
                      <Box>
                        <MediumTypography
                          labelid="EICS.DischargeInformation"
                          defaultlabel={`Discharge Information`}
                          fontSize="16px"
                          fontweight={600}
                        />
                      </Box>
                      <Box>
                        <Grid container rowSpacing={'24px'} className="pt-md">
                          <Grid item xs={4}>
                            <DatePickerComponent
                              labelid="EICS.DischargeDate"
                              disableFuture={false}
                              required={true}
                              defaultlabelid="Discharge date*"
                              value={
                                values.dischargeDate
                                  ? (dayjs(values.dischargeDate) as Dayjs)
                                  : null
                              }
                              handlechange={(dateValue: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(dateValue).format('YYYY-MM-DD');
                                if (dateValue == null) {
                                  setFieldValue('dischargeDate', null);
                                } else {
                                  setFieldValue('dischargeDate', formattedDate);
                                }
                              }}
                              disabledDate={
                                !!(
                                  tcsFlag ||
                                  values.signature.signature ||
                                  eicsAndTbrData.signature.rowKey
                                )
                              }
                            />
                            {errors.dischargeDate && touched.dischargeDate && (
                              <Box className="loginError">
                                <MediumTypography
                                  labelid={errors.dischargeDate}
                                  className="errorText-md"
                                />
                              </Box>
                            )}
                          </Grid>
                          <Grid item xs={0.3}></Grid>
                          <Grid item xs={4}>
                            <DropdownComponent
                              name="dischargeReason"
                              names={transformedArrayDischareReason}
                              value={values.dischargeReason}
                              Required
                              defaultlabelid="Dischare Reason"
                              labelid="EICS.DischargeReason"
                              handleChange={(value: string) => {
                                setFieldValue('dischargeReason', value);
                              }}
                              disabled={
                                !!(
                                  tcsFlag ||
                                  values.signature.signature ||
                                  eicsAndTbrData.signature.rowKey
                                )
                              }
                            />
                            {errors.dischargeReason &&
                              touched.dischargeReason && (
                                <Box className="loginError">
                                  <MediumTypography
                                    labelid={errors.dischargeReason}
                                    className="errorText-md"
                                  />
                                </Box>
                              )}
                          </Grid>
                          <Grid item xs={0.3}></Grid>
                          <Grid item xs={1}>
                            <Box className="flex__ alignItemCenter pt-xs">
                              <MediumTypography
                                onClick={() => navigation(EICS_TBR)}
                                labelid={'EICS'}
                                defaultlabel="EICS"
                                sxProps={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#008C82',
                                  cursor: 'pointer',
                                }}
                              />
                              <GoTo className="ml-xs" />
                            </Box>
                          </Grid>
                          <Grid item xs={4}>
                            <CheckBoxComponent
                              ischecked={values.includeDischargeInfoInNextEMR}
                              labelid="includeDischargeInfoInNextEMRText"
                              defaultlabelid="Include discharge information in next EMR"
                              CheckHandleChange={(
                                event: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setFieldValue(
                                  'includeDischargeInfoInNextEMR',
                                  event.target.checked,
                                );
                              }}
                              disabled={
                                tcsFlag ||
                                values.signature.signature ||
                                eicsAndTbrData.signature.rowKey
                                  ? true
                                  : false
                              }
                            />
                          </Grid>
                          {tcsFlag &&
                            values.signature.rowKey === '' &&
                            values.signature.signature === '' && (
                              <Grid item xs={12}>
                                <MediumTypography
                                  label="*Client is Discharged in TBR"
                                  fontSize="14px"
                                />
                              </Grid>
                            )}
                        </Grid>
                      </Box>
                    </Box>

                    <Box>
                      {eicsAndTbrData.signature &&
                        (eicsAndTbrData.signature.rowKey !== '' ||
                          eicsAndTbrData.signature.signature) && (
                          <>
                            <ButtonComponent
                              className="btn-primary btn-cancel signature_view_Box"
                              labelId={'ProgressNote.ViewUpdateSignature'}
                              defaultLabelId={'View/update signature'}
                              variantType="outlined"
                              LeftIcon={<Editsignature />}
                              onClick={() => {
                                setSignClicked(true);
                              }}
                            />

                            {eicsAndTbrData &&
                              eicsAndTbrData.signature.name && (
                                <Box className="flex__ mt-lg">
                                  <MediumTypography
                                    label={`Approved by ${
                                      eicsAndTbrData.signature.name
                                    } on ${dayjs(
                                      eicsAndTbrData.signature.date,
                                    ).format('MM/DD/YYYY')}`}
                                  />
                                </Box>
                              )}
                          </>
                        )}
                    </Box>
                  </Card>

                  {/* Signature pad */}
                  {signClicked && (
                    <SignatureComponent
                      signClicked={signClicked}
                      labelId="signatureText"
                      defaultLabelId="Signature"
                      onCancelClick={onCancelClick}
                      signature={formRef.current?.values.signature.signature}
                      signaturePartitionKey={
                        formRef.current?.values.signature.partitionKey ?? ''
                      }
                      signatureRowKey={
                        formRef.current?.values.signature.rowKey ?? ''
                      }
                      signatureSaveOrUPdate={onSignatureSave}
                      resetCallBackForTypeEmployee={clearSignature}
                      signatureCallBack={signatureCallBack}
                      handleSuccess={handleSuccess}
                      type="Employee"
                      hideRemoveButton={true}
                    />
                  )}

                  {/* {eicsAndTbrData.allowApprove && ( */}
                  {!tcsFlag && (
                    <Box
                      component="div"
                      className="flex__ mt-md mb-md"
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {eicsAndTbrData.dischargeDate &&
                      eicsAndTbrData.dischargeReason ? (
                        <Box>
                          <ButtonComponent
                            className="btn-primary btn-submit"
                            labelId={'EICS.ApprovalBtn'}
                            defaultLabelId="Approve"
                            variantType="contained"
                            onClick={() => {
                              setApproveModal(true);
                            }}
                            disabled={
                              !(
                                eicsAndTbrData.allowApprove &&
                                values.signature.rowKey === '' &&
                                values.signature.signature === '' &&
                                values.dischargeApproved === false
                              )
                            }
                          />
                        </Box>
                      ) : (
                        <Box></Box>
                      )}

                      <Box
                        className="flex__"
                        style={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <ButtonComponent
                          className="btn-primary btn-cancel"
                          labelId={'Cancel'}
                          defaultLabelId="Cancel"
                          variantType="outlined"
                          onClick={() => {
                            if (
                              !_.isEqual(
                                eicsAndTbrData,
                                formRef.current?.values,
                              )
                            ) {
                              setCancelModal(true);
                            }
                          }}
                          disabled={values.signature.rowKey ? true : false}
                        />
                        <ButtonComponent
                          className="btn-primary btn-submit ml-md"
                          labelId="ConsentSave"
                          defaultLabelId="Save"
                          variantType="contained"
                          onClick={handleSubmit}
                          disabled={values.signature.rowKey ? true : false}
                        />
                      </Box>
                    </Box>
                  )}
                  {/* )} */}
                </Box>
                {cancelModal && (
                  <ModalPopup
                    open={cancelModal}
                    onCancel={() => {
                      setCancelModal(false);
                    }}
                    description="formUnsavedChangesMessage"
                    onOk={() => {
                      getTransitiondataFunction();
                      setCancelModal(false);
                    }}
                    labelId1="Clientpage.cancelbtn"
                    negativeActionLabel="cancelText"
                    labelId2="Clientpage.Okbtn"
                    positiveActionLabel="deleteText"
                  />
                )}
                {approveModal && (
                  <ModalPopup
                    open={approveModal}
                    onCancel={() => {
                      setApproveModal(false);
                    }}
                    description="EICS.ApprovalConfirmation"
                    onOk={() => {
                      setApproveModal(false);
                      setSignClicked(true);
                    }}
                    labelId1="Clientpage.Nobtn"
                    negativeActionLabel="No"
                    labelId2="Clientpage.Yesbtn"
                    positiveActionLabel="Yes"
                  />
                )}
              </div>
            </Box>
          </>
        )}
      </Formik>
    </>
  );
}

export default EICSTBRTransitionAndDischargeInformation;
