import { Box, TableCell, TableRow } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC } from 'react';
import { ReactComponent as AcceptIcon } from '../../assets/images/acceptIcon.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/rejectIcon.svg';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface EmployeeListData {
  employeeId: string;
  employeeName: string;
  dateOfSubmission: string;
  totalAmount: string;
  status: string;
  travelId: string;
  expenseDate: string;
  milesAmt: string;
  travelMileageAmt: string;
  officeSuppliesAmt: string;
  fazzioDirectCareSuppliesAmt: string;
  duesAndSubscriptionAmt: string;
  postageAmt: string;
  printingOrDuplicatingAmt: string;
  continuingEdAmt: string;
  telephoneAmt: string;
  fazzioFoodAmt: string;
  stipendAmt: string;
  fazzioMileageAmt: string;
  otherAmt: string;
  isSelected: boolean;
}

interface EmployeeProps {
  data: EmployeeListData;
  index: number;
  showSubmitButton?: boolean;
  onViewClick: () => void;
  onSelectTravelExpense: () => void;
  onRejectTravelExpense: () => void;
  onApproveTravelExpense: () => void;
}

const ReviewTravelExpenseEmployeeTable: FC<EmployeeProps> = ({
  data,
  index,
  showSubmitButton,
  onViewClick,
  onSelectTravelExpense,
  onRejectTravelExpense,
  onApproveTravelExpense,
}) => {
  return (
    <TableRow
      hover
      tabIndex={-1}
      sx={{
        cursor: 'pointer',
        backgroundColor: index % 2 === 0 ? '#ECF9F8' : '#ffffff',
      }}
    >
      {!showSubmitButton && (
        <TableCell
          sx={{
            position: 'sticky',
            left: 0,
            background: index % 2 === 0 ? '#ECF9F8' : '#ffffff',
          }}
        >
          <CustomCheckBox
            disable={
              !checkPermissionForFeature(
                'backend.review_travel_expense',
                'editPermission',
              )
            }
            value={data.isSelected}
            onCheckBoxClick={() => {
              onSelectTravelExpense();
            }}
          />
        </TableCell>
      )}
      <TableCell
        sx={{
          position: 'sticky',
          left: showSubmitButton ? 0 : '40px',
          background: index % 2 === 0 ? '#ECF9F8' : '#ffffff',
        }}
      >
        <MediumTypography
          className="cursor__pointer"
          onClick={onViewClick}
          sxProps={{ width: 'max-content' }}
          textColor="#008C82"
          label={
            data.employeeName !== '' || data.employeeName !== null
              ? data.employeeName
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.milesAmt !== '' || data.milesAmt !== null ? data.milesAmt : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.travelMileageAmt !== '' || data.travelMileageAmt !== null
              ? data.travelMileageAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.officeSuppliesAmt !== '' || data.officeSuppliesAmt !== null
              ? data.officeSuppliesAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.fazzioDirectCareSuppliesAmt !== '' ||
            data.fazzioDirectCareSuppliesAmt !== null
              ? data.fazzioDirectCareSuppliesAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.duesAndSubscriptionAmt !== '' ||
            data.duesAndSubscriptionAmt !== null
              ? data.duesAndSubscriptionAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.postageAmt !== '' || data.postageAmt !== null
              ? data.postageAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.printingOrDuplicatingAmt !== '' ||
            data.printingOrDuplicatingAmt !== null
              ? data.printingOrDuplicatingAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.continuingEdAmt !== '' || data.continuingEdAmt !== null
              ? data.continuingEdAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.telephoneAmt !== '' || data.telephoneAmt !== null
              ? data.telephoneAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.fazzioFoodAmt !== '' || data.fazzioFoodAmt !== null
              ? data.fazzioFoodAmt
              : '-'
          }
        />
      </TableCell>

      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.stipendAmt !== '' || data.stipendAmt !== null
              ? data.stipendAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.fazzioMileageAmt !== '' || data.fazzioMileageAmt !== null
              ? data.fazzioMileageAmt
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.otherAmt !== '' || data.otherAmt !== null
              ? `${data.otherAmt}`
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={
            data.totalAmount !== '' || data.totalAmount !== null
              ? `$${data.totalAmount}`
              : '-'
          }
        />
      </TableCell>
      <TableCell>
        <MediumTypography
          sxProps={{ width: 'max-content' }}
          label={data.status !== '' || data.status !== null ? data.status : '-'}
          textColor={
            data.status === 'Approved'
              ? '#37D183'
              : data.status === 'Pending'
              ? '#F9B803'
              : '#EB5757'
          }
        />
      </TableCell>
      {!showSubmitButton && (
        <TableCell>
          <Box className="flex__ btn_width_90px" gap={1.5}>
            <ActionToolTip labelId="approve" defaultLabel="Approve">
              <AcceptIcon
                onClick={() => {
                  if (
                    checkPermissionForFeature(
                      'backend.review_travel_expense',
                      'editPermission',
                    )
                  ) {
                    onApproveTravelExpense();
                  }
                }}
                className={
                  checkPermissionForFeature(
                    'backend.review_travel_expense',
                    'editPermission',
                  )
                    ? 'cursor__pointer'
                    : 'cursor__disabled'
                }
              />
            </ActionToolTip>
            <ActionToolTip labelId="reject" defaultLabel="Reject">
              <RejectIcon
                onClick={() => {
                  if (
                    checkPermissionForFeature(
                      'backend.review_travel_expense',
                      'editPermission',
                    )
                  ) {
                    onRejectTravelExpense();
                  }
                }}
                className={
                  checkPermissionForFeature(
                    'backend.review_travel_expense',
                    'editPermission',
                  )
                    ? 'cursor__pointer'
                    : 'cursor__disabled'
                }
              />
            </ActionToolTip>
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};
export default ReviewTravelExpenseEmployeeTable;
