import { ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export interface Tag {
  id?: string;
  type: string | null;
  clientId: string;
  userId: string;
  origin: string | null;
  timestamp: string;
  description: string | null;
  notes: string | null;
  program: string;
  clientName: string;
}

export interface ClientFile {
  name: string;
  url: string;
  tags: Tag;
}

export interface ClientFilesResponse {
  list: ClientFile[];
  continuationToken: string | null;
}

export const getClientFiles = async (
  clientId: string,
): Promise<ClientFilesResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ClientFilesResponse>(
      `blob/list/clientFiles/client/${clientId}/page/100`,
    ),
  );
};

export const uploadFile = async (file: File, tags: Tag): Promise<string> => {
  const formData = new FormData();

  formData.append('file', file, file.name.toLowerCase());
  formData.append('request', JSON.stringify(tags));

  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      'blob/import?folderName=clientFiles',
      formData,
    ),
  );
};

export const deleteFileById = async (fileId: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(`blob/delete/${fileId}`),
  );
};

export const downloadFile = async (filePath: string): Promise<Blob> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Blob>(`blob/import/${filePath}`, {
      responseType: 'blob',
    }),
  );
};
