import { Program } from '../../../../pages/eligibility/types';
import { ParentInfoType } from '../../../../pages/employees/types';
import { TotalResponse } from '../../../../pages/questionnaire/QuestionnaireClientInfo';
import { ApiMessage, ApiResponseHandler } from '../../../ApiResponseHandler';
import ThomApiClient from '../../../ThomApiClient';
import { InatkeParentType } from '../Intake/IntakeServices';

export type Asqse2ListType = {
  id: string;
  age: number;
  dateOfAsqse2: string;
};

export type Asqse2CreateResponseType = {
  id: string;
};

export interface QuestionnaireClientInfoFormData {
  id?: string;
  age?: number;
  clientInfo?: {
    firstName: string;
    lastName: string;
    sex: string;
    dphId: string;
    dob: string | null;
  };
  defaultParent: string;
  parentDescription: string;
  prematureWeeks: string;
  asqCompletion: string | null;
  prematureMonths: string | null;
  prematureDays: string | null;
  ageAtAdministration: string | null;
  parentInfo?: ParentInfoType[];
  selectedParentInfo?: ParentInfoType;
  programDetails?: Program;
  parentContacts?: InatkeParentType[];
  otherName: string;
}

export const getAsqse2List = async (): Promise<Asqse2ListType[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Asqse2ListType[]>('asqse2/all'),
  );
};
export const getAsqse2 = async (): Promise<TotalResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<TotalResponse>(`asqse2/default-data`),
  );
};

export const getAsqse2ById = async (
  asqse2Id: string,
): Promise<TotalResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<TotalResponse>(`asqse2/${asqse2Id}`),
  );
};

export const updateAsqse2 = async (
  asqse2Id: string,
  params: TotalResponse,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<TotalResponse, ApiMessage>(
      `asqse2/${asqse2Id}`,
      params,
    ),
  );
};

export const createAsqse2 = async (
  params: TotalResponse,
): Promise<Asqse2CreateResponseType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<
      TotalResponse,
      Asqse2CreateResponseType
    >(`asqse2`, params),
  );
};

export const deleteAsqse2 = async (asqse2Id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(`asqse2/${asqse2Id}`),
  );
};
