import React from 'react';
import { Box, styled } from '@mui/system';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import TitleText from '../../components/formlib/TitleText';
import { useNavigate } from 'react-router';
import { CLIENTS_ROUTE_NAME } from '../../routes/Routing';
import { InsuranceType } from '../../utils/type';
import { Badge, Typography } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import SmallTypography from '../../components/formlib/SmallTypography';
import { Tooltip } from '@mui/material';

interface InsuranceJSONType {
  data: InsuranceType[];
}

const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color ?? '#37D183',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
    marginRight: '10px',
  },
}));

const InsuranceFaceSheet: React.FC<InsuranceJSONType> = (props) => {
  const navigation = useNavigate();

  const badgePicker = (status: string) => {
    switch (status) {
      case 'Inactive':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'red',
              },
            }}
          />
        );
      case 'Active':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#37D183',
                mb: 5,
              },
            }}
          />
        );
      default:
        return <CustomBadge variant="dot" />;
    }
  };

  return (
    <>
      <Box>
        <Box
          sx={{
            float: 'right',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <ArrowRight
            onClick={() => {
              sessionStorage.setItem('tabName', '3');
              const clientId = localStorage.getItem('ClientId');
              navigation(CLIENTS_ROUTE_NAME, {
                state: { clientId: clientId, isCreate: true },
              });
            }}
          />
        </Box>
        <TitleText
          labelid="Facesheet.insurance"
          defaultlabel="Insurance"
          Sxprops={{ fontSize: '18px' }}
        />

        <Box
          sx={{
            overflowY: 'scroll',
            height: '200px',
            '&::-webkit-scrollbar': {
              width: '5px',
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '--thom-mono-gray',
              borderRadius: '4px',
              border: '0.5px solid (--thom-mono-gray)',
            },
          }}
        >
          {props.data.length === 0 && (
            <SmallTypography
              sxProps={{
                fontSize: '18px',
                color: '#97A6A5',
                textAlign: 'center',
                marginY: 8,
              }}
              labelId="Insurance.nodataValidation"
              defaultLabelId="No Insurance added yet"
            />
          )}

          {props.data.map((insuranceProps: InsuranceType) => {
            return (
              <Box
                sx={{ marginY: '16px' }}
                key={insuranceProps.insuranceDetailsId}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ margin: 0 }}>
                    <SmallTypography
                      labelId={insuranceProps.coverageType2}
                      sxProps={{
                        fontSize: '10px',
                        color: '#97A6A5',
                        margin: 0,
                      }}
                    />
                    <Box sx={{ width: '200px' }}>
                      <Tooltip
                        title={
                          insuranceProps.dph3rdPartyPayer === 'OTHER'
                            ? `${insuranceProps.dph3rdPartyPayer || ''}${
                                insuranceProps.insuranceCompany?.trim()
                                  ? ` - ${insuranceProps.insuranceCompany}`
                                  : ''
                              }`
                            : `${insuranceProps.dph3rdPartyPayer || ''}${
                                insuranceProps.dph3rdPartyPayerName?.trim()
                                  ? ` - ${insuranceProps.dph3rdPartyPayerName}`
                                  : ''
                              }`
                        }
                        followCursor
                        arrow={true}
                        sx={{ maxWidth: 'min-content' }}
                      >
                        <Box>
                          <MediumTypography
                            label={
                              insuranceProps.dph3rdPartyPayer === 'OTHER'
                                ? `${insuranceProps.dph3rdPartyPayer || ''}${
                                    insuranceProps.insuranceCompany?.trim()
                                      ? ` - ${insuranceProps.insuranceCompany}`
                                      : ''
                                  }`
                                : `${insuranceProps.dph3rdPartyPayer || ''}${
                                    insuranceProps.dph3rdPartyPayerName?.trim()
                                      ? ` - ${insuranceProps.dph3rdPartyPayerName}`
                                      : ''
                                  }`
                            }
                            sxProps={{
                              fontSize: '16px',
                              color: '#008C82',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <MediumTypography
                        labelid={'Facesheet.subscriber'}
                        defaultlabel={'Subscriber:'}
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '12px',
                          fontWeight: 500,
                        }}
                        className="mr-xs"
                      />
                      <Box sx={{ width: '150px' }}>
                        <Tooltip
                          title={
                            insuranceProps.subscriberName.length > 5
                              ? insuranceProps.subscriberName
                              : ''
                          }
                          followCursor
                          arrow={true}
                          sx={{ maxWidth: 'min-content' }}
                        >
                          <Box>
                            <MediumTypography
                              label={insuranceProps.subscriberName}
                              textColor="#2A4241"
                              fontweight={500}
                              fontSize="12px"
                              sxProps={{
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                              }}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      marginLeft: 2,
                      display: 'flex',
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontWeight: 500,
                        fontFamily: 'Lato-Regular',
                        lineHeight: '32px',
                      }}
                    >
                      {insuranceProps.eligibilityStatus &&
                        badgePicker(insuranceProps.eligibilityStatus)}
                    </Typography>
                    <SmallTypography
                      text="Not verified"
                      sxProps={{ marginX: '2px', marginY: '8px' }}
                    />
                  </Box>
                </Box>
                {props.data.length - 1 !==
                  props.data.indexOf(insuranceProps) && (
                  <Box className="horizontalLine" sx={{ marginY: '10px' }} />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default InsuranceFaceSheet;
