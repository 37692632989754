import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ProfileInfo from './ProfileInfo';
import ProfileBussinesHours from './ProfileBussinesHours';
import TitleText from '../../components/formlib/TitleText';

export default function ProfileScreen() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        width: '100%',
        padding: '24px',
        background: '#E7F0F0',
      }}
    >
      <Grid container spacing={2}>
        <Grid item md={12}>
          <TitleText labelid="myProfileText" defaultlabel="My Profile" />
        </Grid>

        <Grid item md={3.5}>
          <ProfileInfo />
        </Grid>
        <Grid item md={8.5}>
          <ProfileBussinesHours />
        </Grid>
      </Grid>
      <Grid container spacing={2}></Grid>
    </Box>
  );
}
