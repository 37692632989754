import dayjs, { Dayjs } from 'dayjs';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';
import { STORAGE_USERNAME_KEY } from '../../Constant';
import { getCurrentWeekSundayAsDate } from '../../../utils/utilities';

export type Task = {
  id?: string;
  name: string;
  description: string;
  assignedToIds?: string[];
  createdBy: string | null;
  assignedToId?: string | string[];
  status: string;
  taskType: string;
  dueDate: string | Dayjs | null;
  type: string;
  userType: string;
  employeeId: string | null;
  clientId: string | null;
};

export type TaskResponse = {
  id: string;
  name: string;
  description: string;
  status: string;
  taskType: string;
  type: string;
  dueDate: string | null;
  userType: string;
  origin: string;
  originId: string | null;
  createdOn: string;
  assignedToId: string;
  clientId: string | null;
  clientName: string | null;
  functionalityId: string | null;
  leaveType: string | null;
  leaveReason: string | null;
};

export type TaskListResponse = {
  openStatusCount: number;
  dueStatusCount: number;
  warningStatusCount: number;
  overdueStatusCount: number;
  completedStatusCount: number;
  totalTasksForTheQuery: number;
  totalTasks: number;
  tasks: TaskResponse[];
};

type TimeOffStatus = {
  status: string;
  taskId: string;
  description: string;
};

export const getTaskListAPI = async (
  page: number,
  size: number,
  sort: string,
  sortType: string,
  searchTerm: string,
  filterByStatus: string,
  filterByPeriodStart: string,
  filterByPeriodEnd: string,
  assignedToId: string,
  allTime: boolean,
  filter: string,
): Promise<TaskListResponse> => {
  const timezone = encodeURIComponent(
    dayjs(getCurrentWeekSundayAsDate()).format('Z'),
  );
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<TaskListResponse>(
      `/task?page=${page}&size=${size}&sort=${sort}&sortType=${sortType}&searchTerm=${searchTerm}&filterByStatus=${filterByStatus}&filterByPeriodStart=${filterByPeriodStart}&filterByPeriodEnd=${filterByPeriodEnd}&assignedTo=${assignedToId}&allTime=${allTime}&filter=${filter}&timezone=${timezone}`,
    ),
  );
};

export const addTask = async (requestBody: Task): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<Task, ApiMessage>(
      `task`,
      requestBody,
    ),
  );
};

export const updateTask = async (requestBody: Task): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<Task, ApiMessage>(
      `task`,
      requestBody,
    ),
  );
};

export const markAsCompleted = async (id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<null, ApiMessage>(
      `/task/updateStatus?taskId=${id}`,
      null,
    ),
  );
};

export const acceptOrRejectLeaveApi = async (
  taskResponse: TaskResponse,
  status: string,
  dateValue: string,
): Promise<ApiMessage> => {
  const user = localStorage.getItem(STORAGE_USERNAME_KEY);
  const descriptionData = taskResponse.description
    .concat('\n')
    .concat(`The Time off request is ${status} by ${user} on ${dateValue}`);
  const statusRequest = {
    status: status,
    taskId: taskResponse.id,
    description: descriptionData,
  };
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<TimeOffStatus, ApiMessage>(
      `/task/timeOffStatusUpdate`,
      statusRequest,
    ),
  );
};

export const deleteTaskId = async (id: string): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(`task?id=${id}`),
  );
};
