import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';
import { LOCAL_TIME_24_HOUR_FORMAT } from '../services/Constant';

export interface TimeDuration {
  hour: number;
  minutes: number;
}

export const formatDate = (newDate: Date, format: string) =>
  dayjs(newDate).format(format);

export const formatStringTime = (time: string) => {
  const timeArray = time.split(':');
  return parseInt(timeArray[0], 10) * 60 * 60 + parseInt(timeArray[1], 10) * 60;
};

export const formatTime = (time: number): string => {
  const h = Math.floor(time / 3600)
    .toString()
    .padStart(2, '0');
  const m = Math.floor((time % 3600) / 60)
    .toString()
    .padStart(2, '0');
  return `${h}:${m}`;
};

export const getTimeString = (
  dateTime: string,
  format: string,
  utc: boolean,
): string => {
  if (dateTime.length === 0) {
    return '';
  }
  return moment(dateTime).utc(utc).local().format(format);
};

export const chronologicalAgeLogic = (
  DOB: Date | null | undefined,
  DOA: Date | null | undefined,
) => {
  let cronAge = '';
  let totalMonths: number = 0;
  let days: number = 0;
  //Get the start date
  if (DOB && DOA) {
    const startDate = new Date(DOB);

    // Get the current date
    const currentDate = new Date(DOA);

    if (startDate <= currentDate) {
      let years = currentDate.getFullYear() - startDate.getFullYear();
      let months = currentDate.getMonth() - startDate.getMonth();
      days = currentDate.getDate() - startDate.getDate();

      if (days < 0) {
        months--;
        const tempDate = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() - 1,
          startDate.getDate(),
        );
        const lastMonthDate = new Date(
          tempDate.getFullYear(),
          tempDate.getMonth() + 1,
          0,
        ).getDate();
        days += lastMonthDate;
      }

      // Check if the current month is earlier than the birth month
      if (months < 0) {
        years--;
        months += 12;
      }

      totalMonths = years * 12 + months;

      cronAge = `${years}yr(s) ${months}m ${days}d (${totalMonths} Month(s))`;
    } else if (
      startDate > currentDate ||
      (startDate > currentDate && currentDate)
    ) {
      cronAge = '';
    }
  } else {
    cronAge = '';
  }
  return { cronAge, totalMonths, days };
};
export const checkTimeExceeding = (time: string, maxTime: string) => {
  const [hours, minutes] = maxTime.split(':');
  const maxTimeInSeconds = +hours * 60 * 60 + +minutes * 60;

  const [newHours, newMinutes] = time.split(':');
  const timeInSeconds = +newHours * 60 * 60 + +newMinutes * 60;
  return maxTimeInSeconds < timeInSeconds;
};

export const getFinancialYearJulyStartDate = (): Date => {
  const currentMonth = new Date().getMonth() + 1;
  if (currentMonth >= 7) {
    const previousYear = new Date().getFullYear();
    return new Date(previousYear, 6, 1);
  } else {
    const previousYear = new Date().getFullYear() - 1;
    return new Date(previousYear, 6, 1);
  }
};

export const getFinancialYear = (): string => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const previousYear = currentYear - 1;
  const currentYearStartDate = new Date(currentYear, 6, 1);
  const currentYearEndDate = new Date(currentYear, 5, 30);
  const nextYearEndDate = new Date(currentYear + 1, 5, 30);

  if (currentMonth >= 7) {
    return `${dayjs(currentYearStartDate).format('MMM DD, YYYY')} - ${dayjs(
      nextYearEndDate,
    ).format('MMM DD, YYYY')}`;
  } else {
    const previousYearStartDate = new Date(previousYear, 6, 1);
    return `${dayjs(previousYearStartDate).format('MMM DD, YYYY')} - ${dayjs(
      currentYearEndDate,
    ).format('MMM DD, YYYY')}`;
  }
};

// export const getFinancialYearJuneEndDate = (): Date => {
//   const currentMonth = new Date().getMonth() + 1;
//   if (currentMonth >= 7) {
//     const currentDate = new Date();
//     const juneLast = new Date(currentDate.getFullYear() + 1, 6, 1);
//     juneLast.setDate(juneLast.getDate() - 1);
//     return juneLast;
//   } else {
//     const currentDate = new Date();
//     const juneLast = new Date(currentDate.getFullYear(), 6, 1);
//     juneLast.setDate(juneLast.getDate() - 1);
//     return juneLast;
//   }
// };

export const getFinancialYearJuneEndDate = (): dayjs.Dayjs => {
  const currentMonth = dayjs().month() + 1;
  if (currentMonth >= 7) {
    const juneLast = dayjs().add(1, 'year').month(5).endOf('month');
    return juneLast;
  } else {
    const juneLast = dayjs().month(5).endOf('month');
    return juneLast;
  }
};

export const calculateMonths = (dob: string) => {
  const dateOfBirth = new Date(dob);
  const currentDate = new Date();

  let yearDiff = currentDate.getFullYear() - dateOfBirth.getFullYear();
  let monthDiff = currentDate.getMonth() - dateOfBirth.getMonth();
  let dayDiff = currentDate.getDate() - dateOfBirth.getDate();

  // Adjust negative day difference
  if (dayDiff < 0) {
    const lastMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      0,
    );
    dayDiff += lastMonth.getDate();
    monthDiff--;
  }

  // Adjust negative month difference
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }

  const totalMonths = yearDiff * 12 + monthDiff;

  return `${totalMonths} M (${dayDiff} Days)`;
};

export const formatTimeDuration = (duration: TimeDuration) => {
  return `${duration.hour.toString().padStart(2, '0')}:${duration.minutes
    .toString()
    .padStart(2, '0')}`;
};

export const formatTimeDurationInDecimals = (duration: TimeDuration) => {
  return `${duration.hour.toString()}.${duration.minutes
    .toString()
    .padStart(2, '0')}`;
};

export const formatStringDate = (time: string | null): TimeDuration => {
  if (time === null || time.length === 0) {
    return {
      hour: 0,
      minutes: 0,
    };
  }
  return {
    hour: Number(time.split(':')[0]),
    minutes: Number(time.split(':')[1]),
  };
};
export const formatStringDateInDecimals = (
  time: string | null,
): TimeDuration => {
  if (time === null || time.length === 0) {
    return {
      hour: 0,
      minutes: 0,
    };
  } else if (time.includes('.')) {
    return {
      hour: Number(time.split('.')[0]),
      minutes: Number(time.split('.')[1]),
    };
  } else {
    return {
      hour: Number(time.split(':')[0]),
      minutes: Number(time.split(':')[1]),
    };
  }
};

export const convertTimeDuration = (
  time: string | null,
  isDecimal: boolean,
) => {
  if (time === null) {
    return isDecimal ? '0.00' : '00:00';
  } else {
    if (isDecimal) {
      const hours = parseInt(time.split(':')[0], 10)
        .toString()
        .padStart(2, '0');
      const minutes = time.split(':')[1];
      let minutesConverted = '00';
      if (minutes === '00') {
        minutesConverted = '00';
      } else if (minutes === '15') {
        minutesConverted = '25';
      } else if (minutes === '30') {
        minutesConverted = '50';
      } else if (minutes === '45') {
        minutesConverted = '75';
      }
      return `${hours}.${minutesConverted}`;
    } else {
      const hours = time.split('.')[0];
      const minutes = time.split('.')[1];
      let minutesConverted = '00';
      if (minutes === '00') {
        minutesConverted = '00';
      } else if (minutes === '25') {
        minutesConverted = '15';
      } else if (minutes === '50') {
        minutesConverted = '30';
      } else if (minutes === '75') {
        minutesConverted = '45';
      }

      return `${hours.padStart(2, '0')}:${minutesConverted}`;
    }
  }
};

// export const threeYearsAgo = () => {
//   const currentDate = new Date();
//   const threeYears = new Date(currentDate);
//   threeYears.setFullYear(currentDate.getFullYear() - 3);
//   return threeYears;
// };

export const threeYearsAgo = () => {
  const currentDate = dayjs();
  const threeYearsAgoDate = currentDate.subtract(3, 'year');
  return threeYearsAgoDate;
};

export const getTime = (time: string | null, defaultValue: string) => {
  if (time === null) {
    return dayjs(defaultValue, LOCAL_TIME_24_HOUR_FORMAT);
  }
  return dayjs(time);
};

export const datePickerMinDateMaxDateValidate = (
  value: Dayjs | null | string | Date | undefined,
) => {
  const pickerMin = '01/01/1900';
  const pickerMax = '12/31/2099';
  if (dayjs(value).isBefore(pickerMin) || dayjs(value).isAfter(pickerMax)) {
    return true;
  }
};
