import { ChangeEvent, FC, HTMLInputTypeAttribute, useRef } from 'react';
import UploadButton from './UploadButton';
import { ReactComponent as DocIcon } from '../../assets/images/decIcon.svg';

interface BrowseFileProp {
  accept: string;
  handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
  fileType: HTMLInputTypeAttribute;
  buttonLabelId: string;
}

const BrowseFiles: FC<BrowseFileProp> = ({
  accept,
  handleFileChange,
  fileType,
  buttonLabelId,
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);

  const browseFiles = () => {
    if (accept.length > 0) {
      inputFileRef.current?.click();
    }
  };

  return (
    <div>
      <input
        ref={inputFileRef}
        type={fileType}
        multiple
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <UploadButton
        className="browseFile"
        title={buttonLabelId}
        Icon={<DocIcon />}
        onClick={browseFiles}
      />
    </div>
  );
};

export default BrowseFiles;
