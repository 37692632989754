import {
  // Card,
  Dialog,
  DialogContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { FormDataResponse, FormList, ResponseList } from '../../utils/type';
import { ReactComponent as CancelIcon } from '../../assets/images/x.svg';
import AppPagination from '../../components/shared/AppPagination';
// import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
// import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

interface ButtonProps {
  modalVisible: boolean;
  setModalVisible: () => void;
  selectedRows?: (number | string)[];
  responseList?: ResponseList[];
  handleMainCheckboxChange?: () => void;
  formList: FormList[];
  selectedFormData: FormDataResponse[];
  handleCheckboxChange: (
    id: string | number,
    name: string,
    url: string,
  ) => void;
  addButton: (id: string | number, name: string, templateUrl: string) => void;
  count: number;
  page: number;
  handleChangePage: (event: unknown, newPage: number) => void;
  active: boolean;
  direction: 'asc' | 'desc' | undefined;
  handleSort: (id: string) => void;
}

const IntakeQuestionnaireRenderTableModal: React.FC<ButtonProps> = (props) => {
  const {
    modalVisible,
    setModalVisible,
    selectedRows,
    // handleMainCheckboxChange,
    formList,
    // selectedFormData,
    // handleCheckboxChange,
    addButton,
    responseList,
    count,
    page,
    handleChangePage,
    active,
    direction,
    handleSort,
  } = props;

  return (
    <Box component="div">
      <Dialog
        open={modalVisible}
        keepMounted
        onClose={() => setModalVisible()}
        fullWidth
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className="p-md pb-xs">
          <Box className="rowContainer" sx={{ height: '30px' }}>
            <Grid
              className="flex__ justifyContent-FlexEnd cursor__pointer"
              container
              direction="row"
              alignItems="right"
            >
              <Grid item xs={6} lg={6}>
                <Box className="flex__ pl-none">
                  <MediumTypography
                    labelid={'Intake.AddForms'}
                    defaultlabel="Add Form"
                    fontweight={600}
                    textColor="#2a4241"
                    fontSize="16px"
                  />
                </Box>
              </Grid>
              <Grid item xs={6} lg={6} className="text-align-end">
                <CancelIcon onClick={() => setModalVisible()} />
              </Grid>
              {/* <Grid item xs={6} lg={6}>
                {selectedRows && selectedRows.length > 1 && (
                  <Box
                    component="div"
                    className="flex__ text-align-end justifyContent-FlexEnd"
                  >
                    <ButtonComponent
                      className="btn-primary btn-submit mr-md"
                      variantType="contained"
                      labelId="Intake.AddForms"
                      defaultLabelId="Add Forms"
                      onClick={() => {
                        setModalVisible();
                      }}
                    />
                  </Box>
                )}
              </Grid> */}
            </Grid>
          </Box>

          {/* <Card> */}
          <TableContainer>
            <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
              <TableHead className="listDataTableHead">
                <TableRow>
                  {/* code is commented because further clarity on checkbox functionality is required  */}
                  {/* <TableCell>
                   
                    <CheckBoxComponent
                      ischecked={
                        selectedRows && selectedRows.length === formList.length
                      }
                      CheckHandleChange={handleMainCheckboxChange}
                    />
                    Select All
                  </TableCell> */}
                  <TableCell>
                    <TableSortLabel
                      className="Tableheaderstyle"
                      active={active}
                      direction={direction as 'asc' | 'desc'}
                      onClick={() => handleSort('name')}
                      hideSortIcon={false}
                    >
                      Form Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      className="Tableheaderstyle"
                      hideSortIcon={true}
                    >
                      Created By
                    </TableSortLabel>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableRowcss">
                {formList.map((row) => (
                  <TableRow
                    key={row.id}
                    tabIndex={-1}
                    sx={{
                      cursor: 'pointer',
                      alignContent: 'flex-start',
                      '&:nth-of-type(odd)': {
                        backgroundColor: '#ECF9F8',
                      },
                    }}
                  >
                    {/* <TableCell> */}
                    {/* <CheckBoxComponent 
                      ischecked={selectedRows && selectedRows.includes(row.id)}
                      CheckHandleChange={() =>
                          handleCheckboxChange(
                            row.id,
                            row.name,
                            row.templateUrl,
                          )}/> */}
                    {/* </TableCell> */}
                    <TableCell
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '200px',
                      }}
                    >
                      {row.name.length > 20
                        ? `${row.name.substring(0, 20)}...`
                        : row.name}
                    </TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                    <TableCell>
                      <Box className="text-align-end">
                        <ButtonComponent
                          className="btn-primary btn-submit mr-md"
                          variantType="contained"
                          labelId="Intake.AddForm"
                          defaultLabelId="Add"
                          disabled={
                            selectedRows?.includes(row.id) ||
                            responseList?.some(
                              (item) => item.questionnaireId === row.id,
                            )
                          }
                          onClick={() =>
                            addButton(row.id, row.name, row.templateUrl)
                          }
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {count > 10 && (
            <Grid item>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={page}
                  paginationCount={count}
                  handleChangePage={handleChangePage}
                />
              </Box>
            </Grid>
          )}
          {/* </Card> */}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default IntakeQuestionnaireRenderTableModal;
