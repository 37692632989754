import { InputAdornment, SxProps, TextField } from '@mui/material';
import { ReactComponent as Search } from '../../assets/images/Search.svg';
import './Textinput.css';
import { useIntl } from 'react-intl';

const ALLOWED_CHARACTERS = /^[a-zA-Z0-9,'.\s!-]*$/;

interface SearchType {
  onClick?: () => void;
  onChange?: (value: string) => void;
  value?: string;
  sxProps?: SxProps;
  labelId?: string;
  defaultlabel?: string;
  endAdornment?: JSX.Element;
  backgroundColor?: string;
  className?: string;
}
export default function (props: SearchType) {
  const {
    onClick,
    onChange,
    value,
    labelId,
    defaultlabel,
    sxProps,
    endAdornment,
    backgroundColor,
    className,
  } = props;
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && onClick) {
      onClick();
    } else if (!ALLOWED_CHARACTERS.test(event.key)) {
      event.preventDefault();
    }
  };

  const onPasteText = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedText = e.clipboardData.getData('text');
    console.log(pastedText);
  };

  const intl = useIntl();
  return (
    <TextField
      className={className + ' SearchBox '}
      placeholder={intl.formatMessage({
        id: labelId,
        defaultMessage: defaultlabel,
      })}
      autoComplete="off"
      sx={{
        bgcolor: backgroundColor ?? 'rgba(255, 255, 255, 1)',
        minWidth: '320px',
        textAlign: 'center',
        borderRadius: '4px',
        height: '32px',
        '& .MuiOutlinedInput-root': {
          '& > fieldset': {
            border: 'none',
            padding: 0,
            fontFamily: 'Lato-Regular',
          },
          '& input.MuiInputBase-input': {
            fontFamily: 'Lato-Regular',
            fontSize: '14px',
          },
          '& input:placeholder': {
            fontFamily: 'Lato-Regular',
            fontSize: '14px',
            color: '#97A6A5',
          },
        },
        ...sxProps,
      }}
      size="small"
      onChange={(event) => {
        if (onChange !== undefined) {
          if (!ALLOWED_CHARACTERS.test(event.target.value)) {
            event.preventDefault();
          } else {
            onChange(event.target.value.trimStart());
          }
        }
      }}
      onPaste={onPasteText}
      value={value}
      InputProps={{
        endAdornment: endAdornment ?? (
          <InputAdornment
            onClick={onClick}
            onKeyDown={handleKeyPress}
            sx={{
              cursor: 'pointer',
              color: '#97A6A5',
            }}
            position="end"
          >
            <Search />
          </InputAdornment>
        ),
      }}
      onKeyDown={handleKeyPress}
    />
  );
}
