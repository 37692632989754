import React, { useContext } from 'react';
import { EICSForm } from '../../utils/type';
import { Box } from '@mui/system';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import { useNavigate } from 'react-router';
import { EICS_TBR_FORM } from '../../routes/Routing';
import TitleText from '../../components/formlib/TitleText';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Grid, Tooltip } from '@mui/material';
import moment from 'moment';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

interface TransitionType {
  data: EICSForm;
}

const TransitionFaceSheet: React.FC<TransitionType> = ({ data }) => {
  const navigation = useNavigate();

  const { lookups } = useContext(LookUpContext) as LookUpContextType;

  function handleStatus() {
    if (lookups === null) {
      return;
    }

    const status = lookups.seaStatus.find((r) => r.code === data.seaStatus);
    return status ? status.description : '-';
  }

  return (
    <>
      <Box sx={{ height: '180px' }}>
        <Box
          sx={{
            float: 'right',
            position: 'relative',
            cursor: 'pointer',
            display: 'none',
          }}
        >
          <ArrowRight
            onMouseDown={(e) => e.stopPropagation}
            onTouchStart={(e) => e.stopPropagation}
            onClick={() => {
              navigation(EICS_TBR_FORM);
            }}
          />
        </Box>
        <TitleText
          labelid="EICS.TransitionPlan"
          defaultlabel="Transition Plan"
          Sxprops={{ fontSize: '18px' }}
        />

        <Grid container sx={{ marginTop: '16px' }}>
          <Grid item xs={2.5}>
            <Box sx={{ flexDirection: 'column', display: 'flex' }}>
              <MediumTypography
                labelid={'Facesheet.transitionPlanDate'}
                defaultlabel={'Transition Plan Date'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '12px',
                }}
              />
              <MediumTypography
                label={
                  data.transitionPlanDate
                    ? `${moment(data.transitionPlanDate).format('MM/DD/YYYY')}`
                    : '-'
                }
                textColor="#2A4241"
              />
            </Box>

            <Box
              sx={{ flexDirection: 'column', display: 'flex', marginY: '16px' }}
            >
              <Box
                sx={{ flexDirection: 'row', display: 'flex', width: '600px' }}
              >
                <MediumTypography
                  labelid={'Facesheet.transitionPlanReason'}
                  defaultlabel={'Transition Plan Reason'}
                  sxProps={{
                    color: '#00C6B8',
                    fontSize: '12px',
                  }}
                />
              </Box>
              <Tooltip
                title={
                  data.noTransitionPlanReason &&
                  data.noTransitionPlanReason.toString()
                }
                followCursor
                arrow={true}
                sx={{ maxWidth: 'max-content' }}
              >
                <Box sx={{ maxHeight: '3em', overflow: 'hidden' }}>
                  <MediumTypography
                    label={
                      data.noTransitionPlanReason
                        ? data.noTransitionPlanReason.toString()
                        : '-'
                    }
                    textColor="#2A4241"
                    sxProps={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word',
                      display: '-webkit-box',
                      WebkitLineClamp: 2 /* Number of lines to show */,
                      WebkitBoxOrient: 'vertical',
                      maxWidth:
                        '95%' /* Ensure the text container takes full width */,
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={0.4}>
            <Box
              style={{
                height: '120px',
                borderLeft: '1px solid #cfdede',
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Box sx={{ flexDirection: 'column', display: 'flex' }}>
              <MediumTypography
                labelid={'Facesheet.leaReferralDate'}
                defaultlabel={'LEA Referral Date'}
                sxProps={{
                  color: '#00C6B8',
                  fontSize: '12px',
                }}
              />
              <MediumTypography
                label={
                  data.leaReferralConsentDate
                    ? `${moment(data.leaReferralConsentDate).format(
                        'MM/DD/YYYY',
                      )}`
                    : '-'
                }
                textColor="#2A4241"
              />
            </Box>
            <Box
              sx={{ flexDirection: 'column', display: 'flex', marginY: '16px' }}
            >
              <Box
                sx={{ flexDirection: 'row', display: 'flex', width: '600px' }}
              >
                <MediumTypography
                  labelid={'Facesheet.leaReason'}
                  defaultlabel={'LEA Reason'}
                  sxProps={{
                    color: '#00C6B8',
                    fontSize: '12px',
                  }}
                />
              </Box>
              <Tooltip
                title={
                  data.leaReferralReason && data.leaReferralReason.toString()
                }
                followCursor
                arrow={true}
                sx={{ maxWidth: 'max-content' }}
              >
                <Box sx={{ maxHeight: '3em', overflow: 'hidden' }}>
                  <MediumTypography
                    label={
                      data.leaReferralReason
                        ? data.leaReferralReason.toString()
                        : '-'
                    }
                    textColor="#2A4241"
                    sxProps={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word',
                      display: '-webkit-box',
                      WebkitLineClamp: 2 /* Number of lines to show */,
                      WebkitBoxOrient: 'vertical',
                      maxWidth:
                        '95%' /* Ensure the text container takes full width */,
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={0.4}>
            <Box
              style={{
                height: '120px',
                borderLeft: '1px solid #cfdede',
              }}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Box sx={{ flexDirection: 'column', display: 'flex' }}>
              <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                <MediumTypography
                  labelid={'Facesheet.conferenceDate'}
                  defaultlabel={'Conference Date'}
                  sxProps={{
                    color: '#00C6B8',
                    fontSize: '12px',
                  }}
                />
              </Box>
              <MediumTypography
                label={
                  data.tpcDate
                    ? `${moment(data.tpcDate).format('MM/DD/YYYY')}`
                    : '-'
                }
                textColor="#2A4241"
              />
            </Box>
            <Box
              sx={{ flexDirection: 'column', display: 'flex', marginY: '16px' }}
            >
              <Box
                sx={{ flexDirection: 'row', display: 'flex', width: '600px' }}
              >
                <MediumTypography
                  labelid={'Facesheet.conferenceReason'}
                  defaultlabel={'Conference Reason'}
                  sxProps={{
                    color: '#00C6B8',
                    fontSize: '12px',
                  }}
                />
              </Box>
              <Tooltip
                title={data.noTPCReason && data.noTPCReason.toString()}
                followCursor
                arrow={true}
                sx={{ maxWidth: 'max-content' }}
              >
                <Box sx={{ maxHeight: '3em', overflow: 'hidden' }}>
                  <MediumTypography
                    label={data.noTPCReason ? data.noTPCReason.toString() : '-'}
                    textColor="#2A4241"
                    sxProps={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word',
                      display: '-webkit-box',
                      WebkitLineClamp: 2 /* Number of lines to show */,
                      WebkitBoxOrient: 'vertical',
                      maxWidth:
                        '95%' /* Ensure the text container takes full width */,
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={0.4}>
            <Box
              style={{
                height: '120px',
                borderLeft: '1px solid #cfdede',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Box sx={{ flexDirection: 'column', display: 'flex' }}>
              <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                <MediumTypography
                  labelid={'Facesheet.seaStatus'}
                  defaultlabel={'SEA Status'}
                  sxProps={{
                    color: '#00C6B8',
                    fontSize: '12px',
                  }}
                />
              </Box>
              <MediumTypography label={handleStatus()} textColor="#2A4241" />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default TransitionFaceSheet;
