import { Box, Grid, Tooltip } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import TitleText from '../../components/formlib/TitleText';
import { FC } from 'react';
import { CaseLoadList } from '../../services/configApi/employees/employeeServices';

interface CaseLoadProps {
  caseLoad: CaseLoadList;
  handleNavigation: () => void;
}

const CaseLoad: FC<CaseLoadProps> = ({ caseLoad, handleNavigation }) => {
  return (
    <Box className={'p-md'}>
      <Box className={'flex__'}>
        <MediumTypography
          sxProps={{ flexGrow: 1 }}
          className="mainText-lg"
          labelid="caseloadListText"
          defaultlabel="My Caseload List"
        />
        <Box sx={{ cursor: 'pointer' }} onClick={handleNavigation}>
          <ArrowRight />
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={4} alignSelf={'center'}>
          <Box>
            <Box
              className={'flex__ p-sm mt-sm'}
              sx={{
                backgroundColor: '#ECF9F8',
                borderRadius: '4px',
                border: '1px solid #B3E7E3',
              }}
            >
              <MediumTypography
                sxProps={{ flexGrow: 1, color: '#2A4241' }}
                labelid="noOfClientsAssignedText"
                defaultlabel="No. of clients assigned"
              />
              <TitleText
                Sxprops={{ color: '#00C6B8' }}
                label={
                  caseLoad.noOfClients !== null
                    ? caseLoad.noOfClients.toString()
                    : '0'
                }
              />
            </Box>

            <Box
              className={'flex__ p-sm mt-sm'}
              sx={{
                backgroundColor: '#ECF9F8',
                borderRadius: '4px',
                border: '1px solid #B3E7E3',
              }}
            >
              <MediumTypography
                sxProps={{ flexGrow: 1, color: '#2A4241' }}
                labelid="clientsAgeMoreThan27Months"
                defaultlabel="Client's age more than 27 months"
              />
              <TitleText
                Sxprops={{ color: '#00C6B8' }}
                label={
                  caseLoad.clientsAbove30 !== null
                    ? caseLoad.clientsAbove30.toString()
                    : '0'
                }
              />
            </Box>

            <Box
              className={'flex__ p-sm mt-sm'}
              sx={{
                backgroundColor: '#ECF9F8',
                borderRadius: '4px',
                border: '1px solid #B3E7E3',
              }}
            >
              <MediumTypography
                sxProps={{ flexGrow: 1, color: '#2A4241' }}
                labelid="tpcCompletedText"
                defaultlabel="Transition Planning Completed"
              />
              <TitleText
                Sxprops={{ color: '#00C6B8' }}
                label={
                  caseLoad.unscheduledClients !== null
                    ? caseLoad.unscheduledClients.toString()
                    : '0'
                }
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} sx={{ paddingLeft: '72px' }}>
          <Box className={'flex__'}>
            <MediumTypography
              sxProps={{
                color: '#97A6A5',
                fontWeight: 'bold',
                flex: 1,
              }}
              labelid="Dashboard.ClientName"
              defaultlabel="Client Name"
            />
            <MediumTypography
              sxProps={{ color: '#97A6A5', fontWeight: 'bold', flex: 1 }}
              labelid="Client.noOfAssessmentHoursText"
              defaultlabel="Assessment Hours"
            />
            <MediumTypography
              sxProps={{ color: '#97A6A5', fontWeight: 'bold', flex: 1 }}
              labelid="staffTextLabel"
              defaultlabel="Staff"
            />
          </Box>
          <Box className={'mt-sm'}>
            {caseLoad.caseLoadClients.length === 0 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '8%',
                }}
              >
                <MediumTypography
                  sxProps={{ fontWeight: 'bold' }}
                  labelid="emptyClientTextMessage"
                  defaultlabel="No clients added yet"
                />
              </Box>
            )}
            {caseLoad.caseLoadClients.map((item, index) => (
              <Box>
                {index !== 0 && <Box className="defaultHorizontalLine" />}
                <Box className={'flex__'}>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <Tooltip
                      title={`${
                        item.firstName !== null ? item.firstName : ''
                      } ${item.lastName !== null ? item.lastName : ''}`}
                      followCursor
                      arrow={true}
                    >
                      <Box>
                        <MediumTypography
                          sxProps={{
                            color: '#008C82',
                            fontWeight: 'bold',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: '200px',
                          }}
                          label={`${
                            item.firstName !== null ? item.firstName : ''
                          } ${item.lastName !== null ? item.lastName : ''}`}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <MediumTypography
                      label={
                        item.assessmentHours !== null
                          ? item.assessmentHours
                          : '-'
                      }
                    />
                  </Box>
                  <Box sx={{ flex: 1 }}>
                    <MediumTypography
                      label={
                        item.serviceCoordinator !== null
                          ? item.serviceCoordinator
                          : '-'
                      }
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseLoad;
