import {
  Box,
  Card,
  Grid,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  debounce,
  styled,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Order, ResponseType } from '../../utils/type';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import { ReactComponent as DownloadIcon } from '../../assets/images/downloadIcon.svg';
import {
  ClientFile,
  ClientFilesResponse,
  deleteFileById,
  getClientFiles,
} from '../../services/configApi/clientFiles/clientFilesProvider';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import {
  extractFileExtension,
  getFileNameFromURL,
} from '../../utils/fileUtils';
import SearchBox from '../../components/formlib/SearchBox';
import {
  LOCAL_DATE_TIME_FORMAT,
  PREVIEW_DOCUMENT_EXTENSIONS,
} from '../../services/Constant';
import moment from 'moment';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import './Clientfiles.css';
import FilePreviewDialog from '../../components/formlib/modal/FilePreviewDialog';
import ModalPopup from '../../components/formlib/ModalPopup';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import dayjs from 'dayjs';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';

interface Data {
  notes: string;
  fileName: string;
  fileType: string;
  uploadedTime: string;
  fileDesc: string;
  fileOrigin: string;
  actions: string;
}

const ellipsisCellSX = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '200px',
};

const CustomTableCell = styled(TableCell)({
  paddingLeft: '8px',
});

const headCells: HeadCell<Data>[] = [
  {
    id: 'fileName',
    labelId: 'fileName',
    defaultLabelId: 'File Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'fileDesc',
    labelId: 'fileDesc',
    defaultLabelId: 'File Description',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'fileType',
    labelId: 'fileType',
    defaultLabelId: 'File Type',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'uploadedTime',
    labelId: 'uploadedText',
    defaultLabelId: 'Uploaded Date',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'fileOrigin',
    labelId: 'fileOrigin',
    defaultLabelId: 'File origin',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'notes',
    numeric: false,
    labelId: 'CommunicationLogModal.note',
    defaultLabelId: 'Notes',
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const checkBoxRequired = false;
let startIndex = 0;
const PAGE_SIZE = 10;

const ClientFilesTableView: FC<{ reload: boolean }> = ({ reload }) => {
  const [originalResponse, setOriginalResponse] = useState<ClientFile[]>([]);
  const [fileResponse, setFileResponse] = useState<ClientFile[]>([]);
  const [searchedResponse, setSearchedResponse] = useState<ClientFile[]>([]);
  const [selectedFile, setSelectedFile] = useState<ClientFile>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [toastrId, setToastrId] = useState<string>();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof Data>('uploadedTime');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const clientId = localStorage.getItem('ClientId');
    if (clientId === null) {
      return;
    }
    toggleLoader(true);
    getClientFiles(clientId)
      .then((response: ClientFilesResponse) => {
        startIndex = 0;
        setOriginalResponse(response.list);
        setFileResponse(
          response.list.slice(startIndex, startIndex + PAGE_SIZE),
        );
        startIndex += PAGE_SIZE;
        setPaginationCount(response.list.length);
        toggleLoader(false);
        setSuccessOrError('success');
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setErrorMessage(apiError.message);
        } else {
          setToastrId(error);
          setErrorMessage(error);
        }
      });
  }, [reload]);

  const updateTable = (page: number) => {
    if (page == 1) {
      startIndex = 0;
    } else {
      startIndex = (page - 1) * PAGE_SIZE;
    }
    setPageNumber(page);
    if (searchQuery.length == 0) {
      setFileResponse(
        originalResponse.slice(startIndex, startIndex + PAGE_SIZE),
      );
    } else {
      setFileResponse(
        searchedResponse.slice(startIndex, startIndex + PAGE_SIZE),
      );
    }
  };

  const handleDownloadClick = (fileItem: ClientFile) => {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = fileItem.url;
    const fileName = fileItem.name.split('/').pop();
    if (fileName !== undefined) {
      link.download = fileName;
      link.target = '_blank';
      link.click();
    }
  };

  const handlePreviewClick = (fileItem: ClientFile) => {
    setShowPreview(true);
    setSelectedFile(fileItem);
  };

  const handleDeleteClick = (fileItem: ClientFile) => {
    if (fileItem.tags.id) {
      toggleLoader(true);
      deleteFileById(fileItem.tags.id)
        .then(() => {
          const filteredItems = fileResponse.filter(
            (item) => item.tags.id !== fileItem.tags.id,
          );
          const filteredOriginalResponse = originalResponse.filter(
            (item) => item.tags.id !== fileItem.tags.id,
          );
          if (filteredItems.length === 0 && pageNumber > 1) {
            setPageNumber(pageNumber - 1);
            updateTable(pageNumber - 1);
          } else {
            setFileResponse(filteredItems);
          }
          setOriginalResponse(filteredOriginalResponse);
          setPaginationCount(filteredOriginalResponse.length);
          toggleLoader(false);
          setSuccessOrError('success');
          setToastrId('fileDeleteMessage');
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setErrorMessage(apiError.message);
          } else {
            setToastrId('failedCaseloadListMessage');
            setErrorMessage('Failed to load caseload list');
          }
        });
    }
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setSearchQuery(value);
      if (value.length === 0) {
        startIndex = 0;
        setPageNumber(1);
        setFileResponse(
          originalResponse.slice(startIndex, startIndex + PAGE_SIZE),
        );
        startIndex += PAGE_SIZE;
        setPaginationCount(originalResponse.length);
        setSearchedResponse([]);
      } else {
        const files = JSON.parse(
          JSON.stringify(originalResponse),
        ) as ClientFile[];
        const result = files.filter((response) => {
          const fileName = response.name.split('/');
          const description = response.tags.description;
          const notes = response.tags.notes;
          if (fileName.length > 0) {
            return (
              fileName[fileName.length - 1]
                .toLowerCase()
                .includes(value.toLowerCase()) ||
              description?.toLowerCase().includes(value.toLowerCase()) ||
              notes?.toLowerCase().includes(value.toLowerCase())
            );
          }
          return (
            description?.includes(value.toLowerCase()) ||
            notes?.includes(value.toLowerCase())
          );
        });
        setPaginationCount(result.length);
        setSearchedResponse(result);
        startIndex = 0;
        if (result.length < PAGE_SIZE) {
          setFileResponse(result);
        } else {
          setFileResponse(result.slice(startIndex, startIndex + PAGE_SIZE));
          startIndex += PAGE_SIZE;
        }
      }
    }, 500),
    [originalResponse, searchQuery],
  );

  useEffect(() => {
    if (originalResponse.length > 0) {
      let newResponse: ClientFile[] = [];
      if (searchQuery.length > 0) {
        newResponse = JSON.parse(
          JSON.stringify(searchedResponse),
        ) as ClientFile[];
      } else {
        newResponse = JSON.parse(
          JSON.stringify(originalResponse),
        ) as ClientFile[];
      }

      newResponse.sort((a, b) => {
        if (orderBy === 'uploadedTime') {
          const dateA = dayjs(a.tags.timestamp, 'MM-DD-YYYY HH:mm:ss');
          const dateB = dayjs(b.tags.timestamp, 'MM-DD-YYYY HH:mm:ss');
          if (order === 'asc') {
            return dateA.isBefore(dateB) ? -1 : 1;
          } else {
            return dateA.isAfter(dateB) ? -1 : 1;
          }
        } else if (orderBy === 'fileName') {
          if (order === 'asc') {
            return a.name.localeCompare(b.name);
          } else {
            return b.name.localeCompare(a.name);
          }
        } else if (orderBy === 'fileType') {
          if (order === 'asc') {
            return a.tags.type
              ? a.tags.type.localeCompare(b.tags.type ?? '')
              : 0;
          } else {
            return b.tags.type
              ? b.tags.type.localeCompare(a.tags.type ?? '')
              : 0;
          }
        } else {
          return 0;
        }
      });

      startIndex = 0;
      setPageNumber(1);
      if (searchQuery.length > 0) {
        setSearchedResponse([...newResponse]);
      } else {
        setOriginalResponse([...newResponse]);
      }
      setFileResponse(newResponse.slice(startIndex, startIndex + PAGE_SIZE));
      startIndex += PAGE_SIZE;
      setPaginationCount(newResponse.length);
    }
  }, [order, orderBy]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Grid>
      <Box
        paddingBottom={'16px'}
        paddingTop={'24px'}
        display={'flex'}
        justifyContent={'space-between'}
      >
        <TitleText
          labelid="filesText"
          defaultlabel="Files"
          Sxprops={{ fontSize: 24 }}
        />
        <SearchBox
          labelId="ClientFilesTableView.searchPlaceholder"
          onChange={handleSearchChange}
          sxProps={{
            backgroundColor: 'white',
          }}
        />
      </Box>
      <Card>
        <>
          <ModalPopup
            open={showDialog}
            description="fileDeleteConfirmationText"
            onCancel={() => {
              setSelectedFile(undefined);
              setShowDialog(false);
              setShowPreview(false);
            }}
            onOk={() => {
              if (selectedFile) {
                setShowDialog(false);
                handleDeleteClick(selectedFile);
              }
            }}
            labelId1="Clientpage.Nobtn"
            negativeActionLabel="No"
            labelId2="Clientpage.Yesbtn"
            positiveActionLabel="Yes"
          />
          {selectedFile && showPreview && (
            <FilePreviewDialog
              url={selectedFile.url}
              fileName={selectedFile.name.split('/').pop()}
              onClose={() => {
                setShowPreview(false);
                setSelectedFile(undefined);
              }}
            />
          )}
          {toastrId && (
            <SnackBarComponent
              open={toastrId !== undefined}
              handleClose={() => {
                setToastrId(undefined);
              }}
              labelId={toastrId}
              defaultMessageId={errorMessage}
              successOrError={successOrError}
            />
          )}
          <Box>
            <>
              <div
                ref={parentRef}
                style={{
                  pointerEvents: isScreenLocked ? 'none' : 'auto',
                  opacity: isScreenLocked ? '0.5' : '1 ',
                }}
                onKeyDownCapture={(e) => {
                  if (isScreenLocked) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onFocus={() => {
                  if (isScreenLocked) {
                    if (parentRef.current) parentRef.current.focus();
                  }
                }}
              >
                <TableContainer className="table_padding">
                  <>
                    <Table>
                      <TableHeader
                        className="listDataTableHead"
                        headerNames={headCells}
                        checkBoxRequired={checkBoxRequired}
                        onRequestSort={handleRequestSort}
                        order={order}
                        orderBy={orderBy}
                      />
                      <TableBody className="tableRowcss">
                        {fileResponse &&
                          fileResponse.map((fileItem, index) => {
                            return (
                              <TableRow
                                hover
                                onClick={() => {}}
                                key={index.toString()}
                                sx={{
                                  backgroundColor:
                                    index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                                }}
                              >
                                {checkBoxRequired && <CustomTableCell />}
                                <TooltipTableCell
                                  onClick={() => {
                                    const extension = extractFileExtension(
                                      fileItem.url,
                                    ).toLowerCase();
                                    if (
                                      PREVIEW_DOCUMENT_EXTENSIONS.includes(
                                        extension,
                                      )
                                    ) {
                                      handlePreviewClick(fileItem);
                                    }
                                  }}
                                  value={getFileNameFromURL(fileItem.name)}
                                  visible={true}
                                  textSxProps={{
                                    cursor: 'pointer',
                                    color: '#008C82',
                                    textDecorationLine:
                                      PREVIEW_DOCUMENT_EXTENSIONS.includes(
                                        extractFileExtension(
                                          fileItem.url,
                                        ).toLowerCase(),
                                      )
                                        ? 'underline'
                                        : 'none',
                                    ...ellipsisCellSX,
                                  }}
                                />
                                <TooltipTableCell
                                  value={
                                    fileItem.tags.description !== null
                                      ? fileItem.tags.description
                                      : '-'
                                  }
                                  visible={true}
                                  textSxProps={ellipsisCellSX}
                                />
                                <TooltipTableCell
                                  value={
                                    fileItem.tags.type !== null
                                      ? fileItem.tags.type
                                          .charAt(0)
                                          .toUpperCase() +
                                        fileItem.tags.type.slice(1)
                                      : '-'
                                  }
                                  visible={false}
                                />
                                <CustomTableCell>
                                  <MediumTypography
                                    label={moment(
                                      fileItem.tags.timestamp,
                                      'MM-DD-YYYY HH:mm:ss',
                                    )
                                      .utc(true)
                                      .local()
                                      .format(LOCAL_DATE_TIME_FORMAT)}
                                  />
                                </CustomTableCell>
                                <TooltipTableCell
                                  value={
                                    fileItem.tags.origin !== null
                                      ? fileItem.tags.origin
                                      : '-'
                                  }
                                  visible={false}
                                />
                                <TooltipTableCell
                                  value={
                                    fileItem.tags.notes !== null
                                      ? fileItem.tags.notes
                                      : '-'
                                  }
                                  visible={true}
                                />
                                <CustomTableCell
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                  }}
                                >
                                  <ActionToolTip
                                    labelId="ConsentForms.listActionDownloadToolTipLabel"
                                    defaultLabel="Download"
                                  >
                                    <Box
                                      style={{ padding: '4px' }}
                                      onClick={() => {
                                        handleDownloadClick(fileItem);
                                      }}
                                    >
                                      <DownloadIcon className="cursorPointer" />
                                    </Box>
                                  </ActionToolTip>
                                  <ActionToolTip
                                    labelId="MyTimeOffDelete"
                                    defaultLabel="Delete"
                                  >
                                    <Box
                                      style={{ padding: '4px' }}
                                      onClick={() => {
                                        setShowDialog(true);
                                        setShowPreview(false);
                                        setSelectedFile(fileItem);
                                      }}
                                    >
                                      <DeleteIcon className="cursorPointer" />
                                    </Box>
                                  </ActionToolTip>
                                </CustomTableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>

                    {searchQuery === '' &&
                      pageNumber === 1 &&
                      fileResponse.length === 0 && (
                        <Box
                          component="main"
                          sx={{
                            flexGrow: 1,
                            bgcolor: '#E7F0F0',
                          }}
                        >
                          <MediumTypography
                            textstyle={'center'}
                            labelid="ClientFilesTableView.emptyMessage"
                            defaultlabel="No files found"
                            paddingstyle="9px"
                          />
                        </Box>
                      )}
                    {searchQuery !== '' &&
                      pageNumber === 1 &&
                      fileResponse.length === 0 && (
                        <Box
                          component="main"
                          sx={{
                            flexGrow: 1,
                            bgcolor: '#E7F0F0',
                          }}
                        >
                          <MediumTypography
                            textstyle={'center'}
                            labelid="ClientFilesTableView.searchMessage"
                            defaultlabel="We could not find any search results. Give it another go."
                            paddingstyle="16px"
                          />
                        </Box>
                      )}
                  </>
                </TableContainer>
              </div>
            </>
          </Box>
        </>
      </Card>
      {paginationCount > 10 && (
        <Grid
          container
          spacing={1}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            padding: '20px',
          }}
        >
          <Grid item>
            <Pagination
              boundaryCount={2}
              page={pageNumber}
              onChange={(_, page: number) => {
                updateTable(page);
              }}
              sx={{
                '& .MuiPaginationItem-root': {
                  fontFamily: 'Lato-Regular',
                },
                '& .MuiPaginationItem-root.Mui-selected': {
                  backgroundColor: '#008C82',
                  color: '#ffffff',
                },
              }}
              count={Math.ceil(paginationCount / PAGE_SIZE)}
              siblingCount={0}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ClientFilesTableView;
