import { ApiResponseHandler, ApiMessage } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export type Codes = {
  code: string;
  description: string;
  isValidForInsurance: boolean;
  isUnderlined: boolean;
  date: Date | null | string;
  id?: string;
};
export type SubCategory = {
  subTitleName: string;
  codes: Codes[];
  id?: string;
};

export type Category = {
  categoryName?: string;
  subCategories: SubCategory[];
  id?: string;
};

export type DiagnosisData = {
  diagnosisCode: DiagnosisCode[];
};

export type DiagnosisCode = {
  id?: string;
  codeId: number;
  code: string;
  description: string;
  oneYearEligibility: boolean;
  isUnderlined?: boolean;
  isDeleted?: boolean;
  qualifyingDiagnosis?: boolean;
  isAddedCode?: boolean;
  isDuplicateCode?: boolean;
};

export type CategoryAddDto = {
  addedCode: DiagnosisCode[];
  duplicateCode?: DiagnosisCode[];
};

export type DiagnosisCodeDto = {
  diagnosisCode: DiagnosisCode[];
  count: number;
};

export const getDiagnosticCodes = async (): Promise<Category[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Category[]>(
      `code-list/diagnostic-code/all`,
    ),
  );
};

export const getAllDiagnosisCodes = async (
  searchquery: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
): Promise<DiagnosisCodeDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<DiagnosisCodeDto>(
      `diagnosisCode/all?search=${searchquery}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType}`,
    ),
  );
};

export const addDiagnosticCodes = async (
  requestBody: Category,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<Category, ApiMessage>(
      `code-list/diagnostic-code`,
      requestBody,
    ),
  );
};

export const addDiagnosticCodesNew = async (
  requestBody: DiagnosisData,
): Promise<CategoryAddDto> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<DiagnosisData, CategoryAddDto>(
      `diagnosisCode`,
      requestBody,
    ),
  );
};

export const editDiagnosticCodesNew = async (
  requestBody: DiagnosisCode,
  id: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<DiagnosisCode, ApiMessage>(
      `diagnosisCode?id=${id}`,
      requestBody,
    ),
  );
};

export const deleteDiagnosticCodesNew = async (
  id: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `diagnosisCode?id=${id}`,
    ),
  );
};

export const updateDiagnosticCodes = async (
  requestBody: Category,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<Category, ApiMessage>(
      `code-list/diagnostic-code`,
      requestBody,
    ),
  );
};

export const getCodesAndDescription = async (): Promise<Codes[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Codes[]>(`code`),
  );
};

export const getCodesAndDescriptionNew = async (): Promise<DiagnosisCode[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<DiagnosisCode[]>(`diagnosticCode`),
  );
};
