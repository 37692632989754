import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC } from 'react';
import { Tooltip, Grid } from '@mui/material';

export type Program = {
  programName: string;
  phoneNumber: string;
  faxNumber: string;
  image: string;
  website: string;
};
interface ProgramHeaderprops {
  program: Program;
  programNameLabel: string;
  programNameDefaultLabel: string;
  phoneNumberLabel: string;
  phoneNumberDefaultLabel: string;
  faxNumberLabel: string;
  faxNumberDefaultLabel: string;
  websiteLabel: string;
  websiteDefaultLabel: string;
}

const ProgramDetails: FC<ProgramHeaderprops> = (props) => {
  return (
    <Box component="main" className="mt-xs p-none">
      <Grid container className="flex__justify__space-between">
        <Grid item xs={8}>
          <Box component="div" className="flex__">
            <MediumTypography
              labelid={props.programNameLabel}
              defaultlabel={props.programNameDefaultLabel}
              fontSize="20px"
              fontweight={700}
              textColor="#97A6A5"
              sxProps={{
                fontFamily: 'Lato-Bold',
              }}
            />
            <Tooltip title={props.program.programName} arrow={true}>
              <Box component="div">
                <MediumTypography
                  fontSize="20px"
                  fontweight={700}
                  textColor="#2A4241"
                  className="pl-xs "
                  sxProps={{
                    fontFamily: 'Lato-Bold',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={props.program.programName}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box component="div" className="flex__ width__100" mt={2}>
            <Box component="div" className="flex__ pr-md ">
              <MediumTypography
                labelid={props.phoneNumberLabel}
                defaultlabel={props.phoneNumberDefaultLabel}
                fontSize="16px"
                textColor="#97A6A5"
              />
              <MediumTypography
                fontSize="16px"
                textColor="#2A4241"
                className="pl-xs width150px "
                label={
                  props.program.phoneNumber === '' ||
                  props.program.phoneNumber === null
                    ? '-'
                    : props.program.phoneNumber
                }
                sxProps={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              />
            </Box>
            <Box component="div" className="flex__ pr-md ">
              <MediumTypography
                labelid={props.faxNumberLabel}
                defaultlabel={props.faxNumberDefaultLabel}
                fontSize="16px"
                textColor="#97A6A5"
              />
              <MediumTypography
                fontSize="16px"
                textColor="#2A4241"
                className="pl-xs width150px "
                label={
                  props.program.faxNumber === '' ||
                  props.program.faxNumber === null
                    ? '-'
                    : props.program.faxNumber
                }
                sxProps={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              />
            </Box>
            <Box component="div" className="flex__ pr-md">
              <MediumTypography
                labelid={props.websiteLabel}
                defaultlabel={props.websiteDefaultLabel}
                fontSize="16px"
                textColor="#97A6A5"
              />
              <Box component="div" className="width200px">
                <Tooltip
                  title={
                    props.program.website && props.program.website.length > 10
                      ? props.program.website
                      : ''
                  }
                  followCursor
                  arrow={true}
                >
                  <Box component="div">
                    <MediumTypography
                      fontSize="16px"
                      textColor="#2A4241"
                      className="pl-xs "
                      label={
                        props.program.website === '' ||
                        props.program.website === null
                          ? '-'
                          : props.program.website
                      }
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item className="p-sm justifyContent-End">
          <Box>
            <img
              style={{
                width: '250px',
                height: '63px',
              }}
              src={props.program.image}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProgramDetails;
