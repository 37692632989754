import { Box, Grid } from '@mui/material';
import KeyValueComponent, { DataJsonType } from './KeyValueComponent';
import React, { FC, useEffect, useMemo, useState } from 'react';
import MediumTypography from '../formlib/MediumTypography';
import ThomProgram from '../../assets/images/thomchildlogo.svg';
import { Client, ProgramPanelType } from '../../pages/eligibility/types';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import { removeDoubleCommas } from '../../utils/nameUtils';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

export interface ProgramProps {
  labels: DataJsonType[];
  titleLabelId: string;
  titleDefaultLabel: string;
  titleValue: string;
  imageUrl: string;
}
const ProgramPanel: FC<ProgramPanelType> = ({
  program,
  client,
  parent,
  showIntakeFacilitator,
  showAdditionalDemographics,
}) => {
  const [clientDetails, setClientDetails] = useState<DataJsonType[]>([]);
  const [clientAdditionalDetails, setClientAdditionalDetails] = useState<DataJsonType[]>([]);
  const [programDetails, setProgramDetails] = useState<ProgramProps | null>(
    null,
  );
  const [parentDetails, setParentDetails] = useState<DataJsonType[]>([]);
  const { lookups } = React.useContext(LookUpContext) as LookUpContextType;

  const constructClientDetails = () => {
    const clientKeys: DataJsonType[] = [];
    if (client) {
      clientKeys.push({
        id: 'clientName',
        labelid: 'ProgressNote.ClientName',
        defaultLabel: 'ClientName:',
        value: client.name ?? '-',
      });
      clientKeys.push({
        id: 'sex',
        labelid: 'ProgressNote.Sex',
        defaultLabel: 'Sex:',
        value: `${
          client.sex !== null ? convertGenderToFrontend(client.sex) : '-'
        }`,
      });
      clientKeys.push({
        id: 'dphId',
        labelid: 'ProgressNote.DPHID',
        defaultLabel: 'Enrollment ID:',
        value: client.dphId ?? '-',
      });
      clientKeys.push({
        id: 'dob',
        labelid: 'ProgressNote.DateofBirth',
        defaultLabel: 'Date of Birth:',
        value: client.dob ?? '-',
      });
      clientKeys.push({
        id: 'rcd',
        labelid: 'sspReferral.referralDate',
        defaultLabel: 'Referral Begin Date:',
        value: client.referralBeginDate ?? '-',
      });
      clientKeys.push({
        id: 'serviceCoordinator',
        labelid: 'sspReferral.serviceCoordinator',
        defaultLabel: 'Service Cooridinator:',
        value: `${
          client.serviceCoordinatorName ? client.serviceCoordinatorName : ''
        }${
          client.serviceCoordinatorPhone
            ? ', ' + client.serviceCoordinatorPhone
            : ''
        }${
          client.serviceCoordinatorPhoneExtension
            ? ',  Ext: ' + client.serviceCoordinatorPhoneExtension
            : ''
        }`,
      });
      if (showIntakeFacilitator) {
        clientKeys.push({
          id: 'intakeFacilitator',
          labelid: 'ProgramPanel.intakeFacilitator',
          defaultLabel: 'Intake Facilitator:',
          value: client.intakeFacilitator ?? '-',
        });
      }
    }
    setClientDetails(clientKeys);
  };

  const getPediatricianName = useMemo(
    () =>
      (clientForPed: Client): string => {
        let pediatricianName = '';
        if (clientForPed.pediatricianName !== null) {
          pediatricianName = clientForPed.pediatricianName;
        }
        if (clientForPed.pediatricianPhoneNumber !== null) {
          pediatricianName =
            pediatricianName + ', ' + clientForPed.pediatricianPhoneNumber;
        }
        return pediatricianName;
      },
    [],
  );

  const constructAdditionalClientDetails = () => {
    if(showAdditionalDemographics && client) {
      const clientKeys: DataJsonType[] = [];
      const demographicsDetails = getAdditionalDemographicData(client);
      clientKeys.push(...demographicsDetails);
      clientKeys.push({
        id: 'pediatrician',
        labelid: 'pediatricianText',
        defaultLabel: 'Pediatrician : ',
        value: getPediatricianName(client),
      });
      setClientAdditionalDetails(clientKeys);
    }
  }

  const getLanguageDescription = (code: string) => {
    if (lookups?.language) {
      const language = lookups.language.find((lang) => lang.code === code);
      return language ? language.description : '-';
    }
    return '-';
  };

  const getRaceDescription = (code: string) => {
    if (lookups?.race) {
      const race = lookups.race.find((r) => r.code === code);
      return race ? race.description : '-';
    }
    return '-';
  };

  const getEthnicityDescription = (code: string) => {
    if (lookups?.ethnicity) {
      const ethnicity = lookups.ethnicity.find((eth) => eth.code === code);
      return ethnicity ? ethnicity.description : '-';
    }
    return '-';
  };

  const getAdditionalDemographicData = useMemo(
    () =>
      (clientData: Client): DataJsonType[] => {
        const data: DataJsonType[] = [];

        data.push({
          id: 'spokenLanguage',
          labelid: 'primarySpokenLanguage',
          defaultLabel: 'Primary Spoken Language : ',
          value:
          clientData.primarySpokenLanguage !== null
              ? getLanguageDescription(clientData.primarySpokenLanguage)
              : '-',
        });
        data.push({
          id: 'writtenLanguage',
          labelid: 'primaryWrittenLanguage',
          defaultLabel: 'Primary Written Language : ',
          value:
          clientData.primaryWrittenLanguage !== null
              ? getLanguageDescription(clientData.primaryWrittenLanguage)
              : '-',
        });
        data.push({
          id: 'race',
          labelid: 'raceText',
          defaultLabel: 'Race : ',
          value:
          clientData.race !== null
              ? clientData.race.split(',').map(getRaceDescription).toString()
              : '-',
        });
        data.push({
          id: 'ethnicity',
          labelid: 'ethnicity',
          defaultLabel: 'Ethnicity : ',
          value:
          clientData.ethnicity !== null
              ? getEthnicityDescription(clientData.ethnicity)
              : '-',
        });
        return data;
      },
    [],
  );

  const constructProgramDetails = () => {
    const programProps: ProgramProps = {
      labels: [],
      titleLabelId: '',
      titleDefaultLabel: '',
      titleValue: '',
      imageUrl: '',
    };
    if (program) {
      programProps.titleLabelId = 'ConsentForms.programName';
      programProps.titleDefaultLabel = 'Program:';
      programProps.titleValue = program.name ?? '-';
      programProps.imageUrl = program.imageUrl ?? '';
      const labels: DataJsonType[] = [];
      labels.push({
        id: 'phone',
        labelid: 'ConsentForms.contactPhone',
        defaultLabel: 'Phone:',
        value: program.phoneNumber ?? '-',
      });
      labels.push({
        id: 'fax',
        labelid: 'ProgressNote.Fax',
        defaultLabel: 'Fax:',
        value: program.faxNumber ?? '-',
      });
      labels.push({
        id: 'website',
        labelid: 'ConsentForms.programWebsite',
        defaultLabel: 'Website:',
        value: program.website ?? '-',
      });
      programProps.labels = labels;
    }
    setProgramDetails(programProps);
  };

  const constructParentInfo = () => {
    const parentInfo: DataJsonType[] = [];
    if (parent) {
      parentInfo.push({
        id: 'parentGuardianName',
        labelid: 'sspReferral.parentName',
        defaultLabel: 'Guardian Name:',
        value: parent.name ?? '-',
      });
      parentInfo.push({
        id: 'contactRelationship',
        labelid: 'Facesheet.relationship',
        defaultLabel: 'Guardian Type:',
        value:
          lookups?.guardianType?.find(
            (g) =>
              g.code.toUpperCase() ===
              parent.relationshipToClient?.toUpperCase(),
          )?.description || '-',
      });
      parentInfo.push({
        id: 'contactEmail',
        labelid: 'ConsentEmail',
        defaultLabel: 'Email:',
        value: parent.email ?? '-',
      });
      parentInfo.push({
        id: 'contactPhone',
        labelid: 'ConsentForms.contactPhone',
        defaultLabel: 'Phone:',
        value: parent.homePhoneNumber ?? '-',
      });
      parentInfo.push({
        id: 'contactAddress',
        labelid: 'ConsentForms.clientAddress',
        defaultLabel: 'Address:',
        value: `${
          parent.address !== null ? removeDoubleCommas(parent.address) : '-'
        }`,
      });
    }
    setParentDetails(parentInfo);
  };

  useEffect(() => {
    constructClientDetails();
    constructAdditionalClientDetails();
    constructProgramDetails();
    constructParentInfo();
  }, [program, client, parent]);

  return (
    <Grid>
      <Grid
        container
        direction={'row'}
        className="justifyContentSpaceBtw"
        alignItems={'center'}
      >
        <Grid item xs={8}>
          <Box className="flex__ pb-sm">
            <MediumTypography
              labelid={
                programDetails !== null ? programDetails.titleLabelId : ''
              }
              defaultlabel={
                programDetails !== null ? programDetails.titleDefaultLabel : ''
              }
              sxProps={{
                fontFamily: 'Lato-Bold',
                fontSize: '20px',
                color: '#97A6A5',
              }}
            />
            {programDetails !== null && (
              <MediumTypography
                className="pl-sm"
                sxProps={{
                  color: '#2A4241',
                  fontFamily: 'Lato-Bold',
                  fontSize: '20px',
                }}
                label={
                  programDetails.titleValue === null ||
                  programDetails.titleValue === ''
                    ? '-'
                    : programDetails.titleValue
                }
              />
            )}
          </Box>
          <KeyValueComponent
            data={programDetails !== null ? programDetails.labels : []}
          />
        </Grid>
        <Grid item className="justifyContent-End">
          <Box>
            {programDetails !== null && (
              <img
                style={{
                  width: '250px',
                  height: '63px',
                }}
                src={
                  programDetails.imageUrl === '' ||
                  programDetails.imageUrl === null
                    ? ThomProgram
                    : programDetails.imageUrl
                }
              />
            )}
          </Box>
        </Grid>
      </Grid>

      {clientDetails.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Box className="defaultHorizontalLine" />
          </Grid>
          <Grid direction={'row'} item>
            <KeyValueComponent data={clientDetails} />
          </Grid>
        </Grid>
      )}

      {clientAdditionalDetails.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Box className="defaultHorizontalLine" />
          </Grid>
          <Grid direction={'row'} item>
            <KeyValueComponent data={clientAdditionalDetails} />
          </Grid>
        </Grid>
      )}

      {parentDetails.length > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Box className="defaultHorizontalLine" />
          </Grid>
          <Grid direction={'row'} item>
            <KeyValueComponent data={parentDetails} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
export default ProgramPanel;
