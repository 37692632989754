import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export type ScreensType = {
  screenName: string;
  viewPermission: boolean;
  editPermission: boolean;
  deletePermission: boolean;
};

export type GroupPermissions = {
  screens: ScreenPermission[];
  groupName: string;
  groupId: string;
  superSuperAdmin: boolean;
};

export type UserGroups = {
  groupName: string;
  screens: ScreenPermission[];
  employee: string[];
};

export type GroupType = {
  id: string;
  groupName: string;
  createdBy: string;
  employeeCount: number;
  groupDescription: string;
  programId: string;
  level: number;
};

export type GroupDTO = {
  groups: GroupType[];
  count: number;
};

export type ScreenNamesAccess = {
  id: string;
  isViewEnabled: boolean;
  isAddEnabled: boolean;
  isEditEnabled: boolean;
  isDeleteEnabled: boolean;
};

export type ScreenPermission = {
  id: string;
  screenBackendId: string;
  permissionId: string;
  viewPermission: boolean;
  addPermission: boolean;
  editPermission: boolean;
  deletePermission: boolean;
};

export type ScreenDTO = {
  id: string;
  screenBackendId: string;
  screenAccessPermission: ScreenNamesAccess;
};

export type GroupEmployees = {
  name: string;
  userId: string;
  groupName?: string;
  groupId?: string;
};

export type EmployeeDTO = {
  employees: GroupEmployees[];
};

export type EditGroups = {
  groupName: string;
  screens: ScreenPermission[];
  addEmployee: string[];
  removeEmployee: string[];
};

export type UserGroupsType = {
  groupName: string;
  groupId: string;
  createdBy: string;
  screens: ScreenPermission[];
  employees: GroupEmployees[];
};

export const getEmployeePermissions = async () => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GroupPermissions>(
      `/groups/permission`,
    ),
  );
};

export const addGroups = async (
  requestBody: UserGroups,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<UserGroups, ApiMessage>(
      `groups`,
      requestBody,
    ),
  );
};

export const getAllGroupsAPI = async (
  page: number,
  size: number,
  sort?: string,
  sortType?: string,
  searchquery?: string,
): Promise<GroupDTO> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GroupDTO>(
      `groups/all?page=${page}&size=${size}&sort=${sort}&sortType=${sortType}&search=${searchquery}`,
    ),
  );
};

export const getAllGroupsAPIForOnBoarding = async (): Promise<GroupDTO> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GroupDTO>('groups'),
  );
};

export const getAllScreens = async (
  level: number | null,
): // page: number,
// size: number,
// sort: string,
// sortType: string,
// searchquery: string,
Promise<ScreenDTO[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ScreenDTO[]>(
      // `screens?page=${page}&size=${size}&sort=${sort}&sortType=${sortType}&search=${searchquery}`,
      level === null ? `screens` : `screens?level=${level}`,
    ),
  );
};

export const getAllEmployeesWithGroupName = async (): Promise<EmployeeDTO> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EmployeeDTO>(`groups/employees`),
  );
};

export const getGroupsById = async (
  groupId: string,
): Promise<UserGroupsType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<UserGroupsType>(`groups/${groupId}`),
  );
};

export const editGroups = async (
  groupId: string,
  requestBody: EditGroups,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<EditGroups, ApiMessage>(
      `groups/${groupId}`,
      requestBody,
    ),
  );
};

export const deleteGroupsById = async (
  groupId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(`groups/${groupId}`),
  );
};
