import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AlertColor, Box, Card, Grid, Typography } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import ConsentSignature from './ConsentSignature';

import ConsentGRI from './ConsentJson/Consent-GRI.json';
import ConsentAssessment from './ConsentJson/Consent-Assessment.json';
import ConsentTelehealth from './ConsentJson/Consent-Telehealth.json';
import ConsentSavingsAcc from './ConsentJson/Consent-SavingsAcc.json';
import ConsentSspShare from './ConsentJson/Consent-SspShare.json';
import ConsentSspCommunicate from './ConsentJson/Consent-SspCommunicate.json';
import ConsentCommunicateWithMedical from './ConsentJson/Consent-CommunicateWithMedical.json';
import ConsentCommunicateWithFamily from './ConsentJson/Consent-CommunicateWithFamily.json';
import ConsentNoticeOfPrivacyPractices from './ConsentJson/Consent-NoticeofPrivacyPractices.json';
import ConsentLegalReleaseofRecords from './ConsentJson/Consent-LegalReleaseOfRecords.json';
import ConsentPWN from './ConsentJson/Consent-PWN.json';
import ConsentForChangeServiceDeliveryPlan from './ConsentJson/Consent-ServiceDeliveryPlan.json';

import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SingleLine from '../../components/formlib/SingleLine';

import ConsentGeneralInfo from './ConsentGeneralInfo';
import ConsentClientDetails, { Client } from './ConsentClientDetails';
import ConsentSspAgencyDetails from './ConsentSspAgencyDetails';
import { OptionType } from '../../utils/type';
import ConsentFormTextWithCheckbox from './ConsentFormTextwithCheckbox';

import {
  ConsentClientContact,
  ConsentClientSignature,
  ConsentDetailsType,
  updateConsent,
} from '../../services/configApi/forms/Consent/ConsentServices';

import { useNavigate } from 'react-router';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';

import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { CONSENT_DASHBOARD } from '../../routes/Routing';
import moment from 'moment';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import ConsentServiceDeliveryPlan from './ConsentServiceDeliveryPlan';
import dayjs from 'dayjs';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import ProgramPanel from '../../components/forms/ProgramPanel';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import _, { debounce } from 'lodash';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import ModalPopup from '../../components/formlib/ModalPopup';
import { ConsentContext } from './ConsentProvider';
import TranslateWidget, {
  setLanguage,
  translateTextArea,
} from '../../TranslateWidget';

const excludedTypes = [
  'Assessment',
  'Telehealth',
  'SavingsAcc',
  'NoticeOfPrivacyPractices',
  'PriorWrittenNoticeForm',
  'ConsentForChangeServiceDeliveryPlan',
];

const consentInterventionCheckBox = [
  {
    id: '1',
    dataLabelId: 'ConsentEvaOrAssessment.labelText1',
    defaultDataLabelId:
      'An evaluation/assessment to determine eligibility, strengths and needs of the child.',
  },
  {
    id: '2',
    dataLabelId: 'ConsentEvaOrAssessment.labelText2',
    defaultDataLabelId:
      'A family-directed assessment to determine concerns, priorities and resources.',
  },
  {
    id: '3',
    dataLabelId: 'ConsentEvaOrAssessment.labelText3',
    defaultDataLabelId:
      'A child assessment to determine ongoing strengths and needs.',
  },
];

const consentHSACheckBox = [
  {
    id: '1',
    dataLabelId: 'ConsentSavingsAcc.labelText1',
    defaultDataLabelId:
      'I do not have an HSA. No action is needed at this time.',
  },
  {
    id: '2',
    dataLabelId: 'ConsentSavingsAcc.labelText2',
    defaultDataLabelId:
      'I do have an HSA. I have been given a copy of this HSA alert and understand that I must contact my HSA plan administrator at this time.',
  },
];

const consentFormRefusal = [
  {
    id: '1',
    dataLabelId: 'Consentrefusal.labelText',
    defaultDataLabelId: 'I do not wish to give my permission',
  },
];

const consentFormPwnOthers = [
  {
    id: '1',
    dataLabelId: 'ConsentPwnOthers.labelText',
    defaultDataLabelId: 'Enter activity or action here',
  },
];

const consentPWNIFSP = [
  {
    id: '1',
    dataLabelId: 'ConsentPWN.servicesLabelText1',
    defaultDataLabelId: 'The EI program is proposing to have an IFSP Meeting.',
  },

  {
    id: '2',
    dataLabelId: 'ConsentPWN.servicesLabelText2',
    defaultDataLabelId:
      'The EI program is proposing to make a change in your child’s EI services.',
  },
  {
    id: '3',
    dataLabelId: 'ConsentPWN.servicesLabelText3',
    defaultDataLabelId:
      'A change in EI services that you requested has been declined by the EI program.The EI program must explain in writing the reason(s) why they have decided not to implement your requested change.',
  },
  {
    id: '4',
    dataLabelId: 'ConsentPWN.servicesLabelText4',
    defaultDataLabelId:
      'The EI program is proposing a Transition Planning Conference.',
  },
];

const consentPWNIdentification = [
  {
    id: '1',
    dataLabelId: 'ConsentForms.pwnIdentificationLabelText1',
    defaultDataLabelId:
      'The EI program is proposing to complete an evaluation/assessment to determine if your child is eligible for EI services.',
  },

  {
    id: '2',
    dataLabelId: 'ConsentForms.pwnIdentificationLabelText2',
    defaultDataLabelId:
      'Your child is eligible or continues to be eligible for EI services.',
  },
  {
    id: '3',
    dataLabelId: 'ConsentForms.pwnIdentificationLabelText3',
    defaultDataLabelId: 'Your child is not eligible for EI services.',
  },
];

export const ConsentSignatureRadioData = [
  {
    value: 'primary',
    labelId: 'ConsentForms.RadioLabelA',
    defaultLabel: 'Primary Guardian',
  },
  {
    value: 'legal',
    labelId: 'ConsentForms.RadioLabelB',
    defaultLabel: 'Legal Guardian',
  },
];

export interface ConsentJson {
  [version: string]: {
    ProgramView: boolean;
    ClientView: boolean;
    ReleaseClientView: boolean;
    InterventionView: boolean;
    RequestedView: boolean;
    UocView: boolean;
    UorView: boolean;
    SignatureView: boolean;
    DisclaimerView: boolean;
    PurposeText: PurposeText[];
    UocText: string;
    DisclaimerText: string;
  };
}

interface PurposeText {
  communication?: PurposeJson[];
  purpose?: PurposeJson[];
  id?: string;
  type?: string;
  dataLabelId?: string;
  defaultDataLabelId?: string;
}

interface PurposeJson {
  id: string;
  type: string;
  dataLabelId: string;
  defaultDataLabelId: string;
}

export const consentInitialValues: ConsentDetailsType = {
  type: '',
  name: '',
  consentId: '',
  version: 1,
  signedDate: '',
  revokedDate: '',
  status: 'Not Current',
  requestedDate: '',
  requester: {
    name: '',
    email: '',
    phone: '',
  },
  client: {
    id: '',
    name: '',
    dphId: '',
    gender: '',
    dob: '',
    address: '',
  },
  selectedContact: [],
  contact: [
    {
      id: 0,
      name: '',
      address: '',
      email: '',
      phone: '',
    },
  ],
  refusal: false,
  signatureType: 'primary',
  signature: [
    {
      id: '',
      name: '',
      relationship: '',
      email: '',
      signature: '',
      verbal: false,
      date: '',
      license: '',
    },
  ],
  programProposes: null,
  communicationMode: null,
  communicationComments: '',
  purpose: null,
  purposeComments: '',
  programDetails: {
    name: '',
    phoneNumber: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    faxNumber: '',
    website: '',
    imageUrl: '',
  },
  ifsp: null,
  serviceDeliveryPlan: {
    changes: '',
    isEligibleUntilThirdBirthday: '',
    eligibleTool: {
      id: '',
      label: '',
    },
    assessmentCompletionDate: null,
    reEstablishedDate: null,
    parentInitial: { id: '', label: '' },
    isChecked: false,
    notifiedParent: '',
    participated: false,
    agreeDisagree: '',
    exceptionList: '',
    consent: false,
  },
  eligibility: null,
  other: false,
  otherContactName: '',
  othersComment: '',
  summary: '',
  description: '',
  selectedLanguage: 'en',
};

const ConsentReleaseInfo = () => {
  // useEffect(() => {
  //   translate('consent');

  //   return () => {
  //     removeTranslate();
  //   };
  // }, []);

  const navigate = useNavigate();
  const { consentDataJSON, selectedVersionNo } = useContext(ConsentContext);

  const [consentData, setConsentData] = useState<ConsentDetailsType | null>(
    null,
  );
  const [selectedVersion, setSelectedVersion] = useState<number>(0);

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [contacts, setContacts] = useState<OptionType[]>([]);
  const [sspData, setSspData] = useState<PurposeText[]>([
    { communication: [], purpose: [] },
  ]);

  const [selectedContact, setSelectedContact] = useState<
    ConsentClientContact[]
  >([]);

  const [checked, setChecked] = useState(
    consentFormRefusal.map(() => ({
      checked: false,
    })),
  );

  const [othersChecked, setOthersChecked] = useState(
    consentFormPwnOthers.map(() => ({
      checked: false,
    })),
  );

  const [requestedChecked, setRequestedChecked] = useState(
    consentData && consentData?.communicationMode?.length
      ? consentData.communicationMode.map(() => ({ checked: false }))
      : [],
  );

  const [purposeChecked, setPurposeChecked] = useState(
    consentData && consentData?.purpose?.length
      ? consentData.purpose.map(() => ({ checked: false }))
      : [],
  );

  const [selectedClient, setSelectedClient] = useState<Client>({
    name: '',
    dob: '',
    dphId: '',
    address: '',
    gender: '',
  });
  const [titleTextInfo, setTitleTextInfo] = useState('ConsentGRI.TitleText');

  const [otherContactName, setOtherContactName] = useState(
    consentData && consentData.otherContactName,
  );

  const [consentName, setConsentName] = useState(
    'General Release of Information',
  );
  const [consentDetails, setConsentDetails] =
    useState<ConsentDetailsType>(consentInitialValues);

  const [consentBackupDetails, setConsentBackupDetails] =
    useState<ConsentDetailsType>(consentInitialValues);

  const [programView, setProgramView] = useState<boolean>(false);
  const [clientView, setClientView] = useState<boolean>(false);
  const [releaseClientView, setReleaseClientView] = useState<boolean>(false);
  const [uocView, setUocView] = useState<boolean>(false);
  const [interventionView, setInterventionView] = useState<boolean>(false);
  const [requestedView, setRequestedView] = useState<boolean>(false);
  const [uorView, setUorView] = useState<boolean>(false);
  const [signatureView, setSignatureView] = useState<boolean>(false);
  const [disclaimerView, setDisclaimerView] = useState<boolean>(false);
  const [btnName, setBtnName] = useState<boolean>(false);
  const [noppConsent, setNoppConsent] = useState<boolean>(false);
  const [legalConsent, setLegalConsent] = useState<boolean>(false);
  const [pwnForm, setPwnForm] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [understandingConcent, setUnderstandingConcent] = useState(
    ConsentGRI[1].UocText,
  );
  const [disclaimer, setDisclaimer] = useState(ConsentGRI[1].DisclaimerText);
  const [expiryDate, setExpiryDate] = useState<string>('');

  const [interventionCheckBoxView, setInterventionCheckBoxView] = useState(
    consentInterventionCheckBox,
  );
  const [interventionChecked, setInterventionChecked] = useState(
    consentInterventionCheckBox.map(() => ({
      checked: false,
    })),
  );
  const [ifspCheckBoxView, setIfspCheckBoxView] = useState(consentPWNIFSP);
  const [interventionPwnChecked, setInterventionPwnChecked] = useState(
    consentPWNIFSP.map(() => ({
      checked: false,
    })),
  );

  const [interventionSingleCheckBoxView, setInterventionSingleCheckBoxView] =
    useState(consentInterventionCheckBox);

  const [interventionPwnSingleChecked, setInterventionPwnSingleChecked] =
    useState(
      consentPWNIdentification.map(() => ({
        checked: false,
      })),
    );

  const [consentForChange, setConsentForChange] = useState<boolean>(false);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  const [dropDownValidationState, setDropDownValidationState] =
    useState<boolean>(false);
  const [
    dropDownOtherContactValidationState,
    setDropDownOtherContactValidationState,
  ] = useState<boolean>(false);

  const [
    disableConsentDueToAssessmentCompletionDate,
    setDisableConsentDueToAssessmentCompletionDate,
  ] = useState<boolean>(false);

  const [
    disableConsentDueToReEstablishDate,
    setDisableConsentDueToReEstablishDate,
  ] = useState<boolean>(false);

  const [selectedSignatureTypeValue, setSelectedSignatureTypeValue] =
    useState<string>('primary');

  const [selectedLanguageValue, setSelectedLanguageValue] =
    useState<string>('');

  const translationId = useRef('Consent');

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (consentDataJSON !== null) {
      setConsentData(consentDataJSON);
      setSelectedVersion(selectedVersionNo);
    }
  }, [consentDataJSON]);

  const handleDisableConsentDueToAssessmentCompletionDate = (
    value: boolean,
  ) => {
    setDisableConsentDueToAssessmentCompletionDate(value);
  };

  const handleDisableConsentDueToReEstablishDate = (value: boolean) => {
    setDisableConsentDueToReEstablishDate(value);
  };

  const getContacts = async (responseContact: ConsentClientContact[]) => {
    if (responseContact) {
      const contactList: OptionType[] = [];
      responseContact.forEach((contact) => {
        if (contact.type === 'Referral Contact') {
          contactList.push({
            id: contact.id.toString(),
            label: contact.name ?? 'Referral Contact',
          });
        } else {
          if (contact.type !== 'Family') {
            contactList.push({
              id: contact.id.toString(),
              label: contact.name,
            });
          } else if (consentData && consentData.selectedContact.length > 0) {
            const hasSelectedContact = consentData.selectedContact.some(
              (data) => {
                return data.toString() === contact.id.toString();
              },
            );
            if (hasSelectedContact) {
              contactList.push({
                id: contact.id.toString(),
                label: contact.name,
              });
            }
          }
        }
      });
      setContacts(contactList);
    }
    return [];
  };

  const getContactInfo = (value: OptionType | null) => {
    if (value) {
      if (consentData && consentData.contact.length > 0) {
        const valueFiltered = consentData.contact.filter(
          (data: ConsentClientContact) => {
            return data.id.toString() === value.id;
          },
        );

        valueFiltered.forEach((val: ConsentClientContact) => {
          setSelectedContact(
            (prevSelectedContact) =>
              prevSelectedContact && [
                ...prevSelectedContact,
                {
                  id: val.id,
                  name: val.name,
                  email: val.email,
                  phone: val.phone,
                  address: val.address,
                  type: val.type,
                },
              ],
          );
        });
      }
    }
  };

  const onRequestedChecked = (value: boolean, index: number) => {
    const updateAction = [...requestedChecked];
    updateAction[index].checked = value;
    if (updateAction[index].checked) {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        communicationMode:
          prevConsentDetails.communicationMode !== null
            ? [...prevConsentDetails.communicationMode, index]
            : [index],
      }));
    } else {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        communicationMode: (prevConsentDetails.communicationMode ?? []).filter(
          (i) => i !== index,
        ),
      }));
    }
    setRequestedChecked([...new Set(updateAction)]);
  };

  const onPurposeChecked = (value: boolean, index: number) => {
    const updateAction = [...purposeChecked];
    updateAction[index].checked = value;

    if (updateAction[index].checked) {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        purpose:
          prevConsentDetails.purpose !== null
            ? [...prevConsentDetails.purpose, index]
            : [index],
      }));
    } else {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        purpose: (prevConsentDetails.purpose ?? []).filter((i) => i !== index),
      }));
    }
    setPurposeChecked([...new Set(updateAction)]);
  };

  const onInterventionChecked = (value: boolean, index: number) => {
    const updateAction = [...interventionChecked];
    updateAction[index].checked = value;
    if (updateAction[index].checked) {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        programProposes:
          prevConsentDetails.programProposes !== null
            ? [...prevConsentDetails.programProposes, index]
            : [index],
      }));
    } else {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        programProposes: (prevConsentDetails.programProposes ?? []).filter(
          (i) => i !== index,
        ),
      }));
    }
  };

  //pwn
  const onIFSPPwnChecked = (value: boolean, index: number) => {
    const updateAction = [...interventionPwnChecked];
    updateAction[index].checked = value;

    setConsentDetails((prevConsentDetails) => {
      const updatedIfsp =
        prevConsentDetails.ifsp !== null &&
        prevConsentDetails.ifsp !== undefined
          ? [...prevConsentDetails.ifsp]
          : [];

      if (updateAction[index].checked) {
        updatedIfsp.push(index);
      } else {
        const indexToRemove = updatedIfsp.indexOf(index);
        if (indexToRemove !== -1) {
          updatedIfsp.splice(indexToRemove, 1);
        }
      }

      return {
        ...prevConsentDetails,
        ifsp: updatedIfsp,
      };
    });

    setInterventionPwnChecked(updateAction);
  };

  const onInterventionSingleChecked = (value: boolean, index: number): void => {
    const updatedAction = interventionPwnSingleChecked.map((v, i) => ({
      checked: i === index ? value : false,
    }));
    if (updatedAction[index].checked) {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        eligibility: [index],
      }));
    } else {
      setConsentDetails((prevConsentDetails) => ({
        ...prevConsentDetails,
        eligibility: (prevConsentDetails.eligibility ?? []).filter(
          (i) => i !== index,
        ),
      }));
    }
    setInterventionPwnSingleChecked(updatedAction);
  };

  const onOthersChecked = (value: boolean, index: number) => {
    const updateAction = [...othersChecked];
    updateAction[index].checked = value;

    setConsentDetails((prevConsentDetails) => ({
      ...prevConsentDetails,
      other: value,
      othersComment: value ? prevConsentDetails.othersComment : '',
    }));

    setOthersChecked(updateAction);
  };

  const onChecked = (value: boolean, index: number) => {
    const updateAction = [...checked];
    updateAction[index].checked = value;

    if (value) {
      if (consentData && selectedSignatureTypeValue === 'primary') {
        const filteredSignature = consentData.signature.filter(
          (sign: ConsentClientSignature) => {
            if (sign.isPrimary && sign.isLatest) return sign.isPrimary === true;
          },
        );

        setConsentDetails({
          ...consentDetails,
          revokedDate: new Date().toISOString(),
          refusal: value,
          status: 'Revoked',
          signature: filteredSignature.map((sign: ConsentClientSignature) => ({
            ...sign,
            signature: '',
            verbal: false,
            partitionKey: '',
            rowKey: '',
          })),
        });
      } else if (consentData && selectedSignatureTypeValue === 'legal') {
        const filteredSignature = consentData.signature.filter(
          (sign: ConsentClientSignature) => {
            if (sign.isLegal && sign.isLatest) return sign.isLegal === true;
          },
        );

        setConsentDetails({
          ...consentDetails,
          revokedDate: new Date().toISOString(),
          refusal: value,
          status: 'Revoked',
          signature: filteredSignature.map((sign: ConsentClientSignature) => ({
            ...sign,
            signature: '',
            verbal: false,
            partitionKey: '',
            rowKey: '',
          })),
        });
      }
    } else {
      if (consentDetails.consentId === '') {
        if (consentData && selectedSignatureTypeValue === 'primary') {
          const filteredSignature = consentData.signature.filter(
            (sign: ConsentClientSignature) => {
              if (sign.isPrimary) return sign.isPrimary === true;
            },
          );
          setConsentDetails({
            ...consentDetails,
            revokedDate: '',
            refusal: value,
            status: consentData.status,
            signature: filteredSignature,
          });
        } else if (consentData && selectedSignatureTypeValue === 'legal') {
          const filteredSignature = consentData.signature.filter(
            (sign: ConsentClientSignature) => {
              if (sign.isLegal) return sign.isLegal === true;
            },
          );
          setConsentDetails({
            ...consentDetails,
            revokedDate: '',
            refusal: value,
            status: consentData.status,
            signature: filteredSignature,
          });
        }
      } else if (
        consentData &&
        consentData.signatureType &&
        consentDetails.consentId !== ''
      ) {
        setSelectedSignatureTypeValue(consentData.signatureType);
        if (consentData.signatureType === 'primary') {
          const filteredSignature = consentData.signature.filter(
            (sign: ConsentClientSignature) =>
              sign.isPrimary && (sign.rowKey !== '' || sign.verbal === true),
          );
          setConsentDetails({
            ...consentDetails,
            revokedDate: '',
            refusal: value,
            status: consentData.status,
            signature: filteredSignature,
          });
        } else if (consentData.signatureType === 'legal') {
          const filteredSignature = consentData.signature.filter(
            (sign: ConsentClientSignature) =>
              sign.isLegal && (sign.rowKey !== '' || sign.verbal === true),
          );

          setConsentDetails({
            ...consentDetails,
            revokedDate: '',
            refusal: value,
            status: consentData.status,
            signature: filteredSignature,
          });
        }
      }
    }
    setChecked(updateAction);
  };

  useEffect(() => {
    if (consentData !== null) {
      setViews(consentData.type);
      const resultExpiry =
        consentData.type === 'NoticeOfPrivacyPractices' ||
        consentData.type === 'LegalReleaseofRecords' ||
        consentData.type === 'PriorWrittenNoticeForm'
          ? ''
          : getDateFormatedValue(consentData.signedDate?.toString() ?? '');
      setExpiryDate(resultExpiry);

      const resultRequested =
        consentData.requestedDate !== ''
          ? consentData.requestedDate
          : new Date().toISOString();
      const resultUpdateRequested =
        consentData.type === 'NoticeOfPrivacyPractices' ||
        consentData.type === 'LegalReleaseofRecords' ||
        consentData.type === 'PriorWrittenNoticeForm'
          ? ''
          : resultRequested;

      const resultSigned =
        consentData.signedDate !== ''
          ? consentData.signedDate
          : new Date().toISOString();

      let filterBasedOnSignatureType = [];
      if (consentData.type === 'PriorWrittenNoticeForm') {
        filterBasedOnSignatureType = consentData.signature;
      } else {
        if (consentData.consentId === '') {
          filterBasedOnSignatureType = consentData.signature.filter(
            (sign: ConsentClientSignature) => {
              if (
                consentData.signatureType === 'primary' ||
                consentData.signatureType === ''
              ) {
                setSelectedSignatureTypeValue('primary');
                return sign.isPrimary === true;
              } else if (consentData.signatureType === 'legal') {
                setSelectedSignatureTypeValue('legal');
                return sign.isLegal === true;
              }
            },
          );
        } else {
          filterBasedOnSignatureType = consentData.signature.filter(
            (sign: ConsentClientSignature) => {
              if (
                (consentData.signatureType === 'primary' ||
                  consentData.signatureType === '') &&
                sign.rowKey !== ''
              ) {
                setSelectedSignatureTypeValue('primary');
                return sign.isPrimary === true;
              } else if (
                consentData.signatureType === 'legal' &&
                sign.rowKey !== ''
              ) {
                setSelectedSignatureTypeValue('legal');
                return sign.isLegal === true;
              }
            },
          );
        }
      }

      const resultSignature = filterBasedOnSignatureType.map(
        (item: ConsentClientSignature) => {
          return {
            ...item,
            date: item.date ? item.date : '',
          };
        },
      );

      const statusCheck = consentData.status
        ? consentData.status === 'Revoked'
          ? 'Revoked'
          : consentData.status
        : 'Not Current';

      if (statusCheck === 'Revoked') {
        setIsScreenLocked(true);
      }

      setConsentDetails({
        ...consentData,
        status: statusCheck,
        signedDate: resultSigned,
        requestedDate: resultUpdateRequested,
        requester: {
          name: 'Thom',
          email: consentData.programDetails.email
            ? consentData.programDetails.email
            : '',
          phone: consentData.programDetails.phoneNumber
            ? consentData.programDetails.phoneNumber
            : '',
        },
        signature: resultSignature,
        summary: consentData.summary,
        description: consentData.description,
        othersComment: consentData.othersComment,
      });

      setConsentBackupDetails({
        ...consentData,
        status: statusCheck,
        signedDate: resultSigned,
        requestedDate: resultUpdateRequested,
        requester: {
          name: 'Thom',
          email: consentData.programDetails.email
            ? consentData.programDetails.email
            : '',
          phone: consentData.programDetails.phoneNumber
            ? consentData.programDetails.phoneNumber
            : '',
        },
        signature: resultSignature,
        summary: consentData.summary,
        description: consentData.description,
        othersComment: consentData.othersComment,
      });
      setLanguage(consentData.selectedLanguage);
      setOtherContactName(consentData.otherContactName);
      setOthersChecked([{ checked: consentData.other }]);
      setChecked([{ checked: consentData.refusal }]);
      setSelectedClient({
        name: consentData.client.name,
        dob: consentData.client.dob,
        dphId: consentData.client.dphId ? consentData.client.dphId : '',
        address: consentData.client.address ? consentData.client.address : '',
        gender: consentData.client.gender,
      });
      getContacts(consentData.contact);

      if (
        consentData.selectedContact !== null &&
        consentData.selectedContact.length > 0
      ) {
        const objectsArray: OptionType[] = consentData.selectedContact.map(
          (id: number | string) => ({
            id: id.toString(),
            label: '',
          }),
        );
        onSelectContact(objectsArray);
      }
    }
  }, [consentData]);

  const getDateFormatedValue = (locationDate: string | null) => {
    let originalDate: string;
    if (locationDate === '' || locationDate === null) {
      const currentUtcDate = new Date();
      currentUtcDate.setUTCFullYear(currentUtcDate.getUTCFullYear() + 1);

      originalDate = currentUtcDate.toISOString();
    } else {
      const currentUtcDate = new Date(locationDate);
      currentUtcDate.setUTCFullYear(currentUtcDate.getUTCFullYear() + 1);

      originalDate = currentUtcDate.toISOString();
    }

    return originalDate;
  };

  const setViews = (type: string) => {
    if (consentData && selectedVersion)
      switch (type) {
        case 'GRI': {
          setTitleTextInfo('ConsentGRI.TitleText');
          getRenderViews(ConsentGRI, selectedVersion);
          setConsentName('General Release of Information');
          break;
        }
        case 'Assessment': {
          setTitleTextInfo('ConsentEvaOrAssessment.TitleText');
          getRenderViews(ConsentAssessment, selectedVersion);
          setConsentName('Consent for Evaluation / Assessment');

          setInterventionCheckBoxView(consentInterventionCheckBox);
          const assessmentinputIndices = consentData.programProposes
            ? consentData.programProposes
            : [];
          const assessmentoutputArray = Array.from(
            { length: consentInterventionCheckBox.length },
            (__, index) => assessmentinputIndices?.includes(index),
          );

          setInterventionChecked(
            assessmentoutputArray.map((value) => ({ checked: value })),
          );
          break;
        }
        case 'Telehealth': {
          setTitleTextInfo('ConsentTelehealth.TitleText');
          getRenderViews(ConsentTelehealth, selectedVersion);
          setConsentName('Consent for Telehealth');
          break;
        }
        case 'SavingsAcc': {
          setTitleTextInfo('ConsentSavingsAcc.TitleText');
          getRenderViews(ConsentSavingsAcc, selectedVersion);
          setConsentName('Health Savings Account Alert');

          setInterventionCheckBoxView(consentHSACheckBox);
          const HSAInputIndices = consentData.programProposes
            ? consentData.programProposes
            : [];

          const outputArray = Array.from(
            { length: consentHSACheckBox.length },
            (__, index) => HSAInputIndices?.includes(index),
          );

          setInterventionChecked(
            outputArray.map((value) => ({ checked: value })),
          );
          break;
        }
        case 'SspShare':
          setTitleTextInfo('ConsentSspShare.TitleText');
          getRenderViews(ConsentSspShare, selectedVersion);
          setConsentName('SSP Consent to Share');
          break;
        case 'SspCommunicate': {
          setTitleTextInfo('ConsentSspCommunicate.TitleText');
          getRenderViews(ConsentSspCommunicate, selectedVersion);
          setConsentName('SSP Consent to Communicate');

          const communicationoutputArray = consentData.communicationMode
            ? Array.from(
                { length: 3 },
                (__, index) =>
                  consentData.communicationMode !== null &&
                  consentData.communicationMode.includes(index),
              )
            : [false, false, false];

          setRequestedChecked(
            communicationoutputArray.map((value) => ({ checked: value })),
          );

          const purposeoutputArray = consentData.purpose
            ? Array.from(
                { length: 4 },
                (__, index) =>
                  consentData.purpose !== null &&
                  consentData.purpose.includes(index),
              )
            : [false, false, false, false];

          setPurposeChecked(
            purposeoutputArray.map((value) => ({ checked: value })),
          );
          break;
        }
        case 'CommunicateWithMedical': {
          setTitleTextInfo('ConsentCommunicateWithMedical.TitleText');
          getRenderViews(ConsentCommunicateWithMedical, selectedVersion);
          setConsentName(
            'Consent to communicate with Medical Professionals using THOM Email',
          );
          break;
        }
        case 'CommunicateWithFamily': {
          setTitleTextInfo('ConsentCommunicateWithFamily.TitleText');
          getRenderViews(ConsentCommunicateWithFamily, selectedVersion);
          setConsentName(
            'Consent to communicate with Family using THOM email and ThomConnect',
          );
          break;
        }
        case 'NoticeOfPrivacyPractices': {
          setTitleTextInfo('ConsentNoticeOfPrivacyPractices.TitleText');
          getRenderViews(ConsentNoticeOfPrivacyPractices, selectedVersion);
          setConsentName('Notice of Privacy Practices');
          setNoppConsent(true);
          break;
        }
        case 'LegalReleaseofRecords': {
          setTitleTextInfo('ConsentLegalReleaseofRecords.TitleText');
          getRenderViews(ConsentLegalReleaseofRecords, selectedVersion);
          setConsentName('Legal Release of Records');
          setLegalConsent(true);
          break;
        }
        case 'PriorWrittenNoticeForm': {
          setTitleTextInfo('ConsentPriorWrittenNoticeForm.TitleText');
          getRenderViews(ConsentPWN, selectedVersion);
          setConsentName('Prior Written Notice Form (PWN)');
          setPwnForm(true);
          setIfspCheckBoxView(consentPWNIFSP);
          const PWNInputIndices = consentData && consentData.ifsp;

          const outputArray = PWNInputIndices
            ? Array.from({ length: 4 }, (__, index) =>
                PWNInputIndices?.includes(index),
              )
            : [false, false, false, false];

          setInterventionPwnChecked(
            outputArray.map((value) => ({ checked: value })),
          );
          setInterventionSingleCheckBoxView(consentPWNIdentification);
          const PWNIdentityInputIndices: number[] =
            consentData.eligibility ?? [];

          const initialCheckedState: { checked: boolean }[] = Array.from(
            { length: 3 },
            () => ({ checked: false }),
          );

          if (PWNIdentityInputIndices.length > 0) {
            initialCheckedState[PWNIdentityInputIndices[0]].checked = true;
          }

          setInterventionPwnSingleChecked(initialCheckedState);

          break;
        }
        case 'ConsentForChangeServiceDeliveryPlan': {
          setConsentForChange(true);
          setTitleTextInfo('ConsentForChangeServiceDeliveryPlan.TitleText');
          getRenderViews(ConsentForChangeServiceDeliveryPlan, selectedVersion);
          setConsentName('IFSP Consent');
          break;
        }
      }
  };

  const getRenderViews = (fileName: ConsentJson, consentVersion: number) => {
    // const consentVersions = consentVersion ? 1 : 1; // This needs to changed in further based on dynamic view
    console.log('consentVersion', consentVersion);
    const consentVersions = 1;
    setProgramView(fileName[consentVersions].ProgramView);
    setClientView(fileName[consentVersions].ClientView);
    setReleaseClientView(fileName[consentVersions].ReleaseClientView);
    setInterventionView(fileName[consentVersions].InterventionView);
    setRequestedView(fileName[consentVersions].RequestedView);
    setUocView(fileName[consentVersions].UocView);
    setUorView(fileName[consentVersions].UorView);
    setSignatureView(fileName[consentVersions].SignatureView);
    setDisclaimerView(fileName[consentVersions].DisclaimerView);
    setSspData(fileName[consentVersions].PurposeText);
    setUnderstandingConcent(fileName[consentVersions].UocText);
    setDisclaimer(fileName[consentVersions].DisclaimerText);
  };

  const onSelectContact = (values: OptionType[] | OptionType | null) => {
    if (Array.isArray(values)) {
      setDropDownValidationState(false);
      setSelectedContact([]);
      values.forEach((value) => {
        getContactInfo(value);
      });
    } else {
      getContactInfo(values);
    }
  };

  const onChangeContactText = () => {
    const filteredContacts: OptionType[] = [];
    consentDetails.contact.forEach((c: ConsentClientContact) => {
      filteredContacts.push({
        id: c.id.toString(),
        label: c.name,
      });
    });

    setContacts(filteredContacts);
  };

  const onSaveClick = (sign: string, id: number) => {
    const signatureDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss');

    setConsentDetails((prevConsentDetails) => {
      const updatedSignature = prevConsentDetails.signature.map(
        (item, index) => {
          if (index === id) {
            return {
              ...item,
              // id: '',
              signature: sign,
              date: noppConsent || legalConsent ? signatureDate : '',
              partitionKey: '',
              rowKey: '',
            };
          }
          return item;
        },
      );

      return {
        ...prevConsentDetails,
        status:
          prevConsentDetails.status === 'Revoked'
            ? prevConsentDetails.status
            : 'Current',
        signature: updatedSignature,
      };
    });
  };

  const onVerbalConsentClick = (value: boolean, index: number) => {
    const signatureDate = moment().utc().format('YYYY-MM-DDTHH:mm:ss');

    setConsentDetails((prevConsentDetails) => {
      const updatedSignature = prevConsentDetails.signature.map((item, id) => {
        if (id === index) {
          return {
            ...item,
            // id: '',
            verbal: value,
            date: noppConsent || legalConsent ? signatureDate : '',
          };
        }
        return item;
      });

      return {
        ...prevConsentDetails,
        status:
          prevConsentDetails.status === 'Revoked'
            ? prevConsentDetails.status
            : 'Current',
        signature: updatedSignature,
      };
    });
  };

  const handleSave = () => {
    toggleLoader(true);
    if (consentDetails.signature.length > 0) {
      const resultStatus = consentDetails.signature.some(
        (s) => s.signature !== '' || s.verbal || s.rowKey !== '',
      );

      const updatedSignature = consentDetails.signature.map((item) => ({
        ...item,
        date:
          (noppConsent || legalConsent) &&
          (item.signature !== '' || item.verbal)
            ? item.date
            : '',
      }));

      let checkValidation = false;
      if (
        consentData &&
        selectedContact.length > 0 &&
        !excludedTypes.includes(consentData.type)
      ) {
        consentDetails.contact.forEach((contact: ConsentClientContact) => {
          if (
            selectedContact.some((selected) => selected.id === contact.id) &&
            contact.type === 'Other' &&
            otherContactName === ''
          ) {
            checkValidation = true;
          }
        });

        if (checkValidation) {
          toggleLoader(false);
          setDropDownOtherContactValidationState(true);
        }
      } else {
        toggleLoader(false);
        setDropDownValidationState(true);
      }

      if (
        (consentData && excludedTypes.includes(consentData.type)) ||
        (!checkValidation && selectedContact.length > 0)
      ) {
        callAPIToSaveConsent(resultStatus, updatedSignature);
      }
    } else {
      setOpen(true);
      setToastrVariable('error');
      setToastrId('ConsentForms.parentNotFoundError');
      setToastrDefaultMessage(
        'No Guardian found for the client, Kindly add before you proceed',
      );
      toggleLoader(false);
    }
  };

  const callAPIToSaveConsent = (
    resultStatus: boolean,
    updatedSignature: ConsentClientSignature[],
  ) => {
    if (resultStatus) {
      const params: ConsentDetailsType = {
        type: consentDetails.type,
        name: consentName,
        consentId: consentDetails.consentId,
        version: selectedVersion,
        signedDate: consentDetails.signedDate,
        revokedDate: consentDetails.revokedDate,
        status: consentDetails.status,
        requestedDate: consentDetails.requestedDate,
        requester: {
          name: consentDetails.requester.name,
          email: consentDetails.requester.email,
          phone: consentDetails.requester.phone,
        },
        client: {
          id: consentDetails.client.id,
          name: consentDetails.client.name,
          dphId: consentDetails.client.dphId,
          gender: consentDetails.client.gender,
          dob: consentDetails.client.dob,
          address: consentDetails.client.address,
        },
        selectedContact: selectedContact
          ? selectedContact.map((item) => item.id)
          : [],
        contact: consentDetails.contact,
        refusal: consentDetails.refusal,
        signatureType: selectedSignatureTypeValue,
        signature: updatedSignature,
        programProposes: consentDetails.programProposes,
        communicationMode: consentDetails.communicationMode,
        communicationComments: '',
        purpose: consentDetails.purpose,
        purposeComments: '',
        programDetails: consentDetails.programDetails,
        ifsp: consentDetails.ifsp,
        serviceDeliveryPlan: {
          agreeDisagree: consentDetails.serviceDeliveryPlan.agreeDisagree,
          assessmentCompletionDate:
            consentDetails.serviceDeliveryPlan.assessmentCompletionDate ===
              '' || null
              ? dayjs().format('MM/DD/YYYY')
              : consentDetails.serviceDeliveryPlan.assessmentCompletionDate,
          changes: consentDetails.serviceDeliveryPlan.changes,
          consent: consentDetails.serviceDeliveryPlan.consent,
          eligibleTool: consentDetails.serviceDeliveryPlan.eligibleTool,
          exceptionList: consentDetails.serviceDeliveryPlan.exceptionList,
          isChecked: consentDetails.serviceDeliveryPlan.isChecked,
          isEligibleUntilThirdBirthday:
            consentDetails.serviceDeliveryPlan.isEligibleUntilThirdBirthday,
          notifiedParent: consentDetails.serviceDeliveryPlan.notifiedParent,
          parentInitial: consentDetails.serviceDeliveryPlan.parentInitial,
          participated: consentDetails.serviceDeliveryPlan.participated,
          reEstablishedDate:
            consentDetails.serviceDeliveryPlan.reEstablishedDate === '' || null
              ? dayjs().format('MM/DD/YYYY')
              : consentDetails.serviceDeliveryPlan.reEstablishedDate,
        },
        eligibility: consentDetails.eligibility,
        other: consentDetails.other,
        otherContactName: otherContactName ? otherContactName : '',
        othersComment: consentDetails.othersComment,
        summary: consentDetails.summary,
        description: consentDetails.description,
        selectedLanguage: selectedLanguageValue,
      };
      submitAPI(params);
    } else {
      if (consentData && consentData.type === 'PriorWrittenNoticeForm') {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('ConsentForms.signatureToasterErrorMsg');
        setToastrDefaultMessage('Staff Signature is Mandatory');
        toggleLoader(false);
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('ConsentForms.signatureToasterError');
        setToastrDefaultMessage('Signature/Verbal Consent is Mandatory');
        toggleLoader(false);
      }
    }
  };

  const submitAPI = useCallback(
    debounce((params: ConsentDetailsType) => {
      handleSubmit(params);
    }, 1000),
    [],
  );

  const handleSubmit = (params: ConsentDetailsType) => {
    toggleLoader(true);

    updateConsent(params.type, params.consentId, params, selectedVersionNo)
      .then((response) => {
        if (response) {
          setOpen(true);
          setToastrVariable('success');
          setToastrId('ConsentForms.submitToaster');
          setToastrDefaultMessage('Consent Form Successfully Saved.');
          setBtnName(true);
          setTimeout(() => {
            toggleLoader(false);
            localStorage.removeItem('consentDataJSON');
            localStorage.removeItem('selectedVersionNo');
            navigate(CONSENT_DASHBOARD);
          }, 1000);
        }
      })
      .catch((error) => {
        setOpen(true);
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('ConsentForms.concentUpdateError');
          setToastrDefaultMessage('Failed to update Consent details');
        }
      });
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleClear = () => {
    localStorage.removeItem('consentDataJSON');
    localStorage.removeItem('selectedVersionNo');
    navigate(CONSENT_DASHBOARD);
  };

  const handleStatus = (statusValue: string) => {
    let validatedStatus = '';
    if (statusValue.toLowerCase().includes('revoked')) {
      const filterRevoked =
        consentData && consentDetails.revokedDate === ''
          ? consentData.revokedDate
          : consentDetails.revokedDate;
      validatedStatus = 'Revoked'.concat(
        ' (' + moment(filterRevoked).format('MM/DD/YYYY') + ')',
      );
    } else {
      validatedStatus = statusValue;
    }

    return validatedStatus;
  };

  const handleContactOtherName = (value: string) => {
    setDropDownOtherContactValidationState(false);
    setOtherContactName(value);
  };

  const handleSelectedSignatureType = (value: string) => {
    if (consentDetails.status === 'Revoked') {
      if (value === 'primary') {
        const filteredSignature =
          consentData &&
          consentData.signature.filter((sign: ConsentClientSignature) => {
            if (sign.isPrimary && sign.isLatest) return sign.isPrimary === true;
          });
        if (filteredSignature) {
          setConsentDetails({
            ...consentDetails,
            revokedDate: new Date().toISOString(),
            refusal: true,
            status: 'Revoked',
            signature: filteredSignature.map(
              (sign: ConsentClientSignature) => ({
                ...sign,
                signature: '',
                verbal: false,
                partitionKey: '',
                rowKey: '',
                date: sign.date
                  ? moment(sign.date, 'YYYY-MM-DDTHH:mm:ss')
                      .utc(true)
                      .local()
                      .format('MM/DD/YYYY')
                  : '',
              }),
            ),
          });
        }
      } else if (value === 'legal') {
        const filteredSignature =
          consentData &&
          consentData.signature.filter((sign: ConsentClientSignature) => {
            if (sign.isLegal && sign.isLatest) return sign.isLegal === true;
          });

        if (filteredSignature) {
          setConsentDetails({
            ...consentDetails,
            revokedDate: new Date().toISOString(),
            refusal: true,
            status: 'Revoked',
            signature: filteredSignature.map(
              (sign: ConsentClientSignature) => ({
                ...sign,
                signature: '',
                verbal: false,
                partitionKey: '',
                rowKey: '',
                date: sign.date
                  ? moment(sign.date, 'YYYY-MM-DDTHH:mm:ss')
                      .utc(true)
                      .local()
                      .format('MM/DD/YYYY')
                  : '',
              }),
            ),
          });
        }
      }
    } else if (value === 'primary') {
      const filteredSignature =
        consentData &&
        consentData.signature.filter((sign: ConsentClientSignature) => {
          if (
            sign.isPrimary &&
            (sign.rowKey !== '' || sign.rowKey !== null || sign.verbal === true)
          )
            return sign.isPrimary === true;
        });
      if (filteredSignature) {
        setConsentDetails((prevConsentDetails) => ({
          ...prevConsentDetails,
          signature: filteredSignature,
        }));
      }
    } else {
      const filteredSignature =
        consentData &&
        consentData.signature.filter((sign: ConsentClientSignature) => {
          if (
            sign.isLegal &&
            (sign.rowKey !== '' || sign.rowKey !== null || sign.verbal === true)
          )
            return sign.isLegal === true;
        });

      if (filteredSignature) {
        setConsentDetails((prevConsentDetails) => ({
          ...prevConsentDetails,
          signature: filteredSignature,
        }));
      }
    }
    setSelectedSignatureTypeValue(value);
  };

  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <ModalPopup
        open={openModal}
        maxWidth={'xs'}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => handleClear()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
      <TranslateWidget
        screenId={translationId.current}
        initialLanguage={'en'}
        languageChangeCallback={(lang) => {
          setSelectedLanguageValue(lang);
        }}
      >
        {consentDetails && (
          <Box component="div">
            <Box component="main">
              <Box component="section">
                {!pwnForm && (
                  <Box component="div" className="rowContainer">
                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <Box className="flex__  align__items__center">
                          <CustomBackArrow
                            onClick={() => {
                              if (
                                _.isEqual(consentBackupDetails, consentDetails)
                              ) {
                                setOpenModal(false);
                                localStorage.removeItem('consentDataJSON');
                                localStorage.removeItem('selectedVersionNo');
                                navigate(CONSENT_DASHBOARD);
                              } else {
                                setOpenModal(true);
                              }
                            }}
                          />
                          <TitleText
                            className="flex__ pl-md"
                            labelid={titleTextInfo}
                            defaultlabel="General Release of Information"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {pwnForm && (
                  <Box component="div" className="rowContainer">
                    <Grid container>
                      <Grid item xs={6} lg={6}>
                        <Box className="flex__  align__items__center">
                          <CustomBackArrow
                            onClick={() => {
                              if (
                                _.isEqual(consentBackupDetails, consentDetails)
                              ) {
                                setOpenModal(false);
                                localStorage.removeItem('consentDataJSON');
                                localStorage.removeItem('selectedVersionNo');
                                navigate(CONSENT_DASHBOARD);
                              } else {
                                setOpenModal(true);
                              }
                            }}
                          />
                          <TitleText
                            className="flex__ pl-md"
                            labelid={titleTextInfo}
                            defaultlabel="General Release of Information"
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        lg={6}
                        className="flex__ align__items__center justifyContent-FlexEnd"
                      >
                        <Box component="div" className="flex__">
                          <MediumTypography
                            label={moment(consentDetails.signedDate)
                              .local()
                              .format('MM/DD/YYYY')}
                            fontSize="18px"
                            fontweight={800}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                <div
                  ref={parentRef}
                  style={{
                    pointerEvents: isScreenLocked ? 'none' : 'auto',
                    opacity: isScreenLocked ? '0.5' : '1 ',
                  }}
                  onKeyDownCapture={(e) => {
                    if (isScreenLocked) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  onFocus={() => {
                    if (isScreenLocked) {
                      if (parentRef.current) parentRef.current.focus();
                    }
                  }}
                >
                  <Card className="formCardview pt-sm">
                    {consentDetails.programDetails && (
                      <ProgramPanel program={consentDetails.programDetails} />
                    )}
                    <SingleLine className="width__100 mt-sm mb-sm" />
                    {programView && (
                      <Box component="div">
                        <ConsentGeneralInfo
                          consentDatePlaceholder={'ConsentForms.signedDate'}
                          consentDateDefaultPlaceholder={'Consent Date:'}
                          expiryDatePlaceholder={'ConsentForms.revokedDate'}
                          expiryDateDefaultPlaceholder={'Expiry Date:'}
                          requestedDate={
                            noppConsent || legalConsent
                              ? ''
                              : moment(consentDetails.requestedDate)
                                  .local()
                                  .format('MM/DD/YYYY')
                          }
                          requester={consentDetails.requester}
                          status={handleStatus(consentDetails.status)}
                          signedDate={moment(consentDetails.signedDate)
                            .local()
                            .format('MM/DD/YYYY')}
                          expiredDate={
                            noppConsent || pwnForm
                              ? ''
                              : moment(expiryDate).local().format('MM/DD/YYYY')
                          }
                          statusLabelId={'ConsentForms.status'}
                          statusDefaultLabelId={'Authorization Status:'}
                          requestedNameLabelId={'ConsentForms.requesterName'}
                          requestedNameDefaultLabelId={'Requested by:'}
                          requestedEmailLabelId={'ConsentForms.requesterEmail'}
                          requestedEmailDefaultLabelId={'Requester Email:'}
                          requestedDateLabelId={'ConsentForms.requestedDate'}
                          requestedDateDefaultLabelId={'Requested Date:'}
                          requestedPhoneLabelId={'ConsentForms.requesterPhone'}
                          requestedPhoneDefaultLabelId={'Requester Phone:'}
                        />
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {clientView && (
                      <Box component="div">
                        <ConsentClientDetails
                          titleLabel={'ConsentForms.clientTitle'}
                          defaultTitleLabel="Client Details"
                          searchClientLabel={
                            'ConsentForms.clientSearchPlaceholder'
                          }
                          defaultSearchClientLabel="Search and select client"
                          clients={selectedClient}
                          dphIdLabel={'ConsentForms.clientDPHID'}
                          dphIddefaultLabel={'Enrollment ID:'}
                          dobLabel={'ConsentForms.clientDob'}
                          dobDefaultLabel={'Date of Birth:'}
                          genderLabel={'ConsentForms.clientGender'}
                          genderDefaultLabel={'Sex:'}
                          addressLabel={'ConsentForms.clientAddress'}
                          addressDefaultLabel={'Address:'}
                        />
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {requestedView && (
                      <Box component="div">
                        <Box className="pt-sm pb-md">
                          <ConsentFormTextWithCheckbox
                            data={sspData[0].communication}
                            titleLabel="ConsentForms.communicationPurposeTitle"
                            defaultTitleLabel="Requested by"
                            value={requestedChecked}
                            onClickChange={onRequestedChecked}
                            inline={true}
                          />
                        </Box>
                        <SingleLine className="width__100 mt-sm mb-sm" />
                        <Box className="pt-sm pb-md">
                          <ConsentFormTextWithCheckbox
                            data={sspData[0].purpose}
                            titleLabel="ConsentForms.ConsentSspCommunicate.purposeTitle"
                            defaultTitleLabel="For the purpose of"
                            value={purposeChecked}
                            onClickChange={onPurposeChecked}
                            inline={true}
                          />
                        </Box>
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {releaseClientView && (
                      <Box component="div">
                        <ConsentSspAgencyDetails
                          contacts={selectedContact}
                          titleLabel={'ConsentForms.contactTitle'}
                          defaultTitleLabel={'Release client information to'}
                          searchContactLabel={'ConsentForms.labelPermissionTo'}
                          defaultSearchContactLabel={'Permission Granted to'}
                          contactData={contacts}
                          onSelect={onSelectContact}
                          onChangeText={onChangeContactText}
                          emailLabelId={'ConsentForms.contactEmail'}
                          emailDefaultLabelId={'Email:'}
                          addressLabelId={'ConsentForms.contactAddress'}
                          addressDefaultLabelId={'Address:'}
                          phoneLabelId={'ConsentForms.contactPhone'}
                          phoneDefaultLabelId={'Phone:'}
                          contactOtherName={
                            otherContactName ? otherContactName : ''
                          }
                          handleContactOtherName={handleContactOtherName}
                          dropDownValidation={dropDownValidationState}
                          dropDownOtherContactValidationState={
                            dropDownOtherContactValidationState
                          }
                        />
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {interventionView && !pwnForm && (
                      <Box component="div">
                        <Box className="pt-sm pb-md">
                          <ConsentFormTextWithCheckbox
                            data={interventionCheckBoxView}
                            titleLabel={'ConsentForms.purposeTitle'}
                            defaultTitleLabel={
                              'The early intervention program proposes to complete'
                            }
                            value={interventionChecked}
                            onClickChange={onInterventionChecked}
                            inline={false}
                          />
                        </Box>
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {uocView && (
                      <Box component="div">
                        <MediumTypography
                          fontSize="18px"
                          fontweight={600}
                          textColor="#2A4241"
                          labelid={
                            legalConsent
                              ? 'ConsentUOCTitle.legalConsent'
                              : noppConsent
                              ? 'ConsentUOCTitle.NoticeforPrivacy'
                              : pwnForm
                              ? 'ConsentUOCTitle.pwnForm'
                              : 'ConsentForms.understandingTitle'
                          }
                          defaultlabel={
                            legalConsent
                              ? 'Information Regarding Legal Release of Child Records'
                              : noppConsent
                              ? 'Notice of Privacy Practices For Children and Parents Receiving Services Through the Early Intervention Program'
                              : pwnForm
                              ? 'Understanding of Prior Written Notice form'
                              : 'Understanding of Consent'
                          }
                          className="pb-sm pt-md"
                        />
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: understandingConcent,
                          }}
                          sx={{
                            fontFamily: 'Lato-Regular',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        />
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {interventionView && pwnForm && (
                      <Box component="div">
                        <Box className="pt-sm pb-md">
                          <ConsentFormTextWithCheckbox
                            data={interventionSingleCheckBoxView}
                            titleLabel={'ConsentForms.pwnPurposeTitle'}
                            defaultTitleLabel={
                              'The activity or action requiring written notice (check all that apply)'
                            }
                            inline={false}
                            subTitleLabel="ConsentForms.pwnPurposeSubTitle1"
                            defaultSubTitleLabel="Eligibility / Identification"
                            subTitleRequired={true}
                            onClickChange={onInterventionSingleChecked}
                            value={interventionPwnSingleChecked}
                          />
                          <ConsentFormTextWithCheckbox
                            data={ifspCheckBoxView}
                            value={interventionPwnChecked}
                            onClickChange={onIFSPPwnChecked}
                            inline={false}
                            subTitleLabel="ConsentForms.pwnPurposeSubTitle2"
                            defaultSubTitleLabel="IFSP Meeting / IFSP services:"
                            subTitleRequired={true}
                          />
                        </Box>
                        <SingleLine className="width__100 mt-sm mb-sm" />
                        <Box className="pt-sm pb-md">
                          <ConsentFormTextWithCheckbox
                            data={consentFormPwnOthers}
                            titleLabel={'ConsentForms.pwnOthersTitle'}
                            defaultTitleLabel={'Others(describe):'}
                            inline={false}
                            onClickChange={onOthersChecked}
                            value={othersChecked}
                          />
                          {othersChecked.some((item) => item.checked) && (
                            <Box className="mt-md">
                              <textarea
                                id="othersComment"
                                className="translate"
                                placeholder={'Enter the description here'}
                                rows={5}
                                maxLength={3000}
                                value={consentDetails.othersComment}
                                onChange={(e) => {
                                  setConsentDetails({
                                    ...consentDetails,
                                    othersComment: e.target.value || '',
                                  });
                                  translateTextArea('othersComment');
                                }}
                                style={{
                                  fontFamily: 'Lato-Regular',
                                  fontStyle: 'normal',
                                  fontWeight: 500,
                                  fontSize: '14px',
                                  paddingTop: '10px',
                                  paddingLeft: '10px',
                                  border: '1px solid #ccc',
                                  outline: 'none',
                                  overflow: 'hidden',
                                  borderRadius: '4px',
                                  transition: 'border-color 0.3s',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  resize: 'none',
                                  overflowY: 'scroll',
                                  height: '80px',
                                  minHeight: '80px',
                                  minWidth: '80%',
                                  width: '80%',
                                }}
                                onFocus={(e) => {
                                  e.target.style.borderColor = '#00C6B8';
                                }}
                                onBlur={(e) => {
                                  e.target.style.borderColor = '#ccc';
                                }}
                              />
                            </Box>
                          )}
                        </Box>
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {pwnForm && (
                      <Box component="div">
                        <Box className="pt-xs pb-md">
                          <MediumTypography
                            labelid="ConsentForms.PwnSummary"
                            defaultlabel="Summary of IFSP Team’s Discussion: What is being proposed or declined? Why is the activity being proposed or declined?"
                            fontSize="18px"
                            fontweight={600}
                            className="mt-md mb-md"
                          />
                          <textarea
                            id="summary"
                            className="translate"
                            placeholder={'Enter summary here'}
                            rows={5}
                            maxLength={3000}
                            value={consentDetails.summary}
                            onChange={(e) => {
                              setConsentDetails({
                                ...consentDetails,
                                summary: e.target.value || '',
                              });
                              translateTextArea('summary');
                            }}
                            style={{
                              fontFamily: 'Lato-Regular',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              paddingTop: '10px',
                              paddingLeft: '10px',
                              border: '1px solid #ccc',
                              outline: 'none',
                              overflow: 'hidden',
                              borderRadius: '4px',
                              transition: 'border-color 0.3s',
                              alignItems: 'center',
                              justifyContent: 'center',
                              resize: 'none',
                              overflowY: 'scroll',
                              height: '80px',
                              minHeight: '80px',
                              minWidth: '80%',
                              width: '80%',
                            }}
                            onFocus={(e) => {
                              e.target.style.borderColor = '#00C6B8';
                            }}
                            onBlur={(e) => {
                              e.target.style.borderColor = '#ccc';
                            }}
                          />
                          <MediumTypography
                            labelid="ConsentForms.PwnInfo"
                            defaultlabel="Describe the information used to make this decision (such as evaluation/assessment results, reports, records, etc):"
                            fontSize="18px"
                            fontweight={600}
                            className="mt-md mb-md"
                          />
                          <textarea
                            id="description"
                            className="translate"
                            placeholder={
                              'Enter information used to make this decision here'
                            }
                            rows={5}
                            maxLength={3000}
                            value={consentDetails.description}
                            onChange={(e) => {
                              setConsentDetails({
                                ...consentDetails,
                                description: e.target.value || '',
                              });
                              translateTextArea('description');
                            }}
                            style={{
                              fontFamily: 'Lato-Regular',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: '14px',
                              paddingTop: '10px',
                              paddingLeft: '10px',
                              border: '1px solid #ccc',
                              outline: 'none',
                              overflow: 'hidden',
                              borderRadius: '4px',
                              transition: 'border-color 0.3s',
                              alignItems: 'center',
                              justifyContent: 'center',
                              resize: 'none',
                              overflowY: 'scroll',
                              height: '80px',
                              minHeight: '80px',
                              minWidth: '80%',
                              width: '80%',
                            }}
                            onFocus={(e) => {
                              e.target.style.borderColor = '#00C6B8';
                            }}
                            onBlur={(e) => {
                              e.target.style.borderColor = '#ccc';
                            }}
                          />
                        </Box>
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}
                    {uorView && (
                      <Box component={'div'}>
                        <Box className="pt-sm">
                          <ConsentFormTextWithCheckbox
                            data={consentFormRefusal}
                            titleLabel="ConsentForms.refusalTitle"
                            defaultTitleLabel="Understanding of Refusal"
                            value={checked}
                            onClickChange={onChecked}
                            inline={false}
                          />
                        </Box>
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}

                    {consentForChange && (
                      <Box component="div">
                        <ConsentServiceDeliveryPlan
                          SDPData={consentDetails.serviceDeliveryPlan}
                          contact={consentDetails.contact}
                          disableConsentDueToAssessmentCompletionDate={
                            handleDisableConsentDueToAssessmentCompletionDate
                          }
                          disableConsentDueToReEstablishDate={
                            handleDisableConsentDueToReEstablishDate
                          }
                          onConsentChange={(data) => {
                            setConsentDetails({
                              ...consentDetails,
                              serviceDeliveryPlan: data,
                            });
                          }}
                        />
                      </Box>
                    )}

                    {signatureView && (
                      <Box component="div">
                        {noppConsent ? (
                          <Box component="div">
                            <MediumTypography
                              fontSize="18px"
                              fontweight={600}
                              textColor="#2A4241"
                              labelid="ConsentForms.signatureTitleForNoticeofPrivacyPractices"
                              defaultlabel="Acknowledgment of Receipt of Notice of Privacy Practices"
                              className="pt-md pb-md"
                            />
                            <MediumTypography
                              textColor="#2A4241"
                              labelid="ConsentForms.signatureSubTitleForNoticeofPrivacyPractices"
                              defaultlabel="By signing below, I acknowledge that I have been provided with a copy of this Notice of Privacy Practices."
                            />
                          </Box>
                        ) : legalConsent ? (
                          <Box component="div">
                            <MediumTypography
                              textColor="#2A4241"
                              fontSize="16px"
                              fontweight={600}
                              labelid="ConsentForms.signatureSubTitleForLegalReleaseOfRecords"
                              defaultlabel="Who can authorize us to give information to others about your child?"
                              className="pt-md"
                            />
                          </Box>
                        ) : (
                          <MediumTypography
                            fontSize="18px"
                            fontweight={600}
                            textColor="#2A4241"
                            labelid={
                              pwnForm
                                ? 'ConsentForms.staffSignatureTitle'
                                : 'ConsentForms.signatureTitle'
                            }
                            defaultlabel={
                              pwnForm ? 'Staff Initials' : 'Signature'
                            }
                            className="pt-md"
                          />
                        )}

                        {!pwnForm ? (
                          <>
                            {consentData &&
                              consentData.signature.length > 0 && (
                                <Box className="pt-md">
                                  <CustomRadioButton
                                    data={ConsentSignatureRadioData}
                                    value={selectedSignatureTypeValue}
                                    onSelectItem={(value) => {
                                      if (value) {
                                        handleSelectedSignatureType(value);
                                      }
                                    }}
                                    disable={
                                      consentDetails.consentId !== '' &&
                                      consentDetails.status === 'Current'
                                    }
                                  />
                                </Box>
                              )}
                            {selectedSignatureTypeValue && (
                              <ConsentSignature
                                signatureData={consentDetails.signature}
                                staffSignature={false}
                                onSaveClick={onSaveClick}
                                verbalConsentValue={consentDetails.signature}
                                onVerbalConsentClick={onVerbalConsentClick}
                                defaultParentGuardianNoId="Guardian Name"
                                parentGuardianNoId="ConsentParentGuardianNoId"
                                relationshipWithChildId="ConsentRelationshipWithChild"
                                defaultRelationshipWithChildId="Guardian Type:"
                                emailId="ConsentEmail"
                                defaultEmailId="Email:"
                                dateId="dateLabel"
                                defaultDateId="Date:"
                                isDateRequired={
                                  noppConsent || legalConsent ? true : false
                                }
                                viewUpdateId="ConsentViewOrUpdateSignature"
                                defaultViewUpdateId="View/update signature"
                                addSignatureId="ConsentAddSignature"
                                defaultAddSignatureId="Add Signature"
                                verbalConsentId="ConsentVerbalConsent"
                                defaultVerbalConsentId="Verbal Consent"
                                parentSignatureId="ConsentParentSignature"
                                defaultParentSignatureId="Parent Signature"
                              />
                            )}
                          </>
                        ) : (
                          <ConsentSignature
                            signatureData={consentDetails.signature}
                            onSaveClick={onSaveClick}
                            staffSignature={true}
                            nameLabelId="ConsentForms.signatureName"
                            nameDefaultLabelId="Name:"
                            licenseLabelId="ConsentForms.signatureLicense"
                            licenseDefaultLabelId="License:"
                            viewUpdateId="ConsentViewOrUpdateSignature"
                            defaultViewUpdateId="View/update signature"
                            addSignatureId="ConsentAddSignature"
                            defaultAddSignatureId="Add Signature"
                            parentSignatureId={
                              pwnForm
                                ? 'ConsentStaffSignature'
                                : 'ConsentParentSignature'
                            }
                            defaultParentSignatureId={
                              pwnForm ? 'Staff Signature' : 'Parent Signature'
                            }
                          />
                        )}
                        <SingleLine className="width__100 mt-sm mb-sm" />
                      </Box>
                    )}

                    {disclaimerView && (
                      <Box component="div">
                        <MediumTypography
                          fontSize="18px"
                          fontweight={600}
                          textColor="#2A4241"
                          labelid="ConsentForms.disclaimerTitle"
                          defaultlabel="Disclaimer"
                          className="pb-sm pt-md"
                        />
                        <Typography
                          dangerouslySetInnerHTML={{ __html: disclaimer }}
                          sx={{
                            fontFamily: 'Lato-Regular',
                            fontSize: '14px',
                            fontWeight: 400,
                          }}
                        />
                      </Box>
                    )}

                    <ModalPopup
                      description="formUnsavedChangesMessage"
                      open={openModal}
                      onCancel={() => {
                        setOpenModal(false);
                      }}
                      onOk={() => {
                        handleClear();
                        setOpenModal(false);
                      }}
                      labelId1="Clientpage.Nobtn"
                      negativeActionLabel="No"
                      labelId2="Clientpage.Yesbtn"
                      positiveActionLabel="Yes"
                    />
                  </Card>
                  <Box className="p-lg pr-none justifyContent-FlexEnd flex__ mb-md">
                    <ButtonComponent
                      className="btn-primary btn-cancel mr-md"
                      labelId={!btnName ? 'ConsentCancel' : 'ConsentBack'}
                      defaultLabelId="Cancel"
                      variantType="outlined"
                      onClick={() => {
                        if (_.isEqual(consentBackupDetails, consentDetails)) {
                          setOpenModal(false);
                          localStorage.removeItem('consentDataJSON');
                          localStorage.removeItem('selectedVersionNo');
                          navigate(CONSENT_DASHBOARD);
                        } else {
                          setOpenModal(true);
                        }
                      }}
                    />
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      labelId="ConsentSave"
                      defaultLabelId="Save"
                      variantType="contained"
                      disabled={
                        disableConsentDueToReEstablishDate ||
                        disableConsentDueToAssessmentCompletionDate
                      }
                      onClick={handleSave}
                    />
                  </Box>
                </div>
              </Box>
            </Box>
          </Box>
        )}
      </TranslateWidget>
    </Box>
  );
};

export default ConsentReleaseInfo;
