import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

type ValueType = string | boolean | null;
interface Radioprops {
  labelid?: string;
  defaultlabelid?: string;
  value?: ValueType;
  valueA?: ValueType;
  valueB?: ValueType;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  labelidA?: string;
  defaultlabelidA?: string;
  labelidB?: string;
  defaultlabelidB?: string;
  disable?: boolean;
}
export default function RadioComponent(props: Readonly<Radioprops>) {
  const {
    labelid,
    defaultlabelid,
    value,
    valueA,
    valueB,
    handleChange,
    name,
    labelidA,
    defaultlabelidA,
    labelidB,
    defaultlabelidB,
    disable,
  } = props;
  return (
    <FormControl>
      {labelid && (
        <Typography
          sx={{
            fontFamily: 'Lato-Regular',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '17px',
            paddingBottom: '10px',
          }}
        >
          <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
        </Typography>
      )}
      <RadioGroup
        value={value}
        onChange={handleChange}
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={name}
      >
        <FormControlLabel
          value={valueA ?? true}
          control={
            <Radio
              sx={{
                paddingY: '0px',
                color: '#97A6A5',
                '&.Mui-checked': {
                  color: disable ? '#97A6A5' : '#008C82',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                },
              }}
              disabled={disable}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: 'Lato-Regular',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              <FormattedMessage
                id={labelidA}
                defaultMessage={defaultlabelidA}
              />
            </Typography>
          }
        />
        <FormControlLabel
          value={valueB ?? false}
          control={
            <Radio
              sx={{
                paddingY: '0px',
                color: '#97A6A5',
                '&.Mui-checked': {
                  color: disable ? '#97A6A5' : '#008C82',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                },
              }}
              disabled={disable}
            />
          }
          label={
            <Typography
              sx={{
                fontFamily: 'Lato-Regular',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '17px',
              }}
            >
              <FormattedMessage
                id={labelidB}
                defaultMessage={defaultlabelidB}
              />
            </Typography>
          }
        />
      </RadioGroup>
    </FormControl>
  );
}
