import { Avatar, Box, Card, Drawer, Grid, IconButton } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as Closegray } from '../../assets/images/x.svg';
import SelectComponent from '../../components/formlib/SelectComponent';
import Textinput from '../../components/formlib/Textinput';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { ReactComponent as Clock } from '../../assets/images/clock.svg';
import { ReactComponent as Calendar } from '../../assets/images/Calendar.svg';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import { Formik, FormikProps } from 'formik';
import { DateSelectArg } from '@fullcalendar/core';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import SmallTypography from '../../components/formlib/SmallTypography';
import AppTimePicker from '../../components/formlib/AppTimePicker';
import { BlockTimeData } from '../../services/configApi/employees/employeeServices';
import moment from 'moment';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import _ from 'lodash';
import ModalPopup from '../../components/formlib/ModalPopup';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';

interface BlockTimeModalProps {
  open: boolean;
  handleClose: () => void;
  dateSelected?: DateSelectArg;
  handlesubmit: (schedule: BlockTimeData) => void;
  rowData?: BlockTimeData;
}

interface DayOfWeek {
  label: string;
  value: number;
  shortLabel: string;
  selected: boolean;
  disabled: boolean;
}

const initialValue: BlockTimeData = {
  blockTimeId: '',
  blockingType: '',
  description: '',
  startDate: null,
  endDate: null,
  startTime: null,
  endTime: null,
  duration: null,
  occurrenceDays: [],
  occurrenceDates: [],
};
const daysOfWeek: DayOfWeek[] = [
  {
    label: 'SUNDAY',
    value: 0,
    shortLabel: 'S',
    selected: false,
    disabled: true,
  },
  {
    label: 'MONDAY',
    value: 1,
    shortLabel: 'M',
    selected: false,
    disabled: true,
  },
  {
    label: 'TUESDAY',
    value: 2,
    shortLabel: 'T',
    selected: false,
    disabled: true,
  },
  {
    label: 'WEDNESDAY',
    value: 3,
    shortLabel: 'W',
    selected: false,
    disabled: true,
  },
  {
    label: 'THURSDAY',
    value: 4,
    shortLabel: 'T',
    selected: false,
    disabled: true,
  },
  {
    label: 'FRIDAY',
    value: 5,
    shortLabel: 'F',
    selected: false,
    disabled: true,
  },
  {
    label: 'SATURDAY',
    value: 6,
    shortLabel: 'S',
    selected: false,
    disabled: true,
  },
];

const validationSchema = yup.object().shape({
  blockingType: yup.string().required('BlockTime.blockingTypeError'),
  description: yup.string().required('BlockingTime.descriptionError'),
  startDate: yup.date().required('BlockTime.startDateError').nullable(),
  endDate: yup.date().required('BlockTime.endDateError').nullable(),
  startTime: yup
    .string()
    .required('BlockTime.startTimeError')
    .nullable()
    .test(
      'end-date-not-equal',
      'BlockTime.invalidDateError',
      function (value, context) {
        if (
          new Date(new Date().setHours(0, 0, 0, 0)).getTime() !==
          new Date(context.parent.startDate).getTime()
        ) {
          return true;
        }
        if (value === undefined || value === null) {
          return true;
        }

        const currentDate = new Date();
        const startDate = new Date();
        const hrsAndmins = value.split(':');
        startDate.setHours(hrsAndmins[0] as unknown as number);
        startDate.setMinutes(hrsAndmins[1] as unknown as number);
        startDate.setSeconds(0);
        return dayjs(startDate).isAfter(currentDate);
      },
    ),
  endTime: yup.string().required('BlockTime.endTimeError').nullable(),
});
const BlockTypeData: string[] = ['Personal', 'Others'];

const drawerWidth = 440;
const ProfileBlockTimeModal: FC<BlockTimeModalProps> = ({
  open,
  handleClose,
  handlesubmit,
  rowData,
}) => {
  const [blockTime, setBlockTime] = useState<BlockTimeData>();
  const [action, setAction] = useState<'add' | 'edit'>('add');
  const [selectedDays, setSelectedDays] = useState<DayOfWeek[]>(daysOfWeek);
  const [initialSelectedDaysValues, setInitialSelectedDaysValues] =
    useState<DayOfWeek[]>(daysOfWeek);
  const formRef = useRef<FormikProps<BlockTimeData>>(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [chooseDaysError, setChooseDaysError] = useState(false);
  const { loader, toggleLoader } = React.useContext(
    LoaderContext,
  ) as LoaderContextType;

  useEffect(() => {
    if (rowData) {
      const newBlockData = JSON.parse(JSON.stringify(rowData)) as BlockTimeData;
      newBlockData.startDate = convertDateUTCtoLocal(
        newBlockData.startDate,
        newBlockData.startTime,
      );
      newBlockData.endDate = convertDateUTCtoLocal(
        newBlockData.endDate,
        newBlockData.endTime,
      );
      newBlockData.startTime = convertUTCtoLocal(
        newBlockData.startDate,
        newBlockData.startTime,
      );
      newBlockData.endTime = convertUTCtoLocal(
        newBlockData.endDate,
        newBlockData.endTime,
      );
      const enabledDays: number[] = [];
      if (newBlockData.startDate && newBlockData.endDate) {
        const startDateValue = dayjs(newBlockData.startDate, 'MM/DD/YYYY');
        const endDateValue = dayjs(newBlockData.endDate, 'MM/DD/YYYY');
        const diff = endDateValue.diff(startDateValue, 'days');

        for (let i = 0; i <= diff; i++) {
          enabledDays.push(startDateValue.add(i, 'day').day());
        }
      }
      if (newBlockData.occurrenceDays.length > 0) {
        const selectedBlockTimeDays: DayOfWeek[] = daysOfWeek.map((day) => {
          return {
            ...day,
            selected: newBlockData.occurrenceDays.includes(day.label),
            disabled: !enabledDays.includes(day.value),
          };
        });
        setInitialSelectedDaysValues(selectedBlockTimeDays);
        setSelectedDays(selectedBlockTimeDays);
        updateChooseDaysInFormik(selectedBlockTimeDays);
      }
      setBlockTime(newBlockData);
      setAction('edit');
    } else {
      setBlockTime(initialValue);
      setAction('add');
    }
  }, []);

  const handleFormSubmit = (value: BlockTimeData) => {
    if (!chooseDaysValidation(selectedDays)) {
      toggleLoader(true);
      handlesubmit(value);
    } else {
      setChooseDaysError(true);
    }
  };

  // const getNext5MinuteInterval = () => {
  //   const now = dayjs();
  //   const minutes = now.minute();
  //   const remainder = minutes % 5;
  //   const roundedMinutes =
  //     remainder === 0 ? minutes : minutes + (5 - remainder);
  //   return now.minute(roundedMinutes).second(0);
  // };

  const validateBlockTime = (values: BlockTimeData) => {
    const errors: Partial<BlockTimeData> = {};

    if (values.startTime && values.endTime) {
      const startTime = dayjs(values.startTime, 'HH:mm');
      const endTime = dayjs(values.endTime, 'HH:mm');

      if (startTime.isAfter(endTime) || startTime.isSame(endTime)) {
        errors.endTime = 'BlockTime.endTimeGreaterValidationError';
      }
    }
    if (values.startDate && values.endDate) {
      const startDate = dayjs(values.startDate, 'MM/DD/YYYY');
      const endDate = dayjs(values.endDate, 'MM/DD/YYYY');

      if (startDate.isAfter(endDate)) {
        errors.endDate = 'BlockTime.endDateValidationError';
      }
    }

    if (values.startDate) {
      if (dayjs(values.startDate).isBefore(dayjs().format('MM/DD/YYYY'))) {
        errors.startDate = 'validDate';
      }
    }

    if (datePickerMinDateMaxDateValidate(values.startDate)) {
      errors.startDate = 'validDate';
    }

    if (datePickerMinDateMaxDateValidate(values.endDate)) {
      errors.endDate = 'validDate';
    }
    return errors;
  };

  const updateDaysBasedOnDates = (startDate: string, endDate: string) => {
    const startDay = dayjs(startDate).day();
    const endDay = dayjs(endDate).day();

    const startDateObj = dayjs(startDate);
    const endDateObj = dayjs(endDate);

    const daysDifference = endDateObj.diff(startDateObj, 'days');
    return getUpdatedDays(daysDifference, startDay, endDay);
  };

  const getUpdatedDays = (
    daysDifference: number,
    startDay: number,
    endDay: number,
  ) => {
    return selectedDays.map((day) => {
      if (daysDifference >= 7) {
        return { ...day, disabled: false, selected: false };
      } else if (startDay <= endDay) {
        if (day.value >= startDay && day.value <= endDay) {
          return { ...day, disabled: false, selected: false };
        }
      } else {
        if (day.value >= startDay || day.value <= endDay) {
          return { ...day, disabled: false, selected: false };
        }
      }
      return { ...day, disabled: true, selected: false };
    });
  };

  useEffect(() => {
    if (
      blockTime &&
      blockTime.startDate !== null &&
      blockTime.endDate !== null
    ) {
      const updatedDays = updateDaysBasedOnDates(
        blockTime.startDate,
        blockTime.endDate,
      );
      if (updatedDays && action === 'add') {
        setSelectedDays(updatedDays);
        updateChooseDaysInFormik(updatedDays);
      }
    }
  }, [blockTime?.startDate, blockTime?.endDate]);

  const toggleDay = (index: number) => {
    if (!selectedDays[index].disabled) {
      const updatedDays = [...selectedDays];
      updatedDays[index] = {
        ...selectedDays[index],
        selected: !selectedDays[index].selected,
      };
      setSelectedDays(updatedDays);
      updateChooseDaysInFormik(updatedDays);
    }
  };

  const updateChooseDaysInFormik = (updatedSelectedDays: DayOfWeek[]) => {
    const selectedDayValues = updatedSelectedDays
      .filter((day) => day.selected)
      .map((day) => day.label);
    if (blockTime) {
      setBlockTime({
        ...blockTime,
        occurrenceDays: selectedDayValues,
      });
      formRef.current?.setValues({
        ...formRef.current?.values,
        occurrenceDays: selectedDayValues,
      });
    }
  };

  const chooseDaysValidation = (selectedChooseDays: DayOfWeek[]): boolean => {
    const hasEnabledDays = selectedChooseDays.some((day) => !day.disabled);
    const noDaysSelected = selectedChooseDays.every((day) => !day.selected);
    return hasEnabledDays && noDaysSelected;
  };

  const convertUTCtoLocal = (date: string | null, time: string | null) => {
    if (date !== null && time !== null) {
      const utcStartDateTime = moment(`${date} ${time}`, 'MM/DD/YYYY HH:mm')
        .utc(true)
        .local();

      return utcStartDateTime.format('HH:mm');
    }
    return '00:00';
  };

  const convertDateUTCtoLocal = (date: string | null, time: string | null) => {
    if (date !== null && time !== null) {
      const utcDates = moment(`${date} ${time}`, 'MM/DD/YYYY HH:mm')
        .utc(true)
        .local();
      return utcDates.format('MM/DD/YYYY');
    }
    return null;
  };

  const clearForm = () => {
    if (action === 'add') {
      setSelectedDays(daysOfWeek);
    } else if (action === 'edit') {
      setSelectedDays(initialSelectedDaysValues);
    }
    setBlockTime(formRef.current?.initialValues);
    formRef.current?.setValues(formRef.current?.initialValues);
    setOpenModal(false);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        paddingInline: '24px',
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      anchor={'right'}
      open={open}
      onClose={handleClose}
    >
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => clearForm()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '0px 0px 0px 0px !important',
        }}
      >
        <Card
          sx={{
            my: '0px',
            ml: '0px',
            mt: '0px',
            padding: '24px',
            height: '100%',
          }}
        >
          {blockTime && (
            <Formik
              initialValues={blockTime}
              innerRef={formRef}
              onSubmit={handleFormSubmit}
              validationSchema={validationSchema}
              validate={validateBlockTime}
            >
              {({
                handleSubmit,
                errors,
                touched,
                initialValues,
                values,
                setFieldValue,
              }) => (
                <Grid container style={{ gap: 15 }}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10.7}>
                      <MediumTypography
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '16px',
                          fontWeight: '600',
                          lineHeight: '36px',
                          fontFamily: 'Lato-Bold',
                        }}
                        labelid={
                          action === 'add'
                            ? 'BlockTime.blockTypeButton'
                            : 'Edit Block Time'
                        }
                        defaultlabel={
                          action === 'add'
                            ? 'Add Block Time'
                            : 'Edit Block Time'
                        }
                      />
                    </Grid>
                    <Grid item xs={1.3} textAlign={'right'}>
                      <IconButton onClick={handleClose}>
                        <Closegray />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                      <SelectComponent
                        names={BlockTypeData}
                        labelid="BlockTime.blockingType"
                        defaultlabelid="Select Blocking Type"
                        value={blockTime.blockingType}
                        handleChange={(value: string) => {
                          setFieldValue('blockingType', value);
                          setBlockTime({
                            ...blockTime,
                            blockingType: value,
                          });
                        }}
                      />
                      {errors.blockingType && touched.blockingType && (
                        <SmallTypography
                          sxProps={{ color: 'red', position: 'absolute' }}
                          labelId={errors.blockingType}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center" mt={2}>
                    <Grid item xs={12}>
                      <Textinput
                        name="description"
                        Value={blockTime.description}
                        labelid="AddNewSchedulerEvent.description"
                        defaultlabelid="Add Description"
                        inputProps={{
                          maxLength: 300,
                        }}
                        handlechange={(value: string) => {
                          setFieldValue('description', value);
                          setBlockTime({ ...blockTime, description: value });
                        }}
                      />
                      {errors.description && touched.description && (
                        <SmallTypography
                          sxProps={{ color: 'red', position: 'absolute' }}
                          labelId={errors.description}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center" mt={1}>
                    <Grid item xs={12}>
                      <MediumTypography
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '14px',
                          fontWeight: '600',
                          lineHeight: '36px',
                          fontFamily: 'Lato-Bold',
                        }}
                        labelid="BlockTime.startAndEndDate"
                        defaultlabel="Select Start and End Date"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    columnSpacing={1}
                    alignItems="center"
                  >
                    <Grid item xs={1}>
                      <Box className="svgIcon">
                        <Calendar />
                      </Box>
                    </Grid>
                    <Grid item xs={5.5}>
                      <DatePickerComponent
                        labelid="Start Date"
                        minDate={dayjs()}
                        disableFuture={false}
                        defaultlabelid="Start Date"
                        value={
                          blockTime.startDate
                            ? (dayjs(blockTime.startDate) as Dayjs)
                            : null
                        }
                        handlechange={(datevalue: Dayjs | null) => {
                          const formattedDate =
                            dayjs(datevalue).format('MM/DD/YYYY');

                          if (blockTime.endDate !== null) {
                            const updatedDays = updateDaysBasedOnDates(
                              formattedDate,
                              blockTime.endDate,
                            );
                            setSelectedDays(updatedDays);
                            updateChooseDaysInFormik(updatedDays);
                          }
                          // setFieldValue('startTime', null);
                          // setFieldValue('endTime', null);
                          // formRef.current?.setErrors({
                          //   ...errors,
                          //   startDate: errors.startDate,
                          //   startTime: errors.startTime,
                          //   endTime: errors.endTime,
                          // });
                          if (datevalue === null) {
                            setBlockTime({
                              ...blockTime,
                              startDate: null,
                            });
                            setFieldValue('startDate', null);
                          } else {
                            setBlockTime({
                              ...blockTime,
                              startDate: formattedDate,
                            });
                            setFieldValue('startDate', formattedDate);
                          }

                          // if (formattedDate === dayjs().format('MM/DD/YYYY')) {
                          //   setFieldValue(
                          //     'startTime',
                          //     getNext5MinuteInterval().format('HH:mm'),
                          //   );
                          // }
                        }}
                      />

                      {errors.startDate && touched.startDate && (
                        <SmallTypography
                          sxProps={{ color: 'red', position: 'absolute' }}
                          labelId={errors.startDate}
                        />
                      )}
                    </Grid>
                    <Grid item xs={5.5}>
                      <DatePickerComponent
                        labelid="End Date"
                        minDate={
                          blockTime.startDate !== null
                            ? (dayjs(blockTime.startDate) as Dayjs)
                            : dayjs()
                        }
                        disableFuture={false}
                        defaultlabelid="End Date"
                        value={
                          blockTime.endDate
                            ? (dayjs(blockTime.endDate) as Dayjs)
                            : null
                        }
                        handlechange={(datevalue: Dayjs | null) => {
                          const formattedDate =
                            dayjs(datevalue).format('MM/DD/YYYY');

                          if (blockTime.startDate !== null) {
                            const updatedDays = updateDaysBasedOnDates(
                              blockTime.startDate,
                              formattedDate,
                            );
                            setSelectedDays(updatedDays);
                            updateChooseDaysInFormik(updatedDays);
                          }
                          if (datevalue === null) {
                            setBlockTime({
                              ...blockTime,
                              endDate: null,
                            });
                            setFieldValue('endDate', null);
                          } else {
                            setBlockTime({
                              ...blockTime,
                              endDate: formattedDate,
                            });
                            setFieldValue('endDate', formattedDate);
                          }
                        }}
                      />

                      {errors.endDate && touched.endDate && (
                        <SmallTypography
                          sxProps={{ color: 'red', position: 'absolute' }}
                          labelId={errors.endDate}
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                      <MediumTypography
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '14px',
                          fontWeight: '600',
                          lineHeight: '36px',
                          fontFamily: 'Lato-Bold',
                        }}
                        labelid="BlockTime.startAndEndTime"
                        defaultlabel="Select Start and End Time"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    columnSpacing={1}
                    alignItems="center"
                  >
                    <Grid item xs={1}>
                      <Box className="svgIcon">
                        <Clock style={{ width: '20px', height: '20px' }} />{' '}
                      </Box>
                    </Grid>
                    <Grid item xs={5.5}>
                      <AppTimePicker
                        value={
                          blockTime.startTime === null
                            ? null
                            : dayjs(blockTime.startTime, 'hh:mm')
                        }
                        onTimeChange={(text) => {
                          if (text === null) {
                            setBlockTime({
                              ...blockTime,
                              startTime: null,
                            });
                            setFieldValue('startTime', null);
                          } else {
                            setBlockTime({
                              ...blockTime,
                              startTime: text.format('HH:mm'),
                            });
                            setFieldValue('startTime', text.format('HH:mm'));
                          }
                        }}
                        sxButtonProps={{
                          padding: '16px 40px 16px 8px',
                          width: '100%',
                        }}
                        name="Start Time*"
                      />
                      {errors.startTime && touched.startTime && (
                        <SmallTypography
                          sxProps={{ color: 'red', position: 'absolute' }}
                          labelId={errors.startTime}
                        />
                      )}
                    </Grid>
                    <Grid item xs={5.5}>
                      <AppTimePicker
                        value={
                          blockTime.endTime === null
                            ? null
                            : dayjs(blockTime.endTime, 'hh:mm')
                        }
                        onTimeChange={(text) => {
                          if (text === null) {
                            setBlockTime({
                              ...blockTime,
                              endTime: null,
                            });
                            setFieldValue('endTime', null);
                          } else {
                            setBlockTime({
                              ...blockTime,
                              endTime: text.format('HH:mm'),
                            });
                            setFieldValue('endTime', text.format('HH:mm'));
                          }
                        }}
                        name="End Time*"
                        sxButtonProps={{
                          padding: '16px 40px 16px 8px',
                          width: '100%',
                        }}
                      />
                      {errors.endTime && touched.endTime && (
                        <SmallTypography
                          sxProps={{ color: 'red', position: 'absolute' }}
                          labelId={errors.endTime}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12}>
                      <MediumTypography
                        sxProps={{
                          color: '#2A4241',
                          fontSize: '14px',
                          fontWeight: '600',
                          lineHeight: '36px',
                          fontFamily: 'Lato-Bold',
                        }}
                        labelid="BlockTime.chooseDays"
                        defaultlabel="Choose Days"
                      />
                    </Grid>
                  </Grid>

                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      {selectedDays.map((day, index) => (
                        <Box key={index}>
                          <Avatar
                            sx={{
                              width: 30,
                              height: 30,
                              backgroundColor: day.selected
                                ? '#008C82'
                                : '#FFFF',
                              color: day.selected
                                ? '#FFFF'
                                : day.disabled
                                ? '#97A6A5'
                                : '#2A4241',
                              cursor: day.disabled ? 'not-allowed' : 'pointer',
                              border: day.selected
                                ? '0px'
                                : '2px solid #CFDEDE',
                              fontSize: '12px',
                              margin: '0px 10px',
                            }}
                            onClick={() => {
                              toggleDay(index);
                            }}
                          >
                            {day.shortLabel}
                          </Avatar>
                        </Box>
                      ))}
                    </Grid>
                    {chooseDaysError && chooseDaysValidation(selectedDays) && (
                      <SmallTypography
                        sxProps={{
                          color: 'red',
                          marginLeft: '10px 0px 0px 10px',
                        }}
                        labelId="BlockTime.chooseDaysError"
                      />
                    )}
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    alignItems="right"
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Grid item sx={{ marginRight: '16px' }}>
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        variantType="contained"
                        labelId="AddNewSchedulerEvent.cancelButton"
                        defaultLabelId="Cancel"
                        onClick={() => {
                          if (_.isEqual(initialValues, values)) {
                            handleClose();
                          } else {
                            setOpenModal(true);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId={
                          action === 'add'
                            ? 'BlockTime.addButton'
                            : 'clientpage.Update'
                        }
                        defaultLabelId={action === 'add' ? 'Add' : 'Update'}
                        onClick={() => {
                          setChooseDaysError(true);
                          handleSubmit();
                        }}
                        loading={loader}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Formik>
          )}
        </Card>
      </Box>
    </Drawer>
  );
};

export default ProfileBlockTimeModal;
