import { TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import {
  OutcomesProgressReviewDataType,
  progressRatingsList,
} from './ChildandFamilyIFSPOutcomes';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import dayjs from 'dayjs';

interface OutcomesProgressInfoTabelRowType {
  data: OutcomesProgressReviewDataType;
}

const OutcomesProgressInfoTabelRow: FC<OutcomesProgressInfoTabelRowType> = ({
  data,
}) => {
  return (
    <TableRow>
      <TableCell>
        <TooltipTableCell
          value={data.date ? dayjs(data.date).format('DD/MM/YYYY') : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.progressDesc ? data.progressDesc : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            progressRatingsList.find((x) => x.id === data.progressRating)
              ?.label || '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default OutcomesProgressInfoTabelRow;
