import { Box, TableCell, TableRow } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { ReactComponent as EditIcon } from '../../assets/images/highlight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import { ReactComponent as MessageIcon } from '../../assets/images/messageIcon.svg';
import ActivityNoteModal from '../../components/formlib/modal/ActivityNoteModal';
import moment from 'moment';
import { TimeOffData } from '../../services/myTimeoff/MyTimeoffApi';
import { GetTimeOffListType } from './MyTimeOff';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface MyTimeOff {
  data: GetTimeOffListType;
  onEdit: (data: TimeOffData) => void;
  onDeleteMyTimeOff: (id: string) => void;
}
const MyTimeOffTableRow: FC<MyTimeOff> = ({
  data,
  onEdit,

  onDeleteMyTimeOff,
}) => {
  let isCurrentTimeBetween: boolean = moment().isBetween(
    data.startDate,
    data.endDate,
  );
  let isCurrentTimePast: boolean = moment().isAfter(data.endDate);
  const isApproved = data.status === 'Approved';
  const getDates = () => {
    if (data.durationType === 'days') {
      const startDate = moment(data.startDate, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .local()
        .format('MM/DD/YYYY');
      const startDateTime = moment(
        `${startDate} "00:00:00"`,
        'MM/DD/YYYY HH:mm:ss',
      );

      const endDate = moment(data.endDate, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .local()
        .format('MM/DD/YYYY');
      const endDateTime = moment(
        `${endDate} "23:59:59"`,
        'MM/DD/YYYY HH:mm:ss',
      );

      isCurrentTimeBetween =
        startDateTime.isBefore(moment()) && endDateTime.isAfter(moment());
      isCurrentTimePast = endDateTime.isBefore(moment());
      return `${startDateTime.format('MMM DD, YYYY')} to ${endDateTime.format(
        'MMM DD, YYYY',
      )} `;
    } else {
      const startDate = moment(data.startDate, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .local()
        .format('MM/DD/YYYY');

      const startTime = moment(data.startDate, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .local()
        .format('HH:mm:ss');
      const startDateTime = moment(
        `${startDate} ${startTime}`,
        'MM/DD/YYYY HH:mm:ss',
      );
      const endDate = moment(data.endDate, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .local()
        .format('MM/DD/YYYY');
      const endTime = moment(data.endDate, 'YYYY-MM-DDTHH:mm')
        .utc(true)
        .local()
        .format('HH:mm:ss');
      const endDateTime = moment(
        `${endDate} ${endTime}`,
        'MM/DD/YYYY HH:mm:ss',
      );

      isCurrentTimeBetween =
        startDateTime.isBefore(moment()) && endDateTime.isAfter(moment());
      isCurrentTimePast = endDateTime.isBefore(moment());
      return `${startDateTime.format('MMM DD, YYYY')}`;
    }
  };

  const [openNoteModel, setOpenNoteModel] = useState(false);
  const handleOpenNoteModal = () => {
    if (data.reason) {
      setOpenNoteModel(true);
    }
  };

  const handleCloseNoteModal = () => {
    setOpenNoteModel(false);
  };
  const handleEdit = () => {
    onEdit(data);
  };

  useEffect(() => {
    getDates();
  }, []);
  return (
    <>
      {openNoteModel && data.reason && (
        <ActivityNoteModal
          closeOnBackdropClick={true}
          maxLength={2000}
          open={openNoteModel}
          note={data.reason ?? data.reason}
          handleClose={handleCloseNoteModal}
          updateNote={() => {}}
          hideUpdateButton={true}
        />
      )}
      <TableRow>
        <TableCell>
          <MediumTypography
            label={moment(data.appliedDate, 'YYYY-MM-DDTHH:mm')
              .utc(true)
              .local()
              .format('MM/DD/YYYY')}
          />
        </TableCell>

        <TableCell>
          <MediumTypography label={data.leaveName} />
        </TableCell>
        {data.durationType === 'days' && (
          <TableCell>
            <Box display={'flex'}>
              <MediumTypography label={getDates()} />
              <MediumTypography
                textColor="#00C6B8"
                sxProps={{ marginLeft: '2px' }}
                label={`(${data.totalDays} day(s))`}
              />
            </Box>
          </TableCell>
        )}
        {data.durationType === 'hours' && (
          <TableCell>
            <Box display={'flex'}>
              <MediumTypography label={getDates()} />
              <MediumTypography
                textColor="#00C6B8"
                sxProps={{ marginLeft: '2px' }}
                label={`(${data.noOfHrs} Hour(s))`}
              />
            </Box>
          </TableCell>
        )}
        <TableCell>
          <MediumTypography
            label={data.status}
            textColor={
              data.status === 'Approved'
                ? '#37D183'
                : data.status === 'Pending'
                ? '#F9B803'
                : '#EB4C60'
            }
          />
        </TableCell>
        <TableCell>
          <Box
            sx={{
              cursor: 'pointer',
              opacity: data.reason ? 1 : 0.5,
              marginTop: '8px',
            }}
          >
            <ActionToolTip labelId="myTimeOffReason" defaultLabel="Reason">
              <MessageIcon
                stroke={'#97A6A5'}
                onClick={() => handleOpenNoteModal()}
              />
            </ActionToolTip>
          </Box>
        </TableCell>
        {checkPermissionForFeature('backend.time_off', 'editPermission') ||
        checkPermissionForFeature('backend.time_off', 'deletePermission') ? (
          <TableCell>
            <Box display={'flex'} sx={{ marginTop: '8px' }}>
              {checkPermissionForFeature(
                'backend.time_off',
                'editPermission',
              ) && (
                <Box
                  sx={{
                    cursor: 'pointer',
                    opacity:
                      isCurrentTimeBetween ||
                      isCurrentTimePast ||
                      isApproved ||
                      data.durationType === 'days'
                        ? 0.5
                        : 1,
                    pointerEvents:
                      isCurrentTimeBetween ||
                      isCurrentTimePast ||
                      isApproved ||
                      data.durationType === 'days'
                        ? 'none'
                        : 'auto',
                    marginRight: '16px',
                  }}
                >
                  <ActionToolTip labelId="MyTimeOffEdit" defaultLabel="Edit">
                    <EditIcon onClick={handleEdit} />
                  </ActionToolTip>
                </Box>
              )}
              {checkPermissionForFeature(
                'backend.time_off',
                'deletePermission',
              ) && (
                <Box
                  sx={{
                    cursor: 'pointer',
                    opacity: isCurrentTimePast ? 0.5 : 1,
                    pointerEvents: isCurrentTimePast ? 'none' : 'auto',
                  }}
                >
                  <ActionToolTip
                    labelId="MyTimeOffDelete"
                    defaultLabel="Delete"
                  >
                    <DeleteIcon onClick={() => onDeleteMyTimeOff(data.id)} />
                  </ActionToolTip>
                </Box>
              )}
            </Box>
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
};
export default MyTimeOffTableRow;
