import { Program } from '../../pages/employees/types';
import {
  EmployeeLookUpType,
  ProgramManagmentType,
  ProgramType,
} from '../../utils/type';
import { ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export type AddProgramList = {
  clients: ProgramManagmentType[];
  totalClientsCount: number;
};

export type EmployeeLookup = {
  employees: EmployeeLookUpType[];
  employeeCount: number;
};

export const getAllProgramManagmentList = async (
  page: number,
  size: number,
  sort: string,
  sortType: string,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<AddProgramList>(
      `program_management/list?page=${page}&size=${size}&sort=${sort}&sortType=${sortType}`,
    ),
  );
};

export const getAllPrograms = async (): Promise<Program[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Program[]>('program_management/all'),
  );
};

export const addProgramManagment = async (
  params: ProgramType,
  file: File | null,
): Promise<ProgramType> => {
  const formData = new FormData();
  if (file != null) {
    formData.append('file', file);
  } else {
    const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    formData.append('file', emptyFile); 
  }
  formData.append('request', JSON.stringify(params));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `program_management`,
      formData,
    ),
  );
};

export const editProgramManagment = async (
  params: ProgramType,
  programId: number,
  file: File | null,
): Promise<ProgramType> => {
  const formData = new FormData();
  if (file != null) {
    formData.append('file', file);
  } else {
    const emptyFile = new File([], 'empty.txt', { type: 'text/plain' });
    formData.append('file', emptyFile); 
  }
  formData.append('request', JSON.stringify(params));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `program_management?id=${programId}`,
      formData,
    ),
  );
};

export const getProgramBasedId = async (id: number) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ProgramType>(
      `program_management?id=${id}`,
    ),
  );
};

export const getProgramDetails = async () => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ProgramType>(`program_management`),
  );
};
