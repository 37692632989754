import {
  BaseSingleInputFieldProps,
  DatePicker,
  DatePickerProps,
  DateValidationError,
  FieldSection,
  LocalizationProvider,
  UseDateFieldProps,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import { ReactComponent as BackArrow } from '../../../assets/images/calenderBackArrow.svg';
import { ReactComponent as FrontArrow } from '../../../assets/images/calenderFrontArrow.svg';
import { ReactComponent as Calender } from '../../../assets/images/sidebarCalendar.svg';

interface TextFieldProps
  extends UseDateFieldProps<Dayjs, false>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      false,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface CalendarWeekProps {
  date: Dayjs;
  onDateSelect: (newDate: Dayjs) => void;
  disableFutureDate?: boolean;
}

const CustomTextField: FC<TextFieldProps> = (props) => {
  const { label, id, setOpen, InputProps: { ref } = {} } = props;

  return (
    <Box onClick={() => setOpen?.((prev) => !prev)} display={'flex'}>
      <TextField
        id={id}
        ref={ref}
        value={label}
        className={'date-text-field'}
        sx={{
          '& fieldset': { border: 'none' },
          '& .MuiOutlinedInput-root': {
            height: '36px',
            fontFamily: 'Lato-Regular',
            fontSize: '14px',
            cursor: 'pointer',
          },
        }}
      />
      <Box sx={{ display: 'flex', cursor: 'pointer' }} alignItems={'center'}>
        <Calender />
      </Box>
    </Box>
  );
};

const CustomDatePicker = (
  props: Omit<DatePickerProps<Dayjs>, 'open' | 'onOpen' | 'onClose'>,
) => {
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      {...props}
      slots={{ ...props.slots, field: CustomTextField }}
      slotProps={{
        ...props.slotProps,
        field: { setOpen } as never,
      }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};

const WeekDatePicker: FC<CalendarWeekProps> = (props) => {
  const { date, onDateSelect, disableFutureDate } = props;
  const [startDate, setStartDate] = useState<Dayjs>(date);
  const [endDate, setEndDate] = useState<Dayjs>(date);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(date));

  useEffect(() => {
    const newStartDate = dayjs(selectedDate).date(
      selectedDate.date() - selectedDate.day(),
    );
    setStartDate(newStartDate);

    const newEndDate = dayjs(selectedDate).date(
      selectedDate.date() + (6 - selectedDate.day()),
    );
    setEndDate(newEndDate);
  }, [selectedDate]);

  const updateDate = (futureWeek: boolean) => {
    if (futureWeek) {
      const newDate = dayjs(startDate).add(7, 'days');
      if (newDate.isBefore(dayjs())) {
        setSelectedDate(newDate);
        onDateSelect(newDate);
      }
    } else {
      const newDate = dayjs(startDate).subtract(7, 'days');
      setSelectedDate(newDate);
      onDateSelect(newDate);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        className={'flex__'}
        sx={{
          cursor: 'pointer',
          paddingLeft: '8px',
          paddingRight: '8px',
          borderRadius: '4px',
          backgroundColor: 'white',
        }}
      >
        <Box
          sx={{ display: 'flex', padding: '8px', cursor: 'pointer' }}
          alignItems={'center'}
          onClick={() => {
            updateDate(false);
          }}
        >
          <BackArrow />
        </Box>
        <Box>
          <CustomDatePicker
            label={`${dayjs(startDate).format('MM/DD/YYYY')} - ${dayjs(
              endDate,
            ).format('MM/DD/YYYY')}`}
            disableFuture={disableFutureDate}
            value={selectedDate}
            onChange={(newDate: Dayjs | null) => {
              if (newDate !== null) {
                setSelectedDate(newDate);
                onDateSelect(newDate);
              }
            }}
          />
        </Box>
        <Box
          sx={{ display: 'flex', padding: '8px', cursor: 'pointer' }}
          alignItems={'center'}
          onClick={() => {
            updateDate(true);
          }}
        >
          <FrontArrow />
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default WeekDatePicker;
