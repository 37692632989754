import { Box, Dialog, Grid } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as WarningIcon } from '../../assets/images/WarningIcon.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { SimilarClient } from './type';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import { ReactComponent as StatusIcon } from '../../assets/images/status.svg';
import { getStatusColor } from '../../utils/utilities';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import dayjs from 'dayjs';

interface ModelPropType {
  showModal: boolean;
  onModalClose: () => void;
  data: SimilarClient[];
  updateClient: () => void;
}

interface SimilarClientListType {
  clients: SimilarClient[];
}

const SimilarClientList: FC<SimilarClientListType> = ({ clients }) => {
  const getClientsDetails = (client: SimilarClient) => {
    const clientKeys: DataJsonType[] = [];
    if (client) {
      clientKeys.push({
        id: 'sex',
        labelid: 'DuplicateClientModal.sex',
        defaultLabel: 'Sex:',
        value: client.sex === null ? '-' : convertGenderToFrontend(client.sex),
      });
      clientKeys.push({
        id: 'dob',
        labelid: 'DuplicateClientModal.dob',
        defaultLabel: 'Date of Birth:',
        value: client.dateOfBirth
          ? dayjs(client.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : '-',
      });
      clientKeys.push({
        id: 'enrollmentId',
        labelid: 'ProgressNote.DPHID',
        defaultLabel: 'Enrollment ID:',
        value: client.dphId ?? '-',
      });
      clientKeys.push({
        id: 'program',
        labelid: 'DuplicateClientModal.program',
        defaultLabel: 'Program:',
        value: client.programAcronym ?? '-',
      });
      clientKeys.push({
        id: 'referralBeginDate',
        labelid: 'sspReferral.referralDate',
        defaultLabel: 'Referral Begin Date:',
        value: client.referralBeginDate
          ? dayjs(client.referralBeginDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : '-',
      });
      clientKeys.push({
        id: 'referralEndDate',
        labelid: 'sspReferral.referralEndDate',
        defaultLabel: 'Referral End Date:',
        value: client.referralEndDate
          ? dayjs(client.referralEndDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : '-',
      });
    }
    return clientKeys;
  };

  return (
    <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
      {clients.map((client) => (
        <Box key={client.clientId} className={'flex__ mt-lg'}>
          <Box sx={{ flexGrow: 1 }}>
            <Box className={'flex__ '} sx={{ alignItems: 'center' }}>
              <MediumTypography
                className="mainText-md mr-xs"
                sxProps={{ alignSelf: 'center' }}
                label={`${client.firstName ?? ''} ${client.lastName ?? ''} - `}
              />
              <StatusIcon fill={getStatusColor(client.status)} />
              <MediumTypography
                label={`${client.status ?? ''}`}
                className="ml-xs"
              />
            </Box>
            <Box>
              <Grid container>
                <Grid direction={'row'} item>
                  <KeyValueComponent data={getClientsDetails(client)} />
                </Grid>
                <Grid item xs={12}>
                  <Box className="defaultHorizontalLine" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const SimilarClientsUpdateModal: FC<ModelPropType> = ({
  data,
  showModal,
  updateClient,
  onModalClose,
}) => {
  return (
    <Dialog open={showModal} onClose={onModalClose} fullWidth maxWidth={'md'}>
      <Box className={'flex__ p-xlg'}>
        <Box>
          <WarningIcon />
        </Box>
        <Box className={'ml-xlg'} sx={{ flexGrow: 1 }}>
          <Box>
            <MediumTypography
              className="mainText-xxlg mt-xs"
              labelid="similarClientsFoundTitleText"
              defaultlabel="Similar Client(s) Found"
            />
            <MediumTypography
              sxProps={{ lineHeight: '18px', marginTop: '8px' }}
              labelid="similarClientsFoundUpdateText"
              defaultlabel="The updated details of the client match with the following clients. If you continue to update the demographics, there’s a risk of having duplicate client records."
            />

            <SimilarClientList clients={data} />
          </Box>

          <Box className="flex__ mt-xlg" sx={{ justifyContent: 'flex-end' }}>
            <Box className="flex__ flex-wrap-wrap">
              <ButtonComponent
                className="btn-primary btn-cancel btn_width_90px mr-md"
                variantType="contained"
                labelId="Clientpage.cancelbtn"
                defaultLabelId="Cancel"
                onClick={onModalClose}
              />

              <ButtonComponent
                className="btn-primary btn-submit"
                variantType="contained"
                labelId="updateAnywayText"
                defaultLabelId="Update anyway"
                onClick={() => {
                  updateClient();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SimilarClientsUpdateModal;
