import { Dialog, DialogTitle, DialogContent, Grid, Box } from '@mui/material';
import { FC } from 'react';
import MediumTypography from '../MediumTypography';
import { Formik } from 'formik';
import ButtonComponent from '../ButtonComponent';
import * as yup from 'yup';
import {
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
} from '../../../utils/dateUtil';
import DurationPicker from '../DurationPicker';
import AppTimePicker from '../AppTimePicker';
import dayjs, { Dayjs } from 'dayjs';

interface ActivityGroupBillingModelProps {
  open: boolean;
  editingAllowed: boolean;
  startTime: string | null;
  duration: string | null;
  handleClose: () => void;
  onGroupBillingEdit: (data: {
    startTime: string | null;
    duration: string | null;
  }) => void;
}

const validationSchema = yup.object().shape({
  startTime: yup.string().nullable(),
  duration: yup.string().nullable(),
});

const ActivityGroupBillingModal: FC<ActivityGroupBillingModelProps> = ({
  open,
  startTime,
  duration,
  editingAllowed,
  handleClose,
  onGroupBillingEdit,
}) => {
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <Grid container direction={'column'} rowSpacing={4}>
          <Grid item>
            <MediumTypography
              labelid="groupBillingText"
              defaultlabel="Group Billing"
              sxProps={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '8px',
                lineHeight: '30px',
                wordBreak: 'break-word',
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            startTime,
            duration,
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={(values) => {
            onGroupBillingEdit({
              startTime: values.startTime,
              duration: values.duration,
            });
          }}
        >
          {({ values, handleSubmit, setFieldValue }) => (
            <Grid
              sx={{
                marginTop: '8px',
                marginBottom: editingAllowed ? '0px' : '8px',
              }}
            >
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                  <AppTimePicker
                    disableManualInput={true}
                    needCrossIcon={false}
                    minuteStep={1}
                    disable={!editingAllowed}
                    value={dayjs(values.startTime, 'YYYY-MM-DDTHH:mm')}
                    onTimeChange={(time: Dayjs | null) => {
                      if (time === null) {
                        setFieldValue('startTime', null);
                      } else {
                        setFieldValue(
                          'startTime',
                          time.format('YYYY-MM-DDTHH:mm'),
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DurationPicker
                    interval={25}
                    required={false}
                    labelId="ProgressNote.duration"
                    defaultLabel="Duration"
                    maxDuration={{
                      hour: 24,
                      minutes: 0,
                    }}
                    minDuration={{
                      hour: 1,
                      minutes: 25,
                    }}
                    disabled={!editingAllowed}
                    duration={formatStringDateInDecimals(values.duration)}
                    setDuration={(durationValue) => {
                      if (
                        durationValue.hour === 0 &&
                        durationValue.minutes === 0
                      ) {
                        setFieldValue('duration', null);
                      } else {
                        setFieldValue(
                          'duration',
                          formatTimeDurationInDecimals(durationValue),
                        );
                      }
                    }}
                  />
                </Grid>
              </Grid>

              {editingAllowed && (
                <Box
                  sx={{ marginTop: '24px' }}
                  display={'flex'}
                  justifyContent={'flex-end'}
                >
                  <ButtonComponent
                    className="btn-primary btn-cancel"
                    labelId={'cancelText'}
                    variantType="outlined"
                    onClick={handleClose}
                  />
                  <ButtonComponent
                    className="btn-primary btn-submit ml-md"
                    labelId={'Insurance.update'}
                    onClick={handleSubmit}
                  />
                </Box>
              )}
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityGroupBillingModal;
