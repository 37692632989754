import { Box, Menu } from '@mui/material';
import { ReactComponent as UpArrowHead } from '../../assets/images/upArrowHead.svg';
import { ReactComponent as DownArrowHead } from '../../assets/images/downArrowHead.svg';
import MediumTypography from './MediumTypography';
import {
  TimeDuration,
  formatTimeDuration,
  formatTimeDurationInDecimals,
} from '../../utils/dateUtil';
import { useState } from 'react';
import Textinput from './Textinput';

interface DurationPickerProps {
  labelId: string;
  interval: number;
  defaultLabel: string;
  maxDuration: TimeDuration;
  minDuration?: TimeDuration;
  duration: TimeDuration;
  required: boolean;
  setDuration: (duration: TimeDuration) => void;
  placeholderRequired?: boolean;
  disabled?: boolean;
}

const DurationPicker: React.FC<DurationPickerProps> = ({
  labelId,
  interval,
  defaultLabel,
  duration,
  required,
  maxDuration,
  minDuration,
  setDuration,
  placeholderRequired,
  disabled,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const openAnchor = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isDurationAtMinimum = () => {
    return duration.hour <= 0 && duration.minutes <= 0;
  };

  const isDurationAtMaximum = () => {
    return (
      duration.hour > maxDuration.hour && duration.minutes > maxDuration.minutes
    );
  };

  const isDurationBelowMinimum = () => {
    return (
      minDuration &&
      duration.hour === minDuration.hour &&
      duration.minutes <= minDuration.minutes
    );
  };

  return (
    <>
      <Box className="datepicker bg__white" onClick={handleClick}>
        <Textinput
          sxProps={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
          labelid={labelId}
          defaultlabelid={defaultLabel}
          Value={
            placeholderRequired
              ? ''
              : interval === 25
              ? formatTimeDurationInDecimals(duration)
              : formatTimeDuration(duration)
          }
          Required={required}
          disabled={disabled}
        />
      </Box>
      <Menu open={openAnchor} onClose={handleMenuClose} anchorEl={anchorEl}>
        <Box
          sx={{
            width: '170px',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Box>
            <Box
              onClick={() => {
                if (duration.hour !== maxDuration.hour) {
                  setDuration({
                    ...duration,
                    hour: duration.hour + 1,
                    minutes: 0,
                  });
                }
              }}
            >
              <UpArrowHead
                style={{
                  width: '100%',
                }}
              />
            </Box>
            <MediumTypography
              label={duration.hour.toString().padStart(2, '0')}
              fontSize="20px"
              fontweight={700}
              textColor="#2A4241"
              sxProps={{ paddingY: '4px' }}
            />
            <Box
              onClick={() => {
                if (duration.hour === 0) {
                  return;
                } else {
                  setDuration({
                    ...duration,
                    hour: duration.hour - 1,
                    minutes: 0,
                  });
                }
              }}
            >
              <DownArrowHead
                style={{
                  width: '100%',
                }}
              />
            </Box>
          </Box>
          <Box>
            <MediumTypography
              label="."
              fontSize="20px"
              fontweight={700}
              textColor="#2A4241"
            />
          </Box>
          <Box>
            <Box
              onClick={() => {
                if (
                  duration.hour == maxDuration.hour &&
                  duration.minutes == maxDuration.minutes
                ) {
                  return;
                }
                if (duration.minutes === 59) {
                  setDuration({
                    ...duration,
                    hour: duration.hour + 1,
                    minutes: 0,
                  });
                } else if (interval === 25 && duration.minutes === 75) {
                  setDuration({
                    ...duration,
                    hour:
                      maxDuration.hour === duration.hour
                        ? duration.hour
                        : duration.hour + 1,
                    minutes: 0,
                  });
                } else {
                  setDuration({
                    ...duration,
                    minutes: duration.minutes + interval,
                  });
                }
              }}
            >
              <UpArrowHead
                style={{
                  width: '100%',
                }}
              />
            </Box>
            <MediumTypography
              label={duration.minutes.toString().padStart(2, '0')}
              fontSize="20px"
              fontweight={700}
              textColor="#2A4241"
              sxProps={{ paddingY: '4px' }}
            />
            <Box
              onClick={() => {
                if (isDurationAtMinimum()) {
                  return;
                } else if (isDurationAtMaximum()) {
                  return;
                } else if (isDurationBelowMinimum()) {
                  return;
                } else if (
                  duration.hour === maxDuration.hour &&
                  duration.minutes === 0
                ) {
                  if (interval === 25) {
                    setDuration({
                      ...duration,
                      hour: duration.hour - 1,
                      minutes: 75,
                    });
                  } else {
                    setDuration({
                      ...duration,
                      hour: duration.hour - 1,
                      minutes: 59,
                    });
                  }
                } else if (duration.minutes == 0) {
                  if (duration.hour === 0) {
                    setDuration({ ...duration, hour: 0, minutes: 0 });
                  } else if (interval === 25) {
                    setDuration({
                      ...duration,
                      hour: duration.hour - 1,
                      minutes: 75,
                    });
                  } else {
                    setDuration({
                      ...duration,
                      hour: duration.hour - 1,
                      minutes: 59,
                    });
                  }
                } else {
                  setDuration({
                    ...duration,
                    minutes: duration.minutes - interval,
                  });
                }
              }}
            >
              <DownArrowHead
                style={{
                  width: '100%',
                }}
              />
            </Box>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default DurationPicker;
