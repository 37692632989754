import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Card,
  Grid,
  Checkbox,
  FormControlLabel,
  AlertColor,
} from '@mui/material';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import MediumTypography from '../../components/formlib/MediumTypography';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormattedMessage } from 'react-intl';
import Textinput from '../../components/formlib/Textinput';
import { Formik, FormikProps, FormikErrors, FormikTouched } from 'formik';
import * as yup from 'yup';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import {
  Category,
  Codes,
  SubCategory,
  addDiagnosticCodes,
  updateDiagnosticCodes,
} from '../../services/configApi/codelist/diagnosticCodes';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { useLocation, useNavigate } from 'react-router';
import { CODE_LIST } from '../../routes/Routing';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SmallTypography from '../../components/formlib/SmallTypography';
import { cloneDeep, debounce } from 'lodash';
import { ReactComponent as DeleteIcon } from '../../assets/images/deleteAlertIcon.svg';
import './../../pages/codeList/serviceCodes/ServiceCodesList.css';
import ModalPopup from '../../components/formlib/ModalPopup';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import _ from 'lodash';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';

export interface SubCategoryType {
  subTitleName: string;
  codes: CodeCategoryType[];
  id?: string;
}

export interface CodeCategoryType {
  code: string;
  description: string;
  isValidForInsurance?: boolean;
  date?: Date | null | string;
  isUnderlined?: boolean;
  id?: string;
}

const initialValue: Category = {
  categoryName: '',
  subCategories: [
    {
      subTitleName: '',
      codes: [
        {
          code: '',
          description: '',
          isValidForInsurance: false,
          date: null,
          isUnderlined: false,
        },
      ],
    },
  ],
};

const subCategorySchema = yup.object().shape({
  subTitleName: yup.string().nullable(),
  codes: yup.array().of(
    yup.object().shape({
      code: yup.string().required('Required'),
      description: yup.string().required('Required'),
      isValidForInsurance: yup.boolean().nullable(),
      date: yup.string().when(['isValidForInsurance'], {
        is: (isValidForInsurance: boolean) => isValidForInsurance === true,
        then: yup.string().required('Required'),
        otherwise: yup.string().nullable(),
      }),
      isUnderlined: yup.string().nullable(),
    }),
  ),
});

const validationSchema = yup.object().shape({
  categoryName: yup.string(),
  subCategories: yup.array().of(subCategorySchema),
});

const validateForm = (value: Category) => {
  const errors: Partial<Category> = {};

  value.subCategories.forEach((category, subIndex) => {
    if (category && category.codes) {
      category.codes.forEach((code, codeIndex) => {
        if (code && code.date && datePickerMinDateMaxDateValidate(code.date)) {
          if (!errors.subCategories) {
            errors.subCategories = [];
          }

          if (!errors.subCategories[subIndex]) {
            errors.subCategories[subIndex] = {
              subTitleName: '',
              codes: [],
              id: '',
            };
          }

          if (!errors.subCategories[subIndex].codes) {
            errors.subCategories[subIndex].codes = [];
          }
          errors.subCategories[subIndex].codes[codeIndex] = {
            ...errors.subCategories[subIndex].codes[codeIndex],
            date: 'datePickerMinDateMaxDateValidate',
          };
        }
        if (code && code.date && (code.date === null || code.date === '')) {
          if (!errors.subCategories) {
            errors.subCategories = [];
          }

          if (!errors.subCategories[subIndex]) {
            errors.subCategories[subIndex] = {
              subTitleName: '',
              codes: [],
              id: '',
            };
          }

          if (!errors.subCategories[subIndex].codes) {
            errors.subCategories[subIndex].codes = [];
          }
          errors.subCategories[subIndex].codes[codeIndex] = {
            ...errors.subCategories[subIndex].codes[codeIndex],
            date: 'dateRequiredErrorMessage',
          };
        }
      });
    }
  });

  return errors;
};

const AddCategory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryName, setCategoryName] = React.useState('');
  const [dataView, setDataView] = React.useState<Category>(
    location.state?.categoryDetails !== undefined
      ? location.state?.categoryDetails
      : initialValue,
  );
  const formRef = React.useRef<FormikProps<Category>>(null);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [openModal, setOpenModal] = React.useState(false);

  const addSubCategoryPlaceholder = (
    <FormattedMessage
      id="diagnosticCodelist.addSubcategory"
      defaultMessage="+ Enter Sub Category Name"
    />
  );

  const handleAddSubLink = () => {
    const updatedSubCategoryList: Category = {
      ...dataView,
      subCategories: [
        ...dataView.subCategories,
        {
          subTitleName: '',
          codes: [
            {
              code: '',
              description: '',
              isValidForInsurance: false,
              date: null,
              isUnderlined: false,
            },
          ],
        },
      ],
    };
    formRef.current?.setValues(updatedSubCategoryList);
    setDataView(updatedSubCategoryList);
  };

  useEffect(() => {
    if (location.state?.categoryDetails !== undefined) {
      toggleLoader(true);

      setTimeout(() => {
        toggleLoader(false);
      }, 1000);
      setCategoryName(location.state?.categoryDetails.categoryName);
      formRef.current?.setValues(cloneDeep(location.state?.categoryDetails));
      setDataView(cloneDeep(location.state?.categoryDetails));
    } else {
      formRef.current?.setValues({
        categoryName: '',
        subCategories: [
          {
            subTitleName: '',
            codes: [
              {
                code: '',
                description: '',
                isValidForInsurance: false,
                date: null,
                isUnderlined: false,
              },
            ],
          },
        ],
      });
      setDataView({
        categoryName: '',
        subCategories: [
          {
            subTitleName: '',
            codes: [
              {
                code: '',
                description: '',
                isValidForInsurance: false,
                date: null,
                isUnderlined: false,
              },
            ],
          },
        ],
      });
    }
  }, [location.state?.categoryDetails]);

  const handleAddLink = (subCategoryIndex: number) => {
    const updatedDataView = { ...dataView };
    const currentSubCategory = {
      ...updatedDataView.subCategories[subCategoryIndex],
    };

    const newCodesList = {
      code: '',
      description: '',
      isValidForInsurance: false,
      date: null,
      isUnderlined: false,
    };

    currentSubCategory.codes = [...currentSubCategory.codes, newCodesList];
    updatedDataView.subCategories[subCategoryIndex] = currentSubCategory;
    setDataView(updatedDataView);
    formRef.current?.setValues(updatedDataView);
  };

  const handleSubmit = async () => {
    formRef.current?.submitForm();

    formRef.current?.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        const obj: Category = {
          categoryName: categoryName,
          subCategories: dataView.subCategories,
          id: location.state?.categoryDetails?.id || '',
        };

        addOrEditCategory(obj);
      } else {
        formRef.current?.setValues(dataView);
        setDataView(dataView);
      }
    });
  };

  const addOrEditCategory = useCallback(
    debounce((obj: Category) => {
      toggleLoader(true);
      if (location.state?.categoryDetails.categoryName !== undefined) {
        updateDiagnosticCodes(obj)
          .then(() => {
            toggleLoader(false);
            setTimeout(() => {
              navigate(CODE_LIST);
            }, 800);

            setOpen(true);
            setToastrVariable('success');
            setToastrDefaultMessage('Category Updated Successfully!');
            setToastrId('diagnosticCodelist.updateCategorySuccessMsg');
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('failedApiMsg');
              setToastrDefaultMessage(
                'Oops, something went wrong. Please try again later.',
              );
            }
          });
      } else {
        addDiagnosticCodes(obj)
          .then(() => {
            toggleLoader(false);
            setTimeout(() => {
              navigate(CODE_LIST);
            }, 800);

            setOpen(true);
            setToastrVariable('success');
            setToastrDefaultMessage('Category Added Successfully!');
            setToastrId('diagnosticCodelist.addCategorySuccessMsg');
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('diagnosticCodelist.addCategoryErrorMsg');
              setToastrDefaultMessage('Failed to Add Category!');
            }
          });
      }
    }, 1000),
    [],
  );

  const handleCodeChange = (
    index: number,
    codeIndex: number,
    value: string,
  ) => {
    const codeChangeValue = { ...dataView };
    codeChangeValue.subCategories[index].codes[codeIndex].code = value;
    setDataView(codeChangeValue);
  };

  const handleDescriptionChange = (
    index: number,
    codeIndex: number,
    value: string,
  ) => {
    dataView.subCategories[index].codes[codeIndex].description = value;
    setDataView(dataView);
  };

  const handleIsValidForInsuranceChange = (
    index: number,
    codeIndex: number,
    value: boolean,
  ) => {
    dataView.subCategories[index].codes[codeIndex].isValidForInsurance = value;
    setDataView(dataView);
  };

  const handleIsUnderlineOrAsteriskChange = (
    index: number,
    codeIndex: number,
    value: boolean,
  ) => {
    dataView.subCategories[index].codes[codeIndex].isUnderlined = value;
    setDataView(dataView);
  };

  const handleDate = (index: number, codeIndex: number, dateValue: string) => {
    dataView.subCategories[index].codes[codeIndex].date = dateValue;
    setDataView(dataView);
  };

  const handleSubTitleNameChange = (index: number, value: string) => {
    const titleName = { ...dataView };
    titleName.subCategories[index].subTitleName = value;
    setDataView(titleName);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const clearForm = () => {
    if (
      location.state?.categoryDetails !== undefined &&
      formRef.current?.initialValues
    ) {
      formRef.current?.setValues(formRef.current?.initialValues);
      setDataView(formRef.current?.initialValues);
      setCategoryName(location.state?.categoryDetails.categoryName);
      setOpenModal(false);
    } else {
      formRef.current?.setValues(formRef.current?.initialValues);
      setDataView(initialValue);
      setCategoryName('');
      setOpenModal(false);
    }
  };

  const handleDeleteSubCategory = (ind: number) => {
    if (dataView.subCategories.length > 1) {
      const updatedSubCategories = [...dataView.subCategories]; // Correctly copy the array
      updatedSubCategories.splice(ind, 1); // Remove the element at index 'ind'

      const updatedDataView = {
        ...dataView,
        subCategories: updatedSubCategories,
      };

      formRef.current?.setValues(updatedDataView);
      setDataView(updatedDataView);
    }
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box component="main">
        <Box component="section">
          <Formik
            innerRef={formRef}
            initialValues={{ subCategories: dataView.subCategories }}
            validationSchema={validationSchema}
            validate={validateForm}
            onSubmit={() => {}}
          >
            {({ setFieldValue, values, initialValues, errors, touched }) => (
              <>
                <Box className="rowContainer">
                  <CustomBackArrow onClick={() => navigate(CODE_LIST)} />
                  <Box component="div" className="ml-md">
                    {location.state?.categoryDetails.categoryName ===
                      undefined && (
                      <MediumTypography
                        labelid="codelist.addCategoryText"
                        defaultlabel="Add Category"
                        className="mainText-xxlg"
                      />
                    )}
                    {location.state?.categoryDetails.categoryName !==
                      undefined && (
                      <MediumTypography
                        labelid="codelist.editCategoryText"
                        defaultlabel="Edit Category"
                        className="mainText-xxlg"
                      />
                    )}
                  </Box>
                </Box>
                <Card className="formCardview">
                  <Grid container item spacing={2}>
                    <Grid item xs={4.5} lg={4.5}>
                      <Textinput
                        name={`addGroup`}
                        labelid="diagnosticCodelist.enterCategoryName"
                        defaultlabelid="Enter Category Name"
                        inputProps={{
                          maxLength: 50,
                        }}
                        Value={categoryName}
                        handlechange={(value: string) => {
                          setCategoryName(value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={4.5} lg={4.5}></Grid>
                  </Grid>

                  {dataView.subCategories.map(
                    (items: SubCategory, i: number) => (
                      <Box component="div" key={i}>
                        <Card
                          className="flex__ position__relative"
                          sx={{
                            border: '1px solid rgba(0, 198, 184, 1)',
                            overflow: 'visible',
                            background: 'rgba(236, 249, 248, 1)',
                            my: '3%',
                          }}
                        >
                          <Box
                            component="div"
                            className="flex__ p-xs position__absolute"
                            sx={{
                              top: -25,
                              left: '50%',
                              transform: 'translateX(-50%)',
                            }}
                          >
                            <form noValidate autoComplete="off">
                              <FormControl
                                sx={{
                                  '&:hover': {
                                    border: 'none',
                                  },
                                }}
                              >
                                <OutlinedInput
                                  placeholder={
                                    addSubCategoryPlaceholder.props
                                      .defaultMessage
                                  }
                                  value={dataView.subCategories[i].subTitleName}
                                  inputProps={{
                                    maxLength: 50,
                                  }}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    const value =
                                      event.target.value.trimStart();
                                    handleSubTitleNameChange(i, value);
                                    setFieldValue(
                                      `subCategories[${i}].subTitleName`,
                                      value,
                                    );
                                  }}
                                  sx={{
                                    fontSize: '14px',
                                    width: '201px',
                                    fontFamily: 'Lato-Regular',
                                    fontWeight: '500',
                                    height: '40px',
                                    background: 'rgba(255, 255, 255, 1)',
                                    '.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notChedOutline':
                                      {
                                        borderColor: 'none',
                                      },
                                    '&:hover': {
                                      borderColor: 'none',
                                    },

                                    '&:hover .MuiOutlinedInput-notchedOutline':
                                      {
                                        borderColor: '#00c6b8',
                                      },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline':
                                      {
                                        borderColor: '#00c6b8',
                                      },
                                  }}
                                />
                              </FormControl>
                            </form>
                          </Box>
                          <Grid
                            container
                            rowSpacing={'24px'}
                            columnSpacing={'40px'}
                            className="formCardview"
                            sx={{ my: '2px' }}
                          >
                            {items.codes.map(
                              (itemCodes: CodeCategoryType, index: number) => (
                                <>
                                  <Grid
                                    item
                                    container
                                    rowSpacing={'24px'}
                                    columnSpacing={'40px'}
                                    sx={{ display: 'flex' }}
                                    key={index}
                                  >
                                    <Grid item xs={3}>
                                      <Textinput
                                        name={`code${index}`}
                                        labelid="diagnosticCodelist.enterCode"
                                        defaultlabelid="Enter Code"
                                        sxProps={{
                                          background: 'rgba(255, 255, 255, 1)',
                                        }}
                                        inputProps={{
                                          maxLength: 10,
                                        }}
                                        Value={
                                          values.subCategories[i] &&
                                          values.subCategories[i]?.codes[index]
                                            ?.code
                                        }
                                        handlechange={(value: string) => {
                                          setFieldValue(
                                            `subCategories[${i}].codes[${index}].code`,
                                            value,
                                          );
                                          handleCodeChange(i, index, value);
                                        }}
                                        Required
                                      />
                                      {errors.subCategories &&
                                        errors.subCategories[i] &&
                                        (
                                          errors.subCategories[
                                            i
                                          ] as FormikErrors<SubCategory>
                                        ).codes &&
                                        (
                                          (
                                            errors.subCategories[
                                              i
                                            ] as FormikErrors<SubCategory>
                                          ).codes as FormikErrors<Codes[]>
                                        )[index]?.code &&
                                        touched.subCategories &&
                                        touched.subCategories[i] &&
                                        touched.subCategories[i].codes &&
                                        (
                                          touched.subCategories[i]
                                            ?.codes as FormikTouched<Codes>[]
                                        )[index]?.code && (
                                          <SmallTypography
                                            sxProps={{ color: 'red' }}
                                            labelId={
                                              (
                                                errors.subCategories[
                                                  i
                                                ] as FormikErrors<SubCategory>
                                              ).codes &&
                                              (
                                                (
                                                  errors.subCategories[
                                                    i
                                                  ] as FormikErrors<SubCategory>
                                                ).codes as FormikErrors<Codes[]>
                                              )[index]?.code
                                            }
                                            defaultLabelId="Code is Required"
                                          />
                                        )}
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Textinput
                                        name={`description${index}`}
                                        labelid="diagnosticCodelist.enterDesc"
                                        defaultlabelid="Enter Description"
                                        sxProps={{
                                          background: 'rgba(255, 255, 255, 1)',
                                        }}
                                        inputProps={{
                                          maxLength: 200,
                                        }}
                                        Value={
                                          values.subCategories[i]?.codes[index]
                                            ?.description
                                        }
                                        handlechange={(value: string) => {
                                          setFieldValue(
                                            `subCategories[${i}].codes[${index}].description`,
                                            value,
                                          );
                                          handleDescriptionChange(
                                            i,
                                            index,
                                            value,
                                          );
                                        }}
                                        Required
                                      />
                                      {errors.subCategories &&
                                        errors.subCategories[i] &&
                                        (
                                          errors.subCategories[
                                            i
                                          ] as FormikErrors<SubCategory>
                                        ).codes &&
                                        (
                                          (
                                            errors.subCategories[
                                              i
                                            ] as FormikErrors<SubCategory>
                                          ).codes as FormikErrors<Codes[]>
                                        )[index]?.description &&
                                        touched.subCategories &&
                                        touched.subCategories[i] &&
                                        touched.subCategories[i]?.codes &&
                                        (
                                          touched.subCategories[i]
                                            .codes as FormikTouched<Codes>[]
                                        )[index]?.description && (
                                          <SmallTypography
                                            sxProps={{ color: 'red' }}
                                            labelId={
                                              (
                                                errors.subCategories[
                                                  i
                                                ] as FormikErrors<SubCategory>
                                              ).codes &&
                                              (
                                                (
                                                  errors.subCategories[
                                                    i
                                                  ] as FormikErrors<SubCategory>
                                                ).codes as FormikErrors<Codes[]>
                                              )[index]?.description
                                            }
                                            defaultLabelId="Description is Required"
                                          />
                                        )}
                                    </Grid>

                                    {itemCodes.isValidForInsurance && (
                                      <Grid item xs={4} lg={4}>
                                        <DatePickerComponent
                                          className="bg__white"
                                          name="insuranceDate"
                                          value={
                                            (dayjs(
                                              values.subCategories[i]?.codes[
                                                index
                                              ]?.date,
                                            ) as Dayjs) ?? null
                                          }
                                          disableFuture={false}
                                          labelid="dateFormat"
                                          defaultlabelid="MM/DD/YYYY"
                                          handlechange={(date) => {
                                            const formattedDate =
                                              dayjs(date).format('MM/DD/YYYY');
                                            if (date === null) {
                                              setFieldValue(
                                                `subCategories[${i}].codes[${index}].date`,
                                                '',
                                              );
                                              handleDate(i, index, '');
                                            } else {
                                              setFieldValue(
                                                `subCategories[${i}].codes[${index}].date`,
                                                formattedDate,
                                              );
                                              handleDate(
                                                i,
                                                index,
                                                formattedDate,
                                              );
                                            }
                                          }}
                                        />
                                        {errors.subCategories &&
                                          errors.subCategories[i] &&
                                          (
                                            errors.subCategories[
                                              i
                                            ] as FormikErrors<SubCategory>
                                          ).codes &&
                                          (
                                            (
                                              errors.subCategories[
                                                i
                                              ] as FormikErrors<SubCategory>
                                            ).codes as FormikErrors<Codes[]>
                                          )[index]?.date &&
                                          touched.subCategories &&
                                          touched.subCategories[i] &&
                                          touched.subCategories[i]?.codes &&
                                          (
                                            touched.subCategories[i]
                                              .codes as FormikTouched<Codes>[]
                                          )[index]?.date && (
                                            <SmallTypography
                                              sxProps={{ color: 'red' }}
                                              labelId={
                                                (
                                                  errors.subCategories[
                                                    i
                                                  ] as FormikErrors<SubCategory>
                                                ).codes &&
                                                (
                                                  (
                                                    errors.subCategories[
                                                      i
                                                    ] as FormikErrors<SubCategory>
                                                  ).codes as FormikErrors<
                                                    Codes[]
                                                  >
                                                )[index]?.date
                                              }
                                              defaultLabelId="Date is Required"
                                            />
                                          )}
                                      </Grid>
                                    )}
                                    {itemCodes.isValidForInsurance && (
                                      <Grid item xs={0.5}>
                                        {dataView.subCategories[i].codes
                                          .length > 1 && (
                                          <DeleteIcon
                                            style={{
                                              position: 'relative',
                                              cursor: 'pointer',
                                              width: '22px',
                                              height: '22px',
                                              top: '4px',
                                              right: '30px',
                                            }}
                                            onClick={() => {
                                              if (
                                                dataView.subCategories[i].codes
                                                  .length > 1
                                              ) {
                                                const updatedSubCategories = [
                                                  ...dataView.subCategories,
                                                ];
                                                updatedSubCategories[
                                                  i
                                                ].codes.splice(index, 1);
                                                formRef.current?.setValues({
                                                  ...dataView,
                                                  subCategories:
                                                    updatedSubCategories,
                                                });
                                                setDataView({
                                                  ...dataView,
                                                  subCategories:
                                                    updatedSubCategories,
                                                });
                                              }
                                            }}
                                          />
                                        )}
                                      </Grid>
                                    )}
                                    {!itemCodes.isValidForInsurance && (
                                      <Grid item xs={0.5}>
                                        {dataView.subCategories[i].codes
                                          .length > 1 && (
                                          <DeleteIcon
                                            style={{
                                              position: 'relative',
                                              cursor: 'pointer',
                                              width: '22px',
                                              height: '22px',
                                              top: '4px',
                                              right: '30px',
                                            }}
                                            onClick={() => {
                                              if (
                                                dataView.subCategories[i].codes
                                                  .length > 1
                                              ) {
                                                const updatedSubCategories = [
                                                  ...dataView.subCategories,
                                                ];
                                                updatedSubCategories[
                                                  i
                                                ].codes.splice(index, 1);
                                                formRef.current?.setValues({
                                                  ...dataView,
                                                  subCategories:
                                                    updatedSubCategories,
                                                });
                                                setDataView({
                                                  ...dataView,
                                                  subCategories:
                                                    updatedSubCategories,
                                                });
                                              }
                                            }}
                                          />
                                        )}
                                      </Grid>
                                    )}

                                    {!itemCodes.isValidForInsurance && (
                                      <Grid item xs={3}></Grid>
                                    )}

                                    <Grid item xs={3}>
                                      <FormControlLabel
                                        sx={{ marginTop: '-8%' }}
                                        control={
                                          <Checkbox
                                            name={`name-${index}`}
                                            checked={
                                              values.subCategories[i]?.codes[
                                                index
                                              ]?.isValidForInsurance == true
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              setFieldValue(
                                                `subCategories[${i}].codes[${index}].isValidForInsurance`,
                                                !values.subCategories[i]?.codes[
                                                  index
                                                ]?.isValidForInsurance,
                                              );

                                              handleIsValidForInsuranceChange(
                                                i,
                                                index,
                                                !values.subCategories[i]?.codes[
                                                  index
                                                ]?.isValidForInsurance,
                                              );
                                            }}
                                            style={{
                                              color:
                                                values.subCategories[i]?.codes[
                                                  index
                                                ]?.isValidForInsurance === true
                                                  ? '#00938e'
                                                  : '#97A6A5',
                                            }}
                                          />
                                        }
                                        label={
                                          <span
                                            style={{
                                              fontFamily: 'Lato-Regular',
                                              fontStyle: 'normal',
                                              fontWeight: 400,
                                              fontSize: '14px',
                                            }}
                                          >
                                            Not valid for insurance
                                          </span>
                                        }
                                      />
                                    </Grid>

                                    <Grid item xs={3.5}>
                                      <FormControlLabel
                                        sx={{ marginTop: '-8%' }}
                                        control={
                                          <Checkbox
                                            name={`name-${index}`}
                                            checked={
                                              values.subCategories[i]?.codes[
                                                index
                                              ]?.isUnderlined == true
                                                ? true
                                                : false
                                            }
                                            onChange={() => {
                                              setFieldValue(
                                                `subCategories[${i}].codes[${index}].isUnderlined`,
                                                !values.subCategories[i]?.codes[
                                                  index
                                                ]?.isUnderlined,
                                              );

                                              handleIsUnderlineOrAsteriskChange(
                                                i,
                                                index,
                                                !values.subCategories[i]?.codes[
                                                  index
                                                ]?.isUnderlined,
                                              );
                                            }}
                                            style={{
                                              color: values.subCategories[i]
                                                ?.codes[index]?.isUnderlined
                                                ? '#00938e'
                                                : '#97A6A5',
                                            }}
                                          />
                                        }
                                        label={
                                          <span
                                            style={{
                                              fontFamily: 'Lato-Regular',
                                              fontStyle: 'normal',
                                              fontWeight: 400,
                                              fontSize: '14px',
                                            }}
                                          >
                                            1 Year Diagnosis
                                          </span>
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              ),
                            )}

                            <Grid container item justifyContent="space-between">
                              <Grid item xs={4.5}>
                                <ButtonComponent
                                  className="btn-add-code"
                                  variantType="contained"
                                  type="submit"
                                  labelId="diagnosticCodelist.addCode"
                                  defaultLabelId="+ Add Code"
                                  onClick={() => handleAddLink(i)}
                                />
                              </Grid>
                              <Grid item xs={7}></Grid>

                              {values.subCategories.length > 1 && (
                                <div style={{ display: 'flex' }}>
                                  <Grid item>
                                    <DeleteIcon
                                      style={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        width: '20px',
                                        marginRight: '10px',
                                        height: '20px',
                                        right: '43px',
                                        top: '8px',
                                      }}
                                    />
                                  </Grid>
                                  <Grid item>
                                    <ButtonComponent
                                      className="btn-delete-code position__absolute"
                                      variantType="contained"
                                      type="submit"
                                      labelId="deleteText"
                                      defaultLabelId="Delete"
                                      onClick={() => handleDeleteSubCategory(i)}
                                    />
                                  </Grid>
                                </div>
                              )}
                            </Grid>
                          </Grid>
                        </Card>
                      </Box>
                    ),
                  )}

                  <Grid
                    item
                    xs={4.5}
                    sx={{ position: 'relative', bottom: '15px' }}
                  >
                    <Box className="flex__">
                      <ButtonComponent
                        disabled={
                          !dataView.subCategories[
                            dataView.subCategories.length - 1
                          ].subTitleName ||
                          dataView.subCategories[
                            dataView.subCategories.length - 1
                          ].subTitleName.trim() === ''
                        }
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId="diagnosticCodelist.addSubCategoryBtn1"
                        defaultLabelId={`+ Add Sub Category`}
                        onClick={handleAddSubLink}
                      />
                    </Box>
                  </Grid>
                </Card>
                <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="right"
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Grid item sx={{ marginRight: '16px' }}>
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        variantType="contained"
                        defaultLabelId="Cancel"
                        labelId="Contacts.cancelbtn"
                        onClick={() => {
                          if (
                            !_.isEqual(
                              initialValues.subCategories,
                              values.subCategories,
                            )
                          ) {
                            setOpenModal(true);
                          } else if (
                            !_.isEqual(
                              location.state?.categoryDetails.categoryName,
                              categoryName,
                            ) &&
                            location.state?.categoryDetails.categoryName !==
                              undefined
                          ) {
                            setOpenModal(true);
                          } else {
                            navigate(CODE_LIST);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {location.state?.categoryDetails.categoryName ===
                        undefined && (
                        <ButtonComponent
                          disabled={categoryName === ''}
                          className="btn-primary btn-submit "
                          variantType="contained"
                          type="submit"
                          labelId="BlockTime.addButton"
                          defaultLabelId="Add"
                          onClick={handleSubmit}
                        />
                      )}
                      {location.state?.categoryDetails.categoryName !==
                        undefined && (
                        <ButtonComponent
                          disabled={categoryName === ''}
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="clientpage.Update"
                          defaultLabelId="Update"
                          onClick={handleSubmit}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Formik>
        </Box>
      </Box>
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => clearForm()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
    </>
  );
};

export default AddCategory;
