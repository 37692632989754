import React, { useContext, useEffect } from 'react';
import { AlertColor, Box, Card, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import Textinput from '../../components/formlib/Textinput';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import { OptionType, ProgramType } from '../../utils/type';
import ModalPopup from '../../components/formlib/ModalPopup';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import SmallTypography from '../../components/formlib/SmallTypography';
import {
  addProgramManagment,
  editProgramManagment,
  getProgramBasedId,
} from '../../services/programManagment/programManagment';
import AddressForm from '../../components/formlib/AddressForm';
import { PROGRAM_MANAGEMENT_ROUTE_NAME } from '../../routes/Routing';
import ReactImagePickerEditor from '../../components/imagepickers/ImagePicker';
import { ImagePickerConf } from '../../components/imagepickers/imagepicker.models';
import { dataURItoFile } from '../../utils/fileUtils';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import _ from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';

const initialValues: ProgramType = {
  id: '',
  programId: '',
  programName: '',
  programCode: '',
  programDirector: '',
  phoneNumber1: '',
  phoneNumber2: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: { id: '', label: '' },
  city: '',
  state: '',
  faxNumber1: '',
  faxNumber2: '',
  acronym: '',
  isEdit: false,
  website: '',
};

const emailValidateRegex = /^[^\s@]{1,64}@[A-Za-z0-9.-]{1,255}\.[A-Za-z]{2,}$/;

const validationSchema = yup.object().shape({
  programName: yup.string().nullable(),
  programCode: yup.string().nullable(),
  programDirector: yup.string().nullable(),
  email: yup
    .string()
    .matches(emailValidateRegex, 'parent.emailMessage')
    .nullable(),
  phoneNumber1: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  phoneNumber2: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  addressLine1: yup.string().nullable(),
  addressLine2: yup.string().nullable(),
  zipCode: yup.object().shape({
    label: yup.string().nullable(),
  }),
  city: yup.string().nullable().nullable(),
  state: yup.string().nullable(),
  faxNumber1: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  faxNumber2: yup.string().min(12, 'PhoneNumber.Validation').nullable(),
  acronym: yup.string().nullable(),
});

export interface TabProps {
  tabPosition: number;
}

const AddNewProgram = () => {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [openModal, setOpenModal] = React.useState(false);
  const [initialProgramValues, setInitialProgramValues] =
    React.useState<ProgramType>(initialValues);
  const [imageSrc, setImageSrc] = React.useState<string | null | undefined>('');
  const formRef = React.useRef<FormikProps<ProgramType>>(null);
  const [initialImage, setInitialImage] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const config: ImagePickerConf = {
    borderRadius: '2%',
    language: 'en',
    objectFit: 'cover',
    aspectRatio: 1 / 4,
    compressInitial: null,
    hideDownloadBtn: true,
    hideAddBtn: true,
    hideEditBtn: true,
    hideDeleteBtn: true,
    type: 'ProgramImage',
  };
  const navigate = useNavigate();
  const clearForm = () => {
    formRef.current?.setValues(initialProgramValues);
    setOpenModal(false);
  };
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState('');
  const location = useLocation();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const NumValidation = (value: string) => {
    const cleanValue = value.replace(/\D/g, '');
    const formattedValue = cleanValue.replace(
      /(\d{3})(\d{0,3})(\d{0,4})/,
      (match, p1, p2, p3) => {
        let formatted = p1;
        if (p2) formatted += '-' + p2;
        if (p3) formatted += '-' + p3;
        return formatted;
      },
    );
    return formattedValue.slice(0, 12);
  };

  const programCodeValidation = (value: string) => {
    const sanitizedValue = value?.replace(/\D/g, '');
    return sanitizedValue?.slice(0, 3);
  };

  const formHandler = (formValues: ProgramType) => {
    formValues.zipCode = (formValues.zipCode as OptionType).label;

    if (formValues.programCode.toString().length === 1) {
      formValues.programCode = `0${formValues.programCode}`;
    }
    if (!isSubmitting) {
      setIsSubmitting(true);
      const file: File | null = dataURItoFile(imageSrc);
      addProgramManagment(formValues, file)
        .then(async (response) => {
          if (response) {
            setOpen(true);
            setToastrVariable('success');
            setToastrId('Family.submitsuccess');
            setToastrDefaultMessage('Program Created Successfully');
            navigate(PROGRAM_MANAGEMENT_ROUTE_NAME);
          }
        })
        .finally(() => {
          setIsSubmitting(false);
        })
        .catch(async (error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('ProgressNote.savedsuccess');
            setToastrDefaultMessage('Data Saved Successfully');
          }
        });
    }
  };

  useEffect(() => {
    const navParams = location.state;
    if (navParams?.programId !== undefined) {
      toggleLoader(true);
      getProgramBasedId(navParams.programId)
        .then(async (response) => {
          if (response) {
            toggleLoader(false);
            const responseValues: ProgramType = {
              id: response.id,
              programId: response.programId,
              programName: response.programName,
              programCode: response.programCode,
              programDirector: response.programDirector,
              phoneNumber1: response.phoneNumber1,
              phoneNumber2: response.phoneNumber2,
              email: response.email,
              addressLine1: response.addressLine1,
              addressLine2: response.addressLine2,
              zipCode: { id: 'aa', label: response.zipCode } as OptionType,
              city: response.city,
              state: response.state,
              faxNumber1: response.faxNumber1,
              faxNumber2: response.faxNumber2,
              acronym: response.acronym,
              website: response.website,
              isEdit: true,
            };
            setInitialProgramValues(responseValues);
            formRef.current?.setValues(() => responseValues);

            if (response.programImageUrl && response.programImageUrl !== null) {
              setInitialImage(response.programImageUrl);
            }
          }
        })
        .catch(async (error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('loadFailureMessage');
            setToastrDefaultMessage('Failed to get details');
          }
        });
    }
  }, []);

  const handleUpdate = (formValues: ProgramType) => {
    toggleLoader(true);
    if (formValues.programCode) {
      if (formValues.programCode.toString().length === 1) {
        formValues.programCode = `0${formValues.programCode}`;
      }
    }
    formValues.zipCode = (formValues.zipCode as OptionType).label;

    if (!isSubmitting) {
      setIsSubmitting(true);
      const navParams = location.state;
      if (navParams?.programId !== undefined) {
        const file: File | null = dataURItoFile(imageSrc);
        editProgramManagment(formValues, navParams.programId, file)
          .then(async (response) => {
            if (response) {
              setOpen(true);
              setToastrVariable('success');
              setToastrId('ProgramManagment.UpdateSuccessMessage');
              setToastrDefaultMessage('Program Updated Successfully');
              setTimeout(() => {
                navigate(PROGRAM_MANAGEMENT_ROUTE_NAME);
              }, 1000);
            }
          })
          .finally(() => {
            setIsSubmitting(false);
          })
          .catch(async (error) => {
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('updateFailureMessage');
              setToastrDefaultMessage('Failed to update details');
            }
          });
      }
    }
  };

  const handleEmailChange = (e: string) => {
    const value = e;

    const emailRegex = /^[^\s@]{1,64}@[A-Za-z0-9.-]{1,255}\.[A-Za-z]{2,}$/;

    if (value !== '' && !emailRegex.test(value)) {
      setErrorEmail('Invalid email address');
    } else {
      setErrorEmail('');
    }
  };
  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <ModalPopup
        description="formUnsavedChangesMessage"
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onOk={() => clearForm()}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />

      <Formik
        innerRef={formRef}
        validateOnMount={true}
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          if (values.website === undefined || values.website === null) {
            values.website = '';
          }
          if (values.isEdit) {
            handleUpdate(values);
          } else {
            formHandler(values);
          }
        }}
      >
        {({ setFieldValue, handleSubmit, values, errors, touched }) => (
          <Box component="div">
            <Card className="formCardview">
              <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="programName"
                    disabled={location.state?.programId !== undefined}
                    labelid="Addprogram.programName"
                    defaultlabelid="Program Name"
                    Value={values.programName}
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('programName', value);
                    }}
                  />

                  {errors.programName && touched.programName && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.programName}
                      defaultLabelId="Program Name is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="acronym"
                    disabled={location.state?.programId !== undefined}
                    labelid="Addprogram.Acronym"
                    defaultlabelid="Program Acronym"
                    Value={values.acronym}
                    inputProps={{
                      maxLength: 5,
                      style: { textTransform: 'uppercase' },
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('acronym', value.toUpperCase());
                    }}
                  />

                  {errors.acronym && touched.acronym && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.acronym}
                      defaultLabelId="Program Acronym is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="programCode"
                    disabled={location.state?.programId !== undefined}
                    labelid="Addprogram.programCode"
                    defaultlabelid="Program Code"
                    Value={values.programCode}
                    handlechange={(value: string) => {
                      setFieldValue(
                        'programCode',
                        programCodeValidation(value),
                      );
                    }}
                  />

                  {errors.programCode && touched.programCode && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.programCode}
                      defaultLabelId="Program Code is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="programDirector"
                    Value={values.programDirector}
                    labelid="AddProgram.programDirector"
                    defaultlabelid="Program Director"
                    inputProps={{
                      maxLength: 50,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('programDirector', value);
                    }}
                  />
                  {errors.programDirector && touched.programDirector && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.programDirector}
                      defaultLabelId="Program Director is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name={`email`}
                    labelid="Family.email"
                    defaultlabelid="Email Address"
                    handlechange={(value: string) => {
                      setFieldValue('email', value);
                      handleEmailChange(value);
                    }}
                    inputProps={{
                      maxLength: 320,
                    }}
                    Value={values.email}
                  />

                  {errorEmail !== '' && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId="EmailFormat.Validation"
                      defaultLabelId="Invalid Email Address"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="phoneNumber1"
                    Value={values.phoneNumber1}
                    labelid="Program.addPhone1"
                    defaultlabelid="Enter Phone Number 1"
                    inputProps={{
                      maxLength: 12,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('phoneNumber1', NumValidation(value));
                    }}
                  />
                  {errors.phoneNumber1 && touched.phoneNumber1 && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.phoneNumber1}
                      defaultLabelId="Phone Number is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="phoneNumber2"
                    Value={values.phoneNumber2}
                    labelid="Program.addPhone2"
                    defaultlabelid="Enter Phone Number 2"
                    inputProps={{
                      maxLength: 12,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('phoneNumber2', NumValidation(value));
                    }}
                  />
                  {errors.phoneNumber2 && touched.phoneNumber2 && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.phoneNumber2}
                      defaultLabelId="Phone Number is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="faxNumber"
                    Value={values.faxNumber1}
                    labelid="Program.addFax1"
                    defaultlabelid="Enter Fax Number 1"
                    inputProps={{
                      maxLength: 12,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('faxNumber1', NumValidation(value));
                    }}
                  />
                  {errors.faxNumber1 && touched.faxNumber1 && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.faxNumber1}
                      defaultLabelId="Fax Number is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5} lg={4.5}>
                  <Textinput
                    name="faxNumber"
                    Value={values.faxNumber2}
                    labelid="Program.addFax2"
                    defaultlabelid="Enter Fax Number 2"
                    inputProps={{
                      maxLength: 12,
                    }}
                    handlechange={(value: string) => {
                      setFieldValue('faxNumber2', NumValidation(value));
                    }}
                  />
                  {errors.faxNumber2 && touched.faxNumber2 && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.faxNumber2}
                      defaultLabelId="Fax Number is required"
                    />
                  )}
                </Grid>

                <Grid item xs={4.5}></Grid>

                <Grid item xs={9}>
                  <AddressForm
                    headerTitleId="Clientpage.HomeAddress"
                    address1PlaceholderId="address1Text"
                    address2PlaceholderId="address2Text"
                    zipCodePlaceholderId="Clientpage.Zip"
                    cityPlaceholderId="Clientpage.City"
                    statePlaceholderId="Clientpage.State"
                    value={{
                      address1: values.addressLine1,
                      address2: values.addressLine2,
                      zipCode: values.zipCode,
                      city: values.city,
                      state: values.state,
                    }}
                    isClient={false}
                    disableForm={false}
                    formRequired={false}
                    formikErrors={{
                      address1: errors.addressLine1,
                      address2: errors.addressLine2,
                      zipCode: errors.zipCode,
                      city: errors.city,
                      state: errors.state,
                    }}
                    formikTouched={{
                      address1: touched.addressLine1,
                      address2: touched.addressLine2,
                      zipCode: touched.zipCode,
                      city: touched.city,
                      state: touched.state,
                    }}
                    handleAddressUpdate={(newAddress) => {
                      setFieldValue('addressLine1', newAddress.address1);
                      setFieldValue('addressLine2', newAddress.address2);
                      setFieldValue(
                        'zipCode',
                        newAddress.zipCode as OptionType,
                      );
                      setFieldValue('city', newAddress.city);
                      setFieldValue('state', newAddress.state);
                    }}
                  />
                </Grid>

                <Grid item xs={9} lg={9.05}>
                  <Textinput
                    name="website"
                    Value={values.website}
                    labelid="Program.website"
                    defaultlabelid="Website"
                    inputProps={{ maxLength: 150 }}
                    handlechange={(value: string) => {
                      setFieldValue('website', value);
                    }}
                  />
                </Grid>
                <Grid item xs={9} lg={9.05}>
                  <ReactImagePickerEditor
                    config={config}
                    imageSrcProp={initialImage as string}
                    imageChanged={(newDataUri: string | null | undefined) => {
                      setImageSrc(newDataUri);
                    }}
                  />
                </Grid>
              </Grid>
            </Card>

            <Box>
              <Grid
                className="flex__ justifyContent-FlexEnd pt-md pb-lg "
                container
                direction="row"
                alignItems="right"
              >
                <Grid item>
                  <ButtonComponent
                    className="btn-primary btn-cancel btn_width_90px mr-md"
                    variantType="contained"
                    labelId="Contacts.cancelbtn"
                    defaultLabelId="Cancel"
                    onClick={() => {
                      if (_.isEqual(initialProgramValues, values)) {
                        navigate(PROGRAM_MANAGEMENT_ROUTE_NAME);
                      } else {
                        setOpenModal(true);
                      }
                    }}
                  />

                  {values.isEdit === false && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="parentGuardian.save"
                      onClick={() => {
                        handleSubmit();
                      }}
                      defaultLabelId="Save & Submit"
                    />
                  )}
                  {values.isEdit && (
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="parentGuardian.update"
                      onClick={() => {
                        handleSubmit();
                      }}
                      defaultLabelId="Update"
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default AddNewProgram;
