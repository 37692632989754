import { ReactNode } from 'react';
import { ClearCacheProvider } from 'react-clear-cache';

interface WrapperProps {
  children: ReactNode;
}
export const CacheHandler = (props: WrapperProps) => {
  const { children } = props;

  return (
    <ClearCacheProvider duration={300000} auto={true}>
      {children}
    </ClearCacheProvider>
  );
};
