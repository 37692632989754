import { ChangeEvent, FC, HTMLInputTypeAttribute } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { OptionType } from '../../utils/type';
import TextField from '@mui/material/TextField/TextField';
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete/Autocomplete';
import { FormattedMessage } from 'react-intl';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as SearchIcon } from '../../assets/images/Search.svg';
import { SxProps } from '@mui/system';

interface DropdownFieldProps {
  labelId: string;
  defaultId: string;
  optionHintId?: string;
  data: OptionType[];
  singleSelect?: boolean;
  onSelectItem: (value: OptionType[]) => void;
  hideOption?: string | null;
  value?: OptionType[];
  highlightSelectedValues?: string[];
  highlightSelectedValuesTooltipLabelId?: string;
  disableLabel?: boolean;
  onTextChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: HTMLInputTypeAttribute;
  sxProps?: SxProps;
  disabled?: boolean;
  placeholderOnfloatBackgroundColor?: string;
  required?: boolean;
  textDecoration?: string;
}

const MultipleAutocompleteSearch: FC<DropdownFieldProps> = ({
  labelId,
  defaultId,
  data,
  singleSelect,
  onSelectItem,
  value,
  optionHintId,
  highlightSelectedValues,
  highlightSelectedValuesTooltipLabelId,
  disableLabel,
  onTextChange,
  type,
  sxProps,
  disabled,
  required,
  placeholderOnfloatBackgroundColor,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (type === 'number') {
      e.target.value = e.target.value.slice(0, 5);
    }
  };
  const isOptionEqualToValue = (
    option: OptionType,
    valueSelected: OptionType,
  ) => {
    if (typeof valueSelected.id === 'number') {
      return option?.id?.toString() === valueSelected?.id;
    } else {
      return option.id === valueSelected.id;
    }
  };

  const formatLabel = (option: OptionType) => {
    if (option) {
      let name = '';
      name = option.label ? option.label : '';
      return name;
    }
  };
  return (
    <Autocomplete
      sx={{
        '& .MuiInputLabel-root': {
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
          color: '#97A6A5',
        },
        '& .MuiOutlinedInput-input': {
          fontFamily: 'Lato-Regular',
          fontSize: '14px',
        },
        '& label.Mui-focused': {
          color: '#00C6B8',
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
        },
        '& .inputSelectFormLabel > label.MuiInputLabel-shrink': {
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
          color: '#97A6A5',
        },
        '& .MuiOutlinedInput-root': {
          padding: '2px',
          '&.Mui-focused fieldset': {
            borderColor: '#00C6B8',
            borderWidth: '1px',
          },
          '&:hover fieldset': {
            borderColor: '#00938e',
          },
        },
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00938e',
          },
        },
        ...sxProps,
      }}
      disabled={disabled}
      multiple={!singleSelect}
      options={data}
      value={value}
      isOptionEqualToValue={isOptionEqualToValue}
      noOptionsText={
        optionHintId && (
          <FormattedMessage
            id={optionHintId}
            defaultMessage={'No options found'}
          />
        )
      }
      getOptionLabel={(options) => formatLabel(options) as string}
      id="combo-box-dropdown"
      filterSelectedOptions
      popupIcon={disableLabel ? <SearchIcon /> : <KeyboardArrowDownIcon />}
      onChange={(
        _: React.SyntheticEvent,
        newValue: OptionType | OptionType[] | null,
      ) => {
        if (newValue !== null) {
          if (singleSelect) {
            onSelectItem([newValue] as OptionType[]);
          } else {
            onSelectItem(newValue as OptionType[]);
          }
        }
      }}
      ListboxProps={{
        sx: {
          fontFamily: 'Lato-Regular',
        },
      }}
      renderInput={(params) => (
        <TextField
          className="inputSelectFormLabel"
          {...params}
          label={<FormattedMessage id={labelId} defaultMessage={defaultId} />}
          variant="outlined"
          type={type}
          onInput={handleInputChange}
          onChange={onTextChange}
          required={required}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
      renderOption={(props, option: OptionType) => {
        const parts = option.label.split(' - ');
        let beforeHyphen = '';
        let afterHyphen = '';

        if (parts.length > 1) {
          beforeHyphen = parts[0];
          afterHyphen = parts?.slice(1)?.join(' - ');
        } else {
          beforeHyphen = option.label;
        }

        const underlineStyle = afterHyphen.includes('*') ? 'underline' : 'none';

        return (
          <li {...props}>
            {parts.length > 1 ? (
              <>
                <span
                  style={{
                    fontFamily: 'Lato-Regular',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '17px',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}
                >
                  {beforeHyphen} -{' '}
                </span>
                <span
                  style={{
                    textDecoration: underlineStyle,
                    fontFamily: 'Lato-Regular',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '17px',
                    textOverflow: 'ellipsis',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                  }}
                >
                  {afterHyphen}
                </span>
              </>
            ) : (
              <span
                style={{
                  textDecoration: underlineStyle,
                  fontFamily: 'Lato-Regular',
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '17px',
                  textOverflow: 'ellipsis',
                  wordBreak: 'break-all',
                  whiteSpace: 'normal',
                }}
              >
                {beforeHyphen}
              </span>
            )}
          </li>
        );
      }}
      renderTags={(selectedValues: OptionType[], getTagProps) => {
        return selectedValues.map((option, index) => {
          const underlineStyle = option.label.includes('- *')
            ? 'underline'
            : 'none';
          return (
            <>
              {highlightSelectedValues?.includes(option.id) &&
              highlightSelectedValuesTooltipLabelId ? (
                <Tooltip
                  title={
                    <FormattedMessage
                      id={highlightSelectedValuesTooltipLabelId}
                    />
                  }
                >
                  <Chip
                    variant="outlined"
                    label={formatLabel(option)}
                    style={{
                      textDecoration: underlineStyle,
                      borderColor: '#EB4C60',
                      borderWidth: '1px',
                      color: '#EB4C60',
                    }}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              ) : (
                <Tooltip title={formatLabel(option)}>
                  <Chip
                    variant="outlined"
                    label={formatLabel(option)}
                    size="small"
                    style={{
                      textDecoration: underlineStyle,
                      borderColor: 'gray',
                      borderWidth: '1px',
                    }}
                    {...getTagProps({ index })}
                  />
                </Tooltip>
              )}
            </>
          );
        });
      }}
    />
  );
};

export default MultipleAutocompleteSearch;
