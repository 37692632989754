import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import EmptyScreen from '../../components/shared/EmptyScreen';
import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
} from '@mui/material';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import TitleText from '../../components/formlib/TitleText';
import { useNavigate } from 'react-router';
import {
  ASQ_QUESTIONNAIRES_CLIENT_INFO,
  FACESHEET,
} from '../../routes/Routing';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  Asqse2ListType,
  deleteAsqse2,
  getAsqse2ById,
  getAsqse2List,
} from '../../services/configApi/forms/ASQSE2/ASQQuestionaireServices';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { isCustomError, ApiError } from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { FaceSheetDemographicsType, ResponseType } from '../../utils/type';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import ASQTableRow from './ASQTableRow';
import ModalPopup from '../../components/formlib/ModalPopup';
import { formatName } from '../../utils/nameUtils';

interface Data {
  age: number;
  dateOfAsqse2: string;
  actions: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'dateOfAsqse2',
    labelId: 'dateText',
    defaultLabelId: 'Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'dateOfAsqse2',
    labelId: 'ProgressNote.Questionnaire',
    defaultLabelId: 'Questionnaire',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'ConsentForms.listHeaderActions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const ASQDashboard = () => {
  const navigate = useNavigate();

  const [demographicsLookup, setDemographicsLookup] =
    useState<FaceSheetDemographicsType | null>(null);

  const { lookupsDemographics } = useContext(
    LookUpContext,
  ) as LookUpContextType;
  const [asqse2List, setAsqse2List] = useState<Asqse2ListType[]>([]);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');
  const [toastrId, setToastrId] = useState<string>();
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [showAlertDialog, setShowAlertDialog] = React.useState<string>();
  const [clientName, setClientName] = React.useState(
    'ASQ: SE-2 Ages & Stages Questionnaires',
  );
  const clientId = localStorage.getItem('ClientId');

  useEffect(() => {
    if (lookupsDemographics !== null) {
      setDemographicsLookup(lookupsDemographics);
      const fullName = formatName(
        lookupsDemographics.firstName,
        lookupsDemographics.middleName,
        lookupsDemographics.lastName,
        null,
      );
      setClientName(fullName);
    }
  }, [lookupsDemographics]);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem('ASQData');
    getAsqse2ListDetails();
  }, []);

  const getAsqse2ListDetails = () => {
    toggleLoader(true);
    getAsqse2List()
      .then((response) => {
        if (response) {
          setAsqse2List(response);
        }
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('asqse2DetailsGetFailureMessage');
          setToastrDefaultMessage(
            'Failed to load ASQ: SE2 Ages & Stages Questionnaires details',
          );
        }
      });
  };

  const handleNavigate = () => {
    localStorage.setItem(
      'ASQData',
      JSON.stringify({ data: demographicsLookup, pathname: 'Add' }),
    );
    navigate(ASQ_QUESTIONNAIRES_CLIENT_INFO, {
      state: {
        data: demographicsLookup,
        pathname: 'Add',
      },
    });
  };

  const onEditClick = (selectedRow: Asqse2ListType) => {
    getASQSE2DetailsById(selectedRow.id);
  };

  const onDeleteConfirm = (selectedRowId: string) => {
    toggleLoader(true);
    deleteAsqse2(selectedRowId)
      .then((response) => {
        if (response) {
          setToastrId(response.message);
          setToastrDefaultMessage('ASQSE2 Questionnaire deleted successfully.');
          setShowAlertDialog(undefined);
          getAsqse2ListDetails();
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        }
      });
  };

  const getASQSE2DetailsById = (selectedASQSE2Id: string) => {
    toggleLoader(true);
    if (clientId) {
      getAsqse2ById(selectedASQSE2Id)
        .then(async (response) => {
          if (response) {
            localStorage.setItem(
              'ASQData',
              JSON.stringify({
                data: response,
                pathname: 'Edit',
              }),
            );
            navigate(ASQ_QUESTIONNAIRES_CLIENT_INFO, {
              state: {
                data: response,
                pathname: 'Edit',
              },
            });
            toggleLoader(false);
          }
        })
        .catch(async (error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('asqse2DetailsGetFailureMessage');
            setToastrDefaultMessage(
              'Failed to load ASQ: SE2 Ages & Stages Questionnaires details',
            );
          }
        });
    }
  };

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          successOrError={successOrError}
        />
      )}

      <Box component="main">
        <Box component="section">
          <Box component="div" className="rowContainer">
            <Grid container>
              <Grid item xs={6} lg={6}>
                <Box className="flex__ align__items__center">
                  <Box className="flex__ ">
                    <CustomBackArrow onClick={() => navigate(FACESHEET)} />
                  </Box>
                  <TitleText
                    className="ml-md"
                    Sxprops={{
                      ml: 1,
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      width: '600px',
                    }}
                    label={clientName}
                  />
                </Box>
              </Grid>
              {asqse2List.length > 0 &&
                checkPermissionForFeature(
                  'backend.clients',
                  'editPermission',
                ) && (
                  <Grid item xs={6} lg={6} className="text-align-end">
                    <ButtonComponent
                      className="btn-primary btn-submit"
                      variantType="contained"
                      type="submit"
                      labelId="Intake.AddQuestionnaire"
                      defaultLabelId="Add Questionnaire"
                      onClick={() => handleNavigate()}
                    />
                  </Grid>
                )}
            </Grid>
          </Box>

          {showAlertDialog && (
            <ModalPopup
              open={showAlertDialog !== undefined}
              onCancel={() => {
                setShowAlertDialog(undefined);
              }}
              description="CommunicationLogTableView.deleteDescription"
              onOk={() => onDeleteConfirm(showAlertDialog)}
              labelId1="Clientpage.cancelbtn"
              negativeActionLabel="cancelText"
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="deleteText"
            />
          )}
          {asqse2List.length > 0 && (
            <Card>
              <TableContainer
                component={Paper}
                sx={{ borderTopLeftRadius: '0px', borderTopRightRadius: '0px' }}
              >
                <Table aria-label="simple table">
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells}
                    checkBoxRequired={false}
                  />
                  <TableBody className="tableRowcss">
                    {asqse2List.length !== 0 &&
                      asqse2List.map((asqse2Data) => (
                        <ASQTableRow
                          key={asqse2Data.id}
                          data={asqse2Data}
                          onEdit={onEditClick}
                          onDelete={() => setShowAlertDialog(asqse2Data.id)}
                        />
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          )}
        </Box>
      </Box>
      {asqse2List && asqse2List.length === 0 && (
        <Box component="main" className="mb-md">
          <Box component="section">
            <EmptyScreen
              titleLabelId="QuestionnaireClientInfo.emptyMessage"
              defaultTitleText="No ASQ: SE-2 Ages & Stages Questionnaires added yet"
              buttonLabelId="Intake.AddQuestionnaire"
              defaultButtonTitleText="Add Questionnaire"
              showButton={checkPermissionForFeature(
                'backend.clients',
                'editPermission',
              )}
              onButtonClick={() => {
                handleNavigate();
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default ASQDashboard;
