import { useRef, useEffect, useState } from 'react';
import { Box } from '@mui/system';
import Textinput from './Textinput';
import { ReactComponent as MicOnGreen } from '../../assets/images/micOnGreen.svg';
import { ReactComponent as MicOn } from '../../assets/images/micOn.svg';

import MediumTypography from './MediumTypography';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import '../../pages/ProgressNote/listeningDot.css';

type VoiceRecognitionType = {
  initialNote: string;
  onNoteChange: (value: string) => void;
  disabled?: boolean;
};

export const turnOfMic = () => {
  SpeechRecognition.stopListening();
};

const VoiceRecognition = (props: VoiceRecognitionType) => {
  const [speechValue, setSpeechValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const {
    transcript,
    listening,
    resetTranscript,

    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (!props.disabled && totalCharacterCount < 8000) {
      if (listening) {
        if (transcript.length !== 0) setSpeechValue(transcript);
      }
    } else {
      resetTranscript();
      SpeechRecognition.stopListening();
      const remainingCharacters = 8000 - props.initialNote.length;
      const trimmedTranscript = transcript.slice(0, remainingCharacters);
      setTimeout(() => {
        props.onNoteChange(props.initialNote + trimmedTranscript);
        resetTranscript();
      }, 500);
    }
  }, [transcript]);

  useEffect(() => {
    setTimeout(() => {
      props.onNoteChange(props.initialNote + speechValue);
      resetTranscript();
    }, 1000);
  }, [speechValue]);

  const startListeningHandler = () => {
    if (!browserSupportsSpeechRecognition) {
      return <span>Browser doesn't support speech recognition.</span>;
    }
    if (!props.disabled && totalCharacterCount < 8000) {
      SpeechRecognition.startListening({ continuous: true });
    }
  };
  const stopListeningHandler = () => {
    if (!props.disabled) {
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  };
  const onkeyPress = () => {
    stopListeningHandler();
  };
  const totalCharacterCount = props.initialNote.length + transcript.length;

  return (
    <Box
      sx={{
        position: 'relative',
        width: '80%',
      }}
    >
      <Textinput
        className="bg__white"
        Value={props.initialNote}
        inputRef={inputRef}
        multiline={true}
        handlechange={(value) => {
          if (totalCharacterCount <= 8000) {
            props.onNoteChange(value);
          }
        }}
        labelid="ProgressNote.EnterNotsHere"
        defaultlabelid="Enter notes here"
        inputProps={{
          style: {
            height: '150px',
            paddingBottom: '8px',
            cursor: props.disabled ? 'not-allowed' : 'text',
            padding: '0px 0px 0px 0px',
            marginRight: '20px',
            textAlign: 'justify',
          },
          maxLength: 8000,
        }}
        onKeyUp={() => onkeyPress()}
        onFocus={() => {
          SpeechRecognition.stopListening();
        }}
        disabled={props.disabled}
      />
      {listening && totalCharacterCount < 8000 ? (
        <>
          <MicOnGreen
            onClick={() => SpeechRecognition.stopListening()}
            style={{
              position: 'absolute',
              top: 0,
              right: 0,
              marginRight: '10px',
              marginTop: '10px',
              cursor: props.disabled ? 'not-allowed' : 'pointer',
            }}
          />
          <Box
            sx={{
              width: '70px',
              height: '30px',
              backgroundColor: '#E7F0F0',
              borderRadius: '4px',
              position: 'absolute',
              top: 40,
              right: -20,
            }}
          >
            <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
              <MediumTypography
                textstyle="center"
                textColor="#2A4241"
                fontSize="11px"
                labelid="ProgressNote.SpeakNow"
                defaultlabel="Speak Now"
              />
              <div style={{ marginLeft: '32px' }} className="dots-5"></div>
              <Box></Box>
            </Box>
          </Box>
        </>
      ) : (
        <MicOn
          onClick={startListeningHandler}
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            marginRight: '10px',
            marginTop: '10px',
            cursor: props.disabled ? 'not-allowed' : 'pointer',
          }}
        />
      )}
      <MediumTypography
        labelid="dv"
        defaultlabel={
          totalCharacterCount < 8000
            ? `${8000 - totalCharacterCount} char`
            : `${8000 - (totalCharacterCount - transcript.length)} char`
        }
        fontSize="12px"
        fontweight={500}
        textColor="#97A6A5"
        sxProps={{
          position: 'absolute',
          right: 0,
          bottom: 0,
          marginRight: '10px',
        }}
      />
    </Box>
  );
};

export default VoiceRecognition;
