import { Box, Dialog, DialogContent } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Textinput from './Textinput';
import ButtonComponent from './ButtonComponent';
import MediumTypography from './MediumTypography';

export interface QuestionnaireModelProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (question: string) => void;
}

const QuestionnaireModel: FC<QuestionnaireModelProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const [question, setQuestion] = useState<string>('');

  useEffect(() => {
    return () => {
      setQuestion('');
    };
  }, []);

  return (
    <Dialog open={open} onClose={onClose} maxWidth={'sm'} fullWidth>
      <DialogContent sx={{ p: 0 }} className="p-lg">
        <MediumTypography
          className="mainText-lg"
          labelid="ProgressNote.AddQuestionnaire"
          defaultlabel="Add Questionnaire"
        />
        <Textinput
          className="mt-md"
          labelid="enterQuestionText"
          defaultlabelid="Enter Question"
          inputProps={{ maxLength: 75 }}
          handlechange={(value: string) => {
            setQuestion(value);
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonComponent
            variantType="contained"
            type="submit"
            className="btn-primary btn-submit alrt_Width_btn mt-md"
            labelId={'BlockTime.addButton'}
            defaultLabelId={'Add'}
            onClick={() => {
              if (question.length > 0) {
                onSubmit(question);
                onClose();
              }
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default QuestionnaireModel;
