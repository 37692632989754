import {
  Box,
  Dialog,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';
import TitleText from '../../components/formlib/TitleText';
import { useIntl } from 'react-intl';
import MediumTypography from '../../components/formlib/MediumTypography';
import dayjs from 'dayjs';
import { ReactComponent as CloseIcon } from '../../assets/images/x.svg';
import { Holiday } from '../../services/configApi/employees/holidayServices';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import { getHolidayHeaderData } from './holidayUtils';
import { getFinancialYear } from '../../utils/dateUtil';

export interface HolidayListProps {
  open: boolean;
  onClose: () => void;
  holidays: Holiday[];
}

export interface HolidayHeader {
  month: string;
  date: string;
  holidayName: string;
}

const HolidayListDialog: FC<HolidayListProps> = ({
  open,
  onClose,
  holidays,
}) => {
  const rctl = useIntl();
  const [headerCells] = useState<HeadCell<HolidayHeader>[]>(
    getHolidayHeaderData(),
  );

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth={'sm'}
      scroll="body"
      PaperProps={{
        sx: { width: '100%' },
      }}
    >
      <DialogTitle id="customized-dialog-title">
        <Box className="flex__">
          <Box className="flex__" sx={{ flex: 'auto' }}>
            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}
            <TitleText
              labelid={`${rctl.formatMessage({
                id: 'holidayListText',
                defaultMessage: 'Holiday List',
              })} - `}
              defaultlabel="Holiday List -"
            />
            <MediumTypography
              labelid={getFinancialYear()}
              sxProps={{
                color: '#008C82',
                fontWeight: 'bold',
                fontSize: '24px',
                lineHeight: '26px',
              }}
            />
            {/* </Box> */}
          </Box>
          <Box onClick={onClose} sx={{ cursor: 'pointer' }}>
            <CloseIcon />
          </Box>
        </Box>
      </DialogTitle>

      <TableContainer className="pl-lg pr-lg pb-lg">
        <Table aria-label="simple table">
          <TableHeader
            className="listDataTableHead holidayBorderHeader"
            labelSxProp={{ whiteSpace: 'normal', paddingLeft: '0px' }}
            headerNames={headerCells}
            checkBoxRequired={false}
          />
          <TableBody className="tableRowcss">
            {holidays.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <MediumTypography
                    labelid="noHolidayFoundText"
                    defaultlabel="No Holidays Found"
                  />
                </TableCell>
              </TableRow>
            ) : (
              holidays.map((holiday) => (
                <TableRow
                  hover
                  tabIndex={-1}
                  sx={{
                    alignContent: 'flex-start',
                    '&:nth-of-type(odd)': {
                      backgroundColor: '#ECF9F8',
                    },
                  }}
                >
                  <TableCell>
                    {dayjs(holiday.holidayDate, 'MM-DD-YYYY').format('MMMM')}
                  </TableCell>
                  <TableCell>
                    {dayjs(holiday.holidayDate, 'MM-DD-YYYY').format('DD')}
                  </TableCell>
                  <Tooltip
                    className="cursorPointer"
                    id="tool-tip"
                    arrow
                    followCursor
                    title={holiday.title}
                    placement="top"
                    sx={{ zIndex: '9999 !important' }}
                  >
                    <TableCell
                      sx={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '100px',
                        fontFamily: 'Lato-Regular',
                      }}
                    >
                      {holiday.title}
                    </TableCell>
                  </Tooltip>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};

export default HolidayListDialog;
