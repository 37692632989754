import { Box } from '@mui/system';
import { FC } from 'react';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import MediumTypography from '../../components/formlib/MediumTypography';

type ConsentFormData = {
  id?: string;
  dataLabelId: string;
  defaultDataLabelId: string;
};
type ConsentFormCheckBox = {
  data: ConsentFormData[] | undefined;
  titleLabel?: string;
  defaultTitleLabel?: string;
  value?: { checked: boolean }[];
  onClickChange?: (value: boolean, index: number) => void;
  inline?: boolean;
  subTitleLabel?: string;
  defaultSubTitleLabel?: string;
  subTitleRequired?: boolean;
};

const ConsentFormTextWithCheckbox: FC<ConsentFormCheckBox> = (props) => {
  return (
    <Box className="width__100">
      <MediumTypography
        labelid={props.titleLabel}
        defaultlabel={props.defaultTitleLabel}
        fontweight={600}
        className="pb-sm"
      />

      {props.subTitleRequired && (
        <MediumTypography
          labelid={props.subTitleLabel}
          defaultlabel={props.defaultSubTitleLabel}
          className="mb-sm"
          textColor="#00C6B8"
        />
      )}

      {props.data?.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            display: props.inline ? 'inline-block' : 'block',
            width: props.inline ? '50%' : '100%',
            gap: '24px',
          }}
        >
          <Box className="flex__">
            <CustomCheckBox
              value={props.value ? props.value[index].checked : false}
              onCheckBoxClick={(e) => {
                if (props.onClickChange !== undefined) {
                  props.onClickChange(e, index);
                }
              }}
            />
            <MediumTypography
              labelid={item.dataLabelId}
              defaultlabel={item.defaultDataLabelId}
              className="mt-xs ml-xs"
              textColor="#2A4241"
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ConsentFormTextWithCheckbox;
