import React from 'react';
import { Box } from '@mui/system';
import TitleText from '../../components/formlib/TitleText';
import { SchedulerEventType } from '../../utils/type';
import { Typography } from '@mui/material';
import moment from 'moment';
import SmallTypography from '../../components/formlib/SmallTypography';
import { ReactComponent as ArrowRight } from '../../assets/images/rightWhite.svg';
import { useNavigate } from 'react-router';
import { PROGRESS_NOTE_FACESHEET } from '../../routes/Routing';

interface UpcomingEventsFaceSheetType {
  data: SchedulerEventType[];
}

const UpcomingEventsFaceSheet: React.FC<UpcomingEventsFaceSheetType> = (
  props,
) => {
  const navigation = useNavigate();

  const renderHeader = () => {
    return (
      <>
        <Box
          sx={{
            float: 'right',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <ArrowRight
            onMouseDown={(e) => e.stopPropagation}
            onTouchStart={(e) => e.stopPropagation}
            onClick={() => {
              navigation(PROGRESS_NOTE_FACESHEET);
            }}
          />
        </Box>
        <TitleText
          labelid="upcommingEventsOfflineText"
          defaultlabel="Events"
          Sxprops={{ fontSize: '18px', color: 'white' }}
        />
      </>
    );
  };

  return (
    <>
      {renderHeader()}
      <Box
        sx={{
          overflowY: 'scroll',
          height: '200px',
          '&::-webkit-scrollbar': {
            width: '6px',
            background: 'transparent',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'rgba(12, 130, 121, 1)',
            borderRadius: '4px',
            border: '1px solid rgba(12, 130, 121, 1)',
          },
        }}
      >
        {props.data.length === 0 && (
          <SmallTypography
            sxProps={{
              fontSize: '18px',
              color: '#FFF',
              textAlign: 'center',
              marginY: 8,
            }}
            labelId="noUpcommingFoundText"
            defaultLabelId="No Events found"
          />
        )}
        {props.data.map((eventProps: SchedulerEventType) => {
          return (
            <Box sx={{ marginY: '16px' }} key={eventProps.id}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 0.5,
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '20px',
                      fontWeight: 600,
                      fontFamily: 'Lato-Regular',
                      color: '#FFF',
                    }}
                  >
                    {moment(eventProps.start, 'YYYY-MM-DD HH:mm:ss')
                      .utc(true)
                      .local()
                      .format('MMM DD, ')}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: 500,
                      fontFamily: 'Lato-Regular',
                      lineHeight: '32px',
                      color: '#FFF',
                      marginTop: 2,
                    }}
                  >
                    {moment(eventProps.start, 'YYYY-MM-DD HH:mm:ss')
                      .utc(true)
                      .local()
                      .format('YYYY - dddd')}
                  </Typography>
                </Box>
                <SmallTypography
                  sxProps={{
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '32px',
                    color: '#FFF',
                  }}
                  labelId={eventProps.appointmentType}
                  defaultLabelId="N/A"
                />
              </Box>

              {props.data.length - 1 !== props.data.indexOf(eventProps) && (
                <Box className="horizontalLine" sx={{ marginY: '4px' }} />
              )}
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default UpcomingEventsFaceSheet;
