interface EventBus {
  subscribe: (eventName: string, callback: () => void) => void;
  publish: (eventName: string) => void;
}

export const EventBusProvider: EventBus = {
  subscribe: (eventName, callback) => {
    document.addEventListener(eventName, () => callback());
  },
  publish: (eventName) => {
    document.dispatchEvent(new Event(eventName));
  },
};
