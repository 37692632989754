import { ApiResponse } from 'apisauce';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export interface HolidayResponse {
  holidayList: Holiday[];
}

export interface HolidayRequest {
  holidayList: Holiday[];
  programId: string;
}

export interface Holiday {
  id: string;
  holidayDate: string | null;
  title: string;
  isPastDate: boolean;
}

export const getHolidayList = async (
  date: string,
  programId: string,
): Promise<HolidayResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<HolidayResponse>(
      `holidays?date=${date}&program=${programId}`,
    ),
  );
};

export const createHolidayList = async (
  holidays: HolidayRequest,
  programId: string,
): Promise<ApiResponse<HolidayRequest, ApiMessage>> => {
  holidays.programId = programId;
  return ThomApiClient.getInstance().post<HolidayRequest, ApiMessage>(
    `holidays`,
    holidays,
  );
};

export const updateHolidayList = async (
  holidays: HolidayRequest,
): Promise<ApiResponse<HolidayRequest, ApiMessage>> => {
  return ThomApiClient.getInstance().put<HolidayRequest, ApiMessage>(
    `holidays`,
    holidays,
  );
};

export const deleteHoliday = async (
  date: string,
  programId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `holiday?date=${date}&program=${programId}`,
    ),
  );
};
