import React, { useEffect } from 'react';

import {
  Card,
  Box,
  Typography,
  Button,
  Badge,
  Grid,
  AlertColor,
  Tooltip,
  styled,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import './Contacts.css';
import SelectComponent from '../components/formlib/SelectComponent';
import ProviderSpecialist from './contactTypes/ProviderSpecialist';
import FamilyContact from './contactTypes/FamilyContact';
import { deleteContact, getAllContacts } from '../services/configApi/User';
import {
  ContactTypes,
  FamilyContactType,
  SSPContactTypes,
  ProviderSpecialistType,
  InsuranceContactType,
  OtherContactType,
  LeaSchoolContactType,
  ReferralContactType,
  Order,
} from '../utils/type';
import SnackBarComponent from '../components/formlib/SnackBarComponent';
import SSPContact from './contactTypes/SSPContact';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { contacts } from './Dropdown';
import ModalPopup from '../components/formlib/ModalPopup';
import InsuranceContact from './contactTypes/InsuranceContact';
import OthersContact from './contactTypes/OthersContact';
import LeaSchoolContact from './contactTypes/LeaSchoolContact';
import { ReactComponent as CheckIcon } from '../assets/images/Success.svg';
import { ReactComponent as CheckIcon2 } from '../assets/images/Success2.svg';
import { ReactComponent as EditIcon } from '../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/DeleteIcon.svg';
import { ReactComponent as GraphicBottomRight } from '../assets/images/Graphic-BottomRight.svg';
import { ReactComponent as GraphicBottomLeft } from '../assets/images/Graphic-righttop.svg';
import ReferralContact from './contactTypes/ReferralContact';
import { LoaderContext, LoaderContextType } from '../layouts/AppSidebar';
import { HeadCell, TableHeader } from '../components/formlib/TableHeader';
import TooltipTableCell from '../components/formlib/TooltipTableCell';
import { ApiError, isCustomError } from '../services/ApiResponseHandler';
import { checkPermissionForFeature } from '../utils/checkPermission';

const CustomTableCell = styled(TableCell)(() => ({
  paddingLeft: '8px',
  fontFamily: 'Lato-Regular',
  fontWeight: 500,
  fontSize: '14px',
}));

const ellipsisCellSX = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '170px',
  borderBottom: 'none',
  padding: '6px 0px',
};

interface Data {
  name: string;
  type: string;
  phone: string;
  email: string;
  status: string;
  actions: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'Contacts.Name',
    defaultLabelId: 'Name of the Contact',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'type',
    labelId: 'Contacts.Type',
    defaultLabelId: 'Contact Type',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'phone',
    labelId: 'Contacts.phone',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'Contacts.email',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    labelId: 'Contacts.status',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: color ?? '#37D183',
    height: '8px',
    width: '8px',
    top: 0,
    bottom: 0,
    marginRight: '10px',
  },
}));

const badgePicker = (status: string) => {
  if (status === 'InActive') {
    return (
      <CustomBadge
        variant="dot"
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: 'red',
          },
        }}
      />
    );
  } else {
    return <CustomBadge variant="dot" />;
  }
};

const Contacts = () => {
  const [initialScreen, setInitialScreen] = React.useState(true);
  const [tableData, setTableData] = React.useState<ContactTypes[]>([]);
  const [familyValues, setFamilyValues] = React.useState<FamilyContactType>();
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [providerValues, setProviderValues] =
    React.useState<ProviderSpecialistType>();
  const [sspValues, setSSPValues] = React.useState<SSPContactTypes>();
  const [insuranceValues, setInsuranceValues] =
    React.useState<InsuranceContactType>();
  const [otherValues, setOtherValues] = React.useState<OtherContactType>();
  const [leaValues, setLeaValues] = React.useState<LeaSchoolContactType>();
  const [referralContactValues, setReferralContactValues] =
    React.useState<ReferralContactType>();

  const initialValues: FamilyContactType = {
    otherRelationshipName: '',
    contactType: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    relationship: '',
    contactTypeOthers: '',
    street: '',
    apartment: '',
    zipCode: '',
    city: '',
    state: '',
    birthPlace: '',
    maritalStatus: '',
    education: '',
    occupation: '',
    isFullTimeEmployee: null,
    startDate: dayjs(new Date()),
    endDate: null,
    isActive: null,
    homeNumber: '',
    dateOfBirth: null,
    contactId: '',
    isEdit: false,
    links: [''],
    isPrimary: false,
    notes: '',
    isHouseHoldMember: false,
    fax: '',
  };

  const initialValuesProvider: ProviderSpecialistType = {
    contactType: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    street: '',
    apartment: '',
    zipCode: '',
    city: '',
    state: '',
    startDate: dayjs(new Date()),
    endDate: null,
    isActive: null,
    designation: '',
    links: [''],
    areaServed: '',
    homeNumber: '',
    contactId: '',
    isEdit: false,
    companyOrMedicalPracticeName: '',
    isHouseHoldMember: false,
    notes: '',
    fax: '',
  };

  const initialValuesSSP: SSPContactTypes = {
    contactType: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    street: '',
    apartment: '',
    zipCode: '',
    city: '',
    state: '',
    startDate: dayjs(new Date()),
    endDate: null,
    isActive: null,
    links: [''],
    homeNumber: '',
    sspContactType: [''],
    sspService: '',
    otherSSPService: '',
    contactId: '',
    isEdit: false,
    companyOrMedicalPracticeName: '',
    isHouseHoldMember: false,
    notes: '',
    fax: '',
  };

  const initialValuesLea: LeaSchoolContactType = {
    contactType: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    street: '',
    apartment: '',
    zipCode: '',
    city: '',
    state: '',
    startDate: dayjs(new Date()),
    endDate: null,
    isActive: true,
    links: [''],
    homeNumber: '',
    contactId: '',
    isEdit: false,
    companyOrMedicalPracticeName: '',
    isHouseHoldMember: false,
    notes: '',
    fax: '',
  };

  const initialValuesInsurance: InsuranceContactType = {
    contactType: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    startDate: dayjs(new Date()),
    endDate: null,
    isActive: null,
    insuranceCompany: '',
    contactId: '',
    isEdit: false,
    links: [''],
    companyOrMedicalPracticeName: '',
    isHouseHoldMember: false,
    notes: '',
    fax: '',
  };

  const initialValuesOthers: OtherContactType = {
    contactType: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    startDate: dayjs(new Date()),
    endDate: null,
    isActive: null,
    contactId: '',
    isEdit: false,
    organization: '',
    notes: '',
    links: [''],
    companyOrMedicalPracticeName: '',
    isHouseHoldMember: false,
    fax: '',
  };

  const initialValuesReferral: ReferralContactType = {
    contactType: '',
    contactId: '',
    firstName: '',
    isEdit: false,
    referralContactDate: null,
    jobTitle: '',
    email: '',
    fax: '',
    phoneNumber: '',
    referralSource: '',
    referralReason: '',
    startDate: dayjs(new Date()),
    endDate: null,
    isActive: true,
    notes: '',
    companyOrMedicalPracticeName: '',
    isHouseHoldMember: false,
  };

  const [type, setType] = React.useState('');
  const [editText, setEditText] = React.useState(false);
  const [editable, setEditable] = React.useState(false);
  const [showAlertDialog, setShowAlertDialog] = React.useState<boolean>(false);
  const [contactIdDelete, setContactIdDelete] = React.useState('');
  const [showPopup, setShowPopup] = React.useState<boolean>(false);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('type');
  const [isScreenLocked, setIsScreenLocked] = React.useState<boolean>(false);

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
    const sortedResponse = [...tableData]; // Make a copy of the array
    sortedResponse.sort((a, b) => {
      if (order === 'desc') {
        return a.contactType.localeCompare(b.contactType);
      } else {
        return b.contactType.localeCompare(a.contactType);
      }
    });
    setTableData(sortedResponse);
  };

  const Custombuttonsubmit = styled(Button)`
    background: #00938e;
    border-radius: 4px;
    font-family: 'Lato-Regular', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.8px;
    color: #ffffff;
    padding: 10px, 16px, 10px, 16px;
    text-transform: 'none';
    &:hover {
      background: #00938e;
      color: #ffffff;
    }
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 122px;
    height: 40px;
  `;

  const handleAdd = () => {
    setInitialScreen(false);
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setEditText(false);
    toggleLoader(true);
    if (id !== '') {
      toggleLoader(false);
      setOpen(true);
      setToastrVariable(successError);
      setToastrId(id);
      setToastrDefaultMessage(mes);
    }
    setType('');
    getAllContactsAPI();
  };

  const getAllContactsAPI = () => {
    const clientId: string = localStorage.getItem('ClientId') ?? '';
    toggleLoader(true);
    getAllContacts(clientId)
      .then((response: ContactTypes[]) => {
        if (response.length > 0) {
          toggleLoader(false);
          setInitialScreen(false);
          setType('');
        } else {
          setInitialScreen(true);
          toggleLoader(false);
        }

        const filteredData: ContactTypes[] = response.filter((f) => {
          return f.contactType !== 'Family' && !f.isGuardian;
        });

        setTableData(filteredData);
      })
      .catch((error) => {
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Family.gettoastrError');
          setToastrDefaultMessage('Failed to get contact details');
        }
      });
  };

  useEffect(() => {
    getAllContactsAPI();
  }, []);
  const editContact = (rowValues: ContactTypes) => {
    setEditText(true);
    setType(rowValues.contactType);
    setEditable(false);
    if (rowValues.contactType === 'Family') {
      setFamilyValues(rowValues);
    } else if (rowValues.contactType === 'Provider/Medical Specialist') {
      setProviderValues(rowValues);
    } else if (rowValues.contactType === 'Specialty Service Provider') {
      setSSPValues(rowValues);
    } else if (rowValues.contactType === 'Insurance') {
      setInsuranceValues(rowValues);
    } else if (rowValues.contactType === 'Others') {
      setOtherValues(rowValues);
    } else if (rowValues.contactType === 'Local Education Authority') {
      setLeaValues(rowValues);
    } else if (rowValues.contactType === 'Referral Contact') {
      setReferralContactValues(rowValues);
    }
  };

  const handleDelete = (contactid: string) => {
    setContactIdDelete(contactid);
    setShowAlertDialog(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const deleteLog = () => {
    setShowAlertDialog(false);
    toggleLoader(true);
    if (contactIdDelete !== '') {
      deleteContact(contactIdDelete)
        .then(() => {
          toggleLoader(false);
          getAllContactsAPI();
          setOpen(true);
          setToastrVariable('success');
          setToastrDefaultMessage('Record deleted Successfully');
          setToastrId('Contacts.deleteSuccessToastr');
        })
        .catch((error) => {
          setOpen(true);
          toggleLoader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrDefaultMessage('Failed to delete');
            setToastrId('Contacts.deleteToastr');
          }
        });
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const getContactName = (firstName: string, lastName: string) => {
    return [firstName, lastName].filter(Boolean).join(' ');
  };

  const getReferralContactName = (firstName: string, lastName: string) => {
    if (firstName || lastName) {
      return [firstName, lastName].filter(Boolean).join(' ');
    } else {
      return 'Referral Contact';
    }
  };

  return (
    <Box component="div">
      <Box
        ref={parentRef}
        style={{
          pointerEvents: isScreenLocked ? 'none' : 'auto',
          opacity: isScreenLocked ? '0.5' : '1 ',
        }}
        onKeyDownCapture={(e) => {
          if (isScreenLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (isScreenLocked) {
            if (parentRef.current) parentRef.current.focus();
          }
        }}
      >
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        {showAlertDialog && (
          <ModalPopup
            open={showAlertDialog}
            onCancel={() => {
              setShowAlertDialog(false);
            }}
            description="CommunicationLogTableView.deleteDescription"
            onOk={() => deleteLog()}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}

        {showPopup && (
          <ModalPopup
            open={showPopup}
            description="Contact.insuranceDeletePopup"
            onOk={() => closePopup()}
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="deleteText"
          />
        )}
        <Box>
          <Box component="section">
            <Box>
              {initialScreen && (
                <Card
                  sx={{
                    padding: '24px',
                    my: '0px',
                    ml: '0px',
                    height: '500px',
                    overflow: 'hidden',
                  }}
                >
                  <GraphicBottomRight className="GraphicBottomRight" />

                  <GraphicBottomLeft className="GraphicBottomLeft" />

                  <div className="textCenter">
                    <Typography id="text-contact">
                      <FormattedMessage
                        id="Contacts.nocontacts"
                        defaultMessage="No contacts added yet"
                      />
                    </Typography>
                    {checkPermissionForFeature(
                      'backend.clients',
                      'editPermission',
                    ) && (
                      <Custombuttonsubmit id="contact-btn" onClick={handleAdd}>
                        <FormattedMessage
                          id="Contacts.save"
                          defaultMessage="Add Contact"
                        />
                      </Custombuttonsubmit>
                    )}
                  </div>
                </Card>
              )}
            </Box>
            {initialScreen === false && (
              <TableContainer sx={{ maxHeight: 310 }} className="tableStyles">
                <Table stickyHeader aria-label="sticky table">
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells}
                    checkBoxRequired={false}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />

                  <TableBody className="tableRowcss">
                    {tableData.length === 0 ? ( // Check if tableData is empty
                      <TableRow>
                        <TableCell colSpan={5} align="center" id="nodata">
                          <FormattedMessage
                            id="Contacts.nocontacts"
                            defaultMessage="No contacts added yet"
                          />
                        </TableCell>
                      </TableRow>
                    ) : (
                      tableData.map((row: ContactTypes, index: number) => (
                        <TableRow
                          hover
                          tabIndex={-1}
                          key={row.contactId}
                          sx={{
                            cursor: 'pointer',
                            backgroundColor:
                              index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                          }}
                        >
                          <Tooltip
                            title={
                              row.contactType === 'Referral Contact'
                                ? getReferralContactName(
                                    row.firstName,
                                    row.lastName,
                                  )
                                : getContactName(row.firstName, row.lastName)
                            }
                            arrow
                            followCursor
                            placement="bottom"
                          >
                            <Box
                              className="flex__"
                              sx={{
                                height: '40px',
                                padding: '0px 14px',
                                borderBottom:
                                  '1px solid rgba(224, 224, 224, 1)',
                              }}
                            >
                              <CustomTableCell sx={ellipsisCellSX}>
                                {row.contactType === 'Referral Contact'
                                  ? getReferralContactName(
                                      row.firstName,
                                      row.lastName,
                                    )
                                  : getContactName(row.firstName, row.lastName)}
                              </CustomTableCell>
                              {row.isPrimary && (
                                <Tooltip
                                  id="tool-tip"
                                  title={'Primary Parent'}
                                  placement="right"
                                >
                                  <CheckIcon
                                    className="position__relative cursor__pointer"
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      left: '0.9px',
                                      marginTop: '2.8%',
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {row.isLegal && (
                                <Tooltip
                                  id="tool-tip"
                                  title={'Legal Guardian'}
                                  placement="right"
                                >
                                  <CheckIcon2
                                    className="position__relative cursor__pointer"
                                    style={{
                                      width: '15px',
                                      height: '15px',
                                      left: '0.9px',
                                      marginTop: '2.8%',
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </Tooltip>

                          <TooltipTableCell
                            value={row.contactType}
                            visible={true}
                          />

                          {row.contactType !== 'Insurance' &&
                          row.contactType !== 'Others' &&
                          row.contactType !== 'Referral Contact' ? (
                            <TooltipTableCell
                              value={row.homeNumber ? row.homeNumber : '-'}
                              visible={true}
                            />
                          ) : (
                            <TooltipTableCell
                              value={row.phoneNumber ? row.phoneNumber : '-'}
                              visible={true}
                            />
                          )}

                          <TooltipTableCell
                            value={row.email ? row.email : '-'}
                            visible={true}
                          />
                          <TableCell
                            sx={{
                              fontFamily: 'Lato-Regular',
                            }}
                          >
                            <Box
                              className="flex__ alignItemCenter position__relative"
                              sx={{ left: '13px' }}
                            >
                              {badgePicker(
                                row.isActive ? 'Active' : 'InActive',
                              )}
                              {row.isActive ? 'Active' : 'InActive'}
                            </Box>
                          </TableCell>

                          <TableCell>
                            <Box className="flex__ alignItemCenter">
                              <Box>
                                <Tooltip title="Edit">
                                  <EditIcon
                                    onClick={() => editContact(row)}
                                    className="cursorPointer"
                                  />
                                </Tooltip>
                              </Box>
                              <Box className="ml-md">
                                <Tooltip title="Delete">
                                  <DeleteIcon
                                    style={{
                                      position: 'relative',
                                      ...(row.contactType === 'Referral Contact'
                                        ? {
                                            cursor: 'not-allowed',
                                            pointerEvents: 'none',
                                            opacity: '0.5',
                                          }
                                        : { cursor: 'pointer' }),
                                    }}
                                    onClick={() => handleDelete(row.contactId)}
                                  />
                                </Tooltip>
                              </Box>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {initialScreen === false && (
              <Grid rowSpacing={'24px'} columnSpacing={'16px'}>
                <Typography id="headercontact">
                  {editText ? 'Update Contact' : 'Add New Contact'}
                </Typography>
              </Grid>
            )}
            {initialScreen === false && (
              <Box sx={{ my: '2%' }}>
                <Card
                  sx={{
                    padding: '24px',
                    my: '0px',
                    ml: '0px',
                    height: '100%',
                    overflow: 'hidden',
                    borderBottomLeftRadius: '0px',
                    borderBottomRightRadius: '0px',
                  }}
                >
                  <Grid container rowSpacing={'24px'} columnSpacing={'40px'}>
                    <Grid item xs={4.5}>
                      <SelectComponent
                        labelid="contacts.contactType"
                        defaultlabelid="Contact Type"
                        name={`contactType`}
                        handleChange={(value: string) => {
                          setType(value);
                          setEditable(true);
                        }}
                        value={type}
                        names={contacts}
                      />
                    </Grid>
                    <Grid item xs={4.5}></Grid>
                  </Grid>
                </Card>
                {type === 'Family' && (
                  <FamilyContact
                    contacttype={type}
                    onSuccess={handleSuccess}
                    editable={editable}
                    familyValues={familyValues || initialValues}
                  />
                )}
                {type === 'Provider/Medical Specialist' && (
                  <ProviderSpecialist
                    contacttype={type}
                    onSuccess={handleSuccess}
                    editable={editable}
                    providerValues={providerValues || initialValuesProvider}
                  />
                )}
                {type === 'Specialty Service Provider' && (
                  <SSPContact
                    contacttype={type}
                    onSuccess={handleSuccess}
                    editable={editable}
                    sspValues={sspValues || initialValuesSSP}
                  />
                )}
                {type === 'Insurance' && (
                  <InsuranceContact
                    contacttype={type}
                    onSuccess={handleSuccess}
                    editable={editable}
                    insuranceValues={insuranceValues || initialValuesInsurance}
                  />
                )}
                {type === 'Others' && (
                  <OthersContact
                    contacttype={type}
                    onSuccess={handleSuccess}
                    editable={editable}
                    otherValues={otherValues || initialValuesOthers}
                  />
                )}
                {type === 'Local Education Authority' && (
                  <LeaSchoolContact
                    contacttype={type}
                    onSuccess={handleSuccess}
                    editable={editable}
                    leaValues={leaValues || initialValuesLea}
                  />
                )}
                {type === 'Referral Contact' && (
                  <ReferralContact
                    contacttype={type}
                    onSuccess={handleSuccess}
                    editable={editable}
                    referralValues={
                      referralContactValues || initialValuesReferral
                    }
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contacts;
