import {
  AlertColor,
  Box,
  Card,
  Grid,
  Link,
  autocompleteClasses,
} from '@mui/material';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';

import ProgramDetails from './ProgramDetails';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import ClientDetails from './ClientDetails';
import ParentDetails from './ParentDetails';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import Textinput from '../../components/formlib/Textinput';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import { Formik, FormikProps, getIn, setIn } from 'formik';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { OptionType } from '../../utils/type';
import dayjs, { Dayjs } from 'dayjs';
import * as yup from 'yup';
import {
  Organization,
  ReferralToSSPById,
  addReferralToSSpID,
  defaultNotificationGetById,
  defaultNotificationSSP,
} from '../../services/referralToSSPForm/ReferralToSSPApi';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { SSP_LIST } from '../../routes/Routing';
import { useNavigate, useLocation } from 'react-router';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import TitleText from '../../components/formlib/TitleText';
import _, { debounce } from 'lodash';
import ModalPopup from '../../components/formlib/ModalPopup';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import { LookUpContext } from '../../context/LookUpContextProvider';

const ReferralForData = [
  {
    id: 'autism',
    label: 'Autism',
  },
  {
    id: 'hearing',
    label: 'Hearing',
  },
  {
    id: 'vision',
    label: 'Vision',
  },
  {
    id: 'other',
    label: 'Other',
  },
];

const validationSchema = yup.object().shape({
  dateOfReferral: yup
    .string()
    .required('Date of Referral is required')
    .nullable(),
  referralFor: yup.string().required('Referral For is required'),
  specify: yup.string().when('referralFor', {
    is: 'other',
    then: yup.string().required('Specify theraphy'),
    otherwise: yup.string().nullable(),
  }),
  agency: yup.string().when('referralFor', {
    is: 'vision',
    then: yup.string().nullable(),
    otherwise: yup.string().required('Agency is required'),
  }),
  selectedContact: yup.string().required('Contact is required'),
  ssp: yup.string().nullable(),
});

const validateForm = (value: ReferralToSSPById) => {
  let errors: Partial<ReferralToSSPById> = {};

  const dateOfReferral = getIn(value, 'referral[0].dateOfReferral');

  if (dateOfReferral) {
    if (value.dateOfReferral === null) {
      if (dayjs(dateOfReferral).isBefore(dayjs())) {
        errors = setIn(errors, 'referral[0].dateOfReferral', 'validDate');
      }
    } else {
      if (dayjs(dateOfReferral).isBefore(dayjs(value.dateOfReferral))) {
        errors = setIn(errors, 'referral[0].dateOfReferral', 'validDate');
      }
    }
    if (datePickerMinDateMaxDateValidate(dateOfReferral)) {
      errors = setIn(
        errors,
        'referral[0].dateOfReferral',
        'datePickerMinDateMaxDateValidate',
      );
    }
  }

  return errors;
};

type ParentDetailsType = {
  name: string;
  relationshipToClient: string;
  email: string;
  homePhoneNumber: string;
  address: string;
};

const firstValues = {
  id: '',
  programDetails: {
    name: '',
    imageUrl: '',
    email: '',
    phoneNumber: '',
    faxNumber: '',
    addressLine1: '',
    addressLine2: '',
    website: '',
    programDirector: '',
  },
  clientInfo: {
    name: '',
    sex: '',
    enrollmentId: '',
    dob: '',
    referralBeginDate: '',
    doe: '',
    teamLeader: '',
    serviceCoordinator: '',
    email: '',
    phone: '',
  },
  parent: {
    id: '',
    name: '',
    relationship: '',
    email: '',
    phone: '',
    address: '',
  },
  selectedContact: '',
  dateOfReferral: '',
  referralFor: '',
  agency: '',
  ssp: '',
  comment: '',
  specify: '',
  contact: [
    {
      contactId: '',
      name: '',
      homePhoneNumber: '',
      isPrimary: false,
      type: '',
      relationshipToClient: '',
      email: '',
      address: '',
    },
    {
      contactId: '',
      name: '',
      homePhoneNumber: '',
      isPrimary: false,
      type: '',
      relationshipToClient: '',
      email: '',
      address: '',
    },
  ],
  agencyId: '',
  sspId: '',
};

const ReferralToSSP = () => {
  const perkinsUrl =
    'https://www.perkins.org/community-programs/infant-toddler/';

  const [referralSSPData, setReferralSSPData] =
    useState<ReferralToSSPById>(firstValues);
  const [initialReferalSSPDataValue, setInitialReferalSSPDataValue] =
    useState<ReferralToSSPById>(firstValues);
  const [sspDetails, setSSPDetails] = useState<OptionType[]>([]);
  const [sspAgencyDetails, setSSPAgencyDetails] = useState<OptionType[]>([]);
  const [sspListData, setSSPListData] = useState<Organization[] | undefined>();
  const [sspSelectedAgencyDetails, setSSPSelectedAgencyDetails] =
    useState<OptionType | null>(null);

  const [open, setOpen] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [openModal, setOpenModal] = React.useState(false);
  const [parentContactes, setParentContactes] = useState<OptionType[]>([]);
  const [yetToAddContactId, setYetToAddContactId] = useState<string>('');
  const [initialYetToAddContactIdValue, setInitialYetToAddContactIdValue] =
    useState<string>('');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const navigation = useNavigate();
  const location = useLocation();
  const id = location.state?.id;

  const [isScreenLocked, setIsScreenLocked] = React.useState<boolean>(false);
  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const { lookups } = React.useContext(LookUpContext);

  useEffect(() => {
    if (sspListData) {
      if (sspSelectedAgencyDetails) {
        const selectedOrganization = sspListData.find(
          (org) => org.organizationId === sspSelectedAgencyDetails.id,
        );
        if (selectedOrganization) {
          const sspData: OptionType[] = [];
          selectedOrganization.providers.forEach((provider) => {
            sspData.push({
              id: provider.providerId,
              label: `${provider.firstName} ${provider.lastName}`,
            });
          });

          setSSPDetails(sspData);
        }
      }
    }
  }, [sspSelectedAgencyDetails, id]);

  useEffect(() => {
    const clientId = localStorage.getItem('ClientId');
    const localTimezone = dayjs.tz.guess();
    toggleLoader(true);
    if (id == '') {
      defaultNotificationSSP(clientId, localTimezone)
        .then((data: ReferralToSSPById) => {
          toggleLoader(true);
          setSSPListData(data.organization);
          const sspAgency: OptionType[] = [];
          if (data.organization) {
            data.organization.forEach((agency) => {
              sspAgency.push({
                id: agency.organizationId,
                label: `${agency.organizationName}`,
              });
              return sspAgency;
            });
          }
          setSSPAgencyDetails(sspAgency);
          setReferralSSPData({
            clientInfo: data.clientInfo,
            contact: data.contact,
            programDetails: data.programDetails,
            comment: data.comment,
            selectedContact: data.selectedContact,
            dateOfReferral: data.dateOfReferral,
            referralFor: data.referralFor,
            agency: data.agency,
            ssp: data.ssp,
            parent: data.parent,
            id: data.id,
            specify: data.specify,
            agencyId: data.agencyId,
            sspId: data.sspId,
          });
          setInitialYetToAddContactIdValue(data.selectedContact);
          setYetToAddContactId(data.selectedContact);
          const contactList: OptionType[] = [];
          data.contact?.forEach((item) => {
            contactList.push({
              id: item.contactId ?? '',
              label: item.name ?? '',
            });
          });
          setParentContactes(contactList);
          setInitialReferalSSPDataValue(data);
          formRef.current?.setValues(data);
          setSSPSelectedAgencyDetails({
            id: data.agencyId,
            label:
              sspAgencyDetails.find((s) => s.id === data.agencyId)?.label || '',
          });
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('loadFailureMessage');
            setToastrDefaultMessage('loadFailureMessage');
          }
        });
    } else {
      defaultNotificationGetById(id, clientId, localTimezone)
        .then((data: ReferralToSSPById) => {
          toggleLoader(true);
          setSSPListData(data.organization);
          const sspAgency: OptionType[] = [];
          if (data.organization) {
            data.organization.forEach((agency) => {
              sspAgency.push({
                id: agency.organizationId,
                label: `${agency.organizationName}`,
              });
              return sspAgency;
            });
          }
          setSSPAgencyDetails(sspAgency);
          setReferralSSPData({
            clientInfo: data.clientInfo,
            contact: data.contact,
            programDetails: data.programDetails,
            comment: data.comment,
            selectedContact: data.selectedContact,
            dateOfReferral: data.dateOfReferral,
            referralFor: data.referralFor,
            agency: data.agency,
            ssp: data.ssp,
            parent: data.parent,
            id: data.id,
            specify: data.specify,
            agencyId: data.agencyId,
            sspId: data.sspId,
          });
          setInitialYetToAddContactIdValue(data.selectedContact);
          setYetToAddContactId(data.selectedContact);
          const contactList: OptionType[] = [];
          data.contact?.forEach((item) => {
            contactList.push({
              id: item.contactId ?? '',
              label: item.name ?? '',
            });
          });
          setParentContactes(contactList);
          setInitialReferalSSPDataValue(data);
          formRef.current?.setValues(data);
          setSSPSelectedAgencyDetails({
            id: data.agencyId,
            label:
              sspAgencyDetails.find((s) => s.id === data.agencyId)?.label || '',
          });
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('loadFailureMessage');
            setToastrDefaultMessage('loadFailureMessage');
          }
        });
    }
  }, [id]);

  const formRef = useRef<FormikProps<ReferralToSSPById>>(null);

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const saveDebounce = useCallback(
    debounce((updatedValues: ReferralToSSPById) => {
      const clientId = localStorage.getItem('ClientId');
      toggleLoader(true);
      if (referralSSPData === null) {
        return;
      }
      const FormValues: ReferralToSSPById = {
        clientInfo: updatedValues.clientInfo,
        contact: updatedValues.contact,
        programDetails: updatedValues.programDetails,
        comment: updatedValues.comment,
        selectedContact: updatedValues.selectedContact,
        dateOfReferral: updatedValues.dateOfReferral,
        referralFor: updatedValues.referralFor,
        agency: updatedValues.agency,
        ssp: updatedValues.ssp,
        parent: updatedValues.parent,
        id: id ? id : '',
        specify: updatedValues.specify,
        agencyId: updatedValues.agencyId,
        sspId: updatedValues.sspId,
      };

      if (clientId) {
        addReferralToSSpID(clientId, id ? id : '', FormValues)
          .then(() => {
            setOpen(true);
            setToastrVariable('success');
            setToastrId('referralToSSP.save');
            setToastrDefaultMessage('Referral to SSP Data Saved Successfully');
            toggleLoader(false);
            navigation(SSP_LIST);
          })
          .catch((error) => {
            const response = error as ApiMessage;
            setOpen(true);
            setToastrVariable('error');
            setToastrId('referralToSSP.failedToSave');
            setToastrDefaultMessage(response.message);
            toggleLoader(false);
          });
      }
    }, 500),
    [],
  );

  const primaryParent: ParentDetailsType = {
    name: referralSSPData.parent.name,
    relationshipToClient:
      lookups?.guardianType?.find(
        (g) =>
          g.code.toUpperCase() ===
          referralSSPData.parent.relationship?.toUpperCase(),
      )?.description || '-',
    email: referralSSPData.parent.email,
    homePhoneNumber: referralSSPData.parent.phone,
    address: referralSSPData.parent.address,
  };

  return (
    <Formik
      initialValues={referralSSPData}
      validationSchema={validationSchema}
      validate={validateForm}
      innerRef={formRef}
      onSubmit={(values: ReferralToSSPById) => {
        saveDebounce(values);
      }}
    >
      {({ values, setFieldValue, errors, touched, handleSubmit }) => (
        <Box component={'main'}>
          <div
            ref={parentRef}
            style={{
              pointerEvents: isScreenLocked ? 'none' : 'auto',
              opacity: isScreenLocked ? '0.5' : '1 ',
            }}
            onKeyDownCapture={(e) => {
              if (isScreenLocked) {
                e.preventDefault();
                e.stopPropagation();
              }
            }}
            onFocus={() => {
              if (isScreenLocked) {
                if (parentRef.current) parentRef.current.focus();
              }
            }}
          >
            <Box component="section">
              <ModalPopup
                open={openModal}
                description="formUnsavedChangesMessage"
                onCancel={() => setOpenModal(false)}
                onOk={() => {
                  setSSPSelectedAgencyDetails({
                    id: initialReferalSSPDataValue.agencyId,
                    label:
                      sspAgencyDetails.find(
                        (s) => s.id === initialReferalSSPDataValue.agencyId,
                      )?.label || '',
                  });
                  formRef.current?.setValues(initialReferalSSPDataValue);
                  setYetToAddContactId(initialYetToAddContactIdValue);
                  setOpenModal(false);
                }}
                labelId1="Clientpage.cancelbtn"
                negativeActionLabel="Cancel"
                labelId2="Clientpage.Okbtn"
                positiveActionLabel="Ok"
              />
              <SnackBarComponent
                open={open}
                handleClose={handleCloseSnackBar}
                successOrError={toastrVariable}
                labelId={toastrId}
                defaultMessageId={toastrDefaultMessage}
              />
              <Box component={'div'} className="rowContainer">
                <Box className=" flex__ align__items__center ">
                  <CustomBackArrow
                    onClick={() => {
                      navigation(SSP_LIST);
                    }}
                  />
                  <TitleText
                    labelid="referralToSSPTitle"
                    defaultlabel="SSP Notification Form"
                    className="ml-md"
                  />
                </Box>
              </Box>
              <Card className="formCardView">
                {referralSSPData && (
                  <Box className="pl-lg pr-lg ">
                    <Box
                      component={'div'}
                      sx={{ borderBottom: '1px solid #CFDEDE' }}
                    >
                      <ProgramDetails
                        program={referralSSPData?.programDetails}
                        programNameLabel={'ConsentForms.programName'}
                        programNameDefaultLabel={'Program:'}
                        phoneNumberLabel={'ConsentForms.programPhone'}
                        phoneNumberDefaultLabel={'Phone:'}
                        faxNumberLabel={'ConsentForms.programFax'}
                        faxNumberDefaultLabel={'Fax:'}
                        websiteLabel={'ConsentForms.programWebsite'}
                        websiteDefaultLabel={'Website:'}
                        programDirectorLabelid={'sspReferral.programDirector'}
                        programDirectorDefaultlabel={'Program Director:'}
                      />
                    </Box>
                    <Box component="div">
                      <ClientDetails
                        clientDetails={{
                          name: referralSSPData.clientInfo.name,
                          sex: referralSSPData.clientInfo.sex,
                          dob: referralSSPData.clientInfo.dob,
                          dphId: referralSSPData.clientInfo.enrollmentId,
                          referralBeginDate:
                            referralSSPData.clientInfo.referralBeginDate,
                          doe: referralSSPData.clientInfo.doe,
                          serviceCoordinator: {
                            name: referralSSPData.clientInfo.serviceCoordinator,
                            email: referralSSPData.clientInfo.email,
                            phone: referralSSPData.clientInfo.phone,
                          },
                          teamLeader: referralSSPData.clientInfo.teamLeader,
                          programDirector: '',
                        }}
                        nameLabelid={'ifsp.ClientName'}
                        nameDefaultlabel={'Client Name:'}
                        sexLabelid={'ifsp.Sex'}
                        sexDefaultlabel={'Sex:'}
                        dobLabelid={'ifsp.DateofBirth'}
                        dobDefaultlabel={'Date of Birth:'}
                        dphIdLabelid={'ifsp.DPHID'}
                        dphIdDefaultlabel={'DPH ID:'}
                        referralDateLabelid={'sspReferral.referralDate'}
                        referralDateDefaultlabel={'Referral Begin Date:'}
                        dateOfEligibilityLabelid={
                          'sspReferral.dateOfEligibility'
                        }
                        dateOfEligibilityDefaultlabel={
                          'Date of Eligibility Evaluation:'
                        }
                        serviceCoordinatorLabelid={
                          'sspReferral.serviceCoordinator'
                        }
                        serviceCoordinatorDefaultlabel={'Service Coordinator:'}
                        teamLeaderLabelid={'sspReferral.teamLeader'}
                        teamLeaderDefaultlabel={'Team Leader:'}
                        serviceCoordinatorEmailLabelid={
                          'sspReferral.ServiceCoordinatorEmail'
                        }
                        serviceCoordinatorEmailDefaultlabel={'Email Id:'}
                        serviceCoordinatorPhoneLabelid={
                          'sspReferral.ServiceCoordinatorPhone'
                        }
                        serviceCoordinatorPhoneDefaultlabel={'Phone No:'}
                      />
                    </Box>

                    <Box component="div">
                      <ParentDetails
                        parentDetails={[primaryParent]}
                        nameLabelid={'sspReferral.parentName'}
                        nameDefaultlabel={'Guardian Name:'}
                        relationLabelid={'sspReferral.relation'}
                        relationDefaultlabel={'Guardian Type:'}
                        emailLabelid={'sspReferral.email'}
                        emailDefaultlabel={'Email:'}
                        phoneLabelid={'sspReferral.phone'}
                        phoneDefaultlabel={'Phone No:'}
                        addressLabelid={'sspReferral.address'}
                        addressDefaultlabel={'Address:'}
                      />
                    </Box>
                    <Box component="div">
                      <Grid container rowSpacing={'24px'} className="pt-xlg">
                        <Grid item xs={5}>
                          <AutocompleteSearch
                            data={parentContactes}
                            labelId="sspReferral.contact"
                            defaultId="Select Contact"
                            Required={true}
                            selectedValue={{
                              id:
                                parentContactes.find(
                                  (item) => item.id === yetToAddContactId,
                                )?.id ?? '',
                              label:
                                parentContactes.find(
                                  (item) => item.id === yetToAddContactId,
                                )?.label ?? '',
                            }}
                            onSelectItem={(e) => {
                              if (e) {
                                setYetToAddContactId(e.id);
                                setFieldValue('selectedContact', e.id);
                              }
                            }}
                            disableLabel={false}
                          />
                          {errors.selectedContact &&
                            touched.selectedContact && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={errors.selectedContact}
                                defaultlabel="Contact is required"
                              />
                            )}
                        </Grid>
                        <Grid item xs={5}></Grid>

                        <Grid item xs={5}>
                          <DatePickerComponent
                            labelid="sspReferral.dateOfReferral"
                            minDate={
                              values.dateOfReferral === null
                                ? dayjs()
                                : dayjs(values.dateOfReferral)
                            }
                            required
                            disableFuture={false}
                            defaultlabelid="Date of Referral"
                            value={
                              values.dateOfReferral
                                ? (dayjs(values.dateOfReferral) as Dayjs)
                                : null
                            }
                            handlechange={(dateValue: Dayjs | null) => {
                              const formattedDate =
                                dayjs(dateValue).format('MM/DD/YYYY');
                              if (dateValue === null) {
                                setFieldValue(`dateOfReferral`, null);
                              } else {
                                setFieldValue(`dateOfReferral`, formattedDate);
                              }
                            }}
                          />
                          {errors.dateOfReferral && touched.dateOfReferral ? (
                            <Box className="loginError">
                              <MediumTypography
                                labelid={errors.dateOfReferral}
                                defaultlabel="Enter Name"
                                sxProps={{
                                  color: 'red',
                                  fontSize: '14px',
                                }}
                              />
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5}>
                          <DropdownComponent
                            names={ReferralForData}
                            value={values.referralFor}
                            defaultlabelid="Referral for"
                            labelid="sspReferral.referralFor"
                            Required
                            handleChange={(value: string) => {
                              setFieldValue(`referralFor`, value);
                              if (value == 'vision') {
                                setFieldValue('agency', '');
                                setFieldValue('agencyId', '');
                                setFieldValue('ssp', '');
                                setFieldValue('sspId', '');
                              }
                            }}
                          />
                          {errors.referralFor && touched.referralFor ? (
                            <Box className="loginError">
                              <MediumTypography
                                labelid={errors.referralFor}
                                defaultlabel="Enter Name"
                                sxProps={{
                                  color: 'red',
                                  fontSize: '14px',
                                }}
                              />
                            </Box>
                          ) : null}
                        </Grid>
                        {values.referralFor === 'vision' && (
                          <Box className="pt-md">
                            <MediumTypography
                              labelid="sspReferral.VisionStatement"
                              defaultlabel='*For Vision, kindly reach out to Perkins School for the Blind. Additionally, please complete the online referral by clicking "Make A Referral" at'
                            />
                            <Box sx={{ width: 'fit-content' }}>
                              <Link
                                href={perkinsUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <MediumTypography
                                  label={perkinsUrl}
                                  textColor="#008C82"
                                />
                              </Link>
                            </Box>
                          </Box>
                        )}
                        {values.referralFor === 'other' && (
                          <Grid item xs={5} className="pt-md">
                            <Textinput
                              name={`referral[${0}].othersDesc`}
                              Value={values.specify}
                              labelid="sspReferral.Specify"
                              defaultlabelid="Specify"
                              Required
                              inputProps={{
                                maxLength: 50,
                              }}
                              handlechange={(text: string) => {
                                setFieldValue(`specify`, text);
                              }}
                            />
                            {errors.specify && touched.specify ? (
                              <Box className="loginError">
                                <MediumTypography
                                  labelid={errors.specify}
                                  defaultlabel="Enter Name"
                                  sxProps={{
                                    color: 'red',
                                    fontSize: '14px',
                                  }}
                                />
                              </Box>
                            ) : null}
                          </Grid>
                        )}
                      </Grid>

                      <Grid container>
                        <Grid item xs={5} className="pt-lg">
                          <AutocompleteSearch
                            labelId={'sspReferral.Agency'}
                            defaultId="Select Agency"
                            Required
                            optionHintId={'noSSPAgencyDropdownTextId'}
                            sxProps={{
                              [`& .${autocompleteClasses.popupIndicator}`]: {
                                transform: 'none',
                              },
                            }}
                            data={sspAgencyDetails}
                            onSelectItem={(itemData: OptionType | null) => {
                              if (itemData === null) {
                                values.agency = '';
                                values.ssp = '';
                                setSSPSelectedAgencyDetails(null);
                              } else {
                                setFieldValue('agency', itemData.label);
                                setFieldValue('agencyId', itemData.id);
                                setFieldValue('ssp', '');
                                setFieldValue('sspId', '');
                                setSSPSelectedAgencyDetails(itemData);
                              }
                            }}
                            selectedValue={{
                              id: values.agencyId,
                              label:
                                sspAgencyDetails.find(
                                  (s) => s.id === values.agencyId,
                                )?.label || '',
                            }}
                            disableLabel={false}
                            disabled={values.referralFor === 'vision'}
                          />
                          {errors.agency && touched.agency ? (
                            <Box className="loginError">
                              <MediumTypography
                                labelid={errors.agency}
                                defaultlabel="Enter Name"
                                sxProps={{
                                  color: 'red',
                                  fontSize: '14px',
                                }}
                              />
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5} className="pt-lg">
                          <AutocompleteSearch
                            labelId={'sspReferral.referralTo'}
                            defaultId="Select SSP*"
                            optionHintId={'noSSPDropdownTextId'}
                            sxProps={{
                              [`& .${autocompleteClasses.popupIndicator}`]: {
                                transform: 'none',
                              },
                            }}
                            data={sspDetails}
                            onSelectItem={(itemData: OptionType | null) => {
                              if (itemData === null) {
                                setFieldValue(`ssp`, '');
                                setFieldValue(`sspId`, '');
                              } else {
                                setFieldValue(`ssp`, itemData.label);
                                setFieldValue(`sspId`, itemData.id);
                              }
                            }}
                            selectedValue={{
                              id: values.sspId,
                              label:
                                sspDetails.find(
                                  (value) => value.id === values.sspId,
                                )?.label ?? '',
                            }}
                            disableLabel={false}
                            disabled={values.referralFor === 'vision'}
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box component="div"></Box>
                    <Grid container>
                      <Grid item xs={10.5} className="pt-lg pb-lg">
                        <Box className="position__relative">
                          <Textinput
                            Value={values.comment}
                            multiline={true}
                            handlechange={(text: string) => {
                              setFieldValue('comment', text);
                              setReferralSSPData({
                                ...referralSSPData,
                                comment: text,
                              });
                            }}
                            labelid="sspReferral.comment"
                            defaultlabelid="Comment"
                            inputProps={{
                              style: {
                                height: '100px',
                                paddingBottom: '8px',
                                paddingLeft: '0px',
                              },
                              maxLength: 3000,
                            }}
                          />
                          <MediumTypography
                            labelid="char"
                            defaultlabel={`${
                              3000 - referralSSPData.comment.length
                            } char`}
                            fontSize="12px"
                            textColor="#97A6A5"
                            sxProps={{
                              right: 0,
                              bottom: 0,
                            }}
                            className="mr-sm pt-sm position__absolute"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Card>
              <Box
                component="div"
                className="flex__ mt-md mb-md justifyContent-FlexEnd"
              >
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  labelId={'Cancel'}
                  defaultLabelId="Cancel"
                  variantType="outlined"
                  onClick={() => {
                    if (_.isEqual(initialReferalSSPDataValue, values)) {
                      const clientId = localStorage.getItem('ClientId');
                      navigation(SSP_LIST, { state: { clientId } });
                    } else {
                      setOpenModal(true);
                    }
                  }}
                />
                <ButtonComponent
                  className="btn-primary btn-submit  ml-md"
                  labelId={id ? 'BDI2.Edit' : 'ConsentSave'}
                  defaultLabelId="Save"
                  variantType="contained"
                  onClick={handleSubmit}
                />
              </Box>
            </Box>
          </div>
        </Box>
      )}
    </Formik>
  );
};
export default ReferralToSSP;
