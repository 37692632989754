export const ASQSCORINGCHART = [
  {
    chartDetails: 'Score items (Z= 0, V= 5, X= 10, Concern = 5).',
  },
  {
    chartDetails: 'Transfer the page totals and add them for the total score.',
  },
  {
    chartDetails: ' Record the client’s total score next to the cutoff.',
  },
];
