import { Box } from '@mui/system';
import $ from 'jquery';
import React, { createRef, useEffect, useRef, useState } from 'react';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { AlertColor, Dialog, DialogContent, Grid } from '@mui/material';
import {
  CheckExistFormName,
  CreateForm,
  EditFormTypeBuilder,
  GetByIdList,
} from '../../utils/type';
import {
  checkDuplicateEdit,
  editForm,
  fetchFormJSON,
  getRecordById,
} from '../../services/configApi/forms/FormBuilder/FormBuilderServices';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { useNavigate } from 'react-router-dom';
import Textinput from '../../components/formlib/Textinput';
import MediumTypography from '../../components/formlib/MediumTypography';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { FORM_BUILDER_LIST_DISPLAY } from '../../routes/Routing';
import checkB from '../../assets/images/checkBoxIcon.svg';
import dateIcon from '../../assets/images/calendarFormBuilder.svg';
import radioIcon from '../../assets/images/radioFormBuilder.svg';
import textAreaIcon from '../../assets/images/textAreaFomBuilder.svg';
import headerIcon from '../../assets/images/headerFormBuilder.svg';
import ModalPopup from '../../components/formlib/ModalPopup';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';

require('jquery-ui-sortable');
require('formBuilder');

interface FormBuilderType {
  formData?: string;
  actions?: FormBuilderActions;
}

type MyErrorType = {
  message: string;
};

interface FormBuilderActions {
  clearFields: () => void;
}

const initialState: CreateForm = {
  type: '',
  name: '',
  template: [],
};

const validationSchema = Yup.object({
  name: Yup.string().required('formBuilder.Name'),
});

function EditFormBuilder() {
  const location = useLocation();
  const navParams = location.state;
  const fb = createRef<HTMLDivElement>();
  const formRef = useRef<FormikProps<EditFormTypeBuilder>>(null);
  const [formBuilder, setFormBuilder] = useState<FormBuilderType>({});
  const [formRender, setFormRender] = useState<string | undefined>(undefined);
  const [formDetails, setFormDetails] = useState<CreateForm>(initialState);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('Client.addtoastr');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [open, setOpen] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [isExist, setIsExist] = useState<boolean>(false);
  const [dynamicFormdata, setDynamicFormData] = useState<GetByIdList[]>([]);
  const [clearModal, setClearModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [cancelButtonLabelId, setCancelButtonLabelId] =
    useState<string>('clear.btn');
  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);
  const [numFieldd, setNumFieldd] = useState(0);
  const [fieldRemoved, setFieldRemoved] = useState<boolean>(false);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const navigate = useNavigate();
  const initialValues: EditFormTypeBuilder = {
    name: navParams?.name ? navParams?.name : '',
  };

  useEffect(() => {
    if (numFieldd > 40 && !fieldRemoved) {
      setOpen(true);
      setToastrVariable('error');
      setToastrId('formBuilder.ElementsRestriction');
      setToastrDefaultMessage('Cannot add more than 40 elements to a form');
      setSaveDisabled(true);
    }
  }, [numFieldd]);

  useEffect(() => {
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      name: navParams?.name,
    }));
  }, []);

  useEffect(() => {
    const options = {
      controlPosition: 'left',
    };
    const disabledFields = {
      disableFields: [
        'paragraph',
        'number',
        'hidden',
        'file',
        'button',
        'autocomplete',
        'select',
        'text',
        'checkbox-group',
        'textarea',
        'radio-group',
        'date',
        'header',
      ],
      i18n: {
        override: {
          'en-US': {
            getStarted: 'Drag and drop items here from the left panel',
          },
        },
      },
      typeUserDisabledAttrs: {
        date: ['placeholder', 'min', 'max', 'step'],
        textarea: ['rows', 'subtype'],
      },
      onAddField: function () {
        setCancelButtonLabelId('clear.btn');
        setSaveDisabled(false);
        setFieldRemoved(false);
        setNumFieldd((prevNumFieldd) => {
          const newNumFieldd = prevNumFieldd + 1;
          return newNumFieldd;
        });
      },
      fields: [
        {
          label: 'Checkbox Group',
          type: 'checkbox-group',
          subtype: 'checkbox-group',
          icon: `<img src="${checkB}" style="margin-bottom: -14%;" />`,
        },
        {
          label: 'Date Field',
          type: 'date',
          subtype: 'date',
          icon: `<img src="${dateIcon}" style="margin-bottom: -14%;" />`,
        },
        {
          label: 'Radio Group',
          type: 'radio-group',
          subtype: 'radio-group',
          icon: `<img src= "${radioIcon}" style="margin-bottom: -14%;"/>`,
        },
        {
          label: 'Text Area',
          type: 'textarea',
          subtype: 'textarea',
          icon: `<img src= "${textAreaIcon}"  style="margin-bottom: -14%;"/>`,
        },
        {
          label: 'Header',
          type: 'header',
          subtype: 'header',
          icon: `<img src= "${headerIcon}" style="margin-bottom: -14%;"/>`,
        },
      ],
    };

    if (dynamicFormdata.length > 0 && fb.current) {
      setFormBuilder(
        $(fb.current).formBuilder({
          ...options,
          ...disabledFields,
          disabledActionButtons: ['data', 'clear', 'save'],
          disabledAttrs: ['className', 'required', 'name', 'access', 'inline'],
          formData: dynamicFormdata.length > 0 ? dynamicFormdata : [],
        }) as FormBuilderType,
      );
    }
  }, [dynamicFormdata]);

  function convertHeaderLabelToString(
    data: Array<Record<string, string>>,
  ): Array<Record<string, string>> {
    return data.map((item) => {
      if (item.type === 'header') {
        const div = document.createElement('div');
        div.innerHTML = item.label;
        return {
          ...item,
          label: div.textContent || div.innerText || '',
        };
      }
      return item;
    });
  }

  async function saveData() {
    if (formBuilder.formData) {
      const parsedFormData = JSON.parse(formBuilder.formData);
      const convertedData = convertHeaderLabelToString(parsedFormData);
      if (Object.entries(parsedFormData).length > 40) {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('formBuilder.ElementsRestriction');
        setToastrDefaultMessage('Cannot add more than 40 elements to a form');
        setSaveDisabled(true);
      } else {
        setFormRender(JSON.parse(formBuilder.formData));
        setFormDetails((prevFormDetails) => ({
          ...prevFormDetails,
          template: convertedData,
        }));
      }
    }
  }

  document.addEventListener('fieldRemoved', () => {
    setNumFieldd(numFieldd - 1);
    setFieldRemoved(true);
    if (formBuilder.formData) {
      const parsedFormData = JSON.parse(formBuilder.formData);
      if (Object.entries(parsedFormData).length === 1) {
        setCancelButtonLabelId('cancelText');
        setSaveDisabled(true);
      }

      if (Object.entries(parsedFormData).length <= 41) {
        setSaveDisabled(false);
      }
    }
  });

  const isFirstRender = useRef(true);

  useEffect(() => {
    toggleLoader(true);
    setFormDetails((prevFormDetails) => ({
      ...prevFormDetails,
      type: navParams?.type || '',
    }));
    if (navParams?.id) {
      getRecordById(navParams.id)
        .then((response: GetByIdList) => {
          fetchFunction(response);
        })
        .catch((error) => {
          setOpen(true);
          toggleLoader(false);
          setToastrVariable('error');
          setToastrId('formBuilder.EditFail');
          setToastrDefaultMessage(error);
        });
    }
  }, [navParams]);

  async function fetchFunction(response: { templateUrl: string }) {
    try {
      const apiResponse = await fetchFormJSON(response.templateUrl);
      setDynamicFormData(apiResponse.data);
      setNumFieldd(apiResponse.data.length);
      setNumFieldd(numFieldd / 2);
      toggleLoader(false);
    } catch (error) {
      const typedError = error as MyErrorType;
      setToastrVariable('error');
      setToastrId(typedError.message);
      setToastrDefaultMessage(typedError.message);
      toggleLoader(false);
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (formDetails.template.length > 0) {
      for (const obj of formDetails.template) {
        if (
          !obj.hasOwnProperty('label') ||
          /^\s*$/.test((obj.label ?? '').replace(/&nbsp;/g, ''))
        ) {
          setOpen(true);
          setToastrVariable('error');
          setToastrId('formBuilder.LabelMandatory');
          setToastrDefaultMessage('Label is mandatory for all the fields');
          setTimeout(() => {
            setOpen(false);
          }, 2000);
          return;
        }
      }
    }

    if (formDetails.template.length > 0) {
      setSaveDisabled(true);
      toggleLoader(true);
      editForm(formDetails, navParams.id)
        .then(async (response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setTimeout(() => {
            navigate(FORM_BUILDER_LIST_DISPLAY);
          }, 1000);
        })
        .catch(async (error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          setToastrId(error.message);
          setToastrDefaultMessage(error.message);
        });
    }
  }, [formRender]);

  function clearData() {
    if (formBuilder.formData) {
      const parsedFormData = JSON.parse(formBuilder.formData);
      if (Object.entries(parsedFormData).length === 0) {
        setCancelModal(true);
      } else {
        setClearModal(true);
      }
    }
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const nameValidation = (value: string): string | undefined => {
    const trimmedValue = value?.replace(/^\s+/, '');
    const regex = /^[A-Za-z. -\d]*$/;
    if (regex.test(trimmedValue)) {
      return trimmedValue;
    }
  };

  const duplicateCheck = (value: string) => {
    if (formRef.current?.isValid) {
      toggleLoader(true);
      checkDuplicateEdit(value, navParams.id).then(
        async (response: CheckExistFormName) => {
          if (response.nameExists === true) {
            toggleLoader(false);
            setIsExist(true);
          } else {
            toggleLoader(false);
            setIsExist(false);
            setModalVisible(false);
          }
        },
      );
    }
  };

  return (
    <Box component="main">
      <Box component="section">
        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={0.2} lg={0.2} className="mt-xs">
              <CustomBackArrow
                onClick={() => {
                  if (formBuilder.formData) {
                    const parsedFormData = JSON.parse(formBuilder.formData);
                    if (Object.entries(parsedFormData).length === 0) {
                      navigate(FORM_BUILDER_LIST_DISPLAY);
                    } else {
                      setCancelModal(true);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item xs={1.3} lg={1.3}>
              <TitleText
                labelid="formBuilder.CopyForm"
                defaultlabel="Edit Form"
              />
            </Grid>
            <Grid item xs={10} lg={10}>
              <Box
                component="div"
                className="ml-none"
                sx={{ wordBreak: 'break-all', wordWrap: 'break-word' }}
              >
                <TitleText label={`${formDetails.name}`} defaultlabel="" />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />

        <Box ref={fb} />
        {dynamicFormdata.length !== 0 && (
          <Box component="div">
            <Grid
              className="flex__ justifyContent-FlexEnd alignItemEnd pt-md pb-lg "
              container
              direction="row"
            >
              <Grid item>
                <ButtonComponent
                  className="btn-primary btn-cancel btn_width_90px mr-md"
                  variantType="outlined"
                  labelId={cancelButtonLabelId}
                  defaultLabelId="Clear"
                  onClick={clearData}
                />

                <ButtonComponent
                  className="btn-primary btn-submit btn_width_90px "
                  variantType="contained"
                  type="submit"
                  labelId="formBuilder.Save"
                  defaultLabelId="Save"
                  onClick={saveData}
                  disabled={saveDisabled}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box component="div">
          <Dialog
            open={modalVisible}
            keepMounted
            onClose={() => ({})}
            fullWidth
            aria-describedby="alert-dialog-slide-description"
            className="formBuilderModal"
          >
            <DialogContent className="p-md">
              <MediumTypography
                labelid={'formBuilder.FormNameEnter'}
                defaultlabel="Enter Form Name"
                fontweight={1000}
                textColor="#2a4241"
              />
              <Formik
                initialValues={initialValues}
                validateOnMount={true}
                innerRef={formRef}
                enableReinitialize={true}
                onSubmit={(values) => duplicateCheck(values.name)}
                validationSchema={validationSchema}
              >
                {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                  <Box component="div">
                    <Box component="div" className="mt-lg">
                      <Textinput
                        name="name"
                        labelid={'formBuilder.NameText'}
                        defaultlabelid="Name"
                        handlechange={(value) => {
                          setIsExist(false);
                          const inputValue = value;
                          const validatedValue = nameValidation(inputValue);
                          if (validatedValue !== undefined) {
                            const str = validatedValue;
                            const trimmedString = str.trimEnd();
                            setFormDetails((prevFormDetails) => ({
                              ...prevFormDetails,
                              name: trimmedString,
                            }));
                            setFieldValue('name', value);
                          }
                        }}
                        inputProps={{
                          maxLength: 100,
                        }}
                        type="text"
                        disabled={false}
                        Value={values.name}
                      />
                      {(errors.name && touched.name) || isExist ? (
                        <Box className="loginError">
                          <MediumTypography
                            labelid={
                              errors.name && touched.name
                                ? errors.name
                                : 'Name already exists'
                            }
                            defaultlabel="Enter Name"
                            sxProps={{
                              color: 'red',
                              marginLeft: '10px',
                              fontSize: '14px',
                            }}
                          />
                        </Box>
                      ) : null}
                    </Box>
                    <Grid
                      className="flex__ justifyContent-FlexEnd pt-lg "
                      container
                      direction="row"
                      alignItems="right"
                    >
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel btn_width_90px mr-md"
                          variantType="outlined"
                          labelId="cancelText"
                          defaultLabelId="Cancel"
                          onClick={() => navigate(FORM_BUILDER_LIST_DISPLAY)}
                        />

                        <ButtonComponent
                          className="btn-primary btn-submit mr-md"
                          variantType="contained"
                          labelId="formBuilder.Continue"
                          defaultLabelId="Save"
                          onClick={handleSubmit}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
      {clearModal && (
        <ModalPopup
          open={clearModal}
          onCancel={() => {
            setClearModal(false);
          }}
          description={'formBuilder.FormContentClear'}
          onOk={() => {
            if (formBuilder.actions) {
              setCancelButtonLabelId('cancelText');
              formBuilder.actions.clearFields();
              setClearModal(false);
              setSaveDisabled(true);
              setNumFieldd(0);
              setFieldRemoved(false);
            }
            setFormRender(undefined);
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}

      {cancelModal && (
        <ModalPopup
          open={cancelModal}
          onCancel={() => {
            setCancelModal(false);
          }}
          description={'formBuilder.NaviGateBack'}
          onOk={() => navigate(FORM_BUILDER_LIST_DISPLAY)}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}
    </Box>
  );
}

export default EditFormBuilder;
