import { Box } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import InformationModal from './InformationModal';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { Grid, Paper, Table, TableBody, TableContainer } from '@mui/material';
import Textinput from '../../components/formlib/Textinput';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import { ReactComponent as AddNew } from '../../assets/images/Plus.svg';
import ServiceDeliveryPlanData from './IFSP/ServiceDeliveryPlan.json';
import { ReactComponent as Delete } from '../../assets/images/deleteAlertIcon.svg';

import { ReactComponent as HideIcon } from '../../assets/images/hideIcon.svg';
import { ReactComponent as PrinterIcon } from '../../assets/images/printerIcon.svg';
import { ReactComponent as FilledAddIcon } from '../../assets/images/filledAddIcon.svg';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import EIServiceTableRow from './EIServiceTableRow';
import dayjs, { Dayjs } from 'dayjs';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import IfspContext from './ifspContextApi/IfspContex';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  datePickerMinDateMaxDateValidate,
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
} from '../../utils/dateUtil';
import DurationPicker from '../../components/formlib/DurationPicker';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { OptionType } from '../../utils/type';
import _ from 'lodash';
import { translateTextArea } from '../../TranslateWidget';

export interface TableData {
  eiService: string;
  providedBy: string;
  locationOfService: string;
  length: string;
  period: string;
  frequency: string;
  startDate: string;
  intensity: string;
  duration: string;
  endDate: string;
  reason: string;
  actions: string;
}
export type ServiceDeliveryPlanPropsData = {
  servicePlanId: string;
  eiService: string;
  providedBy: string;
  locationOfService: string;
  frequency: string;
  length: string;
  duration: string;
  period: string;
  intensity: string;
  startDate: string | null | Dayjs;
  endDate: string | null | Dayjs;
  reasonIfLate: string;
  isRecordSubmitted: boolean;
  tbrServicePlanId: number;
  tbrUpdateRequired: boolean;
};

export type ServiceDeliveryPlanType = {
  earlyInterventionService: ServiceDeliveryPlanPropsData[];
  notes: string;
  justification: string;
  otherNeededService: string;
  stepsToTake: string;
  isChecked: boolean;
};
export const headCells: HeadCell<TableData>[] = [
  {
    id: 'eiService',
    labelId: 'EI Service/Method',
    defaultLabelId: 'EI Service/Method',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'providedBy',
    labelId: 'Provided by',
    defaultLabelId: 'Provided By',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'locationOfService',
    labelId: 'Location of Service',
    defaultLabelId: 'Location of Service',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'length',
    labelId: 'length',
    defaultLabelId: 'Length',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'period',
    labelId: 'period',
    defaultLabelId: 'Period',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'frequency',
    labelId: 'Frequency & Length',
    defaultLabelId: 'Frequency & Length',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'intensity',
    labelId: 'Intensity',
    defaultLabelId: 'Intensity',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'duration',
    labelId: 'Duration of Service',
    defaultLabelId: 'Duration of Service',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'startDate',
    labelId: 'Start Date',
    defaultLabelId: 'Start Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'endDate',
    labelId: 'End Date',
    defaultLabelId: 'End Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'reason',
    labelId: 'Reason',
    defaultLabelId: 'Reason',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'Actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

interface ServiceDeliveryPlanProps {
  info: boolean;
  open: boolean;
  isIfspScreenLocked: boolean;
  selectedLanguage: string;
  onClose: (open: boolean) => void;
  closeInfo: (info: boolean) => void;
  onOpen: (open: boolean) => void;
  openInfo: (info: boolean) => void;
}

const initialValues: ServiceDeliveryPlanPropsData = {
  servicePlanId: '',
  eiService: '',
  frequency: '',
  length: '',
  intensity: '',
  locationOfService: '',
  period: '',
  providedBy: '',
  startDate: null,
  endDate: null,
  duration: '',
  reasonIfLate: '',
  isRecordSubmitted: false,
  tbrServicePlanId: 0,
  tbrUpdateRequired: true,
};

const TextAreaStyle: React.CSSProperties = {
  height: '80px',
  minHeight: '80px',
  minWidth: '840px',
  width: '840px',
  fontFamily: 'Lato-Regular',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  paddingTop: '10px',
  paddingLeft: '10px',
  border: '1px solid #ccc',
  outline: 'none',
  overflow: 'hidden',
  borderRadius: '4px',
  transition: 'border-color 0.3s',
  alignItems: 'center',
  justifyContent: 'center',
  resize: 'none',
  overflowY: 'scroll',
};

const validationSchema = yup.object().shape({
  eiService: yup.string().required('IFSP.EIServiceisrequired'),
  frequency: yup
    .string()
    .required('IFSP.Frequencyisrequired')
    .matches(/^[1-9]$|^10$/, 'IFSP.FrequencyisGreaterThanZeroAndLessThanTen'),
  length: yup.string().required('IFSP.Lengthisrequired'),
  intensity: yup.string().required('IFSP.Intensityisrequired'),
  period: yup.string().required('IFSP.Periodisrequired'),
  locationOfService: yup.string().required('IFSP.LocationofServiceisrequired'),
  providedBy: yup.string().required('IFSP.Providedbyisrequired'),
  startDate: yup
    .date()
    .nullable()
    .typeError('Date is required')
    .required('IFSP.StartDateisrequired'),
  endDate: yup.date().nullable().typeError('Date is required'),
  duration: yup.string().required('IFSP.DurationofServiceisrequired'),
});

const validateDate = (values: ServiceDeliveryPlanPropsData) => {
  const errors: Partial<ServiceDeliveryPlanPropsData> = {};

  if (values.startDate && values.endDate) {
    const startDate = dayjs(values.startDate, 'MM/DD/YYYY');
    const endDate = dayjs(values.endDate, 'MM/DD/YYYY');

    if (startDate.isAfter(endDate)) {
      errors.endDate = 'BlockTime.endDateValidationError';
    }
  }
  if (datePickerMinDateMaxDateValidate(values.startDate)) {
    errors.startDate = 'datePickerMinDateMaxDateValidate';
  }
  if (datePickerMinDateMaxDateValidate(values.endDate)) {
    errors.endDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};
const ServiceDeliveryPlan: FC<ServiceDeliveryPlanProps> = ({
  info,
  open,
  isIfspScreenLocked,
  onOpen,
  openInfo,
  onClose,
  closeInfo,
  selectedLanguage,
}) => {
  const { ifsp, updateIfspContext, initialIfsp } = useContext(IfspContext);
  const [sDPData, setSDPData] =
    useState<ServiceDeliveryPlanPropsData>(initialValues);
  const [sdpMainData, setSdpMainData] = useState<ServiceDeliveryPlanType[]>(
    ifsp.ifspTeam.forms.serviceDeliveryPlan,
  );

  const [showSubSection, setShowSubSection] = useState<boolean>(true);
  const [openIndex, setOpenIndex] = useState<number>(0);
  const [openedSubSectionIndex, setOpenedSubSectionIndex] = useState<number>(0);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(0);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [eiService, setEiService] = useState<OptionType[]>([]);
  const [locationOfService, setLocationOfService] = useState<OptionType[]>([]);
  const [period, setPeriod] = useState<OptionType[]>([]);
  const [intensity, setIntensity] = useState<OptionType[]>([]);
  const [providedBy, setProvidedBy] = useState<OptionType[]>([]);
  const [lookUpReasons, setLookUpReasons] = useState<OptionType[]>([]);
  const { lookups } = useContext(LookUpContext) as LookUpContextType;

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      selectedLanguage === 'en'
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    formikRef.current?.setTouched({});
  }, [open]);

  useEffect(() => {
    if (lookups === null) {
      return;
    }

    setEiService(() => {
      return lookups.eiService?.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });

    setLocationOfService(() => {
      return lookups.locationOfService?.map((l) => {
        return {
          label: l.description,
          id: l.code,
        };
      });
    });

    setPeriod(() => {
      return lookups.period?.map((p) => {
        return {
          label: p.description,
          id: p.code,
        };
      });
    });

    setProvidedBy(() => {
      return lookups.discipline?.map((p) => {
        return {
          label: p.description,
          id: p.code,
        };
      });
    });

    setIntensity(() => {
      return lookups.intensity?.map((i) => {
        return {
          label: i.description,
          id: i.code,
        };
      });
    });
    setLookUpReasons(() => {
      return lookups.reason?.map((e) => {
        return {
          label: e.description,
          id: e.code,
        };
      });
    });
  }, [lookups]);
  const parentRef = useRef<HTMLDivElement>(null);

  const formikRef = useRef<FormikProps<ServiceDeliveryPlanPropsData>>(null);

  const handleAddServiceDeliveryPlan = (
    subSectionIndex: number,
    values: ServiceDeliveryPlanPropsData,
  ) => {
    if (isEdit) {
      const updatedSdpData = sdpMainData[
        subSectionIndex
      ].earlyInterventionService.map(
        (earlyInterventionService, earlyInterventionServiceIndex) =>
          earlyInterventionServiceIndex === editIndex
            ? {
                ...earlyInterventionService,
                eiService: values.eiService,
                providedBy: values.providedBy,
                length: values.length,
                period: values.period,
                locationOfService: values.locationOfService,
                frequency: values.frequency,
                duration: values.duration,
                intensity: values.intensity,
                startDate: values.startDate,
                endDate: values.endDate,
                reasonIfLate: values.reasonIfLate,
                tbrServicePlanId: values.tbrServicePlanId,
                tbrUpdateRequired: values.tbrUpdateRequired
                  ? true
                  : !_.isEqual(
                      initialIfsp.ifspTeam.forms.serviceDeliveryPlan[
                        subSectionIndex
                      ].earlyInterventionService[editIndex],
                      values,
                    ),
              }
            : earlyInterventionService,
      );
      setSdpMainData((prevData) => {
        const tempData = [...prevData];
        tempData[subSectionIndex].earlyInterventionService = updatedSdpData;
        return tempData;
      });
      setIsEdit(false);
    } else {
      setSdpMainData((prevData) => {
        const tempData = [...prevData];
        tempData[subSectionIndex].earlyInterventionService = [
          ...tempData[subSectionIndex].earlyInterventionService,
          {
            servicePlanId: values.servicePlanId,
            eiService: values.eiService,
            providedBy: values.providedBy,
            locationOfService: values.locationOfService,
            length: values.length,
            frequency: values.frequency,
            duration: values.duration,
            period: values.period,
            intensity: values.intensity,
            startDate: values.startDate,
            endDate: values.endDate,
            reasonIfLate: values.reasonIfLate,
            isRecordSubmitted: values.isRecordSubmitted,
            tbrServicePlanId: values.tbrServicePlanId,
            tbrUpdateRequired: true,
          },
        ];
        return tempData;
      });
      resetSDPData();
    }
  };

  const resetSDPData = () => {
    setSDPData(initialValues);
  };

  const addSubSection = () => {
    formikRef.current?.setTouched({});
    setOpenIndex(1);
    setSdpMainData([
      ...sdpMainData,
      {
        earlyInterventionService: [],
        notes: '',
        justification: '',
        otherNeededService: '',
        stepsToTake: '',
        isChecked: false,
      },
    ]);
  };

  const onEditRow = (subSectionIndex: number, index: number) => {
    setEditIndex(index);
    const editableData =
      sdpMainData[subSectionIndex].earlyInterventionService[index];
    formikRef.current?.setValues({
      servicePlanId: editableData.servicePlanId,
      eiService: editableData.eiService,
      providedBy: editableData.providedBy,
      locationOfService: editableData.locationOfService,
      length: editableData.length ?? '0.00',
      frequency: editableData.frequency,
      period: editableData.period,
      duration: editableData.duration,
      intensity: editableData.intensity,
      startDate: editableData.startDate,
      endDate: editableData.endDate,
      reasonIfLate: editableData.reasonIfLate,
      isRecordSubmitted: editableData.isRecordSubmitted,
      tbrServicePlanId: editableData.tbrServicePlanId,
      tbrUpdateRequired: editableData.tbrUpdateRequired,
    });
    setSDPData({
      servicePlanId: editableData.servicePlanId,
      eiService: editableData.eiService,
      providedBy: editableData.providedBy,
      locationOfService: editableData.locationOfService,
      length: editableData.length,
      frequency: editableData.frequency,
      duration: editableData.duration,
      period: editableData.period,
      intensity: editableData.intensity,
      startDate: editableData.startDate,
      endDate: editableData.endDate,
      reasonIfLate: editableData.reasonIfLate,
      isRecordSubmitted: editableData.isRecordSubmitted,
      tbrServicePlanId: editableData.tbrServicePlanId,
      tbrUpdateRequired: editableData.tbrUpdateRequired,
    });
    setIsEdit(true);
  };

  const onDeleteRow = (subSectionIndex: number, index: number) => {
    setSdpMainData((prevData) => {
      const tempData = [...prevData];
      tempData[subSectionIndex].earlyInterventionService.splice(index, 1);
      return tempData;
    });
  };

  const deleteSubSection = (index: number) => {
    const newSdpData = sdpMainData.filter((__, i) => i !== index);
    setSdpMainData(newSdpData);
    if (openIndex === index) {
      setShowSubSection(false);
    } else if (openIndex && openIndex > index) {
      setOpenIndex(openIndex - 1);
    }
  };

  useEffect(() => {
    setSdpMainData(ifsp.ifspTeam.forms.serviceDeliveryPlan);
  }, [ifsp.ifspTeam.forms.serviceDeliveryPlan]);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          serviceDeliveryPlan: sdpMainData,
        },
      },
    });
  }, [sdpMainData]);

  return (
    <Box component="main">
      <Box component="section">
        <Box component="div">
          <Formik
            initialValues={{
              servicePlanId: sDPData.servicePlanId,
              eiService: sDPData.eiService,
              providedBy: sDPData.providedBy,
              locationOfService: sDPData.locationOfService,
              length: sDPData.length,
              period: sDPData.period,
              frequency: sDPData.frequency,
              duration: sDPData.duration,
              intensity: sDPData.intensity,
              startDate: sDPData.startDate,
              endDate: sDPData.endDate,
              reasonIfLate: sDPData.reasonIfLate,
              isRecordSubmitted: sDPData.isRecordSubmitted,
              tbrServicePlanId: sDPData.tbrServicePlanId,
              tbrUpdateRequired: sDPData.tbrUpdateRequired,
            }}
            innerRef={formikRef}
            validationSchema={validationSchema}
            validate={validateDate}
            onSubmit={(values, { resetForm }) => {
              const selectedService = eiService.find(
                (item) => item.id === values.eiService,
              );
              const selectedProvider = providedBy.find(
                (item) => item.id === values.providedBy,
              );
              const selectedLocationService = locationOfService.find(
                (item) => item.id === values.locationOfService,
              );
              const selectedIntensity = intensity.find(
                (item) => item.id === values.intensity,
              );
              const selectedPeriod = period.find(
                (item) => item.id === values.period,
              );

              handleAddServiceDeliveryPlan(openedSubSectionIndex, {
                ...values,
                eiService: selectedService?.id || '',
                providedBy: selectedProvider?.id || '',
                period: selectedPeriod?.id || '',
                locationOfService: selectedLocationService?.id || '',
                intensity: selectedIntensity?.id || '',
              });
              resetForm();
            }}
          >
            {({ values, setFieldValue, errors, touched, handleSubmit }) => (
              <Box component="div">
                {info && (
                  <InformationModal
                    open={info}
                    titleLabel="IFSP.Information"
                    titleDefaultLabel="Information"
                    buttonLabel="IFSP.InfoOkButton"
                    buttonDefaultLabel="Ok"
                    onClose={() => {
                      closeInfo(info);
                    }}
                    info={ServiceDeliveryPlanData.info}
                  />
                )}
                <Box
                  component="div"
                  className="p-lg gray3 borderRadius4"
                  sx={{
                    border: '1px solid #00C6B8',
                  }}
                >
                  <Box
                    component="div"
                    className="flex__justify__space-between"
                    sx={{
                      paddingBottom:
                        open && sdpMainData.length > 0 ? '24px' : 0,
                      borderBottom:
                        open && sdpMainData.length > 0
                          ? '1px solid #00C6B8'
                          : 'none',
                    }}
                  >
                    <Box className="flex__ ">
                      <MediumTypography
                        labelid="IFSP.session6"
                        defaultlabel="Service Delivery Plan"
                        fontSize="18px"
                        fontweight={600}
                        textColor="#2A4241"
                        sxProps={{
                          marginBottom:
                            open && sdpMainData.length < 1 ? '24px' : '0px',
                        }}
                      />
                      {open && (
                        <InfoIcon
                          onClick={() => {
                            openInfo(info);
                          }}
                          className="mt-xs ml-sm"
                        />
                      )}
                    </Box>
                    {!open ? (
                      <DownArrow
                        className="cursorPointer"
                        style={{ color: '#008C82' }}
                        onClick={() => {
                          onOpen(open);
                        }}
                      />
                    ) : (
                      <UpArrow
                        className="cursorPointer"
                        onClick={() => {
                          onClose(open);
                        }}
                      />
                    )}
                  </Box>
                  {open && sDPData && (
                    <Box component="div">
                      <div
                        ref={parentRef}
                        style={{
                          pointerEvents: isScreenLocked ? 'none' : 'auto',
                          opacity: isScreenLocked ? '0.5' : '1 ',
                        }}
                        onKeyDownCapture={(e) => {
                          if (isScreenLocked) {
                            e.preventDefault();
                            e.stopPropagation();
                          }
                        }}
                        onFocus={() => {
                          if (isScreenLocked) {
                            if (parentRef.current) parentRef.current.focus();
                          }
                        }}
                      >
                        {sdpMainData.map((__, subSectionIndex) => (
                          <Box component="div" key={subSectionIndex}>
                            <Box
                              component="div"
                              className="flex__justify__space-between mt-lg mb-lg"
                              sx={{
                                paddingBottom:
                                  showSubSection &&
                                  openIndex === subSectionIndex
                                    ? '0px'
                                    : '24px',
                                borderBottom:
                                  showSubSection &&
                                  openIndex === subSectionIndex
                                    ? 'none'
                                    : '1px solid #00C6B8',
                              }}
                            >
                              <Box
                                component="div"
                                className="flex__ align__items__center"
                              >
                                {showSubSection &&
                                openIndex === subSectionIndex ? (
                                  <HideIcon
                                    onClick={() => {
                                      setShowSubSection(false);
                                      setIsEdit(false);
                                      setOpenIndex(subSectionIndex);
                                    }}
                                    className="cursorPointer"
                                  />
                                ) : (
                                  <FilledAddIcon
                                    onClick={() => {
                                      formikRef.current?.resetForm();
                                      setShowSubSection(true);
                                      setIsEdit(false);
                                      setOpenIndex(subSectionIndex);
                                    }}
                                    className="cursorPointer"
                                  />
                                )}
                                {subSectionIndex === 0 ? (
                                  <MediumTypography
                                    labelid="IFSP.addService"
                                    defaultlabel="Add Services"
                                    textColor="#2A4241"
                                    fontweight={600}
                                    fontSize="16px"
                                    className="mr-sm ml-sm"
                                  />
                                ) : (
                                  <MediumTypography
                                    labelid="IFSP.earlyInterventionServicesForSSPAutism"
                                    defaultlabel="Early Intervention Services for SSP Autism"
                                    textColor="#2A4241"
                                    fontweight={600}
                                    fontSize="16px"
                                    className="mr-sm ml-sm"
                                  />
                                )}
                              </Box>
                              <Box
                                component="div"
                                className="flex__ justifyContent-FlexEnd"
                              >
                                <Box
                                  component="div"
                                  className="cursorPointer flex__ align__items__center visibility__hidden"
                                >
                                  <PrinterIcon />
                                  <MediumTypography
                                    labelid="IFSP.print"
                                    defaultlabel="Print"
                                    textColor="#2A4241"
                                    fontweight={700}
                                    fontSize="16px"
                                    className="ml-xs"
                                  />
                                </Box>
                                {subSectionIndex !== 0 &&
                                  !isIfspScreenLocked &&
                                  sdpMainData[
                                    subSectionIndex
                                  ].earlyInterventionService.every(
                                    (i) => i.isRecordSubmitted === false,
                                  ) &&
                                  (sdpMainData[
                                    subSectionIndex
                                  ].earlyInterventionService.some(
                                    (i) => i.tbrServicePlanId < 1,
                                  ) ||
                                    sdpMainData[subSectionIndex]
                                      .earlyInterventionService.length ===
                                      0) && (
                                    <Box
                                      component="div"
                                      className="cursorPointer flex__ align__items__center ml-lg"
                                      onClick={() => {
                                        if (!isIfspScreenLocked) {
                                          deleteSubSection(subSectionIndex);
                                        }
                                      }}
                                    >
                                      <Delete />
                                      <MediumTypography
                                        labelid="IFSP.Delete"
                                        defaultlabel="Delete"
                                        textColor="#EB4C60"
                                        fontweight={700}
                                        fontSize="16px"
                                        className="ml-xs"
                                      />
                                    </Box>
                                  )}
                              </Box>
                            </Box>
                            {showSubSection &&
                              openIndex === subSectionIndex && (
                                <Box component="div">
                                  <Grid container rowSpacing={'24px'}>
                                    <Grid item xs={5}>
                                      <DropdownComponent
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        names={eiService}
                                        defaultlabelid="EI Service/Method"
                                        labelid="IFSP.EIService/Method"
                                        value={values.eiService}
                                        handleChange={(value: string) => {
                                          setFieldValue('eiService', value);
                                        }}
                                        Required
                                      />
                                      {errors.eiService &&
                                        touched.eiService && (
                                          <MediumTypography
                                            className="errorText-md"
                                            labelid={errors.eiService}
                                            defaultlabel="EI Service/Method is Required"
                                          />
                                        )}
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={5}>
                                      <DropdownComponent
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        names={providedBy}
                                        value={values.providedBy}
                                        defaultlabelid="Provided by (Discipline Responsibile)"
                                        labelid="IFSP.provided"
                                        handleChange={(value: string) => {
                                          setFieldValue('providedBy', value);
                                        }}
                                        Required
                                      />
                                      {errors.providedBy &&
                                        touched.providedBy && (
                                          <MediumTypography
                                            className="errorText-md"
                                            labelid={errors.providedBy}
                                            defaultlabel="Provided by is Required"
                                          />
                                        )}
                                    </Grid>
                                    <Grid item xs={5}>
                                      <DropdownComponent
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        names={locationOfService}
                                        value={values.locationOfService}
                                        defaultlabelid="Location of Service"
                                        labelid="ifsp.locationOfService"
                                        handleChange={(value: string) => {
                                          setFieldValue(
                                            'locationOfService',
                                            value,
                                          );
                                        }}
                                        Required
                                      />
                                      {errors.locationOfService &&
                                        touched.locationOfService && (
                                          <MediumTypography
                                            className="errorText-md"
                                            labelid={errors.locationOfService}
                                            defaultlabel="Location of Service is Required"
                                          />
                                        )}
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={5}>
                                      <DurationPicker
                                        placeholderRequired={
                                          values.length === ''
                                        }
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        labelId={'IFSP.length'}
                                        interval={25}
                                        defaultLabel={'Length'}
                                        maxDuration={{
                                          hour: 24,
                                          minutes: 0,
                                        }}
                                        required
                                        duration={formatStringDateInDecimals(
                                          values.length,
                                        )}
                                        setDuration={(duration) => {
                                          setFieldValue(
                                            `length`,
                                            formatTimeDurationInDecimals(
                                              duration,
                                            ),
                                          );
                                        }}
                                      />
                                      {errors.length && touched.length && (
                                        <MediumTypography
                                          className="errorText-md"
                                          labelid={errors.length}
                                          defaultlabel="Length is Required"
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={5}>
                                      <DropdownComponent
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        names={period}
                                        value={values.period}
                                        defaultlabelid="Period"
                                        labelid="IFSP.period"
                                        handleChange={(value: string) => {
                                          setFieldValue('period', value);
                                        }}
                                        Required
                                      />
                                      {errors.period && touched.period && (
                                        <MediumTypography
                                          className="errorText-md"
                                          labelid={errors.period}
                                          defaultlabel="Period is Required"
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={5}>
                                      <Textinput
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        className="bg__white"
                                        name="frequencyAndLength"
                                        Value={values.frequency}
                                        labelid="IFSP.frequency"
                                        defaultlabelid="Frequency"
                                        inputProps={{
                                          className: 'translate',
                                          maxLength: 500,
                                        }}
                                        Required
                                        handlechange={(value: string) => {
                                          const filteredValue = value.replace(
                                            /[^0-9]/g,
                                            '',
                                          );
                                          setFieldValue(
                                            'frequency',
                                            filteredValue,
                                          );
                                        }}
                                      />
                                      {errors.frequency &&
                                        touched.frequency && (
                                          <MediumTypography
                                            className="errorText-md"
                                            labelid={errors.frequency}
                                            defaultlabel="Frequency is Required"
                                          />
                                        )}
                                    </Grid>

                                    <Grid item xs={5}>
                                      <DropdownComponent
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        names={intensity}
                                        value={values.intensity}
                                        defaultlabelid="Intensity"
                                        labelid="IFSP.intensity"
                                        handleChange={(value: string) => {
                                          setFieldValue('intensity', value);
                                        }}
                                        Required
                                      />
                                      {errors.intensity &&
                                        touched.intensity && (
                                          <MediumTypography
                                            className="errorText-md"
                                            labelid={errors.intensity}
                                            defaultlabel="Intensity is Required"
                                          />
                                        )}
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>
                                    <Grid item xs={5}>
                                      <Textinput
                                        disabled={
                                          isIfspScreenLocked ||
                                          values.tbrServicePlanId > 0 ||
                                          values.isRecordSubmitted
                                        }
                                        className="bg__white"
                                        name="durationofService"
                                        Value={values.duration}
                                        labelid="IFSP.durationOfService"
                                        defaultlabelid="Duration of Service"
                                        inputProps={{
                                          className: 'translate',
                                          maxLength: 500,
                                        }}
                                        handlechange={(value: string) => {
                                          setFieldValue('duration', value);
                                        }}
                                        Required
                                      />
                                      {errors.duration && touched.duration && (
                                        <MediumTypography
                                          className="errorText-md"
                                          labelid={errors.duration}
                                          defaultlabel="Duration of Service is Required"
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={5}>
                                      {values.startDate && (
                                        <DatePickerComponent
                                          disabledDate={
                                            isIfspScreenLocked ||
                                            values.tbrServicePlanId > 0 ||
                                            values.isRecordSubmitted
                                          }
                                          className="bg__white"
                                          labelid="IFSP.startDate"
                                          disableFuture={false}
                                          defaultlabelid="Start Date"
                                          value={
                                            dayjs(values.startDate) as Dayjs
                                          }
                                          required={true}
                                          handlechange={(
                                            dateValue: Dayjs | null,
                                          ) => {
                                            const formattedDate =
                                              dayjs(dateValue).format(
                                                'MM/DD/YYYY',
                                              );
                                            if (dateValue === null) {
                                              setFieldValue('startDate', null);
                                            } else {
                                              setFieldValue(
                                                'startDate',
                                                formattedDate,
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                      {values.startDate === null && (
                                        <DatePickerComponent
                                          disabledDate={
                                            isIfspScreenLocked ||
                                            values.tbrServicePlanId > 0 ||
                                            values.isRecordSubmitted
                                          }
                                          className="bg__white"
                                          labelid="IFSP.startDate"
                                          disableFuture={false}
                                          defaultlabelid="Start Date"
                                          value={null}
                                          required={true}
                                          handlechange={(
                                            dateValue: Dayjs | null,
                                          ) => {
                                            const formattedDate =
                                              dayjs(dateValue).format(
                                                'MM/DD/YYYY',
                                              );
                                            if (dateValue === null) {
                                              setFieldValue('startDate', null);
                                            } else {
                                              setFieldValue(
                                                'startDate',
                                                formattedDate,
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                      {errors.startDate &&
                                        touched.startDate && (
                                          <MediumTypography
                                            className="errorText-md"
                                            labelid={errors.startDate}
                                            defaultlabel="Start Date is Required"
                                          />
                                        )}
                                    </Grid>
                                    <Grid item xs={0.5}></Grid>

                                    <Grid item xs={5}>
                                      {values.endDate && (
                                        <DatePickerComponent
                                          disabledDate={isIfspScreenLocked}
                                          className="bg__white"
                                          labelid="IFSP.endDate"
                                          disableFuture={false}
                                          defaultlabelid="End Date"
                                          value={dayjs(values.endDate) as Dayjs}
                                          handlechange={(
                                            dateValue: Dayjs | null,
                                          ) => {
                                            const formattedDate =
                                              dayjs(dateValue).format(
                                                'MM/DD/YYYY',
                                              );
                                            if (dateValue === null) {
                                              setFieldValue('endDate', null);
                                            } else {
                                              setFieldValue(
                                                'endDate',
                                                formattedDate,
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                      {(values.endDate === null ||
                                        values.endDate === '') && (
                                        <DatePickerComponent
                                          disabledDate={isIfspScreenLocked}
                                          className="bg__white"
                                          labelid="IFSP.endDate"
                                          disableFuture={false}
                                          defaultlabelid="End Date"
                                          value={null}
                                          handlechange={(
                                            dateValue: Dayjs | null,
                                          ) => {
                                            const formattedDate =
                                              dayjs(dateValue).format(
                                                'MM/DD/YYYY',
                                              );
                                            if (dateValue === null) {
                                              setFieldValue('endDate', null);
                                            } else {
                                              setFieldValue(
                                                'endDate',
                                                formattedDate,
                                              );
                                            }
                                          }}
                                        />
                                      )}
                                      {errors.endDate && touched.endDate && (
                                        <MediumTypography
                                          className="errorText-md"
                                          labelid={errors.endDate}
                                          defaultlabel="End Date is Required"
                                        />
                                      )}
                                    </Grid>
                                    <Grid item xs={5}>
                                      <DropdownComponent
                                        disabled={isIfspScreenLocked}
                                        labelid="IFSP.reasonForLate"
                                        defaultlabelid="Reason for Late"
                                        names={lookUpReasons}
                                        value={values.reasonIfLate}
                                        handleChange={(value: string) => {
                                          setFieldValue('reasonIfLate', value);
                                        }}
                                      />
                                    </Grid>
                                    <Grid
                                      item
                                      className="flex__ pl-md mt-xs po cursorPointer"
                                      onClick={() => {
                                        if (!isIfspScreenLocked) {
                                          setOpenedSubSectionIndex(
                                            subSectionIndex,
                                          );
                                          handleSubmit();
                                        }
                                      }}
                                    >
                                      <AddNew />
                                      <MediumTypography
                                        className="ml-xs"
                                        labelid={
                                          isEdit ? 'IFSP.Update' : 'IFSP.Add'
                                        }
                                        defaultlabel={isEdit ? 'Update' : 'Add'}
                                      />
                                    </Grid>
                                  </Grid>

                                  <Box
                                    component="div"
                                    className="respoScrollTable mt-lg mb-md"
                                  >
                                    <TableContainer component={Paper}>
                                      <Table>
                                        <TableHeader
                                          className="listDataTableHead"
                                          headerNames={headCells}
                                          checkBoxRequired={false}
                                        />
                                        <TableBody className="tableRowcss">
                                          {openIndex === subSectionIndex &&
                                            sdpMainData[
                                              subSectionIndex
                                            ].earlyInterventionService.map(
                                              (
                                                listData: ServiceDeliveryPlanPropsData,
                                                index,
                                              ) => (
                                                <EIServiceTableRow
                                                  isIfspScreenLocked={
                                                    isIfspScreenLocked
                                                  }
                                                  actionMode={
                                                    isEdit ? 'update' : 'add'
                                                  }
                                                  key={index}
                                                  actionRequired={true}
                                                  data={listData}
                                                  onEdit={() => {
                                                    if (!isIfspScreenLocked) {
                                                      onEditRow(
                                                        subSectionIndex,
                                                        index,
                                                      );
                                                    }
                                                  }}
                                                  onDelete={() => {
                                                    if (
                                                      !isIfspScreenLocked &&
                                                      values.tbrServicePlanId <
                                                        1
                                                    ) {
                                                      onDeleteRow(
                                                        subSectionIndex,
                                                        index,
                                                      );
                                                    }
                                                  }}
                                                  reasonIfLate={lookUpReasons}
                                                  eiService={eiService}
                                                  intensity={intensity}
                                                  locationOfService={
                                                    locationOfService
                                                  }
                                                  period={period}
                                                  providedBy={providedBy}
                                                />
                                              ),
                                            )}
                                        </TableBody>
                                      </Table>
                                      {sdpMainData[subSectionIndex]
                                        .earlyInterventionService.length ===
                                        0 && (
                                        <Box
                                          component="div"
                                          className="width__100"
                                        >
                                          <MediumTypography
                                            textstyle={'center'}
                                            labelid="IFSP.emptyMessage"
                                            defaultlabel="No data added yet"
                                            className="p-sm"
                                          />
                                        </Box>
                                      )}
                                    </TableContainer>
                                  </Box>
                                  <MediumTypography
                                    labelid="bv"
                                    defaultlabel="Note"
                                    fontweight={400}
                                    textColor="#2A4241"
                                    className="pt-md"
                                  />
                                  <textarea
                                    id={'notes'}
                                    className="translate"
                                    disabled={isIfspScreenLocked}
                                    placeholder="Enter your comments"
                                    value={sdpMainData[subSectionIndex].notes}
                                    onChange={(text) => {
                                      setSdpMainData((prevData) => {
                                        const tempData = [...prevData];
                                        tempData[subSectionIndex].notes =
                                          text.target.value;
                                        return tempData;
                                      });
                                      translateTextArea('notes');
                                    }}
                                    style={TextAreaStyle}
                                    maxLength={500}
                                    onFocus={(e) => {
                                      e.target.style.borderColor = '#00C6B8';
                                    }}
                                    onBlur={(e) => {
                                      e.target.style.borderColor = '#ccc';
                                    }}
                                  />
                                  <Box component="div">
                                    {ServiceDeliveryPlanData.EIService.map(
                                      (question) => (
                                        <Box component="div" key={question.id}>
                                          <Box
                                            component="div"
                                            className="align__items__center display-inline-flex"
                                          >
                                            <MediumTypography
                                              sxProps={{
                                                marginLeft: '4px',
                                                marginRight: '24px',
                                              }}
                                              labelid={question.displayQueId}
                                              defaultlabel={
                                                question.displayQuetext
                                              }
                                            />
                                          </Box>
                                          <Box
                                            component="div"
                                            className="pt-sm"
                                            sx={{ width: '48%' }}
                                          >
                                            <textarea
                                              className="translate"
                                              id={'justification'}
                                              disabled={isIfspScreenLocked}
                                              placeholder="Enter your comments"
                                              value={
                                                question.key === 'justification'
                                                  ? sdpMainData[subSectionIndex]
                                                      .justification
                                                  : ''
                                              }
                                              onChange={(e) =>
                                                setSdpMainData((prevData) => {
                                                  const tempData = [
                                                    ...prevData,
                                                  ];
                                                  if (
                                                    question.key ===
                                                    'justification'
                                                  ) {
                                                    translateTextArea(
                                                      'justification',
                                                    );
                                                    tempData[
                                                      subSectionIndex
                                                    ].justification =
                                                      e.target.value;
                                                  }
                                                  return tempData;
                                                })
                                              }
                                              style={TextAreaStyle}
                                              maxLength={5000}
                                              onFocus={(e) => {
                                                e.target.style.borderColor =
                                                  '#00C6B8';
                                              }}
                                              onBlur={(e) => {
                                                e.target.style.borderColor =
                                                  '#ccc';
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      ),
                                    )}
                                  </Box>
                                  <Box
                                    component="div"
                                    className="mb-lg pb-lg"
                                    sx={{
                                      borderBottom: '1px solid #00C6B8',
                                    }}
                                  >
                                    <MediumTypography
                                      defaultlabel="Other Services"
                                      labelid="IFSP.otherServices"
                                      sxProps={{
                                        marginTop: '16px',
                                        marginBottom: '8px',
                                      }}
                                      fontSize="16px"
                                      fontweight={600}
                                    />
                                    {ServiceDeliveryPlanData.OtherServices.map(
                                      (question) => (
                                        <Box component="div" key={question.id}>
                                          <Box
                                            component="div"
                                            className="align__items__center display-inline-flex"
                                          >
                                            <MediumTypography
                                              sxProps={{
                                                marginLeft: '4px',
                                                marginRight: '24px',
                                                fontFamily: 'Lato-Regular',
                                                fontSize: 14,
                                                fontWeight: 500,
                                              }}
                                              labelid={question.displayQueId}
                                              defaultlabel={
                                                question.displayQuetext
                                              }
                                            />
                                          </Box>
                                          <Box
                                            component="div"
                                            className="pt-sm"
                                            sx={{ width: '48%' }}
                                          >
                                            <textarea
                                              className="translate"
                                              id={
                                                question.key ===
                                                'otherNeededService'
                                                  ? 'otherNeededService'
                                                  : 'stepsToTake'
                                              }
                                              placeholder="Enter your comments"
                                              disabled={isIfspScreenLocked}
                                              value={
                                                question.key ===
                                                'otherNeededService'
                                                  ? sdpMainData[subSectionIndex]
                                                      .otherNeededService
                                                  : sdpMainData[subSectionIndex]
                                                      .stepsToTake
                                              }
                                              onChange={(e) =>
                                                setSdpMainData((prevData) => {
                                                  const tempData = [
                                                    ...prevData,
                                                  ];
                                                  if (
                                                    question.key ===
                                                    'otherNeededService'
                                                  ) {
                                                    translateTextArea(
                                                      'otherNeededService',
                                                    );
                                                    tempData[
                                                      subSectionIndex
                                                    ].otherNeededService =
                                                      e.target.value;
                                                  }
                                                  if (
                                                    question.key ===
                                                    'stepsToTake'
                                                  ) {
                                                    translateTextArea(
                                                      'stepsToTake',
                                                    );
                                                    tempData[
                                                      subSectionIndex
                                                    ].stepsToTake =
                                                      e.target.value;
                                                  }
                                                  return tempData;
                                                })
                                              }
                                              maxLength={5000}
                                              style={TextAreaStyle}
                                              onFocus={(e) => {
                                                e.target.style.borderColor =
                                                  '#00C6B8';
                                              }}
                                              onBlur={(e) => {
                                                e.target.style.borderColor =
                                                  '#ccc';
                                              }}
                                            />
                                          </Box>
                                        </Box>
                                      ),
                                    )}
                                  </Box>
                                </Box>
                              )}
                          </Box>
                        ))}
                        <ButtonComponent
                          disabled={
                            sdpMainData.length >= 2 || isIfspScreenLocked
                          }
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="Ifsp.sdpButton"
                          defaultLabelId="Add Additional Service Delivery Plan for SSP Autism"
                          onClick={() => {
                            addSubSection();
                          }}
                        />
                      </div>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </Box>
    </Box>
  );
};

export default ServiceDeliveryPlan;
