import { Box, ThemeProvider } from '@mui/material';
import {
  a11yProps,
  CustomTab,
  CustomTabs,
  TabPanel,
  TabTheme,
} from './TabNavigationComponent';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LEAPreschoolForm from '../../pages/referral/LEAPreschoolForm';
import LEAReferralConsent from '../../pages/consent/LEAReferralConsent';

const LEATabNavigationComponent = () => {
  const [currentTab, setCurrentTab] = useState<number | null>(null);
  const intl = useIntl();

  useEffect(() => {
    const currentTabValue = sessionStorage.getItem('leaReferral');
    setCurrentTab(currentTabValue === null ? 0 : parseInt(currentTabValue));

    return () => {
      sessionStorage.removeItem('leaReferral');
    };
  }, []);

  if (currentTab === null) {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <ThemeProvider theme={TabTheme}>
        <CustomTabs
          sx={{ marginLeft: '4px' }}
          value={currentTab}
          onChange={(_, newValue) => {
            sessionStorage.setItem('leaReferral', String(newValue));
            setCurrentTab(newValue);
          }}
        >
          <CustomTab
            {...a11yProps(0)}
            label={intl.formatMessage({
              id: 'leaReferralTitleSidebar',
              defaultMessage: 'LEA Referral',
            })}
          />
          <CustomTab
            {...a11yProps(0)}
            label={intl.formatMessage({
              id: 'LeaSchool.LEAReferralNavigate',
              defaultMessage: 'LEA Preschool',
            })}
          />
        </CustomTabs>
        <TabPanel value={currentTab} index={0}>
          <LEAReferralConsent />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <LEAPreschoolForm />
        </TabPanel>
      </ThemeProvider>
    </Box>
  );
};

export default LEATabNavigationComponent;
