import { FC } from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Tooltip } from '@mui/material';
import { convertGenderToFrontend } from '../../utils/genderUtil';

export type Client = {
  name: string;
  dphId: string;
  gender: string;
  dob: string;
  address: string;
  id?: string;
};

interface ClientDetails {
  clients: Client;
  titleLabel: string;
  defaultTitleLabel: string;
  searchClientLabel: string;
  dphIdLabel: string;
  dphIddefaultLabel: string;
  dobLabel: string;
  dobDefaultLabel: string;
  genderLabel: string;
  genderDefaultLabel: string;
  addressLabel: string;
  addressDefaultLabel: string;
  defaultSearchClientLabel: string;
}

const ConsentClientDetails: FC<ClientDetails> = (props) => {
  return (
    <Box className="width__100">
      <MediumTypography
        labelid={props.titleLabel}
        defaultlabel={props.defaultTitleLabel}
        fontSize="18px"
        fontweight={600}
        className="pt-sm pb-sm"
      />
      {props.clients.name !== '' && (
        <Box>
          <MediumTypography
            label={props.clients.name}
            fontweight={600}
            fontSize="16px"
          />
          <Box className="flex__ pt-sm">
            <Box className="flex__ pr-md">
              <MediumTypography
                labelid={props.dphIdLabel}
                defaultlabel={props.dphIddefaultLabel}
                textColor="#97A6A5"
              />

              <Box className="width150px">
                <Tooltip
                  title={
                    props.clients.dphId && props.clients.dphId.length > 10
                      ? props.clients.dphId
                      : ''
                  }
                  followCursor
                  arrow={true}
                >
                  <MediumTypography
                    className="pl-xs "
                    textColor="#2A4241"
                    label={
                      props.clients.dphId === null || props.clients.dphId === ''
                        ? '-'
                        : props.clients.dphId
                    }
                    sxProps={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  />
                </Tooltip>
              </Box>
            </Box>
            <Box className="flex__ pr-md">
              <MediumTypography
                labelid={props.genderLabel}
                defaultlabel={props.genderDefaultLabel}
                fontweight={500}
                textColor="#97A6A5"
              />

              <MediumTypography
                className="pl-xs width100px ellipsis"
                textColor="#2A4241"
                label={
                  props.clients.gender === null || props.clients.gender === ''
                    ? '-'
                    : convertGenderToFrontend(props.clients.gender)
                }
              />
            </Box>
            <Box className="flex__ pr-md">
              <MediumTypography
                labelid={props.dobLabel}
                defaultlabel={props.dobDefaultLabel}
                textColor="#97A6A5"
              />

              <MediumTypography
                className="pl-xs width100px ellipsis"
                textColor="#2A4241"
                label={
                  props.clients.dob === null || props.clients.dob === ''
                    ? '-'
                    : props.clients.dob
                }
              />
            </Box>
            <Box className="flex__ pr-md">
              <MediumTypography
                labelid={props.addressLabel}
                defaultlabel={props.addressDefaultLabel}
                textColor="#97A6A5"
              />

              <Box className="width200px">
                <Tooltip
                  title={
                    props.clients.address && props.clients.address.length > 10
                      ? props.clients.address
                          .split(',')
                          .filter((segment) => segment.trim() !== '')
                          .join(', ')
                      : ''
                  }
                  followCursor
                  arrow={true}
                >
                  <Box>
                    <MediumTypography
                      className="pl-xs "
                      textColor="#2A4241"
                      label={
                        props.clients.address === null ||
                        props.clients.address === ''
                          ? '-'
                          : props.clients.address
                              .split(',')
                              .filter((segment) => segment.trim() !== '')
                              .join(', ')
                      }
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ConsentClientDetails;
