import React, { useEffect, ReactNode, useRef, useState } from 'react';
import { FC } from 'react';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

interface TranslateProps {
  screenId: string;
  initialLanguage: string;
  languageChangeCallback: (language: string) => void;
  children: ReactNode;
}

const addGoogleTranslateScript = async (element: string) => {
  console.log('addGoogleTranslateScript trying to add', element);

  // Function to load the script and wait for it to load
  // const loadScript = () => {
  //   return new Promise<void>((resolve, reject) => {
  //     const existingScript = document.getElementById('google_script');
  //     if (existingScript) {
  //       resolve();
  //     }

  //     const script = document.createElement('script');
  //     script.id = 'google_script';
  //     script.type = 'text/javascript';
  //     script.src =
  //       'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
  //     script.async = true;
  //     script.defer = false;

  //     script.onload = async () => {
  //       console.log('Google Translate loaded');
  //       const waitForGoogleTranslate = async () => {
  //         while (
  //           !window.google ||
  //           !window.google.translate ||
  //           !window.google.translate.TranslateElement ||
  //           !window.google.translate.TranslateElement.InlineLayout ||
  //           !window.google.translate.TranslateElement.InlineLayout.SIMPLE
  //         ) {
  //           console.log('Google Translate loading...');
  //           await new Promise((resolve2) => setTimeout(resolve2, 100));
  //         }
  //       };

  //       await waitForGoogleTranslate();
  //       resolve();
  //     };

  //     script.onerror = () => {
  //       console.error('Google Translate failed to load');
  //       reject(new Error('Google Translate script failed to load'));
  //     };

  //     document.body.appendChild(script);
  //   });
  // };

  // try {
  //   await loadScript();
  //   console.log('addGoogleTranslateScript loaded');
  //   // Ensure Google Translate is available before using it
  //   if (
  //     window.google &&
  //     window.google.translate &&
  //     window.google.translate.TranslateElement &&
  //     window.google.translate.TranslateElement.InlineLayout &&
  //     window.google.translate.TranslateElement.InlineLayout.SIMPLE
  //   ) {
  //     const elementid = document.getElementById(element);
  //     if (!elementid) {
  //       throw new Error(`Element with ID ${elementid} not found`);
  //     }
  //     new window.google.translate.TranslateElement(
  //       {
  //         includedLanguages: 'ar,zh-CN,kea,ht,km,lo,pt,es,vi', // Specify the languages you want
  //         layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
  //         autoDisplay: false,
  //         pageLanguage: 'en',
  //       },
  //       element,
  //     );
  //   } else {
  //     console.log('Google Translate API is not available');
  //   }
  // } catch (error) {
  //   console.error('Error initializing Google Translate:', error);
  // }
};

export const translate = async (element: string) => {
  await addGoogleTranslateScript(element);
};

export const removeTranslate = async () => {
  try {
    const script = document.getElementById('google_script');
    if (script && script.id === 'google_script') {
      document.cookie =
        'googtrans=; path=/; domain=' +
        window.location.hostname +
        '; expires=Thu, 01 Jan 1970 00:00:00 UTC;';

      document.body.removeChild(script);
      script.remove();
      window.location.reload();
    }
  } catch (error) {
    console.error('Google Translate:', error);
  }
};

export const setLanguage = (initialLanguage = 'en') => {
  const iframe = document.querySelector(
    '.VIpgJd-ZVi9od-xl07Ob-OEVmcd',
  ) as HTMLIFrameElement;

  if (iframe) {
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;

    if (iframeDoc) {
      const links = iframeDoc.querySelectorAll(
        '#\\:1\\.menuBody > table > tbody > tr > td > a',
      ) as NodeListOf<HTMLAnchorElement>;
      if (initialLanguage === 'en') {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const element = Array.from(links).find((link: any) => {
        if (link.value?.trim() === initialLanguage) {
          return link;
        }
      }) as HTMLAnchorElement | null;

      if (element) {
        console.log('Element found inside iframe!', element);
        setTimeout(() => {
          element.click(); // Simulate click
        }, 100);
      } else {
        console.log('Element not found inside iframe!');
        setTimeout(() => {
          setLanguage(initialLanguage);
        }, 100);
        return;
      }
    } else {
      console.log('Unable to access iframe document!');
    }
  } else {
    console.log('Iframe not found!');
    setTimeout(() => {
      setLanguage(initialLanguage);
    }, 100);
    return;
  }
};

export function isOtherLanguage() {
  if (document.documentElement.lang !== 'en') {
    return true;
  }
  return false;
}

// function to translate even the text area text
export const translateTextArea = (id: string) => {
  const element =
    (document.getElementById(id) as HTMLTextAreaElement) || HTMLInputElement;
  element.setAttribute('value', element.value);
};

const TranslateWidget: FC<TranslateProps> = ({
  children,
  screenId,
  initialLanguage,
  languageChangeCallback,
}) => {
  const [transLoading, setTransLoading] = useState(false);
  const translationId = useRef(screenId);
  useEffect(() => {
    console.log('translationId', translationId.current);
    const expires = new Date();
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
    document.cookie =
      'googtrans' +
      '=' +
      '/auto/' +
      initialLanguage +
      ';expires=' +
      expires.toUTCString() +
      ';path=/;';

    let observer: MutationObserver;
    const translateLang = async () => {
      await translate(translationId.current);
      const existingScript = document.getElementById('google_script');
      observer = new MutationObserver(function (mutations) {
        mutations.forEach(function (mutation) {
          if (mutation.attributeName === 'lang' && existingScript) {
            languageChangeCallback(document.documentElement.lang);

            const elementdiv = document.getElementById(
              translationId.current,
            ) as HTMLDivElement;

            const textareas = document.querySelectorAll(
              'textarea',
            ) as NodeListOf<HTMLTextAreaElement>;

            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                textareas.forEach((element) => {
                  element.setAttribute('aria-disabled', 'true');
                  element.setAttribute('readonly', 'true');
                  // element.style.cursor = 'not-allowed';
                  element.style.pointerEvents = 'none';
                  // element.setAttribute('style', 'pointer-events: none;');
                  // element.setAttribute('pointer-events', 'none');
                });
              }
            } else {
              textareas.forEach((element) => {
                element.removeAttribute('aria-disabled');
                element.removeAttribute('readonly');
                // element.style.cursor = '';
                element.style.pointerEvents = '';
                // element.setAttribute('style', 'pointer-events: auto;');
                // element.setAttribute('pointer-events', 'auto');
              });
            }

            const textfields = document.querySelectorAll(
              'input',
            ) as NodeListOf<HTMLInputElement>;
            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                textfields.forEach((element) => {
                  if (
                    $(element).attr('type') !== undefined &&
                    $(element).attr('type') === 'text'
                  ) {
                    element.setAttribute('aria-disabled', 'true');
                    element.setAttribute('readonly', 'true');
                    // element.style.cursor = 'not-allowed';
                    element.style.pointerEvents = 'none';
                    // element.classList.add('translateDisabledTextField');
                    // element.disabled = true;
                  }
                });
              }
            } else {
              textfields.forEach((element) => {
                if (
                  $(element).attr('type') !== undefined &&
                  $(element).attr('type') === 'text'
                ) {
                  element.removeAttribute('aria-disabled');
                  element.removeAttribute('readonly');
                  element.style.pointerEvents = '';
                  // element.classList.remove('translateDisabledTextField');
                  // element.style.cursor = '';
                  // element.disabled = false;
                }
              });
            }

            const radioFields = document.querySelectorAll(
              '.MuiRadioGroup-root',
            ) as NodeListOf<HTMLInputElement>;
            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                radioFields.forEach((element) => {
                  element.setAttribute('aria-disabled', 'true');
                  element.style.pointerEvents = 'none';
                  // element.setAttribute('style', 'pointer-events: none;');
                  // element.disabled = true;
                });
              }
            } else {
              radioFields.forEach((element) => {
                element.removeAttribute('aria-disabled');
                element.style.pointerEvents = '';
                // element.setAttribute('style', 'pointer-events: auto;');
                // element.disabled = false;
              });
            }
            const checkBoxFields = document.querySelectorAll(
              '.MuiCheckbox-root',
            ) as NodeListOf<HTMLInputElement>;
            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                checkBoxFields.forEach((element) => {
                  element.setAttribute('aria-disabled', 'true');
                  element.style.pointerEvents = 'none';
                  // element.setAttribute('style', 'pointer-events: none;');
                  // element.disabled = true;
                });
              }
            } else {
              checkBoxFields.forEach((element) => {
                element.removeAttribute('aria-disabled');
                element.style.pointerEvents = '';
                // element.setAttribute('style', 'pointer-events: auto;');
                // element.disabled = false;
              });
            }
            const formControlFields = document.querySelectorAll(
              '.MuiFormControlLabel-root',
            ) as NodeListOf<HTMLInputElement>;
            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                formControlFields.forEach((element) => {
                  element.setAttribute('aria-disabled', 'true');
                  element.style.pointerEvents = 'none';
                  // element.setAttribute('style', 'pointer-events: none;');
                  // element.disabled = true;
                });
              }
            } else {
              formControlFields.forEach((element) => {
                element.removeAttribute('aria-disabled');
                element.style.pointerEvents = '';
                // element.setAttribute('style', 'pointer-events: auto;');
                // element.disabled = false;
              });
            }

            const multiSelect = document.querySelectorAll(
              '.inputSelectFormLabel',
            );
            if (elementdiv && isOtherLanguage()) {
              if (isOtherLanguage()) {
                multiSelect.forEach((element) => {
                  element.setAttribute('aria-disabled', 'true');
                  // element. = 'none';
                  element.setAttribute('style', 'pointer-events: none;');
                });
              }
            } else {
              multiSelect.forEach((element) => {
                element.removeAttribute('aria-disabled');
                // element.style.pointerEvents = '';
                element.setAttribute('style', `pointer-events: ''`);
              });
            }
          }
        });
      });

      observer.observe(document.documentElement, {
        attributes: true,
      });

      setTimeout(() => {
        setTransLoading(true);
      }, 5000);
    };

    setTimeout(() => {
      translateLang();
    }, 1000);

    return () => {
      try {
        observer?.disconnect();
        removeTranslate();
      } catch (err) {
        console.log(err);
      }
    };
  }, []);

  const handleVisibility = () => {
    const existingScript = document.getElementById('google_script');
    if (!existingScript) return;
    const backuptextarea = [] as string[];
    const backuptextinput = [] as string[];
    const elementdiv = document.getElementById(
      translationId.current,
    ) as HTMLDivElement;

    const textareas = document.querySelectorAll(
      '#' + translationId.current + ' textarea',
    ) as NodeListOf<HTMLTextAreaElement>;
    if (elementdiv) {
      textareas.forEach((textarea, index) => {
        backuptextarea[index] =
          textarea.getAttribute('value') || textarea.value;
      });
    }
    console.log(textareas);
    setTimeout(() => {
      if (document.visibilityState === 'visible') {
        const textareastemp = document.querySelectorAll(
          '#' + translationId.current + ' textarea',
        ) as NodeListOf<HTMLTextAreaElement>;
        if (elementdiv) {
          textareastemp.forEach((textarea, index) => {
            if (backuptextarea[index] !== undefined)
              textarea.value = backuptextarea[index];
          });
        }
      }
    }, 1000);

    const textinputs = document.querySelectorAll(
      'input',
    ) as NodeListOf<HTMLInputElement>;
    if (elementdiv) {
      textinputs.forEach((textarea, index) => {
        if (
          $(textarea).attr('type') !== undefined &&
          $(textarea).attr('type') === 'text'
        ) {
          backuptextinput[index] =
            textarea.getAttribute('value') || textarea.value;
        }
      });
    }
    setTimeout(() => {
      if (document.visibilityState === 'visible') {
        const textareastemp = document.querySelectorAll(
          'input',
        ) as NodeListOf<HTMLInputElement>;
        if (elementdiv) {
          textareastemp.forEach((textarea, index) => {
            if (
              $(textarea).attr('type') !== undefined &&
              $(textarea).attr('type') === 'text' &&
              backuptextinput[index] !== undefined
            ) {
              textarea.value = backuptextinput[index];
            }
          });
        }
      }
    }, 1000);
  };

  // const handleBlur = (backuptextarea: string[], backuptextinput: string[]) => {
  //   const elementdiv = document.getElementById(
  //     translationId.current,
  //   ) as HTMLDivElement;

  //   const textareas = document.querySelectorAll(
  //     '#' + translationId.current + ' textarea',
  //   ) as NodeListOf<HTMLTextAreaElement>;
  //   if (elementdiv) {
  //     textareas.forEach((textarea, index) => {
  //       backuptextarea[index] = textarea.value;
  //     });
  //   }
  //   // setTimeout(() => {
  //   //   // if (document.visibilityState === 'visible') {
  //   //   const textareastemp = document.querySelectorAll(
  //   //     '#' + translationId.current + ' textarea',
  //   //   ) as NodeListOf<HTMLTextAreaElement>;
  //   //   if (elementdiv) {
  //   //     textareastemp.forEach((textarea, index) => {
  //   //       textarea.value = backuptextarea[index];
  //   //     });
  //   //   }
  //   //   // }
  //   // }, 2000);

  //   const textinputs = document.querySelectorAll(
  //     'input',
  //   ) as NodeListOf<HTMLInputElement>;
  //   if (elementdiv) {
  //     textinputs.forEach((textarea, index) => {
  //       if (
  //         $(textarea).attr('type') !== undefined &&
  //         $(textarea).attr('type') === 'text'
  //       ) {
  //         backuptextinput[index] = textarea.value;
  //       }
  //     });
  //   }
  //   // setTimeout(() => {
  //   //   // if (document.visibilityState === 'visible') {
  //   //   const textareastemp = document.querySelectorAll(
  //   //     'input',
  //   //   ) as NodeListOf<HTMLInputElement>;
  //   //   if (elementdiv) {
  //   //     textareastemp.forEach((textarea, index) => {
  //   //       if (
  //   //         $(textarea).attr('type') !== undefined &&
  //   //         $(textarea).attr('type') === 'text'
  //   //       ) {
  //   //         textarea.value = backuptextinput[index];
  //   //       }
  //   //     });
  //   //     // }
  //   //   }
  //   // }, 2000);
  // };

  // const handlefocus = (backuptextarea: string[], backuptextinput: string[]) => {
  //   console.log('visibilitychange');
  //   const elementdiv = document.getElementById(
  //     translationId.current,
  //   ) as HTMLDivElement;

  //   // const textareas = document.querySelectorAll(
  //   //   '#' + translationId.current + ' textarea',
  //   // ) as NodeListOf<HTMLTextAreaElement>;
  //   // if (elementdiv) {
  //   //   textareas.forEach((textarea, index) => {
  //   //     backuptextarea[index] = textarea.value;
  //   //   });
  //   // }
  //   setTimeout(() => {
  //     // if (document.visibilityState === 'visible') {
  //     const textareastemp = document.querySelectorAll(
  //       '#' + translationId.current + ' textarea',
  //     ) as NodeListOf<HTMLTextAreaElement>;
  //     if (elementdiv) {
  //       textareastemp.forEach((textarea, index) => {
  //         textarea.value = backuptextarea[index];
  //       });
  //     }
  //     // }
  //   }, 2000);

  //   // const textinputs = document.querySelectorAll(
  //   //   'input',
  //   // ) as NodeListOf<HTMLInputElement>;
  //   // if (elementdiv) {
  //   //   textinputs.forEach((textarea, index) => {
  //   //     if (
  //   //       $(textarea).attr('type') !== undefined &&
  //   //       $(textarea).attr('type') === 'text'
  //   //     ) {
  //   //       backuptextinput[index] = textarea.value;
  //   //     }
  //   //   });
  //   // }
  //   setTimeout(() => {
  //     // if (document.visibilityState === 'visible') {
  //     const textareastemp = document.querySelectorAll(
  //       'input',
  //     ) as NodeListOf<HTMLInputElement>;
  //     if (elementdiv) {
  //       textareastemp.forEach((textarea, index) => {
  //         if (
  //           $(textarea).attr('type') !== undefined &&
  //           $(textarea).attr('type') === 'text'
  //         ) {
  //           textarea.value = backuptextinput[index];
  //         }
  //       });
  //       // }
  //     }
  //   }, 2000);
  // };

  useEffect(() => {
    window.addEventListener('visibilitychange', () => {
      console.log('visibility');
      handleVisibility();
    });
    document.addEventListener('openModel', () => {
      console.log('opened');
      handleVisibility();
    });

    // window.addEventListener(
    //   'blur',
    //   () => console.log('Blur'),
    //   // handleBlur(backuptextarea, backuptextinput),
    // );
    // window.addEventListener(
    //   'focus',
    //   () => console.log('Focus'),
    //   // handlefocus(backuptextarea, backuptextinput),
    // );
    return () => {
      window.removeEventListener('visibilitychange', () => handleVisibility);

      //   window.removeEventListener('blur', () =>
      //     handleBlur(backuptextarea, backuptextinput),
      //   );
      //   window.removeEventListener('focus', () =>
      //     handlefocus(backuptextarea, backuptextinput),
      //   );
    };
  }, []);

  useEffect(() => {
    console.log('transLoading', transLoading);
  }, [transLoading]);

  return (
    <div
      id={translationId.current}
      className="translate"
      key={translationId.current}
    >
      {children}
    </div>
  );
};

export default TranslateWidget;
