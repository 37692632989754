import { Box, Grid, Card, AlertColor } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as NotificationWithoutBell } from '../../assets/images/notification_on.svg';
import {
  CLIENT_NAME_KEY,
  REFERRAL_ID_KEY,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import {
  NotificationCenterType,
  NotificationMain,
  getNotificationReadApi,
  getNotificationsApi,
} from '../../services/configApi/notification/notification';
import AppPagination from '../../components/shared/AppPagination';
import { ReactComponent as NotifficationRead } from '../../assets/images/notificationRead.svg';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import {
  ACTIVITY_APPROVAL_SHEET_ROUTE_NAME,
  ACTIVITY_SHEET_ROUTE_NAME,
  FACESHEET,
  PROFILE_ROUTE_NAME,
  SCHEDULER_ROUTE_NAME,
  TASK_MANAGER,
} from '../../routes/Routing';
import { formatDate } from '../../utils/dateUtil';
import { useLocation, useNavigate } from 'react-router';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import moment from 'moment';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';

const NotificationList = () => {
  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState<
    NotificationCenterType[]
  >([]);
  const PAGE_SIZE = 10;
  const { state } = useLocation();
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [unread, setUnread] = useState<boolean | null>(
    state.displayRead == false ? false : null,
  );

  const getNotifications = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId === null) {
      return;
    }
    getNotificationsApi(userId, pageNumber, PAGE_SIZE, '', '', unread)
      .then((response: NotificationMain) => {
        setNotificationList(response.notificationDetails);
        setPaginationCount(response.totalCount);
      })
      .catch((error) => {
        setOpenSnackBar(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  useEffect(() => {
    getNotifications();
  }, [pageNumber, unread]);

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  const notificationRead = (items: NotificationCenterType) => {
    getNotificationReadApi(items.id, true)
      .then(() => {
        const customEvent: CustomEvent = new CustomEvent(
          'customNotificationEvent',
          {
            detail: { message: 'Hello from custom event!' },
          },
        );
        window.dispatchEvent(customEvent);
        if (
          items.notificationType === 'intake_facilitator' ||
          items.notificationType === 'client'
        ) {
          localStorage.setItem('ClientId', items.notificationIdTwo);
          localStorage.setItem(CLIENT_NAME_KEY, items.notificationIdThree);
          localStorage.setItem(REFERRAL_ID_KEY, items.notificationId);
          if (checkPermissionForFeature('backend.clients', 'viewPermission')) {
            navigate(FACESHEET);
          }
        } else if (items.notificationType === 'schedule') {
          navigate(PROFILE_ROUTE_NAME);
        } else if (items.notificationType === 'appointment') {
          if (
            checkPermissionForFeature('backend.appointment', 'viewPermission')
          ) {
            navigate(SCHEDULER_ROUTE_NAME, {
              replace: true,
              state: {
                date: formatDate(
                  new Date(items.notificationDate),
                  'YYYY-MM-DD',
                ),
                appointmentId: items.notificationId,
              },
            });
          }
        } else if (items.notificationType === 'appointment_deleted') {
          if (
            checkPermissionForFeature('backend.appointment', 'viewPermission')
          ) {
            navigate(SCHEDULER_ROUTE_NAME, {
              replace: true,
              state: {
                date: formatDate(
                  new Date(items.notificationDate),
                  'YYYY-MM-DD',
                ),
              },
            });
          }
        } else if (items.notificationType === 'activitysheet') {
          navigate(ACTIVITY_SHEET_ROUTE_NAME);
        } else if (items.notificationType === 'activitysheetapproval') {
          if (
            checkPermissionForFeature(
              'backend.review_activity_sheet',
              'viewPermission',
            )
          ) {
            navigate(ACTIVITY_APPROVAL_SHEET_ROUTE_NAME, {
              state: {
                id: items.notificationId,
                date: items.notificationDate,
                firstName: items.notificationIdTwo,
                lastName: items.notificationIdThree,
              },
            });
          }
        } else if (items.notificationType === 'task_manager') {
          if (
            checkPermissionForFeature('backend.task_manager', 'viewPermission')
          ) {
            navigate(TASK_MANAGER);
          }
        }
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('SomethingWentWrongText');
          setToastrDefaultMessage('Something went wrong, Please try again!');
          setToastrVariable('error');
        }
      });
  };

  return (
    <Box component="main">
      <SnackBarComponent
        open={openSnackBar}
        handleClose={handleCloseSnakBar}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box component="section">
        <Box component="div" className="rowContainer">
          <CustomBackArrow onClick={() => window.history.back()} />
          <Grid container className="ml-md">
            <Grid item xs={6} lg={6}>
              <Box className="flex__  align__items__center">
                <MediumTypography
                  labelid={'notificationListText'}
                  defaultlabel={'Notifications Center'}
                  fontSize="30px"
                  fontweight={600}
                  sxProps={{ lineHeight: '40px' }}
                  textColor="#2A4241"
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              className="flex__ align__items__center justifyContent-FlexEnd"
            >
              <CheckBoxComponent
                name="notificationRead"
                labelid="notificationUnreadText"
                defaultlabelid="Display Unread Notifications"
                ischecked={unread == false ? true : false}
                CheckHandleChange={(
                  event: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  setPageNumber(0);
                  const checked = event.target.checked === true ? false : null;
                  setUnread(checked);
                }}
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="div">
          <Card className="formCardview">
            {notificationList.map((items, index) => {
              return (
                <>
                  <Box
                    component="div"
                    className="flex__ cursorPointer"
                    onClick={() => {
                      notificationRead(items);
                    }}
                  >
                    <Box>
                      {items.notificationRead === false ? (
                        <NotificationWithoutBell />
                      ) : (
                        <NotifficationRead />
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '6px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '6px',
                        }}
                      >
                        {items.notificationRead === false ? (
                          <>
                            <MediumTypography
                              label={`${items.body}.`}
                              fontSize="14px"
                              fontweight={600}
                              textColor="#2A4241"
                              sxProps={{ marginLeft: '16px' }}
                            />
                          </>
                        ) : (
                          <MediumTypography
                            label={`${items.body}.`}
                            fontSize="14px"
                            fontweight={500}
                            textColor="#2A4241"
                            sxProps={{ marginLeft: '16px' }}
                          />
                        )}
                        <MediumTypography
                          label={'View'}
                          fontSize="14px"
                          fontweight={500}
                          className="cursorPointer"
                          textColor="#008C82"
                          sxProps={{ marginLeft: '0px' }}
                        />
                      </Box>

                      <MediumTypography
                        label={moment(items.createdOn).format(
                          'MM/DD/YYYY hh:mm:ss a',
                        )}
                        fontSize="14px"
                        fontweight={500}
                        textColor="#97A6A5"
                        sxProps={{ marginLeft: '16px' }}
                      />
                    </Box>
                  </Box>
                  {notificationList.length - 1 !== index && (
                    <Box
                      className="horizontalLine"
                      sx={{ marginY: '8px', width: '100%' }}
                    />
                  )}
                </>
              );
            })}
          </Card>
          {paginationCount > 10 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AppPagination
                pageNumber={pageNumber}
                paginationCount={paginationCount}
                handleChangePage={(_event, newPage) => {
                  if (newPage - 1 !== pageNumber) {
                    setPageNumber(newPage - 1);
                  }
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default NotificationList;
