import dayjs from 'dayjs';
import { CodeListType } from '../pages/ProgressNote/ProgressNote';
import { consentType } from '../pages/consent/ConsentDashBoard';
import { IfspContextDataType } from '../pages/ifsp/ifspContextApi/IfspContex';
import {
  CommunicationLog,
  FaceSheetType,
  IntakeType,
  OfflineData,
  ProgramReferral,
  ProgressNoteType,
  SyncOfflineData,
  ProgramType,
} from '../utils/type';
import {
  DashboardDetailsResponse,
  ReferralResponseType,
} from './configApi/Clients';
import { CommunicationLogResponse } from './configApi/communicationLog/communication';
import { EmployeeDashboardResponseOffline } from './configApi/employees/employeeServices';
import {
  ConsentDetailsType,
  ConsentList,
  ConsentListData,
} from './configApi/forms/Consent/ConsentServices';
import {
  IntakeClientInfoResponseType,
  IntakeCurrentHealthInfoResponseType,
  IntakeFamilyHistoryInfoResponseType,
  IntakePregnancyInfoResponseType,
} from './configApi/forms/Intake/IntakeServices';
import {
  getResponseDB,
  saveResponseDB,
  isExistInDB,
  deleteResponse,
  deleteAll,
  getAllkeys,
} from './storageDB';
import { StatusType } from '../pages/activitySheet/ActivityTableRow';

const backendRoleIntakeCoordinator =
  'employee/listByRole?employeeRole=backend.role.intake_coordinator';
const backendRoleServiceCoordinator =
  'employee/listByRole?employeeRole=backend.role.service_coordinator';
const employeeList = 'employee/list?';
const clientsList = 'clients';
const code = 'code';
const lookup = 'lookup';
const facesheet = 'client/faceSheet?';
const codes = 'codes?';

const ifsp = 'ifsp?';

const communicationPost = 'clients/communicationLogs?';

const consentOld = 'consent?type=SspShare&consentId=';
const consentNew = 'consent?type=SspShare&consentId=';
const consentPut = 'consent?type=CommunicateWithMedical&consentId=';
const progressNotesUrl = 'progress-notes?attendeeId=';
const dashboard =
  'employee/dashboard?timezone=%2B05%3A30&programId=P001&referralId=null';

//intake
const intakeClientTab = 'intake/clientInfo?';
const intakePregnancy = 'intake/pregnancy?';
const intakeCurrentHealth = 'intake/currentHealth?';
const intakefamily = 'intake/family?';

const includeParamsApis: string[] = [];
includeParamsApis.push(consentOld.split('?')[0]);
includeParamsApis.push(consentNew.split('?')[0]);
includeParamsApis.push(consentPut.split('?')[0]);
includeParamsApis.push(progressNotesUrl.split('?')[0]);
includeParamsApis.push(dashboard.split('?')[0]);

const excludeCommonParamsApis: string[] = [];
excludeCommonParamsApis.push(clientsList);
excludeCommonParamsApis.push(lookup);
excludeCommonParamsApis.push(code);
excludeCommonParamsApis.push(employeeList);
excludeCommonParamsApis.push(codes);

const offlinePostApis: string[] = [];
offlinePostApis.push(consentPut.split('?')[0]);
offlinePostApis.push(communicationPost.split('?')[0]);
offlinePostApis.push(ifsp.split('?')[0]);
offlinePostApis.push(intakeClientTab.split('?')[0]);
offlinePostApis.push(intakePregnancy.split('?')[0]);
offlinePostApis.push(intakeCurrentHealth.split('?')[0]);
offlinePostApis.push(intakefamily.split('?')[0]);
offlinePostApis.push(progressNotesUrl.split('?')[0]);

export const getCustomParamsApis = (url: string) => {
  if (url.includes(backendRoleIntakeCoordinator.split('?')[1])) {
    return backendRoleIntakeCoordinator;
  } else if (url.includes(backendRoleServiceCoordinator.split('?')[1])) {
    return backendRoleServiceCoordinator;
  } else {
    return '';
  }
};

export const isOfflinePostApis = (url: string) => {
  if (offlinePostApis.includes(url)) {
    return true;
  } else {
    return false;
  }
};

// check for common api
export const isExcludeCommonParamsApis = (url: string) => {
  if (excludeCommonParamsApis.includes(url)) {
    return true;
  } else {
    return false;
  }
};

// check for offline api
export const isIncludeParamsApis = (url: string) => {
  if (includeParamsApis.includes(url)) {
    return true;
  } else {
    return false;
  }
};

export const checkIfOfflineIsAddedByReferral = async (referralId: string) => {
  if (referralId.split('=')[1] === 'null') {
    const programIds = (await getResponseDB('programIds')) as string[];
    const selectedProgram: string | null =
      localStorage.getItem('selectedProgram');
    let programId: string;
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;
      if (programIds && programIds.includes(programId)) {
        return true;
      }
    }
    return false;
  }
  const referralIds = (await getResponseDB('referralIds')) as string[];
  if (referralIds) {
    if (referralIds.includes(referralId.split('=')[1])) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const checkIfOfflineIsAddedByReferralId = async (referralId: string) => {
  if (referralId.split('=')[1] === 'null') {
    const programIds = (await getResponseDB('programIds')) as string[];
    const selectedProgram: string | null =
      localStorage.getItem('selectedProgram');
    let programId: string;
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;
      if (programIds && programIds.includes(programId)) {
        return true;
      }
    }
    return false;
  }
  const referralIds = (await getResponseDB('referralIds')) as string[];
  if (referralIds) {
    if (referralIds.includes(referralId)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const saveResponse = async (key: string, value: unknown) => {
  if (key.includes('referralId=')) {
    const referralId = localStorage.getItem('referralId');
    let programId: string | null;
    const selectedProgram: string | null =
      localStorage.getItem('selectedProgram');
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;
    } else {
      programId = null;
    }

    let referralIdToDelete = (await getResponseDB(
      'referralIdToDelete?programId=' + programId + '&referralId=' + referralId,
    )) as string[];
    if (referralIdToDelete === null || referralIdToDelete === undefined) {
      referralIdToDelete = [] as string[];
    }
    if (!referralIdToDelete.includes(key)) {
      referralIdToDelete.push(key);
      await saveResponseDB(
        'referralIdToDelete?programId=' +
          programId +
          '&referralId=' +
          referralId,
        referralIdToDelete,
      );
    }
  }
  await saveResponseDB(key, value);
};

export const saveOfflineDataClient = async (
  response: OfflineData,
  referralId: string,
) => {
  if (response) {
    const selectedProgram: string | null =
      getProgramBasedOnReferralId(referralId);

    let programId: string | null;
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;
    } else {
      programId = null;
    }
    const referralToAdd = response.faceSheet.demographics.referralId;
    let referralIdAdded = (await getResponseDB('referralIds')) as string[];
    if (referralIdAdded) {
      // if (referralIdAdded.length <= 3) {
      if (!referralIdAdded.includes(referralToAdd)) {
        referralIdAdded.push(referralToAdd);
        await saveResponse('referralIds', referralIdAdded);
      }
      // }
    } else {
      referralIdAdded = [];
      referralIdAdded.push(referralToAdd);
      await saveResponse('referralIds', referralIdAdded);
    }

    const clientAdded = (await getResponseDB(
      clientsList + '?programId=' + programId,
    )) as DashboardDetailsResponse;
    const client = response.webClientResponse;

    if (clientAdded) {
      clientAdded.clients.push(client);
      clientAdded.totalClientsCount = clientAdded.totalClientsCount + 1;
      await saveResponse(clientsList + '?programId=' + programId, clientAdded);
    } else {
      const clientNew = {} as DashboardDetailsResponse;
      clientNew.totalClientsCount = 1;
      clientNew.clients = [client];
      await saveResponse(clientsList + '?programId=' + programId, clientNew);
    }
    // for (const [commonKey, commonValue] of Object.entries(value))
    for (const [key, value] of Object.entries(response)) {
      let url = '';
      if (key === 'faceSheet') {
        url =
          'client/faceSheet?programId=' +
          programId +
          '&referralId=' +
          referralId;
      } else if (key === 'consentList') {
        url =
          'consent/list?programId=' + programId + '&referralId=' + referralId;
      } else if (key === 'defaultClientConsent') {
        url =
          'defaultClientConsent?programId=' +
          programId +
          '&referralId=' +
          referralId;
      } else if (key === 'defaultEmployeeConsent') {
        url =
          'defaultEmployeeConsent?programId=' +
          programId +
          '&referralId=' +
          referralId;
      } else if (key === 'ifspDetails') {
        url = 'ifsp?programId=' + programId + '&referralId=' + referralId;
      } else if (key === 'ifspEmployeeList') {
        url =
          'employee/list?programId=' + programId + '&referralId=' + referralId;
      } else if (key === 'ifspUsersList') {
        url = 'users/list?programId=' + programId + '&referralId=' + referralId;
      } else if (key === 'communicationLogResponse') {
        url =
          'clients/communicationLogs?programId=' +
          programId +
          '&referralId=' +
          referralId;
      } else if (key === 'contactList') {
        url =
          'clients/contacts?programId=' +
          programId +
          '&referralId=' +
          referralId;
      } else if (key === 'intake') {
        for (const [commonKey, commonValue] of Object.entries(value)) {
          if (commonKey === 'intakeCurrentHealth') {
            url =
              'intake/currentHealth?programId=' +
              programId +
              '&referralId=' +
              referralId;
          } else if (commonKey === 'intakeClientInfo') {
            url =
              'intake/clientInfo?programId=' +
              programId +
              '&referralId=' +
              referralId;
          } else if (commonKey === 'intakeFamily') {
            url =
              'intake/family?programId=' +
              programId +
              '&referralId=' +
              referralId;
          } else if (commonKey === 'intakePregnancy') {
            url =
              'intake/pregnancy?programId=' +
              programId +
              '&referralId=' +
              referralId;
          }
          await saveResponse(url, commonValue);
          url = '';
        }
      } else if (key === 'consents') {
        for (const [index] of Object.entries(value)) {
          const consent: ConsentDetailsType = value[index];
          url =
            'consent?' +
            'type=' +
            consent.type +
            '&consentId=' +
            consent.consentId +
            '&version=' +
            consent.version +
            '&programId=' +
            programId +
            '&referralId=' +
            referralId;
          await saveResponse(url, value[index]);
          url = '';
        }
      } else if (key === 'clientReferrals') {
        url =
          'client/referral_info?programId=' +
          programId +
          '&referralId=' +
          referralId;
      } else if (key === 'intakeSiblings') {
        url =
          'client/siblings?programId=' +
          programId +
          '&referralId=' +
          referralId;
      } else if (key === 'clientLookup') {
        url = 'lookup?programId=' + programId;
      } else if (key === 'categoryCodes') {
        url = 'code?programId=' + programId;
      } else if (key === 'intakeEmployeeNameList') {
        url =
          'employee/listByRole?employeeRole=backend.role.intake_coordinator&programId=' +
          programId;
      } else if (key === 'serviceEmployeeNameList') {
        url =
          'employee/listByRole?employeeRole=backend.role.service_coordinator&programId=' +
          programId;
      }
      if (url !== '') {
        await saveResponse(url, value);
      }
    }
  }
};

export const checkOfflineReferral = (referralId: string) => {
  const clientAdded = getResponseDB(
    clientsList,
  ) as unknown as DashboardDetailsResponse;
  if (clientAdded) {
    const clientFound = clientAdded.clients.find(
      (client) => client.referralId === referralId,
    );
    if (clientFound) {
      return true;
    }
  }
  return false;
};

export const checkIfOfflineIsAdded = () => {
  const clientAdded = getResponseDB(
    clientsList,
  ) as unknown as DashboardDetailsResponse;
  if (clientAdded) {
    return true;
  }
  return false;
};

export const getResponse = async (url: string) => {
  return getResponseDB(url);
};

export const saveResponsePayload = async (
  method: string,
  baseUrl: string,
  url: string,
  payload: unknown,
) => {
  const urlType = url.split('?')[0];
  const paramsUrl = url.split('?')[1];
  const params = paramsUrl.split('&');
  let urlKey;
  if (isExcludeCommonParamsApis(urlType)) {
    urlKey = urlType + '&' + params[params.length - 2];
  } else if (isIncludeParamsApis(urlType)) {
    urlKey = urlType + '?' + paramsUrl;
  } else {
    urlKey =
      urlType +
      '?' +
      params[params.length - 2] +
      '&' +
      params[params.length - 1];
  }
  if (urlType === 'employee/listByRole') {
    urlKey = getCustomParamsApis(url) + '&' + params[params.length - 2];
  }
  console.log(
    'method ',
    method,
    urlType,
    params,
    payload,
    url,
    offlinePostApis.includes(urlType),
    await checkIfOfflineIsAddedByReferral(params[params.length - 1]),
    await isExistInDB(urlKey),
    urlKey,
  );
  if (
    (await isExistInDB(urlKey)) ||
    (offlinePostApis.includes(urlType) &&
      (await checkIfOfflineIsAddedByReferral(params[params.length - 1])))
  ) {
    if (method === 'post') {
      if (urlType === 'clients/communicationLogs') {
        // need to update facesheet also
        const facesheeturl =
          facesheet +
          params[params.length - 2] +
          '&' +
          params[params.length - 1];

        const facesheetResponse = (await getResponse(
          facesheeturl,
        )) as FaceSheetType;
        if (
          facesheetResponse &&
          facesheetResponse.communicationLogs &&
          facesheetResponse.communicationLogs.length < 2
        ) {
          const payloadcomm = payload as CommunicationLog;
          payloadcomm.createdOn = new Date().toISOString();
          facesheetResponse.communicationLogs.push(payloadcomm);
          await saveResponse(facesheeturl, facesheetResponse);
        }

        // update the communication logs
        const savedResponse = (await getResponse(
          urlKey,
        )) as CommunicationLogResponse;
        if (savedResponse) {
          const payloadcomm = payload as CommunicationLog;
          payloadcomm.createdOn = new Date().toISOString();
          savedResponse.communicationLogs.push(payloadcomm);
          savedResponse.totalCount = 10;
          payload = savedResponse;
        }
      }
    } else if (method === 'put') {
      if (urlType === 'consent') {
        // update the consent list if adding the consent
        const consentListUrl =
          'consent/list?' +
          params[params.length - 2] +
          '&' +
          params[params.length - 1];
        const savedResponse = (await getResponse(
          consentListUrl,
        )) as ConsentList;

        if (savedResponse) {
          const payloadConsent = payload as ConsentDetailsType;

          // check for id in the list and push if id already there then update
          const itemfound = savedResponse.consents.find(
            (consent) =>
              consent.consentType === payloadConsent.type &&
              consent.version === payloadConsent.version,
          );
          if (!itemfound) {
            const date = new Date();
            let consentobj = {
              consentId: payloadConsent.consentId,
              consentType: consentType.filter(
                (consent) => consent.id === payloadConsent.type,
              )[0].id,
              consentName: payloadConsent.name,
              consentDate: date.toString(),
              expirationDate: new Date(
                date.setFullYear(date.getFullYear() + 1),
              ).toString(),
              status: payloadConsent.status,
              permissionGrantedBy: payloadConsent.client
                ? payloadConsent.client.name
                : 'N/A',
              permissionGrantedTo:
                payloadConsent.contact?.length > 0
                  ? payloadConsent.contact[0].name
                  : 'N/A',
              version: payloadConsent.version,
            } as ConsentListData;
            consentobj = handleCustomValuesForConsent(consentobj);

            savedResponse.consents.push(consentobj);
          } else {
            // don't do anything
          }
          savedResponse.totalConsentsCount = savedResponse.consents.length;

          await saveResponse(consentListUrl, savedResponse);
        }
      } else if (urlType === 'progress-notes') {
        //"employee/dashboard?timezone=%2B05%3A30&programId=P001&referralId=null"
        const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
        const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const dashboardUrl =
          'employee/dashboard?' +
          'timezone=' +
          timezone +
          '&zoneName=' +
          zoneName +
          '&' +
          params[params.length - 2] +
          '&referralId=null';
        const savedResponse = (await getResponse(
          dashboardUrl,
        )) as EmployeeDashboardResponseOffline;
        if (savedResponse) {
          const payloadConsent = payload as ProgressNoteType;

          savedResponse.progressNotes.forEach((note) => {
            if (
              note.commonAppointmentId ===
              params
                .find(
                  (param) =>
                    param === 'commonAppointmentId=' + note.commonAppointmentId,
                )
                ?.split('=')[1]
            ) {
              note.status = payloadConsent.clients[0].status as StatusType;
            }
            return note;
          });
          await saveResponse(dashboardUrl, savedResponse);
        }
        if (!(await isExistInDB(urlKey))) {
          return false;
        }
      }
    }

    await saveResponse(urlKey, payload);
    return true;
  } else {
    return false;
  }
};

const handleCustomValuesForConsent = (consent: ConsentListData) => {
  if (
    consent.consentType === 'NoticeOfPrivacyPractices' ||
    consent.consentType === 'LegalReleaseofRecords' ||
    consent.consentType === 'PriorWrittenNoticeForm' ||
    consent.consentType === 'ConsentForChangeServiceDeliveryPlan'
  ) {
    consent.expirationDate = 'N/A';
    consent.status = 'N/A';
    consent.permissionGrantedBy = 'N/A';
    consent.permissionGrantedTo = 'N/A';
  }
  return consent;
};

export const createclientsyncobj = async (referralId: string) => {
  let url;
  const selectedProgram: string | null =
    getProgramBasedOnReferralId(referralId);
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }
  const syncobj = {} as OfflineData;

  //ifsp
  url = 'ifsp?programId=' + programId + '&referralId=' + referralId;
  syncobj.ifspDetails = (await getResponse(url)) as IfspContextDataType;

  // communication log
  url =
    'clients/communicationLogs?programId=' +
    programId +
    '&referralId=' +
    referralId;
  const communicationLogResponse = (await getResponse(
    url,
  )) as CommunicationLogResponse;
  if (communicationLogResponse && communicationLogResponse.communicationLogs) {
    // Filter out communication logs with communicationLogId
    communicationLogResponse.communicationLogs =
      communicationLogResponse.communicationLogs.filter(
        (comm) => !comm.communicationLogId,
      );
    communicationLogResponse.totalCount =
      communicationLogResponse.communicationLogs.length;
  }

  syncobj.communicationLogResponse = communicationLogResponse;

  const consentListUrl =
    'consent/list?' +
    'programId=' +
    programId +
    '&' +
    'referralId=' +
    referralId;
  const consentResponse = (await getResponse(consentListUrl)) as ConsentList;
  if (consentResponse) {
    syncobj.consents = [];
    consentResponse.consents.forEach(async (consent) => {
      url =
        'consent?' +
        'type=' +
        consent.consentType +
        '&consentId=' +
        consent.consentId +
        '&version=' +
        consent.version +
        '&programId=' +
        programId +
        '&referralId=' +
        referralId;

      syncobj.consents.push((await getResponse(url)) as ConsentListData);
    });
  }

  // intake
  syncobj.intake = {} as IntakeType;
  url =
    'intake/clientInfo?programId=' + programId + '&referralId=' + referralId;
  syncobj.intake.intakeClientInfo = (await getResponse(
    url,
  )) as IntakeClientInfoResponseType;

  url = 'intake/pregnancy?programId=' + programId + '&referralId=' + referralId;
  syncobj.intake.intakePregnancy = (await getResponse(
    url,
  )) as IntakePregnancyInfoResponseType;

  url =
    'intake/currentHealth?programId=' + programId + '&referralId=' + referralId;
  syncobj.intake.intakeCurrentHealth = (await getResponse(
    url,
  )) as IntakeCurrentHealthInfoResponseType;

  url = 'intake/family?programId=' + programId + '&referralId=' + referralId;
  syncobj.intake.intakeFamily = (await getResponse(
    url,
  )) as IntakeFamilyHistoryInfoResponseType;

  const referralIds = (await getResponseDB('referralIds')) as string[];
  if (referralIds) {
    if (referralIds.length === 1) {
      syncobj.isLastSync = true;
    } else {
      syncobj.isLastSync = false;
    }
  } else {
    syncobj.isLastSync = true;
  }
  return syncobj;
};

export const checkOfflineAvailableForReferral = async (referralId: string) => {
  const referralIdAvailable = (await getResponseDB('referralIds')) as string[];
  if (referralIdAvailable) {
    if (referralIdAvailable.includes(referralId)) {
      return true;
    }
  }
  return false;
};

// delete data based on programId and referralId
export const deleteData = async (programid: string, referralId: string) => {
  const toDeleteurl =
    'referralIdToDelete?programId=' + programid + '&referralId=' + referralId;
  const referralIdToDelete = (await getResponseDB(toDeleteurl)) as string[];
  if (referralIdToDelete) {
    for (const [index] of Object.entries(referralIdToDelete) as unknown as [
      number,
      string,
    ][]) {
      await deleteResponse(referralIdToDelete[index]);
    }
    await deleteResponse(toDeleteurl);
  }

  const referralIdRemove = (await getResponseDB('referralIds')) as string[];
  if (referralIdRemove) {
    if (referralIdRemove.length > 0) {
      if (referralIdRemove.includes(referralId)) {
        referralIdRemove.splice(referralIdRemove.indexOf(referralId), 1);

        // remove from client list
        const clientsListurl = 'clients?programId=' + programid;
        const clientList = (await getResponseDB(
          clientsListurl,
        )) as DashboardDetailsResponse;
        if (clientList) {
          for (let index = clientList.clients.length - 1; index >= 0; index--) {
            const client = clientList.clients[index];
            if (client.referralId === referralId) {
              clientList.clients.splice(index, 1);
              clientList.totalClientsCount = clientList.totalClientsCount - 1;
            }
          }
          await saveResponse(clientsListurl, clientList);
        }
        if (referralIdRemove.length === 0) {
          deleteAll();
        } else {
          await saveResponse('referralIds', referralIdRemove);
        }
      }
    } else {
      deleteAll();
    }
  }
};

export const saveProgressNotes = async (progressNotes: ProgressNoteType) => {
  /*progress-notes
          ?attendeeId=cec42281-2859-4f31-8614-daaf8cf280f8
          &commonAppointmentId=9b367820-ee9a-4f0b-982a-c2a0be647ec0
          &programId=P001
          &referralId=null
          */

  const userId: string | null = localStorage.getItem('userId');
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }

  const progressNotesurl =
    'progress-notes' +
    '?attendeeId=' +
    userId +
    '&commonAppointmentId=' +
    progressNotes.clients[0].commonAppointmentId +
    '&programId=' +
    programId +
    '&referralId=null';

  await saveResponse(progressNotesurl, progressNotes);
};

export const saveDashboardData = async (
  dashboardJson: EmployeeDashboardResponseOffline,
) => {
  //const dashboard = 'employee/dashboard?timezone=%2B05%3A30&programId=P001&referralId=null';
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }

  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dashboardUrl =
    'employee/dashboard' +
    '?timezone=' +
    timezone +
    '&zoneName=' +
    zoneName +
    '&programId=' +
    programId +
    '&referralId=null';

  await saveResponse(dashboardUrl, dashboardJson);
};

export const saveCodesData = async (codesJson: CodeListType) => {
  //const codesUrl = 'codes?programId=P006&referralId=null';
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }

  const codesUrl = 'codes?' + 'programId=' + programId + '&referralId=null';

  await saveResponse(codesUrl, codesJson);
};

export const saveCodeData = async (data: unknown) => {
  //const codesUrl = 'code?programId=P006';
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }

  const url = 'code?' + 'programId=' + programId;

  await saveResponse(url, data);
};

export const saveClientLookupData = async (data: unknown) => {
  //const codesUrl = 'lookup?programId=P006';
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }

  const url = 'lookup?' + 'programId=' + programId;

  await saveResponse(url, data);
};

export const saveServiceEmployeeNameListData = async (data: unknown) => {
  //const codesUrl = 'employee/listByRole?employeeRole=backend.role.service_coordinator&programId=P006';
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }

  const url =
    'employee/listByRole?employeeRole=backend.role.service_coordinator&' +
    'programId=' +
    programId;

  await saveResponse(url, data);
};

export const saveIntakeEmployeeNameListData = async (data: unknown) => {
  //const codesUrl = 'employee/listByRole?employeeRole=backend.role.intake_coordinator&programId=P006';
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string | null;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;
  } else {
    programId = null;
  }

  const url =
    'employee/listByRole?employeeRole=backend.role.intake_coordinator&' +
    'programId=' +
    programId;

  await saveResponse(url, data);
};

// export const saveuserList = async (data: unknown) => {
//   const selectedProgram: string | null =
//     localStorage.getItem('selectedProgram');
//   let programId: string | null;
//   if (selectedProgram !== null) {
//     programId = JSON.parse(selectedProgram).id;
//   } else {
//     programId = null;
//   }

//   const url =
//     'employee/list?programId=' +
//     'programId=' +
//     programId +
//     '&referralId=' +
//     null;
//   await saveResponse(url, data);
// };

export const saveProgramId = async () => {
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;

    let programIdAdded = (await getResponseDB('programIds')) as string[];
    if (programIdAdded) {
      if (!programIdAdded.includes(programId)) {
        programIdAdded.push(programId);
        await saveResponse('programIds', programIdAdded);
      }
    } else {
      programIdAdded = [];
      programIdAdded.push(programId);
      await saveResponse('programIds', programIdAdded);
    }
  }
};

export const saveProgramReferralMap = async (referralId: string) => {
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;

    let programRefferralAdded = (await getResponseDB(
      'programRefferralMap',
    )) as ProgramReferral[];

    let programReferralMap: ProgramReferral;

    if (programRefferralAdded === null || programRefferralAdded === undefined) {
      programRefferralAdded = [];
    }

    const programRefferralExists = programRefferralAdded.find(
      (item) => item.programId === programId,
    );

    if (programRefferralExists) {
      const idx = programRefferralAdded.indexOf(programRefferralExists);
      programRefferralAdded.splice(idx, 1);
      programReferralMap = {
        programId,
        referralId: [...programRefferralExists.referralId, referralId],
      };
    } else {
      programReferralMap = {
        programId,
        referralId: [referralId],
      };
    }

    programRefferralAdded.push(programReferralMap);

    await saveResponse('programRefferralMap', programRefferralAdded);
  }
};

export const deleteofflinedata = async (programId: string) => {
  const keys = await getAllkeys();
  for (const key of keys) {
    if (key.includes(programId)) {
      await deleteResponse(key);
    }
  }

  const programIdToDelete = (await getResponseDB('programIds')) as string[];
  if (programIdToDelete) {
    if (programIdToDelete.includes(programId)) {
      programIdToDelete.splice(programIdToDelete.indexOf(programId), 1);
      await saveResponse('programIds', programIdToDelete);

      const programRefferralMap = (await getResponseDB(
        'programRefferralMap',
      )) as ProgramReferral[];

      if (programRefferralMap) {
        const idx = programRefferralMap.findIndex(
          (item) => item.programId === programId,
        );

        if (idx !== -1) {
          const referralIds = (await getResponseDB('referralIds')) as string[];
          if (referralIds) {
            programRefferralMap[idx].referralId.forEach((referralId) => {
              if (referralIds.includes(referralId)) {
                referralIds.splice(referralIds.indexOf(referralId), 1);
                saveResponse('referralIds', referralIds);
              }
            });
          }
          programRefferralMap.splice(idx, 1);
          await saveResponse('programRefferralMap', programRefferralMap);
        }
      }

      if (programIdToDelete.length === 0) {
        await deleteResponse('programIds');
        await deleteResponse('programRefferralMap');
        await deleteResponse('referralIds');
        nullifyToken();
      }
    }
  }
};

const nullifyToken = () => {
  localStorage.setItem('accessToken', 'EXPIRE SESSION');
  localStorage.setItem('refreshToken', 'EXPIRE SESSION');
  localStorage.setItem('offlineToken', 'EXPIRE SESSION');
};

export const createProgramsyncobj = async () => {
  const programobj: SyncOfflineData = {
    isLastSync: false,
    clients: [],
    progressNotesDetails: [],
  };
  const selectedProgram: string | null =
    localStorage.getItem('selectedProgram');
  let programId: string;
  if (selectedProgram !== null) {
    programId = JSON.parse(selectedProgram).id;

    let programRefferralAdded = (await getResponseDB(
      'programRefferralMap',
    )) as {
      programId: string;
      referralId: string[];
    }[];

    if (programRefferralAdded === null || programRefferralAdded === undefined) {
      programRefferralAdded = [];
    }

    const programRefferral = programRefferralAdded.find(
      (item) => item.programId === programId,
    );

    if (programRefferral) {
      await Promise.all(
        programRefferral.referralId.map(async (referralId) => {
          const clientobj = await createclientsyncobj(referralId);
          clientobj.referralId = referralId;
          programobj.clients.push(clientobj);
        }),
      );
    }

    programobj.progressNotesDetails = [] as ProgressNoteType[];
    const keys = await getAllkeys();
    for (const key of keys) {
      if (key.includes(programId) && key.includes('progress-notes?')) {
        const progressNote = (await getResponseDB(key)) as ProgressNoteType;
        programobj.progressNotesDetails.push(progressNote);
      }
    }

    const programIds = (await getResponseDB('programIds')) as string[];
    if (programIds) {
      if (programIds.length === 1) {
        programobj.isLastSync = true;
      } else {
        programobj.isLastSync = false;
      }
    } else {
      programobj.isLastSync = true;
    }
    return programobj;
  }
};

export function getProgramBasedOnReferralId(referralId: string | null) {
  let selectedProgram = localStorage.getItem('selectedProgram');
  const clientReferralDetailsSelected = JSON.parse(
    localStorage.getItem('clientReferralDetails') as unknown as string,
  ) as ReferralResponseType[];

  if (
    clientReferralDetailsSelected &&
    clientReferralDetailsSelected.length > 0
  ) {
    clientReferralDetailsSelected.forEach((referral: ReferralResponseType) => {
      if (referral.referralId === referralId) {
        const programs = JSON.parse(
          localStorage.getItem('programs') as unknown as string,
        ) as ProgramType[];

        if (programs && programs.length > 0) {
          programs.forEach((program) => {
            if (program && program.id === referral.programId) {
              selectedProgram = JSON.stringify(program);
            }
          });
        }
      }
    });
  }
  return selectedProgram;
}

export const checkOffline = async () => {
  // check if application is not running in localhost and disable all
  if (
    window.location.hostname !== 'localhost' &&
    window.location.hostname !== '127.0.0.1'
  ) {
    return false;
  }
  return true;
};
