// db.ts
const DB_NAME = 'apiResponsesDB';
const STORE_NAME = 'responses';
const DB_VERSION = 1;

let db: IDBDatabase;

// Open the IndexedDB
export const initDB = (): Promise<IDBDatabase> => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(DB_NAME, DB_VERSION);

    request.onerror = () => {
      reject(`Database error: ${request.error}`);
    };

    request.onsuccess = () => {
      db = request.result;
      resolve(db);
    };

    request.onupgradeneeded = (event) => {
      const dbTemp = (event.target as IDBOpenDBRequest).result;
      dbTemp.createObjectStore(STORE_NAME, { keyPath: 'url' });
    };
  });
};

// Save the response to IndexedDB
export const saveResponseDB = (url: string, data: unknown): Promise<void> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.put({ url, data });

    request.onsuccess = () => resolve();
    request.onerror = () => reject(`Couldn't store the response for ${url}`);
  });
};

// Retrieve the response from IndexedDB
export const getResponseDB = (url: string): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(url);

    request.onsuccess = () => resolve(request.result?.data);
    request.onerror = () => reject(`Couldn't retrieve the response for ${url}`);
  });
};

// Delete the response from IndexedDB
export const deleteResponse = (url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.delete(url);

    request.onsuccess = () => resolve();
    request.onerror = () => reject(`Couldn't delete the response for ${url}`);
  });
};

// deleteAll
export const deleteAll = (): Promise<void> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readwrite');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.clear();

    request.onsuccess = () => resolve();
    request.onerror = () => reject(`Couldn't clear the DB`);
  });
};

// check if url exists in DB
export const isExistInDB = (url: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.get(url);

    request.onsuccess = () => resolve(request.result !== undefined);
    request.onerror = () => reject(`Couldn't retrieve the response for ${url}`);
  });
};

// get all urls
export const getAllkeys = (): Promise<string[]> => {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction([STORE_NAME], 'readonly');
    const store = transaction.objectStore(STORE_NAME);
    const request = store.getAllKeys();

    request.onsuccess = () => {
      const keys: string[] = request.result.map((key: IDBValidKey) =>
        key.toString(),
      );
      resolve(keys);
    };
    request.onerror = () => reject(`Couldn't retrieve the response`);
  });
};
