import { forEach } from 'lodash';
import {
    GroupPermissions,
    ScreenPermission,
  } from '../services/configApi/userGroups/userGroupServices'; 
  type ScreensField = keyof ScreenPermission;
  
  function getField(
    obj: ScreenPermission,
    fieldName: ScreensField,
  ): ScreenPermission[ScreensField] {
    return obj[fieldName];
  }
  export const checkPermissionForFeature = (
    screen: string,
    permissionType: string,
  ) => {
    const selectedProgram = localStorage.getItem('selectedProgram');
    let permissions: GroupPermissions = JSON.parse('[]');
    if (selectedProgram && selectedProgram !== null) {
      const selectedPermission = localStorage.getItem(
        JSON.parse(selectedProgram).id,
      )
      permissions = JSON.parse(selectedPermission?selectedPermission:'[]');
    }
    if (permissions.superSuperAdmin || screen === 'dashboardText' || screen === 'setupText' || screen === 'settingText' || screen === 'myActivityText') {
      return true;
    }
    return checkPermissionHere(screen, permissionType, permissions);
  };

  const checkPermissionHere = (screen: string, permissionType: string, permissions: GroupPermissions) => {
    let returnValue = false;
    forEach(permissions.screens, (permissionScreen: ScreenPermission) => {
      const fieldName = getField(
        permissionScreen,
        permissionType as ScreensField,
      );
      if (permissionScreen.screenBackendId === screen) {
        if (fieldName) {
          returnValue = true;
        } else {
          returnValue =  false;
        }
      }
    });
    return returnValue;
  }