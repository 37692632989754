// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `tbody.Ifsptable >tr>td {
    height: 49px;
    padding: 6px 0px 0px 16px;
    color: var(--thom-table-content-black);
}`, "",{"version":3,"sources":["webpack://./src/pages/ifsp/IFSP.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,sCAAsC;AAC1C","sourcesContent":["tbody.Ifsptable >tr>td {\n    height: 49px;\n    padding: 6px 0px 0px 16px;\n    color: var(--thom-table-content-black);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
