import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  Pagination,
  Grid,
  Select,
  SelectChangeEvent,
  MenuItem,
  AlertColor,
  Card,
  Breadcrumbs,
  Link,
} from '@mui/material';

import { styled } from '@mui/system';
import { useLocation } from 'react-router';
import TitleText from '../../components/formlib/TitleText';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import {
  EmployeeLookup,
  getAllPrograms,
} from '../../services/programManagment/programManagment';
import { EmployeeLookUpType, EmployeeProgram, Order } from '../../utils/type';
import './ProgramManagmentTable.css';
import SearchBox from '../../components/formlib/SearchBox';
import backarrow from '../../assets/images/backarrow.svg';
import { useNavigate } from 'react-router-dom';
import filtericon from '../../assets/images/filtericon.svg';
import arrowIcon from '../../assets/images/ArrowRight.svg';
import { Program } from '../employees/types';
import {
  getEmployeeLookup,
  getAllEmployee,
} from '../../services/configApi/employees/employeeServices';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { PROGRAM_MANAGEMENT_ROUTE_NAME } from '../../routes/Routing';
import { getRoleName } from '../../utils/nameUtils';
import { TableHeader } from '../../components/formlib/TableHeader';
import { HeadCell } from './../../components/formlib/TableHeader';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';

interface IconWithTextProps {
  postIcon?: string;
}
const IconWithText = (props: IconWithTextProps) => {
  const { postIcon } = props;
  return (
    <Box>
      {postIcon && (
        <img
          src={postIcon}
          alt="children.postIcon"
          style={{
            position: 'relative',
            right: '35%',
            marginTop: '8px',
          }}
        />
      )}
    </Box>
  );
};

const BackArrowImage = styled('img')`
  cursor: pointer;
  border: 3px;
  padding-right: 10px;
  margin-top: -1.5%;
`;

const headCells: HeadCell<Data>[] = [
  {
    id: 'first_name',
    labelId: 'programManagment.tableName',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'program',
    labelId: 'programManagment.tableProgram',
    defaultLabelId: 'Program',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'role',
    labelId: 'programManagment.tableRole',
    defaultLabelId: 'Role',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'programManagment.tablePhoneNumber',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'programManagment.tableEmail',
    defaultLabelId: 'Email ID',
    numeric: false,
    requiredSorting: false,
  },
];
export type Data = {
  first_name: string;
  name: string;
  program: string;
  role: string;
  phoneNumber: string;
  email: string;
  emergencyContact: string;
  emergencyPhone: string;
};
const PAGE_SIZE = 10;

const EmployeeLookUpTable = () => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('first_name');
  const [logs, setLogs] = useState<EmployeeLookUpType[]>([]);
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrId, setToastrId] = React.useState('');
  const [searchquery, setSearchquery] = useState('');
  const [onsearchquery, setOnsearchquery] = useState('');
  const [searchTriggered, setSearchTriggered] = useState(false);
  const defaultOption = 'viewall';
  const [selectedValue, setSelectedValue] = React.useState(defaultOption);
  const [programList, setProgramList] = React.useState<Program[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const navParams = location.state;
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [loader, setLoader] = useState(false);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    toggleLoader(true);
    setLoader(true);
    let id: string = '';
    setLogs([]);

    if (navParams.name !== '') {
      id = navParams.id;
      setSelectedValue(navParams.name);
    } else {
      const selectedIndex = programList.findIndex(
        (item) => item.programName === selectedValue,
      );
      id = programList[selectedIndex]?.programId;
    }

    if (navParams?.name !== '' || selectedValue !== 'viewall') {
      getEmployeeLookup(
        pageNumber - 1,
        PAGE_SIZE,
        'first_name',
        order,
        onsearchquery,
        id,
      )
        .then((response: EmployeeLookup) => {
          if (response.employees) {
            setLogs(response.employees);
            setPaginationCount(response.employeeCount);
          }
          toggleLoader(false);
          setLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('loadFailureMessage');
            setToastrDefaultMessage('Failed to get details');
          }
          toggleLoader(false);
          setLoader(false);
        });
    } else {
      setOrderBy('name');
      getAllEmployee(pageNumber - 1, PAGE_SIZE, orderBy, order, onsearchquery)
        .then((response: EmployeeLookup) => {
          if (response.employees) {
            setLogs(response.employees);
            setPaginationCount(response.employeeCount);
          }
          toggleLoader(false);
          setLoader(false);
        })
        .catch((error) => {
          setOpen(true);
          setToastrVariable('error');
          toggleLoader(false);
          setLoader(false);

          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('loadFailureMessage');
            setToastrDefaultMessage('Failed to get details');
          }
        });
    }
  }, [order, orderBy, pageNumber, onsearchquery, selectedValue]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z0-9,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPageNumber(1);
        setSearchquery(value);
        setSearchTriggered(true);
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
        setToastrDefaultMessage(
          'Only alphanumeric characters, commas, periods, spaces, exclamation marks, and hyphens are allowed to be searched.',
        );
      }
    }, 500),
    [],
  );

  useEffect(() => {
    if (searchTriggered) {
      setOnsearchquery(searchquery);
      setSearchTriggered(false);
    }
    if (!searchquery) {
      setOnsearchquery(searchquery);
    }
  }, [searchquery, searchTriggered]);

  const getPrograms = (programs: EmployeeProgram[] | null): string => {
    if (programs === null || programs.length == 0) {
      return '-';
    }
    const pgs: string[] = [];
    programs.forEach((program) => {
      const programArray = program.acronym;
      if (programArray && programArray?.length >= 1) {
        pgs?.push(programArray);
      }
    });

    if (pgs.length >= 1) {
      return pgs.join(',');
    } else {
      return '';
    }
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setLogs([]);
    navParams.id = '';
    navParams.name = '';
    setPageNumber(1);
    const selectedValues = event.target.value as string;
    setSelectedValue(selectedValues);
  };

  useEffect(() => {
    getAllPrograms()
      .then((response: Program[]) => {
        if (response) {
          setProgramList(response);
        }
      })
      .catch((error) => {
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  }, [selectedValue]);

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box component="main">
        <Box className="rowContainer">
          <BackArrowImage
            src={backarrow}
            alt="backarrow"
            onClick={() => navigate(PROGRAM_MANAGEMENT_ROUTE_NAME)}
          />
          <Box sx={{ flexGrow: 1 }}>
            <TitleText
              labelid="Program.employeelookup"
              defaultlabel="Employee Lookup"
            />

            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<img src={arrowIcon} style={{ marginTop: '5px' }} />}
            >
              <Link
                underline="hover"
                color="inherit"
                sx={{
                  color: 'rgba(151, 166, 165, 1)',
                  fontFanily: 'Lato-Regular',
                  fontSize: '12px',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(PROGRAM_MANAGEMENT_ROUTE_NAME)}
              >
                Program Management
              </Link>
              <Link
                underline="hover"
                color="text.primary"
                sx={{
                  color: 'rgba(42, 66, 65, 1)',
                  fontFanily: 'Lato-Regular',
                  fontSize: '12px',
                }}
                href=""
                aria-current="page"
              >
                Employee Lookup
              </Link>
            </Breadcrumbs>
          </Box>

          <Box sx={{ flexGrow: 0, mx: '0px' }}>
            <Select
              value={selectedValue}
              onChange={handleSelectChange}
              displayEmpty
              startAdornment={<IconWithText postIcon={filtericon} />}
              className="position__relative "
              sx={{
                fontWeight: 600,
                fontSize: '14px',
                width: 'auto',
                height: '32px',
                background: '#ffff',
                textTransform: 'none',
                borderRadius: '4px',
                color: '#008C82',

                right: '4%',
                justifyContent: 'flex-end',
                fontFamily: 'Lato-Regular',
                '&. MuiOutlinedInput-notchedOutline': {
                  borderColor: '#00C6B8',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#00C6B8',
                },
                '&:hover': {
                  borderColor: 'none', // Remove border on hover
                },
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: '200px', // Adjust the maxHeight as needed
                    width: 'auto',
                    fontFamily: 'Lato-Regular',
                    fontSize: '12px',
                  },
                },
              }}
            >
              {programList.map((item: Program) => (
                <MenuItem
                  key={item.id}
                  value={item.programName}
                  style={{
                    whiteSpace: 'normal',
                    width: '250px',
                    fontFamily: 'Lato-Regular',
                    fontSize: '14px',
                  }}
                >
                  {item.programName}
                </MenuItem>
              ))}
              <MenuItem
                style={{
                  whiteSpace: 'normal',
                  width: '250px',
                  fontFamily: 'Lato-Regular',
                  fontSize: '14px',
                }}
                value="viewall"
              >
                View All
              </MenuItem>
            </Select>

            <SearchBox
              labelId="EmployeeLookup.search"
              defaultlabel="Search Employee"
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
        {searchquery !== '' && !loader && logs.length === 0 ? (
          <SearchNotFound />
        ) : (
          <Card>
            <TableContainer>
              <Table>
                <TableHeader
                  className="listDataTableHead"
                  headerNames={headCells}
                  checkBoxRequired={false}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody className="tableRowcss">
                  {logs.map((row, index) => {
                    return (
                      <TableRow
                        hover
                        key={row.firstName}
                        tabIndex={-1}
                        sx={{
                          cursor: 'pointer',
                          backgroundColor:
                            index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                        }}
                      >
                        <TooltipTableCell
                          value={row.firstName ? row.firstName : '-'}
                          visible={true}
                        />

                        <TooltipTableCell
                          value={getPrograms(row.programs)}
                          visible={true}
                        />

                        <TooltipTableCell
                          value={
                            row.employeeRole
                              ? getRoleName(row.employeeRole)
                              : '-'
                          }
                          visible={true}
                        />
                        <TooltipTableCell
                          value={row.phone1 ? row.phone1 : '-'}
                          visible={true}
                        />
                        <TooltipTableCell
                          value={row.email ? row.email : '-'}
                          visible={true}
                        />
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}

        <Box>
          {paginationCount > 10 && (
            <Grid
              container
              spacing={1}
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '20px',
              }}
            >
              {!loader && (
                <Pagination
                  boundaryCount={1}
                  page={pageNumber}
                  onChange={handleChangePage}
                  sx={{
                    '& .MuiPaginationItem-root': {
                      fontFamily: 'Lato-Regular',
                      fontWeight: 400,
                      fontSize: '16px',
                    },
                    '& .MuiPaginationItem-root.Mui-selected': {
                      backgroundColor: '#008C82',
                      color: '#ffffff',
                    },
                  }}
                  shape="circular"
                  count={Math.ceil(paginationCount / 10)}
                  siblingCount={1}
                />
              )}
            </Grid>
          )}
        </Box>
        {pageNumber === 0 && logs.length === 0 && (
          <Box sx={{ width: '100%' }}>
            <MediumTypography
              textstyle={'center'}
              labelid="Employeelookup.logsnofound"
              defaultlabel="No Employee's Found"
              paddingstyle="16px"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default EmployeeLookUpTable;
