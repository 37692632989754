import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import SmallTypography from '../../components/formlib/SmallTypography';
import { translateTextArea } from '../../TranslateWidget';

interface ASQQuestionnaireViewProps {
  questionnairesArray: {
    data: ASQQuestionnaireDataType[];
  };
  handleCheckboxChange: (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  handleRadioChange: (index: number, value: string) => void;
  handleInputChange: (index: number, value: string) => void;
  initialCheck?: string[];
  isCheckedArray: boolean[];
  selectedValues: string[];
  points: number[];
  textInputValue: string[];
}

interface ASQQuestionnaireDataType {
  id: string;
  type: string;
  displayQNoId: string;
  displayQueId: string;
  displayQNotext: string;
  displayQuetext: string;
  responseType: string[];
}

const ASQQuestionnaireView: React.FC<ASQQuestionnaireViewProps> = (props) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      (event.currentTarget as HTMLButtonElement).click();
    }
  };

  const handleCheckboxChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    props.handleCheckboxChange(index, event);
  };

  const handleRadioChange = (index: number, value: string) => {
    props.handleRadioChange(index, value);
  };

  const handleInputChange = (index: number, value: string) => {
    props.handleInputChange(index, value);
  };

  return (
    <>
      {/* Table body */}
      <Box>
        <Card>
          {props.questionnairesArray.data.length ===
            props.initialCheck?.length &&
            props.questionnairesArray.data.map(
              (data: ASQQuestionnaireDataType, index) => (
                <>
                  {/* Example of SimpleQuestion */}
                  {data.type === 'simpleQuestion' && (
                    <Box
                      sx={{
                        borderBottom: '1px solid var(--mono-grey-2, #BBBFCE)',
                      }}
                    >
                      <Grid container>
                        <Grid item xs={5} lg={5}>
                          <Grid sx={{ display: 'flex', padding: '12px' }}>
                            <SmallTypography
                              labelId={data.displayQNoId}
                              defaultLabelId={data.displayQNotext}
                              sxProps={{
                                fontSize: 14,
                              }}
                            />
                            <SmallTypography
                              labelId={data.displayQueId}
                              defaultLabelId={data.displayQuetext}
                              sxProps={{ fontSize: 14, marginLeft: 0 }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          lg={4}
                          bgcolor="#ECF9F8"
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          alignContent={'center'}
                          paddingX={4.5}
                        >
                          <RadioGroup
                            id={data.id}
                            value={
                              props.initialCheck && props?.initialCheck[index]
                            }
                            sx={{
                              display: 'contents',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                            }}
                          >
                            <FormControlLabel
                              id={data.id + data.responseType[0]}
                              value={data.responseType[0]}
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': { color: '#00C6B8' },
                                    height: '24px',
                                    borderRadius: '14px',
                                    color: '#97A6A5',
                                  }}
                                  onClick={() =>
                                    handleRadioChange(
                                      index,
                                      data.responseType[0],
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className="notranslate"
                                  translate="no"
                                  sx={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                  }}
                                >
                                  {data.responseType[0]}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              id={data.id + data.responseType[1]}
                              value={data.responseType[1]}
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': { color: '#00C6B8' },
                                    height: '24px',
                                    borderRadius: '14px',
                                    color: '#97A6A5',
                                  }}
                                  onClick={() =>
                                    handleRadioChange(
                                      index,
                                      data.responseType[1],
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className="notranslate"
                                  translate="no"
                                  sx={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                  }}
                                >
                                  {data.responseType[1]}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              id={data.id + data.responseType[2]}
                              value={data.responseType[2]}
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': { color: '#00C6B8' },
                                    height: '24px',
                                    borderRadius: '14px',
                                    color: '#97A6A5',
                                  }}
                                  onClick={() =>
                                    handleRadioChange(
                                      index,
                                      data.responseType[2],
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className="notranslate"
                                  translate="no"
                                  sx={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                  }}
                                >
                                  {data.responseType[2]}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          lg={1.5}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          paddingLeft={1}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`name-${index}`}
                                checked={props.isCheckedArray[index]}
                                onChange={(event) =>
                                  handleCheckboxChange(index, event)
                                }
                                onKeyDown={handleKeyDown}
                                style={{
                                  color: props.isCheckedArray[index]
                                    ? '#00938e'
                                    : '#97A6A5',
                                }}
                              />
                            }
                            label={
                              <Typography
                                className="notranslate"
                                translate="no"
                                sx={{
                                  fontFamily: 'Lato-Regular',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: '12px',
                                }}
                              >
                                V
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          lg={1.5}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Lato-Regular',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '14px',
                              lineHeight: '17px',
                            }}
                          >
                            {props.points[index] === 0 &&
                            props.selectedValues[index] !== 'Z'
                              ? ''
                              : props.points[index]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {/* Example of SimpleQuestion with Input */}
                  {data.type === 'simpleQuestionWithInput' && (
                    <Box
                      sx={{
                        borderBottom: '1px solid var(--mono-grey-2, #BBBFCE)',
                      }}
                    >
                      <Grid container>
                        <Grid item xs={5} lg={5}>
                          <Grid sx={{ display: 'flex', padding: '12px' }}>
                            <SmallTypography
                              labelId={data.displayQNoId}
                              defaultLabelId={data.displayQNotext}
                              sxProps={{
                                fontSize: 14,
                              }}
                            />
                            <SmallTypography
                              labelId={data.displayQueId}
                              defaultLabelId={data.displayQuetext}
                              sxProps={{ fontSize: 14, marginLeft: 0 }}
                            />
                          </Grid>
                          <Grid
                            sx={{
                              paddingY: '12px',
                              paddingX: '30px',
                              paddingTop: 0,
                            }}
                          >
                            <textarea
                              id={`name-${index}`}
                              className="translate"
                              name={`name-${index}`}
                              rows={4}
                              value={props.textInputValue[index]}
                              maxLength={300}
                              onChange={(event) => {
                                handleInputChange(
                                  index,
                                  event.target.value.trimStart(),
                                );
                                translateTextArea(`name-${index}`);
                              }}
                              style={{
                                width: '100%',
                                fontFamily: 'Lato-Regular',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '14px',
                                alignItems: 'center',
                                height: '50px',
                                minHeight: '50px',
                                minWidth: '100%',
                                paddingTop: '10px',
                                paddingLeft: '10px',
                                border: '1px solid #ccc',
                                outline: 'none',
                                overflow: 'hidden',
                                borderRadius: '4px',
                                transition: 'border-color 0.3s',
                                justifyContent: 'center',
                                resize: 'none',
                                overflowY: 'scroll',
                              }}
                              onFocus={(e) => {
                                e.target.style.borderColor = '#00C6B8';
                              }}
                              onBlur={(e) => {
                                e.target.style.borderColor = '#ccc';
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          lg={4}
                          bgcolor="#ECF9F8"
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          alignContent={'center'}
                          paddingX={4.5}
                        >
                          <RadioGroup
                            value={props.selectedValues[index]}
                            sx={{
                              display: 'contents',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              alignContent: 'center',
                            }}
                          >
                            <FormControlLabel
                              value={data.responseType[0]}
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': { color: '#00C6B8' },
                                    height: '24px',
                                    borderRadius: '14px',
                                    color: '#97A6A5',
                                  }}
                                  onClick={() =>
                                    handleRadioChange(
                                      index,
                                      data.responseType[0],
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className="notranslate"
                                  translate="no"
                                  sx={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                  }}
                                >
                                  {data.responseType[0]}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value={data.responseType[1]}
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': { color: '#00C6B8' },
                                    height: '24px',
                                    borderRadius: '14px',
                                    color: '#97A6A5',
                                  }}
                                  onClick={() =>
                                    handleRadioChange(
                                      index,
                                      data.responseType[1],
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className="notranslate"
                                  translate="no"
                                  sx={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                  }}
                                >
                                  {data.responseType[1]}
                                </Typography>
                              }
                            />
                            <FormControlLabel
                              value={data.responseType[2]}
                              control={
                                <Radio
                                  sx={{
                                    '&.Mui-checked': { color: '#00C6B8' },
                                    height: '24px',
                                    borderRadius: '14px',
                                    color: '#97A6A5',
                                  }}
                                  onClick={() =>
                                    handleRadioChange(
                                      index,
                                      data.responseType[2],
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  className="notranslate"
                                  translate="no"
                                  sx={{
                                    fontFamily: 'Lato-Regular',
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                  }}
                                >
                                  {data.responseType[2]}
                                </Typography>
                              }
                            />
                          </RadioGroup>
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          lg={1.5}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                          paddingLeft={1}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name={`name-${index}`}
                                checked={props.isCheckedArray[index]}
                                onChange={(event) =>
                                  handleCheckboxChange(index, event)
                                }
                                onKeyDown={handleKeyDown}
                                style={{
                                  color: props.isCheckedArray[index]
                                    ? '#00938e'
                                    : '#97A6A5',
                                }}
                              />
                            }
                            label={
                              <span
                                style={{
                                  fontFamily: 'Lato-Bold',
                                  fontStyle: 'normal',
                                  fontWeight: 600,
                                  fontSize: '12px',
                                }}
                              >
                                V
                              </span>
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1.5}
                          lg={1.5}
                          display={'flex'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Lato-Regular',
                              fontStyle: 'normal',
                              fontWeight: 600,
                              fontSize: '14px',
                              lineHeight: '17px',
                            }}
                          >
                            {props.points[index] === 0 &&
                            props.selectedValues[index] !== 'Z'
                              ? ''
                              : props.points[index]}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </>
              ),
            )}
        </Card>
      </Box>
    </>
  );
};

export default ASQQuestionnaireView;
