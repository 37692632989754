import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import { FormattedMessage } from 'react-intl';
import { SxProps } from '@mui/system';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  variantType?: 'text' | 'contained' | 'outlined';
  onClick?: () => void;
  defaultLabelId?: string;
  labelId?: string;
  label?: string;
  sxProps?: SxProps;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
}

const ButtonComponent: React.FC<ButtonProps> = (props) => {
  const {
    type,
    variantType,
    onClick,
    defaultLabelId,
    labelId,
    label,
    sxProps,
    disabled = false,
    loading,
    className,
    LeftIcon,
    RightIcon,
  } = props;

  return (
    <Button
      className={className}
      sx={{ ...sxProps }}
      type={type}
      variant={variantType}
      onClick={onClick}
      disabled={disabled || loading}
      startIcon={LeftIcon}
      endIcon={RightIcon}
    >
      {labelId ? (
        <FormattedMessage id={labelId} defaultMessage={defaultLabelId} />
      ) : (
        label
      )}
    </Button>
  );
};

export default ButtonComponent;
