import { ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export interface LookupItem {
  id: string;
  code: string;
  dphServiceCode: string;
  description: string;
  type: string;
  active: boolean;
}

export interface TCSLookUpResponse {
  serviceCode: LookupItem[];
  settingsCode: LookupItem[];
  referralReason: LookupItem[];
  referralSource: LookupItem[];
  referralDischargeReason: LookupItem[];
  race: LookupItem[];
  ethnicity: LookupItem[];
  language: LookupItem[];
  evaluationType: LookupItem[];
  leaReferralReason: LookupItem[];
  tpcReason: LookupItem[];
  transitionReason: LookupItem[];
  eicsPovertyLevel: LookupItem[];
  eiService: LookupItem[];
  locationOfService: LookupItem[];
  period: LookupItem[];
  intensity: LookupItem[];
  reason: LookupItem[];
  discipline: LookupItem[];
  seaStatus: LookupItem[];
  primarySettingCode: LookupItem[];
  location: LookupItem[];
  eicsInsuranceAddendum: LookupItem[];
  eicsPatientRelation: LookupItem[];
  dphInsuranceReasonCode: LookupItem[];
  travelTypes: LookupItem[];
  guardianType: LookupItem[];
}

export const getTCSLookUps = async (): Promise<TCSLookUpResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<TCSLookUpResponse>(`lookup`),
  );
};
