import { AlertColor, Box, Card, Grid, Toolbar } from '@mui/material';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import TitleText from '../../components/formlib/TitleText';
import ActivitySheetChart from '../activitySheet/ActivitySheetChart';
import ProductivityChart from '../activitySheet/ProductivityChart';
import {
  EmployeeDashboardResponse,
  EmployeeDashboardResponseOffline,
  getEmployeeDashboardDetails,
} from '../../services/configApi/employees/employeeServices';
import TaskManager from './TaskManager';
import UpcommingEvents from './UpcommingEvents';
import DashboardProgressNote from './DashboardProgressNote';
import CaseLoad from './CaseLoad';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { getEmployeePermissions } from '../../services/configApi/userGroups/userGroupServices';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { useNavigate } from 'react-router-dom';
import {
  CASE_LOAD_LIST,
  PROGRESS_NOTE_LIST_ROUTE_NAME,
  TASK_MANAGER,
} from '../../routes/Routing';
import EmptyScreen from '../../components/shared/EmptyScreen';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

import { ReactComponent as DownloadIcon } from '../../assets/images/offlineDownload.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/offlineDelete.svg';
import { ReactComponent as SyncIcon } from '../../assets/images/offlineSync.svg';
import MediumTypography from '../../components/formlib/MediumTypography';

import {
  createProgramsyncobj,
  deleteofflinedata,
  saveClientLookupData,
  saveCodeData,
  saveCodesData,
  saveDashboardData,
  saveIntakeEmployeeNameListData,
  saveOfflineDataClient,
  saveProgramId,
  saveProgramReferralMap,
  saveProgressNotes,
  saveServiceEmployeeNameListData,
} from '../../services/Offline';
import {
  DashboardOfflineData,
  ProgressNoteType,
  SyncOfflineData,
} from '../../utils/type';
import { getResponseDB } from '../../services/storageDB';
import {
  deleteSession,
  getOfflineData,
  syncEmployeeOfflineData,
} from '../../services/configApi/Clients';
import { clearClientStorageDetails } from '../../utils/utilities';

const EmployeeDashboard = () => {
  const navigation = useNavigate();
  const [dashboard, setDashboard] = useState<EmployeeDashboardResponse>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const { updateNotificationAndProfileEnabled } = useContext(
    LookUpContext,
  ) as LookUpContextType;
  const [toastrId, setToastrId] = useState<string>();
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');

  const [emptyScreen, setEmptyScreen] = useState<boolean>(false);

  const [makedownload, setMakedownload] = useState(false);
  const [deletedownload, setDeleteMakedownload] = useState(false);
  const [sync, setSync] = useState(false);
  const [offlineMode, setOffline] = useState(false);
  const offlineClicked = useRef(false);

  useEffect(() => {
    sessionStorage.clear();
    clearClientStorageDetails();
    makeDownloadAndSync();
  }, []);

  useLayoutEffect(() => {
    const checkOffline = async () => {
      const programIds = (await getResponseDB('programIds')) as string[];
      const selectedProgram: string | null =
        localStorage.getItem('selectedProgram');
      let programId: string;
      if (selectedProgram !== null) {
        programId = JSON.parse(selectedProgram).id;
        if (programIds && programIds.includes(programId)) {
          setOffline(true);
        } else {
          setOffline(false);
        }
      }
    };
    checkOffline();
  });

  const makeDownloadAndSync = async () => {
    toggleLoader(true);
    const programIds = (await getResponseDB('programIds')) as string[];
    const selectedProgram: string | null =
      localStorage.getItem('selectedProgram');
    let programId: string;
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;

      setMakedownload(true);
      setDeleteMakedownload(false);
      setSync(false);
      if (programIds) {
        if (programIds.includes(programId)) {
          setMakedownload(false);
          setDeleteMakedownload(true);
          setSync(true);
        }
      }
      await getUserPermissions();
      offlineClicked.current = false;
    }

    // check if application is not running in localhost and disable all
    if (
      window.location.hostname !== 'localhost' &&
      window.location.hostname !== '127.0.0.1'
    ) {
      setMakedownload(false);
      setSync(false);
      setDeleteMakedownload(false);
    }
  };

  const getUserPermissions = async () => {
    toggleLoader(true);
    await getEmployeePermissions()
      .then(async (response) => {
        const selectedProgram = localStorage.getItem('selectedProgram');
        if (selectedProgram && selectedProgram !== null) {
          updateNotificationAndProfileEnabled(true);
          const selectedProgramPermission = localStorage.getItem(
            JSON.parse(selectedProgram).id,
          );
          if (
            !selectedProgramPermission ||
            selectedProgramPermission === null
          ) {
            localStorage.setItem(
              JSON.parse(selectedProgram).id,
              JSON.stringify(response),
            );
          }
        }
        await calldashboardapi();
      })
      .catch((error) => {
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          if (
            error.code === 1040 ||
            error.code === 1044 ||
            error.code === 1002 ||
            error.code === 1049
          ) {
            updateNotificationAndProfileEnabled(false);
            setEmptyScreen(true);
          } else {
            setToastrId('failedToLoadDashboardMessage');
            setToastrDefaultMessage('Failed to load dashboard details');
          }
        }
      });
  };

  async function calldashboardapi() {
    await getEmployeeDashboardDetails()
      .then((dashboardData) => {
        setDashboard(dashboardData);
        toggleLoader(false);
      })
      .catch((errorDashboard) => {
        toggleLoader(false);
        setToastrVariable('error');
        if (isCustomError(errorDashboard)) {
          const apiError = errorDashboard as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('failedToLoadDashboardMessage');
          setToastrDefaultMessage('Failed to load dashboard details');
        }
      });
  }
  setTimeout(() => window.dispatchEvent(new Event('resize')), 100);

  const handleNavigation = (routeName: string) => {
    navigation(routeName);
  };

  async function deleteProgramData(programId: string) {
    await deleteofflinedata(programId);
    await makeDownloadAndSync();
  }

  const deleteOfflineData = async () => {
    toggleLoader(true);

    const selectedProgram: string | null =
      localStorage.getItem('selectedProgram');
    let programId: string = '';
    if (selectedProgram !== null) {
      programId = JSON.parse(selectedProgram).id;
    }
    const programRemaining = (await getResponseDB('programIds')) as string[];
    let sessionIntimation: boolean = false;
    if (programRemaining && programRemaining.length === 1) {
      sessionIntimation = true;
    }

    if (sessionIntimation) {
      await deleteSession()
        .then(async (response) => {
          if (response) {
            await deleteProgramData(programId);
          } else {
            setToastrVariable('error');
            setToastrId('deleteSession');
            setToastrDefaultMessage(
              "Couldn't deleted offline data, please try again later.",
            );
            toggleLoader(false);
          }
        })
        .catch(() => {
          setToastrVariable('error');
          setToastrId('deleteSession');
          setToastrDefaultMessage(
            "Couldn't deleted offline data, please try again later.",
          );
          toggleLoader(false);
        });
    } else {
      await deleteProgramData(programId);
    }
  };

  async function fetchOfflineData() {
    if (offlineClicked.current === true) {
      return;
    }
    offlineClicked.current = true;
    toggleLoader(true);
    await getOfflineData()
      .then(async (response) => {
        if (response) {
          await saveOfflineData(response);
        }
      })
      .catch(() => {
        setToastrVariable('error');
        setToastrId('fetchOfflineData');
        setToastrDefaultMessage(
          "Couldn't fetch offline data, please try again later.",
        );
        toggleLoader(false);
      });
  }

  async function saveOfflineData(offlinedata: DashboardOfflineData) {
    const dashboardJson = {} as EmployeeDashboardResponseOffline;
    Object.keys(offlinedata).forEach(async function (key) {
      console.log(key);

      if (key === 'progressNotes') {
        dashboardJson.progressNotes = offlinedata[key];
      }

      if (key === 'upcomingEvents') {
        dashboardJson.upcomingEvents = offlinedata[key];
      }

      if (key === 'progressNotesDetails') {
        offlinedata[key].forEach(async (progressNote: ProgressNoteType) => {
          await saveProgressNotes(progressNote);
        });
      }
      if (key === 'clients') {
        for (const client of offlinedata[key]) {
          console.log(client.faceSheet.demographics.referralId, client);
          await new Promise<void>(async (resolve) => {
            await saveOfflineDataClient(
              client,
              client.faceSheet.demographics.referralId,
            );
            await saveProgramReferralMap(
              client.faceSheet.demographics.referralId,
            );
            resolve();
          });
        }
      }
      if (key === 'codes') {
        await saveCodesData(offlinedata[key]);
      }
      if (key === 'categoryCodes') {
        await saveCodeData(offlinedata[key]);
      }
      if (key === 'clientLookup') {
        await saveClientLookupData(offlinedata[key]);
      }
      if (key === 'serviceEmployeeNameList') {
        await saveServiceEmployeeNameListData(offlinedata[key]);
      }
      if (key === 'intakeEmployeeNameList') {
        await saveIntakeEmployeeNameListData(offlinedata[key]);
      }
      if (key === 'programImageUrl') {
        const imageUrls = [offlinedata[key]];
        const loadImage = (url: string) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            // url decode
            img.src = decodeURIComponent(url);
            img.onload = () => resolve(`Image loaded: ${url}`);
            img.onerror = () => reject(`Error loading image: ${url}`);
          });
        };
        try {
          const loadPromises = imageUrls.map(loadImage);
          await Promise.all(loadPromises);
        } catch (error) {
          console.error(error);
        }
      }
    });

    await saveDashboardData(dashboardJson);
    await saveProgramId();

    const offlineToken: string = localStorage.getItem('offlineToken') || '';
    localStorage.setItem('refreshToken', offlineToken);

    await makeDownloadAndSync();
  }

  const syncData = async () => {
    const programobj = await createProgramsyncobj();
    console.log('programobj', programobj);
    toggleLoader(true);
    await syncEmployeeOfflineData(programobj as SyncOfflineData)
      .then(async (response) => {
        if (response) {
          const selectedProgram: string | null =
            localStorage.getItem('selectedProgram');
          let programId: string = '';
          if (selectedProgram !== null) {
            programId = JSON.parse(selectedProgram).id;
          }

          await deleteofflinedata(programId).then(async () => {
            await makeDownloadAndSync();
          });
          setToastrVariable('success');
          setToastrId('synced');
          setToastrDefaultMessage('Data synced successfully');
        }
      })
      .catch(async () => {
        await makeDownloadAndSync();
        setToastrVariable('error');
        setToastrId('syncfail');
        setToastrDefaultMessage('Failed to sync data');
      })
      .finally(() => {
        toggleLoader(false);
      });
  };

  useEffect(() => {
    if (makedownload) {
      toggleLoader(true);
      const handleDashboard = async () => {
        await getUserPermissions();
      };
      handleDashboard();
    }
  }, [makedownload]);

  return (
    <>
      {toastrId !== undefined && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
      )}

      {dashboard && !emptyScreen && (
        <Box component="main" className="pb-lg">
          <Box component="section">
            <Toolbar disableGutters>
              <TitleText labelid="dashboardText" defaultlabel="Dashboard" />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'end', // Center items vertically
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                {makedownload && (
                  <Box
                    onClick={fetchOfflineData}
                    sx={{
                      cursor:
                        offlineClicked.current === true
                          ? 'not-allowed'
                          : 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DownloadIcon style={{ marginRight: '8px' }} />
                    <MediumTypography
                      labelid={'offlinedownloadtxt'}
                      defaultlabel={'Download offline'}
                      sxProps={{
                        color: '#008C82',
                        fontSize: '14px',
                        textAlign: 'justify',
                        marginRight: '24px',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </Box>
                )}
                {deletedownload && (
                  <Box
                    onClick={deleteOfflineData}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <DeleteIcon style={{ marginRight: '8px' }} />
                    <MediumTypography
                      labelid={'offlinedeletetxt'}
                      defaultlabel={'Delete offline'}
                      sxProps={{
                        color: '#008C82',
                        fontSize: '14px',
                        textAlign: 'justify',
                        marginRight: '24px',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </Box>
                )}
                {sync && (
                  <Box
                    onClick={syncData}
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <SyncIcon style={{ marginRight: '8px' }} />

                    <MediumTypography
                      labelid={'offlinesynctxt'}
                      defaultlabel={'Sync'}
                      sxProps={{
                        color: '#008C82',
                        fontSize: '14px',
                        textAlign: 'justify',
                        marginRight: '24px',
                        whiteSpace: 'nowrap',
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Toolbar>
          </Box>

          <Grid container columnSpacing={'12px'} rowSpacing={'12px'}>
            {dashboard.activityAndProductivity && (
              <Grid
                item
                component={'section'}
                sx={{
                  // display: 'flex',
                  display: 'none', // temporary
                  marginTop: '12px',
                  flexDirection: 'row',
                  width: '100%',
                }}
              >
                {dashboard.activityAndProductivity && (
                  <ActivitySheetChart
                    activity={dashboard.activityAndProductivity}
                    reload={false}
                    cardStyle={{
                      width: '67%',
                      marginRight: '12px',
                    }}
                  />
                )}

                <ProductivityChart
                  productivity={dashboard.activityAndProductivity.productivity}
                  cardStyle={{ width: '33%' }}
                  horizontalValue={
                    dashboard.activityAndProductivity.productivityThreshold
                  }
                  barHeight="120px"
                />
              </Grid>
            )}

            {checkPermissionForFeature(
              'backend.task_manager',
              'viewPermission',
            ) && (
              <Grid item xs={12} sm={12} md={8} lg={8}>
                {dashboard.taskManager && (
                  <Card sx={{ height: '250px' }}>
                    <TaskManager
                      tasks={dashboard.taskManager}
                      handleNavigation={() => {
                        handleNavigation(TASK_MANAGER);
                      }}
                    />
                  </Card>
                )}
              </Grid>
            )}

            {offlineMode &&
              checkPermissionForFeature(
                'backend.progress_note',
                'viewPermission',
              ) && (
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Card sx={{ height: '250px' }}>
                    <DashboardProgressNote
                      progressNotes={dashboard.progressNotes}
                      handleNavigation={() => {
                        handleNavigation(PROGRESS_NOTE_LIST_ROUTE_NAME);
                      }}
                    />
                  </Card>
                </Grid>
              )}

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Card sx={{ backgroundColor: '#00C6B8', height: '250px' }}>
                <UpcommingEvents
                  events={dashboard.upcomingEvents}
                  handleNavigation={() => {
                    handleNavigation(PROGRESS_NOTE_LIST_ROUTE_NAME);
                  }}
                  labelId={'upcommingEventsOfflineText'}
                />
              </Card>
            </Grid>

            {checkPermissionForFeature(
              'backend.case_load',
              'viewPermission',
            ) && (
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Card>
                  {dashboard.caseLoad && (
                    <CaseLoad
                      caseLoad={dashboard.caseLoad}
                      handleNavigation={() => {
                        handleNavigation(CASE_LOAD_LIST);
                      }}
                    />
                  )}
                </Card>
              </Grid>
            )}

            {!offlineMode &&
              checkPermissionForFeature(
                'backend.progress_note',
                'viewPermission',
              ) && (
                <Grid item xs={12} sm={12} md={8} lg={8}>
                  <Card sx={{ height: '250px' }}>
                    <DashboardProgressNote
                      progressNotes={dashboard.progressNotes}
                      handleNavigation={() => {
                        handleNavigation(PROGRESS_NOTE_LIST_ROUTE_NAME);
                      }}
                    />
                  </Card>
                </Grid>
              )}
          </Grid>
        </Box>
      )}

      {emptyScreen && (
        <Box component="main" className="p-lg">
          <Box
            component="section"
            sx={{
              zIndex: 1,
              textAlign: 'center',
              wordWrap: 'break-word',
            }}
          >
            <EmptyScreen
              titleLabelId="accessMessage"
              defaultTitleText="Contact your Program Director to complete onboarding, then update your profile to fully use ThomConnect."
              showButton={false}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default EmployeeDashboard;
