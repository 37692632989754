import {
  Drawer,
  Button,
  Grid,
  Card,
  Box,
  Divider,
  IconButton,
  Typography,
  Tooltip,
  AlertColor,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, FormikErrors, FormikProps } from 'formik';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import Textinput from '../../components/formlib/Textinput';
import dayjs, { Dayjs } from 'dayjs';
import SmallTypography from '../../components/formlib/SmallTypography';
import {
  Client,
  SchedulerEventType,
  EditSchedulerEventType,
  SchedulerAttendee,
} from '../../utils/type';
import {
  Employee,
  getAllEmployeeList,
} from '../../services/configApi/employees/employeeServices';
import MultiSelectAutocompleteSearch from '../../components/formlib/MultiSelectAutocompleteSearch';
import {
  STORAGE_USERNAME_KEY,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import { ReactComponent as User } from '../../assets/images/User.svg';
import { ReactComponent as MapPin } from '../../assets/images/map_pin.svg';
import { ReactComponent as AlignLeft } from '../../assets/images/align_left.svg';
import { ReactComponent as Menu } from '../../assets/images/menu.svg';
import { ReactComponent as Clock } from '../../assets/images/clock.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import { ReactComponent as Closegray } from '../../assets/images/x.svg';
import { ReactComponent as Calender } from '../../assets/images/Calendar.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import DeleteSchedulerEventModal from './DeleteSchedulerEventModal';
import {
  editAppointment,
  checkAvailability,
  getOwnerEventDetailsById,
} from '../../services/schedulerEventsApi';
import UnavailabilityCheckModal from './UnavailabilityCheckModal';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import ModalPopup from '../../components/formlib/ModalPopup';
import _, { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import AppTimePicker from '../../components/formlib/AppTimePicker';
import moment from 'moment';
import {
  appointmentTypes,
  appointmentTypesForMultiClients,
} from './schedulerUtils';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';

dayjs.extend(utc);
dayjs.extend(timezone);

interface EditModelPropType {
  showModal: boolean;
  onModalClose: (
    openFromEdit: boolean,
    toastrVariableFromEdit?: AlertColor,
    toastrMessageFromEdit?: string,
    toastrIdFromEdit?: string,
    toastrDefaultMessageFromEdit?: string,
  ) => void;
  eventId: string;
  clients: Client[];
}

export type DateString = Dayjs | null | string;

export const SubmitButton = styled(Button)`
  background: #00938e;
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  color: #ffffff;
  &:hover {
    background: #00938e;
    color: #ffffff;
  }
`;

export const CancelButton = styled(Button)`
  background: #ffffff;
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  border: 1px solid #97a6a5;
  color: #2a4241;
  &:hover {
    background: #ffffff;
    color: #2a4241;
  }
`;

const drawerWidth = 600;

const validationSchema = yup.object().shape({
  clients: yup.array().of(yup.string()),
  location: yup.string().when('clients', {
    is: (clients: MultiSelectOptioinType[]) => clients && clients.length > 0,
    then: yup.string().required('locationRequiredMessage'),
    otherwise: yup.string(),
  }),
  isManualLocationUpdate: yup.boolean().default(false),
  appointmentType: yup.string().required('apptTypeRequiredMessage'),
  otherDescription: yup.string().when('appointmentType', {
    is: 'appointmentType10',
    then: yup.string().required('BlockingTime.descriptionError').nullable(),
    otherwise: yup.string().nullable(),
  }),
  attendeeList: yup.array().of(
    yup.object().shape({
      attendeeId: yup.string(),
      attendeeName: yup.string().nullable(),
      date: yup.string(),
      startTime: yup.date().required('startTimeRequiredMessage').nullable(),
      endTime: yup
        .date()
        .required('endTimeRequiredMessage')
        .test(
          'end-date-not-equal',
          'schedulerEndDateValidationMessage',
          function (value, context) {
            value?.setDate(context.parent.startTime?.getDate());
            return dayjs(context.parent.startTime).isBefore(dayjs(value));
          },
        )
        .nullable(),
      status: yup.string(),
    }),
  ),
  attendees: yup.array().when('clients', (clients, schema) => {
    return schema.min(1, 'AddNewSchedulerEvent.minAttendeeMessage').required();
  }),
});

type MultiSelectOptioinType = Employee | Client;
let selectedAttendees: MultiSelectOptioinType[] = [];
let schedulerEventAttendeeList: SchedulerAttendee[] = [];

const EditSchedulerEventModal: FC<EditModelPropType> = ({
  showModal,
  onModalClose,
  eventId,
  clients,
}) => {
  const rctl = useIntl();
  const loggedInClinicianName = localStorage.getItem(STORAGE_USERNAME_KEY);
  const [schedulerEvent, setSchedulerEvent] = useState<SchedulerEventType>();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  // const [showLoader, setShowLoader] = useState<boolean>(true);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [openAvailabilityCheckModel, setOpenAvailabilityCheckModel] =
    useState(false);
  const [highlightUnavailableAttendees, setHighlightUnavailableAttendees] =
    useState(false);
  const [unavailableAttendees, setUnavailableAttendees] = useState<string[]>(
    [],
  );
  const [appStartDate, setAppStartDate] = useState<DateString>(null);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [editAppointmentValues, setEditAppointmentValues] =
    useState<EditSchedulerEventType>();
  const [openModal, setOpenModal] = useState(false);
  const [assesments, setAssessments] = useState('');
  const [commonDate, setCommonDate] = useState<string | null>('');
  const formRef = React.useRef<FormikProps<EditSchedulerEventType>>(null);
  const [originalAddress, setOriginalAddress] = useState<string | null>(null);

  const clearForm = () => {
    formRef.current?.setValues(formRef.current?.initialValues);
    // getSchedulerEventDetails();
    setOpenModal(false);
  };

  useEffect(() => {
    if (commonDate) {
      const updatedAttendeeList =
        formRef.current &&
        formRef.current.values.attendeeList.map((attendee) => ({
          ...attendee,
          date: commonDate,
          status: attendee.status === 'CREATE' ? 'CREATE' : 'UPDATE',
        }));
      if (formRef.current)
        formRef.current.setFieldValue('attendeeList', updatedAttendeeList);
    }
  }, [commonDate]);

  useEffect(() => {
    return () => {
      clearForm();
      selectedAttendees = [];
    };
  }, []);

  const getAttendeeList = (event: SchedulerEventType) => {
    getAllEmployeeList(
      dayjs.utc(event.start).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
    )
      .then((response) => {
        event.attendeeList.forEach((attendee) => {
          const employee = response.find(
            (emp) => emp.id === attendee.attendeeId,
          );
          if (employee) {
            attendee.attendeeName = `${employee.firstName} ${employee.lastName}`;
          }
        });
        if (event.location) {
          setOriginalAddress(event.location);
        }
        setAppStartDate(event.start);
        setSchedulerEvent(event);
        schedulerEventAttendeeList = [
          ...schedulerEventAttendeeList,
          ...event.attendeeList,
        ];
        setEmployees(response);
        toggleLoader(false);
      })
      .catch(() => {});
  };

  const getDisabledState = (
    appointmentType: string,
    apptClients: Client[] | undefined,
  ) => {
    if (!appointmentTypesForMultiClients.includes(appointmentType)) {
      if (
        apptClients !== undefined &&
        apptClients.length > 0 &&
        apptClients[0].allowDelete === false
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const updateEvent = useCallback(
    debounce((values: EditSchedulerEventType) => {
      toggleLoader(true);
      formHandleSubmit(values);
    }, 500),
    [originalAddress],
  );

  const formHandleSubmit = (values: EditSchedulerEventType) => {
    setHighlightUnavailableAttendees(false);
    setUnavailableAttendees([]);
    setEditAppointmentValues(values);

    values.attendeeList.forEach((attendee) => {
      const date = dayjs(attendee.date).format('YYYY-MM-DD');
      const startTime = dayjs(attendee.startTime).format('HH:mm:ss');
      const endTime = dayjs(attendee.endTime).format('HH:mm:ss');
      const timeZOne = dayjs(attendee.date).format('Z');

      const newStartTime = dayjs.utc(`${date} ${startTime}${timeZOne}`);
      const newEndTime = dayjs.utc(`${date} ${endTime}${timeZOne}`);

      if (newEndTime.isBefore(newStartTime)) {
        attendee.startTime = newStartTime.format();
        attendee.endTime = newEndTime.add(1, 'day').format();
      } else {
        attendee.startTime = newStartTime.format();
        attendee.endTime = newEndTime.format();
      }
    });

    if (originalAddress) {
      values.isManualLocationUpdate = originalAddress !== values.location;
    } else {
      values.isManualLocationUpdate = false;
    }

    if (values.attendees != undefined && values.attendees.length != 0) {
      checkAvailability(values, eventId)
        .then((response) => {
          const attendees: string[] = [];
          if (response.attendeeList !== null) {
            response.attendeeList.forEach((attendee) => {
              if (
                attendee.unavailableStatus &&
                attendee.unavailableStatus !== null &&
                attendee.unavailableStatus === true
              ) {
                attendees.push(attendee.attendeeId);
              }
            });
          }
          if (attendees.length != 0) {
            setOpenAvailabilityCheckModel(true);
            setUnavailableAttendees(attendees);
            toggleLoader(false);
          } else {
            saveAppointment(values);
          }
        })
        .catch((error) => {
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            onModalClose(
              true,
              'error',
              apiError.message,
              rctl.formatMessage({ id: apiError.id }),
              undefined,
            );
          } else {
            const response = error as ApiMessage;
            onModalClose(
              true,
              'error',
              response.message,
              'failedApiMsg',
              undefined,
            );
          }
          toggleLoader(false);
        });
    } else {
      saveAppointment(values);
    }
  };

  const saveAppointment = (values: EditSchedulerEventType) => {
    toggleLoader(true);
    setHighlightUnavailableAttendees(false);
    setUnavailableAttendees([]);

    editAppointment(values)
      .then(() => {
        toggleLoader(false);
        onModalClose(
          true,
          'success',
          undefined,
          'EditNewSchedulerEvent.successMessage',
          'Appointment updated successfully',
        );
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          onModalClose(
            true,
            'error',
            apiError.message,
            rctl.formatMessage({ id: apiError.id }),
            undefined,
          );
        } else {
          const response = error as ApiMessage;
          onModalClose(
            true,
            'error',
            response.message,
            'failedApiMsg',
            undefined,
          );
        }
      });
  };

  const showDelete = () => setOpenDeleteModel(true);

  const closeDeleteModal = (
    openFromDelete: boolean,
    toastrVariableFromDelete?: AlertColor,
    toastrMessageFromDelete?: string,
    toastrIdFromEdit?: string,
    toastrDefaultMessageFromDelete?: string,
  ) => {
    onModalClose(
      openFromDelete,
      toastrVariableFromDelete,
      toastrMessageFromDelete,
      toastrIdFromEdit,
      toastrDefaultMessageFromDelete,
    );
    setOpenDeleteModel(false);
  };

  const prepareOwnerName = () => {
    if (schedulerEvent) {
      const owner = schedulerEvent.owner;
      return (
        (owner.firstName ?? owner.firstName) +
        ' ' +
        (owner.lastName ?? owner.lastName)
      );
    }
    return '';
  };

  const getSchedulerEventDetails = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId === null) {
      return;
    }
    toggleLoader(true);
    getOwnerEventDetailsById(eventId, userId)
      .then((data) => {
        const dateTime = data.attendeeList[0].startTime;
        const date = dayjs(dateTime).format('YYYY-MM-DD');
        setCommonDate(date);
        setAssessments(
          data.appointmentType == 'appointmentType2' ||
            data.appointmentType == 'appointmentType3'
            ? 'appointmentTypeCombined'
            : '',
        );
        const matchingClients: Client[] = [];
        data.clients?.forEach((client) => {
          clients.forEach((c) => {
            if (c.clientId === client.clientId) {
              matchingClients.push(client);
            }
          });
        });
        data.attendeeList.forEach((attendee) => {
          if (attendee.attendeeId === userId) {
            data.allowUpdateDeleteActions = attendee.allowUpdateDeleteActions;
          }
          attendee.date = moment(attendee.startTime, 'YYYY-MM-DDTHH:mm:ssZ')
            .utc()
            .local()
            .format();
          attendee.status = 'NONE';
          return attendee;
        });
        if (data.attendees) {
          data.attendees.forEach((attendee) => {
            const att = data.attendeeList.find(
              (a) => a.attendeeId === attendee.id,
            );
            if (att) {
              attendee.allowDelete = att.allowUpdateDeleteActions;
            }
            return attendee;
          });
        }
        selectedAttendees = data.attendees as Employee[];
        if (matchingClients.length > 0) {
          const updatedData: SchedulerEventType = {
            ...data,
            clients: matchingClients,
          };
          // setSchedulerEvent(updatedData);
          getAttendeeList(updatedData);
        } else {
          getAttendeeList(data);
        }
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          onModalClose(
            true,
            'error',
            apiError.message,
            rctl.formatMessage({ id: apiError.id }),
            undefined,
          );
        } else {
          const response = error as ApiMessage;
          toggleLoader(false);
          onModalClose(
            true,
            'error',
            response.message,
            'failedApiMsg',
            undefined,
          );
        }
      });
  };

  useEffect(() => {
    getSchedulerEventDetails();
  }, []);

  const closeUnavailabilityModal = (
    justCloseWithoutAnySelection: boolean,
    proceedWithUnavailabilites: boolean,
  ) => {
    if (!justCloseWithoutAnySelection) {
      if (proceedWithUnavailabilites && editAppointmentValues) {
        saveAppointment(editAppointmentValues);
      } else {
        setHighlightUnavailableAttendees(true);
      }
    }
    setOpenAvailabilityCheckModel(false);
  };

  const getClientAddress = useCallback((client: Client): string => {
    const addressParts = [
      client.eStreet,
      client.eAddress2,
      client.eCity,
      client.eState,
      client.eZipCode !== null ? (client.eZipCode as string) : '',
    ]
      .filter(Boolean)
      .join(', ');
    return addressParts;
  }, []);

  return (
    <>
      <Divider sx={{ bgcolor: '#CFDEDE' }} />
      {editAppointmentValues && (
        <UnavailabilityCheckModal
          openAvailabilityCheckModel={openAvailabilityCheckModel}
          closeUnavailabilityModal={closeUnavailabilityModal}
        ></UnavailabilityCheckModal>
      )}
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          clearForm();
        }}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="Cancel"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      <Drawer
        sx={{
          width: drawerWidth,
          paddingInline: '24px',
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          opacity: 1,
          pointerEvents: 'auto',
        }}
        anchor={'right'}
        open={showModal}
        onClose={() => {
          onModalClose(false, undefined, undefined, undefined, undefined);
        }}
      >
        {schedulerEvent && (
          <>
            {openDeleteModel && (
              <DeleteSchedulerEventModal
                schedulerEvent={schedulerEvent}
                openDeleteEventModel={openDeleteModel}
                closeDeleteModal={closeDeleteModal}
              ></DeleteSchedulerEventModal>
            )}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                bgcolor: '#E7F0F0',
                padding: '0px 0px 0px 0px !important',
                backgroundColor: '#fff',
              }}
            >
              <Card
                sx={{
                  padding: '24px',
                  my: '0px',
                  ml: '0px',
                  mt: '0px',
                  boxShadow: 'none',
                }}
              >
                <Formik
                  innerRef={formRef}
                  initialValues={{
                    location: schedulerEvent.location
                      ? schedulerEvent.location
                      : '',
                    otherDescription: schedulerEvent.otherDescription,
                    description: schedulerEvent.description
                      ? schedulerEvent.description
                      : '',
                    isManualLocationUpdate: false,
                    // start: dayjs(schedulerEvent.start),
                    // end: dayjs(schedulerEvent.end),
                    attendeeList: schedulerEvent.attendeeList,
                    appointmentType: schedulerEvent.appointmentType
                      ? schedulerEvent.appointmentType
                      : '',
                    clients:
                      schedulerEvent.clients != undefined &&
                      schedulerEvent.clients.length != 0
                        ? schedulerEvent.clients.map(
                            (client) => client.clientId,
                          )
                        : [],
                    attendees:
                      schedulerEvent.attendees != undefined &&
                      schedulerEvent.attendees.length != 0
                        ? schedulerEvent.attendees.map(
                            (clinician) => clinician.id,
                          )
                        : [],
                    owner: schedulerEvent.owner?.id,
                    recurringId: schedulerEvent.recurringId,
                    commonAppointmentId: schedulerEvent.commonAppointmentId,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={(values) => {
                    updateEvent(JSON.parse(JSON.stringify(values)));
                  }}
                >
                  {({
                    setFieldValue,
                    handleSubmit,
                    initialValues,
                    values,
                    errors,
                    touched,
                  }) => (
                    <Grid container style={{ gap: 16 }}>
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={10.4}>
                          <Typography
                            gutterBottom
                            component="div"
                            style={{
                              color: '#2A4241',
                              fontSize: '16px',
                              fontWeight: '600',
                              lineHeight: '36px',
                              fontFamily: 'Lato-Bold',
                            }}
                          >
                            <FormattedMessage
                              id="AddNewSchedulerEvent.EditAppointmentTitle"
                              defaultMessage="Edit an Appointment"
                            />
                          </Typography>{' '}
                        </Grid>

                        <Grid item xs={0.8}>
                          {checkPermissionForFeature(
                            'backend.appointment',
                            'deletePermission',
                          ) && (
                            <Tooltip title="Delete">
                              <IconButton onClick={showDelete}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Grid>

                        <Grid item xs={0.8} textAlign={'right'}>
                          <IconButton
                            onClick={() => {
                              onModalClose(
                                false,
                                undefined,
                                undefined,
                                undefined,
                                undefined,
                              );
                            }}
                          >
                            <Closegray />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={1} className="svgIcon">
                          <Menu />
                        </Grid>
                        <Grid item xs={11}>
                          <DropdownComponent
                            disabled={
                              true
                              // (schedulerEvent.occurrences != undefined &&
                              //   schedulerEvent.occurrences != null &&
                              //   schedulerEvent.occurrences.length != 0) ||
                              // schedulerEvent.allowUpdateDeleteActions === false
                            }
                            labelid="AddNewSchedulerEvent.appointmentType"
                            defaultlabelid="Appointment type"
                            name={'appointmentType'}
                            handleChange={(value: string) => {
                              setFieldValue('appointmentType', value);
                              setFieldValue('clients', []);
                              setFieldValue('description', '');
                              setFieldValue('location', '');
                              const updatedSchedulerEvent = {
                                ...schedulerEvent,
                                clients: [],
                              };
                              setSchedulerEvent(updatedSchedulerEvent);
                            }}
                            value={
                              assesments !== ''
                                ? assesments
                                : values.appointmentType
                            }
                            names={appointmentTypes}
                          />
                          {errors.appointmentType &&
                            touched.appointmentType && (
                              <SmallTypography
                                sxProps={{ color: 'red' }}
                                labelId={errors.appointmentType}
                              />
                            )}
                          {values.appointmentType == 'appointmentType10' && (
                            <Textinput
                              className="mt-lg"
                              labelid="description"
                              Required
                              defaultlabelid="Description"
                              inputProps={{ maxLength: 30 }}
                              Value={values.otherDescription}
                              handlechange={(value) =>
                                setFieldValue('otherDescription', value)
                              }
                            />
                          )}
                          {errors.otherDescription &&
                            touched.otherDescription && (
                              <MediumTypography
                                className="errorText-md"
                                labelid={errors.otherDescription}
                                defaultlabel={'Description is required'}
                              />
                            )}
                        </Grid>
                      </Grid>

                      {assesments == 'appointmentTypeCombined' && (
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={1} className="svgIcon"></Grid>
                          <Grid item xs={11}>
                            <CustomRadioButton
                              data={[
                                {
                                  value: 'appointmentType2',
                                  labelId: 'Initial',
                                  defaultLabel: 'Initial',
                                },
                                {
                                  value: 'appointmentType3',
                                  labelId: 'Follow up',
                                  defaultLabel: 'Follow up',
                                },
                              ]}
                              value={values.appointmentType}
                              disable={true}
                              onSelectItem={() => {}}
                              row={false}
                            />

                            {errors.appointmentType &&
                              touched.appointmentType && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.appointmentType}
                                />
                              )}
                          </Grid>
                        </Grid>
                      )}

                      <Grid container direction="row" alignItems="center">
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={1} className="svgIcon"></Grid>
                          <Grid item xs={11} className="multisearchHgt">
                            <DatePickerComponent
                              required
                              labelid="IFSP.Date"
                              defaultlabelid="Date *"
                              value={commonDate ? dayjs(commonDate) : null}
                              disabledDate={
                                schedulerEvent.allowUpdateDeleteActions ===
                                false
                              }
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                setCommonDate(formattedDate);
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid container direction="row" alignItems="center">
                          <Grid item xs={1} className="svgIcon">
                            <User />
                          </Grid>
                          <Grid item xs={11} className="multisearchHgt">
                            <MultiSelectAutocompleteSearch
                              labelId="AddNewSchedulerEvent.clients"
                              defaultId="Add Client(s)"
                              disabled={getDisabledState(
                                values.appointmentType,
                                schedulerEvent.clients,
                              )}
                              onSelectItem={(
                                value: MultiSelectOptioinType[],
                              ) => {
                                let updatedSchedulerEvent = null;
                                const selectedClients =
                                  value.length > 0
                                    ? [value[value.length - 1]]
                                    : [];
                                if (
                                  !appointmentTypesForMultiClients.includes(
                                    values.appointmentType,
                                  )
                                ) {
                                  if (value.length == 0) {
                                    setFieldValue('clients', []);
                                    updatedSchedulerEvent = {
                                      ...schedulerEvent,
                                      clients: [],
                                    };
                                  } else if (value.length == 1) {
                                    setFieldValue(
                                      'clients',
                                      value.map((client) => client.clientId),
                                    );
                                    updatedSchedulerEvent = {
                                      ...schedulerEvent,
                                      clients: value as Client[],
                                    };
                                  } else {
                                    updatedSchedulerEvent = {
                                      ...schedulerEvent,
                                      clients: selectedClients as Client[],
                                    };
                                    setFieldValue(
                                      'clients',
                                      selectedClients.map(
                                        (client) => client.clientId,
                                      ),
                                    );
                                  }
                                } else {
                                  updatedSchedulerEvent = {
                                    ...schedulerEvent,
                                    clients: value as Client[],
                                  };
                                  setFieldValue(
                                    'clients',
                                    value.map((client) => client.clientId),
                                  );
                                }
                                if (
                                  values.appointmentType ===
                                    'appointmentType1' ||
                                  values.appointmentType ===
                                    'appointmentType2' ||
                                  values.appointmentType ===
                                    'appointmentType3' ||
                                  values.appointmentType ===
                                    'appointmentType4' ||
                                  values.appointmentType ===
                                    'appointmentType11' ||
                                  values.appointmentType === 'appointmentType5'
                                ) {
                                  let theSelectedClient: Client | null = null;
                                  if (selectedClients.length > 0) {
                                    theSelectedClient =
                                      selectedClients[0] as Client;
                                  }
                                  if (
                                    theSelectedClient !== null &&
                                    theSelectedClient.weapons === true &&
                                    theSelectedClient.weaponSecure === false
                                  ) {
                                    setFieldValue('location', '');
                                    setFieldValue(
                                      'description',
                                      'Weapons are not secure!',
                                    );
                                  } else if (selectedClients.length > 0) {
                                    const address = getClientAddress(
                                      selectedClients[0] as Client,
                                    );
                                    setFieldValue('location', address);
                                    setFieldValue('description', '');
                                    setOriginalAddress(address);
                                  } else if (value.length === 0) {
                                    setFieldValue('description', '');
                                    setFieldValue('location', '');
                                    setOriginalAddress(null);
                                  }
                                }
                                setSchedulerEvent(updatedSchedulerEvent);
                              }}
                              data={clients}
                              value={schedulerEvent.clients as Client[]}
                              optionHintId={
                                'AddNewSchedulerEvent.noClientsFoundInOptions'
                              }
                            />
                            {errors.clients && touched.clients && (
                              <SmallTypography
                                sxProps={{ color: 'red' }}
                                labelId={errors.clients as string}
                              />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={1} className="svgIcon">
                          <User />
                        </Grid>
                        <Grid item xs={11} className="multisearchHgt">
                          <MultiSelectAutocompleteSearch
                            labelId="AddNewSchedulerEvent.clinicians"
                            defaultId="Add Attendee(s)"
                            required
                            onSelectItem={(value: MultiSelectOptioinType[]) => {
                              let attendees =
                                formRef.current?.values.attendeeList;
                              if (attendees) {
                                if (selectedAttendees.length > value.length) {
                                  const att = selectedAttendees.find(
                                    (attendee) => {
                                      return !value.some(
                                        (e) => attendee.id === e.id,
                                      );
                                    },
                                  );
                                  if (att) {
                                    let deleteItem = false;
                                    attendees.forEach((attendee) => {
                                      if (attendee.attendeeId === att.id) {
                                        if (attendee.status === 'CREATE') {
                                          deleteItem = true;
                                        } else {
                                          attendee.status = 'DELETE';
                                        }
                                      }
                                      return attendee;
                                    });
                                    if (deleteItem) {
                                      attendees = attendees.filter(
                                        (attendee) =>
                                          attendee.attendeeId !== att.id,
                                      );
                                    }
                                  }
                                } else {
                                  if (value.length === 0) {
                                    attendees = [];
                                    return;
                                  }
                                  const att = value.find((attendee) => {
                                    return !selectedAttendees.some(
                                      (e) => attendee.id === e.id,
                                    );
                                  });
                                  if (att) {
                                    let attendeeIndex = -1;
                                    attendees.forEach(
                                      (attendeeItem, attendeeItemIndex) => {
                                        if (
                                          attendeeItem.attendeeId === att.id
                                        ) {
                                          attendeeIndex = attendeeItemIndex;
                                        }
                                      },
                                    );
                                    if (attendeeIndex !== -1) {
                                      //This is when delete and add again the same attendee
                                      attendees[attendeeIndex].status =
                                        'UPDATE';
                                    } else {
                                      attendees.push({
                                        attendeeId: att.id as string,
                                        attendeeName: `${att.firstName} ${att.lastName}`,
                                        date: commonDate,
                                        startTime: appStartDate
                                          ? dayjs(appStartDate).format()
                                          : null,
                                        endTime: appStartDate
                                          ? dayjs(appStartDate)
                                              .add(1, 'hour')
                                              .format()
                                          : null,
                                        status: 'CREATE',
                                        appointmentId: '',
                                        allowUpdateDeleteActions: true,
                                        interpreter: false,
                                      });
                                    }
                                  }
                                }
                              } else {
                                attendees = [
                                  {
                                    attendeeId: value[0].id as string,
                                    attendeeName: `${value[0].firstName} ${value[0].lastName}`,
                                    date: commonDate,
                                    startTime: appStartDate
                                      ? dayjs(appStartDate).format()
                                      : null,
                                    endTime: appStartDate
                                      ? dayjs(appStartDate)
                                          .add(1, 'hour')
                                          .format()
                                      : null,
                                    status: 'CREATE',
                                    appointmentId: '',
                                    allowUpdateDeleteActions: true,
                                    interpreter: false,
                                  },
                                ];
                              }
                              selectedAttendees = value;
                              formRef.current?.setValues({
                                ...values,
                                attendees: value.map(
                                  (employee) => employee.id as string,
                                ),
                                attendeeList: attendees,
                              });
                            }}
                            highlightSelectedValues={
                              highlightUnavailableAttendees
                                ? unavailableAttendees
                                : []
                            }
                            highlightSelectedValuesTooltipLabelId="AddNewSchedulerEvent.unavailableAttendeesTooltip"
                            data={employees}
                            hideOption={null}
                            value={selectedAttendees}
                            optionHintId={
                              'AddNewSchedulerEvent.noCliniciansFoundInOptions'
                            }
                          />
                          {errors.attendees && touched.attendees && (
                            <SmallTypography
                              sxProps={{ color: 'red' }}
                              labelId={errors.attendees as string}
                            />
                          )}
                        </Grid>
                      </Grid>
                      {values.attendeeList &&
                        values.attendeeList.length > 0 &&
                        values.attendeeList.map((attendee, index) => {
                          if (attendee.status !== 'DELETE') {
                            return (
                              <>
                                <Grid
                                  container
                                  xs={12}
                                  direction="row"
                                  alignItems={'end'}
                                  columnSpacing={'16px'}
                                >
                                  <Grid item xs={1} className="svgIcon">
                                    <Clock />
                                  </Grid>
                                  <Grid item xs={3.8} className="hide">
                                    <MediumTypography
                                      sxProps={{ marginBottom: '4px' }}
                                      label={attendee.attendeeName}
                                    />
                                    <DatePickerComponent
                                      labelid="IFSP.Date"
                                      defaultlabelid="Date *"
                                      required={true}
                                      value={
                                        attendee.date !== null
                                          ? dayjs(attendee.date)
                                          : null
                                      }
                                      disabledDate={true}
                                      handlechange={(date: Dayjs | null) => {
                                        const formattedDate =
                                          dayjs(date).format('MM/DD/YYYY');
                                        setFieldValue(
                                          `attendeeList[${index}].date`,
                                          formattedDate,
                                        );
                                        if (
                                          values.attendeeList[index].status !==
                                          'CREATE'
                                        ) {
                                          setFieldValue(
                                            `attendeeList[${index}].status`,
                                            'UPDATE',
                                          );
                                        }
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={3.6}>
                                    <MediumTypography
                                      sxProps={{ marginBottom: '4px' }}
                                      label={attendee.attendeeName}
                                    />
                                    <AppTimePicker
                                      name="Start Time"
                                      value={
                                        values.attendeeList[index].startTime !==
                                        null
                                          ? dayjs(
                                              values.attendeeList[index]
                                                .startTime,
                                            )
                                          : null
                                      }
                                      minuteStep={1}
                                      disable={
                                        !values.attendeeList[index]
                                          .allowUpdateDeleteActions
                                      }
                                      onTimeChange={(time) => {
                                        if (time === null) {
                                          values.attendeeList[index].startTime =
                                            null;
                                          values.attendeeList[index].endTime =
                                            null;
                                        } else {
                                          values.attendeeList[index].startTime =
                                            time.format();
                                          values.attendeeList[index].endTime =
                                            null;
                                        }

                                        if (
                                          values.attendeeList[index].status !==
                                          'CREATE'
                                        ) {
                                          values.attendeeList[index].status =
                                            'UPDATE';
                                        }
                                        formRef.current?.setValues(values);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={3.6}>
                                    <AppTimePicker
                                      name="End Time"
                                      disable={
                                        !values.attendeeList[index]
                                          .allowUpdateDeleteActions
                                      }
                                      value={
                                        values.attendeeList[index].endTime !==
                                        null
                                          ? dayjs(
                                              values.attendeeList[index]
                                                .endTime,
                                            )
                                          : null
                                      }
                                      minuteStep={1}
                                      onTimeChange={(time) => {
                                        if (time === null) {
                                          values.attendeeList[index].endTime =
                                            null;
                                        } else {
                                          values.attendeeList[index].endTime =
                                            time.format();
                                        }

                                        const initialIds = new Set(
                                          schedulerEventAttendeeList.map(
                                            (attendee2) => attendee2.attendeeId,
                                          ),
                                        );

                                        values.attendeeList.forEach(
                                          (attendee2, index2) => {
                                            const isExisting = initialIds.has(
                                              attendee2.attendeeId,
                                            );
                                            values.attendeeList[index2].status =
                                              isExisting ? 'UPDATE' : 'CREATE';
                                          },
                                        );

                                        formRef.current?.setValues(values);
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={1}></Grid>
                                  <>
                                    <Grid item xs={3.8}></Grid>
                                    <Grid item xs={3.6}>
                                      {errors.attendeeList &&
                                        touched.attendeeList &&
                                        (
                                          errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                        )[index] &&
                                        (
                                          errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                        )[index].startTime && (
                                          <MediumTypography
                                            labelid={
                                              (
                                                errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                              )[index].startTime
                                            }
                                            defaultlabel="Start or End time is required"
                                            className="errorText-md"
                                          />
                                        )}
                                    </Grid>
                                    <Grid item xs={3.6}>
                                      {errors.attendeeList &&
                                        touched.attendeeList &&
                                        (
                                          errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                        )[index] &&
                                        (
                                          errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                        )[index].endTime && (
                                          <MediumTypography
                                            labelid={
                                              (
                                                errors.attendeeList as FormikErrors<SchedulerAttendee>[]
                                              )[index].endTime
                                            }
                                            defaultlabel="End date must be after start date"
                                            className="errorText-md"
                                          />
                                        )}
                                    </Grid>
                                  </>
                                </Grid>
                              </>
                            );
                          }
                        })}

                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={1} className="svgIcon">
                          <MapPin />
                        </Grid>
                        <Grid item xs={11}>
                          <Textinput
                            name="location"
                            labelid="AddNewSchedulerEvent.location"
                            defaultlabelid="Location"
                            Value={values.location}
                            inputProps={{
                              maxLength: 300,
                            }}
                            Required={values.clients.length >= 1}
                            handlechange={(value: string) => {
                              setFieldValue('location', value);
                            }}
                            disabled={
                              schedulerEvent.allowUpdateDeleteActions === false
                            }
                          />

                          {errors.location && touched.location && (
                            <MediumTypography
                              labelid={errors.location}
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                      </Grid>

                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={1} className="svgIcon">
                          <AlignLeft />
                        </Grid>
                        <Grid item xs={11}>
                          <Textinput
                            name="description"
                            Value={values.description}
                            labelid="AddNewSchedulerEvent.notes"
                            defaultlabelid="Add Notes"
                            inputProps={{
                              maxLength: 500,
                            }}
                            handlechange={(value: string) => {
                              setFieldValue('description', value);
                            }}
                            disabled={
                              schedulerEvent.allowUpdateDeleteActions === false
                            }
                          />
                          {errors.description && touched.description && (
                            <MediumTypography
                              labelid={errors.description}
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                      </Grid>

                      {loggedInClinicianName != undefined && (
                        <Grid container direction="row" alignItems="top">
                          <Grid item xs={1} className="svgIcon">
                            <Calender />
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              color: '#2A4241',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '18px',
                            }}
                          >
                            {prepareOwnerName()}
                          </Grid>
                        </Grid>
                      )}

                      <Grid
                        container
                        direction="row"
                        alignItems="right"
                        sx={{ display: 'flex', justifyContent: 'flex-end' }}
                      >
                        <Grid item sx={{ marginRight: '16px' }}>
                          <ButtonComponent
                            className="btn-primary btn-cancel"
                            variantType="contained"
                            labelId="AddNewSchedulerEvent.cancelButton"
                            defaultLabelId="Cancel"
                            onClick={() => {
                              if (_.isEqual(initialValues, values)) {
                                onModalClose(
                                  false,
                                  undefined,
                                  undefined,
                                  undefined,
                                  undefined,
                                );
                              } else {
                                setOpenModal(true);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <ButtonComponent
                            className="btn-primary btn-submit"
                            variantType="contained"
                            type="submit"
                            labelId="EditSchedulerEvent.submitButton"
                            defaultLabelId="Submit"
                            onClick={() => {
                              handleSubmit();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Formik>
              </Card>
            </Box>
          </>
        )}
      </Drawer>
    </>
  );
};

export default EditSchedulerEventModal;
