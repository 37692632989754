import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import {
  Appointment,
  GroupAppointment,
} from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { FC, useContext, useEffect, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { convertTimeDuration, getTimeString } from '../../utils/dateUtil';
import { ReactComponent as GroupIcon } from '../../assets/images/groupIcon.svg';
import { ReactComponent as SingleIcon } from '../../assets/images/singleIcon.svg';
import { ReactComponent as UndoIcon } from '../../assets/images/undoIcon.svg';
import { ReactComponent as ProgressNote } from '../../assets/images/ProgressNoteIcon.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/images/archiveIcon.svg';
import TextWithIcon from '../../components/formlib/TextWithIcon';
import { LOCAL_TIME_12_HOUR_FORMAT } from '../../services/Constant';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { StatusType } from './ActivityTableRow';
import { getFullName } from '../../utils/nameUtils';
import { useNavigate } from 'react-router';
import { PROGRESS_NOTE_SETUP } from '../../routes/Routing';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { useIntl } from 'react-intl';
import { LookUpContext } from '../../context/LookUpContextProvider';
import { LookupItem } from '../../services/configApi/tcsLookups';
import { ReactComponent as DocIcon } from '../../assets/images/decIcon.svg';
import UploadButton from '../../components/formlib/UploadButton';

interface Header {
  empty: string;
  clientName: string;
  apptStartTime: string;
  duration: string;
  serviceCode: string;
  settingCode: string;
  modifiers: string;
  actions: string;
}

const headCells: HeadCell<Header>[] = [
  {
    id: 'empty',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'clientName',
    labelId: 'Dashboard.ClientName',
    defaultLabelId: 'Client Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'apptStartTime',
    labelId: 'appt',
    defaultLabelId: 'Appt Start Time',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'duration',
    labelId: 'myTimeOffDuration',
    defaultLabelId: 'Duration',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'serviceCode',
    labelId: 'serviceCodeText',
    defaultLabelId: 'Service Code',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'settingCode',
    labelId: 'settingCodeText',
    defaultLabelId: 'Setting Code',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'modifiers',
    labelId: 'modifiersText',
    defaultLabelId: 'Modifiers',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'empty',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
];

const AppointmentTableRow: FC<{
  appointments: Appointment[];
  groupAppointments: GroupAppointment[];
  parentArchived: boolean;
  isHoliday: boolean;
  rowId: number;
  isEmployee: boolean;
  parentStatus: StatusType | null;
  archiveAppointment: (id: string, archived: boolean) => void;
  openGroupBillingModal: (id: string, index: number) => void;
  screenName: string;
  selectedDate?: Date;
}> = ({
  appointments,
  groupAppointments,
  parentArchived,
  archiveAppointment,
  openGroupBillingModal,
  isEmployee,
  isHoliday,
  rowId,
  parentStatus,
  screenName,
  selectedDate,
}) => {
  const navigate = useNavigate();
  const [serviceCode, setServiceCode] = useState<LookupItem[]>([]);
  const [settingCode, setSettingCode] = useState<LookupItem[]>([]);
  const rctl = useIntl();
  const { lookups } = useContext(LookUpContext);

  useEffect(() => {
    if (lookups === null) {
      return;
    }

    setServiceCode(() => {
      return lookups.serviceCode.map((e) => {
        return e;
      });
    });

    setSettingCode(() => {
      return lookups.settingsCode.map((e) => {
        return e;
      });
    });
  }, [lookups]);

  const getAppStartTime = (
    time: string | null,
    format: string,
    utc: boolean,
  ) => {
    if (time === null) {
      return '';
    }
    return getTimeString(time, format, utc);
  };

  const getServiceCode = (id: string | null) => {
    if (id === null) return '-';
    const code = serviceCode.find((item) => item.code === id || item.id === id);
    return code ? code.code : '-';
  };

  const getSettingsCode = (id: string | null) => {
    if (id === null) return '-';
    const code = settingCode.find((item) => item.code === id || item.id === id);
    return code ? code.code : '-';
  };

  const getDurationInDecimal = (duration: string | null) => {
    return `${convertTimeDuration(duration, true)} hrs`;
  };

  const getBackgroundColor = () => {
    if (isHoliday) {
      return '#FFEBDD';
    } else if (parentArchived) {
      return '#97A6A5';
    } else if (rowId === 0) {
      return '#ffffff';
    } else {
      return '#ECF9F8';
    }
  };

  const getStatusColor = (progressNotesStatus: string) => {
    if (progressNotesStatus === 'Completed') {
      return '#37D183';
    } else if (progressNotesStatus === 'Canceled') {
      return '#EB4C60';
    } else {
      return '#F9B803';
    }
  };

  const getClassName = () => {
    if (isHoliday) {
      return 'activity-table-bg holidayBg listDataTableHead';
    }
    return `activity-table-bg ${
      rowId !== 0 ? 'activitybg' : 'activitytransbg'
    } + ' listDataTableHead `;
  };

  const getActivityTableRow = (
    appointment: Appointment,
    isGroupBilling: boolean,
    index: number,
    groupIndex: number,
    removeBorder: boolean,
  ) => {
    return (
      <>
        {index === 0 && isGroupBilling && (
          <TableRow>
            <TableCell
              sx={{
                padding: '0px 8px 0px 0px !important',
                backgroundColor: getBackgroundColor(),
                borderBottom: 'none',
                borderLeft: '1px solid #00C6B8',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '20px',
                    backgroundColor: '#00C6B8',
                    height: '1px',
                  }}
                />
                <GroupIcon />
              </Box>
            </TableCell>
            <TableCell
              colSpan={7}
              sx={{
                borderBottom: isGroupBilling ? 'unset' : '#CFDEDE',
                maxWidth: '150px',
              }}
            >
              <UploadButton
                title="groupBillingText"
                defaultLabel="Group Billing"
                paddingTop="0px"
                Icon={<DocIcon />}
                onClick={() => {
                  openGroupBillingModal(appointment.appointmentId, groupIndex);
                }}
              />
            </TableCell>
          </TableRow>
        )}
        <TableRow key={appointment.appointmentId}>
          <TableCell
            sx={{
              padding: '0px 8px 0px 0px !important',
              borderBottom: isGroupBilling ? 'unset' : '#CFDEDE',
              backgroundColor: getBackgroundColor(),
              borderLeft: appointment.isLastIndex ? '' : '1px solid #00C6B8',
              position: 'relative',
              '&::before': {
                display: appointment.isLastIndex ? 'block' : 'none',
                content: '""',
                position: 'absolute',
                left: -0.5,
                top: '0%', // Start 25% from the top to center the border vertically
                height: '50%', // 50% height of the cell
                width: '1px', // Border thickness
                backgroundColor: '#00C6B8', // Border color
              },
            }}
          >
            {!isGroupBilling && (
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  sx={{
                    width: '20px',
                    backgroundColor: '#00C6B8',
                    height: '1px',
                  }}
                />
                <SingleIcon />
              </Box>
            )}
          </TableCell>
          <TableCell
            className={`${
              removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
            }`}
          >
            <Tooltip
              id="tool-tip"
              title={getFullName(
                appointment.clientFirstName,
                appointment.clientLastName,
              )}
              placement="bottom"
            >
              <Box>
                <MediumTypography
                  label={getFullName(
                    appointment.clientFirstName,
                    appointment.clientLastName,
                  )}
                  sxProps={{
                    color: '#008C82',
                    cursor: 'pointer',
                    marginLeft: '16px',
                    maxWidth: '100px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
              </Box>
            </Tooltip>
          </TableCell>
          <TableCell
            padding={'none'}
            className={`${
              removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
            }`}
          >
            <MediumTypography
              label={getAppStartTime(
                appointment.startTime,
                LOCAL_TIME_12_HOUR_FORMAT,
                true,
              )}
              sxProps={{ color: '#008C82', marginRight: '4px' }}
            />
          </TableCell>
          <TableCell
            padding={'none'}
            className={`${
              removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
            }`}
          >
            <MediumTypography
              label={getDurationInDecimal(appointment.sessionDuration)}
            />
          </TableCell>
          <TableCell
            padding={'none'}
            className={`${
              removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
            }`}
          >
            <MediumTypography label={getServiceCode(appointment.serviceCode)} />
          </TableCell>
          <TableCell
            padding={'none'}
            className={`${
              removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
            }`}
          >
            <MediumTypography
              label={getSettingsCode(appointment.settingCode)}
            />
          </TableCell>
          <TableCell
            padding={'none'}
            className={`${
              removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
            }`}
          >
            <MediumTypography label={appointment.modifier ?? '-'} />
          </TableCell>
          {parentArchived ? (
            <TableCell
              className={`${
                removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
              }`}
            />
          ) : (
            <TableCell
              sx={{
                paddingLeft: '4px',
              }}
              className={`${
                removeBorder ? 'disableBorderBotton' : 'enableBorderBottom'
              }`}
            >
              {appointment.archived ? (
                <Box
                  display={'inline-block'}
                  sx={{
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                  }}
                  onClick={() => {
                    if (
                      appointment.archivalStatus !== 'Submitted' &&
                      archiveAppointment &&
                      appointment.progressNotesId !== null
                    ) {
                      archiveAppointment(
                        appointment.progressNotesId,
                        !appointment.archived,
                      );
                    }
                  }}
                >
                  <TextWithIcon
                    className="paddingZero archivedIconMinusRemoveSm"
                    LeftIcon={
                      appointment.archivalStatus !== 'Submitted' && <UndoIcon />
                    }
                    labelId={'archivedText'}
                    defaultLabel="Archived"
                    sxProps={{ color: '#97A6A5', textTransform: 'none' }}
                  />
                </Box>
              ) : (
                <Box
                  className="alignItemCenter"
                  sx={{
                    display: 'flex',
                  }}
                >
                  {checkPermissionForFeature(
                    'backend.progress_note',
                    'viewPermission',
                  ) && (
                    <ActionToolTip
                      label={`${rctl.formatMessage({
                        id: 'progressNoteText',
                        defaultMessage: 'Progress Note',
                      })}: ${appointment.progressNotesStatus}`}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          cursor: 'pointer',
                          marginLeft: '8px',
                        }}
                        onClick={() => {
                          const attendies: { id: string }[] = [];
                          const clientIds: string[] = [];

                          attendies.push({ id: appointment.attendeeId });
                          clientIds.push(appointment.clientId);

                          if (appointment.commonAppointmentId) {
                            navigate(PROGRESS_NOTE_SETUP, {
                              state: {
                                sortRequired: isEmployee,
                                eventId: appointment.commonAppointmentId,
                                attendeeId: attendies,
                                clientIds: clientIds,
                                screenFrom: screenName,
                                selectedDate: selectedDate,
                              },
                            });
                          }
                        }}
                      >
                        <ProgressNote
                          stroke={getStatusColor(
                            appointment.progressNotesStatus,
                          )}
                        />
                      </Box>
                    </ActionToolTip>
                  )}
                  {(parentStatus === 'Not Submitted' ||
                    parentStatus === 'Rejected') &&
                    isEmployee && (
                      <ActionToolTip
                        labelId="archiveText"
                        defaultLabel="Archive"
                      >
                        <Box
                          sx={{
                            padding: '4px',
                            display: 'flex',
                            cursor: 'pointer',
                            marginLeft: '8px',
                            opacity:
                              appointment.progressNotesStatus === 'Pending'
                                ? '0.5'
                                : 'unset',
                            pointerEvents:
                              appointment.progressNotesStatus === 'Pending'
                                ? 'none'
                                : 'auto',
                          }}
                          onClick={() => {
                            if (
                              archiveAppointment &&
                              appointment.progressNotesId !== null
                            ) {
                              if (
                                appointment.archivalStatus === null ||
                                appointment.archivalStatus === 'Pending'
                              ) {
                                archiveAppointment(
                                  appointment.progressNotesId,
                                  !appointment.archived,
                                );
                              }
                            }
                          }}
                        >
                          <ArchiveIcon />
                        </Box>
                      </ActionToolTip>
                    )}
                </Box>
              )}
            </TableCell>
          )}
          <TableCell
            sx={{
              backgroundColor: getBackgroundColor(),
            }}
            className={`disableBorderBotton`}
          />
        </TableRow>
      </>
    );
  };

  return (
    <Table
      className={`${
        groupAppointments && groupAppointments.length > 0
          ? ''
          : 'activitySheetNextedTable'
      } listDataTableHead`}
      sx={{
        marginLeft: '4px',
      }}
    >
      <TableHeader
        labelSxProp={{ whiteSpace: 'normal' }}
        headerNames={headCells}
        checkBoxRequired={false}
        className={'ActivityTableBorderHeader ' + getClassName()}
      />
      <TableBody className="activity-table-bg tableRowcss">
        {groupAppointments?.map((groupAppointment, groupIndex) =>
          groupAppointment.appointments.map((appointment, index) => {
            const lastGroupIndex =
              groupAppointment.appointments.length - 1 === index;
            return getActivityTableRow(
              appointment,
              true,
              index,
              groupIndex,
              !lastGroupIndex,
            );
          }),
        )}
        {appointments.map((appointment, index) => {
          return getActivityTableRow(appointment, false, index, -1, false);
        })}
      </TableBody>
    </Table>
  );
};

export default AppointmentTableRow;
