import React from 'react';
import { Box, Tooltip } from '@mui/material';
import { ReactComponent as ArrowRight } from '../../assets/images/right.svg';
import { useNavigate } from 'react-router';
import TitleText from '../../components/formlib/TitleText';
import SmallTypography from '../../components/formlib/SmallTypography';
import { PROGRESS_NOTE_FACESHEET } from '../../routes/Routing';
import { ProgressNoteFacheetType } from '../../utils/type';
import MediumTypography from '../../components/formlib/MediumTypography';
import { formatClientNameWithSuffix } from '../../utils/nameUtils';
import moment from 'moment';

interface ProgressNotesJSONType {
  data: ProgressNoteFacheetType[];
}
const ProgressNotesFaceSheet: React.FC<ProgressNotesJSONType> = (props) => {
  const navigation = useNavigate();
  return (
    <>
      <Box>
        <Box
          sx={{
            float: 'right',
            position: 'relative',
            cursor: 'pointer',
          }}
        >
          <ArrowRight
            onMouseDown={(e) => e.stopPropagation}
            onTouchStart={(e) => e.stopPropagation}
            onClick={() => {
              navigation(PROGRESS_NOTE_FACESHEET);
            }}
          />
        </Box>

        <TitleText
          labelid="ProgressNote.Title"
          defaultlabel="Progress Notes"
          Sxprops={{ fontSize: '18px' }}
        />
        <Box
          sx={{
            overflowY: 'scroll',
            height: '200px',
            '&::-webkit-scrollbar': {
              width: '5px',
              background: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '--thom-mono-gray',
              borderRadius: '4px',
              border: '0.5px solid (--thom-mono-gray)',
            },
          }}
        >
          {props.data.length === 0 && (
            <SmallTypography
              sxProps={{
                fontSize: '18px',
                color: '#97A6A5',
                textAlign: 'center',
                marginY: 8,
              }}
              labelId="noProgressFoundText"
              defaultLabelId="No Progress Note found yet"
            />
          )}

          {props.data.map((progressNotesProps: ProgressNoteFacheetType) => {
            return (
              <Box sx={{ marginY: '16px' }} key={progressNotesProps.attendeeId}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ width: '150px' }}>
                    <Tooltip
                      title={
                        formatClientNameWithSuffix(
                          progressNotesProps.firstName,
                          progressNotesProps.middleName,
                          progressNotesProps.lastName,
                          progressNotesProps.suffix,
                        ).length > 5
                          ? formatClientNameWithSuffix(
                              progressNotesProps.firstName,
                              progressNotesProps.middleName,
                              progressNotesProps.lastName,
                              progressNotesProps.suffix,
                            )
                          : ''
                      }
                      followCursor
                      arrow={true}
                      sx={{ maxWidth: 'min-content' }}
                    >
                      <Box>
                        <MediumTypography
                          label={formatClientNameWithSuffix(
                            progressNotesProps.firstName,
                            progressNotesProps.middleName,
                            progressNotesProps.lastName,
                            progressNotesProps.suffix,
                          )}
                          textColor="#2A4241"
                          fontweight={600}
                          sxProps={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            maxWidth: '100%',
                          }}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                  <MediumTypography
                    label={moment(progressNotesProps.appointmentDate)
                      .local()
                      .format('MM/DD/YYYY')}
                    textColor="#2A4241"
                  />
                  <MediumTypography
                    label={
                      progressNotesProps.status
                        ? progressNotesProps.status
                        : '-'
                    }
                    textColor="#2A4241"
                    sxProps={{ marginX: '5px' }}
                  />
                </Box>
                {props.data.length - 1 !==
                  props.data.indexOf(progressNotesProps) && (
                  <Box className="horizontalLine" sx={{ marginY: '8px' }} />
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
};

export default ProgressNotesFaceSheet;
