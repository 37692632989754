import { Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, TooltipItem } from 'chart.js';
import { FC, useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { convertTimeDuration, formatTime } from '../../utils/dateUtil';
import { useIntl } from 'react-intl';
import { ReactComponent as StatusIcon } from '../../assets/images/statusIcon.svg';
import MediumTypography from '../formlib/MediumTypography';

ChartJS.register(ArcElement, Tooltip);

interface ChartProps {
  data: number[];
  totalWorkUsage: string;
  subTextId?: string;
  defaultLable?: string;
  redraw: boolean;
  labelIds: Label[];
}

export interface Label {
  labelId: string;
  iconColor: string;
  visible: boolean;
}

const DoughnutChart: FC<ChartProps> = ({
  data,
  totalWorkUsage,
  subTextId,
  defaultLable,
  redraw,
  labelIds,
}) => {
  const intl = useIntl();
  const totalWorkUsageRef = useRef(totalWorkUsage);
  useEffect(() => {
    totalWorkUsageRef.current = totalWorkUsage;
  }, [totalWorkUsage]);

  return (
    <Box display={'flex'} justifyContent={'space-around'} alignItems={'center'}>
      <Box width={'132px'} height={'132px'}>
        <Doughnut
          redraw={redraw}
          data={{
            labels: labelIds.map((label) => {
              return (
                label.labelId !== '' &&
                intl.formatMessage({ id: label.labelId })
              );
            }),
            datasets: [
              {
                data,
                backgroundColor: labelIds.map((label) => {
                  return label.iconColor;
                }),
                borderWidth: 0,
                type: 'doughnut',
              },
            ],
          }}
          options={{
            cutout: 50,
            plugins: {
              tooltip: {
                caretSize: 0,
                displayColors: false,
                backgroundColor: '#FFF',
                titleColor: '#37D183',
                bodyFont: {
                  size: 14,
                  family: 'Lato-Regular',
                },
                titleFont: {
                  size: 14,
                  family: 'Lato-Regular',
                },
                callbacks: {
                  title: function (tooltipItems: TooltipItem<'doughnut'>[]) {
                    if (tooltipItems[0].label === '') {
                      return '';
                    }
                    return tooltipItems[0].label;
                  },
                  label: function (tooltipItem: TooltipItem<'doughnut'>) {
                    if (tooltipItem.dataset.data[tooltipItem.dataIndex] === 0) {
                      return '';
                    }
                    return convertTimeDuration(
                      formatTime(
                        tooltipItem.dataset.data[tooltipItem.dataIndex],
                      ),
                      true,
                    );
                  },
                  labelTextColor: function () {
                    return '#2A4241';
                  },
                },
              },
            },
          }}
          plugins={[
            {
              id: 'text',
              afterDatasetDraw: function (chart) {
                const width = chart.width;
                const height = chart.height;
                const ctx = chart.ctx;
                ctx.restore();
                ctx.font = '700 20px Lato-Regular';
                ctx.textBaseline = 'middle';
                ctx.fillStyle = '#2A4241';

                const text = totalWorkUsageRef.current;
                const textX = Math.round(
                    (width - ctx.measureText(text).width) / 1.98,
                  ),
                  textY = height / 2;

                ctx.fillText(text, textX, textY);
                ctx.save();
              },
            },
            {
              id: 'subText',
              afterDatasetDraw: function (chart) {
                if (subTextId) {
                  const width = chart.width;
                  const height = chart.height;
                  const ctx = chart.ctx;

                  ctx.restore();
                  ctx.font = '12px Lato-Regular';
                  ctx.textBaseline = 'bottom';
                  ctx.fillStyle = '#97A6A5';

                  const text = intl.formatMessage({
                    id: subTextId,
                    defaultMessage: defaultLable,
                  });
                  const textX = Math.round(
                    (width - ctx.measureText(text).width) / 2,
                  );
                  const textY = height / 1.5;

                  ctx.fillText(text, textX, textY);
                  ctx.save();
                }
              },
            },
          ]}
        />
      </Box>
      <Box alignItems={'center'} sx={{ marginLeft: '8px' }}>
        {labelIds.map((label) => {
          if (label.visible) {
            return (
              <Box
                key={label.labelId}
                display={'flex'}
                alignItems={'center'}
                marginBottom={'8px'}
              >
                <StatusIcon fill={label.iconColor} />
                <MediumTypography
                  labelid={label.labelId}
                  sxProps={{ marginLeft: '4px', fontSize: '12px' }}
                />
              </Box>
            );
          }
        })}
      </Box>
    </Box>
  );
};

export default DoughnutChart;
