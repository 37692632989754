import {
  AlertColor,
  Box,
  Card,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  debounce,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import { useCallback, useContext, useEffect, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import SearchBox from '../../components/formlib/SearchBox';
import AppPagination from '../../components/shared/AppPagination';
import CaseloadEmployeesDropdown, {
  ServiceCoordinator,
} from './CaseloadEmployeesDropdown';
import CaseloadFilter from './CaseloadFilter';
import {
  CaseLoadClient,
  CaseloadListResponse,
  getCaseLoadList,
} from '../../services/configApi/codelist/caseloadlistService';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { Order } from '../referral/ReferralDashboard';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { useNavigate } from 'react-router-dom';
import { CLIENT_NAME_KEY, REFERRAL_ID_KEY } from '../../services/Constant';
import { FACESHEET } from '../../routes/Routing';
import ProductivityChart from '../activitySheet/ProductivityChart';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface TableData {
  clientName: string;
  dateOfBirth: string;
  age: string;
  referralBeginDate: string;
  assessmentHours: string;
  rollOverDate: string;
  ifspDate: string;
  transitionPlanning: string;
  transitionPlanningConference: string;
  intakeFacilitatorName: string;
  serviceCoordinatorName: string;
  ifspFirstMeetingDate: string;
  ifspSignedDate: string;
}

const tableHeadCells: HeadCell<TableData>[] = [
  {
    id: 'clientName',
    labelId: 'Dashboard.ClientName',
    defaultLabelId: 'Client Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'dateOfBirth',
    labelId: 'Clientpage.dateofbirth',
    defaultLabelId: 'Date of Birth',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'age',
    labelId: 'ageText',
    defaultLabelId: 'Age',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'referralBeginDate',
    labelId: 'Facesheet.referralDate',
    defaultLabelId: 'Referral Begin Date',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'ifspDate',
    labelId: 'Facesheet.ifspDueDate',
    defaultLabelId: 'IFSP Due Date',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'ifspFirstMeetingDate',
    labelId: 'ifspMeetingDateText',
    defaultLabelId: 'IFSP Meeting Date',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'ifspSignedDate',
    labelId: 'ifspSignedDateText',
    defaultLabelId: 'IFSP Signed Date',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'transitionPlanning',
    labelId: 'transitionPlanningText',
    defaultLabelId: 'Transition Planning',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'transitionPlanningConference',
    labelId: 'tpcText',
    defaultLabelId: 'TPC',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'intakeFacilitatorName',
    labelId: 'Facesheet.intakeFacilitator',
    defaultLabelId: 'Intake Facilitator',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'serviceCoordinatorName',
    labelId: 'Dashboard.serviceCoordinator',
    defaultLabelId: 'Service Coordinator',
    numeric: false,
    requiredSorting: true,
  },
];

const CaseLoadList = () => {
  const [caseload, setCaseload] = useState<CaseloadListResponse>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [selectedCoordinators, setSelectedCoordinators] = useState<
    ServiceCoordinator[]
  >([]);
  const navigate = useNavigate();
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState<string>();
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [staffType, setStaffType] = useState<'all' | 'myTeam' | 'others'>(
    'others',
  );
  const [employeeIds, setEmployeeIds] = useState<string | null>(
    localStorage.getItem('userId'),
  );
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [tileType, setTileType] = useState<
    'all' | 'clientsAbove27' | 'transitionCompleted'
  >('all');
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof TableData>('ifspDate');
  const [refreshCoordinatorList, setRefreshCoordinatorList] = useState<
    string | null
  >(null);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableData,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    property = property === 'age' ? 'dateOfBirth' : property;
    setOrderBy(property);
  };

  useEffect(() => {
    const getResult = async () => {
      toggleLoader(true);
      await getCaseLoadList(
        staffType,
        employeeIds !== null ? employeeIds : '',
        searchQuery,
        tileType,
        pageNumber,
        orderBy,
        order,
      )
        .then((res) => {
          toggleLoader(false);
          res.productivity = null;
          setCaseload(res);
        })
        .catch((error) => {
          toggleLoader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('failedCaseloadListMessage');
            setToastrDefaultMessage('Failed to load caseload list');
          }
        });
    };
    getResult();
  }, [
    staffType,
    employeeIds,
    searchQuery,
    tileType,
    pageNumber,
    order,
    orderBy,
  ]);

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      setPageNumber(0);
      setSearchQuery(value);
    }, 500),
    [],
  );

  const handleNavigate = async (clientDetails: CaseLoadClient) => {
    if (clientDetails.clientName !== null) {
      localStorage.setItem('ClientId', clientDetails.clientId);
      localStorage.setItem(CLIENT_NAME_KEY, clientDetails.clientName);
      localStorage.setItem(REFERRAL_ID_KEY, clientDetails.referralId);
      if (checkPermissionForFeature('backend.clients', 'viewPermission')) {
        navigate(FACESHEET);
      }
    }
  };

  return (
    <Box component="main">
      {toastrId !== undefined && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
      )}
      <Toolbar disableGutters sx={{ display: 'flex' }}>
        <TitleText
          labelid="caseloadListText"
          defaultlabel="Caseload List"
          Sxprops={{ flex: 1 }}
        />
        <CaseloadEmployeesDropdown
          filterUpdate={refreshCoordinatorList}
          onItemSelect={(item) => {
            if (item.type === 'all' || item.type === 'myTeam') {
              setEmployeeIds('');
            }
            setTileType('all');
            setStaffType(item.type);
            setSelectedCoordinators([item]);
          }}
          onItemsSelect={(items) => {
            setTileType('all');
            setStaffType('others');
            setSelectedCoordinators(items);
            setEmployeeIds(items.map((item) => item.id).join(','));
            setRefreshCoordinatorList(null);
          }}
        />
        <Box sx={{ textAlign: 'right' }}>
          <SearchBox
            labelId="Clientdashboard.searchText"
            defaultlabel="Search Client"
            onChange={(value) => handleSearchChange(value)}
            sxProps={{
              backgroundColor: '#fff',
            }}
          />
        </Box>
      </Toolbar>

      {selectedCoordinators.length > 0 && (
        <Box component={'section'} className="mb-md">
          <CaseloadFilter
            items={selectedCoordinators}
            clearAll={() => {
              setStaffType('others');
              setSelectedCoordinators([]);
              setEmployeeIds(localStorage.getItem('userId'));
              setRefreshCoordinatorList('');
            }}
            onItemDelete={(item) => {
              if (item.type === 'all' || item.type === 'myTeam') {
                setPageNumber(0);
                setStaffType('others');
                setTileType('all');
                setSelectedCoordinators([]);
                setEmployeeIds(localStorage.getItem('userId'));
                setRefreshCoordinatorList('');
              } else {
                const coordinaters = selectedCoordinators.filter(
                  (i) => i.id !== item.id,
                );
                setSelectedCoordinators(coordinaters);
                if (coordinaters.length === 0) {
                  setRefreshCoordinatorList('');
                  setStaffType('others');
                  setTileType('all');
                  setEmployeeIds(localStorage.getItem('userId'));
                } else {
                  setRefreshCoordinatorList(item.id);
                  setEmployeeIds(coordinaters.map((i) => i.id).join(','));
                }
              }
            }}
          />
        </Box>
      )}

      {caseload && (
        <Box component={'section'} display={'flex'} width={'100%'}>
          <Card
            sx={{ width: '65%', alignSelf: 'center', padding: '0px 25px' }}
            className="mr-md"
          >
            <Grid
              container
              display={'flex'}
              justifyContent={'space-around'}
              className={'mt-md mb-md'}
            >
              <Stack
                sx={{
                  bgcolor: '#ECF9F8',
                  cursor: 'pointer',
                  width: '200px',
                  height: '170px',
                  border: `1px solid ${
                    tileType === 'all' ? '#00C6B8' : '#ECF9F8'
                  }`,
                }}
                onClick={() => {
                  if (caseload.clientsCount > 0) {
                    setPageNumber(0);
                    setTileType('all');
                  }
                }}
              >
                <Box className="textCenter" sx={{ flexGrow: 1 }}>
                  <MediumTypography
                    labelid="noOfClientsAssignedText"
                    defaultlabel="No. of clients assigned"
                    sxProps={{
                      width: '100px',
                      textAlign: 'center',
                      color: '#2A4241',
                    }}
                  />
                </Box>
                <TitleText
                  label={caseload.clientsCount.toString()}
                  Sxprops={{
                    color: '#00C6B8',
                    fontSize: '48px',
                    flexGrow: 1,
                    textAlign: 'center',
                    marginRight: '16px',
                  }}
                />
              </Stack>

              <Stack
                sx={{
                  bgcolor: '#ECF9F8',
                  cursor: 'pointer',
                  width: '200px',
                  height: '170px',
                  border: `1px solid ${
                    tileType === 'clientsAbove27' ? '#00C6B8' : '#ECF9F8'
                  }`,
                }}
                onClick={() => {
                  if (caseload.clientsAbove27 > 0) {
                    setPageNumber(0);
                    setTileType('clientsAbove27');
                  }
                }}
              >
                <MediumTypography
                  labelid="clientsAgeMoreThan27Months"
                  defaultlabel="Client's age more than 27 months"
                  className="caseLoadTextCenter"
                  sxProps={{
                    flexGrow: 1,
                    textAlign: 'center',
                    color: '#2A4241',
                    display: 'grid',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
                <TitleText
                  label={caseload.clientsAbove27.toString()}
                  Sxprops={{
                    color: '#00C6B8',
                    fontSize: '48px',
                    flexGrow: 1,
                    textAlign: 'center',
                  }}
                />
              </Stack>

              <Stack
                sx={{
                  bgcolor: '#ECF9F8',
                  cursor: 'pointer',
                  width: '200px',
                  height: '170px',
                  border: `1px solid ${
                    tileType === 'transitionCompleted' ? '#00C6B8' : '#ECF9F8'
                  }`,
                }}
                onClick={() => {
                  if (caseload.transitionCompleted > 0) {
                    setPageNumber(0);
                    setTileType('transitionCompleted');
                  }
                }}
              >
                <MediumTypography
                  labelid="tpcCompletedText"
                  defaultlabel="Transition Planning Completed"
                  sxProps={{
                    flexGrow: 1,
                    textAlign: 'center',
                    color: '#2A4241',
                    display: 'grid',
                    padding: '0px 16px 0px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
                <TitleText
                  label={caseload.transitionCompleted.toString()}
                  Sxprops={{
                    color: '#00C6B8',
                    fontSize: '48px',
                    flexGrow: 1,
                    textAlign: 'center',
                  }}
                />
              </Stack>
            </Grid>
          </Card>

          <Box sx={{ width: '35%', opacity: '0.5', pointerEvents: 'none' }}>
            <ProductivityChart
              productivity={caseload.productivity}
              horizontalValue={50}
              barHeight="110px"
            />
          </Box>
        </Box>
      )}

      {caseload && (
        <Grid
          container
          display={'flex'}
          justifyContent={'space-around'}
          className={'mt-md mb-md'}
        >
          <Grid item xs={12}>
            <TableContainer className="tableStyles">
              <Table
                sx={{
                  width: '100%',
                  maxWidth: '600px',
                  display: 'inline-block',
                }}
              >
                <TableHeader
                  order={order}
                  orderBy={orderBy}
                  className="listDataTableHead"
                  headerNames={tableHeadCells}
                  checkBoxRequired={false}
                  onRequestSort={handleRequestSort}
                />
                {caseload && caseload.clients !== null && (
                  <TableBody className="tableRowcss">
                    {caseload.clients.map((client, index) => (
                      <TableRow
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                        }}
                      >
                        <TooltipTableCell
                          textSxProps={{
                            color: '#008C82',
                            cursor: checkPermissionForFeature(
                              'backend.clients',
                              'viewPermission',
                            )
                              ? 'pointer'
                              : 'default',
                            maxWidth: '200px',
                          }}
                          value={
                            client.clientName !== null &&
                            client.clientName.length > 0
                              ? client.clientName
                              : '-'
                          }
                          visible={true}
                          onClick={() => {
                            handleNavigate(client);
                          }}
                        />
                        <TableCell>
                          <MediumTypography
                            label={
                              client.dateOfBirth !== null
                                ? client.dateOfBirth
                                : '-'
                            }
                          />
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          <MediumTypography
                            label={
                              client.age !== null && client.age.length > 0
                                ? client.age
                                : '-'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <MediumTypography
                            label={
                              client.referralBeginDate !== null &&
                              client.referralBeginDate.length > 0
                                ? client.referralBeginDate
                                : '-'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <MediumTypography
                            label={
                              client.ifspDate !== null &&
                              client.ifspDate.length > 0
                                ? client.ifspDate
                                : '-'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <MediumTypography
                            label={
                              client.ifspFirstMeetingDate !== null
                                ? client.ifspFirstMeetingDate
                                : '-'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <MediumTypography
                            label={
                              client.ifspSignedDate !== null
                                ? client.ifspSignedDate
                                : '-'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <MediumTypography
                            label={
                              client.transitionPlanning !== null &&
                              client.transitionPlanning.length > 0
                                ? client.transitionPlanning
                                : '-'
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <MediumTypography
                            label={
                              client.transitionPlanningConference !== null &&
                              client.transitionPlanningConference.length > 0
                                ? client.transitionPlanningConference
                                : '-'
                            }
                          />
                        </TableCell>
                        <TooltipTableCell
                          textSxProps={{
                            maxWidth: '200px',
                          }}
                          value={
                            client.intakeFacilitatorName !== null
                              ? client.intakeFacilitatorName
                              : '-'
                          }
                          visible={true}
                        />
                        <TooltipTableCell
                          textSxProps={{
                            maxWidth: '200px',
                          }}
                          value={
                            client.serviceCoordinatorName !== null
                              ? client.serviceCoordinatorName
                              : '-'
                          }
                          visible={true}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
              {caseload &&
                caseload.clients !== null &&
                caseload.clients.length === 0 && (
                  <Box component="div" className="width__100">
                    <MediumTypography
                      textstyle={'center'}
                      labelid="noClientsFoundMessage"
                      defaultlabel="No Clients found"
                      className="p-sm"
                    />
                  </Box>
                )}
            </TableContainer>
          </Grid>
        </Grid>
      )}
      {caseload && caseload.count > 10 && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <AppPagination
            pageNumber={pageNumber}
            paginationCount={caseload.count}
            handleChangePage={(_event, newPage) => {
              if (newPage - 1 !== pageNumber) {
                setPageNumber(newPage - 1);
              }
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CaseLoadList;
