import {
  AlertColor,
  Box,
  Card,
  Dialog,
  DialogContent,
  Grid,
  Tooltip,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as GoTo } from '../../assets/images/goTo.svg';
import { ReactComponent as EditComponent } from '../../assets/images/Edit.svg';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import CustomcardComponent from '../../components/formlib/CustomCardComponent';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { BDIData, Examiner, OptionType, employeeRoles } from '../../utils/type';
import * as Yup from 'yup';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import {
  EmployeeListType,
  getAllEmployeeMinimalList,
} from '../../services/ifspClient/IfspApi';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import Textinput from '../../components/formlib/Textinput';
import { useIntl } from 'react-intl';
import {
  addAssesment,
  editAssesment,
  getEvaluationData,
  uploadTBR,
} from '../../services/configApi/BDI2/BDI2Services';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ReactComponent as TrashIcon } from '../../assets/images/trashIcon.svg';
import {
  CLINICAL_JUDGEMENT_ROUTE,
  ELIGIBILITY_DETERMINATION,
  FACESHEET,
  RISK_FACTOR,
} from '../../routes/Routing';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { useNavigate } from 'react-router';
import EmptyScreen from '../../components/shared/EmptyScreen';
import moment from 'moment';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import ModalPopup from '../../components/formlib/ModalPopup';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import { LookUpContext } from '../../context/LookUpContextProvider';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import InformationModal from '../ifsp/InformationModal';
import CloseIcon from '../../assets/images/x.svg';
import _, { debounce } from 'lodash';
import { ApiMessage, isCustomError } from '../../services/ApiResponseHandler';

const BDI3DATE = '2025-01-06';
const initialValuess: BDIData = {
  completed: false,
  notToCompleteBdi: false,
  isEligibilitySigned: false,
  isEligible: false,
  dateOfEvaluation: null,
  status: '',
  type: '',
  examiners: [
    {
      userId: '',
      role: '',
    },
  ],
  subDomainsResults: {
    selfCareScore: '',
    personalResponsibilityScore: '',
    adultInteractionScore: '',
    peerInteractionScore: '',
    selfConceptSocialRoleScore: '',
    respectiveCommunicationScore: '',
    expressiveCommunicationScore: '',
    grossMotorScore: '',
    fineMotorScore: '',
    perceptualMotorScore: '',
    attentionMemoryScore: '',
    reasoningAcademicSkillsScore: '',
    perceptionConceptsScore: '',
  },
  developmentQuotientResults: {
    adaptiveDQScore: '',
    personalSocialDQScore: '',
    communicationDQScore: '',
    motorDQScore: '',
    cognitiveDQScore: '',
  },
  developmentQuotient3Results: {
    adaptiveDQScore: '',
    personalSocialDQScore: '',
    communicationDQScore: '',
    motorDQScore: '',
    cognitiveDQScore: '',
  },
  bdiVersion: '',
};

const BDI2 = () => {
  const navigate = useNavigate();
  const rctl = useIntl();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [personalRespScoreNotApplicable, setPersonalRespScoreNotApplicable] =
    useState<boolean>(false);
  const [
    perceptualMotorScoreNotApplicable,
    setPerceptualMotorScoreNotApplicable,
  ] = useState<boolean>(false);
  const [peerInteractionNotAplicable, setPeerInteractionNotAplicable] =
    useState<boolean>(false);
  const [reasoningScoreNotApplicable, setReasoningScoreNotApplicable] =
    useState<boolean>(false);
  const formRef = useRef<FormikProps<BDIData>>(null);
  const [initialValues, setInitialValues] = useState<BDIData>(initialValuess);
  const [editData, setEditData] = useState<string>('');
  const [uploadedToTBR, setUploadedToTBR] = useState<boolean | undefined>(
    false,
  );
  const [othersContacts, setOthersContacts] = useState<OptionType[]>([]);
  const [evaluationData, setEvaluationData] = useState<BDIData[]>([]);
  const [hasInitialEvaluation, setHasInitialEvaluation] =
    useState<boolean>(false);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState<string>('');
  const [toastrId, setToastrId] = useState<string>('Client.addtoastr');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [open, setOpen] = useState<boolean>(false);
  const [editFlag, setEditFlag] = useState<boolean>(false);
  const [uploadToTBRModal, setUploadToTBRModal] = useState<boolean>(false);
  const [evaluationID, setEvaluationID] = useState<string | undefined>('');
  const { lookups } = useContext(LookUpContext);
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const [employeeList, setEmployeeList] = useState<EmployeeListType[]>([
    {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      disciplineType: '',
      roles: [],
    },
  ]);
  const [openAccordians, setOpenAccordians] = useState<number[]>([]);
  const [infoModal, setInfoModal] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [initialBDIEditData, setInitialBDIEditData] = useState<BDIData | null>(
    initialValuess,
  );

  const handleOpenInfoModal = () => {
    setInfoModal(true);
  };

  const handleCloseInfoModal = () => {
    setInfoModal(false);
  };

  const info = `These five tasks must be completed for the 'Update TBR' button to be enabled.
  
  1. Complete the intake review by adding a signature.
  2. Fill in all the fields on the 'Add New Evaluation' screen and save.
  3. If any sub-domains are not evaluated for a child, please check the 'Not Applicable' box under those sub-domains.
  4. Update all fields in the 'Risk Factor' section and save, but only if it’s a re-evaluation or exit evaluation.
  5. Complete the 'Eligibility Determination' by updating all fields and adding a signature.

  
  Note: The 'Update TBR' button will only be accessible by Program Director, Office Administrators, and Supervisors.`;

  useEffect(() => {
    toggleLoader(true);
    getAllEmployeeMinimalList()
      .then((data) => {
        setEmployeeList(data);
        toggleLoader(false);
      })
      .catch(() => {
        toggleLoader(false);
      });
  }, []);

  useEffect(() => {
    toggleLoader(true);
    getBDI2List();
  }, []);

  const toggleAccordion = (index: number) => {
    if (openAccordians.includes(index)) {
      setOpenAccordians(openAccordians.filter((item) => item !== index));
    } else {
      setOpenAccordians([...openAccordians, index]);
    }
  };

  useEffect(() => {
    setOthersContacts(() => {
      return employeeList.map((data) => {
        return {
          label: data.name ?? '',
          id: data.id,
        };
      });
    });
  }, [employeeList]);

  useEffect(() => {
    setOpenAccordians([...openAccordians, 0]);
  }, []);

  const closeFunction = () => {
    setPersonalRespScoreNotApplicable(false);
    setPerceptualMotorScoreNotApplicable(false);
    setReasoningScoreNotApplicable(false);
    setPeerInteractionNotAplicable(false);
    setModalVisible(false);
    setInitialValues(initialValuess);
    setInitialBDIEditData(initialValuess);
    formRef.current?.resetForm();
  };

  const getBDI2List = () => {
    toggleLoader(true);
    getEvaluationData()
      .then(async (response) => {
        toggleLoader(false);
        const hasInitial = response.find(
          (item) => item.type === 'INITIALEVALUATION',
        );
        setHasInitialEvaluation(hasInitial ? true : false);
        setEvaluationData(response);
        setShowEmptyScreen(response.length === 0 ? true : false);
      })
      .catch(async (error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error);
      });
  };

  const validationSchema = Yup.object().shape({
    examiners: Yup.array().of(
      Yup.object().shape({
        userId: Yup.string().required('Clinician is required'),
        role: Yup.string().required('Role is required'),
      }),
    ),
    dateOfEvaluation: Yup.string()
      .required('Evaluation date is required')
      .nullable(),
    type: Yup.string().required('Type is required').nullable(),
  });

  const typeList: OptionType[] = [];

  if (lookups && lookups.evaluationType) {
    for (const item of lookups.evaluationType) {
      typeList.push({
        id: item.code,
        label: item.description,
      });
    }
  }

  const uploadToTBR = () => {
    setUploadToTBRModal(false);
    toggleLoader(true);
    uploadTBR(evaluationID)
      .then(async (response) => {
        toggleLoader(false);
        setOpen(true);
        setToastrId(response.message);
        setToastrDefaultMessage(response.message);
        setToastrVariable('success');
        setModalVisible(false);
        toggleLoader(false);
        getBDI2List();
      })
      .catch(async (error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId('internetCheckConnectionError');
        setToastrDefaultMessage(error);
        toggleLoader(false);
      });
  };

  const allFieldsEnteredCheck = (values: BDIData) => {
    if (values.bdiVersion === 'BDI2') {
      if (
        values.developmentQuotientResults.adaptiveDQScore === '' ||
        values.developmentQuotientResults.personalSocialDQScore === '' ||
        values.developmentQuotientResults.communicationDQScore === '' ||
        values.developmentQuotientResults.motorDQScore === '' ||
        values.developmentQuotientResults.cognitiveDQScore === '' ||
        values.subDomainsResults.selfCareScore === '' ||
        values.subDomainsResults.personalResponsibilityScore === '' ||
        values.subDomainsResults.adultInteractionScore === '' ||
        values.subDomainsResults.peerInteractionScore === '' ||
        values.subDomainsResults.selfConceptSocialRoleScore === '' ||
        values.subDomainsResults.respectiveCommunicationScore === '' ||
        values.subDomainsResults.expressiveCommunicationScore === '' ||
        values.subDomainsResults.grossMotorScore === '' ||
        values.subDomainsResults.fineMotorScore === '' ||
        values.subDomainsResults.perceptualMotorScore === '' ||
        values.subDomainsResults.attentionMemoryScore === '' ||
        values.subDomainsResults.reasoningAcademicSkillsScore === '' ||
        values.subDomainsResults.perceptionConceptsScore === ''
      ) {
        return false;
      }
      return true;
    } else if (values.bdiVersion === 'BDI3') {
      if (
        values.developmentQuotient3Results.adaptiveDQScore === '' ||
        values.developmentQuotient3Results.personalSocialDQScore === '' ||
        values.developmentQuotient3Results.communicationDQScore === '' ||
        values.developmentQuotient3Results.motorDQScore === '' ||
        values.developmentQuotient3Results.cognitiveDQScore === ''
      ) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  const saveDebounce = useCallback(
    debounce((updatedValues: BDIData) => {
      addAssesment(updatedValues)
        .then(async (response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setModalVisible(false);
          toggleLoader(false);
          getBDI2List();
          formRef.current?.resetForm();
          setInitialValues(initialValuess);
        })
        .catch(async (error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            setToastrId('failed');
            setToastrDefaultMessage(error);
            setModalVisible(false);
            setInitialValues(initialValuess);
            formRef.current?.resetForm();
          } else {
            const errorMessage = error as ApiMessage;
            if (errorMessage.code === 1078) {
              handleEvalDuplicateTypeCheck();
            } else {
              setToastrId('addingEvalFailed');
              setToastrDefaultMessage('Failed to add evaluation details');
            }
          }
        });
    }, 500),
    [],
  );
  const handleSave = async () => {
    if (formRef.current) {
      setPersonalRespScoreNotApplicable(false);
      setPerceptualMotorScoreNotApplicable(false);
      setReasoningScoreNotApplicable(false);
      setPeerInteractionNotAplicable(false);
      toggleLoader(true);

      await setBDIValues();

      setInitialValues(formRef.current.values);
      const updatedValues = {
        ...formRef.current.values,
        completed: allFieldsEnteredCheck(formRef.current?.values),
      };
      saveDebounce(updatedValues);
    }
  };

  const editDebounce = useCallback(
    debounce((updatedValues: BDIData, eeditData) => {
      editAssesment(updatedValues, eeditData)
        .then(async (response) => {
          toggleLoader(false);
          setOpen(true);
          setToastrId(response.message);
          setToastrDefaultMessage(response.message);
          setToastrVariable('success');
          setModalVisible(false);
          toggleLoader(false);
          getBDI2List();
          setInitialValues(initialValuess);
          formRef.current?.resetForm();
        })
        .catch(async (error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            setToastrId('failed');
            setToastrDefaultMessage(error);
            setModalVisible(false);
            toggleLoader(false);
            formRef.current?.resetForm();
          } else {
            const errorMessage = error as ApiMessage;
            if (errorMessage.code === 1078) {
              handleEvalDuplicateTypeCheck();
            } else {
              setToastrId('editEvalFailed');
              setToastrDefaultMessage('Failed to update evaluation details');
            }
          }
        });
    }, 500),
    [],
  );
  const setBDIValues = async () => {
    if (formRef.current) {
      if (dayjs(formRef.current.values.dateOfEvaluation).isBefore(BDI3DATE)) {
        for (const score of scores3) {
          await formRef.current?.setFieldValue(score, '');
        }
        formRef.current?.setFieldValue('bdiVersion', 'BDI2');
      } else {
        for (const score of scores) {
          await formRef.current?.setFieldValue(score, '');
        }
        formRef.current?.setFieldValue('bdiVersion', 'BDI3');
      }
    }
  };

  const handleEdit = async () => {
    if (formRef.current) {
      toggleLoader(true);

      await setBDIValues();

      const updatedValues = {
        ...formRef.current.values,
        completed: allFieldsEnteredCheck(formRef.current.values),
      };
      editDebounce(updatedValues, editData);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEvalDuplicateTypeCheck = () => {
    setOpen(true);
    setToastrVariable('error');
    setToastrId('evaluationDuplicateTypeErrorMessage');
    setToastrDefaultMessage(
      'A client can have only one Initial Assessment. Select a different Evaluation Type.',
    );
  };

  const editFunction = (data: BDIData) => {
    formRef.current?.setFieldValue('completed', allFieldsEnteredCheck(data));
    setEditFlag(true);
    setModalVisible(true);
    setPersonalRespScoreNotApplicable(
      data.subDomainsResults.personalResponsibilityScore == 'Not Applicable'
        ? true
        : false,
    );
    setPerceptualMotorScoreNotApplicable(
      data.subDomainsResults.perceptualMotorScore == 'Not Applicable'
        ? true
        : false,
    );
    setPeerInteractionNotAplicable(
      data.subDomainsResults.peerInteractionScore == 'Not Applicable'
        ? true
        : false,
    );
    setReasoningScoreNotApplicable(
      data.subDomainsResults.reasoningAcademicSkillsScore == 'Not Applicable'
        ? true
        : false,
    );
    if (formRef.current) {
      formRef.current.setValues(data);
      formRef.current.setFieldValue('completed', allFieldsEnteredCheck(data));
      data.completed = allFieldsEnteredCheck(data);
      setInitialBDIEditData(data);
    }
  };

  const scores = [
    'developmentQuotientResults.adaptiveDQScore',
    'subDomainsResults.selfCareScore',
    'subDomainsResults.personalResponsibilityScore',
    'developmentQuotientResults.personalSocialDQScore',
    'subDomainsResults.adultInteractionScore',
    'subDomainsResults.peerInteractionScore',
    'subDomainsResults.selfConceptSocialRoleScore',
    'developmentQuotientResults.communicationDQScore',
    'subDomainsResults.respectiveCommunicationScore',
    'subDomainsResults.expressiveCommunicationScore',
    'developmentQuotientResults.motorDQScore',
    'subDomainsResults.grossMotorScore',
    'subDomainsResults.fineMotorScore',
    'subDomainsResults.perceptualMotorScore',
    'developmentQuotientResults.cognitiveDQScore',
    'subDomainsResults.attentionMemoryScore',
    'subDomainsResults.reasoningAcademicSkillsScore',
    'subDomainsResults.perceptionConceptsScore',
  ];

  const scores3 = [
    'developmentQuotient3Results.adaptiveDQScore',
    'developmentQuotient3Results.personalSocialDQScore',
    'developmentQuotient3Results.communicationDQScore',
    'developmentQuotient3Results.motorDQScore',
    'developmentQuotient3Results.cognitiveDQScore',
  ];

  const addNewEval = () => {
    setUploadedToTBR(false);
    formRef.current?.setFieldValue('completed', false);
    formRef.current?.setFieldValue('examiners', [
      {
        userId: '',
        role: '',
      },
    ]);
    setModalVisible(true);
    setEditFlag(false);
    setInitialValues(initialValuess);
    initialValuess.examiners = [
      {
        userId: '',
        role: '',
      },
    ];
    setInitialBDIEditData(initialValuess);
  };

  const EvaluationdateFunction = async (date: Dayjs | null | string) => {
    if (date === null || undefined || date.toString() === 'Invalid Date') {
      formRef.current?.setFieldValue('dateOfEvaluation', null);
      return;
    }
    const formattedDate = dayjs(date).format('YYYY-MM-DD');

    await formRef.current?.setFieldValue('dateOfEvaluation', formattedDate);
  };

  const checkBDI2or3 = (date: Date | null | string) => {
    if (date === null || undefined || date.toString() === 'Invalid Date') {
      return '';
    }
    const formattedDate = dayjs(date).format('YYYY-MM-DD');
    // check if on or after 01/06/2025
    if (dayjs(formattedDate).isBefore(BDI3DATE)) {
      return 'BDI2';
    } else {
      return 'BDI3';
    }
  };

  const checkInvalidBDI3 = (values: BDIData) => {
    if (values.bdiVersion === 'BDI2') return false;
    const adaptiveDQScore =
      (values.developmentQuotient3Results?.adaptiveDQScore === null ||
        values.developmentQuotient3Results?.adaptiveDQScore === '' ||
        parseInt(values.developmentQuotient3Results?.adaptiveDQScore) !== 0) &&
      (parseInt(values.developmentQuotient3Results?.adaptiveDQScore) < 40 ||
        parseInt(values.developmentQuotient3Results?.adaptiveDQScore) > 160)
        ? true
        : false;

    const personalSocialDQScore =
      ((values.developmentQuotient3Results?.personalSocialDQScore === null ||
        values.developmentQuotient3Results?.personalSocialDQScore === '' ||
        parseInt(values.developmentQuotient3Results?.personalSocialDQScore) !==
          0) &&
        parseInt(values.developmentQuotient3Results?.personalSocialDQScore) <
          40) ||
      parseInt(values.developmentQuotient3Results?.personalSocialDQScore) > 160
        ? true
        : false;

    const communicationDQScore =
      ((values.developmentQuotient3Results?.communicationDQScore === null ||
        values.developmentQuotient3Results?.communicationDQScore === '' ||
        parseInt(values.developmentQuotient3Results?.communicationDQScore) !==
          0) &&
        parseInt(values.developmentQuotient3Results?.communicationDQScore) <
          40) ||
      parseInt(values.developmentQuotient3Results?.communicationDQScore) > 160
        ? true
        : false;

    const motorDQScore =
      ((values.developmentQuotient3Results?.motorDQScore === null ||
        values.developmentQuotient3Results?.motorDQScore === '' ||
        parseInt(values.developmentQuotient3Results?.motorDQScore) !== 0) &&
        parseInt(values.developmentQuotient3Results?.motorDQScore) < 40) ||
      parseInt(values.developmentQuotient3Results?.motorDQScore) > 160
        ? true
        : false;

    const cognitiveDQScore =
      ((values.developmentQuotient3Results?.cognitiveDQScore === null ||
        values.developmentQuotient3Results?.cognitiveDQScore === '' ||
        parseInt(values.developmentQuotient3Results?.cognitiveDQScore) !== 0) &&
        parseInt(values.developmentQuotient3Results?.cognitiveDQScore) < 40) ||
      parseInt(values.developmentQuotient3Results?.cognitiveDQScore) > 160
        ? true
        : false;

    // if any one is true then return true
    return (
      adaptiveDQScore ||
      personalSocialDQScore ||
      communicationDQScore ||
      motorDQScore ||
      cognitiveDQScore
    );
  };

  const replaceTextContainsCommaAndBracket = (text: string) => {
    const updatedText = text.replaceAll('(, ', '(').replaceAll(', ,', ',');
    console.log('updatedText=', updatedText);
    return updatedText;
  };

  const eligibilityStatusCheck = (data: BDIData) => {
    if (data.isEligibilitySigned) {
      if (data.isEligible === null) {
        return '-';
      } else if (data.isEligible) {
        return 'Eligible'
          .concat(
            data.isClinicalJudgementEligible
              ? ' - 6 months'
              : data.isRiskFactorEligible
              ? ' - 1 year'
              : data.isBdiTwoEligible
              ? ' - 1 year'
              : data.isOneYearDiagnosis
              ? ' - 1 year'
              : data.isThreeYearDiagnosis
              ? ' - 3 year'
              : '',
          )
          .concat(
            replaceTextContainsCommaAndBracket(
              (data.isEligible ? ' (' : '') +
                (data.isThreeYearDiagnosis ? 'Medical Diagnosis' : '') +
                (data.isOneYearDiagnosis ? 'Medical Diagnosis' : '') +
                (data.isBdiTwoEligible ? ', BDI2' : '') +
                (data.isRiskFactorEligible ? ', Risk Factor' : '') +
                (data.isClinicalJudgementEligible
                  ? ', Clinical Judgement'
                  : '') +
                (data.isEligible ? ')' : ''),
            ),
          );
      } else {
        return 'Not Eligible';
      }
    } else {
      return '-';
    }
  };

  return (
    <Box component="main" className="mb-xlg">
      <Box
        sx={{
          zIndex: 1200, // Ensures it's above the first popup
          position: 'fixed', // Ensures it's not affected by any other content
          top: 50, // Adjust the top position to make sure it's visible
          left: '50%', // Centers it horizontally
          transform: 'translateX(-50%)', // Centers it properly
        }}
      >
        <Dialog
          open={showCancelDialog}
          keepMounted
          onClose={() => ({})}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
          maxWidth={'lg'}
        >
          <ModalPopup
            description="formUnsavedChangesMessage"
            open={showCancelDialog}
            onCancel={() => {
              setShowCancelDialog(false);
            }}
            onOk={() => {
              if (initialBDIEditData !== null) {
                const areExaminersEqual = (arr1: BDIData, arr2: BDIData) => {
                  if (arr1.examiners.length !== arr2.examiners.length)
                    return false;
                  return arr1.examiners.every((obj1, index) => {
                    const obj2 = arr2.examiners[index];
                    return (
                      obj1.userId === obj2.userId && obj1.role === obj2.role
                    );
                  });
                };

                const isEqual = areExaminersEqual(
                  initialValuess,
                  initialBDIEditData,
                );
                if (isEqual) {
                  formRef.current?.setFieldValue('examiners', [
                    {
                      userId: '',
                      role: '',
                    },
                  ]);
                  initialValuess.examiners = [
                    {
                      userId: '',
                      role: '',
                    },
                  ];
                  setInitialBDIEditData(initialValuess);
                }
                formRef.current?.setValues(initialBDIEditData);
                setPersonalRespScoreNotApplicable(
                  initialBDIEditData.subDomainsResults
                    .personalResponsibilityScore == 'Not Applicable'
                    ? true
                    : false,
                );
                setPerceptualMotorScoreNotApplicable(
                  initialBDIEditData.subDomainsResults.perceptualMotorScore ==
                    'Not Applicable'
                    ? true
                    : false,
                );
                setPeerInteractionNotAplicable(
                  initialBDIEditData.subDomainsResults.peerInteractionScore ==
                    'Not Applicable'
                    ? true
                    : false,
                );
                setReasoningScoreNotApplicable(
                  initialBDIEditData.subDomainsResults
                    .reasoningAcademicSkillsScore == 'Not Applicable'
                    ? true
                    : false,
                );
                setShowCancelDialog(false);
              }
            }}
            labelId1="Clientpage.Nobtn"
            negativeActionLabel="No"
            labelId2="Clientpage.Yesbtn"
            positiveActionLabel="Yes"
          />
        </Dialog>
      </Box>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box component="section">
        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={6} lg={6}>
              <Box className="flex__ alignItemCenter">
                <CustomBackArrow onClick={() => navigate(FACESHEET)} />
                <TitleText
                  labelid="BDI2.CreateNew"
                  defaultlabel="BDI2.CreateNew"
                  className="ml-md"
                />
              </Box>
            </Grid>
            <Grid item xs={6} lg={6}>
              <Box
                component="div"
                className="flex__ text-align-end justifyContent-FlexEnd"
              >
                {evaluationData.length > 0 &&
                  checkPermissionForFeature(
                    'backend.clients',
                    'editPermission',
                  ) && (
                    <ButtonComponent
                      className="btn-primary btn-submit ml-md"
                      variantType="contained"
                      type="submit"
                      labelId="BDI2.AddNew"
                      onClick={() => {
                        addNewEval();
                      }}
                      disabled={false}
                      defaultLabelId="Save"
                    />
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        {evaluationData.length > 0 && (
          <Card className="formCardview">
            {evaluationData.map((data, index) => {
              const labelId = data.uploadToTbr
                ? 'BDI2.UploadedToTBR'
                : 'BDI2.UploadToTBR';
              return (
                <Box
                  key={0}
                  sx={{
                    backgroundColor: '#ECF9F8',
                    padding: '16px',
                    marginBottom: '16px',
                    border: '1px solid #00C6B8',
                    borderRadius: '4px',
                  }}
                >
                  <Box className="flex__ alignItemCenter">
                    <Grid container>
                      <Grid container item lg={10}>
                        <Grid item lg={3.5}>
                          <Box className="flex__">
                            <MediumTypography
                              labelid="BDI2.Evaluation"
                              defaultlabel="Date of Evaluation"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={
                                data.dateOfEvaluation
                                  ? moment(data.dateOfEvaluation).format(
                                      'MM/DD/YYYY',
                                    )
                                  : '-'
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={4.5}>
                          <Box className="flex__ ml-xlg mr-lg">
                            <MediumTypography
                              labelid="BDI2.Type"
                              defaultlabel="Type"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={
                                (
                                  lookups &&
                                  lookups.evaluationType?.find(
                                    (obj) => obj.code === data.type,
                                  )
                                )?.description
                              }
                            />
                          </Box>
                        </Grid>
                        <Grid item lg={4}>
                          <Box className="flex__ ml-xlg mr-lg">
                            <MediumTypography
                              labelid="BDI2.Status"
                              defaultlabel="Status"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <Box className="width300px">
                              <Tooltip
                                title={eligibilityStatusCheck(data)}
                                followCursor
                                arrow={true}
                              >
                                <Box className="flex__">
                                  <MediumTypography
                                    textColor="#97A6A5"
                                    label={eligibilityStatusCheck(data)}
                                    sxProps={{
                                      fontSize: '16px',
                                      fontWeight: 500,
                                      paddingLeft: '6px',
                                      color: '#2A4241',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      whiteSpace: 'nowrap',
                                    }}
                                  />
                                </Box>
                              </Tooltip>
                            </Box>
                          </Box>
                        </Grid>

                        <Grid item lg={4} className="mt-xs">
                          <Box className="flex__">
                            <MediumTypography
                              labelid="BDI2.NotificationDate"
                              defaultlabel="Date of Notification"
                              textColor="#97A6A5"
                              sxProps={{ fontSize: '16px' }}
                            />
                            <MediumTypography
                              sxProps={{
                                fontSize: '16px',
                                fontWeight: 500,
                                paddingLeft: '6px',
                                color: '#2A4241',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                              label={
                                data.notificationDate
                                  ? dayjs(data.notificationDate).format(
                                      'MM/DD/YYYY',
                                    )
                                  : '-'
                              }
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid item lg={2}>
                        <Box
                          component="div"
                          className="flex__ text-align-end justifyContent-FlexEnd alignItemCenter"
                        >
                          {checkPermissionForFeature(
                            'backend.clients',
                            'editPermission',
                          ) && (
                            <Box className="mr-md">
                              <EditComponent
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                  editFunction(data);
                                  setEditData(
                                    data.id !== undefined ? data.id : '',
                                  );
                                  setUploadedToTBR(data.uploadToTbr);
                                }}
                              />
                            </Box>
                          )}
                          <Box>
                            {openAccordians.includes(index) ? (
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '5px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => toggleAccordion(index)}
                              >
                                <UpArrow />
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '5px',
                                  cursor: 'pointer',
                                }}
                                onClick={() => toggleAccordion(index)}
                              >
                                <DownArrow />
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {openAccordians.includes(index) && (
                    <Box>
                      <MediumTypography
                        labelid={
                          data && data.bdiVersion === 'BDI2'
                            ? 'Domain Raw Scores'
                            : 'Domain DQ Scores'
                        }
                        defaultlabel={
                          data && data.bdiVersion === 'BDI2'
                            ? 'Domain Raw Scores'
                            : 'Domain DQ Scores'
                        }
                        className="mt-sm"
                        sxProps={{
                          fontSize: '14px',
                          color: '#97A6A5',
                        }}
                      />
                      <Grid container columnSpacing={'20px'}>
                        <Grid item xs={3.5} className="pt-md">
                          {data && data.bdiVersion === 'BDI2' && (
                            <CustomcardComponent
                              headTextWithValue={`Adaptive(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotientResults &&
                                data.developmentQuotientResults.adaptiveDQScore
                              }
                              subText1={`Self Care(ScRaw):`}
                              subText1Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.selfCareScore
                              }
                              subText2={`Personal responsibility (PrRaw):`}
                              subText2Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults
                                  .personalResponsibilityScore ===
                                  'Not Applicable'
                                  ? 'N/A'
                                  : data.subDomainsResults
                                      .personalResponsibilityScore
                              }
                            />
                          )}
                          {data && data.bdiVersion === 'BDI3' && (
                            <CustomcardComponent
                              headTextWithValue={`Adaptive(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotient3Results &&
                                data.developmentQuotient3Results.adaptiveDQScore
                              }
                              onlyHeadText={true}
                            />
                          )}
                        </Grid>
                        <Grid item xs={3.5} className="pt-md">
                          {data && data.bdiVersion === 'BDI2' && (
                            <CustomcardComponent
                              headTextWithValue={`Personal-Social(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotientResults &&
                                data.developmentQuotientResults
                                  .personalSocialDQScore
                              }
                              subText1={`Adult Interaction(AiRaw):`}
                              subText1Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.adultInteractionScore
                              }
                              subText2={`Peer Interaction(PiRaw):`}
                              subText2Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.peerInteractionScore ===
                                  'Not Applicable'
                                  ? 'N/A'
                                  : data.subDomainsResults.peerInteractionScore
                              }
                              subText3={`Self Concept & Social Role (SrRaw):`}
                              subText3Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults
                                  .selfConceptSocialRoleScore
                              }
                            />
                          )}
                          {data && data.bdiVersion === 'BDI3' && (
                            <CustomcardComponent
                              headTextWithValue={`Personal-Social(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotient3Results &&
                                data.developmentQuotient3Results
                                  .personalSocialDQScore
                              }
                              onlyHeadText={true}
                            />
                          )}
                        </Grid>
                        <Grid item xs={3.5} className="pt-md">
                          {data && data.bdiVersion === 'BDI2' && (
                            <CustomcardComponent
                              headTextWithValue={`Communication(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotientResults &&
                                data.developmentQuotientResults
                                  .communicationDQScore
                              }
                              subText1={`Receptive Communication (RcRaw):`}
                              subText1Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults
                                  .respectiveCommunicationScore
                              }
                              subText2={`Expressive Communication (EcRaw):`}
                              subText2Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults
                                  .expressiveCommunicationScore
                              }
                            />
                          )}
                          {data && data.bdiVersion === 'BDI3' && (
                            <CustomcardComponent
                              headTextWithValue={`Communication(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotient3Results &&
                                data.developmentQuotient3Results
                                  .communicationDQScore
                              }
                              onlyHeadText={true}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid container columnSpacing={'20px'}>
                        <Grid item xs={3.5} className="pt-md">
                          {data && data.bdiVersion === 'BDI2' && (
                            <CustomcardComponent
                              headTextWithValue={`Motor(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotientResults &&
                                data.developmentQuotientResults.motorDQScore
                              }
                              subText1={`Gross Motor(GmRaw):`}
                              subText1Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.grossMotorScore
                              }
                              subText2={`Fine Motor(FmRaw):`}
                              subText2Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.fineMotorScore
                              }
                              subText3={`Perceptual Motor(PmRaw):`}
                              subText3Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.perceptualMotorScore ===
                                  'Not Applicable'
                                  ? 'N/A'
                                  : data.subDomainsResults.perceptualMotorScore
                              }
                            />
                          )}
                          {data && data.bdiVersion === 'BDI3' && (
                            <CustomcardComponent
                              headTextWithValue={`Motor(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotient3Results &&
                                data.developmentQuotient3Results.motorDQScore
                              }
                              onlyHeadText={true}
                            />
                          )}
                        </Grid>
                        <Grid item xs={3.5} className="pt-md">
                          {data && data.bdiVersion === 'BDI2' && (
                            <CustomcardComponent
                              headTextWithValue={`Cognitive(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotientResults &&
                                data.developmentQuotientResults.cognitiveDQScore
                              }
                              subText1={`Attention / Memory (AmRaw): `}
                              subText1Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.attentionMemoryScore
                              }
                              subText2={`Reasoning / Academic Skills (RaRaw):`}
                              subText2Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults
                                  .reasoningAcademicSkillsScore ===
                                  'Not Applicable'
                                  ? 'N/A'
                                  : data.subDomainsResults
                                      .reasoningAcademicSkillsScore
                              }
                              subText3={`Perception / Concepts (PcRaw):`}
                              subText3Value={
                                data &&
                                data.subDomainsResults &&
                                data.subDomainsResults.perceptionConceptsScore
                              }
                            />
                          )}
                          {data && data.bdiVersion === 'BDI3' && (
                            <CustomcardComponent
                              headTextWithValue={`Cognitive(DQ):`}
                              headTextWithValue2={
                                data &&
                                data.developmentQuotient3Results &&
                                data.developmentQuotient3Results
                                  .cognitiveDQScore
                              }
                              onlyHeadText={true}
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={8} className="pt-md">
                          <Box className="flex__">
                            <Box
                              className="flex__ alignItemCenter mr-xlg"
                              onClick={() =>
                                navigate(CLINICAL_JUDGEMENT_ROUTE, {
                                  state: {
                                    evaluationId: data.id,
                                    isUploadedToTBR: data.uploadToTbr,
                                  },
                                })
                              }
                            >
                              <MediumTypography
                                labelid={'clinicalJudgementTextFacesheet'}
                                defaultlabel="Clinical Judgement"
                                sxProps={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#008C82',
                                  cursor: 'pointer',
                                }}
                              />
                              <GoTo className="ml-xs" />
                            </Box>

                            <Box
                              className="flex__ alignItemCenter ml-xlg mr-xlg"
                              onClick={() =>
                                navigate(RISK_FACTOR, {
                                  state: {
                                    evaluationId: data.id,
                                    evaluationType: data.type,
                                    isUploadedToTBR: data.uploadToTbr,
                                  },
                                })
                              }
                            >
                              <MediumTypography
                                labelid={'riskFactorText'}
                                defaultlabel="Risk Factor"
                                sxProps={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#008C82',
                                  cursor: 'pointer',
                                }}
                              />
                              <GoTo className="ml-xs" />
                            </Box>

                            <Box
                              className="flex__ alignItemCenter ml-xlg"
                              onClick={() => {
                                navigate(ELIGIBILITY_DETERMINATION, {
                                  state: {
                                    evaluationId: data.id,
                                    evaluationStatus: data.status,
                                    isUploadedToTBR: data.uploadToTbr,
                                  },
                                });
                              }}
                            >
                              <MediumTypography
                                labelid={'EligibilityDeterminationText'}
                                defaultlabel="Eligibility Determination"
                                sxProps={{
                                  fontSize: '16px',
                                  fontWeight: '500',
                                  color: '#008C82',
                                  cursor: 'pointer',
                                }}
                              />
                              <GoTo className="ml-xs" />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4} className="pt-md">
                          <Box
                            component="div"
                            className="flex__ text-align-end justifyContent-FlexEnd align__items__center"
                          >
                            {data.isAllowed ? (
                              <>
                                <ButtonComponent
                                  className="btn-primary btn-submit ml-md"
                                  variantType="contained"
                                  type="submit"
                                  labelId={labelId}
                                  onClick={() => {
                                    if (!data.uploadToTbr)
                                      setUploadToTBRModal(true);
                                    setEvaluationID(data.id);
                                  }}
                                  disabled={
                                    data.uploadToTbr || !data.readyToUpload
                                  }
                                  defaultLabelId="Save"
                                />
                                <InfoIcon
                                  className="ml-xs"
                                  onClick={handleOpenInfoModal}
                                />
                              </>
                            ) : (
                              <>
                                <MediumTypography
                                  labelid={labelId}
                                  defaultlabel="Ready For TBR"
                                  fontSize="16px"
                                  fontweight={600}
                                  textColor="#2a4241"
                                />
                                <InfoIcon
                                  className="ml-xs"
                                  onClick={handleOpenInfoModal}
                                />
                              </>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              );
            })}
          </Card>
        )}

        {showEmptyScreen && (
          <EmptyScreen
            titleLabelId="BDI2.NoAssesments"
            defaultTitleText="No assesments added yet."
            buttonLabelId="BDI2.AddNew"
            defaultButtonTitleText="Add New Assessment"
            showButton={checkPermissionForFeature(
              'backend.clients',
              'editPermission',
            )}
            onButtonClick={() => addNewEval()}
          />
        )}
      </Box>

      <Box component="div">
        <Dialog
          open={modalVisible}
          keepMounted
          onClose={() => ({})}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
          maxWidth={'lg'}
        >
          <DialogContent sx={{ padding: '16px 24px' }}>
            <Box
              component="div"
              className="rowContainer2 flex__justify__space-between"
            >
              <Box component="div" sx={{ whiteSpace: 'nowrap' }}>
                <MediumTypography
                  labelid={'BDI2.AddNew'}
                  defaultlabel="Add New Evaluation"
                  fontSize="24px"
                  fontweight={600}
                  textColor="#2a4241"
                />
              </Box>
              <Box component="div" className="ml-xs">
                <img
                  src={CloseIcon}
                  alt="backarrow"
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                    top: '10%',
                  }}
                  onClick={() => {
                    if (
                      _.isEqual(initialBDIEditData, formRef.current?.values)
                    ) {
                      closeFunction();
                    } else {
                      setShowCancelDialog(true);
                    }
                  }}
                />
              </Box>
            </Box>
            <Formik
              initialValues={initialValues}
              validateOnMount={true}
              innerRef={formRef}
              enableReinitialize={true}
              onSubmit={() => (editFlag ? handleEdit() : handleSave())}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
            >
              {({ handleSubmit, setFieldValue, values, errors, touched }) => (
                <Box component="div">
                  <Box component="div" className="">
                    <Box component="div" className="mt-md mb-lg">
                      <Box component="div" className="mb-xs">
                        <CheckBoxComponent
                          defaultlabelid="Family chose not to complete the BDI"
                          labelid="Family chose not to complete the BDI"
                          ischecked={values.notToCompleteBdi}
                          disabled={uploadedToTBR}
                          CheckHandleChange={(
                            e: React.ChangeEvent<HTMLInputElement>,
                          ) => {
                            setFieldValue('notToCompleteBdi', e.target.checked);
                            const value = e.target.checked ? 0 : '';
                            scores.forEach((score) => {
                              formRef.current?.setFieldValue(score, value);
                            });
                            scores3.forEach((score) => {
                              formRef.current?.setFieldValue(score, value);
                            });
                            setPersonalRespScoreNotApplicable(false);
                            setPerceptualMotorScoreNotApplicable(false);
                            setPeerInteractionNotAplicable(false);
                            setReasoningScoreNotApplicable(false);
                          }}
                        />
                      </Box>
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3} className="mb-xlg">
                              <Grid item xs={4} className="pt-md">
                                <Box>
                                  <Box className="bg__white">
                                    <DatePickerComponent
                                      name={`dateOfEvaluation`}
                                      labelid="BDI2.EvaluationDate"
                                      disabledDate={uploadedToTBR}
                                      defaultlabelid="Date of Evaluation *"
                                      required={true}
                                      disableFuture={false}
                                      handlechange={(date: Dayjs | null) => {
                                        EvaluationdateFunction(date);
                                      }}
                                      value={
                                        values.dateOfEvaluation
                                          ? dayjs(values.dateOfEvaluation)
                                          : null
                                      }
                                    />
                                  </Box>
                                  {errors.dateOfEvaluation &&
                                  touched.dateOfEvaluation ? (
                                    <Box className="loginError">
                                      <MediumTypography
                                        labelid={errors.dateOfEvaluation}
                                        defaultlabel="Evaluation Date is required"
                                        sxProps={{
                                          color: 'red',
                                          fontSize: '14px',
                                        }}
                                      />
                                    </Box>
                                  ) : null}
                                </Box>
                              </Grid>
                              <Grid item xs={4} className="pt-md">
                                <DropdownComponent
                                  disabled={uploadedToTBR}
                                  name="type"
                                  names={typeList}
                                  labelid="Type"
                                  defaultlabelid="Type"
                                  value={values.type}
                                  handleChange={(e) => {
                                    if (
                                      e === 'INITIALEVALUATION' &&
                                      hasInitialEvaluation
                                    ) {
                                      handleEvalDuplicateTypeCheck();
                                      return;
                                    }
                                    setFieldValue('type', e);
                                  }}
                                  Required={true}
                                />
                                {errors.type && touched.type ? (
                                  <Box className="loginError">
                                    <MediumTypography
                                      labelid={errors.type}
                                      defaultlabel="Type is required"
                                      sxProps={{
                                        color: 'red',
                                        fontSize: '14px',
                                      }}
                                    />
                                  </Box>
                                ) : null}
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        className="formCardview p-sm gray3 borderRadius4"
                        sx={{
                          border: '1px solid #00C6B8',
                          background: '#ECF9F8',
                        }}
                      >
                        <Box component="div"></Box>
                        {values.examiners.map((examiner, index) => {
                          const selectedEmployee = employeeList.find(
                            (contact) => contact.id === examiner?.userId,
                          );
                          const examinerRoles =
                            selectedEmployee?.roles?.flatMap((roleString) =>
                              roleString.split(',').map((roleId) => ({
                                id: roleId.trim(),
                                label:
                                  employeeRoles.find(
                                    (empRole) => empRole.id === roleId.trim(),
                                  )?.label || '',
                              })),
                            ) || [];

                          const filteredOthersContacts = othersContacts.filter(
                            (contact) =>
                              !values.examiners.some(
                                (ex) => ex.userId === contact.id,
                              ) || examiner?.userId === contact.id,
                          );
                          return (
                            <Grid
                              container
                              spacing={3}
                              key={index}
                              className="pt-xlg"
                            >
                              <Grid item xs={4} className="pt-md">
                                <DropdownComponent
                                  disabled={uploadedToTBR}
                                  names={filteredOthersContacts}
                                  labelid="bdi.selectclinicianText"
                                  defaultlabelid="Select Clinician"
                                  Required={true}
                                  value={examiner?.userId || ''}
                                  handleChange={(e) => {
                                    setFieldValue(
                                      `examiners[${index}].userId`,
                                      e,
                                    );
                                    setInitialValues((prevValues) => {
                                      const newValues = { ...prevValues };
                                      newValues.examiners[index] = {
                                        ...newValues.examiners[index],
                                        userId: e ?? undefined,
                                      };
                                      return newValues;
                                    });

                                    setFieldValue(
                                      `examiners[${index}].role`,
                                      '',
                                    );
                                  }}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.examiners &&
                                  Array.isArray(errors.examiners) &&
                                  index < errors.examiners.length &&
                                  (
                                    errors.examiners as FormikErrors<Examiner>[]
                                  )[index]?.userId &&
                                  touched.examiners?.[index]?.userId && (
                                    <MediumTypography
                                      label={
                                        (
                                          errors.examiners as FormikErrors<Examiner>[]
                                        )[index]?.userId
                                      }
                                      sxProps={{
                                        color: 'red',
                                        fontSize: '14px',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={4} className="pt-md">
                                <DropdownComponent
                                  disabled={uploadedToTBR}
                                  names={examinerRoles}
                                  labelid="Ifsp.selectRole"
                                  defaultlabelid="Select Role"
                                  value={examiner?.role || ''}
                                  handleChange={(e) => {
                                    setFieldValue(
                                      `examiners[${index}].role`,
                                      e,
                                    );
                                    setInitialValues((prevValues) => {
                                      const newValues = { ...prevValues };
                                      newValues.examiners[index].role =
                                        e ?? undefined;
                                      return newValues;
                                    });
                                  }}
                                  Required
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.examiners &&
                                  Array.isArray(errors.examiners) &&
                                  index < errors.examiners.length &&
                                  (
                                    errors.examiners as FormikErrors<Examiner>[]
                                  )[index]?.role &&
                                  touched.examiners?.[index]?.role && (
                                    <MediumTypography
                                      label={
                                        (
                                          errors.examiners as FormikErrors<Examiner>[]
                                        )[index]?.role
                                      }
                                      sxProps={{
                                        color: 'red',
                                        fontSize: '14px',
                                      }}
                                    />
                                  )}
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                lg={2}
                                className="mt-lg pt-xs pl-md"
                              >
                                {index > 0 &&
                                  (uploadedToTBR === undefined ||
                                    uploadedToTBR === null ||
                                    !uploadedToTBR) && (
                                    <Grid item xs={6} lg={2}>
                                      <TrashIcon
                                        className="cursor__pointer"
                                        onClick={() => {
                                          setFieldValue(
                                            `examiners`,
                                            values.examiners.filter(
                                              (__, i) => i !== index,
                                            ),
                                          );
                                        }}
                                      />
                                    </Grid>
                                  )}
                              </Grid>
                            </Grid>
                          );
                        })}
                        <ButtonComponent
                          className="btn-add-code mt-md"
                          variantType="contained"
                          type="submit"
                          label={`+ ${rctl.formatMessage({
                            id: 'Add Employee',
                            defaultMessage: 'Add Employee',
                          })}`}
                          defaultLabelId="+ Add Parent"
                          onClick={() => {
                            if (
                              values.examiners.length !== employeeList.length &&
                              !uploadedToTBR
                            ) {
                              setFieldValue('examiners', [
                                ...values.examiners,
                                { userId: '', role: '' },
                              ]);
                            }
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {values.dateOfEvaluation && (
                    <MediumTypography
                      label={
                        checkBDI2or3(values.dateOfEvaluation) === 'BDI2'
                          ? 'Battelle Developmental Inventory 2'
                          : 'Battelle Developmental Inventory 3'
                      }
                      sxProps={{
                        marginTop: '10px',
                        fontWeight: 'bold',
                      }}
                    />
                  )}
                  {/*BDI2*/}
                  {checkBDI2or3(values.dateOfEvaluation) === 'BDI2' && (
                    <Box>
                      {/*Adaptive*/} {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Adaptive'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="adaptiveDQScore"
                                  labelid={'BDI2.Adaptive'}
                                  defaultlabelid="Adaptive DQ Score"
                                  Required={false}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  sxProps={{ background: 'white' }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'developmentQuotientResults.adaptiveDQScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.developmentQuotientResults
                                      .adaptiveDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="selfCareScore"
                                  labelid={'BDI2.SelfCare'}
                                  defaultlabelid="Self Care Raw Score"
                                  Required={false}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  sxProps={{ background: 'white' }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.selfCareScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={values.subDomainsResults.selfCareScore}
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="personalResponsibilityScore"
                                  labelid={'BDI2.PersonalResponsibilityScore'}
                                  defaultlabelid="Personal Responsibility Raw Score"
                                  Required={false}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  sxProps={{ background: 'white' }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.personalResponsibilityScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults
                                      .personalResponsibilityScore
                                  }
                                  disabled={
                                    uploadedToTBR ||
                                    personalRespScoreNotApplicable ||
                                    values.notToCompleteBdi
                                  }
                                />
                                <CheckBoxComponent
                                  defaultlabelid="Not Applicable"
                                  labelid="Not Applicable"
                                  disabled={values.notToCompleteBdi}
                                  ischecked={personalRespScoreNotApplicable}
                                  CheckHandleChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    setPersonalRespScoreNotApplicable(
                                      e.target.checked,
                                    );
                                    setFieldValue(
                                      'subDomainsResults.personalResponsibilityScore',
                                      e.target.checked ? 'Not Applicable' : '',
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*PersonalSocial*/}{' '}
                      {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Personal Social'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="personalSocialDQScore"
                                  labelid={'BDI2.PersonalSocialDQScore'}
                                  defaultlabelid="Personal-Social DQ Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'developmentQuotientResults.personalSocialDQScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.developmentQuotientResults
                                      .personalSocialDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="adultInteractionScore"
                                  labelid={'BDI2.AdultInteractionScore'}
                                  defaultlabelid="Adult Interaction Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.adultInteractionScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults
                                      .adultInteractionScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="peerInteractionScore"
                                  labelid={'BDI2.PeerInteractionScore'}
                                  defaultlabelid="Peer Interaction Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.peerInteractionScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults
                                      .peerInteractionScore
                                  }
                                  disabled={
                                    uploadedToTBR ||
                                    peerInteractionNotAplicable ||
                                    values.notToCompleteBdi
                                  }
                                />
                                <CheckBoxComponent
                                  defaultlabelid="Not Applicable"
                                  labelid="Not Applicable"
                                  disabled={values.notToCompleteBdi}
                                  ischecked={peerInteractionNotAplicable}
                                  CheckHandleChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    setPeerInteractionNotAplicable(
                                      e.target.checked,
                                    );
                                    setFieldValue(
                                      'subDomainsResults.peerInteractionScore',
                                      e.target.checked ? 'Not Applicable' : '',
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-xlg mt-md ">
                                <Textinput
                                  name="selfConceptSocialRoleScore"
                                  labelid={'BDI2.SelfConceptSocialRoleScore'}
                                  defaultlabelid="Self Concept & Social Role Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.selfConceptSocialRoleScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults
                                      .selfConceptSocialRoleScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*Communication*/}{' '}
                      {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Communication'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="communicationDQScore"
                                  labelid={'BDI2.communicationDQScore'}
                                  defaultlabelid="communication DQ Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'developmentQuotientResults.communicationDQScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.developmentQuotientResults
                                      .communicationDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="respectiveCommunicationScore"
                                  labelid={'BDI2.respectiveCommunicationScore'}
                                  defaultlabelid="Receptive  Communication Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.respectiveCommunicationScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults
                                      .respectiveCommunicationScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="expressiveCommunicationScore"
                                  labelid={'BDI2.ExpressiveCommunicationScore'}
                                  defaultlabelid="Expressive Communication Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.expressiveCommunicationScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults
                                      .expressiveCommunicationScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*Motor*/} {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Motor'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="motorDQScore"
                                  labelid={'BDI2.MotorDQScore'}
                                  defaultlabelid="Motor DQ Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'developmentQuotientResults.motorDQScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.developmentQuotientResults
                                      .motorDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="grossMotorScore"
                                  labelid={'BDI2.GrossMotorScore'}
                                  defaultlabelid="Gross Motor Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.grossMotorScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults.grossMotorScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>

                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="fineMotorScore"
                                  labelid={'fineMotorScore'}
                                  defaultlabelid="Fine Motor Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.fineMotorScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults.fineMotorScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-xlg mt-md">
                                <Textinput
                                  name="perceptualMotorScore"
                                  labelid={'BDI2.Perceptual Motor Score'}
                                  defaultlabelid="Perceptual Motor Raw Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'subDomainsResults.perceptualMotorScore',
                                      numericValue,
                                    );
                                  }}
                                  type="text"
                                  Value={
                                    values.subDomainsResults
                                      .perceptualMotorScore
                                  }
                                  disabled={
                                    uploadedToTBR ||
                                    perceptualMotorScoreNotApplicable ||
                                    values.notToCompleteBdi
                                  }
                                />
                                <CheckBoxComponent
                                  defaultlabelid="Not Applicable"
                                  labelid="Not Applicable"
                                  disabled={values.notToCompleteBdi}
                                  ischecked={perceptualMotorScoreNotApplicable}
                                  CheckHandleChange={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    setPerceptualMotorScoreNotApplicable(
                                      e.target.checked,
                                    );
                                    setFieldValue(
                                      'subDomainsResults.perceptualMotorScore',
                                      e.target.checked ? 'Not Applicable' : '',
                                    );
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*Cognitive*/} {/*Need to make these cards reusable*/}
                      <Box
                        className="formCardview p-sm gray3 borderRadius4"
                        sx={{
                          border: '1px solid #00C6B8',
                          background: '#ECF9F8',
                        }}
                      >
                        <MediumTypography
                          label={'Cognitive'}
                          sxProps={{
                            marginTop: '10px',
                            fontWeight: 'bold',
                          }}
                        />
                        <Box component="div" className="mt-xlg">
                          <Grid container spacing={3}>
                            <Grid item xs={4} className="pt-md">
                              <Textinput
                                name="cognitiveDQScore"
                                labelid={'BDI2.cognitiveDQScore'}
                                defaultlabelid="Cognitive DQ Score"
                                Required={false}
                                sxProps={{ background: 'white' }}
                                inputProps={{
                                  maxLength: 5,
                                }}
                                handlechange={(value) => {
                                  const numericValue = value.replace(/\D/g, '');
                                  setFieldValue(
                                    'developmentQuotientResults.cognitiveDQScore',
                                    numericValue,
                                  );
                                }}
                                type="text"
                                Value={
                                  values.developmentQuotientResults
                                    .cognitiveDQScore
                                }
                                disabled={
                                  uploadedToTBR || values.notToCompleteBdi
                                }
                              />
                            </Grid>

                            <Grid item xs={4} className="pt-md">
                              <Textinput
                                name="attentionMemoryScore"
                                labelid={'BDI2.AttentionMemoryScore'}
                                defaultlabelid="Attention & Memory Raw Score"
                                Required={false}
                                sxProps={{ background: 'white' }}
                                inputProps={{
                                  maxLength: 5,
                                }}
                                handlechange={(value) => {
                                  const numericValue = value.replace(/\D/g, '');
                                  setFieldValue(
                                    'subDomainsResults.attentionMemoryScore',
                                    numericValue,
                                  );
                                }}
                                type="text"
                                Value={
                                  values.subDomainsResults.attentionMemoryScore
                                }
                                disabled={
                                  uploadedToTBR || values.notToCompleteBdi
                                }
                              />
                            </Grid>

                            <Grid item xs={4} className="pt-md">
                              <Textinput
                                name="reasoningAcademicSkillsScore"
                                labelid={'BDI2.ReasoningAcademicSkillsScore'}
                                defaultlabelid="Reasoning & Academic Skills Raw Score"
                                Required={false}
                                sxProps={{ background: 'white' }}
                                inputProps={{
                                  maxLength: 5,
                                }}
                                handlechange={(value) => {
                                  const numericValue = value.replace(/\D/g, '');
                                  setFieldValue(
                                    'subDomainsResults.reasoningAcademicSkillsScore',
                                    numericValue,
                                  );
                                }}
                                type="text"
                                Value={
                                  values.subDomainsResults
                                    .reasoningAcademicSkillsScore
                                }
                                disabled={
                                  uploadedToTBR ||
                                  reasoningScoreNotApplicable ||
                                  values.notToCompleteBdi
                                }
                              />
                              <CheckBoxComponent
                                defaultlabelid="Not Applicable"
                                labelid="Not Applicable"
                                disabled={values.notToCompleteBdi}
                                ischecked={reasoningScoreNotApplicable}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  setReasoningScoreNotApplicable(
                                    e.target.checked,
                                  );
                                  setFieldValue(
                                    'subDomainsResults.reasoningAcademicSkillsScore',
                                    e.target.checked ? 'Not Applicable' : '',
                                  );
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid container spacing={3}>
                            <Grid item xs={4} className="pt-xlg mt-md">
                              <Textinput
                                name="perceptionConceptsScore"
                                labelid={'BDI2.PerceptionConceptsScore'}
                                defaultlabelid="Perception & Concepts Raw Score"
                                Required={false}
                                sxProps={{ background: 'white' }}
                                inputProps={{
                                  maxLength: 5,
                                }}
                                handlechange={(value) => {
                                  const numericValue = value.replace(/\D/g, '');
                                  setFieldValue(
                                    'subDomainsResults.perceptionConceptsScore',
                                    numericValue,
                                  );
                                }}
                                type="text"
                                Value={
                                  values.subDomainsResults
                                    .perceptionConceptsScore
                                }
                                disabled={
                                  uploadedToTBR || values.notToCompleteBdi
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {/*BDI3*/}
                  {checkBDI2or3(values.dateOfEvaluation) === 'BDI3' && (
                    <Box sx={{ display: 'auto' }}>
                      {/*Adaptive*/} {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Adaptive'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="adaptiveDQScore"
                                  labelid={'BDI2.Adaptive'}
                                  defaultlabelid="Adaptive DQ Score"
                                  Required={false}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  sxProps={{ background: 'white' }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'developmentQuotient3Results.adaptiveDQScore',
                                      numericValue,
                                    );
                                  }}
                                  error={
                                    values.developmentQuotient3Results
                                      ?.adaptiveDQScore !== null &&
                                    values.developmentQuotient3Results
                                      ?.adaptiveDQScore !== '' &&
                                    parseInt(
                                      values.developmentQuotient3Results
                                        ?.adaptiveDQScore,
                                    ) !== 0 &&
                                    (parseInt(
                                      values.developmentQuotient3Results
                                        ?.adaptiveDQScore,
                                    ) < 40 ||
                                      parseInt(
                                        values.developmentQuotient3Results
                                          ?.adaptiveDQScore,
                                      ) > 160)
                                      ? 'The DQ score should be between 40 and 160.'
                                      : null
                                  }
                                  type="text"
                                  Value={
                                    values.developmentQuotient3Results
                                      ?.adaptiveDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*PersonalSocial*/}{' '}
                      {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Personal Social'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="personalSocialDQScore"
                                  labelid={'BDI2.PersonalSocialDQScore'}
                                  defaultlabelid="Personal-Social DQ Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'developmentQuotient3Results.personalSocialDQScore',
                                      numericValue,
                                    );
                                  }}
                                  error={
                                    values.developmentQuotient3Results
                                      ?.personalSocialDQScore !== null &&
                                    values.developmentQuotient3Results
                                      ?.personalSocialDQScore !== '' &&
                                    parseInt(
                                      values.developmentQuotient3Results
                                        ?.personalSocialDQScore,
                                    ) !== 0 &&
                                    (parseInt(
                                      values.developmentQuotient3Results
                                        ?.personalSocialDQScore,
                                    ) < 40 ||
                                      parseInt(
                                        values.developmentQuotient3Results
                                          ?.personalSocialDQScore,
                                      ) > 160)
                                      ? 'The DQ score should be between 40 and 160.'
                                      : null
                                  }
                                  type="text"
                                  Value={
                                    values.developmentQuotient3Results
                                      ?.personalSocialDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*Communication*/}{' '}
                      {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Communication'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="communicationDQScore"
                                  labelid={'BDI2.communicationDQScore'}
                                  defaultlabelid="communication DQ Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    const numericValue = value.replace(
                                      /\D/g,
                                      '',
                                    );
                                    setFieldValue(
                                      'developmentQuotient3Results.communicationDQScore',
                                      numericValue,
                                    );
                                  }}
                                  error={
                                    values.developmentQuotient3Results
                                      ?.communicationDQScore !== null &&
                                    values.developmentQuotient3Results
                                      ?.communicationDQScore !== '' &&
                                    parseInt(
                                      values.developmentQuotient3Results
                                        ?.communicationDQScore,
                                    ) !== 0 &&
                                    (parseInt(
                                      values.developmentQuotient3Results
                                        ?.communicationDQScore,
                                    ) < 40 ||
                                      parseInt(
                                        values.developmentQuotient3Results
                                          ?.communicationDQScore,
                                      ) > 160)
                                      ? 'The DQ score should be between 40 and 160.'
                                      : null
                                  }
                                  type="text"
                                  Value={
                                    values.developmentQuotient3Results
                                      ?.communicationDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*Motor*/} {/*Need to make these cards reusable*/}
                      <Box className="mb-lg">
                        <Box
                          className="formCardview p-sm gray3 borderRadius4"
                          sx={{
                            border: '1px solid #00C6B8',
                            background: '#ECF9F8',
                          }}
                        >
                          <MediumTypography
                            label={'Motor'}
                            sxProps={{
                              marginTop: '10px',
                              fontWeight: 'bold',
                            }}
                          />
                          <Box component="div" className="mt-xlg mb-xlg">
                            <Grid container spacing={3}>
                              <Grid item xs={4} className="pt-md">
                                <Textinput
                                  name="motorDQScore"
                                  labelid={'BDI2.MotorDQScore'}
                                  defaultlabelid="Motor DQ Score"
                                  Required={false}
                                  sxProps={{ background: 'white' }}
                                  inputProps={{
                                    maxLength: 5,
                                  }}
                                  handlechange={(value) => {
                                    setFieldValue(
                                      'developmentQuotient3Results.motorDQScore',
                                      value,
                                    );
                                  }}
                                  error={
                                    values.developmentQuotient3Results
                                      ?.motorDQScore !== null &&
                                    values.developmentQuotient3Results
                                      ?.motorDQScore !== '' &&
                                    parseInt(
                                      values.developmentQuotient3Results
                                        ?.motorDQScore,
                                    ) !== 0 &&
                                    (parseInt(
                                      values.developmentQuotient3Results
                                        ?.motorDQScore,
                                    ) < 40 ||
                                      parseInt(
                                        values.developmentQuotient3Results
                                          ?.motorDQScore,
                                      ) > 160)
                                      ? 'The DQ score should be between 40 and 160.'
                                      : null
                                  }
                                  type="number"
                                  Value={
                                    values.developmentQuotient3Results
                                      ?.motorDQScore
                                  }
                                  disabled={
                                    uploadedToTBR || values.notToCompleteBdi
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      </Box>
                      {/*Cognitive*/} {/*Need to make these cards reusable*/}
                      <Box
                        className="formCardview p-sm gray3 borderRadius4"
                        sx={{
                          border: '1px solid #00C6B8',
                          background: '#ECF9F8',
                        }}
                      >
                        <MediumTypography
                          label={'Cognitive'}
                          sxProps={{
                            marginTop: '10px',
                            fontWeight: 'bold',
                          }}
                        />
                        <Box component="div" className="mt-xlg">
                          <Grid container spacing={3}>
                            <Grid item xs={4} className="pt-md">
                              <Textinput
                                name="cognitiveDQScore"
                                labelid={'BDI2.cognitiveDQScore'}
                                defaultlabelid="Cognitive DQ Score"
                                Required={false}
                                sxProps={{ background: 'white' }}
                                inputProps={{
                                  maxLength: 5,
                                }}
                                handlechange={(value) => {
                                  const numericValue = value.replace(/\D/g, '');
                                  setFieldValue(
                                    'developmentQuotient3Results.cognitiveDQScore',
                                    numericValue,
                                  );
                                }}
                                error={
                                  values.developmentQuotient3Results
                                    ?.cognitiveDQScore !== null &&
                                  values.developmentQuotient3Results
                                    ?.cognitiveDQScore !== '' &&
                                  parseInt(
                                    values.developmentQuotient3Results
                                      ?.cognitiveDQScore,
                                  ) !== 0 &&
                                  (parseInt(
                                    values.developmentQuotient3Results
                                      ?.cognitiveDQScore,
                                  ) < 40 ||
                                    parseInt(
                                      values.developmentQuotient3Results
                                        ?.cognitiveDQScore,
                                    ) > 160)
                                    ? 'The DQ score should be between 40 and 160.'
                                    : null
                                }
                                type="text"
                                Value={
                                  values.developmentQuotient3Results
                                    ?.cognitiveDQScore
                                }
                                disabled={
                                  uploadedToTBR || values.notToCompleteBdi
                                }
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  <Box>
                    <Grid
                      className="flex__ justifyContent-FlexEnd pt-lg "
                      container
                      direction="row"
                      alignItems="right"
                    >
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel btn_width_90px mr-md"
                          variantType="outlined"
                          labelId="cancelText"
                          defaultLabelId="Cancel"
                          onClick={() => {
                            if (_.isEqual(initialBDIEditData, values)) {
                              closeFunction();
                            } else {
                              setShowCancelDialog(true);
                            }
                          }}
                        />

                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          labelId={editFlag ? 'BDI2.Edit' : 'BDI2.Save'}
                          defaultLabelId="Save"
                          onClick={() => {
                            if (
                              values.type === 'INITIALEVALUATION' &&
                              hasInitialEvaluation
                            ) {
                              handleEvalDuplicateTypeCheck();
                              return;
                            }
                            handleSubmit();
                          }}
                          disabled={checkInvalidBDI3(values) || uploadedToTBR}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Formik>
          </DialogContent>
        </Dialog>
      </Box>

      {uploadToTBRModal && (
        <ModalPopup
          open={uploadToTBRModal}
          onCancel={() => {
            setUploadToTBRModal(false);
          }}
          description={'BDI2.UploadToTBRText'}
          onOk={() => uploadToTBR()}
          labelId1="Clientpage.Nobtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Yesbtn"
          positiveActionLabel="deleteText"
        />
      )}

      {infoModal && (
        <InformationModal
          open={infoModal}
          titleLabel="Information"
          titleDefaultLabel="Information"
          buttonLabel="Ok"
          buttonDefaultLabel="Ok"
          onClose={handleCloseInfoModal}
          info={info}
        />
      )}
    </Box>
  );
};

export default BDI2;
