import { Box, SxProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import ButtonComponent from './ButtonComponent';

export interface TextProps {
  label?: string;
  labelId?: string;
  defaultLabel?: string;
  sxProps?: SxProps;
  LeftIcon?: ReactNode;
  RightIcon?: ReactNode;
  onClick?: () => void;
  className?: string;
}

const TextWithIcon: FC<TextProps> = ({
  defaultLabel,
  label,
  labelId,
  LeftIcon,
  RightIcon,
  sxProps,
  onClick,
  className,
}) => {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <ButtonComponent
        className={className}
        LeftIcon={LeftIcon}
        RightIcon={RightIcon}
        onClick={onClick}
        label={label}
        labelId={label ? undefined : labelId}
        defaultLabelId={label ? undefined : defaultLabel}
        sxProps={{ textTransform: 'none', ...sxProps }}
      />
    </Box>
  );
};

export default TextWithIcon;
