import { IfspContextDataType } from '../../pages/ifsp/ifspContextApi/IfspContex';
import { ApiResponseHandler } from '../ApiResponseHandler';
import { THOM_BASE_URL } from '../Constant';
import ThomApiClient from '../ThomApiClient';

export interface EmployeeListType {
  id: string;
  name: string | null;
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phone: string | null;
  disciplineType: string | null;
  roles: string[] | null;
}

export const getAllEmployeeMinimalList = async (): Promise<
  EmployeeListType[]
> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EmployeeListType[]>(`users/list`),
  );
};

export const getReportingToList = async (): Promise<EmployeeListType[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EmployeeListType[]>(`users/list`),
  );
};

export const getIfspById = async (id: string) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<IfspContextDataType>(
      `ifsp?clientId=${id}`,
    ),
  );
};

export const createIfsp = async (
  data: IfspContextDataType,
  clientId: string,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(`ifsp?clientId=${clientId}`, data),
  );
};

export const downloadIfsp = async (
  clientId: string,
  localTimezone: string,
): Promise<Blob> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Blob>(
      `ifsp/download?clientId=${clientId}&timezone=${localTimezone}`,
      {},
      {
        baseURL: THOM_BASE_URL,
        responseType: 'blob',
      },
    ),
  );
};
