import { Box } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { LEAQuestion } from './type';
import { FC } from 'react';
import Textinput from '../../components/formlib/Textinput';
import { ReactComponent as DeleteAlertIcon } from '../../assets/images/deleteAlertIcon.svg';

export interface QuestionItemProps {
  question: LEAQuestion;
  index: number;
  onAnswerChange: (question: LEAQuestion) => void;
  onDelete: (id: string) => void;
}
const QuestionItem: FC<QuestionItemProps> = ({
  question,
  index,
  onAnswerChange,
  onDelete,
}) => {
  return (
    <Box>
      <Box className={'flex__'}>
        <MediumTypography label={`${index + 1}. `} />
        <MediumTypography label={question.question} sxProps={{ ml: 1 }} />
      </Box>
      <Box className={'flex__ align__items__center'}>
        <Textinput
          labelid="enterAnswerText"
          defaultlabelid="Enter Answer"
          className="mr-md mt-xs"
          Value={question.answer}
          inputProps={{
            maxLength: 500,
          }}
          handlechange={(e) => onAnswerChange({ ...question, answer: e })}
        />
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            onDelete(question.id);
          }}
        >
          <DeleteAlertIcon />
        </Box>
      </Box>
    </Box>
  );
};

export default QuestionItem;
