import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import { ReactComponent as DownArrow } from '../../assets/images/downarrow.svg';

type YearViewSelectorProps = {
  value: string | number;
  onChange: (value: string | number) => void;
  height?: string | number;
  width?: string | number;
};

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      scroll: 'hidden',
      maxWidth: 'min-content',
    },
  },
};
const YearViewSelector = ({
  value,
  onChange,
  height,
  width,
}: YearViewSelectorProps) => {
  const currentYear = new Date().getFullYear();
  const years: { id: string; label: string }[] = [];

  for (let i = 0; i < 10; i++) {
    years.push({
      id: (currentYear - i).toString(),
      label: (currentYear - i).toString(),
    });
  }

  const [open, setOpen] = useState(false);

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    onChange(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box component="div">
      <Box
        className="flex__ justifyContent-Center align__items__center"
        gap={0.3}
        component="div"
      >
        <FormControl
          sx={{
            height: height ? height : '32px',
            width: width ? width : '80px',
          }}
        >
          <Select
            MenuProps={MenuProps}
            IconComponent={() => null}
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              backgroundColor: '#fff',
              height: height ? height : '32px',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              borderTopRightRadius: '0px',
              borderBottomRightRadius: '0px',
              border: 'none',
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={value}
            onChange={handleChange}
          >
            {years.map((year) => (
              <MenuItem
                key={year.id}
                value={year.id}
                defaultValue={year.id}
                sx={{
                  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                  fontSize: '12px',
                  fontWeight: '500',
                  color: '##2A4241',
                }}
                className="pt-sm pb-sm"
              >
                {year.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box
          className="flex__ align__items__center pl-sm pr-sm"
          component="div"
          sx={{
            backgroundColor: '#ffffff',
            cursor: 'pointer',
            height: height ? height : '32px',
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
          }}
          onClick={handleOpen}
        >
          <DownArrow />
        </Box>
      </Box>
    </Box>
  );
};

export default YearViewSelector;
