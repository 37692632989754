// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Text-style {
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #2a4241;
}
.logo {
  height: 64px;
  padding: 13px;
}
.input-field {
  height: 48px;
  max-width: 400px;
  left: 0px;
  top: 18px;
  border-radius: 4px;
}
.addnewclient {
  font-family: 'Lato-Regular', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: #2a4241;
  margin-left: 16px;
  word-break: break-all;
}
`, "",{"version":3,"sources":["webpack://./src/layouts/header/DashboardHeadercomponent.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,SAAS;EACT,SAAS;EACT,kBAAkB;AACpB;AACA;EACE,uCAAuC;EACvC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,iBAAiB;EACjB,qBAAqB;AACvB","sourcesContent":[".Text-style {\n  font-family: 'Lato-Regular', sans-serif;\n  font-style: normal;\n  font-weight: 400;\n  font-size: 14px;\n  line-height: 20px;\n  color: #2a4241;\n}\n.logo {\n  height: 64px;\n  padding: 13px;\n}\n.input-field {\n  height: 48px;\n  max-width: 400px;\n  left: 0px;\n  top: 18px;\n  border-radius: 4px;\n}\n.addnewclient {\n  font-family: 'Lato-Regular', sans-serif;\n  font-style: normal;\n  font-weight: 700;\n  font-size: 24px;\n  line-height: 28px;\n  color: #2a4241;\n  margin-left: 16px;\n  word-break: break-all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
