// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table_padding > table > thead > tr > th:first-child{
    padding-left: 20px;
}
.table_padding > table > tbody > tr > td:first-child{
     padding-left: 22px;
}
.table_padding > table > tbody > tr > td:last-child{
    padding-left: 0px;
}`, "",{"version":3,"sources":["webpack://./src/pages/clientFiles/Clientfiles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;AACA;KACK,kBAAkB;AACvB;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".table_padding > table > thead > tr > th:first-child{\n    padding-left: 20px;\n}\n.table_padding > table > tbody > tr > td:first-child{\n     padding-left: 22px;\n}\n.table_padding > table > tbody > tr > td:last-child{\n    padding-left: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
