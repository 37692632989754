import React from 'react';
import { SxProps, Tooltip, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Titletypes {
  textColor?: string;
  label?: string | null;
  marginstyle?: string;
  labelid?: string;
  defaultlabel?: string;
  Sxprops?: SxProps;
  className?: string;
}
const TitleText: React.FC<Titletypes> = ({
  label,
  textColor,
  marginstyle,
  labelid,
  defaultlabel,
  Sxprops,
  className,
}) => {
  return (
    <Tooltip title={label} arrow={true} followCursor placement="bottom">
      <Typography
        className={className}
        sx={{
          fontFamily: 'Lato-Regular',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '24px',
          lineHeight: '26px',
          alignItems: 'center',
          color: textColor,
          margin: marginstyle,
          ...Sxprops,
        }}
      >
        {label}
        {labelid && (
          <FormattedMessage id={labelid} defaultMessage={defaultlabel} />
        )}
      </Typography>
    </Tooltip>
  );
};

export default TitleText;
