import dayjs from 'dayjs';
import { MyTimeOffType } from '../../pages/profile/MyTimeOff';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export interface TimeOffData {
  id?: string;
  typeId: string;
  durationType: string;
  startDate: string | null;
  endDate?: string | null;
  reason: string;
  leaveName: string;
}

export const addEmployeeMyTimeOff = async (
  employeeId: string,
  requestBody: TimeOffData,
  fiscalStartTime: string,
  fiscalEndTime: string,
): Promise<TimeOffData> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `employee/timeOff?employeeId=${employeeId}&timezone=${timezone}&fiscalStartTime=${fiscalStartTime}&fiscalEndTime=${fiscalEndTime}&zoneName=${zoneName}`,
      requestBody,
    ),
  );
};

export const getEmployeeMyTimeOff = async (
  employeeId: string,
  pageNo: number,
  fiscalStartTime: string,
  fiscalEndTime: string,
  isAvailableHrs: boolean,
  order: string,
  orderBy: string,
): Promise<MyTimeOffType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `employee/timeOff?employeeId=${employeeId}&page=${pageNo}&size=5&sort=${orderBy}&sortType=${order.toUpperCase()}&fiscalStartTime=${fiscalStartTime}&fiscalEndTime=${fiscalEndTime}&isAvailableHrs=${isAvailableHrs}`,
    ),
  );
};

export const updateEmployeeMyTimeOff = async (
  employeeId: string,
  requestBody: TimeOffData,
  fiscalStartTime: string,
  fiscalEndTime: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `employee/timeOff?employeeId=${employeeId}&timezone=${timezone}&fiscalStartTime=${fiscalStartTime}&fiscalEndTime=${fiscalEndTime}&zoneName=${zoneName}`,
      requestBody,
    ),
  );
};

export const deleteEmployeeMyTimeOff = async (
  timeOffId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(`employee/timeOff/${timeOffId}`),
  );
};
