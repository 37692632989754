import { SxProps, Typography } from '@mui/material';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

type TypoValues = {
  labelId?: string;
  defaultLabelId?: string;
  text?: string;
  sxProps?: SxProps;
};

const SmallTypography: FC<TypoValues> = ({
  text,
  labelId,
  defaultLabelId,
  sxProps,
}) => {
  return (
    <div>
      <Typography
        sx={{
          fontFamily: 'Lato-Regular',
          fontSize: 'smaller',
          margin: '5px',
          ...sxProps,
        }}
      >
        {text ?? (
          <FormattedMessage id={labelId} defaultMessage={defaultLabelId} />
        )}
      </Typography>
    </div>
  );
};

export default SmallTypography;
