import { Box, Tooltip } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC } from 'react';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import moment from 'moment';

export type ClientDetailsType = {
  name: string;
  sex: string;
  dob: string;
  dphId: string;
  referralBeginDate: string;
  doe: string;
  serviceCoordinator: {
    name: string;
    email: string;
    phone: string;
  };
  teamLeader?: string;
  programDirector?: string;
};

interface ClientDetailsData {
  clientDetails: ClientDetailsType;
  nameLabelid: string;
  nameDefaultlabel: string;
  sexLabelid: string;
  sexDefaultlabel: string;
  dobLabelid: string;
  dobDefaultlabel: string;
  dphIdLabelid: string;
  dphIdDefaultlabel: string;
  referralDateLabelid: string;
  referralDateDefaultlabel: string;
  dateOfEligibilityLabelid: string;
  dateOfEligibilityDefaultlabel: string;
  serviceCoordinatorLabelid: string;
  serviceCoordinatorDefaultlabel: string;
  serviceCoordinatorEmailLabelid: string;
  serviceCoordinatorEmailDefaultlabel: string;
  serviceCoordinatorPhoneLabelid: string;
  serviceCoordinatorPhoneDefaultlabel: string;
  teamLeaderLabelid: string;
  teamLeaderDefaultlabel: string;
}
const ClientDetails: FC<ClientDetailsData> = ({
  clientDetails,
  nameLabelid,
  nameDefaultlabel,
  sexLabelid,
  sexDefaultlabel,
  dobLabelid,
  dobDefaultlabel,
  dphIdLabelid,
  dphIdDefaultlabel,
  referralDateLabelid,
  referralDateDefaultlabel,
  dateOfEligibilityLabelid,
  dateOfEligibilityDefaultlabel,
  serviceCoordinatorLabelid,
  serviceCoordinatorDefaultlabel,
  teamLeaderLabelid,
  teamLeaderDefaultlabel,
  serviceCoordinatorEmailLabelid,
  serviceCoordinatorEmailDefaultlabel,
  serviceCoordinatorPhoneLabelid,
  serviceCoordinatorPhoneDefaultlabel,
}) => {
  return (
    <Box component="div">
      <Box className=" pt-md " sx={{ borderBottom: '1px solid #CFDEDE' }}>
        <Box className="flex__ pb-md ">
          <Box className="flex__ ">
            <MediumTypography
              labelid={nameLabelid}
              defaultlabel={nameDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />

            <Tooltip
              title={
                clientDetails.name && clientDetails.name.length > 10
                  ? `${clientDetails.name}`
                  : ''
              }
              followCursor
              arrow={true}
            >
              <Box className="width150px ">
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  sxProps={{
                    paddingLeft:
                      clientDetails.name === '' || clientDetails.name === null
                        ? '24px'
                        : '6px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={
                    clientDetails.name === '' || clientDetails.name === null
                      ? '-'
                      : `${clientDetails.name}`
                  }
                />
              </Box>
            </Tooltip>
          </Box>
          <Box className="flex__">
            <MediumTypography
              className="ml-sm"
              labelid={sexLabelid}
              defaultlabel={sexDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <MediumTypography
              fontSize="16px"
              textColor="#2A4241"
              className="pr-lg"
              sxProps={{
                paddingLeft:
                  clientDetails.sex === '' || clientDetails.sex === null
                    ? '24px'
                    : '6px',
              }}
              label={
                clientDetails.sex === '' || clientDetails.sex === null
                  ? '-'
                  : convertGenderToFrontend(clientDetails.sex)
              }
            />
          </Box>
          <Box className="flex__">
            <MediumTypography
              labelid={dphIdLabelid}
              defaultlabel={dphIdDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <MediumTypography
              fontSize="16px"
              textColor="#2A4241"
              className="pr-lg"
              sxProps={{
                paddingLeft:
                  clientDetails.dphId === '' || clientDetails.dphId === null
                    ? '24px'
                    : '6px',
              }}
              label={
                clientDetails.dphId === '' || clientDetails.dphId === null
                  ? '-'
                  : clientDetails.dphId
              }
            />
          </Box>
          <Box className="flex__">
            <MediumTypography
              labelid={dobLabelid}
              defaultlabel={dobDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <MediumTypography
              fontSize="16px"
              textColor="#2A4241"
              className="pl-xs pr-lg"
              label={
                clientDetails.dob === '' || clientDetails.dob === null
                  ? '-'
                  : clientDetails.dob
              }
            />
          </Box>
          <Box className="flex__">
            <MediumTypography
              labelid={referralDateLabelid}
              defaultlabel={referralDateDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <MediumTypography
              fontSize="16px"
              textColor="#2A4241"
              className="pl-xs pr-lg"
              label={
                clientDetails.referralBeginDate === '' ||
                clientDetails.referralBeginDate === null
                  ? '-'
                  : clientDetails.referralBeginDate
              }
            />
          </Box>
        </Box>
        <Box className="flex__ pb-md">
          <Box className="flex__ ">
            <MediumTypography
              labelid={dateOfEligibilityLabelid}
              defaultlabel={dateOfEligibilityDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <MediumTypography
              fontSize="16px"
              textColor="#2A4241"
              className="pr-lg"
              sxProps={{
                paddingLeft:
                  clientDetails.doe === '' || clientDetails.doe === null
                    ? '24px'
                    : '6px',
              }}
              label={
                clientDetails.doe === '' || clientDetails.doe === null
                  ? '-'
                  : moment(clientDetails.doe).local().format('MM/DD/YYYY')
              }
            />
          </Box>

          <Box className="flex__ ">
            <MediumTypography
              labelid={teamLeaderLabelid}
              defaultlabel={teamLeaderDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />

            <Tooltip
              title={
                clientDetails.teamLeader && clientDetails.teamLeader.length > 10
                  ? `${clientDetails.teamLeader}`
                  : ''
              }
              followCursor
              arrow={true}
            >
              <Box className="width150px ">
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  sxProps={{
                    paddingLeft:
                      clientDetails.teamLeader === '' ||
                      clientDetails.teamLeader === null ||
                      clientDetails.teamLeader === undefined
                        ? '24px'
                        : '6px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={
                    clientDetails.teamLeader === '' ||
                    clientDetails.teamLeader === null ||
                    clientDetails.teamLeader === undefined
                      ? '-'
                      : `${clientDetails.teamLeader}`
                  }
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
        <Box className="flex__ pb-md">
          <Box className="flex__">
            <MediumTypography
              labelid={serviceCoordinatorLabelid}
              defaultlabel={serviceCoordinatorDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <Tooltip
              title={
                clientDetails.serviceCoordinator.name &&
                clientDetails.serviceCoordinator.name.length > 10
                  ? `${clientDetails.serviceCoordinator.name}`
                  : ''
              }
              followCursor
              arrow={true}
            >
              <Box className="width200px ">
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  className="pr-lg"
                  sxProps={{
                    paddingLeft:
                      clientDetails.serviceCoordinator.name === '' ||
                      clientDetails.serviceCoordinator.name === null
                        ? '24px'
                        : '6px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={
                    clientDetails.serviceCoordinator.name === '' ||
                    clientDetails.serviceCoordinator.name === null
                      ? '-'
                      : `${clientDetails.serviceCoordinator.name}`
                  }
                />
              </Box>
            </Tooltip>
          </Box>
          <Box className="flex__">
            <MediumTypography
              labelid={serviceCoordinatorEmailLabelid}
              defaultlabel={serviceCoordinatorEmailDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <Tooltip
              title={
                clientDetails.serviceCoordinator.email &&
                clientDetails.serviceCoordinator.email.length > 10
                  ? `${clientDetails.serviceCoordinator.email}`
                  : ''
              }
              followCursor
              arrow={true}
            >
              <Box className="width200px ">
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  className="pr-lg"
                  sxProps={{
                    paddingLeft:
                      clientDetails.serviceCoordinator.email === '' ||
                      clientDetails.serviceCoordinator.email === null
                        ? '24px'
                        : '6px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={
                    clientDetails.serviceCoordinator.email === '' ||
                    clientDetails.serviceCoordinator.email === null
                      ? '-'
                      : `${clientDetails.serviceCoordinator.email}`
                  }
                />
              </Box>
            </Tooltip>
          </Box>
          <Box className="flex__">
            <MediumTypography
              labelid={serviceCoordinatorPhoneLabelid}
              defaultlabel={serviceCoordinatorPhoneDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />
            <Tooltip
              title={
                clientDetails.serviceCoordinator.phone &&
                clientDetails.serviceCoordinator.phone.length > 10
                  ? `${clientDetails.serviceCoordinator.phone}`
                  : ''
              }
              followCursor
              arrow={true}
            >
              <Box className="width150px ">
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  className="pr-lg"
                  sxProps={{
                    paddingLeft:
                      clientDetails.serviceCoordinator.phone === '' ||
                      clientDetails.serviceCoordinator.phone === null
                        ? '24px'
                        : '6px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={
                    clientDetails.serviceCoordinator.phone === '' ||
                    clientDetails.serviceCoordinator.phone === null
                      ? '-'
                      : `${clientDetails.serviceCoordinator.phone}`
                  }
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientDetails;
