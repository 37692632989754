import { HeadCell } from '../../components/formlib/TableHeader';
import { EmployeeType } from '../../utils/type';
import {
  HourlyNonExemptEmployeeHeader,
  SalariedNonExemptEmployeeHeader,
  SalariedEmployeeHeader,
} from './ActivityListTableView';
import { Productivity } from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { ReviewEmployeeHeader } from './types';
import { Label } from '../../components/charts/DoughnutChart';
import dayjs from 'dayjs';

export const FilterStatuses: string[] = [
  'submitted',
  'notSubmitted',
  'approved',
  'rejected',
];

export const workingLabelIds: Label[] = [
  { labelId: 'billableHrsText', iconColor: '#5573FF', visible: true },
  { labelId: 'anticipatedHoursText', iconColor: '#22DFA6', visible: true },
];

export const nonWorkingLabelIds: Label[] = [
  { labelId: 'etHoursText', iconColor: '#22DFA6', visible: true },
  { labelId: 'ltiHoursText', iconColor: '#FFCA42', visible: true },
  { labelId: 'holidayTimeText', iconColor: '#F96300', visible: true },
  { labelId: 'vacationHoursText', iconColor: '#37D183', visible: true },
  { labelId: 'profHoursText', iconColor: '#5573FF', visible: true },
  { labelId: 'otherHoursText', iconColor: '#FF8354', visible: true },
  { labelId: '', iconColor: '#D9D9D9', visible: false },
];

export const getReviewActivitySheetHeaders = () => {
  const reviewActivityEmployeesHeaderData: HeadCell<ReviewEmployeeHeader>[] = [
    {
      id: 'firstName',
      labelId: 'employeeNameText',
      defaultLabelId: 'Employee Name',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'employeeType',
      labelId: 'employeetypeText',
      defaultLabelId: 'Employee Type',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'billableHours',
      labelId: 'billableHrsText',
      defaultLabelId: 'Billable Hrs',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'hoursOut',
      labelId: 'hoursOutText',
      defaultLabelId: 'Hours Out',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'totalHours',
      labelId: 'totalHrsText',
      defaultLabelId: 'Total Hours',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'approvedNonBillableHours',
      labelId: 'approvedNonBillableHrsText',
      defaultLabelId: 'Approved Non-Billable Hrs',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'productivity',
      labelId: 'productivityText',
      defaultLabelId: 'Productivity',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'status',
      labelId: 'statusText',
      defaultLabelId: 'Status',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'actions',
      labelId: 'actions',
      defaultLabelId: 'Actions',
      numeric: false,
      requiredSorting: false,
    },
  ];
  return reviewActivityEmployeesHeaderData;
};

export const getHeaderValues = (employeeType: EmployeeType) => {
  let headerValues:
    | HeadCell<HourlyNonExemptEmployeeHeader>[]
    | HeadCell<SalariedNonExemptEmployeeHeader>[]
    | HeadCell<SalariedEmployeeHeader>[] = [];
  switch (employeeType) {
    case 'Hourly Non-Exempt':
      {
        const hourlyNonExemptHeaderData: HeadCell<HourlyNonExemptEmployeeHeader>[] =
          [
            {
              id: 'day',
              labelId: 'dayText',
              defaultLabelId: 'Day',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'billableHrs',
              labelId: 'billableHrsText',
              defaultLabelId: 'Billable Hrs',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'approvedNonBillingHrs',
              labelId: 'approvedNonBillableHrsText',
              defaultLabelId: 'Approved Non-Billable Hrs',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'noShowTravelWaitDoc',
              labelId: 'noShowText',
              defaultLabelId: 'No Show, Wait, Travel, Doc',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'sickHrs',
              labelId: 'sickHrsText',
              defaultLabelId: 'Sick Hrs',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'totalHrs',
              labelId: 'totalHrsText',
              defaultLabelId: 'Non-Bill Hrs',
              numeric: false,
              requiredSorting: false,
            },
          ];
        headerValues = hourlyNonExemptHeaderData;
      }
      return headerValues;
    case 'Salaried Non-Exempt':
      {
        const salariedNonExemptHeaderData: HeadCell<SalariedNonExemptEmployeeHeader>[] =
          [
            {
              id: 'day',
              labelId: 'dayText',
              defaultLabelId: 'Day',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'billableHrs',
              labelId: 'billableHrsText',
              defaultLabelId: 'Billable Hrs',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'approvedNonBillingHrs',
              labelId: 'approvedNonBillableHrsText',
              defaultLabelId: 'Approved Non-Billable Hrs',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'sickHrs',
              labelId: 'sickHrsText',
              defaultLabelId: 'Sick Hrs',
              numeric: false,
              requiredSorting: false,
            },
            {
              id: 'totalHrs',
              labelId: 'totalHrsText',
              defaultLabelId: 'Non-Bill Hrs',
              numeric: false,
              requiredSorting: false,
            },
          ];
        headerValues = salariedNonExemptHeaderData;
      }
      return headerValues;
    case 'Salaried Exempt':
      {
        const salariedHeaderData: HeadCell<SalariedEmployeeHeader>[] = [
          {
            id: 'day',
            labelId: 'dayText',
            defaultLabelId: 'Day',
            numeric: false,
            requiredSorting: false,
          },
          {
            id: 'billableHrs',
            labelId: 'billableHrsText',
            defaultLabelId: 'Billable Hrs',
            numeric: false,
            requiredSorting: false,
          },
          {
            id: 'approvedNonBillingHrs',
            labelId: 'apvdNonBillableHrsText',
            defaultLabelId: 'Apvd Non-Billable Hrs',
            numeric: false,
            requiredSorting: false,
          },
          {
            id: 'sickHrs',
            labelId: 'sickHrsText',
            defaultLabelId: 'Sick Hrs',
            numeric: false,
            requiredSorting: false,
          },
          {
            id: 'hrsOut',
            labelId: 'hoursOutText',
            defaultLabelId: 'Hours Out',
            numeric: false,
            requiredSorting: false,
          },
          {
            id: 'totalHrs',
            labelId: 'totalHrsText',
            defaultLabelId: 'Total Hrs',
            numeric: false,
            requiredSorting: false,
          },
        ];
        headerValues = salariedHeaderData;
      }
      return headerValues;

    default:
      return headerValues;
  }
};

export const productivityData = (productivity: Productivity[] | null) => {
  const data: string[] = [];
  if (productivity !== null && productivity.length > 0) {
    productivity.forEach((week) => {
      data.push(week.percent);
    });
  }
  return data;
};
export const productivityLabels = (productivity: Productivity[] | null) => {
  if (productivity !== null && productivity.length > 0) {
    const output = productivity.map((week) => {
      const start = dayjs(week.weekStart, 'MM/DD/YYYY').format('MM/DD');
      const end = dayjs(week.weekEnd, 'MM/DD/YYYY').format('MM/DD');
      return `${start} - ${end}`;
    });
    return output;
  }
  return [];
};

export const getBackgroundColors = (productivity: Productivity[] | null) => {
  const colors: string[] = ['#008C82', '#008C82', '#008C82', '#008C82'];
  if (productivity !== null && productivity.length > 0) {
    const lastProductivity = productivity[productivity.length - 1];
    if (
      dayjs().isAfter(dayjs(lastProductivity.weekStart, 'MM/DD/YYYY')) &&
      dayjs().isBefore(dayjs(lastProductivity.weekEnd, 'MM/DD/YYYY'))
    ) {
      colors.push('#BB6BD9');
    } else {
      if (dayjs().format('MM/DD/YYYY') === lastProductivity.weekEnd) {
        colors.push('#BB6BD9');
      } else {
        colors.push('#008C82');
      }
    }
  }
  return colors;
};
