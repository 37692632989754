import {
  Box,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  DialogTitle,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import { FC, memo, useEffect, useState } from 'react';
import MediumTypography from '../MediumTypography';
import './FilePreviewDialog.css';
import print from 'print-js';
import { extractFileExtension } from '../../../utils/fileUtils';
import DocViewer, {
  DocViewerRenderers,
  IDocument,
} from '@cyntler/react-doc-viewer';
import '@cyntler/react-doc-viewer/dist/index.css';

export interface FilePreviewProps {
  url: string;
  fileName?: string;
  onClose: () => void;
}

interface DocViewerWrapperProps {
  documentUrl: string;
}

const DocViewerWrapper: React.FC<DocViewerWrapperProps> = memo(
  ({ documentUrl }) => {
    const [docs, setDocs] = useState<IDocument[]>([]);

    useEffect(() => {
      const newArray = documentUrl.split('?');
      const fileType = newArray[0].substring(newArray[0].lastIndexOf('.') + 1);

      setDocs([
        {
          uri: documentUrl,
          fileType: !documentUrl.includes('blob:http') ? fileType : '',
        },
      ]);
    }, []);

    return (
      <DocViewer
        pluginRenderers={DocViewerRenderers}
        documents={docs}
        style={{ width: '100%' }}
        onDocumentChange={() => null}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
        }}
      />
    );
  },
);

const FilePreviewDialog: FC<FilePreviewProps> = ({
  url,
  fileName,
  onClose,
}) => {
  const handleDownload = () => {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = url;
    if (fileName) {
      link.download = fileName;
    }
    link.target = '_blank';
    link.click();
  };

  const handlePdfPreview = () => {
    if (url.includes('blob:http')) {
      print(url);
    } else {
      const urlArray = url.split('?');
      if (urlArray.length > 0) {
        print(urlArray[0]);
      }
    }
  };

  return (
    <Dialog
      className="imagePreviewModal"
      fullWidth
      open
      onClose={onClose}
      maxWidth={'lg'}
      PaperProps={{
        sx: { width: '100%', height: '90%' },
      }}
    >
      <DialogTitle style={{ padding: '0px' }}>
        <AppBar sx={{ position: 'absolute', backgroundColor: '#5f5c5c' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Box sx={{ flex: 1 }}>
              <MediumTypography
                sxProps={{ color: '#fff', fontSize: 'bold' }}
                label={fileName}
                defaultlabel="File Name"
              />
            </Box>
            <IconButton
              edge="end"
              color="inherit"
              sx={{ paddingRight: '24px' }}
              onClick={handleDownload}
              aria-label="download"
            >
              <DownloadIcon />
            </IconButton>
            {(url.includes('blob:http') ||
              extractFileExtension(url).toLowerCase().includes('.pdf')) && (
              <IconButton
                edge="end"
                color="inherit"
                onClick={handlePdfPreview}
                aria-label="print"
              >
                <PrintIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <Toolbar />
      <DocViewerWrapper documentUrl={url} />
    </Dialog>
  );
};

export default FilePreviewDialog;
