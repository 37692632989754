import React, { ChangeEvent } from 'react';
import { styled } from '@mui/system';
import {
  TextField,
  InputBaseComponentProps,
  InputAdornment,
  IconButton,
} from '@mui/material';
import './Textinput.css';
import { ReactComponent as Visibilite } from '../../assets/images/eyeOn.svg';
import { ReactComponent as Visibiliteoff } from '../../assets/images/eyeNew.svg';

const CssTextField = styled(TextField)({
  '& .MuiInputLabel-root': {
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#97A6A5',
    alignItems: 'center',
    textAlign: 'center',
  },
  '& .MuiInputBase-input': {
    alignItems: 'center',
    border: '1px  #97A6A5',
    borderRadius: '4px',
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  '& label.Mui-focused': {
    color: '#00C6B8',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#00C6B8',
    },
  },
});
interface PasswordTextFieldProps {
  name?: string;
  fieldheight?: string;
  InputProps?: InputBaseComponentProps;
  Value?: string;
  handlechange?: (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  placeHolder?: string;
  autoComplete?: string;
  className?: string;
  onblur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  handleTogglePasswordVisibility?: () => void;
  showpassword?: boolean;
}
const PasswordTextField: React.FC<PasswordTextFieldProps> = (props) => {
  const {
    placeHolder,
    fieldheight,
    name,
    Value,
    handlechange,
    autoComplete,
    InputProps,
    onblur,
    onFocus,
    showpassword,
    className,
    handleTogglePasswordVisibility,
  } = props;

  const internalHandlePasswordChange = (
    value: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (handlechange && value != null) {
      handlechange(value);
    }
  };
  const textFieldStyle = {
    height: fieldheight,
  };
  return (
    <CssTextField
      className={className}
      sx={{
        width: '100%',
        left: '0px',
        '& input::-ms-reveal': {
          display: 'none',
        },
      }}
      id="passwordtextfield"
      name={name}
      variant="outlined"
      value={Value}
      onBlur={onblur}
      onFocus={onFocus}
      autoComplete={autoComplete}
      placeholder={placeHolder}
      onChange={internalHandlePasswordChange}
      style={textFieldStyle}
      InputLabelProps={{ shrink: false }}
      type={showpassword ? 'text' : 'password'}
      inputProps={InputProps}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility} edge="end">
              {showpassword ? <Visibiliteoff /> : <Visibilite />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
export default PasswordTextField;
