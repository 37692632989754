import { SxProps, TableCell, Tooltip, styled } from '@mui/material';
import React, { FC } from 'react';
import MediumTypography from './MediumTypography';

interface TooltipProps {
  value?: string;
  tooltipSxProps?: SxProps;
  textSxProps?: SxProps;
  visible: boolean;
  className?: string;
  localizationId?: string;
  onClick?: () => void;
}

const CustomTableCell = styled(TableCell)(() => ({
  fontFamily: 'Lato-Regular',
  fontWeight: 500,
  fontSize: '14px',
}));

const ellipsisCellSX: SxProps = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100px',
  zIndex: 9999,
};

const TooltipTableCell: FC<TooltipProps> = ({
  value,
  tooltipSxProps,
  textSxProps,
  visible,
  localizationId,
  className,
  onClick,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);
  return (
    <Tooltip
      title={visible ? value : null}
      arrow
      followCursor={true}
      onClose={() => false}
      sx={tooltipSxProps}
      placement="bottom"
      onMouseMoveCapture={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      open={showTooltip}
    >
      <CustomTableCell onClick={onClick} className={className}>
        {localizationId && (
          <MediumTypography
            labelid={localizationId}
            defaultlabel="Open"
            sxProps={{ ...ellipsisCellSX, ...textSxProps }}
          />
        )}
        {value && (
          <MediumTypography
            label={value}
            sxProps={{ ...ellipsisCellSX, ...textSxProps }}
          />
        )}
      </CustomTableCell>
    </Tooltip>
  );
};

export default TooltipTableCell;
