import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReviewedOnType } from './ifspContextApi/IfspContex';
import { ReactComponent as Cross } from '../../assets/images/x.svg';
import { getRoleName } from '../../utils/nameUtils';

interface IfspReviewedOnListModalProps {
  openIfspReviewedOnListModal: boolean;
  closeIfspReviewedOnListModal: () => void;
  data: ReviewedOnType;
}

const IfspReviewedOnListModal = ({
  openIfspReviewedOnListModal,
  closeIfspReviewedOnListModal,
  data,
}: IfspReviewedOnListModalProps) => {
  return (
    <Dialog
      open={openIfspReviewedOnListModal}
      onClose={closeIfspReviewedOnListModal}
    >
      <DialogTitle>
        <Box className="flex__ alignItemCenter justifyContentSpaceBtw">
          <MediumTypography
            labelid="IFSP.ReviewDetails"
            defaultlabel="IFSP Review Details"
            fontweight={600}
            fontSize="16px"
            textColor="#2A4241"
          />
          <Cross
            style={{
              cursor: 'pointer',
              marginLeft: '10px',
            }}
            onClick={closeIfspReviewedOnListModal}
          />
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box className="flex__" sx={{ flexDirection: 'column', gap: '8px' }}>
          <Box className="flex__ align__items__center" sx={{ gap: '8px' }}>
            <MediumTypography
              labelid="IFSP.ReviewedBy"
              defaultlabel="Reviewed By - "
              fontSize="14px"
              fontweight={600}
              textColor="#2A4241"
            />
            <MediumTypography
              label={data.reviewedByName ? data.reviewedByName : '-'}
              fontSize="14px"
              textColor="#2A4241"
            />
          </Box>
          <Box className="flex__ align__items__center" sx={{ gap: '8px' }}>
            <MediumTypography
              labelid="IFSP.ReviewedByDiscipline"
              defaultlabel="Role - "
              fontSize="14px"
              fontweight={600}
              textColor="#2A4241"
            />
            <MediumTypography
              label={
                data.reviewedByDiscipline
                  ? getRoleName(data.reviewedByDiscipline)
                  : '-'
              }
              fontSize="14px"
              textColor="#2A4241"
            />
          </Box>
          <Box className="flex__ align__items__center" sx={{ gap: '8px' }}>
            <MediumTypography
              labelid="IFSP.ReviewedDate"
              defaultlabel="Reviewed Date & Time - "
              fontSize="14px"
              fontweight={600}
              textColor="#2A4241"
            />
            <MediumTypography
              label={data.reviewedDate ? (data.reviewedDate as string) : '-'}
              fontSize="14px"
              textColor="#2A4241"
            />
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default IfspReviewedOnListModal;
