import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemButton, Box } from '@mui/material';
import MediumTypography from './MediumTypography';
import { styled } from '@mui/system';
import '../ComonStyle.css';
import { ReactComponent as RightIcon } from '../../assets/images/rightIconSidebar.svg';
import { clientRoutes } from '../../layouts/Sidebar';

interface CustomButtonProps {
  to: string;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  labelId: string;
  defaultMessage?: string;
  selected: boolean;
  onClick?: () => void;
}

const CustomListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '30px',
}));

const CustomListButton: React.FC<CustomButtonProps> = ({
  to,
  leftIcon,
  rightIcon,
  labelId,
  defaultMessage,
  selected,
  onClick,
}) => {
  useEffect(() => {
    setClientSidebar(clientRoutes.includes(window.location.pathname));
  }, [clientRoutes.includes(window.location.pathname)]);

  const [clientSidebar, setClientSidebar] = useState<boolean>(false);
  return (
    <ListItem disablePadding>
      <ListItemButton
        onClick={onClick}
        sx={{
          '&.Mui-selected': {
            backgroundColor: '#E7F0F0',
            color: '#008C82',
            borderRadius: '4px',
          },
          '&:hover': {
            color: '#008C82',
            backgroundColor: 'transparent',
            fontWeight: '700',
          },
        }}
        selected={selected}
        component={Link}
        to={to}
      >
        <Box sx={{ display: 'flex', flex: 1 }} alignItems="center">
          <Box sx={{ display: 'flex' }} alignItems="center">
            {leftIcon && (
              <CustomListItemIcon className="sidebarIconRespo">
                {leftIcon}
              </CustomListItemIcon>
            )}
          </Box>
          <Box>
            <MediumTypography
              className="typofontsSidebar"
              labelid={labelId}
              defaultlabel={defaultMessage}
              sxProps={{
                fontWeight: selected ? 'bold' : 'normal',
              }}
            />
          </Box>
        </Box>
        <Box sx={{ display: 'flex' }} alignItems="center">
          {rightIcon && <CustomListItemIcon>{rightIcon}</CustomListItemIcon>}
          {clientSidebar && <RightIcon />}
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default CustomListButton;
