import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import InformationModal from './InformationModal';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { ReactComponent as AddIcon } from '../../assets/images/CircleAdd.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/deleteAlertIcon.svg';
import { Grid, Tooltip } from '@mui/material';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import Textinput from '../../components/formlib/Textinput';
import { Formik } from 'formik';
import * as yup from 'yup';
import IfspContext, { initialRoutine } from './ifspContextApi/IfspContex';
import ModalPopup from '../../components/formlib/ModalPopup';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface DailyRoutineProps {
  close: boolean;
  handelClose: Dispatch<SetStateAction<boolean>>;
  info: boolean;
  closeInfo: (info: boolean) => void;
  openInfo: (info: boolean) => void;
  isIfspScreenLocked: boolean;
  selectedLanguage: string;
}

const validationSchema = yup.object().shape({
  newRoutine: yup.string().required('IFSP.Routineisrequired'),
});
const DailyRoutine = ({
  closeInfo,
  info,
  openInfo,
  close,
  handelClose,
  isIfspScreenLocked,
  selectedLanguage,
}: DailyRoutineProps) => {
  const { ifsp, updateIfspContext } = useContext(IfspContext);
  const [dailyRoutines, setDailyRoutines] = useState(
    ifsp.ifspTeam.forms.dailyRoutines,
  );

  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const [deleteWaring, setDeleteWarning] = useState<boolean>(false);
  const [deleteNewSection, setDeleteNewSection] = useState<boolean>(false);

  const [newRoutine, setNewRoutine] = useState<string>('');
  const [allRoutine, setAllRoutine] = useState<
    { name: string; value: string; comments: string }[]
  >([]);
  const [openAccordianIndex, setOpenAccordianIndex] = useState<number>(0);
  const [openAccordian, setOpenAccordian] = useState<boolean>(false);
  const feedbackCount = [1, 2, 3];
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      selectedLanguage === 'en'
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, [selectedLanguage]);
  useEffect(() => {
    if (close) setOpenAccordian(false);
  }, [close]);
  const parentRef = useRef<HTMLDivElement>(null);
  const addRoutine = (newValue: string) => {
    setDailyRoutines((prev) => {
      return prev.map((routine, i) => {
        if (i === openAccordianIndex) {
          // Create a copy of the customRoutines array and add the new routine
          const updatedCustomRoutines = [
            ...routine.customRoutines,
            {
              name: newValue,
              value: '',
              comments: '',
            },
          ];

          // Return the updated routine object with the new customRoutines
          return {
            ...routine,
            customRoutines: updatedCustomRoutines,
          };
        }
        return routine;
      });
    });
    setAllRoutine((prev) => {
      return [
        ...prev,
        {
          name: newValue,
          value: '',
          comments: '',
        },
      ];
    });
    setNewRoutine('');
  };

  const deleteRoutine = (id: number) => {
    setDeleteIndex(id);
    setDeleteWarning(true);
  };

  const handleRadioButtonChange = (index: number, selectedValue: string) => {
    const openAccordianDailyRoutine = dailyRoutines[openAccordianIndex];
    const updatedDailyRoutines = [...dailyRoutines]; // Copy the current state

    // Check if the index is within predefined routines or custom routines
    const routineKeys = Object.keys(openAccordianDailyRoutine.routines);
    if (index < routineKeys.length) {
      // Update value for predefined routines
      const routineKey = routineKeys[index];
      updatedDailyRoutines[openAccordianIndex] = {
        ...openAccordianDailyRoutine,
        routines: {
          ...openAccordianDailyRoutine.routines,
          [routineKey]: {
            ...openAccordianDailyRoutine.routines[routineKey],
            value: selectedValue,
          },
        },
      };
    } else {
      // Update value for custom routines
      const customIndex = index - routineKeys.length;
      const updatedCustomRoutines = [
        ...openAccordianDailyRoutine.customRoutines,
      ];
      if (customIndex < updatedCustomRoutines.length) {
        updatedCustomRoutines[customIndex] = {
          ...updatedCustomRoutines[customIndex],
          value: selectedValue,
        };
        updatedDailyRoutines[openAccordianIndex] = {
          ...openAccordianDailyRoutine,
          customRoutines: updatedCustomRoutines,
        };
      }
    }

    // Update the state with the modified daily routines
    setDailyRoutines(updatedDailyRoutines);

    // Update allRoutines immediately
    const routines = updatedDailyRoutines[openAccordianIndex]?.routines || {};
    const customRoutines =
      updatedDailyRoutines[openAccordianIndex]?.customRoutines || [];

    // Combine routines and custom routines ensuring each has a name property
    const allRoutines = [
      ...Object.keys(routines).map((key) => ({
        name: initialRoutine[key as keyof typeof initialRoutine]?.name || key,
        value: routines[key].value,
        comments: routines[key].comments,
      })),
      ...customRoutines.map((routine) => ({
        ...routine,
        name: routine.name, // or use routine.name if it exists
      })),
    ];

    // Update the state with the combined routines
    setAllRoutine(allRoutines);
  };

  const handleCommentChange = (index: number, value: string) => {
    const openAccordianDailyRoutine = dailyRoutines[openAccordianIndex];
    const updatedDailyRoutines = [...dailyRoutines]; // Copy the current state

    // Check if the index is within predefined routines or custom routines
    if (index < 15) {
      // Update comments for predefined routines
      const routineKeys = Object.keys(openAccordianDailyRoutine.routines);
      const routineKey = routineKeys[index];
      updatedDailyRoutines[openAccordianIndex] = {
        ...openAccordianDailyRoutine,
        routines: {
          ...openAccordianDailyRoutine.routines,
          [routineKey]: {
            ...openAccordianDailyRoutine.routines[routineKey],
            comments: value,
          },
        },
      };
    } else {
      // Update comments for custom routines
      const customIndex = index - 15;
      const updatedCustomRoutines = [
        ...openAccordianDailyRoutine.customRoutines,
      ];
      updatedCustomRoutines[customIndex] = {
        ...updatedCustomRoutines[customIndex],
        comments: value,
      };
      updatedDailyRoutines[openAccordianIndex] = {
        ...openAccordianDailyRoutine,
        customRoutines: updatedCustomRoutines,
      };
    }

    // Update the state with the modified daily routines
    setDailyRoutines(updatedDailyRoutines);

    // Update allRoutines immediately
    const routines = updatedDailyRoutines[openAccordianIndex]?.routines || {};
    const customRoutines =
      updatedDailyRoutines[openAccordianIndex]?.customRoutines || [];

    // Combine routines and custom routines ensuring each has a name property
    const allRoutines = [
      ...Object.keys(routines).map((key) => ({
        name: initialRoutine[key as keyof typeof initialRoutine]?.name || key,
        value: routines[key].value,
        comments: routines[key].comments,
      })),
      ...customRoutines.map((routine) => ({
        ...routine,
        name: routine.name, // or use routine.name if it exists
      })),
    ];

    // Update the state with the combined routines
    setAllRoutine(allRoutines);
  };

  useEffect(() => {
    const routines = dailyRoutines[openAccordianIndex]?.routines || {};
    const customRoutines =
      dailyRoutines[openAccordianIndex]?.customRoutines || [];

    // Combine routines and custom routines ensuring each has a name property
    const allRoutines = [
      ...Object.keys(routines).map((key) => ({
        name: initialRoutine[key as keyof typeof initialRoutine]?.name || key,
        value: routines[key].value,
        comments: routines[key].comments,
      })),
      ...customRoutines.map((routine) => ({
        ...routine,
        name: routine.name, // or use routine.name if it exists
      })),
    ];

    // If allRoutines is empty, set initialRoutine
    setAllRoutine(allRoutines);
  }, [openAccordianIndex]);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          dailyRoutines: dailyRoutines,
        },
      },
    });
  }, [dailyRoutines]);
  const createNewSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          dailyRoutines: [
            ...ifsp.ifspTeam.forms.dailyRoutines,
            {
              routines: initialRoutine,
              customRoutines: [],
              easiestTimes: [
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
              ],
              hardestTimes: [
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
              ],
              isChecked: true,
              sessionReviewedDate: '',
            },
          ],
        },
      },
    });
    setDailyRoutines([
      ...ifsp.ifspTeam.forms.dailyRoutines,
      {
        routines: initialRoutine,
        customRoutines: [],
        easiestTimes: [
          { routineName: '', reason: '' },
          { routineName: '', reason: '' },
          { routineName: '', reason: '' },
        ],
        hardestTimes: [
          { routineName: '', reason: '' },
          { routineName: '', reason: '' },
          { routineName: '', reason: '' },
        ],
        isChecked: true,
        sessionReviewedDate: '',
      },
    ]);
  };

  const deleteSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          dailyRoutines: ifsp.ifspTeam.forms.dailyRoutines.slice(0, -1),
        },
      },
    });
    setDailyRoutines(ifsp.ifspTeam.forms.dailyRoutines.slice(0, -1));
    setDeleteNewSection(false);
  };
  return (
    <Formik
      initialValues={{ newRoutine: newRoutine }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        resetForm();
        addRoutine(values.newRoutine);
      }}
    >
      {({ values, setFieldValue, errors, touched, handleSubmit }) => (
        <Box component="main">
          {info && (
            <InformationModal
              open={info}
              titleLabel="IFSP.Information"
              titleDefaultLabel="Information"
              buttonLabel="IFSP.InfoOkButton"
              buttonDefaultLabel="Ok"
              onClose={() => {
                closeInfo(info);
              }}
              info={
                "Child Outcomes are a way to measure a child's progress as a result of their participation in an Early Intervention Program. Early Intervention supports children in the achievement of three Federal Child Outcomes: (1) developing positive social-emotional skills (including social relationships); (2) acquiring and using their knowledge and skills (including early language/communication); and (3) using appropriate behaviors to meet their needs. Information gathered from daily routines is important when determining a child's progress in each of these areas as they become active participants at home and in the community. \n The three Federal Child Outcomes refer to actions that children need to be able to carry out and knowledge that children need to use in order to function successfully across a variety of settings. To be successful in these settings, it is important for children to be able to, for example, get along with others, follow the rules in a group, continue to learn new things, and take care of their basic needs in an appropriate way. Ultimately, achieving these outcomes will help children thrive at home, in school, and in many settings throughout their communities."
              }
            />
          )}
          <ModalPopup
            open={deleteWaring}
            description="deleteConfirmationMessage"
            onOk={() => {
              setDailyRoutines((prev) => {
                return prev.map((routine, i) => {
                  if (i === openAccordianIndex) {
                    if (deleteIndex < Object.keys(routine.routines).length) {
                      // Delete from predefined routines
                      const routineKey = Object.keys(routine.routines)[
                        deleteIndex
                      ];
                      const {
                        [routineKey]: deletedRoutine,
                        ...updatedRoutines
                      } = routine.routines;
                      return {
                        ...routine,
                        routines: updatedRoutines,
                      };
                    } else {
                      // Delete from custom routines
                      const customIndex =
                        deleteIndex - Object.keys(routine.routines).length;
                      const updatedCustomRoutines = [...routine.customRoutines];
                      updatedCustomRoutines.splice(customIndex, 1);
                      return {
                        ...routine,
                        customRoutines: updatedCustomRoutines,
                      };
                    }
                  }
                  return routine;
                });
              });
              setAllRoutine((prev) => {
                return prev.filter((_, i) => i !== deleteIndex);
              });
              setDeleteWarning(false);
            }}
            onCancel={() => {
              setDeleteWarning(false);
            }}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />
          <ModalPopup
            open={deleteNewSection}
            description="deleteConfirmationMessage"
            onOk={() => {
              deleteSection();
            }}
            onCancel={() => {
              setDeleteNewSection(false);
            }}
            labelId1="Clientpage.cancelbtn"
            negativeActionLabel="cancelText"
            labelId2="Clientpage.Okbtn"
            positiveActionLabel="Ok"
          />
          {ifsp.ifspTeam.forms.dailyRoutines.map((ifspData, mainIndex) => (
            <Box
              component="section"
              className="p-lg gray3 borderRadius4 mb-lg"
              sx={{
                border: '1px solid #00C6B8',
              }}
            >
              <Box component="div" className="flex__justify__space-between">
                {openAccordian && mainIndex === openAccordianIndex ? (
                  <Box component="div" className="flex__justify__space-between">
                    <MediumTypography
                      labelid="IFSP.session1"
                      defaultlabel="Daily Routines, Strengths and Needs"
                      fontSize="18px"
                      fontweight={600}
                      textColor="#2A4241"
                      className="pr-md"
                    />
                    <InfoIcon
                      className="cursorPointer"
                      onClick={() => {
                        openInfo(info);
                      }}
                    />
                    {ifsp.ifspTeam.forms.dailyRoutines[mainIndex]
                      .sessionReviewedDate ? (
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginLeft: '24px',
                          backgroundColor: '#97A6A5',
                          alignItems: 'center',
                        }}
                      >
                        <MediumTypography
                          labelid="IFSP.UpdatedOn"
                          defaultlabel="Updated On: "
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                        />
                        <MediumTypography
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                          label={ifspData.sessionReviewedDate as string}
                        />
                      </Box>
                    ) : null}
                  </Box>
                ) : (
                  <Box className="flex__justify__space-between">
                    <MediumTypography
                      labelid="IFSP.session1"
                      defaultlabel="Daily Routines, Strengths and Needs"
                      fontSize="18px"
                      fontweight={600}
                      textColor="#2A4241"
                    />
                    {ifsp.ifspTeam.forms.dailyRoutines[mainIndex]
                      .sessionReviewedDate ? (
                      <Box
                        sx={{
                          display: 'flex',
                          padding: '4px 8px 4px 8px',
                          borderRadius: '4px',
                          marginLeft: '24px',
                          backgroundColor: '#97A6A5',
                          alignItems: 'center',
                        }}
                      >
                        <MediumTypography
                          labelid="IFSP.UpdatedOn"
                          defaultlabel="Updated On: "
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                        />
                        <MediumTypography
                          fontSize="14px"
                          fontweight={400}
                          textColor="#fff"
                          label={ifspData.sessionReviewedDate as string}
                        />
                      </Box>
                    ) : null}
                  </Box>
                )}
                <Box className="pt-xs">
                  {openAccordian && mainIndex === openAccordianIndex ? (
                    <Box className="flex__ align__items__center">
                      {!isIfspScreenLocked &&
                      !isScreenLocked &&
                      ifsp.ifspTeam.forms.dailyRoutines[
                        ifsp.ifspTeam.forms.dailyRoutines.length - 1
                      ].sessionReviewedDate &&
                      mainIndex ===
                        ifsp.ifspTeam.forms.dailyRoutines.length - 1 ? (
                        <Box
                          className="cursorPointer"
                          sx={{
                            padding: '4px 8px 4px 8px',
                            borderRadius: '4px',
                            marginRight: '24px',
                            border: '1px solid #008C82',
                            backgroundColor: '#fff',
                          }}
                          onClick={() => {
                            createNewSection();
                          }}
                        >
                          <MediumTypography
                            labelid="IFSP.AddNewSection"
                            defaultlabel="+ Add New Section"
                            fontSize="12px"
                            fontweight={600}
                          />
                        </Box>
                      ) : !isIfspScreenLocked &&
                        !isScreenLocked &&
                        !ifsp.ifspTeam.forms.dailyRoutines[
                          ifsp.ifspTeam.forms.dailyRoutines.length - 1
                        ].sessionReviewedDate &&
                        mainIndex ===
                          ifsp.ifspTeam.forms.dailyRoutines.length - 1 &&
                        mainIndex > 0 ? (
                        <Box
                          component={'div'}
                          className="cursorPointer flex__ align__items__center pr-md"
                          onClick={() => {
                            setDeleteNewSection(true);
                          }}
                          sx={{
                            padding: '4px 8px 4px 8px',
                            borderRadius: '4px',
                            marginRight: '24px',
                            border: '1px solid #EB4C60',
                            backgroundColor: '#fff',
                          }}
                        >
                          <DeleteIcon className="pr-xs" />
                          <MediumTypography
                            labelid="IFSP.DeleteNewSection"
                            defaultlabel="Delete"
                            fontSize="12px"
                            fontweight={600}
                          />
                        </Box>
                      ) : null}
                      <UpArrow
                        className="cursorPointer"
                        onClick={() => {
                          setOpenAccordianIndex(openAccordianIndex);
                          setOpenAccordian(false);
                        }}
                      />
                    </Box>
                  ) : (
                    <Box className="flex__ align__items__center">
                      {!isIfspScreenLocked &&
                      !isScreenLocked &&
                      ifsp.ifspTeam.forms.dailyRoutines[
                        ifsp.ifspTeam.forms.dailyRoutines.length - 1
                      ].sessionReviewedDate &&
                      mainIndex ===
                        ifsp.ifspTeam.forms.dailyRoutines.length - 1 ? (
                        <Box
                          className="cursorPointer"
                          sx={{
                            padding: '4px 8px 4px 8px',
                            borderRadius: '4px',
                            marginRight: '24px',
                            border: '1px solid #008C82',
                            backgroundColor: '#fff',
                          }}
                          onClick={() => {
                            createNewSection();
                          }}
                        >
                          <MediumTypography
                            labelid="IFSP.AddNewSection"
                            defaultlabel="+ Add New Section"
                            fontSize="12px"
                            fontweight={600}
                          />
                        </Box>
                      ) : !isIfspScreenLocked &&
                        !isScreenLocked &&
                        !ifsp.ifspTeam.forms.dailyRoutines[
                          ifsp.ifspTeam.forms.dailyRoutines.length - 1
                        ].sessionReviewedDate &&
                        mainIndex ===
                          ifsp.ifspTeam.forms.dailyRoutines.length - 1 &&
                        mainIndex > 0 ? (
                        <Box
                          component={'div'}
                          className="cursorPointer flex__ align__items__center pr-md"
                          onClick={() => {
                            setDeleteNewSection(true);
                          }}
                          sx={{
                            padding: '4px 8px 4px 8px',
                            borderRadius: '4px',
                            marginRight: '24px',
                            border: '1px solid #EB4C60',
                            backgroundColor: '#fff',
                          }}
                        >
                          <DeleteIcon className="pr-xs" />
                          <MediumTypography
                            labelid="IFSP.DeleteNewSection"
                            defaultlabel="Delete"
                            fontSize="12px"
                            fontweight={600}
                          />
                        </Box>
                      ) : null}
                      <DownArrow
                        className="cursorPointer"
                        onClick={() => {
                          setOpenAccordianIndex(mainIndex);
                          setOpenAccordian(true);
                          handelClose(false);
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              {openAccordian && mainIndex === openAccordianIndex && (
                <Box component="div" className="mt-lg">
                  <div
                    ref={parentRef}
                    style={{
                      pointerEvents: isScreenLocked ? 'none' : 'auto',
                      opacity: isScreenLocked ? '0.5' : '1 ',
                    }}
                    onKeyDownCapture={(e) => {
                      if (isScreenLocked) {
                        e.preventDefault();
                        e.stopPropagation();
                      }
                    }}
                    onFocus={() => {
                      if (isScreenLocked) {
                        if (parentRef.current) parentRef.current.focus();
                      }
                    }}
                  >
                    <Grid
                      container
                      className="pt-md pb-md"
                      sx={{
                        borderBottomWidth: '1px',
                        borderBottomStyle: 'solid',
                        borderBottomColor: '#CFDEDE',
                      }}
                    >
                      <Grid item xs={3}>
                        <MediumTypography
                          labelid="IFSP.routine"
                          defaultlabel="Routines"
                          fontweight={700}
                          textColor="#97A6A5"
                        />
                      </Grid>
                      <Grid item xs={2} className="align__items__center">
                        <MediumTypography
                          labelid="IFSP.easy"
                          defaultlabel="Easy"
                          fontweight={700}
                          textstyle="center"
                          textColor="#97A6A5"
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <MediumTypography
                          labelid="IFSP.notEasy"
                          defaultlabel="Not Easy"
                          fontweight={700}
                          textstyle="center"
                          textColor="#97A6A5"
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <MediumTypography
                          labelid="IFSP.comments"
                          defaultlabel="Comments"
                          fontweight={700}
                          textstyle="left"
                          textColor="#97A6A5"
                        />
                      </Grid>
                    </Grid>
                    {allRoutine.map((routines, index) => (
                      <Grid
                        container
                        className="pt-md pb-md"
                        sx={{
                          borderBottomWidth: '1px',
                          borderBottomStyle: 'solid',
                          borderBottomColor: '#CFDEDE',
                        }}
                        key={routines.name}
                      >
                        <Grid
                          item
                          xs={3}
                          className="flex__ align__items__center"
                        >
                          <Tooltip
                            title={routines.name}
                            arrow={true}
                            followCursor
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                width: '400px',
                                overflow: 'hidden',
                              }}
                            >
                              <MediumTypography
                                label={routines.name}
                                fontweight={500}
                                textColor="#2A4241"
                                sxProps={{
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                }}
                              />
                            </Box>
                          </Tooltip>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className="flex__ flex__justify__center align__items__center pl-xlg"
                        >
                          <CustomRadioButton
                            data={[{ value: 'easy' }]}
                            value={routines.value}
                            onSelectItem={(selectedValue) => {
                              if (selectedValue) {
                                handleRadioButtonChange(index, selectedValue);
                              }
                            }}
                            disable={
                              ifspData.sessionReviewedDate ? true : false
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          className="flex__ flex__justify__center align__items__center pl-xlg"
                        >
                          <CustomRadioButton
                            data={[{ value: 'not-easy' }]}
                            value={routines.value}
                            onSelectItem={(selectedValue) => {
                              if (selectedValue) {
                                handleRadioButtonChange(index, selectedValue);
                              }
                            }}
                            disable={
                              ifspData.sessionReviewedDate ? true : false
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          className="flex__ flex__justify__center align__items__center"
                        >
                          <Textinput
                            className="bg__white"
                            labelid="IFSP.comments"
                            defaultlabelid="Comments"
                            Value={routines.comments}
                            inputProps={{
                              className: 'translate',
                              maxLength: 500,
                            }}
                            handlechange={(text) =>
                              handleCommentChange(index, text)
                            }
                            sxProps={{ width: '400px' }}
                            disabled={
                              ifspData.sessionReviewedDate ? true : false
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          className="flex__ justifyContent-FlexEnd align__items__center"
                        >
                          {index > 14 && !ifspData.sessionReviewedDate && (
                            <Box
                              className="cursorPointer flex__"
                              onClick={() => deleteRoutine(index)}
                            >
                              <DeleteIcon className="cursorPointer " />
                              <MediumTypography
                                labelid="IFSP.Delete"
                                defaultlabel="Delete"
                                className="ml-xs"
                                sxProps={{
                                  color: '#EB4C60',
                                  fontWeight: 700,
                                  fontSize: '16px',
                                }}
                              />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                    <Box component="div" className="flex__ mt-lg">
                      <Textinput
                        className="bg__white mr-md"
                        labelid="IFSP.enterOtherRoutine"
                        defaultlabelid="Enter Other Routine"
                        Value={values.newRoutine}
                        inputProps={{
                          className: 'translate',
                          maxLength: 500,
                        }}
                        handlechange={(text) =>
                          setFieldValue('newRoutine', text)
                        }
                        sxProps={{ width: '400px' }}
                        Required
                        disabled={ifspData.sessionReviewedDate ? true : false}
                      />
                      {!ifspData.sessionReviewedDate && (
                        <Box
                          component="div"
                          className="flex__ align__items__center cursorPointer"
                          onClick={() => handleSubmit()}
                        >
                          <AddIcon className="cursorPointer mr-sm" />
                          <MediumTypography
                            labelid="IFSP.Add"
                            defaultlabel="Add"
                            fontweight={400}
                            textColor="#2A4241"
                          />
                        </Box>
                      )}
                    </Box>
                    {errors.newRoutine && touched.newRoutine && (
                      <MediumTypography
                        className="errorText-md"
                        labelid={errors.newRoutine}
                        defaultlabel="Routine is Required"
                      />
                    )}
                    <Box component="div" className="mt-lg">
                      <Grid container columnGap={'40px'}>
                        <Grid item xs={4.5} className="pl-xlg">
                          <MediumTypography
                            labelid="IFSP.easiestRoutine"
                            defaultlabel="Easiest or most enjoyable times (list top 3 routines)"
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <MediumTypography
                            labelid="IFSP.whyWhatEasy"
                            defaultlabel="Why? What makes this time go so well? Who is involved?"
                          />
                        </Grid>
                      </Grid>
                      {feedbackCount.map((__, index) => (
                        <Grid
                          key={feedbackCount[index]}
                          container
                          columnGap={'40px'}
                          className="pt-sm"
                        >
                          <Grid
                            item
                            xs={4.5}
                            className="flex__ align__items__center"
                          >
                            <MediumTypography
                              label={'0' + (index + 1).toString()}
                              className="pr-md"
                              fontweight={600}
                              textColor="#00C6B8"
                            />
                            <Textinput
                              className="bg__white"
                              labelid="IFSP.routine"
                              defaultlabelid="Routine"
                              inputProps={{
                                className: 'translate',
                                maxLength: 500,
                              }}
                              Value={
                                dailyRoutines[openAccordianIndex].easiestTimes[
                                  index
                                ]?.routineName || ''
                              }
                              handlechange={(text) => {
                                setDailyRoutines((prev) => {
                                  return prev.map((item, i) => {
                                    if (i === openAccordianIndex) {
                                      return {
                                        ...item,
                                        easiestTimes: item.easiestTimes.map(
                                          (easiest, ii) => {
                                            if (ii === index) {
                                              return {
                                                ...easiest,
                                                routineName: text,
                                              };
                                            }
                                            return easiest;
                                          },
                                        ),
                                      };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              disabled={
                                ifspData.sessionReviewedDate ? true : false
                              }
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <Textinput
                              className="bg__white"
                              labelid="IFSP.comments"
                              defaultlabelid="Comments"
                              inputProps={{
                                className: 'translate',
                                maxLength: 500,
                              }}
                              Value={
                                dailyRoutines[openAccordianIndex].easiestTimes[
                                  index
                                ]?.reason || ''
                              }
                              handlechange={(text) => {
                                setDailyRoutines((prev) => {
                                  return prev.map((item, i) => {
                                    if (i === openAccordianIndex) {
                                      return {
                                        ...item,
                                        easiestTimes: item.easiestTimes.map(
                                          (easiest, ii) => {
                                            if (ii === index) {
                                              return {
                                                ...easiest,
                                                reason: text,
                                              };
                                            }
                                            return easiest;
                                          },
                                        ),
                                      };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              disabled={
                                ifspData.sessionReviewedDate ? true : false
                              }
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                    <Box component="div" className="mt-lg">
                      <Grid container columnGap={'40px'}>
                        <Grid item xs={4.5} className="pl-xlg">
                          <MediumTypography
                            labelid="IFSP.hardestRoutine"
                            defaultlabel="Hardest or most challenging times (list top 3 routines)"
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <MediumTypography
                            labelid="IFSP.whyWhatHard"
                            defaultlabel="Why, what makes this time so challenging or hard?"
                          />
                        </Grid>
                      </Grid>
                      {feedbackCount.map((__, index) => (
                        <Grid
                          key={feedbackCount[index]}
                          container
                          columnGap={'40px'}
                          className="pt-sm"
                        >
                          <Grid
                            item
                            xs={4.5}
                            className="flex__ align__items__center"
                          >
                            <MediumTypography
                              label={'0' + (index + 1).toString()}
                              className="pr-md"
                              fontweight={600}
                              textColor="#00C6B8"
                            />
                            <Textinput
                              className="bg__white"
                              labelid="IFSP.routine"
                              defaultlabelid="Routine"
                              inputProps={{
                                className: 'translate',
                                maxLength: 500,
                              }}
                              Value={
                                dailyRoutines[openAccordianIndex].hardestTimes[
                                  index
                                ]?.routineName || ''
                              }
                              handlechange={(text) => {
                                setDailyRoutines((prev) => {
                                  return prev.map((item, i) => {
                                    if (i === openAccordianIndex) {
                                      return {
                                        ...item,
                                        hardestTimes: item.hardestTimes.map(
                                          (hardest, ii) => {
                                            if (ii === index) {
                                              return {
                                                ...hardest,
                                                routineName: text,
                                              };
                                            }
                                            return hardest;
                                          },
                                        ),
                                      };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              disabled={
                                ifspData.sessionReviewedDate ? true : false
                              }
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <Textinput
                              className="bg__white"
                              labelid="IFSP.comments"
                              defaultlabelid="Comments"
                              inputProps={{
                                className: 'translate',
                                maxLength: 500,
                              }}
                              Value={
                                dailyRoutines[openAccordianIndex].hardestTimes[
                                  index
                                ]?.reason || ''
                              }
                              handlechange={(text) => {
                                setDailyRoutines((prev) => {
                                  return prev.map((item, i) => {
                                    if (i === openAccordianIndex) {
                                      return {
                                        ...item,
                                        hardestTimes: item.hardestTimes.map(
                                          (hardest, ii) => {
                                            if (ii === index) {
                                              return {
                                                ...hardest,
                                                reason: text,
                                              };
                                            }
                                            return hardest;
                                          },
                                        ),
                                      };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              disabled={
                                ifspData.sessionReviewedDate ? true : false
                              }
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </div>
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </Formik>
  );
};

export default DailyRoutine;
