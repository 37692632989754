import {
  ClinicalEligibilty,
  ClinicalJudgement,
} from '../../../pages/eligibility/types';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export const getClientClinicalJudgementDetails = async (
  clientId: string,
): Promise<ClinicalJudgement> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ClinicalJudgement>(
      `clinical-judgment?evaluationId=${clientId}`,
    ),
  );
};

export const saveClinicalJudgement = async (
  clientId: string,
  value: ClinicalEligibilty,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<ClinicalEligibilty, ApiMessage>(
      `clinical-judgment?evaluationId=${clientId}`,
      value,
    ),
  );
};

export const deleteTeamMemberById = async (
  teamMemberId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `clinical-judgment/teams?teamMemberId=${teamMemberId}`,
    ),
  );
};

export const deleteConcernById = async (
  concernId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `clinical-judgment/concerns?concernId=${concernId}`,
    ),
  );
};
