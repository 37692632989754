import { Box, SxProps } from '@mui/material';

type SingleLineType = {
  lineHeight?: string;
  lineWidth?: string;
  backgroundColor?: string;
  sxContainerProps?: SxProps;
  sxLineProps?: SxProps;
  className?: string;
};

const SingleLine = ({
  lineHeight,
  lineWidth,
  backgroundColor,
  sxContainerProps,
  sxLineProps,
  className,
}: SingleLineType) => {
  return (
    <Box
      className={
        className + 'flex__ align__items__center justifyContent-Center'
      }
      sx={{
        ...sxContainerProps,
      }}
    >
      <Box
        sx={{
          height: lineHeight ?? '1px',
          width: lineWidth ?? '100%',
          backgroundColor: backgroundColor ?? '#CFDEDE',
          alignSelf: 'center',
          ...sxLineProps,
        }}
      />
    </Box>
  );
};

export default SingleLine;
