import { Box } from '@mui/system';
import { FC } from 'react';
import { Tooltip } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Dayjs } from 'dayjs';

interface Information {
  consentDatePlaceholder: string;
  consentDateDefaultPlaceholder: string;
  expiryDatePlaceholder: string;
  expiryDateDefaultPlaceholder: string;
  statusLabelId: string;
  statusDefaultLabelId: string;
  requestedNameLabelId: string;
  requestedNameDefaultLabelId: string;
  requestedEmailLabelId: string;
  requestedEmailDefaultLabelId: string;
  requestedDateLabelId: string;
  requestedDateDefaultLabelId: string;
  requestedPhoneLabelId: string;
  requestedPhoneDefaultLabelId: string;
  status: string;
  requester: RequesterData;
  requestedDate: string;
  signedDate: Date | string | Dayjs | null;
  expiredDate: Date | string | Dayjs | null;
}

type RequesterData = {
  name: string;
  email: string;
  phone: string;
};

const ConsentGeneralInfo: FC<Information> = (props) => {
  return (
    <Box component="div">
      <Box className="flex__ pt-sm">
        <Box className="flex__ mr-lg">
          <MediumTypography
            labelid={props.consentDatePlaceholder}
            defaultlabel={props.consentDateDefaultPlaceholder}
            textColor="#97A6A5"
          />

          <MediumTypography
            className="pl-xs width150px ellipsis"
            textColor="#2A4241"
            label={
              props.signedDate === null || props.signedDate === ''
                ? '-'
                : props.signedDate.toString()
            }
          />
        </Box>
        {props.expiredDate &&
          props.expiredDate !== null &&
          props.expiredDate !== '' && (
            <Box className="flex__ mr-lg">
              <MediumTypography
                labelid={props.expiryDatePlaceholder}
                defaultlabel={props.expiryDateDefaultPlaceholder}
                textColor="#97A6A5"
              />

              <MediumTypography
                className="pl-xs width100px ellipsis"
                textColor="#2A4241"
                label={
                  props.expiredDate === null || props.expiredDate === ''
                    ? '-'
                    : props.expiredDate.toString()
                }
              />
            </Box>
          )}
        <Box className="flex__">
          <MediumTypography
            labelid={props.statusLabelId}
            defaultlabel={props.statusDefaultLabelId}
            textColor="#97A6A5"
          />
          <Box className="width150px">
            <Tooltip
              title={
                props.status && props.status.length > 10 ? props.status : ''
              }
              followCursor
              arrow={true}
            >
              <Box>
                <MediumTypography
                  className="pl-xs "
                  textColor={
                    props.status.toLowerCase().includes('revoked')
                      ? '#EB4C60'
                      : '#2A4241'
                  }
                  label={
                    props.status === null || props.status === ''
                      ? '-'
                      : props.status
                  }
                  sxProps={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ConsentGeneralInfo;
