import {
  CheckExistFormName,
  CreateForm,
  CreateResponse,
  FormResponseList,
  GetByIdList,
  NewFormresponse,
  QuestionnaireintakeResponse,
  ResponseList,
} from '../../../../utils/type';
import {
  ApiMessage,
  ApiResponseHandler,
  ResponseItem,
} from '../../../ApiResponseHandler';
import ThomApiClient from '../../../ThomApiClient';

export const addForm = async (
  params: CreateForm,
  employeeId: string | null,
): Promise<ApiMessage> => {
  const url = `forms?employeeId=${employeeId}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const editForm = async (
  params: CreateForm,
  id: string | null,
): Promise<ApiMessage> => {
  const url = `forms/update/${id}?isDeleted=false`;
  return ApiResponseHandler(await ThomApiClient.getInstance().put(url, params));
};

export const getRecordById = async (
  formID: string | number | undefined,
): Promise<GetByIdList> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(`forms/${formID}`),
  );
};

export const getAllForms = async (
  type: string,
  page: number,
  size: number,
  sortType: string,
  search: string,
  sortName: string,
  isCheckPermission: boolean,
): Promise<FormResponseList> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `forms?&type=${type}&page=${page}&size=${size}&sort=${sortName}&sortType=${sortType}&search=${search}&isCheckPermission=${isCheckPermission}`,
    ),
  );
};

export const defaultForms = async (
  id: string,
  isDefault: boolean,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().patch(
      `forms/update/${id}?isDefault=${isDefault}`,
    ),
  );
};

export const deleteForms = async (
  id: string,
  isDelete: boolean,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `forms/update/${id}?isDeleted=${isDelete}`,
    ),
  );
};

export const checkDuplicate = async (
  value: string,
): Promise<CheckExistFormName> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `forms/check?type=OTHERS&name=${value}`,
    ),
  );
};

export const checkDuplicateEdit = async (
  value: string,
  formID: string,
): Promise<CheckExistFormName> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `forms/check?type=OTHERS&name=${value}&formTemplateId=${formID}`,
    ),
  );
};

export const addResponse = async (
  params: CreateResponse[],
  clientId: string | null | undefined,
): Promise<ResponseItem[]> => {
  const url = `forms/client/response?clientId=${clientId}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const questionnaireresponsePost = async (
  params: QuestionnaireintakeResponse,
  clientId: string | null | undefined,
): Promise<ApiMessage> => {
  const url = `intake/questionnaire?clientId=${clientId}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const deleteResponse = async (id: string): Promise<ApiMessage> => {
  const url = `forms/client/response/${id}?isDeleted=true`;
  return ApiResponseHandler(await ThomApiClient.getInstance().patch(url));
};

export const deleteResponseIntake = async (id: string): Promise<ApiMessage> => {
  const url = `intake/questionnaire/${id}`;
  return ApiResponseHandler(await ThomApiClient.getInstance().delete(url));
};

export const getAllResponseList = async (
  clientId: string,
): Promise<ResponseList[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `forms/client/response?clientId=${clientId}`,
    ),
  );
};

export const getAllResponseListIntake = async (
  clientId: string | null,
): Promise<NewFormresponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `intake/formResponse?clientId=${clientId}`,
    ),
  );
};

export const fetchFormJSON = async (url: string) => {
  const apiUrl = url;
  try {
    const response = await fetch(apiUrl, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return { data };
  } catch (error) {
    throw new Error(`Error fetching data`);
  }
};
