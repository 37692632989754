import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/deleteAlertIcon.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import InformationModal from './InformationModal';
import FamiliyConcernsPrioritiesandResourcesData from './IFSP/FamilyConcernsPrioritiesandResources.json';
import SimpleTextAndTextArea from '../../components/forms/SimpleTextAndTextArea';
import IfspContext from './ifspContextApi/IfspContex';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { Dayjs } from 'dayjs';
import ModalPopup from '../../components/formlib/ModalPopup';

interface FamiliyConcernsPrioritiesandResourcesProps {
  close: boolean;
  handelClose: Dispatch<SetStateAction<boolean>>;
  info: boolean;
  closeInfo: (info: boolean) => void;
  openInfo: (info: boolean) => void;
  isIfspScreenLocked: boolean;
  selectedLanguage: string;
}

type FamilyConcernType = {
  concern: string;
  priorities: string;
  materialResource: string;
  infoResource: string;
  supportiveResource: string;
  isChecked: boolean;
  sessionReviewedDate: string | null | Dayjs;
};

const FamiliyConcernsPrioritiesandResources: FC<
  FamiliyConcernsPrioritiesandResourcesProps
> = ({
  close,
  handelClose,
  info,
  openInfo,
  closeInfo,
  isIfspScreenLocked,
  selectedLanguage,
}) => {
  const { ifsp, updateIfspContext } = useContext(IfspContext);

  const [hesServiceQuestion, setHESServiceQuestion] = useState<
    { commentId: number; comments: string }[]
  >(
    FamiliyConcernsPrioritiesandResourcesData.Questions.map((_, index) => ({
      commentId: index,
      comments: '',
    })),
  );

  const [resourcesQuestion, setResourcesQuestion] = useState<
    { commentId: number; comments: string }[]
  >(
    FamiliyConcernsPrioritiesandResourcesData.resources.map((_, index) => ({
      commentId: index,
      comments: '',
    })),
  );

  const [familyConcern, setFamilyConcern] = useState<FamilyConcernType[]>(
    ifsp.ifspTeam.forms.familyConcerns || [],
  );

  const [openAccordianIndex, setOpenAccordianIndex] = useState<number>(0);
  const [openAccordian, setOpenAccordian] = useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [deleteNewSection, setDeleteNewSection] = useState<boolean>(false);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      selectedLanguage === 'en'
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    const familyConcerns = familyConcern || [];
    setHESServiceQuestion((prev) => {
      const tempData = [...prev];
      if (familyConcerns[openAccordianIndex]) {
        tempData[0].comments = familyConcerns[openAccordianIndex].concern || '';
        tempData[1].comments =
          familyConcerns[openAccordianIndex].priorities || '';
      }
      return tempData;
    });
    setResourcesQuestion((prev) => {
      const tempData = [...prev];
      if (familyConcerns[openAccordianIndex]) {
        tempData[0].comments =
          familyConcerns[openAccordianIndex].materialResource || '';
        tempData[1].comments =
          familyConcerns[openAccordianIndex].infoResource || '';
        tempData[2].comments =
          familyConcerns[openAccordianIndex].supportiveResource || '';
      }
      return tempData;
    });
    setFamilyConcern((prev) => {
      return prev.map((data, index) => {
        if (
          index === openAccordianIndex &&
          familyConcerns[openAccordianIndex]
        ) {
          return {
            concern: familyConcerns[openAccordianIndex].concern || '',
            priorities: familyConcerns[openAccordianIndex].priorities || '',
            materialResource:
              familyConcerns[openAccordianIndex].materialResource || '',
            infoResource: familyConcerns[openAccordianIndex].infoResource || '',
            supportiveResource:
              familyConcerns[openAccordianIndex].supportiveResource || '',
            isChecked: true,
            sessionReviewedDate:
              ifsp.ifspTeam.forms.familyConcerns[openAccordianIndex]
                .sessionReviewedDate,
          };
        }
        return data;
      });
    });
  }, [openAccordianIndex]);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          familyConcerns: familyConcern,
        },
      },
    });
  }, [hesServiceQuestion, resourcesQuestion]);
  useEffect(() => {
    if (close) setOpenAccordian(false);
  }, [close]);
  const createNewSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          familyConcerns: [
            ...ifsp.ifspTeam.forms.familyConcerns,
            {
              concern: '',
              priorities: '',
              materialResource: '',
              infoResource: '',
              supportiveResource: '',
              isChecked: true,
              sessionReviewedDate: '',
            },
          ],
        },
      },
    });
    setFamilyConcern(() => {
      return [
        ...ifsp.ifspTeam.forms.familyConcerns,
        {
          concern: '',
          priorities: '',
          materialResource: '',
          infoResource: '',
          supportiveResource: '',
          isChecked: true,
          sessionReviewedDate: '',
        },
      ];
    });
  };

  const deleteSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          familyConcerns: ifsp.ifspTeam.forms.familyConcerns.slice(0, -1),
        },
      },
    });
    setFamilyConcern(ifsp.ifspTeam.forms.familyConcerns.slice(0, -1));
    setDeleteNewSection(false);
  };

  return (
    <Box component="main">
      <Box component="section">
        {info && (
          <InformationModal
            open={info}
            titleLabel="IFSP.Information"
            titleDefaultLabel="Information"
            buttonLabel="IFSP.InfoOkButton"
            buttonDefaultLabel="Ok"
            onClose={() => {
              closeInfo(info);
            }}
            info={FamiliyConcernsPrioritiesandResourcesData.info}
          />
        )}
        <ModalPopup
          open={deleteNewSection}
          description="deleteConfirmationMessage"
          onOk={() => {
            deleteSection();
          }}
          onCancel={() => {
            setDeleteNewSection(false);
          }}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="Ok"
        />
        {ifsp.ifspTeam.forms.familyConcerns.map((ifspData, mainIndex) => (
          <Box
            component="div"
            className="formCardview gray3 borderRadius4 mb-lg"
            sx={{
              border: '1px solid #00C6B8',
            }}
            key={mainIndex}
          >
            <Box component="div" className="flex__justify__space-between">
              {openAccordian && mainIndex === openAccordianIndex ? (
                <Box component="div" className="flex__ align__items__center">
                  <MediumTypography
                    labelid="IFSP.session4"
                    defaultlabel="Familiy Concerns, Priorities and Resources"
                    fontSize="18px"
                    fontweight={600}
                    textColor="#2A4241"
                    className="pr-md"
                  />
                  <InfoIcon
                    onClick={() => {
                      openInfo(info);
                    }}
                  />
                  {ifsp.ifspTeam.forms.familyConcerns[mainIndex]
                    .sessionReviewedDate ? (
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '4px 8px 4px 8px',
                        borderRadius: '4px',
                        marginLeft: '24px',
                        backgroundColor: '#97A6A5',
                        alignItems: 'center',
                      }}
                    >
                      <MediumTypography
                        labelid="IFSP.UpdatedOn"
                        defaultlabel="Updated On: "
                        fontSize="14px"
                        fontweight={400}
                        textColor="#fff"
                      />
                      <MediumTypography
                        fontSize="14px"
                        fontweight={400}
                        textColor="#fff"
                        label={ifspData.sessionReviewedDate as string}
                      />
                    </Box>
                  ) : null}
                </Box>
              ) : (
                <Box className="flex__justify__space-between">
                  <MediumTypography
                    labelid="IFSP.session4"
                    defaultlabel="Familiy Concerns, Priorities and Resources"
                    fontSize="18px"
                    fontweight={600}
                    textColor="#2A4241"
                  />
                  {ifsp.ifspTeam.forms.familyConcerns[mainIndex]
                    .sessionReviewedDate ? (
                    <Box
                      sx={{
                        display: 'flex',
                        padding: '4px 8px 4px 8px',
                        borderRadius: '4px',
                        marginLeft: '24px',
                        backgroundColor: '#97A6A5',
                        alignItems: 'center',
                      }}
                    >
                      <MediumTypography
                        labelid="IFSP.UpdatedOn"
                        defaultlabel="Updated On: "
                        fontSize="14px"
                        fontweight={400}
                        textColor="#fff"
                      />
                      <MediumTypography
                        fontSize="14px"
                        fontweight={400}
                        textColor="#fff"
                        label={ifspData.sessionReviewedDate as string}
                      />
                    </Box>
                  ) : null}
                </Box>
              )}
              {openAccordian && mainIndex === openAccordianIndex ? (
                <Box className="flex__ align__items__center">
                  {!isIfspScreenLocked &&
                  !isScreenLocked &&
                  ifsp.ifspTeam.forms.familyConcerns[
                    ifsp.ifspTeam.forms.familyConcerns.length - 1
                  ].sessionReviewedDate &&
                  ifsp.ifspTeam.forms.familyConcerns.length - 1 ===
                    mainIndex ? (
                    <Box
                      className="cursorPointer"
                      sx={{
                        padding: '4px 8px 4px 8px',
                        borderRadius: '4px',
                        marginRight: '24px',
                        border: '1px solid #008C82',
                        backgroundColor: '#fff',
                      }}
                      onClick={() => {
                        createNewSection();
                      }}
                    >
                      <MediumTypography
                        labelid="IFSP.AddNewSection"
                        defaultlabel="+ Add New Section"
                        fontSize="12px"
                        fontweight={600}
                      />
                    </Box>
                  ) : !isIfspScreenLocked &&
                    !isScreenLocked &&
                    !ifsp.ifspTeam.forms.familyConcerns[
                      ifsp.ifspTeam.forms.familyConcerns.length - 1
                    ].sessionReviewedDate &&
                    ifsp.ifspTeam.forms.familyConcerns.length - 1 ===
                      mainIndex &&
                    mainIndex > 0 ? (
                    <Box
                      component={'div'}
                      className="cursorPointer flex__ align__items__center pr-md"
                      onClick={() => {
                        setDeleteNewSection(true);
                      }}
                      sx={{
                        padding: '4px 8px 4px 8px',
                        borderRadius: '4px',
                        marginRight: '24px',
                        border: '1px solid #EB4C60',
                        backgroundColor: '#fff',
                      }}
                    >
                      <DeleteIcon className="pr-xs" />
                      <MediumTypography
                        labelid="IFSP.DeleteNewSection"
                        defaultlabel="Delete"
                        fontSize="12px"
                        fontweight={600}
                      />
                    </Box>
                  ) : null}
                  <UpArrow
                    className="cursorPointer"
                    onClick={() => {
                      setOpenAccordianIndex(openAccordianIndex);
                      setOpenAccordian(false);
                    }}
                  />
                </Box>
              ) : (
                <Box className="flex__ align__items__center">
                  {!isIfspScreenLocked &&
                  !isScreenLocked &&
                  ifsp.ifspTeam.forms.familyConcerns[
                    ifsp.ifspTeam.forms.familyConcerns.length - 1
                  ].sessionReviewedDate &&
                  ifsp.ifspTeam.forms.familyConcerns.length - 1 ===
                    mainIndex ? (
                    <Box
                      className="cursorPointer"
                      sx={{
                        padding: '4px 8px 4px 8px',
                        borderRadius: '4px',
                        marginRight: '24px',
                        border: '1px solid #008C82',
                        backgroundColor: '#fff',
                      }}
                      onClick={() => {
                        createNewSection();
                      }}
                    >
                      <MediumTypography
                        labelid="IFSP.AddNewSection"
                        defaultlabel="+ Add New Section"
                        fontSize="12px"
                        fontweight={600}
                      />
                    </Box>
                  ) : !isIfspScreenLocked &&
                    !isScreenLocked &&
                    !ifsp.ifspTeam.forms.familyConcerns[
                      ifsp.ifspTeam.forms.familyConcerns.length - 1
                    ].sessionReviewedDate &&
                    ifsp.ifspTeam.forms.familyConcerns.length - 1 ===
                      mainIndex &&
                    mainIndex > 0 ? (
                    <Box
                      component={'div'}
                      className="cursorPointer flex__ align__items__center pr-md"
                      onClick={() => {
                        setDeleteNewSection(true);
                      }}
                      sx={{
                        padding: '4px 8px 4px 8px',
                        borderRadius: '4px',
                        marginRight: '24px',
                        border: '1px solid #EB4C60',
                        backgroundColor: '#fff',
                      }}
                    >
                      <DeleteIcon className="pr-xs" />
                      <MediumTypography
                        labelid="IFSP.DeleteNewSection"
                        defaultlabel="Delete"
                        fontSize="12px"
                        fontweight={600}
                      />
                    </Box>
                  ) : null}
                  <DownArrow
                    className="cursorPointer"
                    onClick={() => {
                      setOpenAccordianIndex(mainIndex);
                      setOpenAccordian(true);
                      handelClose(false);
                    }}
                  />
                </Box>
              )}
            </Box>
            {openAccordian && mainIndex === openAccordianIndex && (
              <Box component="div">
                <div
                  ref={parentRef}
                  style={{
                    pointerEvents: isScreenLocked ? 'none' : 'auto',
                    opacity: isScreenLocked ? '0.5' : '1 ',
                  }}
                  onKeyDownCapture={(e) => {
                    if (isScreenLocked) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                  onFocus={() => {
                    if (isScreenLocked) {
                      if (parentRef.current) parentRef.current.focus();
                    }
                  }}
                >
                  <Box component="div" className="mt-sm mb-sm">
                    <SimpleTextAndTextArea
                      questions={
                        FamiliyConcernsPrioritiesandResourcesData.Questions
                      }
                      placeholder="Enter your comment"
                      rows={1}
                      customCommentStyles={{
                        height: '80px',
                        minHeight: '80px',
                        minWidth: '840px',
                        width: '840px',
                      }}
                      maxLength={5000}
                      Value={hesServiceQuestion}
                      handlechange={(value: {
                        commentId: number;
                        comments: string;
                      }) => {
                        const updatedComments = [...hesServiceQuestion];
                        updatedComments[value.commentId].comments =
                          value.comments;
                        setHESServiceQuestion((prev) =>
                          prev.map((item, index) => {
                            if (index === openAccordianIndex) {
                              return {
                                ...item,
                                concern: updatedComments[0].comments,
                                priorities: updatedComments[1].comments,
                              };
                            }
                            return item;
                          }),
                        );
                        setFamilyConcern((prev) =>
                          prev.map((item, index) => {
                            if (index === openAccordianIndex) {
                              return {
                                ...item,
                                concern: updatedComments[0].comments,
                                priorities: updatedComments[1].comments,
                              };
                            }
                            return item;
                          }),
                        );
                      }}
                      disabled={ifspData.sessionReviewedDate ? true : false}
                    />
                  </Box>
                  <Box component="div">
                    <MediumTypography
                      labelid="IFSP.resourcesToSupport"
                      defaultlabel="Resources to Support the Child's Development:"
                      fontSize="16px"
                      fontweight={600}
                      textColor="#2A4241"
                    />
                    <MediumTypography
                      labelid="IFSP.listResources"
                      defaultlabel="What do we have or would be helpful to have? List resources that the family may have and may need."
                      textColor="#2A4241"
                      className="mt-sm"
                    />
                  </Box>
                  <Box component="div" className="mt-sm">
                    <SimpleTextAndTextArea
                      questions={
                        FamiliyConcernsPrioritiesandResourcesData.resources
                      }
                      placeholder="Enter your comments"
                      rows={1}
                      customCommentStyles={{
                        height: '80px',
                        minHeight: '80px',
                        minWidth: '840px',
                        width: '840px',
                      }}
                      maxLength={5000}
                      Value={resourcesQuestion}
                      handlechange={(value: {
                        commentId: number;
                        comments: string;
                      }) => {
                        const updatedComments = [...resourcesQuestion];
                        updatedComments[value.commentId].comments =
                          value.comments;
                        setResourcesQuestion((prev) =>
                          prev.map((item, index) => {
                            if (index === openAccordianIndex) {
                              return {
                                ...item,
                                materialResource: updatedComments[0].comments,
                                infoResource: updatedComments[1].comments,
                                supportiveResource: updatedComments[2].comments,
                              };
                            }
                            return item;
                          }),
                        );

                        setFamilyConcern((prev) =>
                          prev.map((item, index) => {
                            if (index === openAccordianIndex) {
                              return {
                                ...item,
                                materialResource: updatedComments[0].comments,
                                infoResource: updatedComments[1].comments,
                                supportiveResource: updatedComments[2].comments,
                              };
                            }
                            return item;
                          }),
                        );
                      }}
                      disabled={ifspData.sessionReviewedDate ? true : false}
                    />
                  </Box>
                </div>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FamiliyConcernsPrioritiesandResources;
