import React, { useCallback, useEffect } from 'react';
import { Box, Card, Grid, AlertColor } from '@mui/material';
import CustomBackArrow from '../../../components/imagepickers/backArrow';

import Textinput from '../../../components/formlib/Textinput';
import { Formik, FormikErrors, FormikProps } from 'formik';
import * as yup from 'yup';

import ButtonComponent from '../../../components/formlib/ButtonComponent';
import SnackBarComponent from '../../../components/formlib/SnackBarComponent';
import { useLocation, useNavigate } from 'react-router';
import { CODE_LIST } from '../../../routes/Routing';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import SmallTypography from '../../../components/formlib/SmallTypography';
import _, { cloneDeep, debounce } from 'lodash';
import MediumTypography from '../../../components/formlib/MediumTypography';
import {
  SettingCode,
  SubSettingCodes,
  addSettingCodes,
  editSettingCodes,
} from '../../../services/configApi/codelist/settingCodes';
import './../serviceCodes/ServiceCodesList.css';
import { ReactComponent as DeleteIcon } from '../../../assets/images/deleteAlertIcon.svg';
import ModalPopup from '../../../components/formlib/ModalPopup';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../../services/ApiResponseHandler';

const validationSchema = yup.object().shape({
  settingCode: yup.array().of(
    yup.object().shape({
      code: yup.string().required('Required'),
      description: yup.string().required('Required'),
    }),
  ),
});

const AddSettingCode = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataView, setDataView] = React.useState<SettingCode>(
    location.state?.categoryDetails !== undefined
      ? {
          settingCode: [location.state?.categoryDetails],
        }
      : {
          settingCode: [
            {
              codeId: 1,
              code: '',
              description: '',
            },
          ],
        },
  );
  const formRef = React.useRef<FormikProps<SettingCode>>(null);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    if (location.state?.categoryDetails !== undefined) {
      toggleLoader(true);

      setTimeout(() => {
        toggleLoader(false);
      }, 1000);
      const obj = {
        settingCode: [location.state?.categoryDetails],
      };
      formRef.current?.setValues(cloneDeep(obj));
      setDataView(cloneDeep(obj));
    } else {
      formRef.current?.setValues({
        settingCode: [
          {
            codeId: 1,
            code: '',
            description: '',
          },
        ],
      });
      setDataView({
        settingCode: [
          {
            codeId: 1,
            code: '',
            description: '',
          },
        ],
      });
    }
  }, [location.state?.categoryDetails]);

  const handleAddLink = () => {
    const updatedDataView = { ...dataView };
    const newCode = {
      codeId: updatedDataView.settingCode.length + 1,
      code: '',
      description: '',
    };

    updatedDataView.settingCode.push(newCode);

    setDataView(updatedDataView);

    formRef.current?.setValues(updatedDataView);
  };

  const handleCodeChange = (index: number, value: string) => {
    const codeChangeValue = { ...dataView };
    codeChangeValue.settingCode[index].code = value;
    codeChangeValue.settingCode[index].isDuplicateCode = false;
    setDataView(codeChangeValue);
  };

  const handleDescriptionChange = (index: number, value: string) => {
    const codeChangeValue = { ...dataView };
    codeChangeValue.settingCode[index].description = value;
    setDataView(codeChangeValue);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    formRef.current?.submitForm();
    formRef.current?.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        addOrEditCategory(dataView);
      } else {
        formRef.current?.setValues(dataView);
        setDataView(dataView);
      }
    });
  };

  const addOrEditCategory = useCallback(
    debounce((obj: SettingCode) => {
      if (
        location.state?.categoryDetails !== undefined &&
        obj.settingCode[0].id
      ) {
        toggleLoader(true);
        editSettingCodes(obj.settingCode[0], obj.settingCode[0].id)
          .then(() => {
            toggleLoader(false);
            setTimeout(() => {
              navigate(CODE_LIST, {
                state: {
                  tabPropValue: 2,
                },
              });
            }, 800);

            setOpen(true);
            setToastrVariable('success');
            setToastrDefaultMessage('Setting Code Updated Successfully');
            setToastrId('codelist.settingCode.editSuccessMsg');
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              const response = error as ApiMessage;
              if (response.code === 1060) {
                setToastrId('duplicateCode');
                setToastrDefaultMessage('Code already exists');
              } else {
                setToastrId('failedApiMsg');
                setToastrDefaultMessage(
                  'Oops, something went wrong. Please try again later.',
                );
              }
            }
          });
      } else {
        toggleLoader(true);
        addSettingCodes(obj)
          .then((response) => {
            toggleLoader(false);

            setTimeout(() => {
              if (response.duplicateCode && response.duplicateCode.length > 0) {
                const combinedArray: SubSettingCodes[] = [];

                response.addedCode.forEach((code) => {
                  code.isAddedCode = true;
                  code.isDuplicateCode = false;
                  combinedArray.push(code);
                });

                response.duplicateCode.forEach((code) => {
                  code.isDuplicateCode = true;
                  code.isAddedCode = false;
                  combinedArray.push(code);
                });

                const combinedObj = {
                  settingCode: combinedArray,
                };
                formRef.current?.setValues(combinedObj);
                setDataView(combinedObj);
              } else {
                navigate(CODE_LIST, {
                  state: {
                    tabPropValue: 2,
                  },
                });
              }
            }, 800);
            if (response.addedCode.length > 0) {
              setOpen(true);
              setToastrVariable('success');
              setToastrDefaultMessage('Setting Code Added Successfully');
              setToastrId('codelist.settingCode.addSuccessMsg');
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('codelist.settingCode.addFailureMsg');
              setToastrDefaultMessage('Failed to Add Setting Code!');
            }
          });
      }
    }, 500),
    [],
  );

  const clearForm = () => {
    setOpenModal(false);
    formRef.current?.resetForm();
    setDataView({
      settingCode: [
        {
          codeId: 1,
          code: '',
          description: '',
        },
      ],
    });
  };
  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          clearForm();
        }}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="Cancel"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      <Box component="main">
        <Box component="section">
          <Formik
            innerRef={formRef}
            initialValues={{ settingCode: dataView.settingCode }}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ setFieldValue, values, initialValues, errors, touched }) => (
              <>
                <Box className="rowContainer">
                  <CustomBackArrow
                    onClick={() =>
                      navigate(CODE_LIST, {
                        state: {
                          tabPropValue: 2,
                        },
                      })
                    }
                  />
                  <Box component="div" className="ml-md">
                    {location.state?.categoryDetails === undefined && (
                      <MediumTypography
                        labelid="codelist.settingCode.addText"
                        defaultlabel="Add Setting Code"
                        className="mainText-xxlg"
                      />
                    )}
                    {location.state?.categoryDetails !== undefined && (
                      <MediumTypography
                        labelid="codelist.settingCode.editText"
                        defaultlabel="Edit Setting Code"
                        className="mainText-xxlg"
                      />
                    )}
                  </Box>
                </Box>
                <Card className="formCardview">
                  <Box component="div">
                    <Box
                      sx={{
                        display: 'flex',
                        border: '1px solid rgba(0, 198, 184, 1)',
                        position: 'relative',
                        overflow: 'visible',
                        background: 'rgba(236, 249, 248, 1)',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          position: 'absolute',
                          top: -25,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          padding: '5px',
                        }}
                      ></Box>
                      <Grid
                        container
                        rowSpacing={'24px'}
                        columnSpacing={'40px'}
                        className="formCardview"
                      >
                        {dataView.settingCode.map(
                          (items: SubSettingCodes, i: number) => (
                            <>
                              <Grid
                                item
                                container
                                rowSpacing={'24px'}
                                columnSpacing={'40px'}
                                sx={{ display: 'flex' }}
                                key={i}
                              >
                                <Grid item xs={3}>
                                  <Textinput
                                    name={`code${i}`}
                                    labelid="diagnosticCodelist.enterCode"
                                    defaultlabelid="Enter Code"
                                    sxProps={{
                                      background: 'rgba(255, 255, 255, 1)',
                                    }}
                                    inputProps={{
                                      maxLength: 10,
                                    }}
                                    Value={values.settingCode[i]?.code}
                                    handlechange={(value: string) => {
                                      if (value !== null) {
                                        setFieldValue(
                                          `settingCode[${i}].isDuplicateCode`,
                                          false,
                                        );
                                        setFieldValue(
                                          `settingCode[${i}].code`,
                                          value,
                                        );
                                        handleCodeChange(i, value);
                                      }
                                    }}
                                  />
                                  {errors.settingCode &&
                                    errors.settingCode[i] &&
                                    (errors.settingCode[
                                      i
                                    ] as FormikErrors<SettingCode>) &&
                                    (
                                      errors.settingCode[
                                        i
                                      ] as FormikErrors<SubSettingCodes>
                                    ).code &&
                                    touched.settingCode &&
                                    touched.settingCode[i] &&
                                    touched.settingCode[i].code && (
                                      <SmallTypography
                                        sxProps={{ color: 'red' }}
                                        labelId={
                                          (
                                            errors.settingCode[
                                              i
                                            ] as FormikErrors<SubSettingCodes>
                                          ).code
                                        }
                                        defaultLabelId="Code is Required"
                                      />
                                    )}
                                  {values.settingCode[i]?.isAddedCode && (
                                    <SmallTypography
                                      sxProps={{ color: 'green' }}
                                      labelId="addedCodeTextMesg"
                                      defaultLabelId="Code Added"
                                    />
                                  )}
                                  {values.settingCode[i] &&
                                    values.settingCode[i].isDuplicateCode && (
                                      <SmallTypography
                                        sxProps={{ color: 'red' }}
                                        labelId="duplicateCodeTextMesg"
                                        defaultLabelId="Duplicated Code"
                                      />
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                  <Textinput
                                    name={`description${i}`}
                                    labelid="diagnosticCodelist.enterDesc"
                                    defaultlabelid="Enter Description"
                                    sxProps={{
                                      background: 'rgba(255, 255, 255, 1)',
                                    }}
                                    inputProps={{
                                      maxLength: 100,
                                    }}
                                    Value={values?.settingCode[i]?.description}
                                    handlechange={(value: string) => {
                                      setFieldValue(
                                        `settingCode[${i}].description`,
                                        value,
                                      );
                                      handleDescriptionChange(i, value);
                                    }}
                                  />
                                  {errors.settingCode &&
                                    errors.settingCode[i] &&
                                    (errors.settingCode[
                                      i
                                    ] as FormikErrors<SettingCode>) &&
                                    (
                                      errors.settingCode[
                                        i
                                      ] as FormikErrors<SubSettingCodes>
                                    ).description &&
                                    touched.settingCode &&
                                    touched.settingCode[i] &&
                                    touched.settingCode[i].description && (
                                      <SmallTypography
                                        sxProps={{ color: 'red' }}
                                        labelId={
                                          (
                                            errors.settingCode[
                                              i
                                            ] as FormikErrors<SubSettingCodes>
                                          ).description
                                        }
                                        defaultLabelId="Description is Required"
                                      />
                                    )}
                                  {values.settingCode[i]?.isAddedCode && (
                                    <SmallTypography
                                      sxProps={{ color: 'green' }}
                                      labelId="addedDescTextMesg"
                                      defaultLabelId="Description Added"
                                    />
                                  )}
                                  {/* Temporarily Commented Duplicate Description
                                  {values.settingCode[i]?.isDuplicateCode && (
                                    <SmallTypography
                                      sxProps={{ color: 'red' }}
                                      labelId="duplicateDescTextMesg"
                                      defaultLabelId="Duplicate Description"
                                    />
                                  )} */}
                                </Grid>
                                <Grid item xs={0.5}>
                                  {i >= 1 && (
                                    <DeleteIcon
                                      style={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        width: '22px',
                                        height: '22px',
                                        top: '2px',
                                        right: '30px',
                                      }}
                                      onClick={() => {
                                        const updatedSubCategories = [
                                          ...dataView.settingCode,
                                        ];
                                        updatedSubCategories.splice(i, 1);
                                        formRef.current?.setValues({
                                          ...dataView,
                                          settingCode: updatedSubCategories,
                                        });
                                        setDataView({
                                          ...dataView,
                                          settingCode: updatedSubCategories,
                                        });
                                      }}
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </>
                          ),
                        )}
                        {location.state?.categoryDetails === undefined && (
                          <Grid item xs={4.5}>
                            <ButtonComponent
                              className="btn-add-code"
                              variantType="contained"
                              type="submit"
                              labelId="diagnosticCodelist.addCode"
                              defaultLabelId="+ Add Code"
                              onClick={handleAddLink}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Card>
                <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="right"
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Grid item sx={{ marginRight: '16px' }}>
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        variantType="contained"
                        defaultLabelId="Cancel"
                        labelId="Contacts.cancelbtn"
                        onClick={() => {
                          if (
                            !_.isEqual(
                              initialValues.settingCode,
                              values.settingCode,
                            )
                          ) {
                            setOpenModal(true);
                          } else {
                            navigate(CODE_LIST, {
                              state: {
                                tabPropValue: 2,
                              },
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {location.state?.categoryDetails === undefined && (
                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="BlockTime.addButton"
                          defaultLabelId="Add"
                          onClick={handleSubmit}
                        />
                      )}
                      {location.state?.categoryDetails !== undefined && (
                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="clientpage.Update"
                          defaultLabelId="Update"
                          onClick={handleSubmit}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default AddSettingCode;
