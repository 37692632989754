import { FC } from 'react';
import MediumTypography from '../MediumTypography';
import { Box, Tooltip } from '@mui/material';
import { DateString } from '../../../utils/type';

interface SignatureNameProps {
  name: string | null;
  date: DateString;
}

const SignatureName: FC<SignatureNameProps> = ({ name, date }) => {
  return (
    <>
      <Box className={'flex__ align__items__center mt-md'}>
        <MediumTypography
          labelid="DuplicateClientModal.name"
          defaultlabel="Name:"
          className="mainText-md mr-sm"
        />
        <Tooltip placement="bottom" title={name ?? '-'} arrow followCursor>
          <Box>
            <MediumTypography
              label={name ?? '-'}
              sxProps={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                maxWidth: '250px',
              }}
            />
          </Box>
        </Tooltip>
      </Box>
      <Box className={'flex__ align__items__center'}>
        <MediumTypography
          labelid="dateLabel"
          defaultlabel="Date:"
          className="mainText-md mr-sm"
        />
        <MediumTypography label={date !== null ? date.toString() : '-'} />
      </Box>
    </>
  );
};

export default SignatureName;
