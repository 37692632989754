import { Box, Card, Grid, SxProps } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import DoughnutChart from '../../components/charts/DoughnutChart';
import { ActivitySheet } from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { FC } from 'react';
import { convertTimeDuration, formatStringTime } from '../../utils/dateUtil';
import { useIntl } from 'react-intl';
import { nonWorkingLabelIds, workingLabelIds } from './activityUtils';

interface ActivitySheetChartProps {
  activity: ActivitySheet;
  reload: boolean;
  cardStyle?: SxProps;
}
const ActivitySheetChart: FC<ActivitySheetChartProps> = ({
  reload,
  activity,
  cardStyle,
}) => {
  const rctl = useIntl();

  const getTotalWorkUsage = () => {
    if (activity === undefined) {
      return '00%';
    }
    if (
      activity.anticipatedHoursPerWeek === null ||
      activity.totalBillableHours === null
    ) {
      return '00%';
    }
    const billableHours = formatStringTime(activity.totalBillableHours);
    if (billableHours === 0) {
      return '00%';
    }
    const anticipatedHoursPerWeek = formatStringTime(
      activity.anticipatedHoursPerWeek,
    );
    return ((billableHours / anticipatedHoursPerWeek) * 100).toFixed(2) + '%';
  };

  const getSalariedTotalWorkUsage = () => {
    if (activity === undefined) {
      return '00.00 hrs';
    }
    return activity.totalHoursOut !== null
      ? convertTimeDuration(activity.totalHoursOut, true) + ' hrs'
      : '00.00 hrs';
  };

  const getChartValues = (): number[] => {
    if (activity) {
      const billableHours = formatStringTime(
        activity.totalBillableHours ?? '00:00',
      );
      const anticipatedHoursPerWeek = formatStringTime(
        activity.anticipatedHoursPerWeek ?? '00:00',
      );
      if (anticipatedHoursPerWeek === 0 && billableHours === 0) {
        return [0, 1];
      }
      return [billableHours, anticipatedHoursPerWeek];
    }
    return [0, 0];
  };

  const getSalariedChartValues = (): number[] => {
    if (activity) {
      const etHours = formatStringTime(activity.totalEtHours ?? '00:00');
      const ltiHours = formatStringTime(activity.totalLtiHours ?? '00:00');
      const holidayHours = formatStringTime(
        activity.totalHolidayTimeHours ?? '00:00',
      );
      const vacationHours = formatStringTime(
        activity.totalVacationHours ?? '00:00',
      );
      const profHours = formatStringTime(
        activity.totalProfessionalHours ?? '00:00',
      );
      const otherHours = formatStringTime(activity.totalOtherHours ?? '00:00');
      if (
        etHours === 0 &&
        holidayHours === 0 &&
        vacationHours === 0 &&
        ltiHours === 0 &&
        profHours === 0 &&
        otherHours === 0
      ) {
        return [0, 0, 0, 0, 0, 0, 1];
      } else {
        return [
          etHours,
          ltiHours,
          holidayHours,
          vacationHours,
          profHours,
          otherHours,
        ];
      }
    }
    return [0, 0, 0, 0, 0, 0, 1];
  };

  return (
    <Card sx={{ padding: '16px', display: 'flex', ...cardStyle }}>
      <Grid container>
        <Grid item>
          <Box sx={{ display: 'flex' }}>
            <MediumTypography
              labelid="totalHoursPayText"
              defaultlabel="Total Hours:"
              sxProps={{ color: '#97A6A5', fontSize: '16px' }}
            />
            <MediumTypography
              label={
                activity.totalPayHours !== null
                  ? convertTimeDuration(activity.totalPayHours, true) + ' hrs'
                  : '00.00 hrs'
              }
              sxProps={{
                fontSize: '16px',
                color: '#2A4241',
                fontWeight: 'bold',
                paddingLeft: '8px',
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', marginLeft: '16px' }}>
            <MediumTypography
              labelid="workingHoursText"
              defaultlabel="Working Hours:"
              sxProps={{ color: '#97A6A5', fontSize: '16px' }}
            />
            <MediumTypography
              label={
                activity.totalWorkingHours !== null
                  ? convertTimeDuration(activity.totalWorkingHours, true) +
                    ' hrs'
                  : '00.00 hrs'
              }
              sxProps={{
                fontSize: '16px',
                color: '#2A4241',
                fontWeight: 'bold',
                paddingLeft: '8px',
              }}
            />
          </Box>
        </Grid>
        <Grid item>
          <Box sx={{ display: 'flex', marginLeft: '16px' }}>
            <MediumTypography
              label={`${rctl.formatMessage({
                id: 'anticipatedHoursText',
                defaultMessage: 'Anticipated Hours',
              })}:`}
              sxProps={{ color: '#97A6A5', fontSize: '16px' }}
            />
            <MediumTypography
              label={
                activity.anticipatedHoursPerWeek !== null
                  ? convertTimeDuration(
                      activity.anticipatedHoursPerWeek,
                      true,
                    ) + ' hrs'
                  : '00.00 hrs'
              }
              sxProps={{
                fontSize: '16px',
                color: '#2A4241',
                fontWeight: 'bold',
                paddingLeft: '8px',
              }}
            />
          </Box>
        </Grid>
        <Grid container direction={'row'} paddingTop={'8px'}>
          <Grid item display={'flex'}>
            <DoughnutChart
              redraw={reload}
              data={getChartValues()}
              totalWorkUsage={getTotalWorkUsage()}
              labelIds={workingLabelIds}
            />
          </Grid>

          {activity.employeeType === 'Salaried Exempt' && (
            <>
              <Grid item sx={{ marginLeft: '8px' }}>
                <DoughnutChart
                  redraw={reload}
                  data={getSalariedChartValues()}
                  totalWorkUsage={getSalariedTotalWorkUsage()}
                  subTextId={'hoursOutText'}
                  defaultLable="Hours Out"
                  labelIds={nonWorkingLabelIds}
                />
              </Grid>

              <Grid
                item
                sx={{ marginLeft: '16px' }}
                display={'flex'}
                alignItems={'center'}
              >
                <Box>
                  <Box display={'flex'} marginBottom={'8px'}>
                    <MediumTypography
                      label={`${rctl.formatMessage({
                        id: 'sickHrsText',
                        defaultMessage: 'Sick Hrs',
                      })} : `}
                      sxProps={{
                        color: '#97A6A5',
                        fontWeight: '500',
                        marginRight: '5px',
                      }}
                    />
                    <MediumTypography
                      label={
                        activity.totalSickHours !== null
                          ? convertTimeDuration(activity.totalSickHours, true)
                          : '00.00'
                      }
                      defaultlabel="00:00"
                      sxProps={{
                        color: '#2A4241',
                        fontWeight: '500',
                        marginRight: '5px',
                      }}
                    />
                  </Box>
                  <Box display={'flex'}>
                    <MediumTypography
                      label={`${rctl.formatMessage({
                        id: 'flexHrsText',
                        defaultMessage: 'Flex Hrs',
                      })} : `}
                      sxProps={{
                        color: '#97A6A5',
                        fontWeight: '500',
                        marginRight: '5px',
                      }}
                    />
                    <MediumTypography
                      label={
                        activity.totalFlexHours !== null
                          ? convertTimeDuration(activity.totalFlexHours, true)
                          : '00.00'
                      }
                      sxProps={{
                        color: '#2A4241',
                        fontWeight: '500',
                        marginRight: '5px',
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default ActivitySheetChart;
