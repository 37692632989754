import { EligibilityDeterminationOthers } from '../../../pages/eligibility/types';
import { ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export const addOrUpdateEligibilityDeterminationApi = async (
  id: string,
  params: EligibilityDeterminationOthers,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<
      EligibilityDeterminationOthers,
      EligibilityDeterminationOthers
    >(`eds?evaluationId=${id}`, params),
  );
};

export const getEligibilityDeterminationFormApi = async (
  id: string,
): Promise<EligibilityDeterminationOthers> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EligibilityDeterminationOthers>(
      `eds?evaluationId=${id}`,
    ),
  );
};
