import {
  AlertColor,
  Box,
  Button,
  Card,
  Grid,
  Menu,
  MenuProps,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Theme,
  Toolbar,
  debounce,
  styled,
} from '@mui/material';
import * as yup from 'yup';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import TitleText from '../../components/formlib/TitleText';
import SearchBox from '../../components/formlib/SearchBox';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as Filtericon } from '../../assets/images/FilterIconsvg.svg';
import { FormattedMessage } from 'react-intl';
import { right } from '@popperjs/core';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import { DateString, progessNotesStatusFilter } from '../../utils/type';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import { Formik, FormikProps } from 'formik';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import MediumTypography from '../../components/formlib/MediumTypography';
import ProgressNotesTableRow from './ProgressNotesTableRow';
import AppPagination from '../../components/shared/AppPagination';
import {
  ProgressNotesListResponseType,
  getProgressNotesListApi,
  getProgressNotesListOnClientApi,
} from '../../services/progressNotes/ProgressNotesApi';
import { isCustomError, ApiError } from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import EmptyScreen from '../../components/shared/EmptyScreen';
import {
  FACESHEET,
  PROGRESS_NOTE_CLIENT_SETUP,
  PROGRESS_NOTE_SETUP,
} from '../../routes/Routing';
import { useNavigate } from 'react-router-dom';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import DisplayFilter, { FilterBy } from '../../components/shared/DisplayFilter';
import SearchNotFound from '../../components/formlib/SearchNotFound';
import { checkPermissionForFeature } from '../../utils/checkPermission';

const ITEMS_PER_PAGE = 10;

const CustomMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }: { theme: Theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 100,
    maxWidth: 490,
    padding: '20px 25px',
    boxShadow: '10px 10px 50px 0px rgba(0, 71, 66, 0.3)',
  },
}));

type ProgressNotesFilterType = {
  status: string;
  aptStartDate: DateString;
  aptEndDate: DateString;
};

export interface ProgressNotesListData {
  progressNoteId?: string;
  clientName: string;
  enrollmentId: string;
  appointmentType: string;
  appointmentDate: string;
  attendees: string;
  startTime: string;
  duration: string;
  status: string;
  actions?: string;
}

function createHeadCell(
  id: keyof ProgressNotesListData,
  labelId: string,
  defaultLabelId: string,
): HeadCell<ProgressNotesListData> {
  return {
    id,
    labelId,
    defaultLabelId,
    numeric: false,
    requiredSorting: false,
  };
}

function deepEqual(
  obj1: ProgressNotesFilterType,
  obj2: ProgressNotesFilterType,
): boolean {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

const HeadCells: HeadCell<ProgressNotesListData>[] = [
  createHeadCell('clientName', 'Dashboard.ClientName', 'Client Name'),
  createHeadCell('enrollmentId', 'Facesheet.DPHID', 'Enrollment ID'),
  createHeadCell(
    'appointmentType',
    'AddNewSchedulerEvent.appointmentType',
    'Appointment Type',
  ),
  createHeadCell(
    'appointmentDate',
    'ProgressNoteList.appointmentDatetitle',
    'Appointment Date',
  ),
  createHeadCell('attendees', 'ProgressNoteList.attendeestitle', 'Attendees'),
  createHeadCell('startTime', 'startTimeText', 'Start Time'),
  createHeadCell('duration', 'ProgressNote.duration', 'Duration'),
  createHeadCell('status', 'Dashboard.Status', 'Status'),
  createHeadCell('actions', 'ConsentForms.listHeaderActions', 'Actions'),
];

const validationSchema = yup.object().shape({
  status: yup.string().nullable(),
  aptStartDate: yup
    .string()
    .nullable()
    .test(
      'required-if-end-date',
      'ProgressNoteList.aptStartDateReq',
      function (value) {
        const { aptEndDate } = this.parent;
        if (aptEndDate && !value) {
          return false;
        }
        return true;
      },
    ),
  aptEndDate: yup
    .string()
    .nullable()
    .test(
      'required-if-start-date',
      'ProgressNoteList.aptEndDateReq',
      function (value) {
        const { aptStartDate } = this.parent;
        if (aptStartDate && !value) {
          return false;
        }
        return true;
      },
    ),
});
const validateForm = (value: ProgressNotesFilterType) => {
  const errors: Partial<ProgressNotesFilterType> = {};

  if (value.aptEndDate) {
    if (dayjs(value.aptEndDate).isBefore(dayjs(value.aptStartDate))) {
      errors.aptEndDate = 'endDateGreaterThanStartDate';
    }
    if (datePickerMinDateMaxDateValidate(value.aptEndDate)) {
      errors.aptEndDate = 'datePickerMinDateMaxDateValidate';
    }
  }

  if (
    datePickerMinDateMaxDateValidate(value.aptStartDate) ||
    datePickerMinDateMaxDateValidate(value.aptEndDate)
  ) {
    errors.aptStartDate = 'datePickerMinDateMaxDateValidate';
    errors.aptEndDate = 'datePickerMinDateMaxDateValidate';
  }
  return errors;
};

const ProgressNoteListView = () => {
  const navigate = useNavigate();
  const { loader, toggleLoader } = useContext(
    LoaderContext,
  ) as LoaderContextType;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [progressNotesListData, setProgressNotesListData] = useState<
    ProgressNotesListResponseType[]
  >([]);
  const [progressNotesListDataCount, setProgressNotesListDataCount] =
    useState(0);
  const formRef = useRef<FormikProps<ProgressNotesFilterType>>(null);
  const [page, setPage] = useState<number>(0);

  const [filterPlainData, setFilterPlainData] =
    useState<ProgressNotesFilterType>({
      status: '',
      aptStartDate: '',
      aptEndDate: '',
    });
  const [minApt, setMinApt] = useState<Date>();

  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [routePath, setRoutePath] = useState('Clinician');
  const [searchquery, setSearchquery] = useState('');
  const [filterBy, setFilterBy] = useState<FilterBy[]>([]);
  const [showEmptyScreen, setShowEmptyScreen] = useState(false);
  const clientId = localStorage.getItem('ClientId');

  const ClinicianHeadCells =
    checkPermissionForFeature('backend.progress_note', 'viewPermission') ===
    false
      ? HeadCells.slice(0, -1)
      : HeadCells;
  const ClientHeadCells =
    checkPermissionForFeature('backend.progress_note', 'viewPermission') ===
    false
      ? HeadCells.slice(2, -1)
      : HeadCells.slice(2);

  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);

  const openit = Boolean(anchorEl);
  const currentUrl = document.location.pathname;

  useLayoutEffect(() => {
    if (currentUrl === '/client/progress-note') {
      setRoutePath('Client');
      getProgressNotesListOnClient();
    } else {
      setRoutePath('Clinician');
      getProgressNotesList();
    }
  }, []);

  useEffect(() => {
    if (currentUrl === '/client/progress-note') {
      getProgressNotesListOnClient();
    } else {
      getProgressNotesList();
    }
  }, [searchquery, page, filterPlainData]);

  const getProgressNotesList = async () => {
    setProgressNotesListData([]);
    toggleLoader(true);
    await getProgressNotesListApi(
      searchquery,
      filterPlainData.status,
      filterPlainData.aptStartDate
        ? filterPlainData.aptStartDate.toString()
        : '',
      filterPlainData.aptEndDate ? filterPlainData.aptEndDate.toString() : '',
      page,
      ITEMS_PER_PAGE,
    )
      .then((response) => {
        if (response) {
          setProgressNotesListData(response.progressNotes);
          setProgressNotesListDataCount(response.count);
          if (response.count === 0 && page === 0) {
            setShowEmptyScreen(true);
          } else {
            setShowEmptyScreen(false);
          }
          toggleLoader(false);
        } else {
          toggleLoader(false);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('ConsentForms.concentLoadError');
          setToastrDefaultMessage('Failed to load Consent details');
        }
      });
  };

  const getProgressNotesListOnClient = async () => {
    setProgressNotesListData([]);
    if (clientId) {
      toggleLoader(true);
      await getProgressNotesListOnClientApi(
        clientId,
        searchquery,
        filterPlainData.status,
        filterPlainData.aptStartDate
          ? filterPlainData.aptStartDate.toString()
          : '',
        filterPlainData.aptEndDate ? filterPlainData.aptEndDate.toString() : '',
        page,
        ITEMS_PER_PAGE,
      )
        .then((response) => {
          if (response) {
            setProgressNotesListData(response.progressNotes);
            setProgressNotesListDataCount(response.count);
            if (response.count === 0 && page === 0) {
              setShowEmptyScreen(true);
            } else {
              setShowEmptyScreen(false);
            }
            toggleLoader(false);
          } else {
            toggleLoader(false);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('ConsentForms.concentLoadError');
            setToastrDefaultMessage('Failed to load Consent details');
          }
        });
    }
  };

  const handlefilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlefilterClose = () => {
    setAnchorEl(null);
  };
  const handleFilterSubmit = (values: ProgressNotesFilterType) => {
    const filterDataBy: FilterBy[] = [];
    setFilterPlainData(values);
    if (values.aptStartDate != null && values.aptStartDate !== '') {
      filterDataBy.push({
        key: 'ProgressNoteList.appointmentDatetitle',
        value: values.aptStartDate + ' - ' + values.aptEndDate,
      });
    }
    if (values.status != null && values.status !== '') {
      filterDataBy.push({
        key: 'Dashboard.Status',
        value: values.status,
      });
    }
    setFilterBy(filterDataBy);
    if (page >= 0) {
      setPage(0);
    }

    if (filterDataBy.length <= 2) {
      if (filterDataBy.some((item) => item.value === 'false')) {
        clearForm();
        setFilterBy([]);
      }
    }
    setAnchorEl(null);
  };
  const handleClose = (reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const onViewClick = (selectedRow: ProgressNotesListResponseType) => {
    if (routePath === 'Client') {
      navigate(PROGRESS_NOTE_CLIENT_SETUP, {
        state: {
          sortRequired: false,
          eventId: selectedRow.commonAppointmentId,
          attendeeId: [{ id: selectedRow.attendeeId }],
          screenFrom: 'ProgressNoteFacesheet',
        },
      });
    } else {
      navigate(PROGRESS_NOTE_SETUP, {
        state: {
          sortRequired: true,
          eventId: selectedRow.commonAppointmentId,
          attendeeId: [{ id: selectedRow.attendeeId }],
          clientIds: [selectedRow.clientId],
          screenFrom: 'ProgressNoteEmployee',
        },
      });
    }
  };

  const isValidInput = (value: string) => {
    const allowedCharacters = /^[a-zA-Z,'.\s!-]*$/;
    return allowedCharacters.test(value);
  };

  const handleSearchChange = useCallback(
    debounce((value: string) => {
      if (isValidInput(value)) {
        setPage(0);
        setSearchquery(value);
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('Dashboard.ClientList.searchError');
        setToastrDefaultMessage(
          'Only alphanumeric characters, commas, periods, spaces, exclamation marks, and hyphens are allowed to be searched.',
        );
      }
    }, 500),
    [],
  );

  const clearForm = () => {
    setPage(0);
    formRef.current?.resetForm();
    setFilterPlainData({
      aptStartDate: '',
      aptEndDate: '',
      status: '',
    });
    setFilterBy([]);
    setAnchorEl(null);
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '24px',
        }}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Box className="flex__ align__items__center">
              {routePath === 'Client' && (
                <Box className="flex__ mr-md">
                  <CustomBackArrow onClick={() => navigate(FACESHEET)} />
                </Box>
              )}
              <Box sx={{ flexGrow: 1 }}>
                <TitleText
                  labelid={
                    routePath === 'Client'
                      ? 'ProgressNoteList.title'
                      : 'ProgressNoteList.myProgressNotestitle'
                  }
                  defaultlabel="My Progress Notes"
                />
              </Box>
              <Box sx={{ flexGrow: 0, mx: '16px' }}>
                <SearchBox
                  labelId={
                    routePath === 'Client'
                      ? 'ProgressNoteList.searchAttendee'
                      : 'Clientdashboard.searchText'
                  }
                  defaultlabel={
                    routePath === 'Client' ? 'Search Attendee' : 'Search Client'
                  }
                  onChange={handleSearchChange}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 0,
                }}
              >
                <Button
                  className="btn-primary btn-filter"
                  variant="outlined"
                  onClick={handlefilter}
                  endIcon={<KeyboardArrowDownIcon />}
                  startIcon={<Filtericon />}
                >
                  <FormattedMessage id="FiterText" defaultMessage="Filter" />
                </Button>
                <MemorizedCustomMenu
                  id="demo-customized-menu"
                  anchorEl={anchorEl}
                  open={openit}
                  onClose={handlefilterClose}
                >
                  <Formik
                    innerRef={formRef}
                    initialValues={filterPlainData}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                      handleFilterSubmit(values);
                    }}
                    validate={validateForm}
                  >
                    {({
                      setFieldValue,
                      handleSubmit,
                      errors,
                      touched,
                      values,
                    }) => (
                      <>
                        <Grid container rowSpacing={2} columnSpacing={1}>
                          <Grid item xs={5.75}>
                            <DatePickerComponent
                              name="aptStartDate"
                              value={
                                values.aptStartDate !== null
                                  ? dayjs(values.aptStartDate)
                                  : null
                              }
                              labelid="ProgressNoteList.aptStartDatetitle"
                              defaultlabelid="Appointment Start Date"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('aptStartDate', null);
                                } else {
                                  setFieldValue('aptStartDate', formattedDate);
                                }
                                if (
                                  values &&
                                  values.aptEndDate !== null &&
                                  values.aptEndDate > formattedDate
                                ) {
                                  setFieldValue('aptEndDate', null);
                                }
                                const newEndDate =
                                  dayjs(formattedDate).toDate();
                                setMinApt(newEndDate);
                                setFieldValue('aptEndDate', null);
                              }}
                            />
                            {errors.aptStartDate && touched.aptStartDate && (
                              <MediumTypography
                                labelid={errors.aptStartDate}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>
                          <Grid item xs={0.5}>
                            <MediumTypography
                              labelid="Dashboard.to"
                              defaultlabel="to"
                              sxProps={{
                                textAlign: 'center',
                                margin: 'revert',
                              }}
                            />
                          </Grid>
                          <Grid item xs={5.75}>
                            <DatePickerComponent
                              name="aptEndDate"
                              value={
                                values.aptEndDate !== null
                                  ? dayjs(values.aptEndDate)
                                  : null
                              }
                              minDate={dayjs(minApt)}
                              labelid="ProgressNoteList.aptEndDatetitle"
                              defaultlabelid="Appointment End Date"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('aptEndDate', null);
                                } else {
                                  setFieldValue('aptEndDate', formattedDate);
                                }
                              }}
                            />
                            {errors.aptEndDate && touched.aptEndDate && (
                              <MediumTypography
                                labelid={errors.aptEndDate}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>
                          <Grid item xs={12}>
                            <DropdownComponent
                              labelid="Dashboard.Status"
                              defaultlabelid="Status"
                              name="status"
                              value={values.status}
                              handleChange={(value: string) => {
                                setFieldValue('status', value);
                              }}
                              names={progessNotesStatusFilter}
                            />
                          </Grid>
                          <Grid item xs={12} textAlign={right} mt={1.5}>
                            <Box
                              sx={{ justifyContent: 'right', display: 'flex' }}
                            >
                              <Box className="mr-md">
                                <ButtonComponent
                                  className="btn-primary btn-cancel btn_width_90px"
                                  variantType="contained"
                                  labelId="Dashboard.filtercancel"
                                  defaultLabelId="Cancel"
                                  onClick={() => {
                                    setAnchorEl(null);
                                  }}
                                  disabled={loader}
                                />
                              </Box>
                              <Box>
                                <ButtonComponent
                                  className="btn-primary btn-submit btn_width_90px"
                                  variantType="contained"
                                  type="submit"
                                  labelId="Dashboard.filtersubmit"
                                  defaultLabelId="Apply"
                                  onClick={() => {
                                    if (deepEqual(filterPlainData, values)) {
                                      return;
                                    }
                                    handleSubmit();
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </Formik>
                </MemorizedCustomMenu>
              </Box>
            </Box>
            {(filterPlainData.aptStartDate !== '' ||
              filterPlainData.aptEndDate !== '' ||
              filterPlainData.status !== '') && (
              <DisplayFilter
                filteredData={filterBy}
                handleCloseClick={(index: number) => {
                  if (filterBy.length < 2) {
                    clearForm();
                  } else {
                    setFilterPlainData({
                      aptStartDate:
                        filterBy[index].key ===
                        'ProgressNoteList.appointmentDatetitle'
                          ? ''
                          : filterPlainData.aptStartDate,
                      aptEndDate:
                        filterBy[index].key ===
                        'ProgressNoteList.appointmentDatetitle'
                          ? ''
                          : filterPlainData.aptEndDate,
                      status:
                        filterBy[index].key === 'Dashboard.Status'
                          ? ''
                          : filterPlainData.status,
                    });
                    const newFilterBy = filterBy.filter((_, i) => i !== index);
                    setFilterBy(newFilterBy);
                  }
                }}
                handleClearClick={() => {
                  clearForm();
                }}
              />
            )}
          </Box>
        </Toolbar>

        {progressNotesListData.length > 0 && (
          <Card>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHeader
                  className="listDataTableHead"
                  headerNames={
                    routePath === 'Clinician'
                      ? ClinicianHeadCells
                      : ClientHeadCells
                  }
                  checkBoxRequired={false}
                />
                <TableBody className="tableRowcss">
                  {progressNotesListData.length !== 0 &&
                    progressNotesListData.map((progressNotesData) => (
                      <ProgressNotesTableRow
                        key={progressNotesData.progressNotesId}
                        data={progressNotesData}
                        routePath={routePath}
                        onView={onViewClick}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        )}
        {progressNotesListData.length > 0 &&
          progressNotesListDataCount > ITEMS_PER_PAGE && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <AppPagination
                pageNumber={page}
                paginationCount={progressNotesListDataCount}
                handleChangePage={(_event, newPage) => {
                  if (newPage - 1 !== page) {
                    setPage(newPage - 1);
                  }
                }}
              />
            </Box>
          )}
      </Box>

      {filterBy.length === 0 &&
        searchquery === '' &&
        showEmptyScreen &&
        progressNotesListData.length === 0 && (
          <Box component="main" className="mb-md">
            <Box component="section">
              <EmptyScreen
                titleLabelId="noProgressFoundText"
                defaultTitleText="No Progress Note found yet"
                showButton={false}
              />
            </Box>
          </Box>
        )}

      {filterBy.length > 0 &&
        progressNotesListData.length === 0 &&
        showEmptyScreen && <SearchNotFound />}
      {searchquery != '' && progressNotesListData.length === 0 && (
        <SearchNotFound />
      )}
    </>
  );
};

export default ProgressNoteListView;
