import { ReactComponent as AcceptIcon } from '../../assets/images/acceptIcon.svg';
import { ReactComponent as RejectIcon } from '../../assets/images/rejectIcon.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import { Box } from '@mui/system';

type BulckApproveRejectActionProps = {
  handleBulkApprove: () => void;
  handleBulkReject: () => void;
  height?: number | string;
  seperatorHeight?: number | string;
};

const BulckApproveRejectAction = ({
  handleBulkApprove,
  handleBulkReject,
  height,
  seperatorHeight,
}: BulckApproveRejectActionProps) => {
  return (
    <Box
      className="flex__ align__items__center pl-sm pr-sm"
      sx={{
        border: '1px solid #00C6B8',
        height: height ? height : '32px',
        borderRadius: '4px',
      }}
    >
      <Box
        className="flex__ align__items__center cursor__pointer"
        onClick={handleBulkApprove}
      >
        <AcceptIcon />
        <MediumTypography
          className="pl-sm"
          labelid="approveAll"
          defaultlabel="Approve All"
          textColor="#2A4241"
        />
      </Box>
      <Box
        sx={{
          border: '1px solid #97A6A5',
          height: seperatorHeight ? seperatorHeight : '20px',
        }}
        className="mr-lg ml-lg"
      />
      <Box
        className="flex__ align__items__center cursor__pointer"
        onClick={handleBulkReject}
      >
        <RejectIcon />
        <MediumTypography
          className="pl-sm"
          labelid="rejectAll"
          defaultlabel="Reject All"
          textColor="#2A4241"
        />
      </Box>
    </Box>
  );
};

export default BulckApproveRejectAction;
