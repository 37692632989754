import { Box, Dialog, Grid, Radio } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as WarningIcon } from '../../assets/images/WarningIcon.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { SimilarClient } from './type';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import { ReactComponent as StatusIcon } from '../../assets/images/status.svg';
import { getStatusColor } from '../../utils/utilities';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import dayjs from 'dayjs';
import { DateString } from '../../utils/type';
import { formatName } from '../../utils/nameUtils';

interface ModelPropType {
  showModal: boolean;
  referralBeginDate: DateString;
  onModalClose: () => void;
  data: SimilarClient[];
  addNewReferral: (id: string) => void;
  addNewClient: () => void;
}

interface SimilarClientListType {
  clients: SimilarClient[];
  referralBeginDate: DateString;
  onClientSelect: (id: string) => void;
}

const SimilarClientList: FC<SimilarClientListType> = ({
  clients,
  referralBeginDate,
  onClientSelect,
}) => {
  const getClientsDetails = (client: SimilarClient) => {
    const clientKeys: DataJsonType[] = [];
    if (client) {
      clientKeys.push({
        id: 'sex',
        labelid: 'DuplicateClientModal.sex',
        defaultLabel: 'Sex:',
        value: client.sex === null ? '-' : convertGenderToFrontend(client.sex),
      });
      clientKeys.push({
        id: 'dob',
        labelid: 'DuplicateClientModal.dob',
        defaultLabel: 'Date of Birth:',
        value: client.dateOfBirth
          ? dayjs(client.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : '-',
      });
      clientKeys.push({
        id: 'enrollmentId',
        labelid: 'ProgressNote.DPHID',
        defaultLabel: 'Enrollment ID:',
        value: client.dphId ?? '-',
      });
      clientKeys.push({
        id: 'program',
        labelid: 'DuplicateClientModal.program',
        defaultLabel: 'Program:',
        value: client.programAcronym ?? '-',
      });
      clientKeys.push({
        id: 'referralBeginDate',
        labelid: 'sspReferral.referralDate',
        defaultLabel: 'Referral Begin Date:',
        value: client.referralBeginDate
          ? dayjs(client.referralBeginDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : '-',
      });
      clientKeys.push({
        id: 'referralEndDate',
        labelid: 'sspReferral.referralEndDate',
        defaultLabel: 'Referral End Date:',
        value: client.referralEndDate
          ? dayjs(client.referralEndDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          : '-',
      });
    }
    return clientKeys;
  };

  return (
    <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
      {clients.map((client) => (
        <Box
          key={client.clientId}
          className={'flex__ mt-lg'}
          sx={{
            pointerEvents:
              client.status === 'Pending' || client.status === 'Active'
                ? 'none'
                : 'auto',
            opacity:
              client.status === 'Pending' || client.status === 'Active'
                ? '0.6'
                : '1 ',
          }}
        >
          <Box>
            <Radio
              onClick={() => onClientSelect(client.clientId)}
              checked={client.isChecked}
              sx={{
                marginLeft: '0px !important',
                color: '#97A6A5',
                '&.Mui-checked': {
                  color: '#008C82',
                },
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <Box className={'flex__ '} sx={{ alignItems: 'center' }}>
              <MediumTypography
                className="mainText-md mr-xs"
                sxProps={{ alignSelf: 'center' }}
                label={`${formatName(
                  client.firstName,
                  client.middleName,
                  client.lastName,
                  client.suffix,
                )} - `}
              />
              <StatusIcon fill={getStatusColor(client.status)} />
              <MediumTypography
                label={`${client.status ?? ''}`}
                className="ml-xs"
              />
            </Box>
            <Box>
              <Grid container>
                <Grid direction={'row'} item>
                  <KeyValueComponent data={getClientsDetails(client)} />
                </Grid>
                {client.isChecked &&
                  referralBeginDate &&
                  client.referralEndDate &&
                  dayjs(referralBeginDate).isBefore(
                    dayjs(client.referralEndDate),
                  ) && (
                    <Grid item xs={12}>
                      <MediumTypography
                        className="errorText-md mt-sm"
                        labelid="referralBeginDateOverlappingText"
                        defaultlabel="Referral Begin Date is overlapping with this client referral’s end date"
                      />
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Box className="defaultHorizontalLine" />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const SimilarClientsModal: FC<ModelPropType> = ({
  data,
  showModal,
  addNewClient,
  onModalClose,
  addNewReferral,
  referralBeginDate,
}) => {
  const [clients, setClients] = useState<SimilarClient[]>(data);
  const [disableButton, setDisableButton] = useState<boolean>(true);

  useEffect(() => {
    const clientChecked = clients.find((client) => {
      return client.isChecked;
    });
    if (clientChecked) {
      if (clientChecked.referralEndDate && referralBeginDate) {
        const dateOverlapping = dayjs(referralBeginDate).isBefore(
          dayjs(clientChecked.referralEndDate),
        );
        setDisableButton(dateOverlapping);
      } else {
        setDisableButton(false);
      }
    } else {
      setDisableButton(true);
    }
  }, [clients]);

  const onClientSelect = (id: string) => {
    const updatedClients = clients.map((client) => {
      if (client.clientId === id) {
        client.isChecked = !client.isChecked;
      } else {
        client.isChecked = false;
      }
      return client;
    });
    setClients(updatedClients);
  };

  return (
    <Dialog open={showModal} onClose={onModalClose} fullWidth maxWidth={'md'}>
      <Box className={'flex__ p-xlg'}>
        <Box>
          <WarningIcon />
        </Box>
        <Box className={'ml-xlg'} sx={{ flexGrow: 1 }}>
          <Box>
            <MediumTypography
              className="mainText-xxlg mt-xs"
              labelid="similarClientsFoundTitleText"
              defaultlabel="Similar Client(s) Found"
            />
            <MediumTypography
              sxProps={{ lineHeight: '18px', marginTop: '8px' }}
              labelid="similarClientsFoundSubTitleText"
              defaultlabel="The following client(s) were found to be similar to the client being added. Please select a matching client if relevant."
            />

            <SimilarClientList
              clients={clients}
              referralBeginDate={referralBeginDate}
              onClientSelect={(id) => {
                onClientSelect(id);
              }}
            />
          </Box>

          <Box className="flex__ mt-xlg" sx={{ justifyContent: 'flex-end' }}>
            <Box className="flex__ flex-wrap-wrap">
              <ButtonComponent
                className="btn-primary btn-cancel btn_width_90px mr-md"
                variantType="contained"
                labelId="Clientpage.cancelbtn"
                defaultLabelId="Cancel"
                onClick={onModalClose}
              />

              <ButtonComponent
                disabled={disableButton}
                className="btn-primary btn-submit mr-md"
                variantType="contained"
                labelId="createNewReferralText"
                defaultLabelId="Create new referral"
                onClick={() => {
                  const client = clients.find((c) => {
                    return c.isChecked;
                  });
                  if (client) {
                    addNewReferral(client.clientId);
                  }
                }}
              />

              <ButtonComponent
                disabled={!disableButton}
                className="btn-primary btn-submit"
                variantType="contained"
                labelId="createNewClientText"
                defaultLabelId="Create new client"
                onClick={() => {
                  addNewClient();
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SimilarClientsModal;
