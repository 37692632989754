import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { ReactComponent as Delete } from '../../assets/images/deleteAlertIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/deleteAlertIcon.svg';
import { ReactComponent as HideIcon } from '../../assets/images/hideIcon.svg';
import { ReactComponent as PrinterIcon } from '../../assets/images/printerIcon.svg';
import { ReactComponent as FilledAddIcon } from '../../assets/images/filledAddIcon.svg';
import InformationModal from './InformationModal';
import DevelopmentalSummaryData from './IFSP/DevelopmentalSummary.json';
import SimpleTextAndTextArea from '../../components/forms/SimpleTextAndTextArea';
import IfspContext, {
  DevelopmentalSummaryProgressReview,
} from './ifspContextApi/IfspContex';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import ModalPopup from '../../components/formlib/ModalPopup';

interface DevelopmentalSummaryProps {
  close: boolean;
  handelClose: Dispatch<SetStateAction<boolean>>;
  info: boolean;
  closeInfo: (info: boolean) => void;
  openInfo: (info: boolean) => void;
  isIfspScreenLocked: boolean;
  selectedLanguage: string;
}

const DevelopmentalSummary: FC<DevelopmentalSummaryProps> = ({
  close,
  handelClose,
  info,
  openInfo,
  closeInfo,
  isIfspScreenLocked,
  selectedLanguage,
}) => {
  const { ifsp, updateIfspContext } = useContext(IfspContext);
  const [showSubSection, setShowSubSection] = useState<boolean>(true);
  const [openIndex, setOpenIndex] = useState<number>(0);
  const [openAccordianIndex, setOpenAccordianIndex] = useState<number>(0);
  const [openAccordian, setOpenAccordian] = useState<boolean>(false);

  const [developmentalSummaryMainData, setDevelopmentalSummaryMainData] =
    useState<DevelopmentalSummaryProgressReview[]>(
      ifsp.ifspTeam.forms.developmentalSummary[openAccordianIndex]
        ? ifsp.ifspTeam.forms.developmentalSummary[openAccordianIndex]
            .developmentalSummaryProgressReview
        : [],
    );

  const [deleteNewSection, setDeleteNewSection] = useState<boolean>(false);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.clients', 'editPermission') &&
      selectedLanguage === 'en'
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, [selectedLanguage]);
  useEffect(() => {
    if (close) setOpenAccordian(false);
  }, [close]);
  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ifsp.ifspTeam.forms.developmentalSummary[openAccordianIndex]) {
      setDevelopmentalSummaryMainData(
        ifsp.ifspTeam.forms.developmentalSummary[openAccordianIndex]
          .developmentalSummaryProgressReview,
      );
    }
  }, [ifsp.ifspTeam.forms.developmentalSummary, openAccordianIndex]);

  useEffect(() => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          developmentalSummary: ifsp.ifspTeam.forms.developmentalSummary.map(
            (data, index) => {
              if (index === openAccordianIndex) {
                return {
                  ...data,
                  isChecked: true,
                  developmentalSummaryProgressReview:
                    developmentalSummaryMainData,
                };
              }
              return data;
            },
          ),
        },
      },
    });
  }, [developmentalSummaryMainData]);

  const addSubSection = () => {
    setDevelopmentalSummaryMainData([
      ...developmentalSummaryMainData,
      {
        adaptive: '',
        personalSocial: '',
        communication: '',
        motor: '',
        cognition: '',
      },
    ]);
    setOpenIndex(openIndex + 1);
  };

  const handleChange = (
    subSectionIndex: number,
    value: { commentId: number; comments: string },
  ) => {
    const updatedData = [...developmentalSummaryMainData];
    switch (value.commentId) {
      case 0:
        updatedData[subSectionIndex].adaptive = value.comments;
        break;
      case 1:
        updatedData[subSectionIndex].personalSocial = value.comments;
        break;
      case 2:
        updatedData[subSectionIndex].communication = value.comments;
        break;
      case 3:
        updatedData[subSectionIndex].motor = value.comments;
        break;
      case 4:
        updatedData[subSectionIndex].cognition = value.comments;
        break;
      default:
        break;
    }
    setDevelopmentalSummaryMainData(updatedData);
  };

  const transformToSimpleTextAreaValue = (subSectionIndex: number) => {
    const data = developmentalSummaryMainData[subSectionIndex];
    return [
      { commentId: 0, comments: data.adaptive },
      { commentId: 1, comments: data.personalSocial },
      { commentId: 2, comments: data.communication },
      { commentId: 3, comments: data.motor },
      { commentId: 4, comments: data.cognition },
    ];
  };

  const deleteSubSection = (index: number) => {
    const newDevelopmentalSummaryMainData = developmentalSummaryMainData.filter(
      (_, i) => i !== index,
    );
    setDevelopmentalSummaryMainData(newDevelopmentalSummaryMainData);
    if (openIndex === index) {
      setShowSubSection(false);
    } else if (openIndex && openIndex > index) {
      setOpenIndex(openIndex - 1);
    }
  };

  const createNewSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          developmentalSummary: [
            ...ifsp.ifspTeam.forms.developmentalSummary,
            {
              developmentalSummaryProgressReview: [
                {
                  adaptive: '',
                  personalSocial: '',
                  communication: '',
                  motor: '',
                  cognition: '',
                },
              ],
              isChecked: true,
              sessionReviewedDate: '',
            },
          ],
        },
      },
    });
  };

  const deleteSection = () => {
    updateIfspContext({
      ...ifsp,
      ifspTeam: {
        ...ifsp.ifspTeam,
        forms: {
          ...ifsp.ifspTeam.forms,
          developmentalSummary: ifsp.ifspTeam.forms.developmentalSummary.slice(
            0,
            -1,
          ),
        },
      },
    });
    setDeleteNewSection(false);
  };

  return (
    <Box component="main">
      {info && (
        <InformationModal
          open={info}
          titleLabel="IFSP.Information"
          titleDefaultLabel="Information"
          buttonLabel="IFSP.InfoOkButton"
          buttonDefaultLabel="Ok"
          onClose={() => {
            closeInfo(info);
          }}
          info={DevelopmentalSummaryData.info}
        />
      )}
      <ModalPopup
        open={deleteNewSection}
        description="deleteConfirmationMessage"
        onOk={() => {
          deleteSection();
        }}
        onCancel={() => {
          setDeleteNewSection(false);
        }}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="cancelText"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      {ifsp.ifspTeam.forms.developmentalSummary.map((ifspData, mainIndex) => (
        <Box
          component="section"
          className="p-lg borderRadius4 gray3 mb-lg"
          sx={{
            border: '1px solid #00C6B8',
          }}
        >
          <Box component="div" className="flex__justify__space-between">
            <Box component="div" className="flex__ align__items__center">
              <MediumTypography
                labelid="IFSP.session5"
                defaultlabel="Developmental Summary"
                fontSize="18px"
                fontweight={600}
                textColor="#2A4241"
              />
              {ifsp.ifspTeam.forms.developmentalSummary[mainIndex]
                .sessionReviewedDate ? (
                <Box
                  sx={{
                    display: 'flex',
                    padding: '4px 8px 4px 8px',
                    borderRadius: '4px',
                    marginLeft: '24px',
                    backgroundColor: '#97A6A5',
                    alignItems: 'center',
                  }}
                >
                  <MediumTypography
                    labelid="IFSP.UpdatedOn"
                    defaultlabel="Updated On: "
                    fontSize="14px"
                    fontweight={400}
                    textColor="#fff"
                  />
                  <MediumTypography
                    fontSize="14px"
                    fontweight={400}
                    textColor="#fff"
                    label={ifspData.sessionReviewedDate as string}
                  />
                </Box>
              ) : null}
            </Box>
            {openAccordian && mainIndex === openAccordianIndex ? (
              <Box className="flex__ align__items__center">
                {!isIfspScreenLocked &&
                !isScreenLocked &&
                ifsp.ifspTeam.forms.developmentalSummary[
                  ifsp.ifspTeam.forms.developmentalSummary.length - 1
                ].sessionReviewedDate &&
                mainIndex ===
                  ifsp.ifspTeam.forms.developmentalSummary.length - 1 ? (
                  <Box
                    className="cursorPointer"
                    sx={{
                      padding: '4px 8px 4px 8px',
                      borderRadius: '4px',
                      marginRight: '24px',
                      border: '1px solid #008C82',
                      backgroundColor: '#fff',
                    }}
                    onClick={() => {
                      createNewSection();
                    }}
                  >
                    <MediumTypography
                      labelid="IFSP.AddNewSection"
                      defaultlabel="+ Add New Section"
                      fontSize="12px"
                      fontweight={600}
                    />
                  </Box>
                ) : !isIfspScreenLocked &&
                  !isScreenLocked &&
                  !ifsp.ifspTeam.forms.developmentalSummary[
                    ifsp.ifspTeam.forms.developmentalSummary.length - 1
                  ].sessionReviewedDate &&
                  mainIndex ===
                    ifsp.ifspTeam.forms.developmentalSummary.length - 1 &&
                  mainIndex > 0 ? (
                  <Box
                    component={'div'}
                    className="cursorPointer flex__ align__items__center pr-md"
                    onClick={() => {
                      setDeleteNewSection(true);
                    }}
                    sx={{
                      padding: '4px 8px 4px 8px',
                      borderRadius: '4px',
                      marginRight: '24px',
                      border: '1px solid #EB4C60',
                      backgroundColor: '#fff',
                    }}
                  >
                    <DeleteIcon className="pr-xs" />
                    <MediumTypography
                      labelid="IFSP.DeleteNewSection"
                      defaultlabel="Delete"
                      fontSize="12px"
                      fontweight={600}
                    />
                  </Box>
                ) : null}
                <UpArrow
                  className="cursorPointer"
                  onClick={() => {
                    setOpenAccordianIndex(openAccordianIndex);
                    setOpenAccordian(false);
                  }}
                />
              </Box>
            ) : (
              <Box className="flex__ align__items__center">
                {!isIfspScreenLocked &&
                !isScreenLocked &&
                ifsp.ifspTeam.forms.developmentalSummary[
                  ifsp.ifspTeam.forms.developmentalSummary.length - 1
                ].sessionReviewedDate &&
                mainIndex ===
                  ifsp.ifspTeam.forms.developmentalSummary.length - 1 ? (
                  <Box
                    className="cursorPointer"
                    sx={{
                      padding: '4px 8px 4px 8px',
                      borderRadius: '4px',
                      marginRight: '24px',
                      border: '1px solid #008C82',
                      backgroundColor: '#fff',
                    }}
                    onClick={() => {
                      createNewSection();
                    }}
                  >
                    <MediumTypography
                      labelid="IFSP.AddNewSection"
                      defaultlabel="+ Add New Section"
                      fontSize="12px"
                      fontweight={600}
                    />
                  </Box>
                ) : !isIfspScreenLocked &&
                  !isScreenLocked &&
                  !ifsp.ifspTeam.forms.developmentalSummary[
                    ifsp.ifspTeam.forms.developmentalSummary.length - 1
                  ].sessionReviewedDate &&
                  mainIndex ===
                    ifsp.ifspTeam.forms.developmentalSummary.length - 1 &&
                  mainIndex > 0 ? (
                  <Box
                    component={'div'}
                    className="cursorPointer flex__ align__items__center pr-md"
                    onClick={() => {
                      setDeleteNewSection(true);
                    }}
                    sx={{
                      padding: '4px 8px 4px 8px',
                      borderRadius: '4px',
                      marginRight: '24px',
                      border: '1px solid #EB4C60',
                      backgroundColor: '#fff',
                    }}
                  >
                    <DeleteIcon className="pr-xs" />
                    <MediumTypography
                      labelid="IFSP.DeleteNewSection"
                      defaultlabel="Delete"
                      fontSize="12px"
                      fontweight={600}
                    />
                  </Box>
                ) : null}
                <DownArrow
                  className="cursorPointer"
                  onClick={() => {
                    setOpenAccordianIndex(mainIndex);
                    setOpenAccordian(true);
                    handelClose(false);
                  }}
                />
              </Box>
            )}
          </Box>
          {openAccordian && mainIndex === openAccordianIndex && (
            <Box>
              <div
                ref={parentRef}
                style={{
                  pointerEvents: isScreenLocked ? 'none' : 'auto',
                  opacity: isScreenLocked ? '0.5' : '1 ',
                }}
                onKeyDownCapture={(e) => {
                  if (isScreenLocked) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onFocus={() => {
                  if (isScreenLocked) {
                    if (parentRef.current) parentRef.current.focus();
                  }
                }}
              >
                {developmentalSummaryMainData.map(
                  (__, subSectionIndex) =>
                    subSectionIndex === 0 && (
                      <Box
                        component="div"
                        key={subSectionIndex}
                        sx={{
                          margin: '24px 0px 0px 0px',
                          // paddingBottom:
                          //   showSubSection && openIndex === subSectionIndex
                          //     ? '16px'
                          //     : '0px',
                          // borderTop:
                          //   subSectionIndex === 0
                          //     ? '1px solid #00C6B8'
                          //     : 'none',
                          // borderBottom: '1px solid #00C6B8',
                        }}
                      >
                        <Box
                          component="div"
                          className="flex__justify__space-between mt-lg mb-lg"
                        >
                          <Box
                            component="div"
                            className="flex__ align__items__center"
                          >
                            {showSubSection && openIndex === subSectionIndex ? (
                              <HideIcon
                                display={'none'}
                                onClick={() => {
                                  setShowSubSection(false);
                                  setOpenIndex(subSectionIndex);
                                }}
                                className="cursorPointer"
                              />
                            ) : (
                              <FilledAddIcon
                                display={'none'}
                                onClick={() => {
                                  setShowSubSection(true);
                                  setOpenIndex(subSectionIndex);
                                }}
                                className="cursorPointer"
                              />
                            )}
                            <MediumTypography
                              labelid="IFSP.summary"
                              defaultlabel="Summary of Development"
                              textColor="#2A4241"
                              fontweight={600}
                              fontSize="16px"
                              className="mr-sm"
                            />
                            <InfoIcon
                              onClick={() => {
                                openInfo(info);
                              }}
                            />
                          </Box>
                          <Box
                            component="div"
                            className="flex__ justifyContent-FlexEnd"
                          >
                            <Box
                              component="div"
                              className="cursorPointer flex__ align__items__center visibility__hidden"
                            >
                              <PrinterIcon />
                              <MediumTypography
                                labelid="IFSP.print"
                                defaultlabel="Print"
                                textColor="#2A4241"
                                fontweight={700}
                                fontSize="16px"
                                className="ml-xs"
                              />
                            </Box>
                            {subSectionIndex !== 0 &&
                              !ifspData.sessionReviewedDate && (
                                <Box
                                  component="div"
                                  className="cursorPointer flex__ align__items__center ml-lg"
                                  onClick={() => {
                                    deleteSubSection(subSectionIndex);
                                  }}
                                >
                                  <Delete />
                                  <MediumTypography
                                    labelid="IFSP.Delete"
                                    defaultlabel="Delete"
                                    textColor="#EB4C60"
                                    fontweight={700}
                                    fontSize="16px"
                                    className="ml-xs"
                                  />
                                </Box>
                              )}
                          </Box>
                        </Box>
                        {showSubSection && openIndex === subSectionIndex && (
                          <SimpleTextAndTextArea
                            questions={DevelopmentalSummaryData.Questions}
                            placeholder="Enter your comments"
                            rows={1}
                            customCommentStyles={{
                              height: '80px',
                              minHeight: '80px',
                              minWidth: '840px',
                              width: '840px',
                            }}
                            maxLength={5000}
                            Value={transformToSimpleTextAreaValue(
                              subSectionIndex,
                            )}
                            handlechange={(value) =>
                              handleChange(subSectionIndex, value)
                            }
                            disabled={
                              ifspData.sessionReviewedDate ? true : false
                            }
                          />
                        )}
                      </Box>
                    ),
                )}
                <ButtonComponent
                  sxProps={{ display: 'none' }}
                  disabled={ifspData.sessionReviewedDate ? true : false}
                  className="btn-primary btn-submit mt-lg"
                  variantType="contained"
                  type="submit"
                  labelId="IFSP.addAdditionalEvaluation"
                  defaultLabelId="Add Additional Evaluation"
                  onClick={() => {
                    addSubSection();
                  }}
                />
              </div>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

export default DevelopmentalSummary;
