import { AlertColor, Box, Dialog, DialogContent, Grid } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import TitleText from '../../components/formlib/TitleText';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import 'quill-mention';
import Textinput from '../../components/formlib/Textinput';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import VerticalTabs from '../../components/formlib/VerticalTabs';
import { EmailData, EmailGetList } from '../../utils/type';
import {
  emailTemplateSubmit,
  getEmailTemplate,
} from '../../services/configApi/emailOrTemplate/EmailOrTemplateServices';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import MediumTypography from '../../components/formlib/MediumTypography';
import { checkPermissionForFeature } from '../../utils/checkPermission';

function EmailAndTextTemplate() {
  const quillRef = useRef<HTMLDivElement | null>(null);
  const quillInstanceRef = useRef<Quill | null>(null);
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('Client.addtoastr');
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [open, setOpen] = useState(false);
  const [activeCheckbox, setActiveCheckbox] = useState(true);

  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);

  useEffect(() => {
    if (
      checkPermissionForFeature('backend.email_text_template', 'editPermission')
    ) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const initialState: EmailData = {
    emailType: 'email',
    templateType: '',
    status: activeCheckbox ? 'Active' : 'Inactive',
    subject: '',
    body: '',
  };

  const [emailDetails, setEmailDetails] = useState<EmailData>(initialState);
  const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);

  function formatPlaceholders(text: string) {
    const replacements: { [key: string]: string } = {
      '@Client Name': '<b>clientName</b>',
      '@Parent Name': '<b>parentName</b>',
      '@Intake Facilitator': '<b>intakeFacilitator</b>',
      '@Service Coordinator': '<b>serviceCoordinator</b>',
      '@Appointment Type': '<b>appointmentType</b>',
      '@Appointment Date': '<b>appointmentDate</b>',
      '@Appointment Time': '<b>appointmentTime</b>',
      '@Attendees list': '<b>attendeesList</b>',
    };

    for (const key in replacements) {
      if (Object.prototype.hasOwnProperty.call(replacements, key)) {
        text = text.replace(new RegExp(key, 'g'), replacements[key]);
      }
    }

    return text;
  }

  useEffect(() => {
    initializeQuill();
  }, []);

  const MaxLength = 4500;

  useEffect(() => {
    if (
      quillInstanceRef.current &&
      emailDetails.body !== quillInstanceRef.current.root.innerHTML
    ) {
      const sanitizedData = emailDetails.body.replace(/\?/g, '');
      quillInstanceRef.current.root.innerHTML = sanitizedData;
    }
  }, [emailDetails.body]);

  const initializeQuill = () => {
    let lockScreen;
    if (
      checkPermissionForFeature('backend.email_text_template', 'editPermission')
    ) {
      lockScreen = false;
    } else {
      lockScreen = true;
    }

    if (quillRef.current) {
      const quill = new Quill(quillRef.current, {
        theme: 'snow',
        readOnly: lockScreen,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link', 'mention'],
          ],
          mention: {
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            mentionDenotationChars: ['@'],
            source: function (
              searchTerm: string,
              renderList: (
                mentions: { id: number; value: string }[],
                searchTerm: string,
              ) => void,
            ) {
              const mentions = [
                { id: 1, value: 'Client Name' },
                { id: 2, value: 'Parent Name' },
                { id: 3, value: 'Intake Facilitator' },
                { id: 4, value: 'Service Coordinator' },
                { id: 5, value: 'Appointment Type' },
                { id: 6, value: 'Appointment Date' },
                { id: 7, value: 'Appointment Time' },
                { id: 8, value: 'Attendees list' },
              ];

              const matches = mentions.filter((mention) =>
                mention.value.toLowerCase().includes(searchTerm.toLowerCase()),
              );

              renderList(matches, searchTerm);
            },
            onSelect: function (
              item: { id: number; value: string },
              insertItem: (item: { id: number; value: string }) => void,
            ) {
              insertItem(item);
            },
          },
        },
      });

      quill.on('text-change', () => {
        const textLength = quill.getLength();
        if (textLength > MaxLength) {
          let excessChars = textLength - MaxLength;
          let selectionIndex = quill.getSelection()?.index || MaxLength;
          for (let i = selectionIndex - excessChars; i < selectionIndex; i++) {
            const char = quill.getText(i, 1);
            if (char === ' ') {
              excessChars--;
            }
          }
          selectionIndex -= excessChars;
          quill.deleteText(selectionIndex, excessChars);
        }
        const entireText = quill.root.innerHTML;
        const doc = new DOMParser().parseFromString(entireText, 'text/html');
        const images = doc.querySelectorAll('img');

        images.forEach((image) => image.remove());
        const finalText = doc.body.innerHTML || '';
        const replacedText = formatPlaceholders(finalText);
        setEmailDetails((prevFormDetails) => ({
          ...prevFormDetails,
          body: replacedText,
        }));
      });
      quillInstanceRef.current = quill;
    }
  };

  const [emialOrPhoneTemplate, setEmailOrPhoneTemplate] =
    useState<EmailTemplateType>({ emailOrPhone: 'email' });
  const [modalVisible, setModalVisible] = useState(false);

  interface EmailTemplateType {
    emailOrPhone: string;
  }

  useEffect(() => {
    setEmailDetails((prevFormDetails) => ({
      ...prevFormDetails,
      emailType: 'email',
      templateType: 'welcome_email',
    }));
    emailTemplateAPICall('welcome_email');
  }, []);

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const emailTemplateAPICall = (emailType: string) => {
    toggleLoader(true);
    getEmailTemplate(emailType)
      .then((response: EmailGetList) => {
        toggleLoader(false);
        setEmailDetails((prevFormDetails) => ({
          ...prevFormDetails,
          body: response.body,
          subject: response.subject,
          status: response.status,
        }));
        setActiveCheckbox(response.status == 'Active' ? true : false);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error);
      });
  };

  const elements = [
    {
      id: 0,
      label: 'welcome_email',
      itemText: 'Welcome Email',
    },
    {
      id: 1,
      label: 'intake_email',
      itemText: 'Intake Email',
    },
    {
      id: 2,
      label: 'eligibility_determination',
      itemText: 'Eligibility Determination',
    },
    {
      id: 3,
      label: 'ifsp_meeting_email',
      itemText: 'IFSP Meeting Email',
    },
  ];

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const saveEmailTemplate = () => {
    toggleLoader(true);
    emailTemplateSubmit(emailDetails, employeeId)
      .then(async (response) => {
        toggleLoader(false);
        setOpen(true);
        setToastrId(response.message);
        setToastrDefaultMessage(response.message);
        setToastrVariable('success');
      })
      .catch(async (error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        setToastrId(error.message);
        setToastrDefaultMessage(error.message);
      });
  };

  return (
    <Box component="main">
      <Box component="section">
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box className="rowContainer">
          <Box component="div" className="ml-md">
            <TitleText
              labelid="emailTemplate.HeadLabel"
              defaultlabel="Email / Text Template"
            />
          </Box>
        </Box>
        <Box component="div" className="pl-lg">
          <CustomRadioButton
            data={[
              {
                value: 'email',
                labelId: 'emailTemplate.Email',
                defaultLabel: 'Email',
              },
              {
                value: 'text',
                labelId: 'emailTemplate.Text',
                defaultLabel: 'Text',
              },
            ]}
            value={emialOrPhoneTemplate.emailOrPhone}
            onSelectItem={(value) => {
              if (value) {
                setEmailOrPhoneTemplate({
                  ...emialOrPhoneTemplate,
                  emailOrPhone: value,
                });
              }
              if (value === 'text') {
                setModalVisible(true);
              }
            }}
            row={false}
          />
        </Box>
        <Box>
          <Box className="rowContainer">
            <Grid container>
              <Grid item xs={2} lg={2}>
                <VerticalTabs
                  fields={elements}
                  onClickCallback={(data) => {
                    emailTemplateAPICall(data);
                    setEmailDetails((prevFormDetails) => ({
                      ...prevFormDetails,
                      templateType: data,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={0.5} lg={0.5}></Grid>
              <Grid item xs={9.5} xl={9.5} className="bg__white">
                <Box component="div" className="pl-md">
                  <CheckBoxComponent
                    disabled={isScreenLocked}
                    defaultlabelid="Active"
                    labelid="emailTemplate.ActiveCheckbox"
                    ischecked={activeCheckbox}
                    CheckHandleChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      setActiveCheckbox(!activeCheckbox);
                      setEmailDetails((prevFormDetails) => ({
                        ...prevFormDetails,
                        status: e.target.checked ? 'Active' : 'Inactive',
                      }));
                    }}
                  />
                </Box>
                <Box component="div" className="p-md" sx={{ width: '95%' }}>
                  <Box className="mb-lg" sx={{ width: '50%' }}>
                    <Textinput
                      name="subject"
                      labelid="Subject"
                      defaultlabelid="Subject"
                      Required={false}
                      disabled={isScreenLocked}
                      inputProps={{
                        maxLength: 500,
                      }}
                      Value={emailDetails.subject}
                      handlechange={(value: string) => {
                        setEmailDetails((prevFormDetails) => ({
                          ...prevFormDetails,
                          subject: value,
                        }));
                      }}
                    />
                  </Box>

                  <Box
                    ref={quillRef}
                    sx={{ height: '40vh', wordBreak: 'break-all' }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {checkPermissionForFeature(
        'backend.email_text_template',
        'editPermission',
      ) && (
        <Box component="div">
          <Grid
            className="flex__ justifyContent-FlexEnd pt-md pb-lg "
            container
            direction="row"
            alignItems="right"
          >
            <Grid item>
              <Box component="div">
                <ButtonComponent
                  className="btn-primary btn-cancel btn_width_90px mr-md"
                  variantType="outlined"
                  labelId="Clear"
                  defaultLabelId="Clear"
                  onClick={() =>
                    setEmailDetails((prevFormDetails) => ({
                      ...prevFormDetails,
                      body: '',
                    }))
                  }
                />

                <ButtonComponent
                  className="btn-primary btn-submit btn_width_90px"
                  variantType="contained"
                  type="submit"
                  labelId="Save"
                  defaultLabelId="Save"
                  onClick={() => saveEmailTemplate()}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box component="div">
        <Dialog
          open={modalVisible}
          keepMounted
          onClose={() => {
            setModalVisible(false);
            setEmailOrPhoneTemplate({
              ...emialOrPhoneTemplate,
              emailOrPhone: 'email',
            });
          }}
          fullWidth
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent className="p-xlg">
            <MediumTypography
              labelid={'emailTemplate.TextFeature'}
              defaultlabel="This feature is under construction"
              fontweight={1000}
              textColor="#2a4241"
            />
            <Box className="flex__ mt-xlg justifyContent-FlexEnd">
              <ButtonComponent
                className="btn-primary btn-submit"
                type="submit"
                labelId="Ok"
                defaultLabelId="Ok"
                onClick={() => {
                  setModalVisible(false);
                  setEmailOrPhoneTemplate({
                    ...emialOrPhoneTemplate,
                    emailOrPhone: 'email',
                  });
                }}
              />
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    </Box>
  );
}

export default EmailAndTextTemplate;
