import { Card, SxProps } from '@mui/material';
import BarChart from '../../components/charts/BarChart';
import { Productivity } from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { FC } from 'react';
import {
  getBackgroundColors,
  productivityData,
  productivityLabels,
} from './activityUtils';

interface ProductivityChartProps {
  productivity: Productivity[] | null;
  cardStyle?: SxProps;
  horizontalValue: number;
  barHeight: string;
}

const ProductivityChart: FC<ProductivityChartProps> = ({
  productivity,
  cardStyle,
  horizontalValue,
  barHeight,
}) => {
  return (
    <Card sx={{ padding: '16px 8px 8px 12px', ...cardStyle }}>
      <BarChart
        barHeight={barHeight}
        labels={productivityLabels(productivity)}
        data={productivityData(productivity)}
        barPercentage={0.5}
        horizontalValue={horizontalValue}
        backgroundColors={getBackgroundColors(productivity)}
      />
    </Card>
  );
};

export default ProductivityChart;
