import { Box, Dialog } from '@mui/material';
import { FC, useState } from 'react';
import MediumTypography from '../MediumTypography';
import CommentTextbox from '../CommentTextbox';
import ButtonComponent from '../ButtonComponent';
import { useIntl } from 'react-intl';

interface ActivityNoteModalProps {
  titleId?: string;
  placeholderId?: string;
  positiveButtonId?: string;
  open: boolean;
  note: string;
  handleClose: () => void;
  updateNote: (note: string) => void;
  hideUpdateButton?: boolean;
  maxLength?: number;
  closeOnBackdropClick: boolean;
}

const ActivityNoteModal: FC<ActivityNoteModalProps> = ({
  titleId,
  placeholderId,
  positiveButtonId,
  open,
  note,
  handleClose,
  updateNote,
  hideUpdateButton,
  closeOnBackdropClick,
  maxLength,
}) => {
  const [newNote, setNewNote] = useState<string>(note);
  const rctl = useIntl();

  return (
    <Dialog
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && !closeOnBackdropClick) {
          return;
        } else {
          handleClose();
        }
      }}
      fullWidth
      maxWidth={'sm'}
    >
      <Box sx={{ padding: '16px' }}>
        <MediumTypography
          labelid={titleId ?? 'noteText'}
          sxProps={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        />
        <Box sx={{ paddingTop: '16px' }}>
          <CommentTextbox
            maxLength={maxLength}
            rows={5}
            Value={newNote}
            placeholder={rctl.formatMessage({
              id: placeholderId ?? 'CommunicationLogPage.enterNote',
            })}
            handlechange={(value) => setNewNote(value)}
            disabled={hideUpdateButton}
          />
        </Box>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          sx={{ marginTop: '16px' }}
        >
          <ButtonComponent
            className="btn-primary btn-cancel"
            labelId={'cancelText'}
            defaultLabelId="Cancel"
            variantType="outlined"
            onClick={handleClose}
          />
          {!hideUpdateButton && (
            <ButtonComponent
              className="btn-primary btn-submit ml-md"
              labelId={positiveButtonId ?? 'addNoteText'}
              defaultLabelId="Add Note"
              disabled={newNote.length <= 0}
              onClick={() => {
                updateNote(newNote);
              }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ActivityNoteModal;
