import { Tooltip } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

interface TooltipIconProps {
  labelId?: string;
  defaultLabel?: string;
  label?: string;
  children: JSX.Element;
}

const ActionToolTip: FC<TooltipIconProps> = ({
  labelId,
  defaultLabel,
  label,
  children,
}) => {
  const iconTitle = useIntl();
  const tooltipTitle =
    label ??
    iconTitle.formatMessage({
      id: labelId,
      defaultMessage: defaultLabel,
    });

  return (
    <Tooltip title={tooltipTitle} arrow followCursor placement="bottom">
      {children}
    </Tooltip>
  );
};

export default ActionToolTip;
