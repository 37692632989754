import { Box, TableCell, TableRow } from '@mui/material';
import { FC } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import ToolTipIcon from '../../components/formlib/ToolTipIcon';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import moment from 'moment';
import { Asqse2ListType } from '../../services/configApi/forms/ASQSE2/ASQQuestionaireServices';
// import { ReactComponent as Download } from '../../assets/images/downloadIcon.svg';
// import { ReactComponent as Print } from '../../assets/images/printerIcon.svg';
// import { ReactComponent as Mail } from '../../assets/images/mail.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface ASQListProps {
  data: Asqse2ListType;
  onDelete: (rowData: string) => void;
  onEdit: (rowData: Asqse2ListType) => void;
  //   onDownload?: (rowData: ConsentListData) => void;
  //   onPrint?: () => void;
  //   onMail?: () => void;
}

const ASQTableRow: FC<ASQListProps> = (props) => {
  const { age, dateOfAsqse2 } = props.data;

  const renderCell = (
    content: string | null | undefined,
    format?: string,
    textColor?: string,
  ) => {
    if (!content || content === '') {
      return (
        <TableCell>
          <MediumTypography label="-" sxProps={{ textAlign: 'left' }} />
        </TableCell>
      );
    } else if (content === 'N/A') {
      return <MediumTypography label="N/A" sxProps={{ textAlign: 'left' }} />;
    }
    return (
      <MediumTypography
        label={format ? moment(content).local().format(format) : content}
        textColor={textColor}
      />
    );
  };

  return (
    <TableRow
      hover
      sx={{
        cursor: 'pointer',
        alignContent: 'flex-start',
        '&:nth-of-type(odd)': {
          backgroundColor: '#ECF9F8',
        },
      }}
    >
      <TableCell padding="checkbox">
        <CustomCheckBox value={false} />
      </TableCell>
      <TableCell>{renderCell(dateOfAsqse2, 'MM/DD/YYYY')}</TableCell>
      <TableCell>
        <MediumTypography label={age + ' Months'} />
      </TableCell>
      <TableCell>
        <Box className="flex__ alignContent_left">
          <Box className="cursorPointer">
            <ToolTipIcon
              labelId="editText"
              defaultLabel="Edit"
              Icon={<EditIcon onClick={() => props.onEdit(props.data)} />}
            />
          </Box>

          {checkPermissionForFeature('backend.clients', 'editPermission') && (
            <Box className="cursorPointer">
              <ToolTipIcon
                labelId="ConsentForms.listActionDeleteToolTipLabel"
                defaultLabel="Delete"
                Icon={
                  <DeleteIcon onClick={() => props.onDelete(props.data.id)} />
                }
              />
            </Box>
          )}

          {/* <Box className="cursorPointer">
            <ToolTipIcon
              labelId="ConsentForms.listActionDownloadToolTipLabel"
              defaultLabel="Download"
              Icon={<Download onClick={() => props.onDownload(props.data)} />}
            />
          </Box>
          <Box className="cursorPointer">
            <ToolTipIcon
              labelId="ConsentForms.listActionPrintToolTipLabel"
              defaultLabel="Print"
              Icon={<Print onClick={props.onPrint} />}
            />
          </Box>
          <Box className="cursorPointer">
            <ToolTipIcon
              labelId="ConsentForms.listActionMailToolTipLabel"
              defaultLabel="Mail"
              Icon={<Mail onClick={props.onMail} />}
            />
          </Box> */}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ASQTableRow;
