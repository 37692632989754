import { Box, Card, Grid, SxProps } from '@mui/material';
import { FC } from 'react';
import { ActivitySheet } from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import MediumTypography from '../../components/formlib/MediumTypography';
import { useIntl } from 'react-intl';
import { convertTimeDuration } from '../../utils/dateUtil';

interface ActivityWeekPeekProps {
  activity: ActivitySheet;
  cardStyle?: SxProps;
}

const ActivityWeekPeek: FC<ActivityWeekPeekProps> = ({
  cardStyle,
  activity,
}) => {
  const rctl = useIntl();

  const getProductivityValue = () => {
    if (activity.productivity) {
      return (
        activity.productivity[activity.productivity.length - 1].percent + '%'
      );
    }
    return '00%';
  };

  return (
    <Card sx={{ padding: '16px', display: 'flex', ...cardStyle }}>
      <Grid container>
        <Grid
          item
          lg={5}
          sx={{ display: 'inline-grid', justifyContent: 'space-between' }}
        >
          <Box>
            <MediumTypography
              labelid="totalHoursPayText"
              defaultlabel="Total Hours"
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              className="mainText-xlg"
              sxProps={{
                color: 'var(--thom-green-primary2)',
              }}
              label={
                activity.totalPayHours !== null
                  ? convertTimeDuration(activity.totalPayHours, true) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>
          <Box>
            <MediumTypography
              labelid="productivityText"
              defaultlabel="Productivity"
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              className="mainText-xlg"
              sxProps={{
                color: 'var(--thom-green-primary2)',
              }}
              label={getProductivityValue()}
            />
          </Box>
          <Box>
            <MediumTypography
              labelid="anticipatedHoursText"
              defaultlabel="Anticipated Hours"
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              className="mainText-xlg"
              sxProps={{
                color: 'var(--thom-green-primary2)',
              }}
              label={
                activity.anticipatedHoursPerWeek !== null
                  ? convertTimeDuration(
                      activity.anticipatedHoursPerWeek,
                      true,
                    ) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>
        </Grid>

        <Grid
          item
          lg={7}
          sx={{ display: 'inline-grid', justifyContent: 'space-between' }}
        >
          <Box display={'flex'}>
            <MediumTypography
              className="mr-xs"
              label={`${rctl.formatMessage({
                id: 'billableHoursScheduledText',
                defaultMessage: 'Billable Hours Scheduled',
              })} :`}
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              sxProps={{
                color: 'var(--thom-dark-green-table-black)',
                fontWeight: 'bold',
              }}
              label={
                activity.totalScheduledBillableHours !== null
                  ? convertTimeDuration(
                      activity.totalScheduledBillableHours,
                      true,
                    ) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>

          <Box display={'flex'}>
            <MediumTypography
              className="mr-xs"
              label={`${rctl.formatMessage({
                id: 'billableHoursCompletedText',
                defaultMessage: 'Billable Hours Completed',
              })} :`}
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              sxProps={{
                color: 'var(--thom-dark-green-table-black)',
                fontWeight: 'bold',
              }}
              label={
                activity.totalCompletedBillableHours !== null
                  ? convertTimeDuration(
                      activity.totalCompletedBillableHours,
                      true,
                    ) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>

          <Box display={'flex'}>
            <MediumTypography
              className="mr-xs"
              label={`${rctl.formatMessage({
                id: 'approvedNonBillableHrsText',
                defaultMessage: 'Approved Non-Billable Hrs',
              })} :`}
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              sxProps={{
                color: 'var(--thom-dark-green-table-black)',
                fontWeight: 'bold',
              }}
              label={
                activity.totalApprovedNonBillableHours !== null
                  ? convertTimeDuration(
                      activity.totalApprovedNonBillableHours,
                      true,
                    ) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>

          <Box display={'flex'}>
            <MediumTypography
              className="mr-xs"
              label={`${rctl.formatMessage({
                id: 'sickHrsText',
                defaultMessage: 'Sick Hrs',
              })} :`}
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              sxProps={{
                color: 'var(--thom-dark-green-table-black)',
                fontWeight: 'bold',
              }}
              label={
                activity.totalSickHours !== null
                  ? convertTimeDuration(activity.totalSickHours, true) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>

          <Box display={'flex'}>
            <MediumTypography
              className="mr-xs"
              label={`${rctl.formatMessage({
                id: 'timeOutText',
                defaultMessage: 'Time-out',
              })} :`}
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              sxProps={{
                color: 'var(--thom-dark-green-table-black)',
                fontWeight: 'bold',
              }}
              label={
                activity.totalHoursOut !== null
                  ? convertTimeDuration(activity.totalHoursOut, true) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>

          <Box display={'flex'}>
            <MediumTypography
              className="mr-xs"
              label={`${rctl.formatMessage({
                id: 'noShowText',
                defaultMessage: 'No Show, Wait, Travel, Doc',
              })} :`}
              sxProps={{
                color: 'var(--thom-mono-gray)',
              }}
            />
            <MediumTypography
              sxProps={{
                color: 'var(--thom-dark-green-table-black)',
                fontWeight: 'bold',
              }}
              label={
                activity.totalNoShowTravelDocHours !== null
                  ? convertTimeDuration(
                      activity.totalNoShowTravelDocHours,
                      true,
                    ) + ' hrs'
                  : '00.00 hrs'
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ActivityWeekPeek;
