import { Box, IconButton, TableCell, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as MessageIcon } from '../../assets/images/messageIcon.svg';
import { ReactComponent as EditIcon } from '../../assets/images/highlight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import ToolTipIcon from '../../components/formlib/ToolTipIcon';
import { BlockTimeData } from '../../services/configApi/employees/employeeServices';
import moment from 'moment';
import ActivityNoteModal from '../../components/formlib/modal/ActivityNoteModal';
import { LOCAL_TIME_12_HOUR_FORMAT } from '../../services/Constant';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface BlockTime {
  data: BlockTimeData;
  onDelete: (blockingTimeId: string) => void;
  onEdit: (data: BlockTimeData) => void;
}

const ProfileBlockTimeTableRow: FC<BlockTime> = ({
  data,
  onDelete,
  onEdit,
}) => {
  const [openNoteModel, setOpenNoteModel] = useState(false);
  let isCurrentTimeBetween: boolean = moment().isBetween(
    data.startDate,
    data.endDate,
  );
  let isCurrentTimePast: boolean = moment().isAfter(data.endDate);
  let isSingleDayEvent: boolean = moment().isAfter(data.startTime);
  const handleOpenNoteModal = () => {
    if (data.description) {
      setOpenNoteModel(true);
    }
  };

  const handleCloseNoteModal = () => {
    setOpenNoteModel(false);
  };
  const handleDeleteClick = () => {
    if (data.blockTimeId) {
      onDelete(data.blockTimeId);
    }
  };
  const handleEdit = () => {
    onEdit(data);
  };

  const getDates = () => {
    const utcStartDateTime = moment(
      `${data.startDate} ${data.startTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();

    const utcEndDateTime = moment(
      `${data.endDate} ${data.endTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();
    return `${utcStartDateTime.format('MM/DD/YYYY')} - ${utcEndDateTime.format(
      'MM/DD/YYYY',
    )}`;
  };

  const getTimes = () => {
    const utcStartDateTime = moment(
      `${data.startDate} ${data.startTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();

    const utcEndDateTime = moment(
      `${data.endDate} ${data.endTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();

    // check if current time is between start time and end time and get true or false
    if (
      utcStartDateTime.format('MM/DD/YYYY') ===
      utcEndDateTime.format('MM/DD/YYYY')
    ) {
      isSingleDayEvent = utcStartDateTime.isBefore(moment());
    }
    isCurrentTimeBetween =
      utcStartDateTime.isBefore(moment()) && utcEndDateTime.isAfter(moment());

    isCurrentTimePast = utcEndDateTime.isBefore(moment());

    return `${utcStartDateTime.format(
      LOCAL_TIME_12_HOUR_FORMAT,
    )} - ${utcEndDateTime.format(LOCAL_TIME_12_HOUR_FORMAT)}`;
  };

  return (
    <>
      {openNoteModel && data.description && (
        <ActivityNoteModal
          closeOnBackdropClick={true}
          open={openNoteModel}
          note={data.description !== null ? data.description : ''}
          handleClose={handleCloseNoteModal}
          updateNote={() => {}}
          hideUpdateButton={true}
          maxLength={2000}
        />
      )}
      <TableRow>
        <TableCell component="th">
          <MediumTypography label={data.blockingType} />
        </TableCell>
        <TableCell>
          <MediumTypography label={getDates()} />
        </TableCell>
        <TableCell>
          <MediumTypography label={getTimes()} />
        </TableCell>
        <TableCell>
          <Box sx={{ cursor: 'pointer' }}>
            <IconButton>
              <MessageIcon stroke={'#97A6A5'} onClick={handleOpenNoteModal} />
            </IconButton>
          </Box>
        </TableCell>
        {checkPermissionForFeature('backend.block_time', 'editPermission') ||
        checkPermissionForFeature('backend.block_time', 'deletePermission') ? (
          <TableCell>
            <Box className=" flex__ justifyContent-Center">
              {checkPermissionForFeature(
                'backend.block_time',
                'editPermission',
              ) && (
                <Box
                  sx={{
                    cursor: 'pointer',
                    opacity:
                      isCurrentTimeBetween || isCurrentTimePast ? 0.5 : 1,
                    pointerEvents:
                      isCurrentTimeBetween || isCurrentTimePast
                        ? 'none'
                        : 'auto',
                  }}
                >
                  <ToolTipIcon
                    // based on iscurrentisBetween keep the style opacity to 0
                    labelId="BlockTime.edit"
                    defaultLabel="Edit"
                    Icon={<EditIcon onClick={handleEdit} />}
                  />
                </Box>
              )}
              {checkPermissionForFeature(
                'backend.block_time',
                'deletePermission',
              ) && (
                <Box
                  sx={{
                    cursor: 'pointer',
                    opacity: isSingleDayEvent || isCurrentTimePast ? 0.5 : 1,
                    pointerEvents:
                      isSingleDayEvent || isCurrentTimePast ? 'none' : 'auto',
                  }}
                >
                  <ToolTipIcon
                    labelId="BlockTime.delete"
                    defaultLabel="Delete"
                    Icon={<DeleteIcon onClick={handleDeleteClick} />}
                  />
                </Box>
              )}
            </Box>
          </TableCell>
        ) : null}
      </TableRow>
    </>
  );
};

export default ProfileBlockTimeTableRow;
