import React, { useContext, useEffect, useRef, useState } from 'react';
import { AlertColor, Box, Card, Typography } from '@mui/material';
import * as Yup from 'yup';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import ConsentGeneralInfo from './ConsentGeneralInfo';
import SingleLine from '../../components/formlib/SingleLine';
import ConsentClientDetails, { Client } from './ConsentClientDetails';
import ConsentSignature from './ConsentSignature';
import {
  ConsentDetailsType,
  getConsentDetailsLEA,
  updateConsent,
} from '../../services/configApi/forms/Consent/ConsentServices';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import moment from 'moment';
import ConsentGRI from './ConsentJson/Consent-GRI.json';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import dayjs from 'dayjs';
import { Formik, FormikProps } from 'formik';
import Textinput from '../../components/formlib/Textinput';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { useNavigate } from 'react-router';
import { FACESHEET } from '../../routes/Routing';
import ProgramPanel from '../../components/forms/ProgramPanel';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import TranslateWidget, { setLanguage } from '../../TranslateWidget';

export const initialValues: ConsentDetailsType = {
  type: '',
  name: '',
  consentId: '',
  version: 1,
  signedDate: '',
  revokedDate: '',
  status: 'Not Current',
  requestedDate: '',
  requester: {
    name: '',
    email: '',
    phone: '',
  },
  client: {
    id: '',
    name: '',
    dphId: '',
    gender: '',
    dob: '',
    address: '',
  },
  selectedContact: [],
  contact: [
    {
      id: 0,
      name: '',
      address: '',
      email: '',
      phone: '',
    },
  ],
  refusal: false,
  signature: [
    {
      id: '',
      name: '',
      relationship: '',
      email: '',
      signature: '',
      verbal: false,
      date: '',
      license: '',
    },
  ],
  programProposes: null,
  communicationMode: null,
  communicationComments: '',
  purpose: null,
  purposeComments: '',
  programDetails: {
    name: '',
    phoneNumber: '',
    email: '',
    addressLine1: '',
    addressLine2: '',
    faxNumber: '',
    website: '',
    imageUrl: '',
  },
  ifsp: null,
  serviceDeliveryPlan: {
    changes: '',
    isEligibleUntilThirdBirthday: '',
    eligibleTool: {
      id: '',
      label: '',
    },
    assessmentCompletionDate: null,
    reEstablishedDate: null,
    parentInitial: { id: '', label: '' },
    isChecked: false,
    notifiedParent: '',
    participated: false,
    agreeDisagree: '',
    exceptionList: '',
    consent: false,
  },
  eligibility: null,
  other: false,
  otherContactName: '',
  othersComment: '',
  summary: '',
  description: '',
  selectedLanguage: 'en',
};

export const initalStates = {
  schoolName: '',
  schoolNameMandatory: true,
};

export type SchoolNameType = {
  schoolName: string;
  schoolNameMandatory: boolean;
};
const LEAReferralConsent = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object({
    schoolName: Yup.string().when('schoolNameMandatory', {
      is: true,
      then: Yup.string().required('formBuilder.Name'),
      otherwise: Yup.string().nullable(),
    }),
    schoolNameMandatory: Yup.boolean().nullable(),
  });

  const formRef = useRef<FormikProps<SchoolNameType>>(null);

  const [initialState, setInitialState] =
    useState<SchoolNameType>(initalStates);
  const [selectedClient, setSelectedClient] = useState<Client>({
    name: '',
    dob: '',
    dphId: '',
    address: '',
    gender: '',
  });
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [consentDetails, setConsentDetails] =
    useState<ConsentDetailsType>(initialValues);
  const [consentName] = useState('LEA');
  const [disclaimer] = useState(ConsentGRI[1].DisclaimerText);
  const [expiryDate, setExpiryDate] = useState<string>('');
  const [btnName, setBtnName] = useState<boolean>(false);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);
  const parentRef = useRef<HTMLDivElement>(null);

  const translationId = useRef('Consent');

  const [selectedLanguageValue, setSelectedLanguageValue] =
    useState<string>('');

  const getDateFormatedValue = (locationDate: string | Date | null) => {
    let originalDate: string;
    if (locationDate === '' || locationDate === null) {
      const currentUtcDate = new Date();
      currentUtcDate.setUTCFullYear(currentUtcDate.getUTCFullYear() + 1);

      originalDate = currentUtcDate.toISOString();
    } else {
      const currentUtcDate = new Date(locationDate);
      currentUtcDate.setUTCFullYear(currentUtcDate.getUTCFullYear() + 1);

      originalDate = currentUtcDate.toISOString();
    }

    return originalDate;
  };

  useEffect(() => {
    toggleLoader(true);
    getConsentDetailsLEA('LEA', '')
      .then(async (response) => {
        toggleLoader(false);
        if (response) {
          formRef.current?.setValues((prevValues) => ({
            ...prevValues,
            schoolName: response.description,
            schoolNameMandatory:
              response.schoolNameMandatory !== undefined &&
              response.schoolNameMandatory,
          }));
          setInitialState((prevValues) => ({
            ...prevValues,
            schoolName: response.description,
            schoolNameMandatory:
              response.schoolNameMandatory !== undefined &&
              response.schoolNameMandatory,
          }));
          setConsentDetails(response);
          setLanguage(response.selectedLanguage);
          const resultExpiry =
            response.type === 'NoticeOfPrivacyPractices' ||
            response.type === 'LegalReleaseofRecords' ||
            response.type === 'PriorWrittenNoticeForm' ||
            response.type === 'ConsentForChangeServiceDeliveryPlan'
              ? ''
              : getDateFormatedValue(response.signedDate);
          setExpiryDate(resultExpiry);
          setSelectedClient({
            name: response.client.name,
            dob: response.client.dob,
            dphId: response.client.dphId !== null ? response.client.dphId : '',
            address:
              response.client.address !== null ? response.client.address : '',
            gender: response.client.gender,
          });
        }
      })
      .catch(async (error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('internetCheckConnectionError');
          setToastrDefaultMessage('internetCheckConnectionError');
        }
      });
  }, []);

  const handleStatus = (statusValue: string) => {
    let validatedStatus = '';
    if (statusValue.toLowerCase().includes('revoked')) {
      const filterRevoked = consentDetails.revokedDate;
      validatedStatus = 'Revoked'.concat(
        ' (' + moment(filterRevoked).format('MM/DD/YYYY') + ')',
      );
    } else {
      validatedStatus = statusValue;
    }

    return validatedStatus;
  };

  const handleClear = () => {
    navigate(FACESHEET);
  };

  const onSaveClick = (sign: string, id: number) => {
    const signatureDate = moment().format('MM/DD/YYYY');
    setConsentDetails((prevConsentDetails) => {
      const updatedSignature = prevConsentDetails.signature.map(
        (item, index) => {
          if (index === id) {
            return {
              ...item,
              signature: sign,
              date: signatureDate,
              partitionKey: '',
              rowKey: '',
            };
          }
          return item;
        },
      );

      return {
        ...prevConsentDetails,
        status:
          prevConsentDetails.status === 'Revoked'
            ? prevConsentDetails.status
            : 'Current',
        signature: updatedSignature,
      };
    });
  };

  const onVerbalConsentClick = (value: boolean, index: number) => {
    const signatureDate = moment().format('MM/DD/YYYY');
    setConsentDetails((prevConsentDetails) => {
      const updatedSignature = prevConsentDetails.signature.map((item, id) => {
        if (id === index) {
          return {
            ...item,
            verbal: value,
            date: signatureDate,
          };
        }
        return item;
      });

      return {
        ...prevConsentDetails,
        status:
          prevConsentDetails.status === 'Revoked'
            ? prevConsentDetails.status
            : 'Current',
        signature: updatedSignature,
      };
    });
  };

  const handleSave = () => {
    toggleLoader(true);

    if (consentDetails.signature.length > 0) {
      const resultStatus = consentDetails.signature.some(
        (s) => s.signature !== '' || s.verbal || s.rowKey !== '',
      );
      const updatedSignature = consentDetails.signature.map((item) => {
        return {
          ...item,
          date: moment(new Date(), 'MM/DD/YYYY HH:mm:ss')
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss'),
        };
      });

      const resultSigned =
        consentDetails.signedDate !== ''
          ? consentDetails.signedDate
          : new Date().toISOString();

      if (resultStatus) {
        const params: ConsentDetailsType = {
          source: 'lea',
          type: consentDetails.type,
          name: consentName,
          consentId: consentDetails.consentId,
          version: 1,
          signedDate: resultSigned,
          revokedDate: consentDetails.revokedDate,
          status: consentDetails.status,
          requestedDate: consentDetails.requestedDate,
          requester: {
            name: consentDetails.requester.name,
            email: consentDetails.requester.email,
            phone: consentDetails.requester.phone,
          },
          client: {
            id: consentDetails.client.id,
            name: consentDetails.client.name,
            dphId: consentDetails.client.dphId,
            gender: consentDetails.client.gender,
            dob: consentDetails.client.dob,
            address: consentDetails.client.address,
          },
          selectedContact: [],
          contact: consentDetails.contact,
          refusal: consentDetails.refusal,
          signature: updatedSignature,
          programProposes: consentDetails.programProposes,
          communicationMode: consentDetails.communicationMode,
          communicationComments: '',
          purpose: consentDetails.purpose,
          purposeComments: '',
          programDetails: consentDetails.programDetails,
          ifsp: consentDetails.ifsp,
          serviceDeliveryPlan: {
            agreeDisagree: consentDetails.serviceDeliveryPlan.agreeDisagree,
            assessmentCompletionDate:
              consentDetails.serviceDeliveryPlan.assessmentCompletionDate ===
                '' || null
                ? dayjs().format('MM/DD/YYYY')
                : consentDetails.serviceDeliveryPlan.assessmentCompletionDate,
            changes: consentDetails.serviceDeliveryPlan.changes,
            consent: consentDetails.serviceDeliveryPlan.consent,
            eligibleTool: consentDetails.serviceDeliveryPlan.eligibleTool,
            exceptionList: consentDetails.serviceDeliveryPlan.exceptionList,
            isChecked: consentDetails.serviceDeliveryPlan.isChecked,
            isEligibleUntilThirdBirthday:
              consentDetails.serviceDeliveryPlan.isEligibleUntilThirdBirthday,
            notifiedParent: consentDetails.serviceDeliveryPlan.notifiedParent,
            parentInitial: consentDetails.serviceDeliveryPlan.parentInitial,
            participated: consentDetails.serviceDeliveryPlan.participated,
            reEstablishedDate:
              consentDetails.serviceDeliveryPlan.reEstablishedDate === '' ||
              null
                ? dayjs().format('MM/DD/YYYY')
                : consentDetails.serviceDeliveryPlan.reEstablishedDate,
          },
          eligibility: consentDetails.eligibility,
          other: consentDetails.other,
          othersComment: consentDetails.othersComment,
          otherContactName: consentDetails.otherContactName,
          summary: consentDetails.summary,
          description: initialState.schoolName,
          schoolNameMandatory: initialState.schoolNameMandatory,
          selectedLanguage: selectedLanguageValue,
        };
        updateConsent(
          consentDetails.type,
          consentDetails.consentId,
          params,
          null,
        )
          .then((response) => {
            if (response) {
              setOpen(true);
              setToastrVariable('success');
              setToastrId('ConsentForms.submitToaster');
              setToastrDefaultMessage('Consent Form Successfully Saved.');
              setBtnName(true);
              toggleLoader(false);
            }
          })
          .catch((error) => {
            setOpen(true);
            toggleLoader(false);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('ConsentForms.concentUpdateError');
              setToastrDefaultMessage('Failed to update Consent details');
            }
          });
      } else {
        setOpen(true);
        setToastrVariable('error');
        setToastrId('ConsentForms.signatureToasterError');
        setToastrDefaultMessage('Signature/Verbal Consent is Mandatory');
        toggleLoader(false);
      }
    }
  };

  function getDateAfterThreeYears(date: string) {
    const parts = date.split('/');
    const month = parseInt(parts[0], 10) - 1;
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);
    const currentDate = new Date(year, month, day);
    const futureDate = new Date(
      currentDate.getFullYear() + 3,
      currentDate.getMonth(),
      currentDate.getDate(),
    );
    const formattedDate =
      (futureDate.getMonth() + 1).toString().padStart(2, '0') +
      '/' +
      futureDate.getDate().toString().padStart(2, '0') +
      '/' +
      futureDate.getFullYear().toString();
    return formattedDate;
  }

  const letter = `The child ${
    consentDetails.client.name
  } will turn three years on ${getDateAfterThreeYears(
    consentDetails.client.dob,
  )}, hence I am making a referral and requesting an evaluation to determine eligibility of Part B Special Education and related service from the <strong>${
    initialState.schoolName
  }</strong> School Department in signing this letter, I understand that the development information from Thom ${
    consentDetails.programDetails.name
  } will be forwarded to the <strong>${
    initialState.schoolName
  }</strong> School System Preschool Coordinator or designee.
  The developmental information shared will include the development evaluation completed by Thom ${
    consentDetails.programDetails.name
  }.
  I further agree that telephone contact between the <strong>${
    initialState.schoolName
  }</strong> School System and Thom ${
    consentDetails.programDetails.name
  } may occur to begin the transition process.`;

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <TranslateWidget
        screenId={translationId.current}
        initialLanguage={'en'}
        languageChangeCallback={(lang) => {
          setSelectedLanguageValue(lang);
        }}
      >
        <Box component="main">
          <Box component="section">
            <Card className="formCardview">
              <div
                ref={parentRef}
                style={{
                  pointerEvents: isScreenLocked ? 'none' : 'auto',
                  opacity: isScreenLocked ? '0.5' : '1 ',
                }}
                onKeyDownCapture={(e) => {
                  if (isScreenLocked) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
                onFocus={() => {
                  if (isScreenLocked) {
                    if (parentRef.current) parentRef.current.focus();
                  }
                }}
              >
                {consentDetails.programDetails && (
                  <ProgramPanel program={consentDetails.programDetails} />
                )}
                <SingleLine className="width__100 mt-sm mb-sm" />
                <Box component="div">
                  <ConsentGeneralInfo
                    consentDatePlaceholder={'ConsentForms.signedDate'}
                    consentDateDefaultPlaceholder={'Consent Date:'}
                    expiryDatePlaceholder={'ConsentForms.revokedDate'}
                    expiryDateDefaultPlaceholder={'Expiry Date:'}
                    requestedDate={
                      consentDetails.requestedDate
                        ? moment(consentDetails.requestedDate)
                            .local()
                            .format('MM/DD/YYYY')
                        : new Date().toLocaleDateString('en-US')
                    }
                    requester={consentDetails.requester}
                    status={handleStatus(consentDetails.status)}
                    signedDate={
                      consentDetails.signedDate
                        ? moment(consentDetails.signedDate)
                            .local()
                            .format('MM/DD/YYYY')
                        : ''
                    }
                    expiredDate={
                      expiryDate
                        ? moment(expiryDate).local().format('MM/DD/YYYY')
                        : ''
                    }
                    statusLabelId={'ConsentForms.status'}
                    statusDefaultLabelId={'Authorization Status:'}
                    requestedNameLabelId={'ConsentForms.requesterName'}
                    requestedNameDefaultLabelId={'Requested by:'}
                    requestedEmailLabelId={'ConsentForms.requesterEmail'}
                    requestedEmailDefaultLabelId={'Requester Email:'}
                    requestedDateLabelId={'ConsentForms.requestedDate'}
                    requestedDateDefaultLabelId={'Requested Date:'}
                    requestedPhoneLabelId={'ConsentForms.requesterPhone'}
                    requestedPhoneDefaultLabelId={'Requester Phone:'}
                  />
                  <SingleLine className="width__100 mt-sm mb-sm" />
                </Box>
                <Box component="div">
                  <ConsentClientDetails
                    titleLabel={'ConsentForms.clientTitle'}
                    defaultTitleLabel="Client Details"
                    searchClientLabel={'ConsentForms.clientSearchPlaceholder'}
                    defaultSearchClientLabel="Search and select client"
                    clients={selectedClient}
                    dphIdLabel={'ConsentForms.clientDPHID'}
                    dphIddefaultLabel={'Enrollment ID:'}
                    dobLabel={'ConsentForms.clientDob'}
                    dobDefaultLabel={'Date of Birth:'}
                    genderLabel={'ConsentForms.clientGender'}
                    genderDefaultLabel={'Sex:'}
                    addressLabel={'ConsentForms.clientAddress'}
                    addressDefaultLabel={'Address:'}
                  />
                  <SingleLine className="width__100 mt-sm mb-sm" />
                </Box>
                <Formik
                  initialValues={initialState}
                  validateOnMount={true}
                  innerRef={formRef}
                  enableReinitialize={true}
                  onSubmit={handleSave}
                  validationSchema={validationSchema}
                >
                  {({ setFieldValue, values, errors, touched }) => (
                    <Box component="div">
                      <Box component="div">
                        <Box component="div" className="mt-md">
                          <MediumTypography
                            className=""
                            labelid="leaReferralConsentParentsOPTInOPTOut"
                            defaultlabel="Parent choose to opt out of notification ?"
                          />

                          <CustomRadioButton
                            data={[
                              {
                                value: 'Yes',
                                labelId: 'Yes',
                                defaultLabel: 'Yes',
                              },
                              {
                                value: 'No',
                                labelId: 'No',
                                defaultLabel: 'No',
                              },
                            ]}
                            value={
                              values.schoolNameMandatory == true ? 'No' : 'Yes'
                            }
                            onSelectItem={(value) => {
                              if (value == 'Yes') {
                                setFieldValue('schoolName', '');
                                setInitialState((prevValue) => ({
                                  ...prevValue,
                                  schoolName: '',
                                }));
                              }
                              setFieldValue(
                                'schoolNameMandatory',
                                value == 'No' ? true : false,
                              );
                              setInitialState((prevValue) => ({
                                ...prevValue,
                                schoolNameMandatory:
                                  value == 'No' ? true : false,
                              }));
                            }}
                            row={false}
                          />
                        </Box>

                        <Box component="div" className="mt-md">
                          {values.schoolNameMandatory && (
                            <Box>
                              <Textinput
                                key={'schoolName'}
                                name="schoolName"
                                labelid="School Name"
                                defaultlabelid="School Name"
                                Value={values.schoolName}
                                inputProps={{
                                  maxLength: 100,
                                  className: 'translate',
                                }}
                                sxProps={{ width: '250px' }}
                                handlechange={(text) => {
                                  setFieldValue('schoolName', text);
                                  setInitialState((prevValue) => ({
                                    ...prevValue,
                                    schoolName: text,
                                  }));
                                }}
                                Required={values.schoolNameMandatory}
                              />
                              {errors.schoolName && touched.schoolName && (
                                <MediumTypography
                                  className="errorText-md"
                                  labelid={errors.schoolName}
                                  defaultlabel="School name is required"
                                />
                              )}
                            </Box>
                          )}
                          {values.schoolNameMandatory && (
                            <Typography
                              dangerouslySetInnerHTML={{ __html: letter }}
                              sx={{
                                fontFamily: 'Lato-Regular',
                                fontSize: '14px',
                                fontWeight: 400,
                              }}
                              className="pt-md pb-md"
                            />
                          )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Formik>
                <SingleLine className="width__100 mt-sm mb-sm" />
                <Box component="div">
                  <Box component="div">
                    <MediumTypography
                      fontSize="18px"
                      fontweight={600}
                      textColor="#2A4241"
                      labelid="ConsentForms.signatureTitleForNoticeofPrivacyPractices"
                      defaultlabel="Acknowledgment of Receipt of Notice of Privacy Practices"
                      className="pt-md pb-md"
                    />
                    <MediumTypography
                      textColor="#2A4241"
                      labelid="ConsentForms.signatureSubTitleForNoticeofPrivacyPractices"
                      defaultlabel="By signing below, I acknowledge that I have been provided with a copy of this Notice of Privacy Practices."
                    />
                  </Box>
                  <Box component="div">
                    <MediumTypography
                      textColor="#2A4241"
                      fontSize="16px"
                      fontweight={600}
                      labelid="ConsentForms.signatureSubTitleForLegalReleaseOfRecords"
                      defaultlabel="Who can authorize us to give information to others about your child?"
                      className="pt-md"
                    />
                  </Box>
                  <MediumTypography
                    fontSize="18px"
                    fontweight={600}
                    textColor="#2A4241"
                    labelid={'ConsentForms.signatureTitle'}
                    defaultlabel={'Signature'}
                    className="pt-md"
                  />
                  <ConsentSignature
                    signatureData={consentDetails.signature}
                    staffSignature={false}
                    onSaveClick={onSaveClick}
                    verbalConsentValue={consentDetails.signature}
                    onVerbalConsentClick={onVerbalConsentClick}
                    defaultParentGuardianNoId="Guardian Name"
                    parentGuardianNoId="ConsentParentGuardianNoId"
                    relationshipWithChildId="ConsentRelationshipWithClient"
                    defaultRelationshipWithChildId="Guardian Type:"
                    emailId="ConsentEmail"
                    defaultEmailId="Email:"
                    dateId="dateLabel"
                    defaultDateId="Date:"
                    isDateRequired={false}
                    viewUpdateId="ConsentViewOrUpdateSignature"
                    defaultViewUpdateId="View/update signature"
                    addSignatureId="ConsentAddSignature"
                    defaultAddSignatureId="Add Signature"
                    verbalConsentId="ConsentVerbalConsent"
                    defaultVerbalConsentId="Verbal Consent"
                    parentSignatureId="ConsentParentSignature"
                    defaultParentSignatureId="Parent Signature"
                  />
                  <SingleLine className="width__100 mt-sm mb-sm" />
                </Box>
                <Box component="div">
                  <MediumTypography
                    fontSize="18px"
                    fontweight={600}
                    textColor="#2A4241"
                    labelid="ConsentForms.disclaimerTitle"
                    defaultlabel="Disclaimer"
                    className="pb-sm pt-md"
                  />
                  <Typography
                    dangerouslySetInnerHTML={{ __html: disclaimer }}
                    sx={{
                      fontFamily: 'Lato-Regular',
                      fontSize: '14px',
                      fontWeight: 400,
                    }}
                  />
                </Box>
              </div>
            </Card>
            <Box className="p-lg pr-none justifyContent-FlexEnd flex__">
              {!isScreenLocked && (
                <>
                  <ButtonComponent
                    className="btn-primary btn-cancel mr-md"
                    labelId={!btnName ? 'ConsentCancel' : 'ConsentBack'}
                    defaultLabelId="Cancel"
                    variantType="outlined"
                    onClick={handleClear}
                  />
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    labelId="ConsentSave"
                    defaultLabelId="Save"
                    variantType="contained"
                    onClick={formRef.current?.handleSubmit}
                  />
                </>
              )}
            </Box>
          </Box>
        </Box>
      </TranslateWidget>
    </Box>
  );
};

export default LEAReferralConsent;
