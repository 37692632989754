import React, { FC } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as CloseIcon } from '../../assets/images/selectCloseIcon.svg';
import './Textinput.css';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormattedMessage } from 'react-intl';
import { SxProps } from '@mui/system';
import { OptionType } from '../../utils/type';
import MediumTypography from './MediumTypography';
import { Badge, styled, Box } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      scroll: 'hidden',
      maxWidth: 'min-content',
    },
  },
};
interface DropdownComponentProps {
  SelectLabel?: string;
  optionalBadge?: boolean;
  name?: string;
  value?: string;
  handleChange?: (value: string) => void;
  names: OptionType[];
  defaultValue?: string;
  disabled?: boolean;
  labelid?: string;
  defaultlabelid?: string;
  sxProps?: SxProps;
  className?: string;
  Required?: boolean;
  placeholderOnfloatBackgroundColor?: string;
}

const DropdownComponent: FC<DropdownComponentProps> = ({
  SelectLabel,
  optionalBadge,
  name,
  value,
  handleChange,
  names,
  defaultValue,
  disabled,
  labelid,
  defaultlabelid,
  sxProps,
  Required,
  className,
  placeholderOnfloatBackgroundColor,
}) => {
  const selectStyles: SxProps = {
    fontFamily: 'Lato-Regular',
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#00C6B8',
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: !disabled ? '#00C6B8' : 'inherit',
    },
    height: '36px',
    fontSize: '14px',
    backgroundColor: '#fff',
  };
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (disabled) {
      return;
    }
    setOpen(true);
  };

  const CustomBadge = styled(Badge)(({ color }: { color?: string }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: color ?? '#37D183',
      height: '8px',
      width: '8px',
      top: 0,
      bottom: 0,
    },
  }));
  const badgePicker = (id: string) => {
    switch (id) {
      case 'appointmentType1':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-green-primary)',
              },
            }}
          />
        );
      case 'appointmentType2':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-pink)',
              },
            }}
          />
        );
      case 'appointmentType3':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-blue)',
              },
            }}
          />
        );
      case 'appointmentType4':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-red)',
              },
            }}
          />
        );
      case 'appointmentType5':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-yellow)',
              },
            }}
          />
        );
      case 'appointmentType6':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-purple)',
              },
            }}
          />
        );
      case 'appointmentType7':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-dark-blue)',
              },
            }}
          />
        );
      case 'appointmentType8':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-brown)',
              },
            }}
          />
        );
      case 'appointmentType9':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-orange)',
              },
            }}
          />
        );
      case 'appointmentType10':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-violet)',
              },
            }}
          />
        );
      case 'appointmentType11':
        return (
          <CustomBadge
            variant="dot"
            sx={{
              '& .MuiBadge-badge': {
                backgroundColor: 'var(--thom-dark-green)',
              },
            }}
          />
        );
      default:
        return <CustomBadge variant="dot" />;
    }
  };
  return (
    <FormControl
      className="inputSelectFormLabel"
      sx={{
        width: '100%',
        left: '0px',
        m: 0,
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            border: '1px solid #00C6B8',
          },
        },
        '&.inputSelectFormLabel > label.MuiFormLabel-filled': {
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
          color: '#97A6A5',
        },
        '&.inputSelectFormLabel > label.Mui-focused': {
          backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
          color: '#00C6B8',
        },
      }}
    >
      <InputLabel
        sx={{
          fontFamily: 'Lato-Regular',
          fontWeight: 500,
          fontSize: '14px',
          lineHeight: '17px',
          color: '#97A6A5',
          '&.Mui-focused': {
            color: '#97A6A5',
          },
        }}
      >
        {labelid && (
          <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
        )}
        {Required && <span>*</span>}
      </InputLabel>
      <Select
        className={className}
        MenuProps={MenuProps}
        name={name}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        disabled={disabled}
        defaultValue={defaultValue}
        IconComponent={() => null}
        onChange={(event: SelectChangeEvent) => {
          if (handleChange !== undefined) {
            handleChange(event.target.value);
          }
        }}
        required={Required}
        sx={selectStyles}
        input={
          <OutlinedInput
            className="addReferralItemEllipsis"
            disabled={disabled}
            id="selectcomponentid"
            required={Required}
            label={
              !labelid ? (
                SelectLabel
              ) : (
                <FormattedMessage
                  id={labelid}
                  defaultMessage={defaultlabelid}
                />
              )
            }
            endAdornment={
              <Box className="flex__ align__items__center">
                {value && (
                  <CloseIcon
                    stroke=""
                    style={{ cursor: 'pointer', marginRight: '4px' }}
                    onClick={() => {
                      if (handleChange) {
                        handleChange('');
                      }
                    }}
                  />
                )}
                <KeyboardArrowDownIcon
                  onClick={handleOpen}
                  style={{
                    cursor: disabled ? 'not-allowed' : 'pointer',
                    color: '#97A6A5',
                    marginRight: '-4px',
                    marginTop: '4px',
                  }}
                />
              </Box>
            }
          />
        }
      >
        {names.map((item: OptionType) => (
          <MenuItem
            key={item.id}
            value={item.id}
            defaultValue={item.id}
            sx={{
              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
              fontFamily: 'Lato-Regular',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '17px',
              textOverflow: 'ellipsis',
              // wordBreak: 'break-all',
              whiteSpace: 'normal',
              ...sxProps,
            }}
          >
            {optionalBadge ? (
              <Box className="flex__">
                <Box style={{ alignSelf: 'center' }}>
                  {badgePicker(item.id)}
                </Box>
                <MediumTypography
                  label={item.label}
                  sxProps={{
                    paddingLeft: '10px',
                    color: '#000000de !important',
                  }}
                />
              </Box>
            ) : (
              <MediumTypography
                label={item.label}
                sxProps={{
                  color: item.color,
                }}
              />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DropdownComponent;
