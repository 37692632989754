import { Box, Card, Grid } from '@mui/material';
import { Formik, FormikErrors, FormikProps } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { EICSRadioData, RoutingProps } from './IntakeClientsInfo';
import * as yup from 'yup';
import {
  IntakeCurrentHealthInfoResponseType,
  getIntakeCurrentHealthInfoDetails,
  putIntakeCurrentHealthInfo,
} from '../../services/configApi/forms/Intake/IntakeServices';
import MediumTypography from '../../components/formlib/MediumTypography';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import { IntakeRadioData } from './IntakePregnancyInfo';
import Textinput from '../../components/formlib/Textinput';
import SmallTypography from '../../components/formlib/SmallTypography';
import { ReactComponent as DeleteIcon } from '../../assets/images/deleteAlertIcon.svg';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import CustomCheckBox from '../../components/formlib/CustomCheckBox';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ResponseType } from '../../utils/type';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import { formatPhoneNumber } from '../../utils/formValidationUtil';
import HealthIssueJsonData from './IntakeJSON/CurrentHealthTab/HealthIssue.json';
import MedicalSpecialtyJsonData from './IntakeJSON/CurrentHealthTab/MedicalSpecialty.json';
import MedicalProceduresJsonData from './IntakeJSON/CurrentHealthTab/MedicalProcedures.json';
import ModalPopup from '../../components/formlib/ModalPopup';
import {
  DownloadContext,
  DownloadContextType,
  showCancelToaster,
} from './IntakeTabNavigationComponent';
import { checkPermissionForFeature } from '../../utils/checkPermission';

type HospitalViewJsonType = {
  id: number;
  dateOfAdmitted: string | null;
  hospitalName: string;
  phoneNumber: string;
  comment: string;
};

const IntakeCurrentHealthInitialValues = {
  hospitalization: '',
  hospitals: [
    {
      id: 1,
      dateOfAdmitted: null,
      hospitalName: '',
      phoneNumber: '',
      comment: '',
    },
  ],
  immunizationsUpToDate: '',
  immunizationExplain: '',
  seenDentist: '',
  dentistFirstName: '',
  dentistlastName: '',
  dentistPhoneNumber: '',
  childOralHealthExplain: '',
  healthIssueNames: [],
  seizureDisorderExplain: '',
  bloodLevel: '',
  bloodLevelDesc: '',
  heartDefectDesc: '',
  allergiesDesc: '',
  foodIntolerancesDesc: '',
  brokenBonesDesc: '',
  healthIssueOthers: '',
  medicalSpecialtyNames: [],
  medicalSpecialtyOther: '',
  medicalProcedureNames: [],
  medicalProcedureOther: '',
  therapies: '',
  theraphyDesc: '',
  temperamentDesc: '',
  childDesc: '',
  childEatingDesc: '',
  childFeedDesc: '',
  childGrowthDesc: '',
  childSleepDesc: '',
  activitiesDesc: '',
  childBehaviourDesc: '',
  childActivities: '',
  socialRelationship: '',
  socialRelationshipDesc: '',
  childSmile: '',
  childRollOver: '',
  childCrawl: '',
  childWalkAlone: '',
  childSitIndependently: '',
  childImitateActions: '',
  childImitateSounds: '',
  childUseFirstWords: '',
  childMakeNoise: '',
  childSoundsDesc: '',
  childNeedsDesc: '',
  childFollowsDesc: '',
  childTolerateDesc: '',
  childlookSmileDesc: '',
  childUnderstandDesc: '',
  supportSystems: '',
  medicationExplain: '',
  childBloodLevel: '',
  childInsecureDifficulties: '',
  childNervousSystem: '',
  childMultipleTrauma: '',
  childHospitalized: '',
  childDiagnosedAtBirth: '',
  childChronicFeeding: '',
  smallSize: '',
  comment: '',
};

export interface IntakeCurrentHealthInfoType {
  hospitalization: string;
  hospitals: HospitalViewJsonType[];
  immunizationsUpToDate: string;
  immunizationExplain: string;
  seenDentist: string;
  dentistFirstName: string;
  dentistlastName: string;
  dentistPhoneNumber: string;
  childOralHealthExplain: string;
  healthIssueNames: string[];
  seizureDisorderExplain: string;
  bloodLevel: string;
  bloodLevelDesc: string;
  heartDefectDesc: string;
  allergiesDesc: string;
  foodIntolerancesDesc: string;
  brokenBonesDesc: string;
  healthIssueOthers: string;
  medicalSpecialtyNames: string[];
  medicalSpecialtyOther: string;
  medicalProcedureNames: string[];
  medicalProcedureOther: string;
  therapies: string;
  theraphyDesc: string;
  temperamentDesc: string;
  childDesc: string;
  childEatingDesc: string;
  childFeedDesc: string;
  childGrowthDesc: string;
  childSleepDesc: string;
  activitiesDesc: string;
  childBehaviourDesc: string;
  childActivities: string;
  socialRelationship: string;
  socialRelationshipDesc: string;
  childSmile: string;
  childRollOver: string;
  childCrawl: string;
  childWalkAlone: string;
  childSitIndependently: string;
  childImitateActions: string;
  childImitateSounds: string;
  childUseFirstWords: string;
  childMakeNoise: string;
  childSoundsDesc: string;
  childNeedsDesc: string;
  childFollowsDesc: string;
  childTolerateDesc: string;
  childlookSmileDesc: string;
  childUnderstandDesc: string;
  supportSystems: string;
  medicationExplain: string;
  childBloodLevel: string;
  childInsecureDifficulties: string;
  childNervousSystem: string;
  childMultipleTrauma: string;
  childHospitalized: string;
  childDiagnosedAtBirth: string;
  childChronicFeeding: string;
  smallSize: string;
  comment: string;
}

export interface IntakeCurrentHealthFormikType {
  hospitalization: string;
  hospitals: HospitalViewJsonType[];
  immunizationsUpToDate: string;
  immunizationExplain: string;
  seenDentist: string;
  dentistFirstName: string;
  dentistlastName: string;
  dentistPhoneNumber: string;
  childOralHealthExplain: string;
  seizureDisorderExplain: string;
  bloodLevel: string;
  bloodLevelDesc: string;
  heartDefectDesc: string;
  allergiesDesc: string;
  foodIntolerancesDesc: string;
  brokenBonesDesc: string;
  healthIssueOthers: string;
  medicalSpecialtyOther: string;
  medicalProcedureOther: string;
  therapies: string;
  theraphyDesc: string;
  temperamentDesc: string;
  childDesc: string;
  childEatingDesc: string;
  childFeedDesc: string;
  childGrowthDesc: string;
  childSleepDesc: string;
  activitiesDesc: string;
  childBehaviourDesc: string;
  childActivities: string;
  socialRelationship: string;
  socialRelationshipDesc: string;
  childSmile: string;
  childRollOver: string;
  childCrawl: string;
  childWalkAlone: string;
  childSitIndependently: string;
  childImitateActions: string;
  childImitateSounds: string;
  childUseFirstWords: string;
  childMakeNoise: string;
  childSoundsDesc: string;
  childNeedsDesc: string;
  childFollowsDesc: string;
  childTolerateDesc: string;
  childlookSmileDesc: string;
  childUnderstandDesc: string;
  supportSystems: string;
  medicationExplain: string;
  childBloodLevel: string;
  childInsecureDifficulties: string;
  childNervousSystem: string;
  childMultipleTrauma: string;
  childHospitalized: string;
  childDiagnosedAtBirth: string;
  childChronicFeeding: string;
  smallSize: string;
  comment: string;
}

const validationSchema = yup.object().shape({
  hospitalization: yup.string().nullable(),
  hospitals: yup.array().of(
    yup.object().shape({
      dateOfAdmitted: yup.string(),
      hospitalName: yup.string(),
      phoneNumber: yup.string(),
      comment: yup.string(),
    }),
  ),
  immunizationsUpToDate: yup.string(),
  immunizationExplain: yup.string(),
  seenDentist: yup.string(),
  dentistFirstName: yup.string(),
  dentistlastName: yup.string(),
  dentistPhoneNumber: yup.string(),
  childOralHealthExplain: yup.string(),
  seizureDisorderExplain: yup.string(),
  bloodLevel: yup.string(),
  bloodLevelDesc: yup.string(),
  heartDefectDesc: yup.string(),
  allergiesDesc: yup.string(),
  foodIntolerancesDesc: yup.string(),
  brokenBonesDesc: yup.string(),
  healthIssueOthers: yup.string(),
  medicalSpecialtyOther: yup.string(),
  medicalProcedureOther: yup.string(),
  therapies: yup.string(),
  theraphyDesc: yup.string(),
  temperamentDesc: yup.string(),
  childDesc: yup.string(),
  childEatingDesc: yup.string(),
  childFeedDesc: yup.string(),
  childGrowthDesc: yup.string(),
  childSleepDesc: yup.string(),
  activitiesDesc: yup.string(),
  childBehaviourDesc: yup.string(),
  childActivities: yup.string(),
  socialRelationship: yup.string(),
  socialRelationshipDesc: yup.string(),
  childSmile: yup.string(),
  childRollOver: yup.string(),
  childCrawl: yup.string(),
  childWalkAlone: yup.string(),
  childSitIndependently: yup.string(),
  childImitateActions: yup.string(),
  childImitateSounds: yup.string(),
  childUseFirstWords: yup.string(),
  supportSystems: yup.string(),
  medicationExplain: yup.string(),
  childBloodLevel: yup.string(),
  childInsecureDifficulties: yup.string(),
  childNervousSystem: yup.string(),
  childMultipleTrauma: yup.string(),
  childHospitalized: yup.string(),
  childDiagnosedAtBirth: yup.string(),
  childChronicFeeding: yup.string(),
  smallSize: yup.string(),
});

const IntakeCurrentHealthInfo: React.FC<RoutingProps> = ({ setTabProp }) => {
  const clientId = localStorage.getItem('ClientId');
  const [intakeCurrentHealthInfo, setIntakeCurrentHealthInfo] =
    useState<IntakeCurrentHealthInfoType>(IntakeCurrentHealthInitialValues);

  const [hospitalView, setHospitalView] = React.useState<
    HospitalViewJsonType[]
  >(IntakeCurrentHealthInitialValues.hospitals);

  const [healthIssueData, setHealthIssueData] = useState(HealthIssueJsonData);

  const [healthIssueNames, setHealthIssueNames] = useState<string[]>([]);

  const [medicalSpecialtyData, setMedicalSpecialtyData] = useState(
    MedicalSpecialtyJsonData,
  );

  const [medicalSpecialtyNames, setMedicalSpecialtyNames] = useState<string[]>(
    [],
  );
  const [medicalProceduresData, setMedicalProceduresData] = useState(
    MedicalProceduresJsonData,
  );

  const [medicalProcedureNames, setMedicalProcedureNames] = useState<string[]>(
    [],
  );

  const [intakeCurrentHealthSavedValues, setIntakeCurrentHealthSavedValues] =
    useState<IntakeCurrentHealthInfoResponseType>();

  const formikRef = useRef<FormikProps<IntakeCurrentHealthFormikType>>(null);

  const [openEICSSection, setOpenEICSSection] = useState(true);

  const [openAdditionalSection, setOpenAdditionalSection] = useState(true);

  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');

  const [openModal, setOpenModal] = useState(false);

  const [viewToBeLocked, setViewToBeLocked] = useState<boolean>(false);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const { toggleDownload } = React.useContext(
    DownloadContext,
  ) as DownloadContextType;

  useEffect(() => {
    const handleIntakeGet = async () => {
      handleIntakeGetAPI();
    };
    handleIntakeGet();
  }, []);

  const handleHealthIssueCheckBoxClick = (id: number, checked: boolean) => {
    setHealthIssueData(
      healthIssueData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }

        setHealthIssueNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });
        if (id === 0 && !checked) {
          formikRef.current?.setFieldValue('seizureDisorderExplain', '');
        } else if (id === 7 && !checked) {
          formikRef.current?.setFieldValue('bloodLevel', '');
          formikRef.current?.setFieldValue('bloodLevelDesc', '');
        } else if (id === 8 && !checked) {
          formikRef.current?.setFieldValue('heartDefectDesc', '');
        } else if (id === 10 && !checked) {
          formikRef.current?.setFieldValue('allergiesDesc', '');
        } else if (id === 11 && !checked) {
          formikRef.current?.setFieldValue('foodIntolerancesDesc', '');
        } else if (id === 16 && !checked) {
          formikRef.current?.setFieldValue('brokenBonesDesc', '');
        } else if (id === healthIssueData.length - 1 && !checked) {
          formikRef.current?.setFieldValue('healthIssueOthers', '');
        }

        return data;
      }),
    );
  };

  const handleMedicalSpecialtyCheckBoxClick = (
    id: number,
    checked: boolean,
  ) => {
    setMedicalSpecialtyData(
      medicalSpecialtyData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }
        setMedicalSpecialtyNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });

        if (id === medicalSpecialtyData.length - 1 && !checked) {
          formikRef.current?.setFieldValue('medicalSpecialtyOther', '');
        }

        return data;
      }),
    );
  };

  const handleMedicalProceduresCheckBoxClick = (
    id: number,
    checked: boolean,
  ) => {
    setMedicalProceduresData(
      medicalProceduresData.map((data) => {
        if (data.id === id) {
          data.checked = checked;
        }
        setMedicalProcedureNames((prevNames) => {
          if (data.checked) {
            return [...prevNames, data.dataLabelId];
          } else {
            return prevNames.filter((name) => name !== data.dataLabelId);
          }
        });

        if (id === medicalProceduresData.length - 1 && !checked) {
          formikRef.current?.setFieldValue('medicalProcedureOther', '');
        }

        return data;
      }),
    );
  };

  const handleIntakeGetAPI = async () => {
    if (clientId) {
      window.scrollTo(0, 0);
      toggleLoader(true);
      getIntakeCurrentHealthInfoDetails(clientId)
        .then((response: IntakeCurrentHealthInfoResponseType) => {
          if (response.readyToDownload) {
            toggleDownload(response.readyToDownload);
          }
          if (response.isSigned) {
            setViewToBeLocked(true);
          } else {
            if (response.isCurrentlyHeld) {
              setViewToBeLocked(true);
              showCancelToaster(response.heldBy);
            } else {
              // unlock the page
              if (
                checkPermissionForFeature('backend.clients', 'editPermission')
              ) {
                setViewToBeLocked(false);
              } else {
                setViewToBeLocked(true);
              }
            }
          }
          setIntakeCurrentHealthSavedValues(response);

          setIntakeCurrentHealthInfo({
            ...intakeCurrentHealthInfo,
            hospitalization: response.eics.hospitalization,
            hospitals: response.medication.hospitals,
            medicalSpecialtyOther: response.medication.medicalSpecialtyOther,
            medicalProcedureOther: response.medication.medicalProcedureOther,
            medicationExplain: response.medication.medicationExplain,
            immunizationsUpToDate: response.doctor.immunizationsUpToDate,
            immunizationExplain: response.doctor.immunizationExplain,
            seenDentist: response.doctor.seenDentist,
            dentistFirstName: response.doctor.dentistFirstName,
            dentistlastName: response.doctor.dentistlastName,
            dentistPhoneNumber: response.doctor.dentistPhoneNumber,
            childOralHealthExplain: response.healthIssue.childOralHealthExplain,
            seizureDisorderExplain: response.healthIssue.seizureDisorderExplain,
            bloodLevel: response.healthIssue.bloodLevel,
            bloodLevelDesc: response.healthIssue.bloodLevelDesc,
            heartDefectDesc: response.healthIssue.heartDefectDesc,
            allergiesDesc: response.healthIssue.allergiesDesc,
            foodIntolerancesDesc: response.healthIssue.foodIntolerancesDesc,
            brokenBonesDesc: response.healthIssue.brokenBonesDesc,
            healthIssueOthers: response.healthIssue.healthIssueOthers,
            therapies: response.doctor.therapies,
            theraphyDesc: response.doctor.theraphyDesc,
            temperamentDesc: response.doctor.temperamentDesc,
            childDesc: response.childDesc.childDesc,
            childEatingDesc: response.childDesc.childEatingDesc,
            childFeedDesc: response.childDesc.childFeedDesc,
            childGrowthDesc: response.childDesc.childGrowthDesc,
            childSleepDesc: response.childDesc.childSleepDesc,
            activitiesDesc: response.childDesc.activitiesDesc,
            childBehaviourDesc: response.childDesc.childBehaviourDesc,
            childActivities: response.childActivities.childActivities,
            socialRelationship: response.childActivities.socialRelationship,
            socialRelationshipDesc: response.childDesc.socialRelationshipDesc,
            childSmile: response.childActivities.childSmile,
            childRollOver: response.childActivities.childRollOver,
            childCrawl: response.childActivities.childCrawl,
            childWalkAlone: response.childActivities.childWalkAlone,
            childSitIndependently:
              response.childActivities.childSitIndependently,
            childImitateActions: response.childActivities.childImitateActions,
            childImitateSounds: response.childActivities.childImitateSounds,
            childUseFirstWords: response.childActivities.childUseFirstWords,
            childMakeNoise: response.childActivities.childMakeNoise,
            childSoundsDesc: response.childDesc.childSoundsDesc,
            childNeedsDesc: response.childDesc.childNeedsDesc,
            childFollowsDesc: response.childDesc.childFollowsDesc,
            childTolerateDesc: response.childDesc.childTolerateDesc,
            childlookSmileDesc: response.childDesc.childlookSmileDesc,
            childUnderstandDesc: response.childDesc.childUnderstandDesc,
            supportSystems: response.childActivities.supportSystems,
            childBloodLevel: response.eics.childBloodLevel,
            childInsecureDifficulties: response.eics.childInsecureDifficulties,
            childNervousSystem: response.eics.childNervousSystem,
            childMultipleTrauma: response.eics.childMultipleTrauma,
            childHospitalized: response.eics.childHospitalized,
            childDiagnosedAtBirth: response.eics.childDiagnosedAtBirth,
            childChronicFeeding: response.eics.childChronicFeeding,
            smallSize: response.eics.smallSize,
            comment: response.comment ? response.comment : '',
          });

          setHospitalView(response.medication.hospitals);

          getCheckBoxSelectedNamesGenericFunc(
            'medicalSpecialtyNames',
            response.medication.medicalSpecialtyNames,
          );
          getCheckBoxSelectedNamesGenericFunc(
            'medicalProcedureNames',
            response.medication.medicalProcedureNames,
          );
          getCheckBoxSelectedNamesGenericFunc(
            'healthIssueNames',
            response.healthIssue.healthIssueNames,
          );

          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  const handleNameChange = (index: number, value: string) => {
    const nameChangeValue = [...hospitalView];
    nameChangeValue[index].hospitalName = value;
    setHospitalView(nameChangeValue);
  };

  const handlePhoneChange = (index: number, value: string) => {
    const phoneChangeValue = [...hospitalView];
    phoneChangeValue[index].phoneNumber = value;
    setHospitalView(phoneChangeValue);
  };

  const handleDateChange = (index: number, value: string | null) => {
    const dateChangeValue = [...hospitalView];
    dateChangeValue[index].dateOfAdmitted = value;
    setHospitalView(dateChangeValue);
  };

  const handleCommentChange = (index: number, value: string) => {
    const commentChangeValue = [...hospitalView];
    commentChangeValue[index].comment = value;
    setHospitalView(commentChangeValue);
  };

  const handleAddLink = () => {
    const updatedDataView = [...hospitalView];
    const newCode = {
      id: updatedDataView.length + 1,
      dateOfAdmitted: null,
      hospitalName: '',
      phoneNumber: '',
      comment: '',
    };

    updatedDataView.push(newCode);

    setHospitalView(updatedDataView);

    formikRef.current?.setValues({
      ...formikRef.current.values,
      hospitals: updatedDataView,
    });
  };

  const getCheckBoxSelectedNamesGenericFunc = (
    type: string,
    selectedProblems: string[],
  ) => {
    if (type === 'medicalSpecialtyNames') {
      setMedicalSpecialtyData(
        medicalSpecialtyData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setMedicalSpecialtyNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    } else if (type === 'medicalProcedureNames') {
      setMedicalProceduresData(
        medicalProceduresData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setMedicalProcedureNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    } else if (type === 'healthIssueNames') {
      setHealthIssueData(
        healthIssueData.map((data) => {
          if (selectedProblems.includes(data.dataLabelId)) {
            data.checked = true;
          } else {
            data.checked = false;
          }
          setHealthIssueNames((prevNames) => {
            if (data.checked) {
              return [...prevNames, data.dataLabelId];
            } else {
              return prevNames.filter((name) => name !== data.dataLabelId);
            }
          });
          return data;
        }),
      );
    }
  };

  const handleFormSubmit = async (values: IntakeCurrentHealthFormikType) => {
    const params: IntakeCurrentHealthInfoResponseType = {
      medication: {
        medicalSpecialtyNames: [...new Set(medicalSpecialtyNames)],
        medicalSpecialtyOther: values.medicalSpecialtyOther,
        medicalProcedureNames: [...new Set(medicalProcedureNames)],
        medicalProcedureOther: values.medicalProcedureOther,
        medicationExplain: values.medicationExplain,
        hospitals: hospitalView,
      },
      healthIssue: {
        healthIssueNames: [...new Set(healthIssueNames)],
        childOralHealthExplain: values.childOralHealthExplain,
        seizureDisorderExplain: values.seizureDisorderExplain,
        bloodLevel: values.bloodLevel,
        bloodLevelDesc: values.bloodLevelDesc,
        heartDefectDesc: values.heartDefectDesc,
        allergiesDesc: values.allergiesDesc,
        foodIntolerancesDesc: values.foodIntolerancesDesc,
        brokenBonesDesc: values.brokenBonesDesc,
        healthIssueOthers: values.healthIssueOthers,
      },
      doctor: {
        immunizationsUpToDate: values.immunizationsUpToDate,
        immunizationExplain: values.immunizationExplain,
        seenDentist: values.seenDentist,
        dentistFirstName: values.dentistFirstName,
        dentistlastName: values.dentistlastName,
        dentistPhoneNumber: values.dentistPhoneNumber,
        therapies: values.therapies,
        theraphyDesc: values.theraphyDesc,
        temperamentDesc: values.temperamentDesc,
      },
      childDesc: {
        childDesc: values.childDesc,
        childEatingDesc: values.childEatingDesc,
        childFeedDesc: values.childFeedDesc,
        childGrowthDesc: values.childGrowthDesc,
        childSleepDesc: values.childSleepDesc,
        activitiesDesc: values.activitiesDesc,
        childBehaviourDesc: values.childBehaviourDesc,
        socialRelationshipDesc: values.socialRelationshipDesc,
        childSoundsDesc: values.childSoundsDesc,
        childNeedsDesc: values.childNeedsDesc,
        childFollowsDesc: values.childFollowsDesc,
        childTolerateDesc: values.childTolerateDesc,
        childlookSmileDesc: values.childlookSmileDesc,
        childUnderstandDesc: values.childUnderstandDesc,
      },
      childActivities: {
        childActivities: values.childActivities,
        socialRelationship: values.socialRelationship,
        childSmile: values.childSmile,
        childRollOver: values.childRollOver,
        childCrawl: values.childCrawl,
        childWalkAlone: values.childWalkAlone,
        childSitIndependently: values.childSitIndependently,
        childImitateActions: values.childImitateActions,
        childImitateSounds: values.childImitateSounds,
        childUseFirstWords: values.childUseFirstWords,
        childMakeNoise: values.childMakeNoise,
        supportSystems: values.supportSystems,
      },
      eics: {
        hospitalization: values.hospitalization,
        childBloodLevel: values.childBloodLevel,
        childInsecureDifficulties: values.childInsecureDifficulties,
        childNervousSystem: values.childNervousSystem,
        childMultipleTrauma: values.childMultipleTrauma,
        childHospitalized: values.childHospitalized,
        childDiagnosedAtBirth: values.childDiagnosedAtBirth,
        childChronicFeeding: values.childChronicFeeding,
        smallSize: values.smallSize,
      },
      comment: values.comment,
    };

    toggleLoader(true);

    if (clientId) {
      putIntakeCurrentHealthInfo(clientId, params)
        .then((response) => {
          if (response) {
            setSuccessOrError('success');
            setToastrId('Intake.successMsg');
            setToastrDefaultMessage('Intake updated successfully');

            setTimeout(() => {
              toggleLoader(false);
              setTabProp((value) => {
                sessionStorage.setItem(
                  'intakeTabName',
                  String(value.tabPosition),
                );
                return {
                  tabPosition: value.tabPosition,
                };
              });
            }, 1000);

            handleIntakeGetAPI();
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          successOrError={successOrError}
        />
      )}
      <Box
        ref={parentRef}
        style={{
          pointerEvents: viewToBeLocked ? 'none' : 'auto',
          opacity: viewToBeLocked ? '0.8' : 'unset',
        }}
        onKeyDownCapture={(e) => {
          if (viewToBeLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (parentRef.current) parentRef.current.focus();
        }}
      >
        <Card className="formCardview gray3 borderRadius4 mb-md p-sm mt-md">
          <Formik
            initialValues={intakeCurrentHealthInfo}
            innerRef={formikRef}
            validateOnMount={true}
            validateOnChange={true}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Box component="div">
                <Box
                  component="div"
                  className="p-sm gray3 borderRadius4"
                  sx={{
                    border: '1px solid #00C6B8',
                  }}
                >
                  <Box component="div">
                    <Box display="flex" flexDirection="row">
                      <MediumTypography
                        className="mr-xs"
                        labelid="IntakeCurrentHealth.hospitalization"
                        defaultlabel="Hospitalization ?"
                      />

                      <CustomRadioButton
                        data={EICSRadioData}
                        value={values.hospitalization}
                        onSelectItem={(value) => {
                          if (value) {
                            if (values.hospitalization === 'No') {
                              setFieldValue('hospitals', []);
                              setHospitalView([]);
                            }
                            setFieldValue('hospitalization', value);
                            setFieldValue('childHospitalized', value);
                          }
                        }}
                      />
                    </Box>
                    <Box>
                      {errors.hospitalization && touched.hospitalization && (
                        <MediumTypography
                          labelid={errors.hospitalization}
                          defaultlabel="hospitalization is Required"
                          className="errorText-md"
                        />
                      )}
                    </Box>
                  </Box>
                  {values.hospitalization === 'Yes' && (
                    <Box>
                      {hospitalView.map(
                        (items: HospitalViewJsonType, i: number) => (
                          <Grid
                            item
                            container
                            rowSpacing={'12px'}
                            columnSpacing={'40px'}
                            sx={{ display: 'flex' }}
                            className="pt-md"
                            key={items.id}
                          >
                            <Grid item xs={3}>
                              <Textinput
                                name={`hospitalName${i}`}
                                labelid="IntakeCurrentHealth.hospitalName"
                                defaultlabelid="Hospital Name"
                                sxProps={{
                                  background: 'rgba(255, 255, 255, 1)',
                                }}
                                Value={values.hospitals[i]?.hospitalName}
                                handlechange={(value: string) => {
                                  setFieldValue(
                                    `hospitals[${i}].hospitalName`,
                                    value,
                                  );
                                  handleNameChange(i, value);
                                }}
                                placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                              {errors?.hospitals &&
                                errors?.hospitals[i] &&
                                (errors.hospitals[
                                  i
                                ] as FormikErrors<IntakeCurrentHealthFormikType>) &&
                                (
                                  errors.hospitals[
                                    i
                                  ] as FormikErrors<HospitalViewJsonType>
                                ).hospitalName &&
                                touched?.hospitals &&
                                touched?.hospitals[i] &&
                                touched.hospitals[i].hospitalName && (
                                  <SmallTypography
                                    sxProps={{ color: 'red' }}
                                    labelId={
                                      (
                                        errors.hospitals[
                                          i
                                        ] as FormikErrors<HospitalViewJsonType>
                                      ).hospitalName
                                    }
                                    defaultLabelId="Hospital Name is Required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                              <Textinput
                                name={`phoneNumber${i}`}
                                labelid="cType1"
                                defaultlabelid="Phone"
                                sxProps={{
                                  background: 'rgba(255, 255, 255, 1)',
                                }}
                                inputProps={{
                                  maxLength: 12,
                                  pattren: ['0-9'],
                                }}
                                Value={values.hospitals[i]?.phoneNumber}
                                handlechange={(value: string) => {
                                  const validatedNumber =
                                    formatPhoneNumber(value);
                                  setFieldValue(
                                    `hospitals[${i}].phoneNumber`,
                                    validatedNumber,
                                  );
                                  handlePhoneChange(i, value);
                                }}
                                placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                              {errors.hospitals &&
                                errors.hospitals[i] &&
                                (errors.hospitals[
                                  i
                                ] as FormikErrors<IntakeCurrentHealthFormikType>) &&
                                (
                                  errors.hospitals[
                                    i
                                  ] as FormikErrors<HospitalViewJsonType>
                                ).phoneNumber &&
                                touched.hospitals &&
                                touched.hospitals[i] &&
                                touched.hospitals[i].phoneNumber && (
                                  <SmallTypography
                                    sxProps={{ color: 'red' }}
                                    labelId={
                                      (
                                        errors.hospitals[
                                          i
                                        ] as FormikErrors<HospitalViewJsonType>
                                      ).phoneNumber
                                    }
                                    defaultLabelId="Phone Number is Required"
                                  />
                                )}
                            </Grid>
                            <Grid item xs={3}>
                              <DatePickerComponent
                                className="bg__white"
                                value={
                                  dayjs(
                                    values.hospitals[i]?.dateOfAdmitted,
                                  ) as Dayjs
                                }
                                disableFuture={false}
                                labelid="intake.dateOfAdmitted"
                                defaultlabelid="Date Of Admitted"
                                handlechange={(date: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(date).format('MM/DD/YYYY');
                                  if (date === null) {
                                    setFieldValue(
                                      `hospitals[${i}].dateOfAdmitted`,
                                      null,
                                    );
                                    handleDateChange(i, null);
                                  } else {
                                    setFieldValue(
                                      `hospitals[${i}].dateOfAdmitted`,
                                      formattedDate,
                                    );
                                    handleDateChange(i, formattedDate);
                                  }
                                }}
                              />
                              {errors.hospitals &&
                                errors.hospitals[i] &&
                                (errors.hospitals[
                                  i
                                ] as FormikErrors<IntakeCurrentHealthFormikType>) &&
                                (
                                  errors.hospitals[
                                    i
                                  ] as FormikErrors<HospitalViewJsonType>
                                ).dateOfAdmitted &&
                                touched.hospitals &&
                                touched.hospitals[i] &&
                                touched.hospitals[i].dateOfAdmitted && (
                                  <SmallTypography
                                    sxProps={{ color: 'red' }}
                                    labelId={
                                      (
                                        errors.hospitals[
                                          i
                                        ] as FormikErrors<HospitalViewJsonType>
                                      ).dateOfAdmitted
                                    }
                                    defaultLabelId="Date Of Admitted is Required"
                                  />
                                )}
                            </Grid>

                            <Grid item xs={9}>
                              <Textinput
                                name={`comment${i}`}
                                labelid="myTimeOffReason"
                                defaultlabelid="Reason"
                                sxProps={{
                                  background: 'rgba(255, 255, 255, 1)',
                                }}
                                Value={values.hospitals[i]?.comment}
                                handlechange={(value: string) => {
                                  setFieldValue(
                                    `hospitals[${i}].comment`,
                                    value,
                                  );
                                  handleCommentChange(i, value);
                                }}
                                inputProps={{
                                  maxLength: 100,
                                }}
                                placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                            </Grid>
                            <Grid item xs={0.5}>
                              {i >= 0 && (
                                <DeleteIcon
                                  style={{
                                    position: 'relative',
                                    cursor: 'pointer',
                                    width: '22px',
                                    height: '22px',
                                    top: '5px',
                                    right: '30px',
                                  }}
                                  onClick={() => {
                                    const updatedSubCategories = [
                                      ...hospitalView,
                                    ];
                                    updatedSubCategories.splice(i, 1);
                                    formikRef.current?.setValues({
                                      ...formikRef.current?.values,
                                      hospitals: updatedSubCategories,
                                    });
                                    setHospitalView(updatedSubCategories);
                                  }}
                                />
                              )}
                            </Grid>
                          </Grid>
                        ),
                      )}

                      {values.hospitals.length < 3 && (
                        <Grid item xs={4.5}>
                          <ButtonComponent
                            className="btn-add-code pt-md"
                            variantType="contained"
                            type="submit"
                            labelId="IntakeCurrentHealth.addhospitalName"
                            defaultLabelId="+ Add Hospital"
                            onClick={handleAddLink}
                          />
                        </Grid>
                      )}
                    </Box>
                  )}

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-md"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.ImmunizationsTitle"
                      defaultlabel="Immunizations"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeCurrentHealth.ImmunizationsUpToDate"
                      defaultlabel="Are your child’s immunizations up to date ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.immunizationsUpToDate}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('immunizationsUpToDate', value);
                        }
                      }}
                    />
                    {errors.immunizationsUpToDate &&
                      touched.immunizationsUpToDate && (
                        <MediumTypography
                          labelid={errors.immunizationsUpToDate}
                          defaultlabel="immunizationsUpToDate is Required"
                          className="errorText-md"
                        />
                      )}
                  </Box>

                  {values.immunizationsUpToDate === 'No' && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md mb-lg"
                    >
                      {' '}
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.immunizationExplain}
                          placeholder="Please Explain"
                          name="immunizationExplain"
                          maxLength={500}
                          handlechange={(e) => {
                            setFieldValue('immunizationExplain', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.immunizationExplain &&
                          touched.immunizationExplain && (
                            <MediumTypography
                              labelid={errors.immunizationExplain}
                              defaultlabel="Immunization Explaination is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.dentalTitle"
                      defaultlabel="Dental"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeCurrentHealth.seenDentist"
                      defaultlabel="Has your child seen a dentist ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.seenDentist}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'No') {
                            setFieldValue('dentistFirstName', '');
                            setFieldValue('dentistlastName', '');
                            setFieldValue('dentistPhoneNumber', '');
                          }
                          setFieldValue('seenDentist', value);
                        }
                      }}
                    />
                    {errors.seenDentist && touched.seenDentist && (
                      <MediumTypography
                        labelid={errors.seenDentist}
                        defaultlabel="seenDentist is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.seenDentist === 'Yes' && (
                    <Grid
                      item
                      container
                      rowSpacing={'24px'}
                      columnSpacing={'40px'}
                      sx={{ display: 'flex' }}
                      className="pt-md"
                    >
                      <Grid item xs={3}>
                        <Textinput
                          name="dentistFirstName"
                          labelid="IntakeCurrentHealth.dentistFirstName"
                          defaultlabelid="First Name"
                          Value={values.dentistFirstName}
                          handlechange={(text: string) => {
                            setFieldValue(
                              'dentistFirstName',
                              text.replace(/[^A-Za-z,'. -]/g, ''),
                            );
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.dentistFirstName &&
                          touched.dentistFirstName && (
                            <MediumTypography
                              labelid={errors.dentistFirstName}
                              defaultlabel="FirstName is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                      <Grid item xs={3}>
                        <Textinput
                          name="dentistlastName"
                          labelid="IntakeCurrentHealth.dentistlastName"
                          defaultlabelid="Last Name"
                          Value={values.dentistlastName}
                          handlechange={(text: string) => {
                            setFieldValue(
                              'dentistlastName',
                              text.replace(/[^A-Za-z,'. -]/g, ''),
                            );
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.dentistlastName && touched.dentistlastName && (
                          <MediumTypography
                            labelid={errors.dentistlastName}
                            defaultlabel="LastName is Required"
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                      <Grid item xs={3}>
                        <Textinput
                          name="dentistPhoneNumber"
                          labelid="IntakeCurrentHealth.dentistPhoneNumber"
                          defaultlabelid="Phone Number"
                          Value={values.dentistPhoneNumber}
                          handlechange={(text: string) => {
                            const validatedNumber = formatPhoneNumber(text);
                            setFieldValue(
                              'dentistPhoneNumber',
                              validatedNumber,
                            );
                          }}
                          inputProps={{
                            maxLength: 12,
                            pattren: ['0-9'],
                          }}
                          sxProps={{
                            backgroundColor: 'white',
                          }}
                          placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.dentistPhoneNumber &&
                          touched.dentistPhoneNumber && (
                            <MediumTypography
                              labelid={errors.dentistPhoneNumber}
                              defaultlabel="Phone Number is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md mb-lg"
                  >
                    {' '}
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childOralHealthExplain}
                        placeholder="Any concerns for your child’s oral health/teeth?"
                        name="childOralHealthExplain"
                        maxLength={500}
                        handlechange={(e) => {
                          setFieldValue('childOralHealthExplain', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childOralHealthExplain &&
                        touched.childOralHealthExplain && (
                          <MediumTypography
                            labelid={errors.childOralHealthExplain}
                            defaultlabel="childOralHealth Explaination is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.accidentsTitle"
                      defaultlabel="Accidents, Injuries, Chronic Conditions"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Box className="mt-md">
                    <MediumTypography
                      className="mr-md"
                      label="Does your child have any of the following chronic conditions requiring medical attention ?"
                    />
                    <Box component="div" className="ml-lg mt-md">
                      <Grid container spacing={1}>
                        {healthIssueData.map((issue) => (
                          <Grid key={issue.id} item xs={3.5}>
                            <Box component="div" className="flex__">
                              <CustomCheckBox
                                value={issue.checked}
                                style={{ padding: 0, paddingRight: '8px' }}
                                onCheckBoxClick={(e) =>
                                  handleHealthIssueCheckBoxClick(issue.id, e)
                                }
                              />
                              <MediumTypography
                                label={issue.defaultDataLabelId}
                                fontweight={400}
                                textColor="#2A4241"
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>

                  {healthIssueData[0].checked && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md"
                    >
                      {' '}
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.seizureDisorderExplain}
                          placeholder="Seizure Disorder Explain"
                          name="seizureDisorderExplain"
                          maxLength={200}
                          handlechange={(e) => {
                            setFieldValue('seizureDisorderExplain', e);
                          }}
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.seizureDisorderExplain &&
                          touched.seizureDisorderExplain && (
                            <MediumTypography
                              labelid={errors.seizureDisorderExplain}
                              defaultlabel="Explaination is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  {healthIssueData[7].checked && (
                    <>
                      <Box
                        display="flex"
                        flexDirection="row"
                        className="mt-md mb-md"
                      >
                        <MediumTypography
                          className="mr-lg"
                          labelid="IntakeCurrentHealth.bloodLevel"
                          defaultlabel="Measured 5 Micrograms/dl ?"
                        />
                        <CustomRadioButton
                          data={EICSRadioData}
                          value={values.bloodLevel}
                          onSelectItem={(value) => {
                            if (value) {
                              if (value === 'No') {
                                setFieldValue('bloodLevelDesc', '');
                              }
                              setFieldValue('bloodLevel', value);
                            }
                          }}
                        />
                        {errors.bloodLevel && touched.bloodLevel && (
                          <MediumTypography
                            labelid={errors.bloodLevel}
                            defaultlabel="Blood Level is Required"
                            className="errorText-md"
                          />
                        )}
                      </Box>
                      {values.bloodLevel === 'Yes' && (
                        <Grid
                          container
                          direction={'row'}
                          columnSpacing={'40px'}
                        >
                          {' '}
                          <Grid item xs={9} lg={9}>
                            <CommentTextbox
                              Value={values.bloodLevelDesc}
                              placeholder="Explain"
                              name="bloodLevelDesc"
                              maxLength={200}
                              handlechange={(e) => {
                                setFieldValue('bloodLevelDesc', e);
                              }}
                              placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                            />
                            {errors.bloodLevelDesc &&
                              touched.bloodLevelDesc && (
                                <MediumTypography
                                  labelid={errors.bloodLevelDesc}
                                  defaultlabel="Explaination is Required"
                                  className="errorText-md"
                                />
                              )}
                          </Grid>
                        </Grid>
                      )}
                    </>
                  )}

                  {healthIssueData[8].checked && (
                    <>
                      <Grid container direction={'row'} columnSpacing={'40px'}>
                        {' '}
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.heartDefectDesc}
                            placeholder="Heart Defect Description"
                            name="heartDefectDesc"
                            maxLength={200}
                            handlechange={(e) => {
                              setFieldValue('heartDefectDesc', e);
                            }}
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.heartDefectDesc &&
                            touched.heartDefectDesc && (
                              <MediumTypography
                                labelid={errors.heartDefectDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {healthIssueData[10].checked && (
                    <>
                      <Grid container direction={'row'} columnSpacing={'40px'}>
                        {' '}
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.allergiesDesc}
                            placeholder="Allergies Description"
                            name="allergiesDesc"
                            maxLength={200}
                            handlechange={(e) => {
                              setFieldValue('allergiesDesc', e);
                            }}
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.allergiesDesc && touched.allergiesDesc && (
                            <MediumTypography
                              labelid={errors.allergiesDesc}
                              defaultlabel="Description is Required"
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {healthIssueData[11].checked && (
                    <>
                      <Grid container direction={'row'} columnSpacing={'40px'}>
                        {' '}
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.foodIntolerancesDesc}
                            placeholder="Food Intolerances Description"
                            name="foodIntolerancesDesc"
                            maxLength={200}
                            handlechange={(e) => {
                              setFieldValue('foodIntolerancesDesc', e);
                            }}
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.foodIntolerancesDesc &&
                            touched.foodIntolerancesDesc && (
                              <MediumTypography
                                labelid={errors.foodIntolerancesDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {healthIssueData[16].checked && (
                    <>
                      <Grid container direction={'row'} columnSpacing={'40px'}>
                        {' '}
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.brokenBonesDesc}
                            placeholder="Broken Bones Description"
                            name="brokenBonesDesc"
                            maxLength={200}
                            handlechange={(e) => {
                              setFieldValue('brokenBonesDesc', e);
                            }}
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.brokenBonesDesc &&
                            touched.brokenBonesDesc && (
                              <MediumTypography
                                labelid={errors.brokenBonesDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {healthIssueData[healthIssueData.length - 1].checked && (
                    <>
                      <Grid container direction={'row'} columnSpacing={'40px'}>
                        {' '}
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.healthIssueOthers}
                            placeholder="Other Health Issue Description"
                            name="healthIssueOthers"
                            maxLength={200}
                            handlechange={(e) => {
                              setFieldValue('healthIssueOthers', e);
                            }}
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.healthIssueOthers &&
                            touched.healthIssueOthers && (
                              <MediumTypography
                                labelid={errors.healthIssueOthers}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  <Box className="mt-md">
                    <MediumTypography
                      className="mr-md"
                      label="Has your child had the following screening tests ?"
                    />
                    <Box component="div" className="ml-lg mt-md">
                      <Grid container spacing={1}>
                        {medicalSpecialtyData.map((medicalSpecialty) => (
                          <Grid key={medicalSpecialty.id} item xs={3.5}>
                            <Box
                              component="div"
                              className="flex__ align__items__center"
                            >
                              <CustomCheckBox
                                value={medicalSpecialty.checked}
                                style={{ padding: 0, paddingRight: '8px' }}
                                onCheckBoxClick={(e) =>
                                  handleMedicalSpecialtyCheckBoxClick(
                                    medicalSpecialty.id,
                                    e,
                                  )
                                }
                              />
                              <MediumTypography
                                label={medicalSpecialty.defaultDataLabelId}
                                fontweight={400}
                                textColor="#2A4241"
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md"
                  >
                    {' '}
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.medicalSpecialtyOther}
                        placeholder="Screening Notes"
                        name="medicalSpecialtyOther"
                        maxLength={200}
                        handlechange={(e) => {
                          setFieldValue('medicalSpecialtyOther', e);
                        }}
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.medicalSpecialtyOther &&
                        touched.medicalSpecialtyOther && (
                          <MediumTypography
                            labelid={errors.medicalSpecialtyOther}
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeCurrentHealth.supportSystems"
                      defaultlabel="Are any of the support systems being received by your child at this time ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.supportSystems}
                      onSelectItem={(value) => {
                        if (value) {
                          setFieldValue('supportSystems', value);
                        }
                      }}
                    />
                    {errors.supportSystems && touched.supportSystems && (
                      <MediumTypography
                        labelid={errors.supportSystems}
                        defaultlabel="Support Systems is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.supportSystems === 'Yes' && (
                    <Box component="div" className="ml-lg mt-md">
                      <Grid container spacing={1}>
                        {medicalProceduresData.map((medicalProcedure) => (
                          <Grid key={medicalProcedure.id} item xs={3.5}>
                            <Box
                              component="div"
                              className="flex__ align__items__center"
                            >
                              <CustomCheckBox
                                value={medicalProcedure.checked}
                                style={{ padding: 0, paddingRight: '8px' }}
                                onCheckBoxClick={(e) =>
                                  handleMedicalProceduresCheckBoxClick(
                                    medicalProcedure.id,
                                    e,
                                  )
                                }
                              />
                              <MediumTypography
                                label={medicalProcedure.defaultDataLabelId}
                                fontweight={400}
                                textColor="#2A4241"
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  )}

                  {medicalProceduresData[medicalProceduresData.length - 1]
                    .checked && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.medicalProcedureOther}
                          placeholder="Mention others"
                          name="medicalProcedureOther"
                          maxLength={200}
                          handlechange={(e) => {
                            setFieldValue('medicalProcedureOther', e);
                          }}
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.medicalProcedureOther &&
                          touched.medicalProcedureOther && (
                            <MediumTypography
                              labelid={errors.medicalProcedureOther}
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.medicationTitle"
                      defaultlabel="Medication"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-md"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.medicationExplain}
                        placeholder="Is your child taking any medication ?"
                        name="medicationExplain"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('medicationExplain', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.medicationExplain &&
                        touched.medicationExplain && (
                          <MediumTypography
                            labelid={errors.medicationExplain}
                            defaultlabel="Medication Explaination is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.communityServices"
                      defaultlabel="Community Services"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeCurrentHealth.therapies"
                      defaultlabel="Is your child receiving any private services or therapies ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.therapies}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'No') {
                            setFieldValue('theraphyDesc', '');
                          }
                          setFieldValue('therapies', value);
                        }
                      }}
                    />
                    {errors.therapies && touched.therapies && (
                      <MediumTypography
                        labelid={errors.therapies}
                        defaultlabel="Therapies is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.therapies === 'Yes' && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-md"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.theraphyDesc}
                          placeholder="Please Describe"
                          name="theraphyDesc"
                          maxLength={2000}
                          handlechange={(e) => {
                            setFieldValue('theraphyDesc', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.theraphyDesc && touched.theraphyDesc && (
                          <MediumTypography
                            labelid={errors.theraphyDesc}
                            defaultlabel="Theraphy Description  is Required"
                            className="errorText-md"
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.temperament"
                      defaultlabel="Temperament, Behavior, Developmental Milestones"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-sm"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.temperamentDesc}
                        placeholder="How would you describe your child as an infant ? (temperament, personality, responsiveness, fussy, quiet, colicky, sleepy, etc.)"
                        name="temperamentDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('temperamentDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.temperamentDesc && touched.temperamentDesc && (
                        <MediumTypography
                          labelid={errors.theraphyDesc}
                          defaultlabel="Description  is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-sm"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childDesc}
                        placeholder="How would you describe your child now? (personality, interactiveness, behavior or discipline concerns)"
                        name="childDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('childDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childDesc && touched.childDesc && (
                        <MediumTypography
                          labelid={errors.childDesc}
                          defaultlabel="Description is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.childHabits"
                      defaultlabel="Do you have any concerns about your child’s Eating/Sleep Habits"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-sm"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childEatingDesc}
                        placeholder="What is your child eating ?"
                        name="childEatingDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('childEatingDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childEatingDesc && touched.childEatingDesc && (
                        <MediumTypography
                          labelid={errors.childEatingDesc}
                          defaultlabel="Description is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>

                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childFeedDesc}
                        placeholder="How does your child feed him/herself ?"
                        name="childFeedDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('childFeedDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childFeedDesc && touched.childFeedDesc && (
                        <MediumTypography
                          labelid={errors.childFeedDesc}
                          defaultlabel="Description is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>

                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childGrowthDesc}
                        placeholder="Any concerns about nutrition or growth ?"
                        name="childGrowthDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('childGrowthDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childGrowthDesc && touched.childGrowthDesc && (
                        <MediumTypography
                          labelid={errors.childGrowthDesc}
                          defaultlabel="Description is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>

                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childSleepDesc}
                        placeholder="What is Current sleep schedule ?"
                        name="childSleepDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('childSleepDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childSleepDesc && touched.childSleepDesc && (
                        <MediumTypography
                          labelid={errors.childSleepDesc}
                          defaultlabel="Description is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.SensoryTitle"
                      defaultlabel="Sensory"
                      fontSize="14px"
                      fontweight={600}
                      textColor="#00C6B8"
                    />
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-sm"
                  >
                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.activitiesDesc}
                        placeholder="Activities dislikes/avoids ?"
                        name="activitiesDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('activitiesDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.activitiesDesc && touched.activitiesDesc && (
                        <MediumTypography
                          labelid={errors.activitiesDesc}
                          defaultlabel="Description is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>

                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childBehaviourDesc}
                        placeholder="Child’s Behavior/Temperament"
                        name="childBehaviourDesc"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('childBehaviourDesc', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childBehaviourDesc &&
                        touched.childBehaviourDesc && (
                          <MediumTypography
                            labelid={errors.childBehaviourDesc}
                            defaultlabel="Description is Required"
                            className="errorText-md"
                          />
                        )}
                    </Grid>

                    <Grid item xs={9} lg={9}>
                      <CommentTextbox
                        Value={values.childActivities}
                        placeholder="What activities does your child enjoy ? What keeps his/her attention for the longest time ?"
                        name="childActivities"
                        maxLength={2000}
                        handlechange={(e) => {
                          setFieldValue('childActivities', e);
                        }}
                        height="150px"
                        placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childActivities && touched.childActivities && (
                        <MediumTypography
                          labelid={errors.childActivities}
                          defaultlabel="Description is Required"
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeCurrentHealth.socialRelationship"
                      defaultlabel="Do you feel your child has difficulty with social relationships ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.socialRelationship}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'No') {
                            setFieldValue('socialRelationshipDesc', '');
                          }
                          setFieldValue('socialRelationship', value);
                        }
                      }}
                    />
                    {errors.socialRelationship &&
                      touched.socialRelationship && (
                        <MediumTypography
                          labelid={errors.socialRelationship}
                          defaultlabel="socialRelationship is Required"
                          className="errorText-md"
                        />
                      )}
                  </Box>

                  {values.socialRelationship === 'Yes' && (
                    <Grid
                      container
                      direction={'row'}
                      columnSpacing={'40px'}
                      className="mt-sm"
                    >
                      <Grid item xs={9} lg={9}>
                        <CommentTextbox
                          Value={values.socialRelationshipDesc}
                          placeholder="Please describe"
                          name="socialRelationshipDesc"
                          maxLength={2000}
                          handlechange={(e) => {
                            setFieldValue('socialRelationshipDesc', e);
                          }}
                          height="150px"
                          placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                        />
                        {errors.socialRelationshipDesc &&
                          touched.socialRelationshipDesc && (
                            <MediumTypography
                              labelid={errors.socialRelationshipDesc}
                              defaultlabel="Description is Required"
                              className="errorText-md"
                            />
                          )}
                      </Grid>
                    </Grid>
                  )}

                  <Box
                    component="div"
                    className="flex__ align__items__center mt-sm"
                  >
                    <MediumTypography
                      labelid="IntakeCurrentHealth.childActivityTitle"
                      defaultlabel="At what age did your child (In months)"
                    />
                  </Box>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-sm"
                  >
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childSmile"
                        labelid="IntakeCurrentHealth.childSmile"
                        defaultlabelid="Smile"
                        Value={values.childSmile}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childSmile',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childSmile && touched.childSmile && (
                        <MediumTypography
                          labelid={errors.childSmile}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childRollOver"
                        labelid="IntakeCurrentHealth.childRollOver"
                        defaultlabelid="Roll Over"
                        Value={values.childRollOver}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childRollOver',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childRollOver && touched.childRollOver && (
                        <MediumTypography
                          labelid={errors.childRollOver}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childCrawl"
                        labelid="IntakeCurrentHealth.childCrawl"
                        defaultlabelid="Crawl"
                        Value={values.childCrawl}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childCrawl',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childCrawl && touched.childCrawl && (
                        <MediumTypography
                          labelid={errors.childCrawl}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-sm"
                  >
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childWalkAlone"
                        labelid="IntakeCurrentHealth.childWalkAlone"
                        defaultlabelid="Walk Alone"
                        Value={values.childWalkAlone}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childWalkAlone',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childWalkAlone && touched.childWalkAlone && (
                        <MediumTypography
                          labelid={errors.childWalkAlone}
                          className="errorText-md"
                        />
                      )}
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childSitIndependently"
                        labelid="IntakeCurrentHealth.childSitIndependently"
                        defaultlabelid="Sit Independently"
                        Value={values.childSitIndependently}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childSitIndependently',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childSitIndependently &&
                        touched.childSitIndependently && (
                          <MediumTypography
                            labelid={errors.childSitIndependently}
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childImitateActions"
                        labelid="IntakeCurrentHealth.childImitateActions"
                        defaultlabelid="Imitate Actions"
                        Value={values.childImitateActions}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childImitateActions',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childImitateActions &&
                        touched.childImitateActions && (
                          <MediumTypography
                            labelid={errors.childImitateActions}
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={'row'}
                    columnSpacing={'40px'}
                    className="mt-sm"
                  >
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childImitateSounds"
                        labelid="IntakeCurrentHealth.childImitateSounds"
                        defaultlabelid="Imitate Sounds/Words"
                        Value={values.childImitateSounds}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childImitateSounds',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childImitateSounds &&
                        touched.childImitateSounds && (
                          <MediumTypography
                            labelid={errors.childImitateSounds}
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                    <Grid item xs={3} lg={3}>
                      <Textinput
                        name="childUseFirstWords"
                        labelid="IntakeCurrentHealth.childUseFirstWords"
                        defaultlabelid="Use First Words"
                        Value={values.childUseFirstWords}
                        inputProps={{ maxLength: 2 }}
                        handlechange={(text: string) => {
                          setFieldValue(
                            'childUseFirstWords',
                            text.replace(/[^0-9]/g, ''),
                          );
                        }}
                        sxProps={{
                          backgroundColor: 'white',
                        }}
                        placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                      />
                      {errors.childUseFirstWords &&
                        touched.childUseFirstWords && (
                          <MediumTypography
                            labelid={errors.childUseFirstWords}
                            className="errorText-md"
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Box display="flex" flexDirection="row" className="mt-sm">
                    <MediumTypography
                      className="mr-lg"
                      labelid="IntakeCurrentHealth.childMakeNoise"
                      defaultlabel="Did your child make a lot of sounds as a baby ?"
                    />
                    <CustomRadioButton
                      data={IntakeRadioData}
                      value={values.childMakeNoise}
                      onSelectItem={(value) => {
                        if (value) {
                          if (value === 'No') {
                            setFieldValue('childSoundsDesc', '');
                            setFieldValue('childNeedsDesc', '');
                            setFieldValue('childFollowsDesc', '');
                            setFieldValue('childTolerateDesc', '');
                            setFieldValue('childlookSmileDesc', '');
                            setFieldValue('childUnderstandDesc', '');
                          }
                          setFieldValue('childMakeNoise', value);
                        }
                      }}
                    />
                    {errors.childMakeNoise && touched.childMakeNoise && (
                      <MediumTypography
                        labelid={errors.childMakeNoise}
                        defaultlabel="Make Noise is Required"
                        className="errorText-md"
                      />
                    )}
                  </Box>

                  {values.childMakeNoise === 'Yes' && (
                    <>
                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-sm"
                      >
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.childSoundsDesc}
                            placeholder="What sounds does your child make ?"
                            name="childSoundsDesc"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('childSoundsDesc', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.childSoundsDesc &&
                            touched.childSoundsDesc && (
                              <MediumTypography
                                labelid={errors.childSoundsDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.childNeedsDesc}
                            placeholder="How does your child let you know what he/she needs ?"
                            name="childNeedsDesc"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('childNeedsDesc', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.childNeedsDesc && touched.childNeedsDesc && (
                            <MediumTypography
                              labelid={errors.childNeedsDesc}
                              defaultlabel="Description is Required"
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.childFollowsDesc}
                            placeholder="Can your child follow simple instructions ?"
                            name="childFollowsDesc"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('childFollowsDesc', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.childFollowsDesc &&
                            touched.childFollowsDesc && (
                              <MediumTypography
                                labelid={errors.childFollowsDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.childTolerateDesc}
                            placeholder="Did your baby tolerate being on his belly ?"
                            name="childTolerateDesc"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('childTolerateDesc', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.childTolerateDesc &&
                            touched.childTolerateDesc && (
                              <MediumTypography
                                labelid={errors.childTolerateDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.childlookSmileDesc}
                            placeholder="Does your child look and smile at you ?"
                            name="childlookSmileDesc"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('childlookSmileDesc', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.childlookSmileDesc &&
                            touched.childlookSmileDesc && (
                              <MediumTypography
                                labelid={errors.childlookSmileDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.childUnderstandDesc}
                            placeholder="Does your child seem to understand what you say ?"
                            name="childUnderstandDesc"
                            maxLength={2000}
                            handlechange={(e) => {
                              setFieldValue('childUnderstandDesc', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                          {errors.childUnderstandDesc &&
                            touched.childUnderstandDesc && (
                              <MediumTypography
                                labelid={errors.childUnderstandDesc}
                                defaultlabel="Description is Required"
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>

                <Box
                  component="div"
                  className="p-sm gray3 borderRadius4 mt-md"
                  sx={{
                    border: '1px solid #00C6B8',
                  }}
                >
                  <Box component="div" className="flex__justify__space-between">
                    <MediumTypography
                      labelid="Intake.additionalCommentsTitle"
                      defaultlabel="Additional Comments"
                      fontSize="16px"
                      fontweight={600}
                      textColor="#2A4241"
                    />

                    {!openAdditionalSection ? (
                      <DownArrow
                        className="cursorPointer"
                        style={{ color: '#008C82' }}
                        onClick={() => {
                          setOpenAdditionalSection(true);
                        }}
                      />
                    ) : (
                      <UpArrow
                        className="cursorPointer"
                        onClick={() => {
                          setOpenAdditionalSection(false);
                        }}
                      />
                    )}
                  </Box>

                  {openAdditionalSection && (
                    <Box component="div">
                      <Box className="horizontalLine" sx={{ marginY: '8px' }} />

                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-md pl-xs"
                      >
                        <Grid item xs={9} lg={9}>
                          <CommentTextbox
                            Value={values.comment}
                            placeholder="Comments"
                            name="comment"
                            maxLength={3000}
                            handlechange={(e) => {
                              setFieldValue('comment', e);
                            }}
                            height="150px"
                            placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>

                <Box
                  component="div"
                  className="p-sm gray3 borderRadius4 mt-md"
                  sx={{
                    border: '1px solid #00C6B8',
                  }}
                >
                  <Box component="div" className="flex__justify__space-between">
                    <MediumTypography
                      labelid="Intake.eicsTitle"
                      defaultlabel="EICS Additional Data"
                      fontSize="16px"
                      fontweight={600}
                      textColor="#2A4241"
                    />

                    {!openEICSSection ? (
                      <DownArrow
                        className="cursorPointer"
                        style={{ color: '#008C82' }}
                        onClick={() => {
                          setOpenEICSSection(true);
                        }}
                      />
                    ) : (
                      <UpArrow
                        className="cursorPointer"
                        onClick={() => {
                          setOpenEICSSection(false);
                        }}
                      />
                    )}
                  </Box>

                  {openEICSSection && (
                    <Box component="div">
                      <Box className="horizontalLine" sx={{ marginY: '8px' }} />

                      <Grid
                        container
                        direction={'row'}
                        columnSpacing={'40px'}
                        className="mt-md"
                      >
                        <Grid item xs={4.5} lg={4.5}>
                          <MediumTypography
                            labelid="IntakeCurrentHealth.childBloodLevel"
                            defaultlabel="Blood lead levels measured 5 micrograms/dl ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childBloodLevel}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('childBloodLevel', value);
                              }
                            }}
                          />
                          {errors.childBloodLevel &&
                            touched.childBloodLevel && (
                              <MediumTypography
                                labelid={errors.childBloodLevel}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={4.5} lg={4.5}>
                          <MediumTypography
                            labelid="IntakeCurrentHealth.childInsecureDifficulties"
                            defaultlabel="Difficulty with insecure attachment/interactional difficulties ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childInsecureDifficulties}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue(
                                  'childInsecureDifficulties',
                                  value,
                                );
                              }
                            }}
                          />
                          {errors.childInsecureDifficulties &&
                            touched.childInsecureDifficulties && (
                              <MediumTypography
                                labelid={errors.childInsecureDifficulties}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeCurrentHealth.childNervousSystem"
                            defaultlabel="Suspected central nervous system abnormality ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childNervousSystem}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('childNervousSystem', value);
                              }
                            }}
                          />
                          {errors.childNervousSystem &&
                            touched.childNervousSystem && (
                              <MediumTypography
                                labelid={errors.childNervousSystem}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={4.5} lg={4.5} className="mt-md">
                          <MediumTypography
                            labelid="IntakeCurrentHealth.childMultipleTrauma"
                            defaultlabel="Multiple trauma/losses experienced by child ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childMultipleTrauma}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('childMultipleTrauma', value);
                              }
                            }}
                          />
                          {errors.childMultipleTrauma &&
                            touched.childMultipleTrauma && (
                              <MediumTypography
                                labelid={errors.childMultipleTrauma}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={12} lg={12} className="mt-md">
                          <MediumTypography
                            labelid="IntakePregnancy.childHospitalized"
                            defaultlabel="Was the child hospitalized for more than 25 days during a 6-month period, excluding the initial birth admission to a NICU ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childHospitalized}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('childHospitalized', value);
                              }
                            }}
                          />
                          {errors.childHospitalized &&
                            touched.childHospitalized && (
                              <MediumTypography
                                labelid={errors.childHospitalized}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                        <Grid item xs={12} lg={12} className="mt-md">
                          <MediumTypography
                            labelid="IntakeCurrentHealth.childDiagnosedAtBirth"
                            defaultlabel="Was child diagnosed at birth with Intrauterine Growth Restriction(IUGR) or Small for the Gestational Age (SGA) ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childDiagnosedAtBirth}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('childDiagnosedAtBirth', value);
                              }
                            }}
                          />
                          {errors.childDiagnosedAtBirth &&
                            touched.childDiagnosedAtBirth && (
                              <MediumTypography
                                labelid={errors.childDiagnosedAtBirth}
                                className="errorText-md"
                              />
                            )}
                        </Grid>

                        <Grid item xs={12} lg={12} className="mt-md">
                          <MediumTypography
                            labelid="IntakeCurrentHealth.smallSize"
                            defaultlabel="Is child's weight, weight for height, or height for age less than the 5th percentile (Small size) ?"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.smallSize}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('smallSize', value);
                              }
                            }}
                          />
                          {errors.smallSize && touched.smallSize && (
                            <MediumTypography
                              labelid={errors.smallSize}
                              className="errorText-md"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} lg={12} className="mt-md">
                          <MediumTypography
                            labelid="IntakeCurrentHealth.childChronicFeeding"
                            defaultlabel="Chronic feeding difficulties ? *"
                          />
                          <CustomRadioButton
                            data={EICSRadioData}
                            value={values.childChronicFeeding}
                            onSelectItem={(value) => {
                              if (value) {
                                setFieldValue('childChronicFeeding', value);
                              }
                            }}
                          />
                          {errors.childChronicFeeding &&
                            touched.childChronicFeeding && (
                              <MediumTypography
                                labelid={errors.childChronicFeeding}
                                className="errorText-md"
                              />
                            )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
                <ModalPopup
                  description="formUnsavedChangesMessage"
                  open={openModal}
                  onCancel={() => {
                    setOpenModal(false);
                  }}
                  onOk={() => {
                    formikRef.current?.resetForm();
                    setIntakeCurrentHealthInfo({
                      ...intakeCurrentHealthInfo,
                    });

                    setHospitalView(
                      intakeCurrentHealthSavedValues?.medication.hospitals ||
                        [],
                    );
                    getCheckBoxSelectedNamesGenericFunc(
                      'medicalSpecialtyNames',
                      intakeCurrentHealthSavedValues?.medication
                        .medicalSpecialtyNames || [],
                    );
                    getCheckBoxSelectedNamesGenericFunc(
                      'medicalProcedureNames',
                      intakeCurrentHealthSavedValues?.medication
                        .medicalProcedureNames || [],
                    );
                    getCheckBoxSelectedNamesGenericFunc(
                      'healthIssueNames',
                      intakeCurrentHealthSavedValues?.healthIssue
                        .healthIssueNames || [],
                    );
                    setOpenModal(false);
                  }}
                  labelId1="Clientpage.Nobtn"
                  negativeActionLabel="No"
                  labelId2="Clientpage.Yesbtn"
                  positiveActionLabel="Yes"
                />
              </Box>
            )}
          </Formik>
        </Card>

        <Box
          className="mt-md mb-xlg"
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <ButtonComponent
            className="btn-primary btn-cancel alrt_Width_btn mr-md"
            variantType="contained"
            labelId={'cancelText'}
            defaultLabelId={'Cancel'}
            onClick={() => {
              if (
                JSON.stringify(intakeCurrentHealthInfo) !==
                JSON.stringify(formikRef.current?.values)
              ) {
                setOpenModal(true);
              }
            }}
          />

          <ButtonComponent
            variantType="contained"
            type="submit"
            className="btn-primary btn-submit alrt_Width_btn"
            labelId={'Intake.submit'}
            defaultLabelId={'Save & Next'}
            onClick={() => {
              formikRef.current?.handleSubmit();
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default IntakeCurrentHealthInfo;
