import { Box, TableCell, TableRow } from '@mui/material';
import { FC, useContext } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';

import { ReactComponent as EditIcon } from '../../assets/images/highlight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';

import { IfspTeamMembersType } from './ifspContextApi/IfspContex';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import { getRoleName } from '../../utils/nameUtils';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

interface IfspTable {
  data: IfspTeamMembersType;
  onDelete?: (id: string) => void;
  onEdit?: (data: IfspTeamMembersType) => void;
  dateRequired?: boolean;
  isIfspScreenLocked?: boolean;
}

const IfspTeamMembersTableRow: FC<IfspTable> = ({
  data,
  onDelete,
  onEdit,
  dateRequired,
  isIfspScreenLocked,
}) => {
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const handleDeleteClick = () => {
    if (onDelete && !isIfspScreenLocked) {
      onDelete(data.id);
    }
  };
  const handleEdit = () => {
    if (onEdit && !isIfspScreenLocked) {
      onEdit(data);
    }
  };

  const getGuardianType = (gaurdianType: string): string => {
    return (
      lookups?.guardianType?.find(
        (g) => g.code.toUpperCase() === gaurdianType.toUpperCase(),
      )?.description || '-'
    );
  };

  const getDateLabel = (date: string | null | undefined): string => {
    if (date === '' || date === null) {
      return '-';
    } else if (date !== undefined && date !== null && date !== '') {
      return date;
    } else {
      return 'Not Applicable';
    }
  };
  return (
    <TableRow
      className="tableRowcss"
      sx={{
        cursor: 'pointer',
        alignContent: 'flex-start',
        '&:nth-of-type(odd)': {
          backgroundColor: '#ECF9F8',
        },
      }}
    >
      <TableCell>
        <TooltipTableCell
          value={data.name ? data.name : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            data.discipline
              ? lookups?.discipline.find((d) => d.code === data.discipline)
                  ?.description || '-'
              : '-'
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        {(data.startDate !== undefined &&
          data.startDate !== '' &&
          data.startDate !== null) ||
        dateRequired ? (
          <TooltipTableCell
            value={data.role ? getRoleName(data.role) : '-'}
            visible={true}
            tooltipSxProps={{
              borderBottom: '0px',
              padding: '0px',
            }}
          />
        ) : (
          <TooltipTableCell
            value={data.role ? getGuardianType(data.role) : '-'}
            visible={true}
            tooltipSxProps={{
              borderBottom: '0px',
              padding: '0px',
            }}
          />
        )}
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.email ? data.email : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={data.phone ? data.phone : '-'}
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
        />
      </TableCell>
      {!dateRequired && (
        <TableCell>
          <TooltipTableCell
            value={data.jobDescription ? data.jobDescription : '-'}
            visible={true}
            tooltipSxProps={{
              borderBottom: '0px',
              padding: '0px',
            }}
          />
        </TableCell>
      )}
      {!dateRequired && (
        <TableCell>
          <MediumTypography label={getDateLabel(data.startDate as string)} />
        </TableCell>
      )}
      {!dateRequired && (
        <TableCell>
          <MediumTypography label={getDateLabel(data.endDate as string)} />
        </TableCell>
      )}
      <TableCell>
        <Box component="div" className="flex__">
          {data.startDate !== undefined &&
            data.startDate !== '' &&
            data.startDate !== null && (
              <Box
                component="div"
                className="pr-sm pt-xs"
                sx={{
                  cursor: !isIfspScreenLocked ? 'pointer' : 'not-allowed',
                }}
              >
                <ActionToolTip labelId="BlockTime.edit" defaultLabel="Edit">
                  <EditIcon onClick={handleEdit} />
                </ActionToolTip>
              </Box>
            )}
          {dateRequired && data.role !== 'parent' && (
            <Box
              component="div"
              className="pr-sm pt-xs"
              sx={{
                cursor: !isIfspScreenLocked ? 'pointer' : 'not-allowed',
              }}
            >
              <ActionToolTip labelId="BlockTime.edit" defaultLabel="Edit">
                <EditIcon onClick={handleEdit} />
              </ActionToolTip>
            </Box>
          )}
          <Box
            component="div"
            className="pt-xs"
            sx={{
              opacity: 1,
              cursor: !isIfspScreenLocked ? 'pointer' : 'not-allowed',
              pointerEvents: 'auto',
            }}
          >
            <ActionToolTip labelId="BlockTime.delete" defaultLabel="Delete">
              <DeleteIcon onClick={handleDeleteClick} />
            </ActionToolTip>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default IfspTeamMembersTableRow;
