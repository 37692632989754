import React, { useCallback, useEffect } from 'react';
import {
  Box,
  Card,
  Grid,
  AlertColor,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import CustomBackArrow from '../../../components/imagepickers/backArrow';
import Textinput from '../../../components/formlib/Textinput';
import { Formik, FormikErrors, FormikProps } from 'formik';
import * as yup from 'yup';
import ButtonComponent from '../../../components/formlib/ButtonComponent';
import SnackBarComponent from '../../../components/formlib/SnackBarComponent';
import { useLocation, useNavigate } from 'react-router';
import { CODE_LIST } from '../../../routes/Routing';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import './ServiceCodesList.css';
import SmallTypography from '../../../components/formlib/SmallTypography';
import { cloneDeep, debounce } from 'lodash';
import MediumTypography from '../../../components/formlib/MediumTypography';
import { ReactComponent as DeleteIcon } from '../../../assets/images/deleteAlertIcon.svg';
import ModalPopup from '../../../components/formlib/ModalPopup';
import { ApiError, isCustomError } from '../../../services/ApiResponseHandler';
import _ from 'lodash';
import {
  DiagnosisCode,
  DiagnosisData,
  addDiagnosticCodesNew,
  editDiagnosticCodesNew,
} from '../../../services/configApi/codelist/diagnosticCodes';

const validationSchema = yup.object().shape({
  diagnosisCode: yup.array().of(
    yup.object().shape({
      code: yup.string().required('Required'),
      description: yup.string().required('Required'),
      oneYearEligibility: yup.boolean(),
    }),
  ),
});

const AddDiagnosticCodes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dataView, setDataView] = React.useState<DiagnosisData>(
    location.state?.categoryDetails !== undefined
      ? {
          diagnosisCode: [location.state?.categoryDetails],
        }
      : {
          diagnosisCode: [
            {
              codeId: 1,
              code: '',
              description: '',
              oneYearEligibility: false,
            },
          ],
        },
  );
  const formRef = React.useRef<FormikProps<DiagnosisData>>(null);
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    if (location.state?.categoryDetails !== undefined) {
      toggleLoader(true);

      setTimeout(() => {
        toggleLoader(false);
      }, 1000);
      const obj = {
        diagnosisCode: [location.state?.categoryDetails],
      };
      formRef.current?.setValues(cloneDeep(obj));
      setDataView(cloneDeep(obj));
    } else {
      formRef.current?.setValues({
        diagnosisCode: [
          {
            codeId: 1,
            code: '',
            description: '',
            oneYearEligibility: false,
          },
        ],
      });
      setDataView({
        diagnosisCode: [
          {
            codeId: 1,
            code: '',
            description: '',
            oneYearEligibility: false,
          },
        ],
      });
    }
  }, [location.state?.categoryDetails]);

  const handleAddLink = () => {
    const updatedDataView = { ...dataView };
    const newCode = {
      codeId: updatedDataView.diagnosisCode.length + 1,
      code: '',
      description: '',
      oneYearEligibility: false,
    };

    updatedDataView.diagnosisCode.push(newCode);

    setDataView(updatedDataView);

    formRef.current?.setValues(updatedDataView);
  };

  const handleCodeChange = (index: number, value: string) => {
    const codeChangeValue = { ...dataView };
    codeChangeValue.diagnosisCode[index].code = value;
    setDataView(codeChangeValue);
  };

  const handleDescriptionChange = (index: number, value: string) => {
    const codeChangeValue = { ...dataView };
    codeChangeValue.diagnosisCode[index].description = value;
    setDataView(codeChangeValue);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleSubmit = async () => {
    formRef.current?.submitForm();
    formRef.current?.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (formRef.current) addOrEditCategory(formRef.current?.values);
      } else {
        formRef.current?.setValues(dataView);
        const dataViewObj = dataView;
        setDataView(dataViewObj);
      }
    });
  };

  const addOrEditCategory = useCallback(
    debounce((obj: DiagnosisData) => {
      if (
        location.state?.categoryDetails !== undefined &&
        obj.diagnosisCode[0].id
      ) {
        toggleLoader(true);
        editDiagnosticCodesNew(obj.diagnosisCode[0], obj.diagnosisCode[0].id)
          .then(() => {
            toggleLoader(false);
            setTimeout(() => {
              navigate(CODE_LIST, {
                state: {
                  tabPropValue: 0,
                },
              });
            }, 800);
            setOpen(true);
            setToastrVariable('success');
            setToastrDefaultMessage('Category Updated Successfully!');
            setToastrId('diagnosticCodelist.updateCategorySuccessMsg');
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('failedApiMsg');
              setToastrDefaultMessage(
                'Oops, something went wrong. Please try again later.',
              );
            }
          });
      } else {
        toggleLoader(true);
        addDiagnosticCodesNew(obj)
          .then((response) => {
            toggleLoader(false);
            setTimeout(() => {
              if (response.duplicateCode && response.duplicateCode.length > 0) {
                const combinedArray: DiagnosisCode[] = [];
                response.addedCode.forEach((code) => {
                  code.isAddedCode = true;
                  code.isDuplicateCode = false;
                  combinedArray.push(code);
                });
                response.duplicateCode.forEach((code) => {
                  code.isDuplicateCode = true;
                  code.isAddedCode = false;
                  combinedArray.push(code);
                });
                const combinedObj = {
                  diagnosisCode: combinedArray,
                };
                formRef.current?.setValues(combinedObj);
                setDataView(combinedObj);
              } else {
                navigate(CODE_LIST, {
                  state: {
                    tabPropValue: 0,
                  },
                });
              }
            }, 800);
            setOpen(true);
            setToastrVariable('success');
            setToastrDefaultMessage('Category Added Successfully!');
            setToastrId('diagnosticCodelist.addCategorySuccessMsg');
          })
          .catch((error) => {
            toggleLoader(false);
            setOpen(true);
            setToastrVariable('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId('diagnosticCodelist.addCategoryErrorMsg');
              setToastrDefaultMessage('Failed to Add Category!');
            }
          });
      }
    }, 500),
    [],
  );

  const clearForm = () => {
    setOpenModal(false);
    formRef.current?.resetForm();
    setDataView({
      diagnosisCode: [
        {
          codeId: 1,
          code: '',
          description: '',
          oneYearEligibility: false,
        },
      ],
    });
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <ModalPopup
        open={openModal}
        description="formUnsavedChangesMessage"
        onCancel={() => setOpenModal(false)}
        onOk={() => {
          clearForm();
        }}
        labelId1="Clientpage.cancelbtn"
        negativeActionLabel="Cancel"
        labelId2="Clientpage.Okbtn"
        positiveActionLabel="Ok"
      />
      <Box component="main">
        <Box component="section">
          <Box className="rowContainer">
            <CustomBackArrow
              onClick={() =>
                navigate(CODE_LIST, {
                  state: {
                    tabPropValue: 0,
                  },
                })
              }
            />
            <Box component="div" className="ml-md">
              {location.state?.categoryDetails === undefined && (
                <MediumTypography
                  labelid="codeList.addDiagnosisCodeNameBtn"
                  defaultlabel="Add Diagnosis Code"
                  className="mainText-xxlg"
                />
              )}
              {location.state?.categoryDetails !== undefined && (
                <MediumTypography
                  labelid="codeList.editDiagnosisCodeNameBtn"
                  defaultlabel="Edit Diagnosis Code"
                  className="mainText-xxlg"
                />
              )}
            </Box>
          </Box>
          <Formik
            innerRef={formRef}
            initialValues={{ diagnosisCode: dataView.diagnosisCode }}
            validationSchema={validationSchema}
            onSubmit={() => {}}
          >
            {({ setFieldValue, values, initialValues, errors, touched }) => (
              <>
                <Card sx={{ padding: '24px', my: '0px', ml: '0px' }}>
                  <Box component="div">
                    <Card
                      sx={{
                        display: 'flex',
                        border: '1px solid rgba(0, 198, 184, 1)',
                        position: 'relative',
                        overflow: 'visible',
                        background: 'rgba(236, 249, 248, 1)',
                        my: '2%',
                      }}
                    >
                      <Box
                        component="div"
                        sx={{
                          display: 'flex',
                          position: 'absolute',
                          top: -25,
                          left: '50%',
                          transform: 'translateX(-50%)',
                          padding: '5px',
                        }}
                      ></Box>
                      <Grid
                        container
                        rowSpacing={'24px'}
                        columnSpacing={'40px'}
                        className="formCardview"
                        sx={{ my: '2px' }}
                      >
                        {dataView.diagnosisCode.map(
                          (items: DiagnosisCode, i: number) => (
                            <Grid
                              item
                              container
                              rowSpacing={'24px'}
                              columnSpacing={'40px'}
                              sx={{ display: 'flex' }}
                              key={items.codeId}
                            >
                              <Grid item xs={3}>
                                <Textinput
                                  name={`code${i}`}
                                  labelid="diagnosticCodelist.enterCode"
                                  defaultlabelid="Enter Code"
                                  sxProps={{
                                    background: 'rgba(255, 255, 255, 1)',
                                  }}
                                  inputProps={{
                                    maxLength: 10,
                                  }}
                                  Value={values.diagnosisCode[i]?.code}
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      `diagnosisCode[${i}].code`,
                                      value,
                                    );
                                    handleCodeChange(i, value);
                                  }}
                                />
                                {errors?.diagnosisCode &&
                                  errors?.diagnosisCode[i] &&
                                  (errors.diagnosisCode[
                                    i
                                  ] as FormikErrors<DiagnosisData>) &&
                                  (
                                    errors.diagnosisCode[
                                      i
                                    ] as FormikErrors<DiagnosisCode>
                                  ).code &&
                                  touched?.diagnosisCode &&
                                  touched?.diagnosisCode[i] &&
                                  touched.diagnosisCode[i].code && (
                                    <SmallTypography
                                      sxProps={{ color: 'red' }}
                                      labelId={
                                        (
                                          errors.diagnosisCode[
                                            i
                                          ] as FormikErrors<DiagnosisCode>
                                        ).code
                                      }
                                      defaultLabelId="Code is Required"
                                    />
                                  )}
                                {values.diagnosisCode[i]?.isAddedCode && (
                                  <SmallTypography
                                    sxProps={{ color: 'green' }}
                                    labelId="addedCodeTextMesg"
                                    defaultLabelId="Code Added"
                                  />
                                )}
                                {values.diagnosisCode[i]?.isDuplicateCode && (
                                  <SmallTypography
                                    sxProps={{ color: 'red' }}
                                    labelId="duplicateCodeTextMesg"
                                    defaultLabelId="Duplicated Code"
                                  />
                                )}

                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name={`oneYearEligibility-${i}`}
                                      checked={
                                        values.diagnosisCode[i]
                                          ?.oneYearEligibility == true
                                          ? true
                                          : false
                                      }
                                      onChange={(e) => {
                                        setFieldValue(
                                          `diagnosisCode[${i}].oneYearEligibility`,
                                          e.target.checked,
                                        );
                                      }}
                                      style={{
                                        color: values.diagnosisCode[i]
                                          .oneYearEligibility
                                          ? '#00938e'
                                          : '#97A6A5',
                                      }}
                                    />
                                  }
                                  label={
                                    <span
                                      style={{
                                        fontFamily: 'Lato-Regular',
                                        fontStyle: 'normal',
                                        fontWeight: 400,
                                        fontSize: '14px',
                                      }}
                                    >
                                      1 Year Diagnosis
                                    </span>
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Textinput
                                  name={`description${i}`}
                                  labelid="diagnosticCodelist.enterDesc"
                                  defaultlabelid="Enter Description"
                                  sxProps={{
                                    background: 'rgba(255, 255, 255, 1)',
                                  }}
                                  inputProps={{
                                    maxLength: 100,
                                  }}
                                  Value={values?.diagnosisCode[i]?.description}
                                  handlechange={(value: string) => {
                                    setFieldValue(
                                      `diagnosisCode[${i}].description`,
                                      value,
                                    );
                                    handleDescriptionChange(i, value);
                                  }}
                                />
                                {errors.diagnosisCode &&
                                  errors.diagnosisCode[i] &&
                                  (errors.diagnosisCode[
                                    i
                                  ] as FormikErrors<DiagnosisData>) &&
                                  (
                                    errors.diagnosisCode[
                                      i
                                    ] as FormikErrors<DiagnosisCode>
                                  ).description &&
                                  touched.diagnosisCode &&
                                  touched.diagnosisCode[i] &&
                                  touched.diagnosisCode[i].description && (
                                    <SmallTypography
                                      sxProps={{ color: 'red' }}
                                      labelId={
                                        (
                                          errors.diagnosisCode[
                                            i
                                          ] as FormikErrors<DiagnosisCode>
                                        ).description
                                      }
                                      defaultLabelId="Description is Required"
                                    />
                                  )}
                                {values.diagnosisCode[i]?.isAddedCode && (
                                  <SmallTypography
                                    sxProps={{ color: 'green' }}
                                    labelId="addedDescTextMesg"
                                    defaultLabelId="Description Added"
                                  />
                                )}
                                {values.diagnosisCode[i]?.isDuplicateCode && (
                                  <SmallTypography
                                    sxProps={{ color: 'red' }}
                                    labelId="duplicateDescTextMesg"
                                    defaultLabelId="Duplicate Description"
                                  />
                                )}
                              </Grid>
                              <Grid item xs={0.5}>
                                {i >= 1 && (
                                  <DeleteIcon
                                    style={{
                                      position: 'relative',
                                      cursor: 'pointer',
                                      width: '22px',
                                      height: '22px',
                                      top: '2px',
                                      right: '30px',
                                    }}
                                    onClick={() => {
                                      const updatedSubCategories = [
                                        ...dataView.diagnosisCode,
                                      ];
                                      updatedSubCategories.splice(i, 1);
                                      formRef.current?.setValues({
                                        ...dataView,
                                        diagnosisCode: updatedSubCategories,
                                      });
                                      setDataView({
                                        ...dataView,
                                        diagnosisCode: updatedSubCategories,
                                      });
                                    }}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          ),
                        )}
                        {location.state?.categoryDetails === undefined && (
                          <Grid item xs={4.5}>
                            <ButtonComponent
                              className="btn-add-code"
                              variantType="contained"
                              type="submit"
                              labelId="diagnosticCodelist.addCode"
                              defaultLabelId="+ Add Code"
                              onClick={handleAddLink}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Card>
                  </Box>
                </Card>
                <Box sx={{ marginTop: '30px', marginBottom: '30px' }}>
                  <Grid
                    container
                    direction="row"
                    alignItems="right"
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Grid item sx={{ marginRight: '16px' }}>
                      <ButtonComponent
                        className="btn-primary btn-cancel"
                        variantType="contained"
                        defaultLabelId="Cancel"
                        labelId="Contacts.cancelbtn"
                        onClick={() => {
                          if (
                            !_.isEqual(
                              initialValues.diagnosisCode,
                              values.diagnosisCode,
                            )
                          ) {
                            setOpenModal(true);
                          } else {
                            navigate(CODE_LIST, {
                              state: {
                                tabPropValue: 0,
                              },
                            });
                          }
                        }}
                      />
                    </Grid>
                    <Grid item>
                      {location.state?.categoryDetails === undefined && (
                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="BlockTime.addButton"
                          defaultLabelId="Add"
                          onClick={handleSubmit}
                        />
                      )}
                      {location.state?.categoryDetails !== undefined && (
                        <ButtonComponent
                          className="btn-primary btn-submit"
                          variantType="contained"
                          type="submit"
                          labelId="clientpage.Update"
                          defaultLabelId="Update"
                          onClick={handleSubmit}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </>
            )}
          </Formik>
        </Box>
      </Box>
    </>
  );
};

export default AddDiagnosticCodes;
