import { HeadCell } from '../../components/formlib/TableHeader';
import { HolidayHeader } from './HolidayListDialog';

export const getHolidayHeaderData = () => {
  const holidayHeaderData: HeadCell<HolidayHeader>[] = [
    {
      id: 'month',
      labelId: 'dayGridMonth',
      defaultLabelId: 'Month',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'date',
      labelId: 'dateText',
      defaultLabelId: 'Date',
      numeric: false,
      requiredSorting: false,
    },
    {
      id: 'holidayName',
      labelId: 'holidayText',
      defaultLabelId: 'Holiday',
      numeric: false,
      requiredSorting: false,
    },
  ];
  return holidayHeaderData;
};
