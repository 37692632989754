import React, { useRef, FC, useCallback, useEffect } from 'react';
import {
  Box,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  AlertColor,
} from '@mui/material';
import TitleText from '../TitleText';
import {
  OrganizationListType,
  addOrganizationApi,
  editOrganizationApi,
} from '../../../services/configApi/sspContactList/sspContact';
import * as yup from 'yup';
import { Formik, FormikProps } from 'formik';
import Textinput from '../Textinput';
import { nameValidation } from '../../../utils/formValidationUtil';
import MediumTypography from '../MediumTypography';
import DropdownComponent from '../DropdownComponent';
import AddressForm from '../AddressForm';
import { OptionType } from '../../../utils/type';
import DatePickerComponent from '../DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import ButtonComponent from '../ButtonComponent';
import _, { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import ModalPopup from '../ModalPopup';
import { datePickerMinDateMaxDateValidate } from '../../../utils/dateUtil';

interface ModalProps {
  open: boolean;
  handleClose: () => void;
  onSuccess: (successerror: AlertColor, id: string, message: string) => void;
  organizationVals?: OrganizationListType;
}

export const TreatmentTypes = [
  {
    id: 'autism',
    label: 'Autism',
  },
  {
    id: 'vision',
    label: 'Vision',
  },
  {
    id: 'hearing',
    label: 'Hearing',
  },
  {
    id: 'other',
    label: 'Other',
  },
];

export const orgInitialValues: OrganizationListType = {
  id: '',
  organizationName: '',
  treatmentType: '',
  treatmentName: '',
  areaServed: '',
  address1: '',
  address2: '',
  state: '',
  city: '',
  zipCode: { id: '', label: '' },
  startDate: dayjs(new Date()),
  endDate: null,
  isActive: false,
};

const AddOrganization: FC<ModalProps> = ({
  open,
  handleClose,
  onSuccess,
  organizationVals,
}) => {
  const formikRef = useRef<FormikProps<OrganizationListType>>(null);
  const [vals, setVals] =
    React.useState<OrganizationListType>(orgInitialValues);
  const [openModal, setOpenModal] = React.useState(false);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const validationSchema = yup.object().shape({
    organizationName: yup.string().required('ssp.organizationNameRequiredText'),
    treatmentType: yup.string().required('ssp.treatmentTypeRequiredText'),
    treatmentName: yup.string().when(['treatmentType'], {
      is: () => formikRef.current?.values.treatmentType === 'other',
      then: yup.string().required('treatmentNameRequiredText'),
      otherwise: yup.string().nullable(),
    }),
    areaServed: yup.string().nullable(),
    address1: yup.string().required('addressLine1RequiredMessage'),
    address2: yup.string().nullable(),
    zipCode: yup.object().shape({
      label: yup.string().required('zipCodeRequiredMessage'),
    }),
    city: yup.string().required('cityRequiredMessage'),
    state: yup.string().required('stateRequiredMessage'),
    startDate: yup.date().required('startDateRequiredMessage').nullable(),
    endDate: yup.date().nullable(),
  });

  const validateForm = (value: OrganizationListType) => {
    const errors: Partial<OrganizationListType> = {};
    if (value.endDate) {
      if (dayjs(value.endDate).isBefore(dayjs(value.startDate))) {
        errors.endDate = 'endDateGreaterThanStartDate';
      }
      if (datePickerMinDateMaxDateValidate(value.endDate)) {
        errors.endDate = 'datePickerMinDateMaxDateValidate';
      }
    }
    if (value.startDate) {
      if (datePickerMinDateMaxDateValidate(value.startDate)) {
        errors.startDate = 'datePickerMinDateMaxDateValidate';
      }
    }
    return errors;
  };

  const formHandler = (formValues: OrganizationListType) => {
    toggleLoader(true);
    addOrganizationApiCall(formValues);
  };

  const addOrganizationApiCall = useCallback(
    debounce((formVals: OrganizationListType) => {
      formVals.startDate = dayjs(formVals.startDate).format('MM/DD/YYYY');
      formVals.zipCode = (formVals.zipCode as OptionType).label;
      addOrganizationApi(formVals)
        .then(async (response) => {
          if (response) {
            toggleLoader(false);
            onSuccess(
              'success',
              'organization.addMessage',
              'Organization Added Successfully',
            );
          }
        })
        .catch(async () => {
          toggleLoader(false);

          onSuccess(
            'error',
            'failedApiMsg',
            'Oops, something went wrong. Please try again later.',
          );
        });
    }, 1000),
    [],
  );

  const updateHandler = (updateHandlerVals: OrganizationListType) => {
    toggleLoader(true);
    updateOrganizationApiCall(updateHandlerVals);
  };

  const updateOrganizationApiCall = useCallback(
    debounce((updateVals: OrganizationListType) => {
      updateVals.startDate = dayjs(updateVals.startDate).format('MM/DD/YYYY');
      updateVals.zipCode = (updateVals.zipCode as OptionType).label;
      const updateId = updateVals.id ? updateVals.id : '';
      editOrganizationApi(updateId, updateVals)
        .then(async (response) => {
          if (response) {
            toggleLoader(false);
            onSuccess(
              'success',
              'organization.updateMessage',
              'Organization Updated Successfully',
            );
          }
        })
        .catch(async () => {
          toggleLoader(false);

          onSuccess(
            'error',
            'failedApiMsg',
            'Oops, something went wrong. Please try again later.',
          );
        });
    }, 1000),
    [],
  );
  useEffect(() => {
    if (organizationVals?.id) {
      formikRef.current?.setValues(organizationVals);
      if (typeof organizationVals.zipCode === 'string') {
        organizationVals.zipCode = {
          id: 'aa',
          label: organizationVals.zipCode,
        } as OptionType;
      }
      setVals(organizationVals);
    } else {
      formikRef.current?.setValues(orgInitialValues);
      setVals(orgInitialValues);
    }
  }, [organizationVals]);

  return (
    <Box component="main">
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
        <DialogTitle>
          <Box className="rowContainer pb-none" sx={{ height: '30px' }}>
            <Box className="">
              <TitleText
                labelid="ssp.addOrganizationText"
                defaultlabel="Add Organization"
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box component="div" className="mt-sm">
            <ModalPopup
              open={openModal}
              description="formUnsavedChangesMessage"
              onCancel={() => setOpenModal(false)}
              onOk={() => {
                formikRef.current?.resetForm();
                setOpenModal(false);
              }}
              labelId1="Clientpage.cancelbtn"
              negativeActionLabel="Cancel"
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="Ok"
            />
            <Formik
              innerRef={formikRef}
              initialValues={vals}
              enableReinitialize={true}
              validateOnChange
              validationSchema={validationSchema}
              validate={validateForm}
              onSubmit={(values) => {
                if (values.id) {
                  updateHandler(values);
                } else {
                  formHandler(values);
                }
              }}
            >
              {({
                values,
                setFieldValue,
                initialValues,
                errors,
                touched,
                handleSubmit,
              }) => (
                <Box component="div">
                  <Grid container rowSpacing={'24px'} columnSpacing={'24px'}>
                    <Grid item xs={4}>
                      <Textinput
                        name="organizationName"
                        labelid="ssp.organizationName"
                        defaultlabelid="Organization Name"
                        Value={values.organizationName}
                        inputProps={{ maxLength: 100 }}
                        handlechange={(text) => {
                          setFieldValue(
                            'organizationName',
                            nameValidation(text),
                          );
                        }}
                        Required
                      />
                      {errors.organizationName && touched.organizationName && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.organizationName}
                          defaultlabel="Organization Name is Required"
                        />
                      )}
                    </Grid>
                    <Grid item xs={4}>
                      <DropdownComponent
                        names={TreatmentTypes}
                        labelid={'ssp.treatmentType'}
                        value={values.treatmentType}
                        Required
                        defaultlabelid="Treatment Type"
                        handleChange={(e) => {
                          if (e) {
                            setFieldValue('treatmentType', e);
                          }
                        }}
                      />
                      {errors.treatmentType && touched.treatmentType && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.treatmentType}
                          defaultlabel="Treatment Type is Required"
                        />
                      )}
                    </Grid>

                    <Grid item xs={4}>
                      <Textinput
                        name="areaServed"
                        labelid="ssp.areaServed"
                        defaultlabelid="Area Served"
                        Value={values.areaServed}
                        inputProps={{ maxLength: 50 }}
                        handlechange={(text) => {
                          setFieldValue('areaServed', text);
                        }}
                      />
                    </Grid>
                    {values.treatmentType === 'other' && (
                      <Grid item xs={6}>
                        <Textinput
                          name="treatmentName"
                          labelid="ssp.treatmentName"
                          defaultlabelid="Treatment Name"
                          Value={values.treatmentName}
                          Required
                          inputProps={{ maxLength: 100 }}
                          handlechange={(text) => {
                            setFieldValue(
                              'treatmentName',
                              nameValidation(text),
                            );
                          }}
                        />
                        {errors.treatmentName && touched.treatmentName && (
                          <MediumTypography
                            className="errorText-md"
                            labelid={errors.treatmentName}
                            defaultlabel="Treatment Name is Required"
                          />
                        )}
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <AddressForm
                        address1PlaceholderId="address1Text"
                        address2PlaceholderId="address2Text"
                        zipCodePlaceholderId="Clientpage.Zip"
                        cityPlaceholderId="Clientpage.City"
                        statePlaceholderId="Clientpage.State"
                        disableForm={false}
                        value={{
                          address1: values.address1,
                          address2: values.address2,
                          zipCode: values.zipCode,
                          city: values.city,
                          state: values.state,
                        }}
                        isClient={false}
                        formRequired={true}
                        formikErrors={{
                          address1: errors.address1,
                          address2: errors.address2,
                          zipCode: errors.zipCode,
                          city: errors.city,
                          state: errors.state,
                        }}
                        formikTouched={{
                          address1: touched.address1,
                          address2: touched.address2,
                          zipCode: touched.zipCode,
                          city: touched.city,
                          state: touched.state,
                        }}
                        handleAddressUpdate={(newAddress) => {
                          setFieldValue('address1', newAddress.address1);
                          setFieldValue('address2', newAddress.address2);
                          setFieldValue(
                            'zipCode',
                            newAddress.zipCode as OptionType,
                          );
                          setFieldValue('city', newAddress.city);
                          setFieldValue('state', newAddress.state);

                          formikRef.current?.setValues({
                            ...values,
                            address1: newAddress.address1,
                            address2: newAddress.address2,
                            zipCode: newAddress.zipCode,
                            city: newAddress.city,
                            state: newAddress.state,
                          });
                        }}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <DatePickerComponent
                        name={`startDate`}
                        labelid="Family.startDate"
                        disabledDate={false}
                        defaultlabelid="Start Date"
                        required
                        disableFuture={true}
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('startDate', null);
                          } else {
                            setFieldValue('startDate', formattedDate);
                          }
                        }}
                        value={dayjs(values.startDate)}
                      />
                      {errors.startDate && touched.startDate && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.startDate}
                          defaultlabel="Start Date is Required"
                        />
                      )}
                    </Grid>
                    <Grid xs={6} item>
                      <DatePickerComponent
                        name={`endDate`}
                        labelid="Family.endDate"
                        defaultlabelid="End Date"
                        minDate={dayjs(values.startDate)}
                        handlechange={(date: Dayjs | null) => {
                          const formattedDate =
                            dayjs(date).format('MM/DD/YYYY');
                          if (date === null) {
                            setFieldValue('endDate', null);
                          } else {
                            setFieldValue('endDate', formattedDate);
                          }
                        }}
                        value={dayjs(values.endDate)}
                      />
                      {errors.endDate && touched.endDate && (
                        <MediumTypography
                          className="errorText-md"
                          labelid={errors.endDate}
                          defaultlabel="End date should be greater than start date"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Box>
                    <Grid
                      className="flex__ justifyContent-FlexEnd pt-md pb-xs"
                      container
                      direction="row"
                      alignItems="right"
                    >
                      <Grid item>
                        <ButtonComponent
                          className="btn-primary btn-cancel"
                          variantType="contained"
                          labelId="Contacts.cancelbtn"
                          defaultLabelId="Cancel"
                          onClick={() => {
                            if (_.isEqual(initialValues, values)) {
                              handleClose();
                            } else {
                              setOpenModal(true);
                            }
                          }}
                        />

                        {vals.id && (
                          <ButtonComponent
                            className="btn-primary btn-submit ml-md"
                            variantType="contained"
                            type="submit"
                            labelId="clientpage.Update"
                            defaultLabelId="Update"
                            onClick={handleSubmit}
                          />
                        )}
                        {!vals.id && (
                          <ButtonComponent
                            className="btn-primary btn-submit ml-md"
                            variantType="contained"
                            type="submit"
                            labelId="Insurance.submit"
                            defaultLabelId="Save & Submit"
                            onClick={handleSubmit}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AddOrganization;
