import React, { FC, useCallback, useContext, useEffect, useRef } from 'react';
import './SignInComponent.css';
import Textinput from '../components/formlib/Textinput';
import { Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { Notification, SignInType } from '../utils/type';
import PasswordTextField from '../components/formlib/PasswordTextField';
import { loginUser } from '../services/userApi/User';
import MediumTypography from '../components/formlib/MediumTypography';
import SnackBarComponent from '../components/formlib/SnackBarComponent';
import { Context } from '../LanguageWrapper';
import {
  STORAGE_USERNAME_KEY,
  STORAGE_USER_ID_KEY,
  VERSION,
} from '../services/Constant';
import ButtonComponent from '../components/formlib/ButtonComponent';
import { AlertColor } from '@mui/material';
import { ReactComponent as TopLeftLogo } from '../assets/images/ShapeLeftTop.svg';
import { ReactComponent as TopRightLogo } from '../assets/images/Shape-TopRight.svg';
import { ReactComponent as BottomLeftLogo } from '../assets/images/Shape-BottomLeft.svg';
import { ReactComponent as BottomRightLogo } from '../assets/images/Shape-BottomRight.svg';
import { ReactComponent as ErrorLogo } from '../assets/images/error.svg';
import { ReactComponent as ThomLogo } from '../assets/images/ThomConnectLogo.svg';
import { ReactComponent as ThomConnectBottomLogo } from '../assets/images/ThomConnectBottomLogo.svg';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import { ApiError, isCustomError } from '../services/ApiResponseHandler';
import { getFirebaseToken } from '../firebase';
import { notifications } from '../services/configApi/notification/notification';
import { deleteAll, initDB } from '../services/storageDB';
import { isSupported } from 'firebase/messaging';

const initialValues: SignInType = {
  email: '',
  password: '',
};

export interface LoginBody {
  username: string;
  password: string;
  grantType: string;
  appSource: string;
}

interface SignInPropType {
  onLoginSuccess: (userId: string) => void;
}

const SignInComponent: FC<SignInPropType> = ({ onLoginSuccess }) => {
  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState<string>();
  const [showPassword, setShowPassword] = React.useState(false);
  const intl = useIntl();

  useEffect(() => {
    initDB()
      .then(() => {
        console.log('DB initialized to delete data');
        deleteAll();
      })
      .catch((error) => {
        console.log('DB initialized to delete data', error);
      });
  }, []);

  const getCopyRightMessage = () => {
    return `© ${dayjs().year()} ${intl.formatMessage({
      id: 'copyrightMessage',
      defaultMessage: 'Thom Child and Family Services All Rights Reserved',
    })}`;
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastrId(undefined);
    setOpen(false);
  };

  const readNotifications = (params: Notification, userId: string) => {
    notifications(params)
      .then(() => {
        console.log('notification is coming in signin.tsx');
      })
      .catch(async (error) => {
        console.error(
          'An error occurred while adding notification info and device token:',
          error,
        );
      })
      .finally(() => {
        navigation('/');
        onLoginSuccess(userId);
      });
  };
  const submit = async (values: SignInType) => {
    setLoader(true);
    const userparams: LoginBody = {
      username: values.email,
      password: values.password,
      grantType: 'password',
      appSource: 'TCS',
    };

    loginUser(userparams)
      .then(async (response) => {
        setOpen(true);
        // clearing for permission denied flag
        localStorage.clear();
        // clearing for permission denied flag
        if (response.programList === null) {
          setToastrVariable('error');
          setToastrId('forbiddenErrorMessage');
          setToastrDefaultMessage('Unable to login, Please contact admin');
        } else {
          localStorage.setItem('accessToken', response.accessToken);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('offlineToken', response.offlineToken);
          localStorage.setItem(STORAGE_USERNAME_KEY, response.name);
          localStorage.setItem(STORAGE_USER_ID_KEY, response.userId);
          localStorage.setItem('refreshTokenExpiry', response.refreshExpiresIn);
          localStorage.setItem('superProgramId', response.superProgramId);
          localStorage.setItem(
            'defaultProgramId',
            response.defaultProgramId ?? '',
          );
          context.updatePrograms(response.programList);
          context.updateSelectedProgram(
            response.defaultProgramId
              ? response.programList.filter(
                  (program) => program.id === response.defaultProgramId,
                )[0]
              : response.programList[0],
            false,
          );
          checkAndReadFirebaseToken(response.refreshExpiresIn, response.userId);
        }
      })
      .catch((error) => {
        setOpen(true);
        setLoader(false);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('Signin.errortoastr');
          setToastrDefaultMessage('Invalid username or password');
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const checkAndReadFirebaseToken = async (
    refreshExpiresIn: string,
    userId: string,
  ) => {
    try {
      if (
        (await isSupported()) &&
        window.Notification.permission === 'granted'
      ) {
        readFirebaseToken(refreshExpiresIn, userId);
      } else {
        navigation('/');
        onLoginSuccess(userId);
      }
    } catch (error) {
      navigation('/');
      onLoginSuccess(userId);
    }
  };

  const readFirebaseToken = (refreshExpiresIn: string, userId: string) => {
    getFirebaseToken()
      .then((firebaseToken) => {
        const params: Notification = {
          deviceToken: firebaseToken,
          refreshExpiresIn: refreshExpiresIn,
        };
        localStorage.setItem('fireBaseToken', firebaseToken);
        readNotifications(params, userId);
      })
      .catch(() => {
        navigation('/');
        onLoginSuccess(userId);
      });
  };

  const navigation = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid Email format')
      .required('EmailRequired.Validation'),
    password: Yup.string().required('SignIn.validation.passwordRequired'),
  });

  const formRef = useRef<FormikProps<SignInType>>(null);
  const handleFormSubmit = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      formRef.current?.submitForm();
    }
  };

  const context = useContext(Context);
  useEffect(() => {
    context.selectLanguage();
    navigation('/');
  }, []);

  const handleVisibilityChange = useCallback(() => {
    if (
      document.visibilityState === 'visible' &&
      localStorage.getItem(STORAGE_USERNAME_KEY) !== null
    ) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={open}
          handleClose={handleClose}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
      )}
      <Box className="loginContainerStyle3">
        <TopLeftLogo className="topLeftCornerImage" />
        <TopRightLogo className="topRightCornerImage" />
        <BottomLeftLogo className="bottomLeftCornerImage" />
        <BottomRightLogo className="bottomRightCornerImage" />
        <Box className="cardView" onKeyDown={handleFormSubmit}>
          <Box style={{ justifyContent: 'center', display: 'flex' }}>
            <ThomLogo />
          </Box>
          <Formik
            initialValues={initialValues}
            validateOnMount={true}
            innerRef={formRef}
            enableReinitialize={true}
            onSubmit={(values) => submit(values)}
            validationSchema={validationSchema}
          >
            {({ handleSubmit, setFieldValue, values, errors, touched }) => (
              <>
                <Box style={{ marginTop: '12%' }}>
                  <Textinput
                    sxProps={{ height: '40px' }}
                    className={`loginfields ${values.email && 'textbg'}`}
                    loginField={true}
                    name="email"
                    defaultlabelid="Username"
                    Value={values.email}
                    placeHolder="Username"
                    handlechange={(value: string) =>
                      setFieldValue('email', value)
                    }
                    inputLabelProps={{ shrink: false }}
                  />
                </Box>
                {errors.email && touched.email && (
                  <Box className="loginError">
                    <ErrorLogo />
                    <MediumTypography
                      labelid={errors.email}
                      defaultlabel="Enter the Email"
                      sxProps={{
                        color: 'var(--white)',
                        marginLeft: '10px',
                        fontSize: '12px',
                      }}
                    />
                  </Box>
                )}
                <Box style={{ marginTop: '5%' }}>
                  <PasswordTextField
                    className={`loginfields ${values.password && 'eyeiconbg'}`}
                    name="password"
                    placeHolder="Password"
                    Value={values.password}
                    showpassword={showPassword}
                    handleTogglePasswordVisibility={() => {
                      setShowPassword(!showPassword);
                    }}
                    autoComplete="new-password"
                    handlechange={(
                      e: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                    ) => setFieldValue('password', e.target.value)}
                  />
                </Box>
                {errors.password && touched.password && (
                  <Box className="loginError">
                    <ErrorLogo />
                    <MediumTypography
                      labelid={errors.password}
                      defaultlabel="Enter the password"
                      sxProps={{
                        color: 'var(--white)',
                        marginLeft: '10px',
                        fontSize: '12px',
                      }}
                    />
                  </Box>
                )}
                <Box style={{ marginTop: '5%' }}>
                  <ButtonComponent
                    className="btn-primary btn-submitLogin login_button"
                    type="submit"
                    labelId="SignIn.Loginbtn"
                    defaultLabelId="Login"
                    onClick={handleSubmit}
                    loading={loader}
                  />
                </Box>
              </>
            )}
          </Formik>
          <Box className="reservedText">
            <ThomConnectBottomLogo
              style={{ cursor: 'pointer' }}
              onClick={() => window.open('https://www.thomchild.org/')}
            />
            <Box className="justifyContent-Center">
              <MediumTypography
                label={getCopyRightMessage()}
                textColor="white"
                marginTop="8px"
                fontSize="12px"
              />
            </Box>
            <Box className="justifyContent-Center">
              <MediumTypography
                className="pl-md"
                label={`Version - ${VERSION}`}
                textColor="white"
                marginTop="-5px"
                fontSize="12px"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SignInComponent;
