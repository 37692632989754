import { Box, SxProps, TableCell, TableHead, TableRow } from '@mui/material';

import MediumTypography from '../../components/formlib/MediumTypography';

interface TableProps<T> {
  numSelected?: number;
  rowCount?: number;
  headerNames: HeadCell<T>[];
  labelSxProp?: SxProps;
  className?: string | undefined;
}

export interface HeadCell<T> {
  id: keyof T;
  labelId: string;
  defaultLabelId: string;
  numeric: boolean;
}

export const CustomTableHeader = <T,>(props: TableProps<T>) => {
  const { headerNames, labelSxProp, className } = props;

  return (
    <TableHead className={className}>
      <TableRow>
        {headerNames.map((cell: HeadCell<T>) => (
          <TableCell
            key={cell.id as string}
            align={cell.numeric ? 'right' : 'left'}
            padding="none"
            className="pt-xs pb-xs"
            sx={{
              verticalAlign: 'top',
              border: '1px solid #CFDEDE',
              backgroundColor: '#ECF9F8',
            }}
          >
            <Box>
              <MediumTypography
                sxProps={{
                  fontFamily: 'Lato-Bold',
                  color: '#97A6A5',
                  whiteSpace: 'nowrap',
                  ...labelSxProp,
                }}
                labelid={cell.labelId}
                defaultlabel={cell.defaultLabelId}
              />
            </Box>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
