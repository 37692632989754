import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Modal, Grid, Box, Card, AlertColor } from '@mui/material';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { FormattedMessage, useIntl } from 'react-intl';
import RadioComponent from '../../components/formlib/RadioComponent';
import { ScheduleOccurance, SchedulerEventType } from '../../utils/type';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import { deleteAppointment } from '../../services/schedulerEventsApi';
import SmallTypography from '../../components/formlib/SmallTypography';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import { debounce } from 'lodash';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';

interface DeleteModalFieldProps {
  schedulerEvent: SchedulerEventType;
  openDeleteEventModel: boolean;
  closeDeleteModal: (
    openFromDelete: boolean,
    toastrVariableFromDelete?: AlertColor,
    toastrMessageFromDelete?: string,
    toastrIdFromEdit?: string,
    toastrDefaultMessageFromDelete?: string,
  ) => void;
}

const styledelmodel = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 410,
  height: 'auto',
  bgcolor: 'background.paper',
  padding: '20px 20px 20px 20px',
  maxHeight: '225px',
  outline: 'none',
  fontFamily: 'Lato-Regular',
};

const DeleteSchedulerEventModal: FC<DeleteModalFieldProps> = ({
  schedulerEvent,
  openDeleteEventModel,
  closeDeleteModal,
}) => {
  const loggedInClinicianId = localStorage.getItem(STORAGE_USER_ID_KEY);
  const rctl = useIntl();

  dayjs.extend(utc);
  dayjs.extend(timezone);

  const deleteOption = useRef('');
  const [refreshState, setRefreshState] = useState<Date>(new Date());
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [error, setError] = React.useState(false);

  const handleDelete = useCallback(
    debounce(() => {
      handleDeleteSubmit();
    }, 500),
    [],
  );

  useEffect(() => {
    console.log('refreshState = ', refreshState);
  }, [refreshState]);

  const handleDeleteSubmit = () => {
    let deletOptionSelected = '';

    if (futureoccurrencesExists) {
      if (deleteOption.current.toString() === '') {
        setError(true);
        return;
      }
      deletOptionSelected =
        deleteOption.current.toString() === 'true'
          ? 'this_appointment'
          : 'following_appointments';
    } else {
      deletOptionSelected = 'this_appointment';
    }
    toggleLoader(true);
    deleteAppointment(
      loggedInClinicianId ?? '',
      loggedInClinicianId ?? '',
      schedulerEvent.appointmentType !== 'block'
        ? schedulerEvent.attendeeList[0].appointmentId
        : schedulerEvent.id,
      deletOptionSelected,
    )
      .then(() => {
        toggleLoader(false);
        closeDeleteModal(
          true,
          'success',
          undefined,
          'DeleteSchedulerEvent.successMessage',
          'Appointment deleted successfully',
        );
      })
      .catch((apiError) => {
        toggleLoader(false);
        if (isCustomError(apiError)) {
          const e = apiError as ApiError;
          closeDeleteModal(
            true,
            'error',
            rctl.formatMessage({ id: e.id }),
            undefined,
            undefined,
          );
        } else {
          const response = apiError as ApiMessage;
          if (response.code === 13001) {
            closeDeleteModal(
              true,
              'error',
              rctl.formatMessage({ id: 'apptProgressNoteSignedMessage' }),
              undefined,
              undefined,
            );
          } else if (response.code === 13002) {
            closeDeleteModal(
              true,
              'error',
              rctl.formatMessage({ id: 'appointmentApprovedMessage' }),
              undefined,
              undefined,
            );
          } else if (response.code === 13003) {
            closeDeleteModal(
              true,
              'error',
              rctl.formatMessage({ id: 'appointmentDoesNotExitsOrDeleted' }),
              undefined,
              undefined,
            );
          } else if (response.code === 400) {
            closeDeleteModal(
              true,
              'error',
              rctl.formatMessage({ id: 'appointmentCannotDeleteMessage' }),
              undefined,
              undefined,
            );
          } else {
            closeDeleteModal(true, 'error', apiError, undefined, undefined);
          }
        }
      });
    deleteOption.current = '';
  };

  let futureoccurrencesExists = false;

  //check if any future occurrences exist
  if (
    schedulerEvent?.occurrences != undefined &&
    schedulerEvent?.occurrences.length !== 0
  ) {
    const currentUtcTime = dayjs.utc(schedulerEvent.start);
    futureoccurrencesExists = schedulerEvent?.occurrences.some(
      (item: ScheduleOccurance) => {
        const itemDate = dayjs.utc(item.start);
        return itemDate.isAfter(currentUtcTime);
      },
    );
  }

  const handleRadio = (val: string) => {
    if (val === 'true') {
      return true;
    } else if (val === 'false') {
      return false;
    } else {
      return '';
    }
  };

  return (
    <Card
      sx={{
        my: '0px',
        ml: '0px',
        mt: '0px',
        padding: '24px',
      }}
    >
      {schedulerEvent != undefined && (
        <Modal
          open={openDeleteEventModel}
          onClose={() => {
            deleteOption.current = '';
            closeDeleteModal(false, undefined, undefined, undefined, undefined);
          }}
        >
          <div>
            <Box sx={styledelmodel}>
              {futureoccurrencesExists && (
                <>
                  <h2
                    style={{
                      color: '#008C82',
                      fontSize: '16px',
                      fontWeight: '600',
                      lineHeight: '16px',
                      marginBottom: '0px',
                      marginTop: '5px',
                      fontFamily: 'Lato-Bold',
                    }}
                  >
                    <FormattedMessage
                      id="AddNewSchedulerEvent.DeleteModalTitleForOccuringEvent"
                      defaultMessage="Remove Recurring Event?"
                    />
                  </h2>
                  <Grid item xs={4.5}>
                    <div className="radiobtn">
                      <RadioComponent
                        value={handleRadio(deleteOption.current)}
                        name="deleteRecurringEvent"
                        handleChange={(
                          e: React.ChangeEvent<HTMLInputElement>,
                        ) => {
                          deleteOption.current = e.target.value;
                          setRefreshState(new Date());
                          setError(false);
                        }}
                        labelidA="AddNewSchedulerEvent.DeleteThisOccuringEventLabel"
                        defaultlabelidA="This Event"
                        labelidB="AddNewSchedulerEvent.DeleteAlleOccuringEventLabel"
                        defaultlabelidB="This and following Events"
                      />
                    </div>
                    {error && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId="DeleteSchedulerEvent.validationErrorMessage"
                      />
                    )}
                  </Grid>
                  <Grid></Grid>
                </>
              )}
              {!futureoccurrencesExists && (
                <h2
                  style={{
                    color: '#2A4241',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '16px',
                    marginBottom: '0px',
                    marginTop: '5px',
                    fontFamily: 'Lato-Bold',
                  }}
                >
                  <FormattedMessage
                    id="AddNewSchedulerEvent.DeleteModalTitleForNonOccuringEvent"
                    defaultMessage="Are you sure you want to delete this appointment?"
                  />
                </h2>
              )}

              <Grid
                container
                direction="row"
                alignItems="right"
                textAlign={'right'}
              >
                <Grid item xs={12} textAlign={'right'}>
                  <Grid mr={2} mt={5}>
                    <ButtonComponent
                      className="btn-primary btn-cancel"
                      variantType="contained"
                      labelId={
                        futureoccurrencesExists
                          ? 'AddNewSchedulerEvent.cancelButton'
                          : 'DuplicateClientModal.noButton'
                      }
                      defaultLabelId={futureoccurrencesExists ? 'Cancel' : 'No'}
                      onClick={() => {
                        deleteOption.current = '';
                        closeDeleteModal(
                          false,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                        );
                      }}
                    />
                    <ButtonComponent
                      variantType="contained"
                      type="submit"
                      className="btn-primary btn-submit ml-md"
                      labelId={
                        futureoccurrencesExists
                          ? 'AddNewSchedulerEvent.okButton'
                          : 'DuplicateClientModal.yesButton'
                      }
                      defaultLabelId={futureoccurrencesExists ? 'Ok' : 'Yes'}
                      onClick={() => {
                        handleDelete();
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        </Modal>
      )}
    </Card>
  );
};

export default DeleteSchedulerEventModal;
