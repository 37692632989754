import { FC } from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const Loader: FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        height: '100%',
        width: '100%',
        zIndex: 9999,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgb(53 132 126 / 80%)',
        display: 'flex',
      }}
    >
      {' '}
      <CircularProgress
        size={40}
        sx={{
          width: '2%',
          justifyContent: 'center',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 9999,
          position: 'relative',
          height: '10%',
          color: 'var(--white)',
        }}
      />
    </Box>
  );
};

export default Loader;
