import React, { useContext, useEffect, useState } from 'react';
import './App.css';
import './fonts/Lato-Black.ttf';
import './fonts/Lato-BlackItalic.ttf';
import './fonts/Lato-Bold.ttf';
import './fonts/Lato-Italic.ttf';
import './fonts/Lato-Light.ttf';
import './fonts/Lato-LightItalic.ttf';
import './fonts/Lato-Regular.ttf';
import './fonts/Lato-Thin.ttf';
import './fonts/Lato-ThinItalic.ttf';
import { REFERRAL_ID_KEY, STORAGE_USER_ID_KEY } from './services/Constant';
import SignInComponent from './pages/SignInComponent';
import {
  ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME,
  ApplicationRoute,
  EMPLOYEE_DASHBOARD_ROUTE_NAME,
  FACESHEET,
} from './routes/Routing';
import { EventBusProvider } from './context/EventBusProvider';
import SnackBarComponent from './components/formlib/SnackBarComponent';
import { ReactComponent as SessionIcon } from './assets/images/expiryIcon.svg';
import { Notification, ResponseType } from './utils/type';
import ScrollToTop from './ScrollToTop';
import { getFirebaseToken } from './firebase';
import { notifications } from './services/configApi/notification/notification';
import { useNavigate } from 'react-router';
import { getFaceSheetSideBarManus, getSideBarMenus } from './layouts/Sidebar';
import { LookUpContext } from './context/LookUpContextProvider';
import { Context, ContextType } from './LanguageWrapper';
import { clearClientStorageDetails } from './utils/utilities';

function App() {
  const [userLoggedIn, setUserLoggedIn] = useState<string | null>();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');

  const navigation = useNavigate();

  const { updateDemographics, updateNotificationAndProfileEnabled } =
    React.useContext(LookUpContext);

  const context: ContextType = useContext(Context);

  useEffect(() => {
    context.selectLanguage();
  }, []);

  useEffect(() => {
    const lookupsDemographics = localStorage.getItem('Demographics');
    if (lookupsDemographics) {
      updateDemographics(JSON.parse(lookupsDemographics));
    }
  }, []);

  useEffect(() => {
    const disableBackButton = (event: PopStateEvent) => {
      const currentUrl = document.location.pathname;

      const getReferralId = localStorage.getItem(REFERRAL_ID_KEY);

      if (getReferralId === null || currentUrl === FACESHEET) {
        if (
          getSideBarMenus().some((menu) => {
            if (menu.to.includes(currentUrl) || currentUrl === FACESHEET) {
              return true;
            }
            // Check if the submenu contains the current URL
            if (menu.subMenus && menu.subMenus.length > 0) {
              return menu.subMenus.some((subMenu) =>
                subMenu.to.includes(currentUrl),
              );
            }
            return false;
          })
        ) {
          if (
            currentUrl !== EMPLOYEE_DASHBOARD_ROUTE_NAME &&
            currentUrl !== ACTIVITY_APPROVAL_OVERVIEW_SHEET_ROUTE_NAME
          ) {
            navigation(EMPLOYEE_DASHBOARD_ROUTE_NAME);
            event.preventDefault();
            event.stopPropagation();
          }
        }
      } else {
        if (
          getFaceSheetSideBarManus().some((subMenu) =>
            subMenu.to.includes(currentUrl),
          )
        ) {
          if (currentUrl !== FACESHEET) {
            navigation(FACESHEET);
            event.preventDefault();
            event.stopPropagation();
          }
        }
      }
    };

    // Add an event listener to continuously disable the back button
    window.addEventListener('popstate', disableBackButton);

    const currentUrlPath = document.location.pathname;

    const shouldClearStorage = getSideBarMenus().some((menu) => {
      if (menu.to === currentUrlPath) {
        return true;
      }

      if (menu.subMenus && menu.subMenus.length > 0) {
        return menu.subMenus.some((subMenu) =>
          subMenu.to.includes(currentUrlPath),
        );
      }

      return false;
    });

    if (shouldClearStorage) {
      sessionStorage.clear();
      clearClientStorageDetails();
    }
    window.addEventListener('storage', function (e) {
      if (e.key === 'clientReferralDetails') {
        if (
          localStorage.getItem(REFERRAL_ID_KEY) === null &&
          localStorage.getItem(STORAGE_USER_ID_KEY) !== null
        ) {
          navigation(EMPLOYEE_DASHBOARD_ROUTE_NAME);
        } else if (
          localStorage.getItem(REFERRAL_ID_KEY) !== null &&
          localStorage.getItem(STORAGE_USER_ID_KEY) !== null
        ) {
          navigation(FACESHEET);
        }
      }

      if (e.key === 'selectedProgram') {
        if (localStorage.getItem(STORAGE_USER_ID_KEY) !== null) {
          navigation(EMPLOYEE_DASHBOARD_ROUTE_NAME);
          window.location.reload();
        }
      }

      if (!e.newValue) {
        if (localStorage.getItem(STORAGE_USER_ID_KEY) === null) {
          window.location.reload();
        }
      }
    });
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', disableBackButton);
    };
  }, []);

  useEffect(() => {
    window.process = {
      ...window.process,
    };

    const selectedProgram = localStorage.getItem('selectedProgram');

    if (selectedProgram) {
      const permissionOfThisProgram = localStorage.getItem(
        JSON.parse(selectedProgram).id,
      );
      if (permissionOfThisProgram) {
        updateNotificationAndProfileEnabled(true);
      }
    }

    getFirebaseToken()
      .then((firebaseToken) => {
        const loggedInFireBaseToken = localStorage.getItem('fireBaseToken');
        const params: Notification = {
          deviceToken: firebaseToken,
          refreshExpiresIn: localStorage.getItem('refreshTokenExpiry'),
        };
        if (firebaseToken) {
          if (
            firebaseToken !== loggedInFireBaseToken &&
            localStorage.getItem(STORAGE_USER_ID_KEY) !== null
          ) {
            notifications(params).catch(async (error) => {
              console.error(
                'An error occurred while adding notification info and device token:',
                error,
              );
            });
          }
        }
      })
      .catch((err) =>
        console.error(
          'An error occured while retrieving firebase token. ',
          err,
        ),
      );
    EventBusProvider.subscribe('logout', () => {
      setSuccessOrError('error');
      setShowMessage(true);
      setUserLoggedIn(null);
    });
    setUserLoggedIn(localStorage.getItem(STORAGE_USER_ID_KEY));
  }, []);

  if (userLoggedIn === undefined) {
    return null;
  }

  if (userLoggedIn === null) {
    const permissionFlag = localStorage.getItem('permissionDenied');
    return (
      <>
        {showMessage && (
          <SnackBarComponent
            open={showMessage}
            handleClose={() => {
              setShowMessage(false);
            }}
            icon={<SessionIcon />}
            successOrError={successOrError}
            labelId={
              permissionFlag
                ? 'permissionDeniedMessage'
                : 'sessionExpiryMessage'
            }
          />
        )}
        <SignInComponent
          onLoginSuccess={(userId) => {
            setUserLoggedIn(userId);
          }}
        />
      </>
    );
  }

  return (
    <>
      <ScrollToTop />
      <ApplicationRoute
        onLogout={() => {
          setUserLoggedIn(null);
        }}
      />
    </>
  );
}

export default App;
