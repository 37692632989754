import { Box, TableCell, TableRow, Tooltip } from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/delete.svg';
import AppTimePicker from '../../components/formlib/AppTimePicker';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  BusinesScheduleResponse,
  Schedule,
} from '../../services/configApi/employees/employeeServices';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import {
  LOCAL_TIME_12_HOUR_FORMAT,
  LOCAL_TIME_24_HOUR_FORMAT,
} from '../../services/Constant';
import { getTime } from '../../utils/dateUtil';
import { checkPermissionForFeature } from '../../utils/checkPermission';

interface TableRowData {
  data: Schedule | null;
  day: number;
  handleSubmit: (
    schedule: Schedule,
    dayNumber: keyof BusinesScheduleResponse['myWeekSchedule'],
  ) => void;
  handleDelete: (scheduleId: string) => void;
}
const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const ProfileBusinessHoursTableRow: FC<TableRowData> = ({
  data,
  day,
  handleSubmit,
  handleDelete,
}) => {
  const [editable, setEditable] = useState<boolean>(false);
  const [isSave, setIsSave] = useState<boolean>(false);
  const [newSchedule, setNewSchedule] = useState<Schedule | null>(data);
  const [saveDisable, setSaveDisable] = useState<boolean>(false);
  const [startWarning, setStartWarning] = useState<boolean>(false);
  const [endWarning, setEndWarning] = useState<boolean>(false);
  const [startLunchWarning, setStartLunchWarning] = useState<boolean>(false);
  const [endLunchWarning, setendLunchWarning] = useState<boolean>(false);

  const handleAddClick = () => {
    setIsSave(true);
    setEditable(true);
    if (newSchedule === null) {
      setNewSchedule({
        day: day,
        startTime: dayjs('09:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        endTime: dayjs('18:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        lunchStartTime: dayjs('13:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        lunchEndTime: dayjs('14:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        scheduleId: null,
      });
    }
  };

  useEffect(() => {
    if (!dayjs(newSchedule?.startTime).isValid()) {
      setNewSchedule({
        day: day,
        startTime: dayjs('09:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        endTime: dayjs('18:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        lunchStartTime: dayjs('13:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        lunchEndTime: dayjs('14:00:00', LOCAL_TIME_24_HOUR_FORMAT).format(),
        scheduleId: null,
      });
    }
  }, [isSave, editable]);
  const handleEditClick = () => {
    setEditable(true);
  };

  const handleCancelAction = () => {
    setStartWarning(false);
    setEndWarning(false);
    setStartLunchWarning(false);
    setendLunchWarning(false);
    setSaveDisable(false);
    if (!isSave && editable) {
      setEditable(false);
      setNewSchedule(data);
    } else {
      setEditable(false);
      setNewSchedule(null);
    }
  };

  const handleDeleteAction = (scheduleId: string) => {
    handleDelete(scheduleId);
  };

  const handleSave = (
    dayNumber: keyof BusinesScheduleResponse['myWeekSchedule'],
  ) => {
    if (newSchedule !== null) {
      setEditable(false);
      setIsSave(false);
      handleSubmit(newSchedule, dayNumber);
    }
  };

  useEffect(() => {
    if (newSchedule !== null) {
      const selectedStartTime = dayjs(newSchedule.startTime);
      const selectedEndTime = dayjs(newSchedule.endTime);
      const selectedLunchStartTime = dayjs(newSchedule.lunchStartTime);
      const selectedLunchEndTime = dayjs(newSchedule.lunchEndTime);
      if (selectedStartTime.isAfter(selectedEndTime)) {
        setStartWarning(true);
        setSaveDisable(true);
      }
      if (selectedEndTime.isBefore(selectedStartTime)) {
        setEndWarning(true);
        setSaveDisable(true);
      }
      if (selectedLunchStartTime.isBefore(selectedStartTime)) {
        setStartLunchWarning(true);
        setSaveDisable(true);
      }
      if (selectedLunchEndTime.isBefore(selectedLunchStartTime)) {
        setendLunchWarning(true);
        setSaveDisable(true);
      }
      if (selectedLunchEndTime.isAfter(selectedEndTime)) {
        setendLunchWarning(true);
        setSaveDisable(true);
      }
      if (selectedLunchEndTime.isBefore(selectedStartTime)) {
        setendLunchWarning(true);
        setSaveDisable(true);
      }
      if (selectedLunchStartTime.isAfter(selectedEndTime)) {
        setStartLunchWarning(true);
        setSaveDisable(true);
      }
      if (
        selectedEndTime.isSame(selectedStartTime) ||
        selectedStartTime.isSame(selectedEndTime)
      ) {
        setEndWarning(true);
        setSaveDisable(true);
      }
      if (
        selectedLunchEndTime.isSame(selectedLunchStartTime) ||
        selectedLunchStartTime.isSame(selectedLunchEndTime)
      ) {
        setendLunchWarning(true);
        setSaveDisable(true);
      }
    }
  }, [
    newSchedule?.endTime,
    newSchedule?.startTime,
    newSchedule?.lunchStartTime,
    newSchedule?.lunchEndTime,
  ]);

  useEffect(() => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
  }, []);

  return (
    <TableRow className={editable ? 'edit-row' : 'non-edit-row'}>
      <TableCell
        component="th"
        scope="row"
        className={
          newSchedule === null && !editable ? 'activateWorking' : 'non-edit-row'
        }
      >
        <MediumTypography label={daysOfWeek[day]} />
      </TableCell>
      {newSchedule === null && !editable ? (
        <TableCell
          sx={{
            cursor: checkPermissionForFeature(
              'backend.work_schedule',
              'editPermission',
            )
              ? 'pointer'
              : 'not-allowed',
            backgroundColor: checkPermissionForFeature(
              'backend.work_schedule',
              'editPermission',
            )
              ? null
              : 'var(--thom-green-light2-bg)',
          }}
          colSpan={5}
          align="center"
          onClick={() => {
            if (
              checkPermissionForFeature(
                'backend.work_schedule',
                'editPermission',
              )
            ) {
              handleAddClick();
            }
          }}
          style={{
            pointerEvents: 'none',
          }}
          onKeyDownCapture={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          className={
            newSchedule === null && !editable
              ? 'activateWorking'
              : 'non-edit-row'
          }
        >
          <MediumTypography
            label="Activate Working Today"
            sxProps={{
              color: checkPermissionForFeature(
                'backend.work_schedule',
                'editPermission',
              )
                ? '#008C82'
                : '#97A6A5',
              fontWeight: '600',
              cursor: checkPermissionForFeature(
                'backend.work_schedule',
                'editPermission',
              )
                ? 'pointer'
                : 'not-allowed',
              marginLeft: '-25%',
            }}
          />
        </TableCell>
      ) : (
        <>
          <TableCell>
            {editable || isSave ? (
              <Box display={'flex'} className="timePickerStyle_Profile">
                {newSchedule?.startTime !== 'Invalid Date' && (
                  <AppTimePicker
                    value={
                      newSchedule === null
                        ? null
                        : getTime(newSchedule.startTime, '09:00:00')
                    }
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            startTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            startTime: null,
                            endTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            startTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            startTime: time.format(),
                            endTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: startWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
                {newSchedule?.startTime === 'Invalid Date' && (
                  <AppTimePicker
                    value={null}
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            startTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            startTime: null,
                            endTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            startTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            startTime: time.format(),
                            endTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: startWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
              </Box>
            ) : (
              <MediumTypography
                label={
                  newSchedule !== null
                    ? newSchedule.startTime !== null
                      ? dayjs(newSchedule.startTime).format(
                          LOCAL_TIME_12_HOUR_FORMAT,
                        )
                      : '09:00 AM'
                    : undefined
                }
              />
            )}
          </TableCell>
          <TableCell>
            {editable ? (
              <Box display={'flex'} className="timePickerStyle_Profile">
                {newSchedule?.endTime !== 'Invalid Date' && (
                  <AppTimePicker
                    value={
                      newSchedule === null
                        ? null
                        : getTime(newSchedule.endTime, '18:00:00')
                    }
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            endTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            endTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: time.format(),
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: endWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
                {newSchedule?.endTime === 'Invalid Date' && (
                  <AppTimePicker
                    value={null}
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            endTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            endTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: time.format(),
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: endWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
              </Box>
            ) : (
              <MediumTypography
                label={
                  newSchedule !== null
                    ? newSchedule.endTime !== null
                      ? dayjs(newSchedule.endTime).format(
                          LOCAL_TIME_12_HOUR_FORMAT,
                        )
                      : '06:00 PM'
                    : undefined
                }
              />
            )}
          </TableCell>
          <TableCell>
            {editable ? (
              <Box display={'flex'} className="timePickerStyle_Profile">
                {newSchedule?.lunchStartTime !== 'Invalid Date' && (
                  <AppTimePicker
                    value={
                      newSchedule === null
                        ? null
                        : getTime(newSchedule.lunchStartTime, '13:00:00')
                    }
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchStartTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchStartTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: time.format(),
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: startLunchWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
                {newSchedule?.lunchStartTime === 'Invalid Date' && (
                  <AppTimePicker
                    value={null}
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchStartTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchStartTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: time.format(),
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: startLunchWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
              </Box>
            ) : (
              <MediumTypography
                label={
                  newSchedule !== null
                    ? newSchedule.lunchStartTime !== null
                      ? dayjs(newSchedule.lunchStartTime).format(
                          LOCAL_TIME_12_HOUR_FORMAT,
                        )
                      : '01:00 PM'
                    : undefined
                }
              />
            )}
          </TableCell>
          <TableCell>
            {editable ? (
              <Box display={'flex'} className="timePickerStyle_Profile">
                {newSchedule?.lunchEndTime !== 'Invalid Date' && (
                  <AppTimePicker
                    value={
                      newSchedule === null
                        ? null
                        : getTime(newSchedule.lunchEndTime, '14:00:00')
                    }
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchEndTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchEndTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: time.format(),
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: endLunchWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
                {newSchedule?.lunchEndTime === 'Invalid Date' && (
                  <AppTimePicker
                    value={null}
                    onTimeChange={(time) => {
                      if (time === null) {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchEndTime: null,
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: null,
                            scheduleId: null,
                          });
                        }
                      } else {
                        if (newSchedule !== null) {
                          setNewSchedule({
                            ...newSchedule,
                            lunchEndTime: time.format(),
                          });
                        } else {
                          setNewSchedule({
                            day: day,
                            endTime: null,
                            startTime: null,
                            lunchStartTime: null,
                            lunchEndTime: time.format(),
                            scheduleId: null,
                          });
                        }
                      }

                      setSaveDisable(false);
                      setStartLunchWarning(false);
                      setendLunchWarning(false);
                      setStartWarning(false);
                      setEndWarning(false);
                    }}
                    sxButtonProps={{
                      border: endLunchWarning
                        ? '1px solid #EB4C60'
                        : '1px solid #97A6A5',
                    }}
                  />
                )}
              </Box>
            ) : (
              <MediumTypography
                label={
                  newSchedule !== null
                    ? newSchedule.lunchEndTime !== null
                      ? dayjs(newSchedule.lunchEndTime).format(
                          LOCAL_TIME_12_HOUR_FORMAT,
                        )
                      : '02:00 PM'
                    : undefined
                }
              />
            )}
          </TableCell>
          <TableCell sx={{ display: 'none', textAlign: 'center' }}>
            {editable ? (
              <Box display={'none'}>
                <ButtonComponent
                  className="btn-primary btn-cancel ml-md"
                  variantType="contained"
                  labelId="cancelText"
                  defaultLabelId="Cancel"
                  onClick={handleCancelAction}
                />

                <ButtonComponent
                  className="btn-primary btn-submit ml-md mr-md"
                  variantType="contained"
                  labelId="Save"
                  defaultLabelId="Save"
                  onClick={() =>
                    handleSave(
                      day as keyof BusinesScheduleResponse['myWeekSchedule'],
                    )
                  }
                  disabled={saveDisable}
                />
              </Box>
            ) : (
              <Box display={'none'}>
                <Box
                  sx={{
                    marginRight: '15px',
                    cursor: checkPermissionForFeature(
                      'backend.work_schedule',
                      'editPermission',
                    )
                      ? 'pointer'
                      : 'not-allowed',
                  }}
                >
                  <Tooltip title="Edit">
                    <EditIcon
                      onClick={
                        checkPermissionForFeature(
                          'backend.work_schedule',
                          'editPermission',
                        )
                          ? handleEditClick
                          : () => {}
                      }
                    />
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    cursor: checkPermissionForFeature(
                      'backend.work_schedule',
                      'deletePermission',
                    )
                      ? 'pointer'
                      : 'not-allowed',
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteIcon
                      onClick={() => {
                        if (
                          newSchedule !== null &&
                          newSchedule.scheduleId !== null &&
                          checkPermissionForFeature(
                            'backend.work_schedule',
                            'deletePermission',
                          )
                        ) {
                          handleDeleteAction(newSchedule.scheduleId);
                        }
                      }}
                    />
                  </Tooltip>
                </Box>
              </Box>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  );
};

export default ProfileBusinessHoursTableRow;
