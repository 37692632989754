import pkg from '../../package.json';
// Production
// export const USER_BASE_URL = 'https://userapi.thomchild.org/';
// export const THOM_BASE_URL = 'https://configapi.thomchild.org/';

//dev
export const USER_BASE_URL = process.env.REACT_APP_USER_API;
export const THOM_BASE_URL = process.env.REACT_APP_CONFIG_API;
export const THOM_WEBSOCKET_URL = process.env.REACT_APP_WS_API;
export const VERSION = pkg.version;

//qa
// export const USER_BASE_URL = 'http://userapi-qa.thomchild.org:8080/';
// export const THOM_BASE_URL = 'http://configapi-qa.thomchild.org:8080/';

// //BTC SERVER
// export const USER_BASE_URL = 'http://localhost:8081/';
// export const THOM_BASE_URL = 'http://localhost:8081/';

export const STORAGE_USERNAME_KEY = 'userName';
export const STORAGE_USER_ID_KEY = 'userId';
export const CLIENT_ID_KEY = 'ClientId';
export const CLIENT_NAME_KEY = 'clientName';
export const REFERRAL_ID_KEY = 'referralId';
export const SELECTED_PROGRAM_KEY = 'selectedProgram';
export const PROGRAMS_KEY = 'programs';
export const LOCAL_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const LOCAL_TIME_24_HOUR_FORMAT = 'HH:mm:ss';
export const LOCAL_TIME_12_HOUR_FORMAT = 'hh:mm A';
export const LOCAL_DATE_TIME_UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const PAGE_SIZE = 10;
export const EXCLUDING_DATES = ['01/01/0001', '01/01/1800'];
export const UAT_HOST_NAME = ['ehr.thomchild.org', 'localhost'];

export const PREVIEW_DOCUMENT_EXTENSIONS: string[] = [
  '.jpg',
  '.pdf',
  '.txt',
  '.jpeg',
  '.png',
];

export const employeeRoles = [
  'Service Coordinator',
  'Clinician',
  'Clinical Supervisor/Team Leader',
  'Intake Coordinator',
  'Assessment Clinician',
  'Intake/Evaluation Scheduler',
  'Program Biller/Billing',
  'EICS Data Entry',
  'Insurance/Billing Contact',
  'Insurance Verification',
  'Assistant Director',
  'Program Director',
  'Service Verification',
  'Payroll',
  'Central Billing',
  'Central Leadership',
  'System Admin/DBA',
  'Office Manager',
];

export const employeeTypes = [
  'Hourly Non-Exempt',
  'Salaried Non-Exempt',
  'Salaried Exempt',
];

export const programList = [
  'Anne Sullivan Center - ASC',
  'Boston Metro Early Intervention - BMEI',
  'Charles River Early Intervention - CREI',
  'Marlboro Area Early Intervention - ΜΑΕΙ',
  'Mystic Valley Early Intervention - MVEI',
  'Neponset Valley Early Intervention - NVEI',
  'Pentucket Early Intervention - ΡΕΙ',
  'Springfield Infant Toddler Services - SITS',
  'Worcester Area Early Intervention - WAEI',
  'Westfield Infant Toddler Services - WITS',
];

// RegEx
export const EMAIL_REGEX = /^[^\s@]{1,64}@[A-Za-z0-9.-]{1,255}\.[A-Za-z]{2,}$/;
export const DECIMAL_POINT_REGEX = /^\d+(\.\d+)?$/;
