import { Box } from '@mui/system';
import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import MediumTypography from './MediumTypography';

interface ChipDisplayProps {
  items: string[];
  dataRef: MutableRefObject<number>;
  width: number
}

const ChipDisplay: React.FC<ChipDisplayProps> = ({ items, dataRef, width }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [remainingCount, setRemainingCount] = useState(0);

  const calculateVisibleItems = () => {
    if (!containerRef.current) return;

    const containerWidth = width;
    let currentWidth = 0;
    let visibleItemsCount = 0;

    const children = containerRef.current.children;

    for (const element of children) {
      const chipWidth = (element as HTMLElement).offsetWidth;
      if (currentWidth + chipWidth > containerWidth) {
        break;
      }
      currentWidth += chipWidth;
      visibleItemsCount++;
    }

    dataRef.current = currentWidth;

    setVisibleItems(items.slice(0, visibleItemsCount));
    setRemainingCount(items.length - visibleItemsCount);
  };

  useEffect(() => {
    calculateVisibleItems(); // Initial calculation

    return () => {
    };
  }, [items]);

  return (
    <Box
      ref={containerRef}
      style={{ display: 'flex', flexWrap: 'nowrap', overflow: 'hidden' }}
    >
      {visibleItems.map((item, index) => (
        <Box
          key={index}
          style={{
            padding: '0px 5px',
            border: '1px solid #E7F0F0',
            margin: '0 5px',
            borderRadius: '5px',
            background: '#E7F0F0',
            maxWidth: `${width}px`, // Set your desired max width
            whiteSpace: 'nowrap',
          }}
        >
          {item && (
            <MediumTypography
              label={item}
              sxProps={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            />
          )}
        </Box>
      ))}
      {remainingCount > 0 && (
        <Box
          style={{
            padding: '0px 5px',
            whiteSpace: 'nowrap',
            fontWeight: '600',
          }}
        >
          <MediumTypography
            label={`+${remainingCount}`}
            sxProps={{
              color: '#008C82',
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ChipDisplay;
