import { Box, TableCell, TableRow } from '@mui/material';
import { FC, useContext } from 'react';

import { ReactComponent as EditIcon } from '../../assets/images/highlight.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import ActionToolTip from '../../components/formlib/ActionToolTip';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { EmployeeListType } from '../../services/ifspClient/IfspApi';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';

export type EvaluationTableDataType = {
  evaluationId: number;
  evaluationName: string;
  evaluationDiscipline: string;
};
interface EvaluationTableRowType {
  data: EvaluationTableDataType;
  employeeList: EmployeeListType[];
  onEdit: (data: EvaluationTableDataType) => void;
  onDelete: (id: number) => void;
}

const EvaluationTableRow: FC<EvaluationTableRowType> = ({
  data,
  employeeList,
  onEdit,
  onDelete,
}) => {
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const handleEdit = () => {
    onEdit(data);
  };

  const handleDelete = () => {
    onDelete(data.evaluationId);
  };

  return (
    <TableRow>
      <TableCell>
        <TooltipTableCell
          value={
            employeeList.find((x) => x.id === data.evaluationName)?.name ?? ''
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <TooltipTableCell
          value={
            lookups?.discipline.find(
              (d) => d.code === data.evaluationDiscipline,
            )?.description ?? ''
          }
          visible={true}
          tooltipSxProps={{
            borderBottom: '0px',
            padding: '0px',
          }}
          textSxProps={{
            maxWidth: '200px',
          }}
        />
      </TableCell>
      <TableCell>
        <Box className="flex__">
          <Box
            component="div"
            className="cursor__pointer pointerEvents mr-md"
            onClick={handleEdit}
          >
            <ActionToolTip labelId="BlockTime.edit" defaultLabel="Edit">
              <EditIcon />
            </ActionToolTip>
          </Box>
          <Box
            component="div"
            className="cursor__pointer"
            onClick={handleDelete}
          >
            <ActionToolTip
              labelId="ConsentForms.listActionDeleteToolTipLabel"
              defaultLabel="Delete"
            >
              <DeleteIcon />
            </ActionToolTip>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default EvaluationTableRow;
