import dayjs from 'dayjs';
import { EICSForm, EICSTransitionLookupValues } from '../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export const getEICSLookupValues =
  async (): Promise<EICSTransitionLookupValues> => {
    return ApiResponseHandler(await ThomApiClient.getInstance().get(`lookup`));
  };

export const getTransitiondata = async (
  clientId: string | null | undefined,
): Promise<EICSForm> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `client/transition?clientId=${clientId}`,
    ),
  );
};

export const addTransitionData = async (
  params: EICSForm,
  clientId: string | null | undefined,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const url = `client/transition?timezone=${timezone}&clientId=${clientId}`;

  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};
