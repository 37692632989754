import dayjs from 'dayjs';
import {
  BusinessHourApiResponse,
  SchedulerEventType,
  AppointmentAvailabilityCheckResponse,
  SchedulerFormikType,
  EditSchedulerEventType,
  OptionType,
} from '../utils/type';
import { ApiMessage, ApiResponseHandler } from './ApiResponseHandler';
import ThomApiClient from './ThomApiClient';
import { getCurrentWeekSundayAsDate } from '../utils/utilities';

export const getSchedulerEventDetailsById = async (
  eventId: string,
): Promise<SchedulerEventType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `appointment?appointmentId=${eventId}`,
    ),
  );
};

export const getOwnerEventDetailsById = async (
  commonAppointmentId: string,
  employeeId: string,
): Promise<SchedulerEventType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `/appointment/multiple-attendees?commonAppointmentId=${commonAppointmentId}&employeeId=${employeeId}`,
    ),
  );
};

export const getBusinessHoursOfEmployee = async (
  employeeId: string,
  sundayDateinISO: string,
): Promise<BusinessHourApiResponse> => {
  const timezone = encodeURIComponent(
    dayjs(getCurrentWeekSundayAsDate()).format('Z'),
  );
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `appointment/business-hours?employeeId=${employeeId}&startDateTime=${sundayDateinISO}&timezone=${timezone}&zoneName=${zoneName}`,
    ),
  );
};

export const getAllSchedulerEvents = async (
  employeeId: string | null | undefined,
  start: string,
  end: string,
  calendarUnit: string | null,
  schedulerType: string,
  selectedAppointmentTypes: OptionType[],
  selectedClientFilter: string,
): Promise<[SchedulerEventType]> => {
  const timezone = encodeURIComponent(
    dayjs(getCurrentWeekSundayAsDate()).format('Z'),
  );
  let url = '';
  if(schedulerType === 'programschedule'){
    let filterApi:string = '';
    if(employeeId && employeeId !== null){
      filterApi = filterApi.concat(`&employeeId=${employeeId}`);
    }
    if(selectedAppointmentTypes && selectedAppointmentTypes.length > 0){
      filterApi = filterApi.concat(`&appointmentType=${selectedAppointmentTypes.map(item => item.id).join(',')}`);
    }
    if(selectedClientFilter && selectedClientFilter !== null){
      filterApi = filterApi.concat(`&withClients=${selectedClientFilter==='all'}`);
    }
    url = `appointment/program/list?start=${start}&end=${end}&timezone=${timezone}${filterApi}`;
  } else {
    const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    url = `appointment/list?employeeId=${employeeId}&start=${start}&end=${end}&timezone=${timezone}&calendarUnit=${calendarUnit}&zoneName=${zoneName}`;
  }
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      url,
    ),
  );
};

export const addAppointment = async (
  params: SchedulerFormikType,
): Promise<ApiMessage> => {
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<SchedulerFormikType, ApiMessage>(
      `appointment?timezone=${timezone}&zoneName=${zoneName}`,
      params,
    ),
  );
};

export const checkAvailability = async (
  params: SchedulerFormikType | EditSchedulerEventType,
  appointmentId: string,
): Promise<AppointmentAvailabilityCheckResponse> => {
  const timezone = encodeURIComponent(dayjs().format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (appointmentId == '') {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().post<
        SchedulerFormikType | EditSchedulerEventType,
        AppointmentAvailabilityCheckResponse
      >(`appointment/availability-check?timezone=${timezone}&zoneName=${zoneName}`, params),
    );
  } else {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().post<
        SchedulerFormikType | EditSchedulerEventType,
        AppointmentAvailabilityCheckResponse
      >(
        `appointment/availability-check?appointmentId=${appointmentId}&timezone=${timezone}&zoneName=${zoneName}`,
        params,
      ),
    );
  }
};

export const editAppointment = async (
  params: EditSchedulerEventType,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<EditSchedulerEventType, ApiMessage>(
      `appointment?timezone=${timezone}&zoneName=${zoneName}`,
      params,
    ),
  );
};

export const deleteAppointment = async (
  userId: string,
  attendeeId: string,
  appointmentId: string,
  deleteOption: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(
      `appointment?attendeeId=${attendeeId}&deleteOption=${deleteOption}&appointmentId=${appointmentId}&timezone=${timezone}&employeeId=${userId}`,
    ),
  );
};

export const deleteEvent = async (
  Id: string,
  deleteOption: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(
      `appointment?deleteOption=${deleteOption}&appointmentId=${Id}`,
    ),
  );
};
