import { OptionType } from '../../utils/type';

export const appointmentTypes: OptionType[] = [
  {
    id: 'appointmentType1',
    label: 'Intake Visit',
    color: 'var(--thom-green-primary)',
  },
  {
    id: 'appointmentTypeCombined',
    label: 'Assesments',
    color: 'var(--thom-pink)',
  },
  {
    id: 'appointmentType11',
    label: 'IFSP Meeting',
    color: 'var(--thom-dark-green)',
  },
  {
    id: 'appointmentType4',
    label: 'Home Visit',
    color: 'var(--thom-red)',
  },
  {
    id: 'appointmentType5',
    label: 'Center Visit',
    color: 'var(--thom-yellow)',
  },
  {
    id: 'appointmentType6',
    label: 'Parent Group',
    color: 'var(--thom-purple)',
  },
  {
    id: 'appointmentType7',
    label: 'Community Group',
    color: 'var(--thom-dark-blue)',
  },
  {
    id: 'appointmentType8',
    label: 'Segregated Group',
    color: 'var(--thom-brown)',
  },
  {
    id: 'appointmentType9',
    label: 'EI Only Group',
    color: 'var(--thom-orange)',
  },
  {
    id: 'appointmentType10',
    label: 'Other',
    color: 'var(--thom-violet)',
  },
];

export const appointmentTypesFilter: OptionType[] = [
  {
    id: 'appointmentType1',
    label: 'Intake Visit',
    color: 'var(--thom-green-primary)',
  },
  {
    id: 'appointmentType2',
    label: 'Assesments - Initial',
    color: 'var(--thom-pink)',
  },
  {
    id: 'appointmentType3',
    label: 'Assesments - Follow up',
    color: 'var(--thom-pink)',
  },
  {
    id: 'appointmentType11',
    label: 'IFSP Meeting',
    color: 'var(--thom-dark-green)',
  },
  {
    id: 'appointmentType4',
    label: 'Home Visit',
    color: 'var(--thom-red)',
  },
  {
    id: 'appointmentType5',
    label: 'Center Visit',
    color: 'var(--thom-yellow)',
  },
  {
    id: 'appointmentType6',
    label: 'Parent Group',
    color: 'var(--thom-purple)',
  },
  {
    id: 'appointmentType7',
    label: 'Community Group',
    color: 'var(--thom-dark-blue)',
  },
  {
    id: 'appointmentType8',
    label: 'Segregated Group',
    color: 'var(--thom-brown)',
  },
  {
    id: 'appointmentType9',
    label: 'EI Only Group',
    color: 'var(--thom-orange)',
  },
  {
    id: 'appointmentType10',
    label: 'Other',
    color: 'var(--thom-violet)',
  },
];

export const appointmentTypesForMultiClients: string[] = [
  'appointmentType6',
  'appointmentType7',
  'appointmentType8',
  'appointmentType9',
];
