import {
  AlertColor,
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import { ReactComponent as CheckMark } from '../../assets/images/CheckMark.svg';
import {
  Birth,
  ChildQuestionnaire,
  Client,
  Diagnosis,
  DiagonosisCode,
  EICSFormType,
  EicsFormDataType,
  EligibilitySummary,
  EvaluationDataInfo,
  FamilyQuestionnaire,
  Referral,
  VisitInfo,
} from './types';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  downloadEICSData,
  getEicsForm,
} from '../../services/eicsTBREvaluation/EicsTBREvaluation';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import dayjs from 'dayjs';
import Povertylevel from '../intake/IntakeJSON/FamilyHistory/Poverty.json';
import ProgramPanel from '../../components/forms/ProgramPanel';
import moment from 'moment';
import { EICS_TBR_FORM } from '../../routes/Routing';
import { useNavigate } from 'react-router';
import { employeeRoles } from '../../utils/type';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import ShareDownloadModal from '../../components/formlib/modal/ShareDownloadModal';
import { checkOffline } from '../../services/Offline';
import { formatName } from '../../utils/nameUtils';

interface TableData {
  name: string;
  role: string;
  email: string;
  phoneNumber: string;
}
interface ChildTableData {
  name: string;
  yes: string;
  no: string;
  unknown: string;
}

interface SummaryTable {
  name: string;
  eligible: string;
  notEligible: string;
}
interface TableDataResponse {
  id: string;
  questionlabelId: string;
  questionDefaultLabelId: string;
  value: string;
}

interface EligibilityTableDataResponse {
  id: string;
  questionlabelId: string;
  questionDefaultLabelId: string;
  value: boolean | null;
}

const summaryHeadCells: HeadCell<SummaryTable>[] = [
  {
    id: 'name',
    labelId: '',
    defaultLabelId: '',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'eligible',
    labelId: 'Eligible',
    defaultLabelId: 'Eligible',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'notEligible',
    labelId: 'Not Eligible',
    defaultLabelId: 'Not Eligible',
    numeric: false,
    requiredSorting: false,
  },
];
const teamHeadCells: HeadCell<TableData>[] = [
  {
    id: 'name',
    labelId: 'Name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'role',
    labelId: 'Role',
    defaultLabelId: 'Role',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'Email',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'Phone Number',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
];
const TableHeadCells: HeadCell<ChildTableData>[] = [
  {
    id: 'name',
    labelId: 'Child Characteristics',
    defaultLabelId: 'Child Characteristics',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'yes',
    labelId: 'Yes',
    defaultLabelId: 'Yes',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'no',
    labelId: 'No',
    defaultLabelId: 'No',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'unknown',
    labelId: 'Unknown',
    defaultLabelId: 'Unknown',
    numeric: false,
    requiredSorting: false,
  },
];
const familyHeadCells: HeadCell<ChildTableData>[] = [
  {
    id: 'name',
    labelId: 'Family Characteristics',
    defaultLabelId: 'Family Characteristics',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'yes',
    labelId: 'Yes',
    defaultLabelId: 'Yes',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'no',
    labelId: 'No',
    defaultLabelId: 'No',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'unknown',
    labelId: 'Unknown',
    defaultLabelId: 'Unknown',
    numeric: false,
    requiredSorting: false,
  },
];
const ChildData: TableDataResponse[] = [
  {
    id: 'childInNICU',
    questionlabelId: 'Was the Child in the NICU?',
    questionDefaultLabelId: 'Was the Child in the NICU?',
    value: '',
  },
  {
    id: 'childHospitalized',
    questionlabelId:
      'Was the child hospitalized for more than 25 days during a 6 month period, excluding the initial birth admission to a NICU?',
    questionDefaultLabelId:
      'Was the child hospitalized for more than 25 days during a 6 month period, excluding the initial birth admission to a NICU?',
    value: '',
  },
  {
    id: 'childDiagnosedAtBirth',
    questionlabelId:
      'Was Child Diagnosed at Birth with Intrauterine Growth Restriction(IUGR) or Small for the Gestational Age (SGA)?',
    questionDefaultLabelId:
      'Was Child Diagnosed at Birth with Intrauterine Growth Restriction(IUGR) or Small for the Gestational Age (SGA)?',
    value: '',
  },
  {
    id: 'childWeightConstraints',
    questionlabelId: `Is child's weight, weight for height, or height for age less than the 5th percentile (Small size) ?`,
    questionDefaultLabelId: `Is child's weight, weight for height, or height for age less than the 5th percentile (Small size) ?`,
    value: '',
  },
  {
    id: 'childChronicFeeding',
    questionlabelId: 'Chronic Feeding Difficulties?',
    questionDefaultLabelId: 'Chronic Feeding Difficulties?',
    value: '',
  },
  {
    id: 'childBloodLevel',
    questionlabelId: 'Blood Lead Levels Measured 5 Micrograms/dl?',
    questionDefaultLabelId: 'Blood Lead Levels Measured 5 Micrograms/dl?',
    value: '',
  },

  {
    id: 'childInsecureDifficulties',
    questionlabelId:
      'Difficulty with insecure attachment/interactional difficulties?',
    questionDefaultLabelId:
      'Difficulty with insecure attachment/interactional difficulties?',
    value: '',
  },
  {
    id: 'childNervousSystem',
    questionlabelId: 'Suspected Central Nervous System Abnormality?',
    questionDefaultLabelId: 'Suspected Central Nervous System Abnormality?',
    value: '',
  },
  {
    id: 'childMultipleTrauma',
    questionlabelId: 'Multiple trauma/losses experienced by child?',
    questionDefaultLabelId: 'Multiple trauma/losses experienced by child?',
    value: '',
  },
  {
    id: 'diagnosisOfSubstanceExposedNewborn',
    questionlabelId: 'Diagnosis of Substance Exposed Newborn (SEN)?',
    questionDefaultLabelId: 'Diagnosis of Substance Exposed Newborn (SEN)?',
    value: '',
  },
  {
    id: 'siblingWithSEN',
    questionlabelId:
      'Sibling with a diagnosis of Substance Exposed Newborn (SEN)',
    questionDefaultLabelId:
      'Sibling with a diagnosis of Substance Exposed Newborn (SEN)',
    value: '',
  },
];
const FamilyData: TableDataResponse[] = [
  {
    id: 'motherAge',
    questionlabelId:
      'Mother less than 17 years of age and/or with 3 or more children before 20 (Birth Mother Parity) ?',
    questionDefaultLabelId:
      'Mother less than 17 years of age and/or with 3 or more children before 20 (Birth Mother Parity) ? ',
    value: '',
  },
  {
    id: 'parentalChronicIllness',
    questionlabelId:
      'Parental Chronic Illness or Disability that Affects Caregiving Ability',
    questionDefaultLabelId:
      'Parental Chronic Illness or Disability that Affects Caregiving Ability',
    value: '',
  },
  {
    id: 'lackingAdequate',
    questionlabelId: 'Lacking Adequate Food or Clothing',
    questionDefaultLabelId: 'Lacking Adequate Food or Clothing',
    value: '',
  },
  {
    id: 'protectiveServiceInvestigation',
    questionlabelId: 'Open/Confirmed Protective Service Investigation',
    questionDefaultLabelId: 'Open/Confirmed Protective Service Investigation',
    value: '',
  },
  {
    id: 'substanceAffectingChild',
    questionlabelId: 'Substance Abuse Affecting Child',
    questionDefaultLabelId: 'Substance Abuse Affecting Child',
    value: '',
  },
  {
    id: 'affectingChild',
    questionlabelId: 'Abuse (physical or emotional) Affecting Child',
    questionDefaultLabelId: 'Abuse (physical or emotional) Affecting Child',
    value: '',
  },
  {
    id: 'homelessness',
    questionlabelId: 'Homelessness',
    questionDefaultLabelId: 'Homelessness',
    value: '',
  },
  {
    id: 'homelessnessInPast',
    questionlabelId: 'Homeless in the past 12 months',
    questionDefaultLabelId: 'Homeless in the past 12 months',
    value: '',
  },
];
const summaryData: EligibilityTableDataResponse[] = [
  {
    id: 'medicalDiagnosisEligibility',
    questionlabelId: 'Eligibility diagnosis',
    questionDefaultLabelId: 'Eligibility diagnosis',
    value: null,
  },
  {
    id: 'bdiEligibility',
    questionlabelId: 'Eligibility based on bdi',
    questionDefaultLabelId: 'Eligibility based on bdi',
    value: null,
  },
  {
    id: 'riskFactorEligibility',
    questionlabelId: 'Eligibility based on risk factors',
    questionDefaultLabelId: 'Eligibility based on risk factors',
    value: null,
  },
  {
    id: 'isClinicalJudgementEligible',
    questionlabelId: 'Eligibility based on clinical judgement',
    questionDefaultLabelId: 'Eligibility based on clinical judgement',
    value: null,
  },
];

const EicsTBRReferralToEvaluation = () => {
  const navigation = useNavigate();
  const [eicsForm, setEicsForm] = useState<EicsFormDataType | null>(null);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [childData, setChildData] = useState<TableDataResponse[]>([]);
  const [familyData, setFamilyData] = useState<TableDataResponse[]>([]);
  const [eligibilityData, setEligibilityData] = useState<
    EligibilityTableDataResponse[]
  >([]);
  const [shareModal, setShareModal] = useState<boolean>(false);

  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const clientId = localStorage.getItem('ClientId');

  const [offlineMode, setOffline] = useState(false);

  useLayoutEffect(() => {
    const getOfflineMode = async () => {
      const offlineFlag = await checkOffline();
      setOffline(offlineFlag);
    };
    getOfflineMode();
  });

  const updateChildDataValues = (childCharacteristics: ChildQuestionnaire) => {
    const updatedChildData = ChildData.map((item) => {
      const characteristicValue =
        childCharacteristics[item.id as keyof ChildQuestionnaire];
      if (characteristicValue !== undefined && characteristicValue !== null) {
        item.value = characteristicValue;
      }
      return item;
    });

    setChildData(updatedChildData);
  };
  const updateFamilyDataValues = (
    familyCharacteristics: FamilyQuestionnaire,
  ) => {
    const updatedFamilyData = FamilyData.map((item) => {
      const characteristicValue =
        familyCharacteristics[item.id as keyof FamilyQuestionnaire];
      if (characteristicValue !== undefined && characteristicValue !== null) {
        item.value = characteristicValue;
      }
      return item;
    });

    setFamilyData(updatedFamilyData);
  };
  const updateEligibilityDataValues = (
    eligibiltyDeterminationSummaryData: EligibilitySummary,
  ) => {
    const updatedEligibilityData = summaryData.map((item) => {
      const characteristicValue =
        eligibiltyDeterminationSummaryData[item.id as keyof EligibilitySummary];

      if (characteristicValue !== undefined) {
        if (
          typeof characteristicValue === 'boolean' ||
          characteristicValue === null
        ) {
          item.value = characteristicValue;
        }
      }

      return item;
    });

    setEligibilityData(updatedEligibilityData);
  };
  useEffect(() => {
    if (eicsForm) {
      updateChildDataValues(eicsForm?.childCharacteristics);
      updateFamilyDataValues(eicsForm.familyCharacteristics);
      updateEligibilityDataValues(eicsForm.eligibilityDeterminationSummary);
    }
  }, [eicsForm]);
  const getPovertyLevel = (value: string) => {
    const foundItem = Povertylevel.find((item) => item.value === value);
    return foundItem?.defaultLabel;
  };
  const getEicsData = () => {
    toggleLoader(true);
    if (clientId === null) {
      return;
    }

    getEicsForm(clientId)
      .then((response: EICSFormType) => {
        toggleLoader(false);
        setEicsForm({
          parents: response.parents,
          intakeFamily: response.intakeFamily.parent,
          diagnosisCode: {
            code: response.diagnosisCode.code,
            description: response.diagnosisCode.description,
            isValidForInsurance: response.diagnosisCode.isValidForInsurance,
            isUnderlined: response.diagnosisCode.isUnderlined,
            date: response.diagnosisCode.date,
            id: response.diagnosisCode.id,
          },
          dischargeDate: response.dischargeDate,
          dischargeReason: response.dischargeReason,
          client: response.client,
          program: response.program,
          referralContact: response.referralContact,
          scores: response.scores,
          evaluators: response.evaluators,
          childCharacteristics: {
            childInNICU: response.intakePregnancy.childInNICU,
            childBloodLevel: response.intakeCurrentHealth.childBloodLevel,
            childHospitalized: response.intakeCurrentHealth.childHospitalized,
            childDiagnosedAtBirth:
              response.intakeCurrentHealth.childDiagnosedAtBirth,
            childWeightConstraints: response.intakeCurrentHealth.smallSize,
            childChronicFeeding:
              response.intakeCurrentHealth.childChronicFeeding,
            childInsecureDifficulties:
              response.intakeCurrentHealth.childInsecureDifficulties,
            childNervousSystem: response.intakeCurrentHealth.childNervousSystem,
            childMultipleTrauma:
              response.intakeCurrentHealth.childMultipleTrauma,
            diagnosisOfSubstanceExposedNewborn:
              response.intakePregnancy.diagnosisOfSubstanceExposedNewborn,
            siblingWithSEN: response.intakeFamily.siblingWithSEN,
          },
          familyCharacteristics: {
            affectingChild: response.intakeFamily.affectingChild,
            protectiveServiceInvestigation:
              response.intakeFamily.protectiveServiceInvestigation,
            motherAge: response.intakeFamily.birthMotherParity,
            parentalChronicIllness:
              response.intakeFamily.parentalChronicIllness,
            lackingAdequate: response.intakeFamily.lackingAdequate,
            substanceAffectingChild: response.intakeFamily.abuseAffectingChild,
            homelessness: response.intakeFamily.homelessness,
            homelessnessInPast: response.intakeFamily.homelessnessInPast,
          },
          eligibilityDeterminationSummary:
            response.eligibilityDeterminationSummary,
          visitInfo: {
            childAdopted: response.intakeClient.childAdopted,
            childBorn: response.intakeClient.childBorn,
            primarySpokenLanguage: response.intakeFamily.primarySpokenLanguage,
            primaryWrittenLanguage:
              response.intakeFamily.primaryWrittenLanguage,
            ethnicity: response.intakeFamily.ethnicity,
            race: response.intakeFamily.race,
            primarySpokenLanguageOther:
              response.intakeFamily.primarySpokenLanguageOther,
            primaryWrittenLanguageOther:
              response.intakeFamily.primaryWrittenLanguageOther,
          },
          diagnosis: {
            diagnosisCode: '',
            gestationalAge: response.intakePregnancy.gestationalAge,
          },
          bdiDate: '',
          birth: {
            weightInGrams: response.intakePregnancy.weightInGrams,
            weightInLbs: response.intakePregnancy.weightInLbs,
            weightInOz: response.intakePregnancy.weightInOz,
          },
          evaluation: {
            pets: response.intakeFamily.pets,
            smoking: response.intakeFamily.smoking,
            unsecuredWeapons: response.intakeFamily.unsecuredWeapons,
            dateOfEvaluationScheduled: response.client.dateOfEvaluation,
            dayOfEvaluationScheduled:
              response.client.dateOfEvaluation !== null &&
              response.client.dateOfEvaluation !== ''
                ? dayjs(response.client.dateOfEvaluation).format('dddd')
                : '-',
          },
          povertyLevel: response.client.povertyLevel
            ? response.client.povertyLevel
            : null,
        });
      })
      .catch((error) => {
        toggleLoader(false);
        setToastrVariable('error');
        setOpen(true);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('EicsForm.addToastError');
          setToastrDefaultMessage('Failed to get Eics data');
        }
      });
  };

  useEffect(() => {
    getEicsData();
  }, []);

  const getLanguageDescription = (code: string) => {
    if (lookups && lookups.language) {
      const language = lookups.language.find((lang) => lang.code === code);
      return language ? language.description : '-';
    }
    return '-';
  };

  const getRaceDescription = (code: string) => {
    if (lookups && lookups.race) {
      const race = lookups.race.find((r) => r.code === code);
      return race ? race.description : '-';
    }
    return '-';
  };

  const getEthnicityDescription = (code: string) => {
    if (lookups && lookups.ethnicity) {
      const ethnicity = lookups.ethnicity.find((eth) => eth.code === code);
      return ethnicity ? ethnicity.description : '-';
    }
    return '-';
  };

  const getInitialVisit = (visitInfo: VisitInfo) => {
    const initialVisit: DataJsonType[] = [];

    const spokenLanguages = getLanguageDescription(
      visitInfo.primarySpokenLanguage,
    );

    const filteredSpokenLanguages =
      spokenLanguages === 'Other' && visitInfo.primarySpokenLanguageOther
        ? `${spokenLanguages} - ${visitInfo.primarySpokenLanguageOther}`
        : spokenLanguages;

    const writtenLanguages = getLanguageDescription(
      visitInfo.primaryWrittenLanguage,
    );

    const filteredWrittenLanguages =
      writtenLanguages === 'Other' && visitInfo.primaryWrittenLanguageOther
        ? `${writtenLanguages} - ${visitInfo.primaryWrittenLanguageOther}`
        : writtenLanguages;

    initialVisit.push({
      id: 'primarySpokenLanguage',
      labelid: 'EICS.PrimaryLanguageSpoken',
      defaultLabel: 'Primary Spoken Language at Home:',
      value: filteredSpokenLanguages,
    });
    initialVisit.push({
      id: 'primaryWrittenLanguage',
      labelid: 'EICS.PrimaryLanguageWritten',
      defaultLabel: 'Primary Written Language at Home:',
      value: filteredWrittenLanguages,
    });
    initialVisit.push({
      id: 'ethnicity',
      labelid: 'ethnicity',
      defaultLabel: 'Ethnicity:',
      value:
        visitInfo.ethnicity !== null
          ? getEthnicityDescription(visitInfo.ethnicity)
          : '-',
    });
    const races =
      visitInfo.race.split(',').map(getRaceDescription).toString() ?? '-';
    initialVisit.push({
      id: 'race',
      labelid: 'EICS.ChildsAge',
      defaultLabel: "Child's Race:",
      value: races,
    });
    initialVisit.push({
      id: 'adopted',
      labelid: 'EICS.ChildAdopted',
      defaultLabel: 'Is this Child adopted?:',
      value: `${
        visitInfo.childAdopted !== null ? visitInfo.childAdopted : '-'
      }`,
    });
    initialVisit.push({
      id: 'bornInMA',
      labelid: 'EICS.ChildBornMA',
      defaultLabel: 'Was this Child born in MA?:',
      value: `${visitInfo.childBorn !== null ? visitInfo.childBorn : '-'}`,
    });
    return initialVisit;
  };

  const transformedArrayDischareReason = lookups?.referralDischargeReason.map(
    (item) => {
      const label = `${item.description}`;

      return {
        id: item.code,
        label,
      };
    },
  );

  const getDischargeReason = (reason: string) => {
    let foundLabel: string | undefined;

    transformedArrayDischareReason?.forEach((data) => {
      if (data.id == reason) {
        foundLabel = data.label;
      }
    });

    return foundLabel;
  };

  const getReferralInfo = (referral: Referral) => {
    const referralInfo: DataJsonType[] = [];
    referralInfo.push({
      id: 'name',
      labelid: 'EICS.Name',
      defaultLabel: 'Name:',
      value: `${
        referral.referralContactName !== null
          ? referral.referralContactName
          : '-'
      }`,
    });
    referralInfo.push({
      id: 'job',
      labelid: 'EICS.JobTitle',
      defaultLabel: 'Job Title:',
      value: `${
        referral.referralContactJobTitle !== null
          ? referral.referralContactJobTitle
          : '-'
      }`,
    });
    referralInfo.push({
      id: 'referralContactDate',
      labelid: 'EICS.ReferralContactDate',
      defaultLabel: 'Referral Contact Date:',
      value: `${
        referral.referralContactDate !== null
          ? referral.referralContactDate
          : '-'
      }`,
    });
    referralInfo.push({
      id: 'referralSource',
      labelid: 'EICS.ReferralSource',
      defaultLabel: 'Referral Source:',
      value: `${
        referral.referralSource !== null ? referral.referralSource : '-'
      }`,
    });
    referralInfo.push({
      id: 'referralReason',
      labelid: 'EICS.ReferralReason',
      defaultLabel: 'Referral Reason:',
      value: `${
        referral.referralReason !== null ? referral.referralReason : '-'
      }`,
    });
    referralInfo.push({
      id: 'email',
      labelid: 'EICS.Email',
      defaultLabel: 'Email:',
      value: `${
        referral.referralContactEmail !== null
          ? referral.referralContactEmail
          : '-'
      }`,
    });
    referralInfo.push({
      id: 'phone',
      labelid: 'EICS.Phone',
      defaultLabel: 'Phone:',
      value: `${
        referral.referralContactPhone !== null
          ? referral.referralContactPhone
          : '-'
      }`,
    });
    referralInfo.push({
      id: 'fax',
      labelid: 'EICS.Fax',
      defaultLabel: 'Fax:',
      value: `${
        referral.referralContactFax !== null ? referral.referralContactFax : '-'
      }`,
    });
    referralInfo.push({
      id: 'notes',
      labelid: 'EICS.Notes',
      defaultLabel: 'Notes:',
      value: `${referral.notes !== null ? referral.notes : '-'}`,
    });
    return referralInfo;
  };
  const getDiagnosisInfo = (
    diagnosis: Diagnosis,
    diagnosisCode: DiagonosisCode,
  ) => {
    const diagnosisInfo: DataJsonType[] = [];
    diagnosisInfo.push({
      id: 'diagnosisCode',
      labelid: 'EICS.DiagonosisCode',
      defaultLabel: 'Diagnosis Code:',
      value: `${
        diagnosisCode.code !== null && diagnosisCode.code !== ''
          ? `${diagnosisCode.code} - ${diagnosisCode.description}`
          : '-'
      }`,
      sxProps: {
        textDecoration: diagnosisCode.isUnderlined ? 'underline' : 'none',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    });
    diagnosisInfo.push({
      id: 'gestationalAge',
      labelid: 'EICS.GestationalAge',
      defaultLabel: 'Gestational Age:',
      value: `${
        diagnosis.gestationalAge !== null && diagnosis.gestationalAge !== ''
          ? `${diagnosis.gestationalAge} Weeks`
          : '-'
      }`,
    });
    return diagnosisInfo;
  };

  const getWeightInfo = (weight: Birth) => {
    const weightInfo: DataJsonType[] = [];
    if (
      weight.weightInLbs == '' &&
      weight.weightInOz == '' &&
      weight.weightInGrams == ''
    ) {
      weightInfo.push({
        id: 'lbs',
        labelid: 'EICS.LBS',
        defaultLabel: 'lbs:',
        value: `${weight.weightInLbs !== null ? weight.weightInLbs : '-'}`,
      });
      weightInfo.push({
        id: 'oz',
        labelid: 'EICS.OZ',
        defaultLabel: 'Oz:',
        value: `${weight.weightInOz !== null ? weight.weightInOz : '-'}`,
      });
      weightInfo.push({
        id: 'kg',
        labelid: 'EICS.KG',
        defaultLabel: 'Grams:',
        value: `${weight.weightInGrams !== null ? weight.weightInGrams : '-'}`,
      });
    } else if (weight.weightInGrams !== '') {
      weightInfo.push({
        id: 'kg',
        labelid: 'EICS.KG',
        defaultLabel: 'Grams:',
        value: `${weight.weightInGrams !== null ? weight.weightInGrams : '-'}`,
      });
    } else {
      weightInfo.push({
        id: 'lbs',
        labelid: 'EICS.LBS',
        defaultLabel: 'lbs:',
        value: `${weight.weightInLbs !== null ? weight.weightInLbs : '-'}`,
      });
      weightInfo.push({
        id: 'oz',
        labelid: 'EICS.OZ',
        defaultLabel: 'Oz:',
        value: `${weight.weightInOz !== null ? weight.weightInOz : '-'}`,
      });
    }
    return weightInfo;
  };
  const getBDIValues = () => {
    const bdiValues: DataJsonType[] = [];

    bdiValues.push({
      id: 'toolsUsed',
      labelid: 'toolsUsedText',
      defaultLabel: 'Tools Used:',
      value: 'Battelle Development Inventory',
    });
    return bdiValues;
  };

  const getEvaluationValues = (data: EvaluationDataInfo, data2: Client) => {
    const evaluationValues: DataJsonType[] = [];

    evaluationValues.push({
      id: 'does',
      labelid: 'EICS.DateOfEvaluationScheduled',
      defaultLabel: 'Date of Evaluation Scheduled:',
      value: `${
        data2.dateOfEvaluation !== null
          ? dayjs(data2.dateOfEvaluation).format('MM/DD/YYYY')
          : '-'
      }`,
    });
    evaluationValues.push({
      id: 'doe',
      labelid: 'EICS.DayOfEvaluationScheduled',
      defaultLabel: 'Day of Evaluation Scheduled:',
      value: `${
        data.dayOfEvaluationScheduled !== null
          ? data.dayOfEvaluationScheduled
          : '-'
      }`,
    });
    return evaluationValues;
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleDownload = () => {
    const localTimezone = dayjs.tz.guess();
    toggleLoader(true);
    if (clientId) {
      downloadEICSData(clientId, localTimezone)
        .then((response) => {
          const blob = new Blob([response], { type: 'application/pdf' });

          // Create a Blob URL
          const blobUrl = URL.createObjectURL(blob);

          // Open the Blob URL in a new tab
          const anchor = document.createElement('a');
          anchor.href = blobUrl;
          anchor.target = '_blank';
          anchor.download = `EICS Summary.pdf`; // Set desired file name here

          // Append anchor to document body
          document.body.appendChild(anchor);

          // Trigger anchor click
          anchor.click();

          window.open(blobUrl, '_blank');

          // Clean up after use
          URL.revokeObjectURL(blobUrl);
          document.body.removeChild(anchor);

          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('ConsentForms.downloadErrorMsg');
            setToastrDefaultMessage('Failed to download');
          }
        });
    }
  };

  return (
    <Box component="main">
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {eicsForm && (
        <Box component="section">
          <Box component="section">
            <Box display="flex">
              <Grid container>
                <Grid item xs={8} lg={8}>
                  <Box className="display-flex-center">
                    <CustomBackArrow
                      onClick={() => navigation(EICS_TBR_FORM)}
                    />
                    <Box className="addnewclient ml-md">
                      <TitleText
                        labelid="eicsTBR.TitleText"
                        defaultlabel="EICS / TBR -  Referral to Evaluation Data Summary"
                        className="pt-lg pb-lg"
                      />
                    </Box>
                  </Box>
                </Grid>
                {!offlineMode && (
                  <Grid item xs={4} lg={4}>
                    <Box
                      component="div"
                      display="flex"
                      className="justifyContent-FlexEnd alignItems-Center pt-lg"
                    >
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        labelId="ConsentForms.listActionDownloadToolTipLabel"
                        defaultLabelId="Download"
                        variantType="contained"
                        onClick={handleDownload}
                      />
                      <ButtonComponent
                        className="btn-primary btn-submit ml-xs"
                        labelId="ConsentForms.listActionShareToolTipLabel"
                        defaultLabelId="Share"
                        variantType="contained"
                        onClick={() => setShareModal(true)}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box component="div">
              <Card className="p-lg">
                <ProgramPanel
                  program={eicsForm?.program}
                  client={{
                    id: eicsForm.client.id,
                    clientId: eicsForm.client.clientId,
                    name: `${formatName(
                      eicsForm.client.firstName,
                      eicsForm.client.middleName,
                      eicsForm.client.lastName,
                      eicsForm.client.suffix,
                    )}`,
                    firstName: eicsForm.client.firstName,
                    lastName: eicsForm.client.lastName,
                    sex: eicsForm.client.sex,
                    dob: eicsForm.client.dob,
                    dphId: eicsForm.client.dphId,
                    referralBeginDate: eicsForm.client.referralBeginDate,
                    dateOfEvaluation:
                      eicsForm.client.dateOfEvaluation &&
                      eicsForm.client.dateOfEvaluation !== null
                        ? moment(eicsForm.client.dateOfEvaluation)
                            .local()
                            .format('MM/DD/YYYY')
                        : '-',
                    serviceCoordinatorId: eicsForm.client.serviceCoordinator.id,
                    serviceCoordinatorName:
                      eicsForm.client.serviceCoordinator.name,
                    serviceCoordinatorPhone:
                      eicsForm.client.serviceCoordinator.phone,
                    serviceCoordinatorPhoneExtension: '',
                    primarySpokenLanguage: '',
                    primaryWrittenLanguage: '',
                    race: '',
                    ethnicity: '',
                    pediatricianName: '',
                    pediatricianPhoneNumber: '',
                    intakeFacilitator: eicsForm.client.intakeFacilitator,
                    intakeFacilitatorId: '',
                  }}
                />
              </Card>
            </Box>
          </Box>
          <Box component="section" className="pt-lg pb-lg">
            <Card className="p-lg">
              <MediumTypography
                labelid="Guardian"
                defaultlabel="Guardian"
                className="mainText-xlg pb-md"
              />
              {eicsForm.parents.map((data) => (
                <Grid container>
                  <Grid item>
                    <Box className="flex__">
                      <MediumTypography
                        labelid={'Name:'}
                        defaultlabel={'Name:'}
                        sxProps={{ color: '#97A6A5' }}
                      />
                      <Tooltip
                        title={
                          data.name && data.name.length > 50 ? data.name : ''
                        }
                        arrow={true}
                        followCursor
                      >
                        <Box>
                          <MediumTypography
                            className="pr-lg pl-sm"
                            sxProps={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width: data.name.length > 50 ? '300px' : 'auto',
                              color: '#2A4241',
                            }}
                            label={
                              data.name === '' || data.name === null
                                ? '-'
                                : data.name
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="flex__">
                      <MediumTypography
                        labelid={'addressTextEics'}
                        defaultlabel={'Address:'}
                        sxProps={{ color: '#97A6A5' }}
                      />
                      <Tooltip
                        title={
                          data.address && data.address.length > 50
                            ? data.address
                            : ''
                        }
                        arrow={true}
                        followCursor
                      >
                        <Box>
                          <MediumTypography
                            className="pr-lg pl-sm"
                            sxProps={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width:
                                data.address.length > 50 ? '300px' : 'auto',
                              color: '#2A4241',
                            }}
                            label={
                              data.address === '' || data.address === null
                                ? '-'
                                : data.address
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="flex__">
                      <MediumTypography
                        labelid={'EICS.mobilePhoneNumber'}
                        defaultlabel={'Mobile Number:'}
                        sxProps={{ color: '#97A6A5' }}
                      />
                      <Tooltip
                        title={
                          data.phoneNumber && data.phoneNumber.length > 50
                            ? data.phoneNumber
                            : ''
                        }
                        arrow={true}
                        followCursor
                      >
                        <Box>
                          <MediumTypography
                            className="pr-lg pl-sm"
                            sxProps={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width:
                                data.phoneNumber.length > 50 ? '300px' : 'auto',
                              color: '#2A4241',
                            }}
                            label={
                              data.phoneNumber === '' ||
                              data.phoneNumber === null
                                ? '-'
                                : data.phoneNumber
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>
                  <Grid item>
                    <Box className="flex__">
                      <MediumTypography
                        labelid={'EICS.relationShipToChild'}
                        defaultlabel={'Guardian Type:'}
                        sxProps={{ color: '#97A6A5' }}
                      />
                      <Tooltip
                        title={
                          data.relationshipToClient &&
                          data.relationshipToClient.length > 4
                            ? lookups?.guardianType?.find(
                                (g) =>
                                  g.code.toUpperCase() ===
                                  data.relationshipToClient.toUpperCase(),
                              )?.description || ''
                            : ''
                        }
                        arrow={true}
                        followCursor
                      >
                        <Box>
                          <MediumTypography
                            className="pr-lg pl-sm"
                            sxProps={{
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              width:
                                data.relationshipToClient.length > 4
                                  ? '300px'
                                  : 'auto',
                              color: '#2A4241',
                            }}
                            label={
                              data.relationshipToClient === '' ||
                              data.relationshipToClient === null
                                ? '-'
                                : lookups?.guardianType?.find(
                                    (g) =>
                                      g.code.toUpperCase() ===
                                      data.relationshipToClient.toUpperCase(),
                                  )?.description || '-'
                            }
                          />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              ))}
              <Box className="horizontalLine" />
              <MediumTypography
                labelid="Evaluation Information"
                defaultlabel="Evaluation Information"
                className="mainText-xlg pb-md"
              />
              <KeyValueComponent
                data={getEvaluationValues(eicsForm.evaluation, eicsForm.client)}
              />
              <Box className="horizontalLine" />
              <Box className="pb-md">
                <TableContainer>
                  <Table>
                    <TableHeader
                      className="listDataTableHead"
                      headerNames={teamHeadCells}
                      checkBoxRequired={false}
                    />
                    <TableBody className="tableRowcss">
                      {eicsForm.evaluators !== null &&
                        eicsForm.evaluators.map((team, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                            }}
                          >
                            <TableCell>
                              <MediumTypography
                                label={
                                  team.name !== null && team.name !== ''
                                    ? team.name
                                    : '-'
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <MediumTypography
                                label={
                                  team.roles
                                    ? team.roles
                                        .map((roleId) => {
                                          const matchingRole =
                                            employeeRoles.find(
                                              (role) => role.id === roleId,
                                            );
                                          return matchingRole
                                            ? matchingRole.label
                                            : null;
                                        })
                                        .filter((label) => label !== null)
                                        .join(', ')
                                    : '-'
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <MediumTypography
                                label={
                                  team.email !== null && team.email !== ''
                                    ? team.email
                                    : '-'
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <MediumTypography
                                label={
                                  team.phone !== null && team.phone !== ''
                                    ? team.phone
                                    : '-'
                                }
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                  {eicsForm.evaluators !== null &&
                    eicsForm.evaluators.length === 0 && (
                      <Box component="div" className="width__100">
                        <MediumTypography
                          textstyle={'center'}
                          labelid="IFSP.emptyMessage"
                          defaultlabel="No data added yet"
                          className="p-sm"
                        />
                      </Box>
                    )}
                </TableContainer>
              </Box>
              <MediumTypography
                labelid="Referral Contact Info"
                defaultlabel="Referral Contact Info"
                className="mainText-xlg pb-md"
              />
              <KeyValueComponent
                data={getReferralInfo(eicsForm.referralContact)}
              />
              <Box className="horizontalLine" />
              <MediumTypography
                labelid="Initial Visit Information"
                defaultlabel="Initial Visit Information"
                className="mainText-xlg pb-md"
              />
              <KeyValueComponent data={getInitialVisit(eicsForm.visitInfo)} />
              <Box className="horizontalLine" />
              <MediumTypography
                labelid="Diagnosis"
                defaultlabel="Diagnosis"
                className="mainText-xlg pb-md"
              />
              <KeyValueComponent
                data={getDiagnosisInfo(
                  eicsForm.diagnosis,
                  eicsForm.diagnosisCode,
                )}
              />
              <MediumTypography
                labelid="Birth Weight"
                defaultlabel="Birth Weight"
                className="mainText-md pt-md pb-xs"
              />
              <KeyValueComponent data={getWeightInfo(eicsForm.birth)} />
              <Box className="mt-md mb-md">
                <TableContainer>
                  <Table>
                    <TableHeader
                      className="listDataTableHead"
                      headerNames={TableHeadCells}
                      checkBoxRequired={false}
                    />
                    <TableBody className="tableRowcss">
                      {childData !== null &&
                        childData.map((child, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              backgroundColor:
                                index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                            }}
                          >
                            <TableCell>
                              <Box width={'500px'}>
                                <MediumTypography
                                  label={child.questionlabelId}
                                />
                              </Box>
                            </TableCell>
                            <TableCell>
                              {child.value === 'Yes' && <CheckMark />}
                            </TableCell>
                            <TableCell>
                              {child.value === 'No' && <CheckMark />}
                            </TableCell>
                            <TableCell>
                              {child.value === 'Unknown' && <CheckMark />}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box className="mb-lg">
                <TableContainer>
                  <Table>
                    <TableHeader
                      className="listDataTableHead"
                      headerNames={familyHeadCells}
                      checkBoxRequired={false}
                    />
                    <TableBody className="tableRowcss">
                      {familyData !== null &&
                        familyData.map((data, index) => (
                          <>
                            <TableRow
                              key={index}
                              sx={{
                                backgroundColor:
                                  index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                              }}
                            >
                              <TableCell>
                                <Box width={'500px'}>
                                  <MediumTypography
                                    label={data.questionlabelId}
                                  />
                                </Box>
                              </TableCell>
                              <TableCell>
                                {data.value === 'Yes' && <CheckMark />}
                              </TableCell>
                              <TableCell>
                                {data.value === 'No' && <CheckMark />}
                              </TableCell>
                              <TableCell>
                                {data.value === 'Unknown' && <CheckMark />}
                              </TableCell>
                            </TableRow>
                            {index === 0 && (
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  className="pt-md pb-md pl-lg"
                                  sx={{ backgroundColor: '#CFDEDE' }}
                                >
                                  <MediumTypography
                                    labelid="EICS.BiologicalYearsEducationYears"
                                    defaultlabel="Biological Mother’s Years of Formal Education:"
                                    className="pb-xs"
                                  />
                                  <MediumTypography
                                    labelid="EICS.ExistsInFamily"
                                    defaultlabel="Do any of the Following Conditions Exist in the Biological or Primary Family?"
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <MediumTypography
                labelid="EICS.BDI2Info"
                defaultlabel="BDI &  DEVELOPMENTAL INFORMATION"
                className="mainText-xlg pb-xs"
              />
              <MediumTypography
                labelid="EICS.RawScoresForItems"
                defaultlabel="Raw scores for all items with an asterisk needed – plus the DQ"
                className="pb-sm"
                textColor="#97A6A5"
              />
              <Box className="flex__ pb-xs">
                <MediumTypography
                  labelid="EICS.DateOfBDI"
                  defaultlabel="Date BDI first used for this evaluation:"
                  className="pr-xs"
                />
                <MediumTypography
                  label={
                    eicsForm.client.assessmentDate
                      ? moment(eicsForm.client.assessmentDate)
                          .local()
                          .format('MM/DD/YYYY')
                      : '-'
                  }
                />
              </Box>
              <KeyValueComponent data={getBDIValues()} />
              {eicsForm.scores.map((data, index) => (
                <Box className="rowContainer p-none" key={index}>
                  <Grid container>
                    <Grid item xs={2} className="pt-sm">
                      <MediumTypography
                        labelid={data.domain ?? undefined}
                        sxProps={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#2A4241',
                        }}
                      />
                    </Grid>
                    <Grid item xs={3} className="pt-sm">
                      <MediumTypography
                        labelid={`Developmental Quotient (DQ): ${
                          data.developmentalQuotient
                            ? data.developmentalQuotient
                            : '-'
                        }`}
                        sxProps={{
                          fontSize: '14px',
                          fontWeight: 400,
                          color: '#2A4241',
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Box className="flex__ pt-lg pb-sm">
                <MediumTypography
                  labelid="EICS.PovertyLevel"
                  defaultlabel="Poverty Level:"
                  className="mainText-lg pr-xs"
                />
                <MediumTypography
                  textColor={'#008C82'}
                  label={
                    eicsForm.povertyLevel !== null &&
                    eicsForm.povertyLevel !== ''
                      ? getPovertyLevel(eicsForm.povertyLevel)
                      : '-'
                  }
                  className="mainText-lg"
                />
              </Box>
              <MediumTypography
                labelid="EICS.SummaryOfEvaluationInfo"
                defaultlabel="Summary of Evaluation Information"
                className="mainText-lg pt-md pb-lg"
              />
              <TableContainer>
                <Table>
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={summaryHeadCells}
                    checkBoxRequired={false}
                  />
                  <TableBody className="tableRowcss">
                    {eligibilityData !== null &&
                      eligibilityData.map((data, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor:
                              index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                          }}
                        >
                          <TableCell>
                            <Box>
                              <MediumTypography label={data.questionlabelId} />
                            </Box>
                          </TableCell>
                          <TableCell>
                            {data.value === true && <CheckMark />}
                          </TableCell>
                          <TableCell>
                            {data.value === false && <CheckMark />}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                className="pt-md pb-md pl-lg mt-lg mb-lg"
                sx={{ backgroundColor: '#ECF9F8', textAlign: 'center' }}
              >
                <MediumTypography
                  labelid="EICS.NothingIsChecked"
                  defaultlabel="If nothing is checked in an “Eligible” column, child should be discharged"
                  className="mainText-md"
                />
              </Box>
              <Box className="mb-md flex__">
                <MediumTypography
                  labelid="EICS.FamilyDateNoticed"
                  defaultlabel="Date Family Noticed of Eligibility:"
                  textColor="#97A6A5"
                  fontSize="16px"
                  className="pr-xs"
                />
                <MediumTypography
                  label={
                    eicsForm.eligibilityDeterminationSummary.notificationDate
                      ? moment(
                          eicsForm.eligibilityDeterminationSummary
                            .notificationDate,
                        )
                          .local()
                          .format('MM/DD/YYYY')
                      : '-'
                  }
                  fontSize="16px"
                />
              </Box>
              <Box className="mb-md flex__">
                <MediumTypography
                  labelid="EICS.EligibilityNarrative"
                  defaultlabel="Eligibility Summary Narrative:"
                  textColor="#97A6A5"
                  fontSize="16px"
                  className="pr-xs"
                />
                <Tooltip
                  title={
                    eicsForm.eligibilityDeterminationSummary &&
                    eicsForm?.eligibilityDeterminationSummary
                      .eligibilitySummaryNarrative &&
                    eicsForm.eligibilityDeterminationSummary
                      .eligibilitySummaryNarrative.length > 400
                      ? eicsForm.eligibilityDeterminationSummary
                          .eligibilitySummaryNarrative
                      : ''
                  }
                  arrow={true}
                  followCursor
                >
                  <Box>
                    <MediumTypography
                      className="pr-lg pl-sm"
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        width:
                          eicsForm.eligibilityDeterminationSummary &&
                          eicsForm.eligibilityDeterminationSummary
                            .eligibilitySummaryNarrative &&
                          eicsForm.eligibilityDeterminationSummary
                            .eligibilitySummaryNarrative.length > 400
                            ? '750px'
                            : 'auto',
                        color: '#2A4241',
                      }}
                      label={
                        eicsForm.eligibilityDeterminationSummary &&
                        eicsForm.eligibilityDeterminationSummary
                          .eligibilitySummaryNarrative !== null &&
                        eicsForm.eligibilityDeterminationSummary
                          .eligibilitySummaryNarrative !== '' &&
                        eicsForm.eligibilityDeterminationSummary
                          .eligibilitySummaryNarrative !== undefined
                          ? eicsForm.eligibilityDeterminationSummary
                              .eligibilitySummaryNarrative
                          : '-'
                      }
                    />
                  </Box>
                </Tooltip>
              </Box>
              <Box
                className="alignItemCenter flex__ justifyContent-Center rowContainer"
                sx={{ background: '#ECF9F8' }}
              >
                <MediumTypography
                  labelid={`Discharge Reason: ${
                    eicsForm?.dischargeReason
                      ? getDischargeReason(eicsForm?.dischargeReason)
                      : '-'
                  }  }`}
                  defaultlabel={`Discharge Reason: ${
                    eicsForm?.dischargeReason
                      ? getDischargeReason(eicsForm?.dischargeReason)
                      : '-'
                  }   `}
                  textColor="#EB4C60"
                  fontSize="16px"
                  className="pr-xs"
                />
                <MediumTypography
                  labelid={`  Discharge Date: ${
                    eicsForm.dischargeDate
                      ? moment(eicsForm.dischargeDate).format('MMM DD, YYYY')
                      : '-'
                  }`}
                  defaultlabel={`    Discharge Date: ${
                    eicsForm.dischargeDate
                      ? moment(eicsForm.dischargeDate).format('MMM DD, YYYY')
                      : '-'
                  }`}
                  textColor="#EB4C60"
                  fontSize="16px"
                  className="pr-xs ml-xlg"
                />
              </Box>
            </Card>
          </Box>
          {shareModal && (
            <ShareDownloadModal
              modalVisible={shareModal}
              closeModal={() => setShareModal(false)}
              screenName="eics"
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EicsTBRReferralToEvaluation;
