import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Grid, Link } from '@mui/material';
import MediumTypography from '../components/formlib/MediumTypography';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

export default function AppFooter() {
  const intl = useIntl();

  const getCopyRightMessage = () => {
    return `© ${dayjs().year()} ${intl.formatMessage({
      id: 'copyrightMessage',
      defaultMessage: 'Thom Child and Family Services All Rights Reserved',
    })}`;
  };

  return (
    <Box className="mainfooter" justifyContent="center" alignItems="center">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        sx={{
          justifyContent: 'center',
          alignContent: 'center',
          paddingTop: '0px',
        }}
      >
        <Grid item xs={6}>
          <List
            className="submainfooter"
            style={{
              display: 'inline-flex',
              height: '36px',
              color: '#536B6A',
              fontSize: '12px',
              fontWeight: '400',
              lineHeight: '14.5px',
            }}
          >
            <ListItem>
              <Link
                href="#"
                underline="none"
                title="Click to visit About Us page"
              >
                About
              </Link>{' '}
            </ListItem>
            <ListItem>
              <Link href="#" underline="none" title="Click to visit Terms page">
                Terms
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href="#"
                underline="none"
                title="Click to visit Policy page"
              >
                Policy
              </Link>
            </ListItem>
            <ListItem>
              <Link
                href="#"
                underline="none"
                title="Click to visit Contact page"
              >
                Contact
              </Link>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={6}>
          <Box
            justifyContent="center"
            sx={{
              textAlign: 'right',
            }}
          >
            <MediumTypography
              sxProps={{
                color: '#536B6A',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '14.5px',
                paddingRight: '30px',
                '&:hover': {
                  color: '#008c82',
                },
              }}
              label={getCopyRightMessage()}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
