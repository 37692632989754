import React from 'react';
import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { useNavigate } from 'react-router';
import {
  BATELLE_DEVELOPMENT_INVENTORY_2,
  CLIENTS_ROUTE_NAME,
  EICS_TBR_FORM,
  ELIGIBILITY_DETERMINATION,
  IFSP_ROUTE_NAME,
  INTAKE,
} from '../../routes/Routing';

export interface ClientJourneyFaceSheetProps {
  journeyName: string;
  Icon: React.ComponentType;
  StatusComponent?: React.ComponentType;
  status: string;
  routeId: string;
  ifspDueDate: string;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Completed':
      return 'textColor';
    case 'InProgress':
      return '#F9B803';
    case 'NotStarted':
      return '#97A6A5';
    default:
      return 'textColor';
  }
};

const ClientJourneyFaceSheet: React.FC<ClientJourneyFaceSheetProps> = ({
  journeyName,
  Icon,
  StatusComponent,
  status,
  routeId,
  ifspDueDate,
}) => {
  const navigation = useNavigate();
  const clientId = localStorage.getItem('ClientId');
  const handleNavigation = (journey: string) => {
    if (
      (journey === 'backend.journey_name.referral_begin' &&
        status !== 'NotStarted') ||
      (journey === 'backend.journey_name.client' && status !== 'NotStarted')
    ) {
      sessionStorage.setItem('tabName', '0');
      navigation(CLIENTS_ROUTE_NAME, {
        state: { clientId: clientId, isCreate: true },
      });
    } else if (
      journey === 'backend.journey_name.intake' &&
      status !== 'NotStarted'
    ) {
      navigation(INTAKE);
    } else if (
      (journey === 'backend.journey_name.evaluation' &&
        status !== 'NotStarted') ||
      (journey === 'backend.journey_name.re-evaluation' &&
        status !== 'NotStarted')
    ) {
      navigation(BATELLE_DEVELOPMENT_INVENTORY_2);
    } else if (
      journey === 'backend.journey_name.eds' &&
      status !== 'NotStarted'
    ) {
      navigation(ELIGIBILITY_DETERMINATION, {
        state: { evaluationId: routeId },
      });
    } else if (
      journey === 'backend.journey_name.ifsp' &&
      status !== 'NotStarted'
    ) {
      navigation(IFSP_ROUTE_NAME, {
        state: { ifspDueDate: ifspDueDate },
      });
    } else if (
      (journey === 'backend.journey_name.tpc' && status !== 'NotStarted') ||
      (journey === 'backend.journey_name.transition_planning' &&
        status !== 'NotStarted')
    ) {
      navigation(IFSP_ROUTE_NAME, {
        state: { ifspDueDate: ifspDueDate, openTransitionPlanning: true },
      });
    } else if (
      journey === 'backend.journey_name.referral_ends' &&
      status !== 'NotStarted'
    ) {
      navigation(EICS_TBR_FORM);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={() => handleNavigation(journeyName)}
    >
      <Box
        sx={{
          width: '26px',
          height: '27px',
          display: 'flex',
        }}
      >
        <Icon />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {StatusComponent && (
          <>
            <Box
              style={{
                flex: '1',
                height: '1px',
                backgroundColor:
                  journeyName === 'backend.journey_name.referral_begin'
                    ? 'transparent'
                    : '#97A6A5',
              }}
            />
            <StatusComponent />
            <Box
              style={{
                flex: 1,
                height: '1px',
                backgroundColor:
                  journeyName === 'backend.journey_name.referral_ends'
                    ? 'transparent'
                    : '#97A6A5',
              }}
            />
          </>
        )}
      </Box>
      <MediumTypography
        labelid={journeyName}
        fontweight={700}
        sxProps={{
          textAlign: 'center',
          width: '100%',
          color: getStatusColor(status),
        }}
      />
    </Box>
  );
};

export default ClientJourneyFaceSheet;
