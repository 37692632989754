import { LEAReferralType } from '../../pages/referral/type';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export const getLEAPreschoolInfo = async (
  employeeId: string,
  clientId: string,
): Promise<LEAReferralType> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<LEAReferralType>(
      `lea-referrals?employeeId=${employeeId}&clientId=${clientId}`,
    ),
  );
};

export const updateLEAPreschoolInfo = async (
  employeeId: string,
  clientId: string,
  requestBody: LEAReferralType,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<LEAReferralType, ApiMessage>(
      `lea-referrals?employeeId=${employeeId}&clientId=${clientId}`,
      requestBody,
    ),
  );
};
