import { Typography, Box, styled, AlertColor } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import backarrow from '../../assets/images/backarrow.svg';
import { useLocation, useNavigate } from 'react-router';
import React, { useContext, useEffect, useState } from 'react';
import ProgressNote from './ProgressNote';
import TitleText from '../../components/formlib/TitleText';
import { ReactComponent as InActiveUser } from '../../assets/images/ProgressNoteInActiveUser.svg';
import { ReactComponent as ActiveUser } from '../../assets/images/ProgressNoteActiveUser.svg';
import { ProgressNoteAttendee, ProgressNoteType } from '../../utils/type';
import { getProgressNote } from '../../services/progressNotes/ProgressNotesApi';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { STORAGE_USER_ID_KEY } from '../../services/Constant';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import {
  ACTIVITY_APPROVAL_SHEET_ROUTE_NAME,
  ACTIVITY_SHEET_ROUTE_NAME,
  PROGRESS_NOTE_FACESHEET,
  PROGRESS_NOTE_LIST_ROUTE_NAME,
  SCHEDULER_ROUTE_NAME,
} from '../../routes/Routing';

const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00C6B8',
          height: 2,
        },
      },
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const BackArrowImage = styled('img')`
  cursor: pointer;
  border: 3px;
`;

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginTop: '24px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export interface TabProps {
  tabPosition: number;
}

export type ClientListType = {
  firstName: string;
  clientId: string;
  lastName: string;
  middleName: string;
};

export type AttendeeListType = {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
};

export type IdType = {
  tabPosoition: number;
  eventId: string;
  interpreter?: ProgressNoteAttendee;
  clickedAttendeeId: string;
  progressNoteData: ProgressNoteType;
  screenFrom: string;
  selectedDateInReviewActivityScreen?: Date;
  clientIdsFromActivity: string[];
};

const CustomTabs = styled(Tabs)({
  '& .MuiButtonBase-root.MuiTab-root': {
    padding: '0px',
    marginTop: '16px',
    minHeight: '24px',
    maxWidth: '100%',
    minWidth: 'auto',
    marginRight: '24px',
    textTransform: 'none',
    fontFamily: 'Lato-Regular',
  },
  '&.Mui-selected': {
    color: '#00C6B8',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
  },
});

const CustomTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    color: '#00C6B8',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
  },
  '&:not(.Mui-selected)': {
    color: '#2A4241',
    fontWeight: '400',
    fontFamily: 'Lato-Regular',
    fontSize: '14px',
  },
}));
const ProgressNoteSetup = () => {
  const [tabProp, setTabProp] = useState<TabProps>({
    tabPosition: 0,
  });

  const { state } = useLocation();
  const navigation = useNavigate();
  const [clickedAttendeeId, setClickedAttendeId] = useState<string>(
    state?.sortRequired || state?.sortRequired === undefined
      ? localStorage.getItem(STORAGE_USER_ID_KEY)?.toString()
      : state?.attendeeId[0].id,
  );
  const [reveiverAttendeeList, setReveiverAttendeeList] = useState<string>('');
  const [clientIds, setClientIds] = useState<string[]>([]);
  const [eventId, setEventId] = useState<string>('');
  const [sortRequired, setSortRequired] = useState<boolean>(true);
  const [attendeeList, setAttendeeList] = React.useState<
    ProgressNoteAttendee[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [screenFrom, setScreenFrom] = useState<string>('');
  const [progressNoteData, setProgressNoteData] =
    useState<ProgressNoteType | null>(null);
  const [interpreter, setInterpreter] = useState<ProgressNoteAttendee>();

  const [open, setOpen] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');

  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  useEffect(() => {
    if (state !== null && state.eventId && state.attendeeId) {
      setEventId(state.eventId);
      setSortRequired(state?.sortRequired);
      setSelectedDate(state?.selectedDate);
      if (!state.sortRequired && state.attendeeId.length > 0) {
        setReveiverAttendeeList(state.attendeeId[0].id);
      } else {
        setClickedAttendeId(
          localStorage.getItem(STORAGE_USER_ID_KEY)?.toString() ?? '',
        );
      }
      setClientIds(state.clientIds);
      setScreenFrom(state.screenFrom);
    }
  }, [state]);

  const navigateTo = () => {
    if (screenFrom === 'EmployeeActivity') {
      navigation(ACTIVITY_SHEET_ROUTE_NAME, {
        state: {
          id: clickedAttendeeId,
          date: selectedDate,
          firstName: attendeeList[0]?.firstName,
          lastName: attendeeList[0]?.lastName,
        },
      });
    } else if (screenFrom === 'ApprovalActivity') {
      navigation(ACTIVITY_APPROVAL_SHEET_ROUTE_NAME, {
        state: {
          id: clickedAttendeeId,
          date: selectedDate,
          firstName: attendeeList[0]?.firstName,
          lastName: attendeeList[0]?.lastName,
        },
      });
    } else if (
      screenFrom === 'schedulerEvent' ||
      screenFrom === 'programschedule'
    ) {
      navigation(SCHEDULER_ROUTE_NAME, {
        state: {
          screenFrom: screenFrom,
        },
      });
    } else if (screenFrom === 'ProgressNoteEmployee') {
      navigation(PROGRESS_NOTE_LIST_ROUTE_NAME);
    } else if (screenFrom === 'ProgressNoteFacesheet') {
      navigation(PROGRESS_NOTE_FACESHEET);
    } else {
      history.back();
    }
  };

  useEffect(() => {
    toggleLoader(true);
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (eventId) {
      getProgressNote(
        reveiverAttendeeList !== '' && clientIds?.length > 0
          ? reveiverAttendeeList
          : clickedAttendeeId,
        eventId,
      )
        .then((response) => {
          toggleLoader(false);
          if (response.clients.length === 0) {
            setOpen(true);
            setToastrVariable('error');
            setToastrDefaultMessage('No Clients Found');
            setToastrId('ProgressNote.NoClientError');
            setTimeout(navigateTo, 1000);
          } else {
            if (!sortRequired && clientIds) {
              setTabProp({
                ...tabProp,
                tabPosition:
                  response.clients[0]?.attendeeList?.findIndex(
                    (item) => item.attendeeId === reveiverAttendeeList,
                  ) ?? 0,
              });
            }
            let attendeeListSorted = response.clients[0].attendeeList || [];
            const hasInterpreter = attendeeListSorted.find((attendee) => {
              return attendee.interpreter;
            });
            attendeeListSorted = attendeeListSorted.filter((attendee) => {
              return !attendee.interpreter;
            });
            if (attendeeListSorted.length > 0) {
              if (attendeeListSorted.length === tabProp.tabPosition) {
                setTabProp({
                  ...tabProp,
                  tabPosition: tabProp.tabPosition - 1,
                });
              }
              if (
                response.clients[0].attendeeList?.find(
                  (item) => item.attendeeId === userId,
                ) &&
                (state?.sortRequired === undefined || state?.sortRequired)
              ) {
                const index = attendeeListSorted.findIndex(
                  (attendee) => attendee.attendeeId === userId,
                );
                const matchingAttendee = attendeeListSorted.splice(index, 1)[0];
                attendeeListSorted.unshift(matchingAttendee);
              } else {
                const index = attendeeListSorted.findIndex(
                  (attendee) => attendee.attendeeId === state.attendeeId[0].id,
                );
                const matchingAttendee = attendeeListSorted.splice(index, 1)[0];
                attendeeListSorted.unshift(matchingAttendee);
              }
              // To show all group billing clients we are ignoring this logic
              // if (clientIds) {
              //   const filteredClient = response.clients.filter((client) =>
              //     clientIds.includes(client.client.clientId),
              //   );
              //   response.clients = filteredClient;
              // }
              setInterpreter(hasInterpreter);
              setProgressNoteData(response);
              setAttendeeList(attendeeListSorted);
            }
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            const response = error as ApiMessage;
            if (response.code === 1013) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.NoAppointmentError');
              setTimeout(navigateTo, 1000);
            } else if (response.code === 1012) {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.NoAttendeeError');
              setTimeout(navigateTo, 1000);
            } else {
              setToastrDefaultMessage(response.message);
              setToastrId('ProgressNote.CodeError');
            }
          }
        });
    }
  }, [clickedAttendeeId, eventId]);
  const handleTabChange = (
    _event: React.ChangeEvent<object>,
    position: number,
  ) => {
    setProgressNoteData(null);
    setClickedAttendeId(attendeeList[position].attendeeId);
    setReveiverAttendeeList(attendeeList[position].attendeeId);
    setTabProp({ ...tabProp, tabPosition: position });
  };
  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <ThemeProvider theme={theme}>
      <Box
        // component="main"
        sx={{
          // flexGrow: 1,
          margin: '24px',
          bgcolor: '#E7F0F0',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <BackArrowImage
            src={backarrow}
            alt="backarrow"
            onClick={() => navigateTo()}
          />
          <TitleText
            labelid="ProgressNote.Title"
            defaultlabel="Progress Notes"
            Sxprops={{ paddingLeft: '14px' }}
          />
        </Box>
        <SnackBarComponent
          open={open}
          handleClose={handleCloseSnakBar}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box sx={{ width: '100%' }}>
          <CustomTabs
            value={tabProp.tabPosition}
            onChange={handleTabChange}
            aria-label="secondary tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            {attendeeList &&
              attendeeList !== null &&
              attendeeList.length > 0 &&
              attendeeList.map(
                (attendee: ProgressNoteAttendee, index: number) => {
                  return (
                    <CustomTab
                      label={attendee.firstName + ' ' + attendee.lastName}
                      key={attendee.attendeeId}
                      icon={
                        tabProp.tabPosition === index ? (
                          <ActiveUser />
                        ) : (
                          <InActiveUser />
                        )
                      }
                      iconPosition="start"
                    />
                  );
                },
              )}
          </CustomTabs>

          {attendeeList &&
            attendeeList !== null &&
            progressNoteData !== null &&
            attendeeList.map(
              (attendee: ProgressNoteAttendee, index: number) => {
                return (
                  <TabPanel
                    value={
                      tabProp.tabPosition === attendeeList.length
                        ? tabProp.tabPosition - 1
                        : tabProp.tabPosition
                    }
                    index={index}
                    key={attendee.attendeeId}
                  >
                    <ProgressNote
                      tabPosoition={
                        tabProp.tabPosition === attendeeList.length
                          ? tabProp.tabPosition - 1
                          : tabProp.tabPosition
                      }
                      clientIdsFromActivity={clientIds}
                      eventId={eventId}
                      interpreter={interpreter}
                      clickedAttendeeId={clickedAttendeeId}
                      progressNoteData={progressNoteData}
                      screenFrom={screenFrom}
                      selectedDateInReviewActivityScreen={selectedDate}
                    />
                  </TabPanel>
                );
              },
            )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default ProgressNoteSetup;
