import React from 'react';
import { SxProps, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Typographyproptypes {
  textColor?: string;
  label?: string;
  marginTop?: string;
  fontweight?: number;
  textstyle?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify';
  labelid?: string;
  defaultlabel?: string;
  paddingstyle?: string;
  fontSize?: string;
  sxProps?: SxProps;
  onClick?: () => void;
  id?: string;
  className?: string;
  fontStyle?: string;
  lineHeight?: string;
}
const MediumTypography: React.FC<Typographyproptypes> = ({
  label,
  textColor,
  marginTop,
  fontweight,
  textstyle,
  labelid,
  defaultlabel,
  paddingstyle,
  fontSize,
  sxProps,
  onClick,
  id,
  className,
  fontStyle,
  lineHeight,
}) => {
  return (
    <Typography
      onClick={onClick}
      id={id}
      key={label}
      className={className}
      sx={{
        fontFamily: 'Lato-Regular',
        fontStyle: fontStyle ?? 'normal',
        fontWeight: fontweight ?? 500,
        fontSize: fontSize ?? '14px',
        lineHeight: lineHeight ?? '22px',
        color: textColor,
        marginTop: marginTop,
        textAlign: textstyle,
        padding: paddingstyle,
        ...sxProps,
      }}
    >
      {label}
      {labelid && (
        <FormattedMessage id={labelid} defaultMessage={defaultlabel} />
      )}
    </Typography>
  );
};
export default MediumTypography;
