import {
  AlertColor,
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Grid,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import MediumTypography from '../../components/formlib/MediumTypography';
import { CustomTableHeader } from './CustomTableHeader';
import { headCells } from './TravelExpenses';
import { TableHeader } from '../../components/formlib/TableHeader';
import ReviewTravelExpenseEmployeeTable from './ReviewTravelExpenseEmployeeTable';
import AppPagination from '../../components/shared/AppPagination';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import React from 'react';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import ModalPopup from '../../components/formlib/ModalPopup';
import ActivityNoteModal from '../../components/formlib/modal/ActivityNoteModal';
import {
  GetTravelExpenseResponse,
  approveOrRejectTravelExpenses,
  getReviewTravelExpenses,
  getTravelExpenseByEmployeeId,
} from '../../services/travelExpenses/TravelExpensesApi';
import ExpenseTableRow from './ExpenseTableRow';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import MonthViewSelector from './MonthViewSelector';
import YearViewSelector from './YearViewSelector';
import SwitchComponent from '../../components/formlib/SwitchComponent';
import BulckApproveRejectAction from './BulckApproveRejectAction';
interface ReviewTable {
  employeeId: string;
  employeeName: string;
  dateOfSubmission: string;
  totalAmount: string;
  status: string;
  travelId: string;
  expenseDate: string;
  milesAmt: string;
  travelMileageAmt: string;
  officeSuppliesAmt: string;
  fazzioDirectCareSuppliesAmt: string;
  duesAndSubscriptionAmt: string;
  postageAmt: string;
  printingOrDuplicatingAmt: string;
  continuingEdAmt: string;
  telephoneAmt: string;
  fazzioFoodAmt: string;
  stipendAmt: string;
  fazzioMileageAmt: string;
  otherAmt: string;
  isSelected: boolean;
}

export interface ReviewTravelExpenseTabelType {
  reviewExpenses: ReviewTable[];
  reviewExpensesCount: number;
}

type HeadCell = {
  id: string;
  labelId: string;
  defaultLabelId: string;
  numeric: boolean;
  requiredSorting: boolean;
};
export const initialReviewHeadCells: HeadCell[] = [
  {
    id: 'name',
    labelId: 'Name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'miles',
    labelId: 'Miles',
    defaultLabelId: 'Miles',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'travelMileage',
    labelId: 'Travel Mileage',
    defaultLabelId: 'Travel Mileage (71800)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'officeSupplies',
    labelId: 'Office Supplies',
    defaultLabelId: 'Office Supplies (71000)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'directCareSupplies',
    labelId: 'Fazzio Direct Care Supplies',
    defaultLabelId: 'Fazzio Direct Care Supplies (80600)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'duesandsubscriptions',
    labelId: 'Dues and Subscriptions',
    defaultLabelId: 'Dues and Subscriptions (70800)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'postage',
    labelId: 'Postage',
    defaultLabelId: 'Postage (71100)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'painting/duplicating',
    labelId: 'Painting / Duplicating',
    defaultLabelId: 'Painting / Duplicating (71200)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'continuingEd/inService',
    labelId: 'Continuing Ed / In Service',
    defaultLabelId: 'Continuing Ed / In Service (70500)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'telephone',
    labelId: 'Telephone',
    defaultLabelId: 'Telephone (71500)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'fazzioFood',
    labelId: 'Fazzio Food',
    defaultLabelId: 'Fazzio Food (80900)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'stipend',
    labelId: 'TFI Stipend',
    defaultLabelId: 'TFI Stipend (NA)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'fazzioMileage',
    labelId: 'Fazzio-Mileage',
    defaultLabelId: 'Fazzio-Mileage (NA)',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'Others',
    labelId: 'Others',
    defaultLabelId: 'Others',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'totalExpenses',
    labelId: 'Total Expenses',
    defaultLabelId: 'Total Expenses',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    labelId: 'Status',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'action',
    labelId: 'Action',
    defaultLabelId: 'Action',
    numeric: false,
    requiredSorting: false,
  },
];

const ReviewTravelExpense = () => {
  const [type, setType] = useState<string>('list');
  const [reviewTableData, setReviewTableData] =
    useState<ReviewTravelExpenseTabelType>();
  const [reviewHeadCells, setReviewHeadCells] = useState<HeadCell[]>(
    initialReviewHeadCells,
  );
  const [reviewEmployeeTableData, setReviewEmployeeTableData] =
    useState<GetTravelExpenseResponse>();
  const [employeeName, setEmployeeName] = useState<string>('');
  const [selectedEmpId, setSelectedEmpId] = useState<string>('');
  const currentDate = dayjs().format('MMMM YYYY');
  const [selectedSubmissionDate, setSelectedSubmissionDate] =
    useState(currentDate);
  const [selectedExpenseMonth, setSelectedExpenseMonth] = useState(currentDate);
  const [page, setPage] = useState(0);
  const [employeeReviewTablePage, setEmployeeReviewTablePage] = useState(0);
  const [supervisiorSignatureModal, setSupervisiorSignatureModal] =
    useState(false);
  const [openApprovalConfirmationModal, setOpenApprovalConfirmationModal] =
    useState<boolean>(false);
  const [openSendToFinanceWarningModal, setOpenSendToFinanceWarningModal] =
    useState<boolean>(false);
  const [
    approveOrRejectOnTableRecordModal,
    setApproveOrRejectOnTableRecordModal,
  ] = useState<boolean>(false);
  const [clickedExpenseTableIndex, setClickedExpenseTableIndex] =
    useState<number>(0);
  const [isBulckApproval, setIsBulkApproval] = useState<boolean>(false);
  const [isBulkReject, setIsBulkReject] = useState<boolean>(false);
  const [showSubmitted, setShowSubmitted] = useState<boolean>(false);
  const [mainCheckBox, setMainCheckBox] = useState<boolean>(false);
  const [signature, setSignature] = useState('');
  const [open, setOpen] = React.useState(false);
  const [toastrVariable, setToastrVariable] =
    React.useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [approvalModal, setApprovalModal] = useState(false);
  const [openNoteModel, setOpenNoteModel] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [selectedYear, setSelectedYear] = useState<string | number>(
    new Date().getFullYear(),
  );
  const [selectedMonth, setSelectedMonth] = useState<string | number>(
    new Date().getMonth() + 1,
  );
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const handleYearChange = (value: string | number) => {
    setSelectedYear(value);
  };

  const month = parseInt(
    dayjs(selectedSubmissionDate, 'MMMM YYYY').format('MM'),
    10,
  );
  const year = parseInt(
    dayjs(selectedSubmissionDate, 'MMMM YYYY').format('YYYY'),
    10,
  );

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpen(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const handleCloseNoteModal = () => {
    setOpenNoteModel(false);
    if (note === '') {
      setNote('');
    }
  };

  const getReviewTableData = (employeeId: string) => {
    toggleLoader(true);
    getTravelExpenseByEmployeeId(
      employeeId,
      selectedMonth as number,
      selectedYear as number,
      employeeReviewTablePage,
    )
      .then((response) => {
        if (response) {
          toggleLoader(false);
          setType('view');
          if (response.expenses.length !== 0) {
            response.expenses.forEach((exp) => {
              exp.expenseDate = dayjs(exp.expenseDate, 'YYYY-MM-DD').format(
                'MM/DD/YYYY',
              );
            });
          }
          setReviewEmployeeTableData(response);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelExpensesFailedToLoadError');
          setToastrDefaultMessage('Failed to load travel expenses');
        }
      });
  };

  useEffect(() => {
    if (selectedEmpId !== '') {
      getReviewTableData(selectedEmpId);
    }
  }, [employeeReviewTablePage]);
  const getTableReviewTravelExpenses = () => {
    toggleLoader(true);
    getReviewTravelExpenses(
      selectedMonth as string,
      selectedYear as string,
      showSubmitted,
    )
      .then((response) => {
        toggleLoader(false);
        if (response) {
          setReviewTableData((prevData) => {
            return {
              ...prevData,
              reviewExpensesCount: response.reviewExpensesCount,
              reviewExpenses: response.reviewExpenses.map((expense) => ({
                ...expense,
                isSelected: false,
              })),
            };
          });
        }
        setMainCheckBox(false);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('travelExpensesFailedToLoadError');
          setToastrDefaultMessage('Failed to load travel expenses');
        }
      });
  };
  useEffect(() => {
    if (type === 'list') {
      getTableReviewTravelExpenses();
    } else {
      getReviewTableData(selectedEmpId);
    }
  }, [
    selectedSubmissionDate,
    selectedExpenseMonth,
    type,
    employeeName,
    selectedEmpId,
    selectedMonth,
    selectedYear,
    showSubmitted,
  ]);

  useEffect(() => {
    if (showSubmitted) {
      setReviewHeadCells((prevHeadCells) => {
        const newHeadCells = [...prevHeadCells];
        newHeadCells.pop();
        return newHeadCells;
      });
    } else {
      setReviewHeadCells(initialReviewHeadCells);
    }
  }, [showSubmitted]);

  const handleSendToFinance = () => {
    const selectedRecords = reviewTableData?.reviewExpenses?.filter(
      (record) => record.isSelected,
    );
    if (selectedRecords?.every((record) => record.status === 'Approved')) {
      const selectedRecordsIds = selectedRecords.map(
        (record) => record.travelId,
      );
      toggleLoader(true);
      approveOrRejectTravelExpenses('SentToFinance', '', '', selectedRecordsIds)
        .then(() => {
          getTableReviewTravelExpenses();
          toggleLoader(false);
          setOpen(true);
          setType('list');
          setToastrVariable('success');
          setToastrId('travelExpensesSendToFinanceSuccess');
          setToastrDefaultMessage(
            'Selected Travel expenses submitted to Finance team',
          );
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          }
        });
    } else {
      setOpenSendToFinanceWarningModal(true);
    }
  };

  const onMainCheckBoxClick = (value: boolean) => {
    setMainCheckBox(value);
  };

  useEffect(() => {
    setReviewTableData((prev) => {
      if (prev) {
        return {
          ...prev,
          reviewExpenses: prev.reviewExpenses.map((slectedData) => {
            return {
              ...slectedData,
              isSelected: mainCheckBox,
            };
          }),
        };
      }
      return prev;
    });
  }, [mainCheckBox]);
  return (
    <Box component="main">
      <Box component="section">
        {
          <SnackBarComponent
            open={open}
            handleClose={handleClose}
            successOrError={toastrVariable}
            labelId={toastrId}
            defaultMessageId={toastrDefaultMessage}
          />
        }
        {
          <ModalPopup
            open={openApprovalConfirmationModal}
            description="travelExpensesAreYouSureWantToApprove"
            labelId1="travelExpense.no"
            labelId2="travelExpense.yes"
            negativeActionLabel="No"
            positiveActionLabel="Yes"
            onClose={() => setOpenApprovalConfirmationModal(false)}
            onCancel={() => setOpenApprovalConfirmationModal(false)}
            onOk={() => setApproveOrRejectOnTableRecordModal(true)}
          />
        }
        {
          <ModalPopup
            open={openSendToFinanceWarningModal}
            description="travelExpensesWarningToSendToFinance"
            labelId2="travelExpensesOk"
            onOk={() => setOpenSendToFinanceWarningModal(false)}
          />
        }
        {openNoteModel && (
          <ActivityNoteModal
            positiveButtonId="reject"
            placeholderId="travelExpensesRejectionNotePlaceholder"
            closeOnBackdropClick={false}
            open={openNoteModel}
            note={note}
            handleClose={handleCloseNoteModal}
            maxLength={200}
            updateNote={(value: string) => {
              const selectedRecords = reviewTableData?.reviewExpenses?.filter(
                (record) => record.isSelected,
              );
              if (
                reviewEmployeeTableData?.travelId ||
                reviewTableData?.reviewExpenses[clickedExpenseTableIndex]
                  .travelId ||
                (selectedRecords && selectedRecords?.length > 0 && isBulkReject)
              ) {
                toggleLoader(true);
                const travelIdList =
                  type === 'list' && isBulkReject
                    ? selectedRecords?.map((record) => record.travelId)
                    : type === 'list'
                    ? [
                        reviewTableData?.reviewExpenses[
                          clickedExpenseTableIndex
                        ].travelId,
                      ]
                    : [reviewEmployeeTableData?.travelId];
                const validTravelIdList = (travelIdList ?? []).filter(
                  (id): id is string => id !== undefined,
                );
                if (validTravelIdList.length > 0) {
                  approveOrRejectTravelExpenses(
                    'Rejected',
                    value,
                    signature,
                    validTravelIdList,
                  )
                    .then(() => {
                      toggleLoader(false);
                      setOpenNoteModel(false);

                      getTableReviewTravelExpenses();
                      setOpen(true);
                      setType('list');
                      setToastrVariable('success');
                      setToastrId('travelExpensesRejectedSuccess');
                      setToastrDefaultMessage(
                        'Travel expenses rejected successfully',
                      );
                    })
                    .catch((error) => {
                      toggleLoader(false);
                      setOpenNoteModel(false);

                      setOpen(true);
                      setToastrVariable('error');
                      if (isCustomError(error)) {
                        const apiError = error as ApiError;
                        setToastrId(apiError.id);
                        setToastrDefaultMessage(apiError.message);
                      } else {
                        setToastrId('travelExpensesFailedToRejectError');
                        setToastrDefaultMessage(
                          'Failed to load travel expenses',
                        );
                      }
                    });
                }
              }
            }}
          />
        )}
        {approvalModal &&
        signature === '' &&
        reviewEmployeeTableData?.managerSignatureRowKey === '' ? (
          <ModalPopup
            open={approvalModal}
            description="reviewTravelExpense.Approve"
            onCancel={() => setApprovalModal(false)}
            labelId1="reviewTravelExpense.close"
            negativeActionLabel="Close"
            hidePositiveBtn={true}
          />
        ) : (
          <ModalPopup
            open={approvalModal}
            description="travelApprovalSubmission"
            onCancel={() => setApprovalModal(false)}
            onOk={() => {
              if (reviewEmployeeTableData?.travelId) {
                toggleLoader(true);
                approveOrRejectTravelExpenses('Approved', '', signature, [
                  reviewEmployeeTableData?.travelId,
                ])
                  .then(() => {
                    toggleLoader(false);
                    setOpen(true);
                    setType('list');
                    setToastrVariable('success');
                    setToastrId('travelExpensesApprovedSuccess');
                    setToastrDefaultMessage(
                      'Travel expenses approved successfully',
                    );
                  })
                  .catch((error) => {
                    toggleLoader(false);
                    setOpen(true);
                    setToastrVariable('error');
                    if (isCustomError(error)) {
                      const apiError = error as ApiError;
                      setToastrId(apiError.id);
                      setToastrDefaultMessage(apiError.message);
                    } else {
                      setToastrId('travelExpensesFailedToApproveError');
                      setToastrDefaultMessage(
                        'Failed to approve travel expenses',
                      );
                    }
                  });
              }
              setApprovalModal(false);
            }}
            labelId1="TimeOff.No"
            negativeActionLabel="No"
            labelId2="TimeOff.Yes"
            positiveActionLabel="Yes"
          />
        )}
        {supervisiorSignatureModal && (
          <Box component="div">
            {checkPermissionForFeature(
              'backend.review_travel_expense',
              'editPermission',
            ) && (
              <SignatureComponent
                onCancelClick={() => {
                  setSupervisiorSignatureModal(false);
                }}
                signatureRowKey={
                  reviewEmployeeTableData?.managerSignatureRowKey || ''
                }
                signaturePartitionKey={
                  reviewEmployeeTableData?.managerSignatureRowKey !== ''
                    ? 'travelExpense'
                    : ''
                }
                signatureSaveOrUPdate={(sign: string) => {
                  setSignature(sign);
                  setSupervisiorSignatureModal(!supervisiorSignatureModal);
                }}
                handleSuccess={handleSuccess}
                type="Employee"
                signature={signature}
                signClicked={supervisiorSignatureModal}
                hideRemoveButton={
                  (reviewEmployeeTableData &&
                    reviewEmployeeTableData.status === 'Approved') ||
                  (reviewEmployeeTableData &&
                    reviewEmployeeTableData.status === 'Rejected') ||
                  (reviewEmployeeTableData &&
                    reviewEmployeeTableData.status === 'Sent To Finance')
                }
                resetCallBackForTypeEmployee={() => {
                  setSupervisiorSignatureModal(false);
                  setSignature('');
                }}
              />
            )}
          </Box>
        )}
        <Grid
          container
          xs={12}
          lg={12}
          className="flex__ mt-md mb-lg align__items__center justifyContentSpaceBtw"
        >
          <Grid item>
            <Box className="flex__">
              {type === 'view' && (
                <Box className="pt-sm">
                  <CustomBackArrow
                    onClick={() => {
                      setSelectedSubmissionDate(currentDate);
                      setType('list');
                    }}
                  />
                </Box>
              )}

              <TitleText
                labelid="Review Travel Expense"
                defaultlabel="Review Travel Expense"
                className="ml-sm pt-xs"
              />
            </Box>
          </Grid>
          <Grid item>
            <Box className="display-flex-center justifyContent-FlexEnd" gap={2}>
              {type === 'list' &&
                reviewTableData?.reviewExpenses.some(
                  (item) => item.isSelected,
                ) && (
                  <Box className="flex__ align__items__center" gap={2}>
                    <BulckApproveRejectAction
                      handleBulkApprove={() => {
                        const selectedTravelExpenseIds =
                          reviewTableData?.reviewExpenses.filter(
                            (item) =>
                              item.isSelected && item.status !== 'Approved',
                          );
                        if (selectedTravelExpenseIds.length === 0) {
                          setOpen(true);
                          setToastrVariable('error');
                          setToastrId('travelExpensesAlreadyApproved.success');
                          setToastrDefaultMessage(
                            'Travel expenses has been already approved',
                          );
                        } else {
                          setIsBulkApproval(true);
                          setOpenApprovalConfirmationModal(true);
                        }
                      }}
                      handleBulkReject={() => {
                        setIsBulkReject(true);
                        setOpenNoteModel(true);
                      }}
                    />
                  </Box>
                )}
              {type === 'list' &&
                reviewTableData?.reviewExpenses.every(
                  (item) => !item.isSelected,
                ) && (
                  <Box className="flex__ align__items__center" gap={2}>
                    <Box className="flex__ align__items__center">
                      <MediumTypography
                        textColor="#2A4241"
                        fontweight={600}
                        fontSize="14px"
                        labelid="travelExpenses.ShowSubmitted"
                        defaultlabel="Show Submitted"
                      />
                      <SwitchComponent
                        name={'showSubmitted'}
                        toggle={showSubmitted}
                        handlechange={() => {
                          setShowSubmitted(!showSubmitted);
                        }}
                      />
                    </Box>
                    <MonthViewSelector
                      initialMonth={new Date().getMonth()}
                      onMonthChange={(mon) => {
                        setSelectedMonth(`${mon + 1}`);
                      }}
                    />
                    <YearViewSelector
                      onChange={handleYearChange}
                      value={selectedYear}
                    />
                  </Box>
                )}
              {type === 'list' &&
                reviewTableData?.reviewExpenses.some(
                  (item) => item.isSelected,
                ) && (
                  <ButtonComponent
                    className="btn-primary btn-submit"
                    labelId="travelExpenses.SendToFinance"
                    defaultLabelId="Sent To Finance"
                    variantType="contained"
                    onClick={() => {
                      handleSendToFinance();
                    }}
                  />
                )}
              {type === 'view' && (
                <ButtonComponent
                  sxProps={{ display: 'none' }}
                  className="btn-primary btn-submit  ml-md"
                  labelId="Generate Report"
                  defaultLabelId="Generate Report"
                  variantType="contained"
                  onClick={() => {}}
                />
              )}
            </Box>
          </Grid>
        </Grid>
        {type === 'view' && reviewEmployeeTableData && (
          <Box component="div">
            <Card className="formCardview">
              <Box className="flex__ justifyContentSpaceBtw ">
                <Box className="mb-md flex__">
                  <MediumTypography
                    labelid="Employee Name:"
                    defaultlabel="Employee Name:"
                    sxProps={{
                      color: '#97A6A5',
                      fontWeight: 700,
                    }}
                  />
                  <MediumTypography
                    label={employeeName}
                    sxProps={{
                      fontWeight: 700,
                    }}
                    className="pl-xs"
                  />
                </Box>
                <Box className="flex__ ">
                  <MediumTypography
                    labelid="travelExpense.totalAmount"
                    defaultlabel="Total Amount ($):"
                    textColor="#97A6A5"
                    fontweight={700}
                  />
                  <MediumTypography
                    label={reviewEmployeeTableData.totalAmount}
                    fontweight={700}
                    className="pl-xs"
                  />
                </Box>
              </Box>
              <TableContainer sx={{ height: '400px', maxHeight: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                  <CustomTableHeader
                    headerNames={headCells}
                    numSelected={0}
                    labelSxProp={{
                      paddingLeft: '10px',
                    }}
                  />
                  <TableBody className="tableRowcss travelTablebody ">
                    {reviewEmployeeTableData.expenses.map((expense, index) => (
                      <ExpenseTableRow
                        data={expense}
                        index={index}
                        review={
                          checkPermissionForFeature(
                            'backend.review_travel_expense',
                            'editPermission',
                          ) ||
                          checkPermissionForFeature(
                            'backend.review_travel_expense',
                            'viewPermission',
                          )
                        }
                        key={index}
                        handleDataChange={() => {}}
                        mileageRate={reviewEmployeeTableData.mileageRate}
                        status={reviewEmployeeTableData.status}
                        month={month.toString()}
                        year={year.toString()}
                      />
                    ))}
                  </TableBody>
                </Table>
                {reviewEmployeeTableData.expenses.length === 0 && (
                  <Box sx={{ width: '100%' }}>
                    <MediumTypography
                      textstyle={'center'}
                      labelid="No data added yet"
                      defaultlabel="No data added yet"
                      paddingstyle="16px"
                    />
                  </Box>
                )}
              </TableContainer>
              {reviewEmployeeTableData.expensesCount &&
              reviewEmployeeTableData.expensesCount > 6 ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <AppPagination
                    pageNumber={employeeReviewTablePage}
                    perPageCount={7}
                    paginationCount={reviewEmployeeTableData.expensesCount}
                    handleChangePage={(_event, newPage) => {
                      if (newPage - 1 !== employeeReviewTablePage) {
                        setEmployeeReviewTablePage(newPage - 1);
                      }
                    }}
                  />
                </Box>
              ) : null}
            </Card>
            {reviewEmployeeTableData.expensesCount &&
            reviewEmployeeTableData.expensesCount > 0 ? (
              <Box
                component="div"
                className="flex__ pt-lg pb-lg alignItemsCenter justifyContentSpaceBtw"
              >
                <Box className="flex__ align__items__center ">
                  {checkPermissionForFeature(
                    'backend.review_travel_expense',
                    'editPermission',
                  ) && (
                    <ButtonComponent
                      className={`btn-primary btn-cancel ${
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== ''
                          ? 'signature_view_Box'
                          : 'signature_add_Box'
                      }`}
                      labelId={
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== ''
                          ? 'Insurance.Viewsignature'
                          : 'Add Supervisior Signature'
                      }
                      defaultLabelId={
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== ''
                          ? 'View/update signature'
                          : 'Add Supervisior Signature'
                      }
                      variantType="outlined"
                      LeftIcon={
                        reviewEmployeeTableData.managerSignatureRowKey !== '' ||
                        signature !== '' ? (
                          <Editsignature />
                        ) : null
                      }
                      onClick={() => {
                        setSupervisiorSignatureModal(true);
                      }}
                    />
                  )}
                </Box>
                {checkPermissionForFeature(
                  'backend.review_travel_expense',
                  'editPermission',
                ) && (
                  <Box className="flex__ align__items__center">
                    <ButtonComponent
                      className="btn-primary btn-alert-cancel"
                      labelId={'Reject'}
                      defaultLabelId="Reject"
                      variantType="outlined"
                      disabled={
                        reviewEmployeeTableData.status === 'Sent To Finance'
                      }
                      onClick={() => {
                        setOpenNoteModel(true);
                      }}
                    />
                    <ButtonComponent
                      className="btn-primary btn-submit  ml-md"
                      labelId="Approve"
                      defaultLabelId="Approve"
                      disabled={
                        reviewEmployeeTableData.status === 'Approved' ||
                        reviewEmployeeTableData.status === 'Rejected' ||
                        reviewEmployeeTableData.status === 'Sent To Finance'
                      }
                      variantType="contained"
                      onClick={() => {
                        setApprovalModal(true);
                      }}
                    />
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        )}
        {type === 'list' && (
          <Box component={'div'} sx={{ width: 'auto', display: 'grid' }}>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHeader
                  className="listDataTableHead"
                  headerNames={reviewHeadCells}
                  checkBoxRequired={!showSubmitted}
                  checkBoxValue={mainCheckBox}
                  onCheckBoxClick={onMainCheckBoxClick}
                  needStickyHeader={true}
                  disableHeaderCheckBox={
                    !checkPermissionForFeature(
                      'backend.review_travel_expense',
                      'editPermission',
                    )
                  }
                />
                <TableBody className="tableRowcss">
                  {reviewTableData &&
                    reviewTableData.reviewExpenses.map((item, index) => (
                      <ReviewTravelExpenseEmployeeTable
                        key={index}
                        data={item}
                        index={index}
                        showSubmitButton={showSubmitted}
                        onViewClick={() => {
                          setEmployeeReviewTablePage(0);
                          setPage(0);
                          setSelectedExpenseMonth(
                            dayjs(item.expenseDate).format('MMMM YYYY'),
                          );
                          setSelectedEmpId(item.employeeId);
                          setEmployeeName(item.employeeName);
                          setType('');
                        }}
                        onSelectTravelExpense={() => {
                          setReviewTableData((prev) => {
                            if (prev) {
                              return {
                                ...prev,
                                reviewExpenses: prev.reviewExpenses.map(
                                  (slectedData, i) => {
                                    if (i === index) {
                                      return {
                                        ...slectedData,
                                        isSelected: !slectedData.isSelected,
                                      };
                                    }
                                    return slectedData;
                                  },
                                ),
                              };
                            }
                            return prev; // Handle the case when prev is null or undefined
                          });
                        }}
                        onApproveTravelExpense={() => {
                          const clickedIndex = index;
                          setClickedExpenseTableIndex(clickedIndex);
                          if (
                            reviewTableData.reviewExpenses[clickedIndex]
                              .status === 'Approved'
                          ) {
                            setOpen(true);
                            setToastrVariable('success');
                            setToastrId(
                              'travelExpensesAlreadyApproved.success',
                            );
                            setToastrDefaultMessage(
                              'Travel expenses has been already approved',
                            );
                          } else {
                            setOpenApprovalConfirmationModal(true);
                          }
                        }}
                        onRejectTravelExpense={() => {
                          setOpenNoteModel(true);
                        }}
                      />
                    ))}
                </TableBody>
              </Table>
              {reviewTableData &&
                reviewTableData.reviewExpenses.length === 0 && (
                  <Box sx={{ width: '100%' }}>
                    <MediumTypography
                      textstyle={'center'}
                      labelid="No data added yet"
                      defaultlabel="No data added yet"
                      paddingstyle="16px"
                    />
                  </Box>
                )}
            </TableContainer>
            {reviewTableData && reviewTableData.reviewExpensesCount > 10 && (
              <Card
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={page}
                  perPageCount={10}
                  paginationCount={
                    reviewTableData && reviewTableData.reviewExpensesCount
                  }
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== page) {
                      setPage(newPage - 1);
                    }
                  }}
                />
              </Card>
            )}
          </Box>
        )}
      </Box>
      {approveOrRejectOnTableRecordModal && (
        <Box component="div">
          <SignatureComponent
            onCancelClick={() => {
              setApproveOrRejectOnTableRecordModal(false);
            }}
            signatureRowKey={''}
            signaturePartitionKey={''}
            signatureSaveOrUPdate={(sign: string) => {
              const selectedClientId = reviewTableData?.reviewExpenses?.filter(
                (record) => record.isSelected && record.status !== 'Approved',
              );
              const expenseIdsToBeApproved = isBulckApproval
                ? selectedClientId?.map((record) => record.travelId)
                : [
                    reviewTableData?.reviewExpenses[clickedExpenseTableIndex]
                      ?.travelId,
                  ];
              const validExpenseIdsToBeApproved =
                expenseIdsToBeApproved?.filter(
                  (id): id is string => id !== undefined,
                );
              setSignature(sign);
              if (validExpenseIdsToBeApproved) {
                toggleLoader(true);
                approveOrRejectTravelExpenses(
                  'Approved',
                  '',
                  sign,
                  validExpenseIdsToBeApproved,
                )
                  .then(() => {
                    toggleLoader(false);
                    getTableReviewTravelExpenses();
                    setOpen(true);
                    setApproveOrRejectOnTableRecordModal(
                      !approveOrRejectOnTableRecordModal,
                    );
                    setOpenApprovalConfirmationModal(false);
                    setType('list');
                    setToastrVariable('success');
                    if (isBulckApproval) {
                      setToastrId(
                        `${selectedClientId?.length} ` +
                          'travelExpensesApprovedSuccess',
                      );
                      setToastrDefaultMessage(
                        `${selectedClientId?.length} ` +
                          'Travel expenses approved successfully',
                      );
                    } else {
                      setToastrId('travelExpensesApprovedSuccess');
                      setToastrDefaultMessage(
                        'Travel expenses approved successfully',
                      );
                    }
                    setSignature('');
                  })
                  .catch((error) => {
                    toggleLoader(false);
                    setOpen(true);
                    setToastrVariable('error');
                    if (isCustomError(error)) {
                      const apiError = error as ApiError;
                      setToastrId(apiError.id);
                      setToastrDefaultMessage(apiError.message);
                    } else {
                      setToastrId('travelExpensesFailedToApproveError');
                      setToastrDefaultMessage(
                        'Failed to approve travel expenses',
                      );
                    }
                    setApproveOrRejectOnTableRecordModal(
                      !approveOrRejectOnTableRecordModal,
                    );
                    setOpenApprovalConfirmationModal(false);
                  });
              }
            }}
            handleSuccess={handleSuccess}
            type="Employee"
            signature={signature}
            signClicked={approveOrRejectOnTableRecordModal}
            hideRemoveButton={
              (reviewEmployeeTableData &&
                reviewEmployeeTableData.status === 'Approved') ||
              (reviewEmployeeTableData &&
                reviewEmployeeTableData.status === 'Rejected') ||
              (reviewEmployeeTableData &&
                reviewEmployeeTableData.status === 'Sent To Finance')
            }
            resetCallBackForTypeEmployee={() => {
              setApproveOrRejectOnTableRecordModal(false);
              setSignature('');
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ReviewTravelExpense;
