import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import SmallTypography from './SmallTypography';

interface ButtonProp {
  title: string;
  defaultLabel?: string;
  disable?: boolean;
  Icon: ReactNode;
  onClick: () => void;
  paddingTop?: string;
  paddingBottom?: string;
  className?: string;
}

const UploadButton: FC<ButtonProp> = ({
  title,
  defaultLabel,
  disable,
  Icon,
  onClick,
  paddingTop,
  paddingBottom,
  className,
}) => {
  return (
    <Box
      className={className}
      sx={{
        opacity: disable ? 0.5 : 1,
        pointerEvents: disable ? 'none' : 'auto',
        paddingTop: paddingTop ?? '4px',
        paddingBottom: paddingBottom ?? '4px',
        borderRadius: '4px',
        border: '1px solid #008C82',
        backgroundColor: '#B1FDF7',
        cursor: 'pointer',
        width: '138px',
        height: '32px',
      }}
      display="flex"
      justifyContent="center"
      onClick={onClick}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {Icon}
      </div>
      <SmallTypography labelId={title} defaultLabelId={defaultLabel} />
    </Box>
  );
};

export default UploadButton;
