import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Toolbar,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  AlertColor,
  Typography,
  Card,
} from '@mui/material';
import { styled } from '@mui/system';
import TitleText from '../../components/formlib/TitleText';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as EyeIcon } from '../../assets/images/ViewEye.svg';
import { ReactComponent as EditIcon } from '../../assets/images/editvector.svg';
import { ReactComponent as ViewEye } from '../../assets/images/ViewEye.svg';
import {
  AddProgramList,
  getAllProgramManagmentList,
} from '../../services/programManagment/programManagment';
import { ProgramManagmentType } from '../../utils/type';
import './ProgramManagmentTable.css';
import {
  PROGRAM_MANAGEMENT_EMPLOYEE_LOOKUP_ROUTE_NAME,
  PROGRAM_MANAGEMENT_SETUP_ROUTE_NAME,
} from '../../routes/Routing';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import AppPagination from '../../components/shared/AppPagination';
import { checkPermissionForFeature } from '../../utils/checkPermission';

const initialHeadCells: HeadCell<Data>[] = [
  {
    id: 'programName',
    labelId: 'Addprogram.programName',
    defaultLabelId: 'Program Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'programCode',
    labelId: 'Program.management.programCode',
    defaultLabelId: 'Program Code',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'programDirector',
    labelId: 'Program Director',
    defaultLabelId: 'Program Director',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'Contacts.phone',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'Parent.emails',
    defaultLabelId: 'Email ID',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'address1',
    labelId: 'address1Text',
    defaultLabelId: 'Address Line 1',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'address2',
    labelId: 'address2Text',
    defaultLabelId: 'Address Line 2',
    numeric: false,
    requiredSorting: false,
  },
];
export type Data = {
  programName: string;
  programCode: string;
  programDirector: string;
  phoneNumber: string;
  email: string;
  address1: string;
  address2: string;
  actions: string;
};

export type Order = 'asc' | 'desc';
const PAGE_SIZE = 10;

const ProgramManagmentTable = () => {
  const CustomClientButton = styled(Button)`
    background: #00938e;
    border-radius: 4px;
    font-family: 'Lato-Regular', sans-serif;
    font-style: normal;
    font-size: 14px;
    color: #ffffff;
    height: 32px;
    line-height: 17px;
    text-transform: 'none';
    &:hover {
      background: #00938e;
      color: #ffff;
    }
  `;

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('programName');
  const [logs, setLogs] = useState<ProgramManagmentType[]>([]);
  const [paginationCount, setPaginationCount] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = React.useState('');
  const [headCells, setHeadCells] = useState(initialHeadCells);
  const navigate = useNavigate();
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;

  const redirect = () => {
    return navigate(PROGRAM_MANAGEMENT_SETUP_ROUTE_NAME);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(property);
  };
  useEffect(() => {
    if (
      headCells.length === 7 &&
      (checkPermissionForFeature('backend.employee', 'viewPermission') ||
        checkPermissionForFeature('backend.program_details', 'editPermission'))
    ) {
      setHeadCells((prevHeadCells) => [
        ...prevHeadCells,
        {
          id: 'actions',
          labelId: 'actions',
          defaultLabelId: 'Actions',
          numeric: false,
          requiredSorting: false,
        },
      ]);
    }
    return () => {
      setHeadCells(initialHeadCells);
    };
  }, []);
  useEffect(() => {
    toggleLoader(true);
    getAllProgramManagmentList(pageNumber, PAGE_SIZE, orderBy, order)
      .then((response: AddProgramList) => {
        if (response.clients) {
          setLogs(response.clients);
          setPaginationCount(response.totalClientsCount);
        }
        toggleLoader(false);
      })
      .catch((error) => {
        setOpen(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('updateFailureMessage');
          setToastrDefaultMessage('Failed to update details');
        }
        toggleLoader(false);
      });
  }, [order, orderBy, pageNumber]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const viewEmployee = (name: string, id: string) => {
    navigate('/program-management/employee-lookup', {
      state: {
        name: name,
        id: id,
      },
    });
  };

  const eidtProgram = (
    id: number,
    programId: string,
    programName: string,
    programAcronym: string,
  ) => {
    navigate(PROGRAM_MANAGEMENT_SETUP_ROUTE_NAME, {
      state: {
        programId: id,
        pId: programId,
        programName: programName,
        programAcronym: programAcronym,
      },
    });
  };

  const viewAllEmployees = () => {
    navigate(PROGRAM_MANAGEMENT_EMPLOYEE_LOOKUP_ROUTE_NAME, {
      replace: true,
      state: {
        name: '',
      },
    });
  };

  return (
    <>
      <SnackBarComponent
        open={open}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: '#E7F0F0',
          padding: '24px',
        }}
      >
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 1 }}>
            <TitleText
              labelid="Program.programList"
              defaultlabel="Program Management"
            />
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {checkPermissionForFeature(
                'backend.employee',
                'viewPermission',
              ) && (
                <div id="view-icon-view" onClick={() => viewAllEmployees()}>
                  <EyeIcon className="view-eye-icon" />
                  <Typography id="viewEmployees">
                    <FormattedMessage
                      id="Program.viewAll"
                      defaultMessage="View All Employees"
                    />
                  </Typography>
                </div>
              )}
              <CustomClientButton
                sx={{ textTransform: 'none' }}
                variant="contained"
                onClick={redirect}
                disabled={true}
              >
                <FormattedMessage
                  id="Program.addnew"
                  defaultMessage="Add New Program"
                />
              </CustomClientButton>
            </Box>
          </Box>
        </Toolbar>
        <Card sx={{ width: '100%' }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750, maxWidth: '100%' }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <TableHeader
                className="listDataTableHead"
                headerNames={headCells}
                checkBoxRequired={false}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />

              <TableBody className="tableRowcss">
                {logs.map((row) => {
                  if (!row.programName) {
                    return null;
                  } else {
                    return (
                      <TableRow
                        hover
                        key={row.programId}
                        tabIndex={-1}
                        sx={{
                          cursor: 'pointer',
                          alignContent: 'flex-start',
                          '&:nth-of-type(odd)': {
                            backgroundColor: '#ECF9F8',
                          },
                        }}
                      >
                        <TooltipTableCell
                          value={`${row.programName} ${
                            row.acronym ? `- (${row.acronym})` : ''
                          }`}
                          visible={true}
                        />
                        <TooltipTableCell
                          value={row.programCode ? row.programCode : '-'}
                          visible={true}
                        />
                        <TooltipTableCell
                          value={
                            row.programDirector ? row.programDirector : '-'
                          }
                          visible={true}
                        />

                        <TooltipTableCell
                          value={row.phoneNumber1 ? row.phoneNumber1 : '-'}
                          visible={true}
                        />

                        <TooltipTableCell
                          value={row.email ? row.email : '-'}
                          visible={true}
                        />

                        <TooltipTableCell
                          value={row.addressLine1 ? row.addressLine1 : '-'}
                          visible={true}
                        />

                        <TooltipTableCell
                          value={row.addressLine2 ? row.addressLine2 : '-'}
                          visible={true}
                        />
                        {(checkPermissionForFeature(
                          'backend.employee',
                          'viewPermission',
                        ) ||
                          checkPermissionForFeature(
                            'backend.program_details',
                            'editPermission',
                          )) && (
                          <TableCell>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {checkPermissionForFeature(
                                'backend.employee',
                                'viewPermission',
                              ) && (
                                <Box
                                  onClick={() =>
                                    viewEmployee(row.programName, row.programId)
                                  }
                                  sx={{ cursor: 'pointer' }}
                                >
                                  <Tooltip title="View Employee">
                                    <ViewEye />
                                  </Tooltip>
                                </Box>
                              )}
                              {checkPermissionForFeature(
                                'backend.program_details',
                                'editPermission',
                              ) && (
                                <Box
                                  sx={{
                                    marginLeft: '16px',
                                    cursor:
                                      row.programId ===
                                      JSON.parse(
                                        localStorage.getItem(
                                          'selectedProgram',
                                        ) || '{}',
                                      ).id
                                        ? 'pointer'
                                        : 'not-allowed',
                                  }}
                                  onClick={() => {
                                    if (
                                      row.programId ===
                                      JSON.parse(
                                        localStorage.getItem(
                                          'selectedProgram',
                                        ) || '{}',
                                      ).id
                                    ) {
                                      eidtProgram(
                                        row.id,
                                        row.programId,
                                        row.programName,
                                        row.acronym,
                                      );
                                    }
                                  }}
                                >
                                  <EditIcon />
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {paginationCount > 10 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <AppPagination
              pageNumber={pageNumber}
              paginationCount={paginationCount}
              handleChangePage={(_event, newPage) => {
                if (newPage - 1 !== pageNumber) {
                  setPageNumber(newPage - 1);
                }
              }}
            />
          </Box>
        )}
        {pageNumber === 0 && logs.length === 0 && !toggleLoader && (
          <Box sx={{ width: '100%' }}>
            <MediumTypography
              textstyle={'center'}
              labelid="CommunicationLogTableView.emptyMessage"
              defaultlabel="No logs found"
              paddingstyle="16px"
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ProgramManagmentTable;
