import { ReactComponent as Backarrow } from '../../assets/images/backarrow.svg';

interface BackArrowProps {
  onClick?: () => void;
}

const backarrow: React.FC<BackArrowProps> = (props) => {
  const { onClick } = props;

  return (
    <Backarrow className="cursorPointer pointerEvents" onClick={onClick} />
  );
};

export default backarrow;
