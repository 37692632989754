import Switch, { SwitchProps } from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

interface SwitchComponentProps {
  labelid?: string;
  defaultlabelid?: string;
  Disabled?: boolean;
  name: string;
  toggle: boolean;
  handlechange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
export default function SwitchComponent(props: Readonly<SwitchComponentProps>) {
  const { labelid, defaultlabelid, Disabled, name, toggle, handlechange } =
    props;

  const IOSSwitch = styled((propss: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...propss}
    />
  ))(() => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        color: '#ffff',
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          backgroundColor: '#00938E',
          opacity: 1,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          border: '2px solid #0c0ccc',
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#ffff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: '#CFDEDE',
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        color: '#CFDEDE',
        border: '2px solid #72d8d8',
        backgroundColor: '#0d9999',
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: '18px',
      height: '18px',
      color: '#CFDEDE',
      margin: '1px 0px 2px 1px',
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      border: '2px solid #CFDEDE',
      backgroundColor: '#ffff',
      width: '58px',
      height: '25px',
      opacity: 5,
    },
  }));
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      const simulatedEvent = {
        ...event,
        target: { checked: !toggle, name },
      };
      handlechange(
        simulatedEvent as unknown as React.ChangeEvent<HTMLInputElement>,
      );
    }
  };
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '16px 0px 16px 0px',
      }}
    >
      <Typography
        sx={{
          marginBottom: '4px',
          marginRight: '16px',
          fontFamily: 'Lato-Regular',
          fontStyle: 'normal',
          fontWeight: 600,
          fontSize: '14px',
          textAlign: 'center',
          lineHeight: '17px',
          color: '#2A4241',
        }}
      >
        {labelid && (
          <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
        )}
      </Typography>
      <IOSSwitch
        disabled={Disabled}
        onKeyDown={handleKeyDown}
        name={name}
        checked={toggle}
        onChange={handlechange}
      />
    </div>
  );
}
