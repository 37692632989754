import { IconButton, Tooltip } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

interface TooltipIconProps {
  labelId?: string;
  cursor?: string;
  defaultLabel?: string;
  Icon?: ReactNode;
  className?: string;
}

const TooltipTableCell: FC<TooltipIconProps> = ({
  labelId,
  cursor,
  defaultLabel,
  Icon,
  className,
}) => {
  const iconTitle = useIntl();
  return (
    <Tooltip
      className={className}
      title={iconTitle.formatMessage({
        id: labelId,
        defaultMessage: defaultLabel,
      })}
      arrow
      followCursor
      sx={{
        cursor: cursor ?? 'default',
      }}
      placement="top"
    >
      <IconButton>{Icon}</IconButton>
    </Tooltip>
  );
};

export default TooltipTableCell;
