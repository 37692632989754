import { Box, Card } from '@mui/material';
import { ReactComponent as HeaderImage } from '../../assets/images/Questionnare_Header.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import TitleText from '../../components/formlib/TitleText';

type ASQQuestionnaireHeaderComponentType = {
  totalMonth: number;
  startMonth: number;
  endMonth: number;
};

const ASQQuestionnaireHeaderComponent = ({
  totalMonth,
  startMonth,
  endMonth,
}: ASQQuestionnaireHeaderComponentType) => {
  return (
    <Card
      sx={{
        display: 'flex',
        paddingTop: '16px',
        paddingLeft: '32px',
        marginBottom: '16px',
      }}
    >
      <Box>
        <HeaderImage />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingTop: '56px',
          paddingRight: '24px',
          paddingLeft: '16px',
        }}
      >
        <Box>
          <Box sx={{ display: 'flex' }}>
            <TitleText
              label={`${totalMonth}`}
              textColor="#00C6B8"
              Sxprops={{ fontSize: '48px' }}
            />
            <TitleText
              label="Month Questionnaire"
              textColor="#00C6B8"
              Sxprops={{ paddingTop: '6px', paddingLeft: '12px' }}
            />
          </Box>
          <MediumTypography
            label={` ${startMonth} Month 0 days through ${endMonth} months 30 days`}
            textColor="#2A4241"
            fontweight={400}
            sxProps={{ paddingTop: '4px' }}
          />
        </Box>
        <Box>
          <TitleText label="Social-Emotional" textColor="#97A6A5" />
          <MediumTypography
            label="Second Edition"
            textColor="#97A6A5"
            fontweight={400}
            textstyle="right"
            sxProps={{ paddingTop: '4px' }}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default ASQQuestionnaireHeaderComponent;
