import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { ConsentDetailsType } from '../../services/configApi/forms/Consent/ConsentServices';

export interface ConsentContextType {
  consentDataJSON: ConsentDetailsType | null;
  selectedVersionNo: number;
  updateConsentJSON: (consentJson: ConsentDetailsType) => void;
  updateConsentVersionNo: (no: number) => void;
}

export const ConsentContext = createContext<ConsentContextType>({
  consentDataJSON: null,
  selectedVersionNo: 0,
  updateConsentJSON: () => {},
  updateConsentVersionNo: () => {},
});

interface ConsentProviderProps {
  children: ReactNode;
}

export const ConsentProvider = ({ children }: ConsentProviderProps) => {
  const [consentDataJSON, setConsentDataJSON] =
    useState<ConsentDetailsType | null>(() => {
      const savedData = localStorage.getItem('consentDataJSON');
      return savedData ? JSON.parse(savedData) : null;
    });

  const [selectedVersionNo, setSelectedVersionNo] = useState<number>(() => {
    const savedVersionNo = localStorage.getItem('selectedVersionNo');
    return savedVersionNo ? +savedVersionNo : 0;
  });

  const updateConsentJSON = (consentJson: ConsentDetailsType) => {
    setConsentDataJSON(consentJson);
  };

  const updateConsentVersionNo = (no: number) => {
    setSelectedVersionNo(no);
  };

  // Save consentDataJSON to localStorage whenever it changes
  useEffect(() => {
    if (consentDataJSON !== null) {
      localStorage.setItem('consentDataJSON', JSON.stringify(consentDataJSON));
      localStorage.setItem('selectedVersionNo', selectedVersionNo.toString());
    }
  }, [consentDataJSON]);

  return (
    <ConsentContext.Provider
      value={{
        consentDataJSON,
        selectedVersionNo,
        updateConsentJSON,
        updateConsentVersionNo,
      }}
    >
      {children}
    </ConsentContext.Provider>
  );
};
