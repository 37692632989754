import { Dialog, DialogTitle, DialogContent, Grid, Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import MediumTypography from '../MediumTypography';
import { Formik } from 'formik';
import SmallTypography from '../SmallTypography';
import ButtonComponent from '../ButtonComponent';
import * as yup from 'yup';
import {
  checkTimeExceeding,
  convertTimeDuration,
  formatStringDateInDecimals,
  formatTimeDurationInDecimals,
} from '../../../utils/dateUtil';
import { STORAGE_USERNAME_KEY } from '../../../services/Constant';
import DurationPicker from '../DurationPicker';

interface ActivityNoteModelProps {
  open: boolean;
  totalHours: string | null;
  approvedNonBillableHours: string | null;
  handleClose: () => void;
  onTimeEdit: (data: {
    totalHours: string | null;
    approvedNonBillableHours: string | null;
  }) => void;
}

const validationSchema = yup.object().shape({
  totalHours: yup.string().nullable(),
  approvedNonBillableHours: yup.string().nullable(),
});

const ActivityEditModal: FC<ActivityNoteModelProps> = ({
  open,
  approvedNonBillableHours,
  totalHours,
  handleClose,
  onTimeEdit,
}) => {
  const [employeeName, setEmployeeName] = useState<string | null>(null);

  useEffect(() => {
    setEmployeeName(localStorage.getItem(STORAGE_USERNAME_KEY));
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <Grid container direction={'column'} rowSpacing={4}>
          <Grid item>
            <MediumTypography
              label={employeeName ?? ''}
              sxProps={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginTop: '8px',
                lineHeight: '30px',
                wordBreak: 'break-word',
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            totalHours: totalHours,
            approvedNonBillableHours: approvedNonBillableHours,
          }}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={(values, { setFieldError }) => {
            if (
              values.approvedNonBillableHours !== null &&
              checkTimeExceeding(values.approvedNonBillableHours, '12:00')
            ) {
              setFieldError('approvedNonBillableHours', 'hrsLimitMessage');
              return;
            }

            if (
              values.totalHours !== null &&
              checkTimeExceeding(values.totalHours, '12:00')
            ) {
              setFieldError('totalHours', 'hrsLimitMessage');
              return;
            }

            const ApprovedNonBillablehoursConversion = convertTimeDuration(
              values.approvedNonBillableHours,
              false,
            );

            const totalHoursConversion = convertTimeDuration(
              values.totalHours,
              false,
            );

            onTimeEdit({
              approvedNonBillableHours: ApprovedNonBillablehoursConversion,
              totalHours: totalHoursConversion,
            });
          }}
        >
          {({ values, errors, touched, handleSubmit, setFieldValue }) => (
            <Grid sx={{ marginTop: '8px' }}>
              <Grid container item xs={12} columnSpacing={2} rowSpacing={2}>
                <Grid item xs={6}>
                  <DurationPicker
                    interval={25}
                    required={false}
                    labelId="totalHrsText"
                    defaultLabel="Total Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(values.totalHours)}
                    setDuration={(duration) => {
                      setFieldValue(
                        'totalHours',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.totalHours && touched.totalHours && (
                    <SmallTypography
                      sxProps={{ color: 'red' }}
                      labelId={errors.totalHours}
                      defaultLabelId="Field should not be empty"
                    />
                  )}
                </Grid>

                <Grid item xs={6}>
                  <DurationPicker
                    interval={25}
                    required={false}
                    labelId="approvedNonBillableHrsText"
                    defaultLabel="Approved Non-Billable Hrs"
                    maxDuration={{ hour: 12, minutes: 0 }}
                    duration={formatStringDateInDecimals(
                      values.approvedNonBillableHours,
                    )}
                    setDuration={(duration) => {
                      setFieldValue(
                        'approvedNonBillableHours',
                        formatTimeDurationInDecimals(duration),
                      );
                    }}
                  />
                  {errors.approvedNonBillableHours &&
                    touched.approvedNonBillableHours && (
                      <SmallTypography
                        sxProps={{ color: 'red' }}
                        labelId={errors.approvedNonBillableHours}
                        defaultLabelId="Field should not be empty"
                      />
                    )}
                </Grid>
              </Grid>

              <Box
                sx={{ marginTop: '24px' }}
                display={'flex'}
                justifyContent={'flex-end'}
              >
                <ButtonComponent
                  className="btn-primary btn-cancel"
                  labelId={'cancelText'}
                  variantType="outlined"
                  onClick={handleClose}
                />
                <ButtonComponent
                  className="btn-primary btn-submit ml-md"
                  labelId={'Insurance.update'}
                  onClick={handleSubmit}
                />
              </Box>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ActivityEditModal;
