import { Pagination } from '@mui/material';

export interface PaginationProps {
  pageNumber: number;
  perPageCount?: number;
  paginationCount: number;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const AppPagination: React.FC<PaginationProps> = ({
  pageNumber,
  perPageCount,
  paginationCount,
  handleChangePage,
}) => {
  return (
    <Pagination
      boundaryCount={1}
      page={pageNumber + 1}
      onChange={handleChangePage}
      sx={{
        '& .MuiPaginationItem-root': {
          fontFamily: 'Lato-Regular',
          fontWeight: 400,
          fontSize: '16px',
          margin: '8px 0px 8px 0px',
        },
        '& .MuiPaginationItem-root.Mui-selected': {
          backgroundColor: '#008C82',
          color: '#ffffff',
        },
      }}
      shape="circular"
      count={
        perPageCount
          ? Math.ceil(paginationCount / perPageCount)
          : Math.ceil(paginationCount / 10)
      }
      siblingCount={1}
    />
  );
};

export default AppPagination;
