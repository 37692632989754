import dayjs from 'dayjs';
import { DateString } from '../../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';
import { EmployeeLookup } from '../../programManagment/programManagment';
import { ActivitySheet } from '../activitySheet/weeklyActivitySheetProvider';
import { StatusType } from '../../../pages/activitySheet/ActivityTableRow';
import { STORAGE_USER_ID_KEY } from '../../Constant';

export interface LocalProgram {
  id: string;
  programId: string | null;
  programName: string | null;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  // editable: boolean;
  acronym: string | null;
  startDate: string | null;
  endDate: string | null;
}

export interface LocalEmployeeCredentials {
  credentialLicenseNumber: string | null;
  credentialExpirationDate: DateString | null;
  credentialFileUrl: string | null;
}

export interface BlockTimeData {
  blockTimeId?: string;
  blockingType: string;
  description?: string;
  startDate: string | null;
  endDate: string | null;
  startTime: string | null;
  endTime: string | null;
  duration: string | null;
  occurrenceDays: string[];
  occurrenceDates: {
    start: string;
    end: string;
  }[];
}
export interface BlockTimeDataList {
  blockTimeDetails: BlockTimeData[];
  totalBlockTimeCount: number;
}

export interface GroupEmployee {
  id: string | null;
  groupName: string | null;
}

export interface Employee {
  nameSuffix: string;
  id: string;
  clientId: string | null;
  kcUsername: string | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  preferredName: string | null;
  email: string | null;
  phone1: string | null;
  phone2: string | null;
  workingHoursPerWeek: number | null;
  anticipatedHoursPerWeek: number | null;
  allowAnticipatedHoursEdit: boolean | null;
  employeeRole: string | null;
  reportingTo: string | null;
  employeeType: string | null;
  startDate: string | null;
  endDate: string | null;
  name: string | null;
  status: string | null;
  primaryAddress1: string | null;
  primaryAddress2: string | null;
  primaryZipcode: string | null;
  primaryCity: string | null;
  primaryState: string | null;
  secondaryAddressRequired: boolean;
  secondaryAddress1: string | null;
  secondaryAddress2: string | null;
  secondaryZipcode: string | null;
  secondaryCity: string | null;
  secondaryState: string | null;

  emergencyFirstName: string | null;
  emergencyLastName: string | null;
  emergencyPhone: string | null;
  emergencyEmail: string | null;

  emergencyAddress1: string | null;
  emergencyAddress2: string | null;
  emergencyZipcode: string | null;
  emergencyCity: string | null;
  emergencyState: string | null;
  programs: LocalProgram[] | null;
  modifiedOn?: string | null;
  employeeCredentials: LocalEmployeeCredentials | null;
  allowDelete: boolean | null;

  signature: string;
  pin: string | number | null;

  signatureRowKey?: string;
  signaturePartitionKey?: string;

  phoneNumberExtension: string;
  phoneNumberExtension2: string;
  defaultProgram: string;
  superProgramId: string | null;
  disciplineType: string | null;
  group: GroupEmployee;
}

export interface EmployeeFilter {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: string;
  disciplineType: string;
  phoneNumberExtension: string;
}

export interface Schedule {
  scheduleId: string | null;
  day: number;
  lunchStartTime: string | null;
  lunchEndTime: string | null;
  startTime: string | null;
  endTime: string | null;
}

export interface BusinesScheduleResponse {
  myWeekSchedule: {
    0: Schedule | null;
    1: Schedule | null;
    2: Schedule | null;
    3: Schedule | null;
    4: Schedule | null;
    5: Schedule | null;
    6: Schedule | null;
  };
  workingHours: string | null;
  scheduledHours: string | null;
}

export interface EmployeesResponse {
  employeeCount: number;
  employees: Employee[];
}

export interface TaskManagerItem {
  id: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  clientId: string | null;
  name: string | null;
  dueDate: string | null;
  status: string | null;
}

export interface UpcommingEventItem {
  id: string | null;
  date: DateString;
  day: string | null;
  type: string | null;
}

export interface CaseLoadClient {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  assessmentHours: string | null;
  serviceCoordinator: string | null;
}

export interface ProgressNoteItem {
  id: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  suffix: string | null;
  dphId: string | null;
  appointmentId: string | null;
  commonAppointmentId: string | null;
  clientId: string | null;
  status: StatusType;
}

export interface CaseLoadList {
  noOfClients: number | null;
  clientsAbove30: number | null;
  unscheduledClients: number | null;
  caseLoadClients: CaseLoadClient[];
}

export interface EmployeeDashboardResponse {
  activityAndProductivity: ActivitySheet;
  taskManager: TaskManagerItem[];
  upcomingEvents: UpcommingEventItem[];
  caseLoad: CaseLoadList;
  progressNotes: ProgressNoteItem[];
}

export interface EmployeeDashboardResponseOffline {
  upcomingEvents: UpcommingEventItem[];
  progressNotes: ProgressNoteItem[];
}

export const getEmployeeDashboardDetails =
  async (): Promise<EmployeeDashboardResponse> => {
    const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
    const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return ApiResponseHandler(
      await ThomApiClient.getInstance().get<EmployeeDashboardResponse>(
        `employee/dashboard?timezone=${timezone}&zoneName=${zoneName}`,
      ),
    );
  };

export const getEmployeeList = async (
  search: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
): Promise<EmployeesResponse> => {
  const required = false;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EmployeesResponse>(
      `employee?search=${search}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType.toUpperCase()}&allEmployeesRequired=${required}`,
    ),
  );
};

export const fetchAllEmployees = async (): Promise<EmployeeFilter[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EmployeeFilter[]>(`employee/list`),
  );
};

export const getEmployeeById = async (
  employeeId: string,
): Promise<Employee> => {
  const userIdCurrent = localStorage.getItem(STORAGE_USER_ID_KEY);
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Employee>(
      userIdCurrent !== employeeId
        ? `employee/details?employeeId=${employeeId}`
        : `employee/my-profile?employeeId=${employeeId}`,
    ),
  );
};

export const getEmployeeByIdForProfile = async (
  employeeId: string,
): Promise<Employee> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Employee>(
      `employee/my-profile?employeeId=${employeeId}`,
    ),
  );
};

export const getBusinessSchedules = async (
  employeeId: string,
): Promise<BusinesScheduleResponse> => {
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<BusinesScheduleResponse>(
      `schedule?employeeId=${employeeId}&zoneName=${zoneName}`,
    ),
  );
};

export const createOrUpdateBusinessSchedules = async (
  employeeId: string,
  schedule: Schedule,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (schedule.scheduleId) {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().put<Schedule, ApiMessage>(
        `schedule?timezone=${timezone}&zoneName=${zoneName}`,
        schedule,
      ),
    );
  } else {
    return ApiResponseHandler(
      await ThomApiClient.getInstance().post<Schedule, ApiMessage>(
        `schedule?employeeId=${employeeId}&timezone=${timezone}&zoneName=${zoneName}`,
        schedule,
      ),
    );
  }
};

export const deleteBusinessSchedules = async (
  scheduleId: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `schedule?scheduleId=${scheduleId}&timezone=${timezone}&zoneName=${zoneName}`,
    ),
  );
};

export const updateEmployeeDataByAdmin = async (
  employee: Employee,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `employee?zoneName=${zoneName}&timezone=${timezone}`,
      employee,
    ),
  );
};

export const updateEmployeeDataByUser = async (
  employee: Employee,
): Promise<ApiMessage> => {
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `employee/my-profile?zoneName=${zoneName}&timezone=${timezone}`,
      employee,
    ),
  );
};

export const getAllEmployeeList = async (
  startDateTime: string,
): Promise<Employee[]> => {
  const timezone = encodeURIComponent(dayjs(startDateTime).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Employee[]>(
      `attendee/list?startDateTime=${startDateTime}&timezone=${timezone}`,
    ),
  );
};

export const getEmployeeLookup = async (
  page: number,
  size: number,
  sort: string,
  sortType: string,
  searchquery: string,
  programName: string,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EmployeeLookup>(
      `employee/lookup?page=${page}&size=${size}&sort=${sort}&sortType=${sortType}&search=${searchquery}&program=${programName}`,
    ),
  );
};

export const getAllEmployee = async (
  page: number,
  size: number,
  sort: string,
  sortType: string,
  searchquery: string,
) => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<EmployeeLookup>(
      `employee?page=${page}&size=${size}&sort=${sort}&sortType=${sortType.toUpperCase()}&search=${searchquery}`,
    ),
  );
};

export const addEmployeeBlockTime = async (
  employeeId: string,
  requestBody: BlockTimeData,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<BlockTimeData, ApiMessage>(
      `block-time?employeeId=${employeeId}`,
      requestBody,
    ),
  );
};

export const getEmployeeBlockTime = async (
  employeeId: string,
  pageNo: number,
  year: string,
): Promise<BlockTimeDataList> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<BlockTimeDataList>(
      `block-time?employeeId=${employeeId}&page=${pageNo}&size=5&filter=${year}`,
    ),
  );
};
export const deleteEmployeeBlockTime = async (
  blockingTimeId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `block-time?blockTimeId=${blockingTimeId}`,
    ),
  );
};

export const updateEmployeeBlockTime = async (
  requestBody: BlockTimeData,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put<BlockTimeData, ApiMessage>(
      `block-time`,
      requestBody,
    ),
  );
};

export const uploadEmployeeProfileOrCredentialFile = async (
  imageFile: File | null,
  pdfFile: File | null,
  employeeId: string,
): Promise<ApiMessage> => {
  const formData = new FormData();
  if (imageFile !== null) {
    formData.append('imageFile', imageFile);
  } else {
    formData.append('imageFile', '');
  }
  if (pdfFile !== null) {
    formData.append('pdfFile', new File([pdfFile], pdfFile.name.toLowerCase()));
  } else {
    formData.append('pdfFile', '');
  }
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `employee/uploadFile?employeeId=${employeeId}`,
      formData,
    ),
  );
};

export const fileDelete = async (
  employeeId: string,
  imageFile: boolean,
  pdfFile: boolean,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `employee/deleteFile?employeeId=${employeeId}&imageFile=${imageFile}&pdfFile=${pdfFile}`,
    ),
  );
};
