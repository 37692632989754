import { FC } from 'react';
import {
  AlertColor,
  Box,
  IconButton,
  Modal,
  Tooltip,
  Grid,
} from '@mui/material';
import { EventType } from '../../utils/type';

import { ReactComponent as Close } from '../../assets/images/x.svg';
import { ReactComponent as DELETE } from '../../assets/images/delete.svg';
import { deleteEvent } from '../../services/schedulerEventsApi';
import { ReactComponent as AlignLeft } from '../../assets/images/align_left.svg';
import { ReactComponent as Calender } from '../../assets/images/Calendar.svg';
import { ReactComponent as Clock } from '../../assets/images/clock.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import moment from 'moment';
import React from 'react';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { LOCAL_TIME_12_HOUR_FORMAT } from '../../services/Constant';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { useIntl } from 'react-intl';

interface ModalFieldProps {
  data: EventType;
  eventId: string;
  openViewEventModel: boolean;
  eventDelete: boolean;
  onClose: (
    openFromView: boolean,
    toastrVariableFromView?: AlertColor,
    toastrMessageFromView?: string,
    toastrIdFromView?: string,
    toastrDefaultMessageFromView?: string,
  ) => void;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '410px',
  height: 'auto',
  bgcolor: 'background.paper',
  padding: '10px 20px 20px 20px',
  maxHeight: '450px',
  borderRadius: '6px',
  overflowX: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: 'rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0.1)',
  '&::-webkit-scrollbar': {
    width: '6px',
    background: 'none',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
  },
  outline: 'none',
  fontFamily: 'Lato-Regular',
};

const EventModal: FC<ModalFieldProps> = ({
  data,
  eventId,
  openViewEventModel,
  onClose,
  eventDelete,
}) => {
  const rctl = useIntl();
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const getDates = () => {
    const utcStartDateTime = moment(
      `${data.startDate} ${data.startTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();

    const utcEndDateTime = moment(
      `${data.endDate} ${data.endTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();
    return `${utcStartDateTime.format('MM/DD/YYYY')} - ${utcEndDateTime.format(
      'MM/DD/YYYY',
    )}`;
  };

  const getTimes = () => {
    const utcStartDateTime = moment(
      `${data.startDate} ${data.startTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();

    const utcEndDateTime = moment(
      `${data.endDate} ${data.endTime}`,
      'MM/DD/YYYY HH:mm',
    )
      .utc(true)
      .local();
    return `${utcStartDateTime.format(
      LOCAL_TIME_12_HOUR_FORMAT,
    )} - ${utcEndDateTime.format(LOCAL_TIME_12_HOUR_FORMAT)}`;
  };

  function handleDelete(type: string) {
    deleteEvent(eventId, type)
      .then(() => {
        closeDeleteModal(
          true,
          'success',
          undefined,
          'DeleteEvent.successMessage',
          'Event deleted successfully',
        );
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          closeDeleteModal(
            true,
            'error',
            undefined,
            rctl.formatMessage({ id: apiError.id }),
            apiError.message,
          );
        } else {
          closeDeleteModal(
            true,
            'error',
            undefined,
            'DeleteEvent.errorMessage',
            "Event couldn't be deleted",
          );
        }
      });
  }
  const closeDeleteModal = (
    openFromDelete: boolean,
    toastrVariableFromDelete?: AlertColor,
    toastrMessageFromDelete?: string,
    toastrIdFromEdit?: string,
    toastrDefaultMessageFromDelete?: string,
  ) => {
    onClose(
      openFromDelete,
      toastrVariableFromDelete,
      toastrMessageFromDelete,
      toastrIdFromEdit,
      toastrDefaultMessageFromDelete,
    );
  };
  return (
    <>
      <Modal
        open={openViewEventModel}
        onClose={() => {
          onClose(false, undefined, undefined, undefined, undefined);
        }}
      >
        <Box sx={style}>
          <Box display="flex" justifyContent="flex-end">
            {!eventDelete && (
              <Tooltip title="Delete">
                <IconButton
                  onClick={() => {
                    toggleLoader(true);
                    handleDelete('blocked');
                  }}
                >
                  <DELETE />
                </IconButton>
              </Tooltip>
            )}
            <IconButton
              onClick={() =>
                onClose(false, undefined, undefined, undefined, undefined)
              }
            >
              <Close />
            </IconButton>
          </Box>

          <MediumTypography
            labelid="Scheduler.BlockTimeTitle"
            defaultlabel="Block Time"
            textColor="#008C82"
            fontSize="16px"
            sxProps={{ fontFamily: 'Lato-Bold' }}
          />

          <Box className="flex__ ">
            <MediumTypography
              labelid="Scheduler.BlockType"
              defaultlabel="Blocking Type:"
              textColor="#97A6A5"
              fontSize="12px"
              className="mt-md"
              sxProps={{ fontFamily: 'Lato-Bold' }}
            />

            <MediumTypography
              className="pl-xs mt-md"
              textColor="#2A4241"
              fontSize="12px"
              fontweight={600}
              sxProps={{ fontFamily: 'Lato-Bold' }}
              label={
                data && data.blockingType !== null ? data.blockingType : ''
              }
            />
          </Box>

          <Box className="mt-sm">
            <Grid
              container
              direction="row"
              alignItems="center"
              sx={{ mt: 0.5 }}
              style={{ padding: '0px 0px' }}
            >
              <Grid
                item
                xs={1.3}
                sx={{ paddingTop: '4px' }}
                className="svgIcon"
              >
                <AlignLeft />
              </Grid>
              <Grid
                item
                xs={10.7}
                style={{
                  color: '#2A4241',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '18px',
                  wordWrap: 'break-word',
                }}
              >
                {' '}
                {data.description}
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ padding: '0px 0px' }}
            >
              <Grid item xs={1.3} sx={{ marginTop: '4px' }} className="svgIcon">
                <Calender />
              </Grid>
              <Grid
                item
                xs={10.7}
                style={{
                  color: '#2A4241',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '18px',
                  wordWrap: 'break-word',
                }}
              >
                {' '}
                {getDates()}
              </Grid>
            </Grid>
            <Grid
              style={{
                color: '#2A4241',
                fontSize: '12px',
                fontWeight: '400',
                marginLeft: '40px',
                wordWrap: 'break-word',
              }}
            >
              {data.occurrenceDays.join(', ')}
            </Grid>
            <Grid
              container
              direction="row"
              alignItems="center"
              style={{ padding: '0px 0px' }}
            >
              <Grid item xs={1.3} sx={{ marginTop: '4px' }} className="svgIcon">
                <Clock />
              </Grid>
              <Grid
                item
                xs={10.7}
                style={{
                  color: '#2A4241',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '18px',
                  wordWrap: 'break-word',
                }}
              >
                {' '}
                {getTimes()}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default EventModal;
