import React from 'react';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import MediumTypography from './MediumTypography';

export type RadioButtonData = {
  value: string;
  label?: string;
  labelId?: string;
  defaultLabel?: string;
  titleId?: string;
  defaultTitleId?: string;
};

type CustomRadioButtonType = {
  data: RadioButtonData[];
  value: string | null;
  needUnSelect?: boolean;
  onSelectItem: (value: string | null) => void;
  disable?: boolean;
  row?: boolean;
  paddingY?: string;
  className?: string;
  flexDirection?: string;
  textStyleClassName?: string;
};

const CustomRadioButton = ({
  data,
  value,
  needUnSelect,
  onSelectItem,
  disable,
  row = true,
  paddingY,
  className,
  textStyleClassName,
  flexDirection = 'row',
}: CustomRadioButtonType) => {
  return (
    <Box component="div" sx={{ display: 'inline-flex' }}>
      <RadioGroup
        row={row}
        value={value}
        className={className}
        sx={{
          flexDirection: flexDirection,
        }}
      >
        {data.map((item) => (
          <Box key={item.value}>
            {item.titleId && (
              <MediumTypography
                labelid={item.titleId}
                defaultlabel={item.defaultTitleId}
                sxProps={{
                  marginTop: '8px',
                  color: '#2a4241',
                  fontWeight: 'bold',
                }}
              />
            )}
            <FormControlLabel
              key={item.value}
              value={item.value}
              control={
                <Radio
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    const target = event.target as HTMLInputElement;
                    if (target.value === value && needUnSelect) {
                      onSelectItem(null);
                    } else {
                      onSelectItem(target.value);
                    }
                  }}
                  sx={{
                    paddingY:
                      row === true ? 0 : row === false ? paddingY : '0px',
                    color: '#97A6A5',
                    '&.Mui-checked': {
                      color: disable ? '#97A6A5' : '#008C82',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                    },
                  }}
                  disabled={disable}
                  size="medium"
                />
              }
              label={
                <MediumTypography
                  className={textStyleClassName}
                  label={item.label}
                  labelid={item.labelId}
                  defaultlabel={item.defaultLabel}
                  sxProps={{ display: 'inline-flex' }}
                />
              }
            />
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default CustomRadioButton;
