import { Box } from '@mui/system';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FC } from 'react';
import { Tooltip, Grid } from '@mui/material';
import ThomProgram from '../../assets/images/thomchildlogo.svg';

export type Program = {
  name: string;
  phoneNumber: string;
  faxNumber: string;
  imageUrl: string;
  website: string;
  programDirector?: string;
};
interface ProgramHeaderprops {
  program: Program;
  programNameLabel: string;
  programNameDefaultLabel: string;
  phoneNumberLabel: string;
  phoneNumberDefaultLabel: string;
  faxNumberLabel: string;
  faxNumberDefaultLabel: string;
  websiteLabel: string;
  websiteDefaultLabel: string;
  programDirectorLabelid: string;
  programDirectorDefaultlabel: string;
}

const ProgramDetails: FC<ProgramHeaderprops> = (props) => {
  return (
    <Box component="main" className="mt-xs p-none">
      <Grid container className="flex__justify__space-between">
        <Grid item xs={8}>
          <Box component="div" className="flex__ pt-md">
            <MediumTypography
              labelid={props.programNameLabel}
              defaultlabel={props.programNameDefaultLabel}
              fontSize="20px"
              fontweight={700}
              textColor="#97A6A5"
              sxProps={{
                fontFamily: 'Lato-Bold',
              }}
            />
            <Tooltip title={props.program.name} followCursor arrow={true}>
              <Box component="div">
                <MediumTypography
                  fontSize="20px"
                  fontweight={700}
                  textColor="#2A4241"
                  className="pl-xs "
                  sxProps={{
                    fontFamily: 'Lato-Bold',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={props.program.name}
                />
              </Box>
            </Tooltip>
          </Box>
          <Box component="div" className="flex__ width__100 mt-md">
            <Box component="div" className="flex__ pr-md ">
              <MediumTypography
                labelid={props.phoneNumberLabel}
                defaultlabel={props.phoneNumberDefaultLabel}
                fontSize="16px"
                textColor="#97A6A5"
              />
              <MediumTypography
                fontSize="16px"
                textColor="#2A4241"
                className="pl-xs width150px "
                label={
                  props.program.phoneNumber === '' ||
                  props.program.phoneNumber === null
                    ? '-'
                    : props.program.phoneNumber
                }
                sxProps={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              />
            </Box>
            <Box component="div" className="flex__ pr-md ">
              <MediumTypography
                labelid={props.faxNumberLabel}
                defaultlabel={props.faxNumberDefaultLabel}
                fontSize="16px"
                textColor="#97A6A5"
              />
              <MediumTypography
                fontSize="16px"
                textColor="#2A4241"
                className="pl-xs width150px "
                label={
                  props.program.faxNumber === '' ||
                  props.program.faxNumber === null
                    ? '-'
                    : props.program.faxNumber
                }
                sxProps={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              />
            </Box>
            <Box component="div" className="flex__ pr-md">
              <MediumTypography
                labelid={props.websiteLabel}
                defaultlabel={props.websiteDefaultLabel}
                fontSize="16px"
                textColor="#97A6A5"
              />
              <Box component="div" className="width200px">
                <Tooltip
                  title={
                    props.program.website && props.program.website.length > 10
                      ? props.program.website
                      : ''
                  }
                  followCursor
                  arrow={true}
                >
                  <Box component="div">
                    <MediumTypography
                      fontSize="16px"
                      textColor="#2A4241"
                      className="pl-xs "
                      label={
                        props.program.website === '' ||
                        props.program.website === null
                          ? '-'
                          : props.program.website
                      }
                      sxProps={{
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                      }}
                    />
                  </Box>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <Box className="flex__ mt-sm mb-md ">
            <MediumTypography
              labelid={props.programDirectorLabelid}
              defaultlabel={props.programDirectorDefaultlabel}
              textColor="#97A6A5"
              fontSize="16px"
            />

            <Tooltip
              title={
                props.program.programDirector &&
                props.program.programDirector.length > 10
                  ? `${props.program.programDirector}`
                  : ''
              }
              followCursor
              arrow={true}
            >
              <Box className="width150px ">
                <MediumTypography
                  fontSize="16px"
                  textColor="#2A4241"
                  sxProps={{
                    paddingLeft:
                      props.program.programDirector === '' ||
                      props.program.programDirector === null ||
                      props.program.programDirector === undefined
                        ? '24px'
                        : '6px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  label={
                    props.program.programDirector === '' ||
                    props.program.programDirector === null ||
                    props.program.programDirector === undefined
                      ? '-'
                      : `${props.program.programDirector}`
                  }
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
        <Grid item className="p-sm justifyContent-End">
          <Box>
            <img
              style={{
                width: '250px',
                height: '63px',
              }}
              src={
                props.program.imageUrl === '' || props.program.imageUrl === null
                  ? ThomProgram
                  : props.program.imageUrl
              }
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProgramDetails;
