import { CommunicationLog, Contact } from '../../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export interface CommunicationLogResponse {
  totalCount: number;
  communicationLogs: CommunicationLog[];
}

export const getCommunicationLogs = async (
  clientId: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
): Promise<CommunicationLogResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<CommunicationLogResponse>(
      `clients/communicationLogs?clientId=${clientId}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType}`,
    ),
  );
};

export const submitCommunicationLog = async (
  clientId: string,
  body: CommunicationLog,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post<CommunicationLog, ApiMessage>(
      `clients/communicationLogs?clientId=${clientId}`,
      body,
    ),
  );
};

export const deleteCommunicationLog = async (
  logId?: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(
      `clients/communicationLogs?communicationLogId=${logId}`,
    ),
  );
};

export const getClientContacts = async (
  clientId: string,
): Promise<Contact[]> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Contact[]>(
      `clients/contacts?clientId=${clientId}`,
    ),
  );
};
