import { ChangeEvent, memo, useEffect, useMemo, useRef, useState } from 'react';
import './ImagePickerStyles.css';
import { IState, ImagePickerConf } from './imagepicker.models';
import { ReactComponent as Logo } from '../../assets/images/logo-selector.svg';
import { ReactComponent as ProfileLogo } from '../../assets/images/profile-default.svg';
import { Box } from '@mui/material';

type ImageUploadData = {
  dataUri: string;
  height: number;
  width: number;
  state?: IState;
};

type ReaderEvent = {
  target: {
    result: string;
  };
};

type UploadEventType = {
  preventDefault: () => void;
};

export const initialConfig: ImagePickerConf = {
  language: 'en',
  objectFit: 'cover',
  hideDeleteBtn: false,
  hideDownloadBtn: false,
  hideEditBtn: false,
  hideAddBtn: false,
  compressInitial: null,
  type: null,
};

const initialState: IState = {
  maxHeight: 3000,
  maxWidth: 3000,
  cropHeight: 150,
  cropWidth: 150,
  maintainAspectRatio: true,
  format: 'jpeg',
  arrayCopiedImages: [],
  originImageSrc: '',
  basicFilters: undefined,
  quality: 100,
};

const ReactImagePickerEditor = memo(
  ({
    config = {},
    imageSrcProp = '',
    imageChanged = () => {},
  }: {
    config: ImagePickerConf;
    imageSrcProp?: string;
    imageChanged?: (imgUrl: string | null) => void;
  }) => {
    const [state, setState] = useState<IState>({
      ...initialState,
    });
    const [imageSrc, setImageSrc] = useState<string | null>('');
    const [loadImage, setLoadImage] = useState<boolean>(false);
    const [configuration, setConfiguration] =
      useState<ImagePickerConf>(initialConfig);
    const imagePicker = useRef<HTMLInputElement>(null);
    const fileType = useRef('');
    const urlImage = useRef('');
    const uuidFilePicker = Date.now().toString(20);
    const imageName = useRef('download');

    useEffect(() => {
      appendLinkIconsToHead();
      processConfig();
    }, [config]);

    useEffect(() => {
      loadImageFromProps();
    }, [imageSrcProp]);

    async function loadImageFromProps() {
      if (imageSrcProp) {
        const result = await parseToBase64(imageSrcProp);
        const newState: IState = result.state;
        newState.originImageSrc = imageSrcProp;
        newState.arrayCopiedImages = [
          {
            lastImage: result.imageUri,
            width: newState.maxWidth,
            height: newState.maxHeight,
            quality: newState.quality,
            format: newState.format,
            originImageSrc: imageSrcProp,
          },
        ];
        setImageSrc(result.imageUri);
        setState(newState);
        setLoadImage(true);
      } else {
        const newState = { ...state };
        newState.originImageSrc = null;
        newState.arrayCopiedImages = [];
        setLoadImage(false);
        setImageSrc(null);
        setState(newState);
      }
    }

    useEffect(() => {
      imageChanged(imageSrc);
    }, [imageSrc]);

    function processConfig() {
      const dataConf = { ...configuration, ...config };
      setConfiguration(dataConf);
    }

    function appendLinkIconsToHead() {
      const head: HTMLElement = document.head;
      const linkIcons: HTMLElement | null = head.querySelector(
        '#ngp-image-picker-icons-id',
      );
      if (linkIcons) return;
      const link = document.createElement('link');
      link.href = 'https://fonts.googleapis.com/icon?family=Material+Icons';
      link.rel = 'stylesheet';
      link.id = 'ngp-image-picker-icons-id';
      head.appendChild(link);
    }

    function onUpload(event: UploadEventType) {
      event.preventDefault();
      imagePicker?.current?.click();
    }

    function handleFileSelect(
      this: typeof handleFileSelect,
      event: ChangeEvent<HTMLInputElement>,
    ) {
      const files: FileList | null = event.target.files;
      if (files !== null && files.length > 0) {
        const file = files[0];
        imageName.current = file.name.split('.')[0];
        fileType.current = file.type;
        if (!fileType.current.includes('image')) return;
        urlImage.current = `data:${file.type};base64,`;
        if (file) {
          setState({ ...state, format: fileType.current.split('image/')[1] });
          const reader = new FileReader();
          reader.onload = handleReaderLoaded.bind(reader);
          const blobFile = file as unknown as Blob;
          reader.readAsDataURL(blobFile);
        }
      }
    }

    async function handleReaderLoaded(
      this: FileReader,
      readerEvt: ProgressEvent<FileReader>,
    ) {
      const castedReaderEvt = readerEvt as ReaderEvent;
      const binaryString = castedReaderEvt.target.result;
      const base64textString = btoa(binaryString);
      const newState = { ...state };
      const newImageSrc = urlImage.current + base64textString;
      newState.originImageSrc = urlImage.current + base64textString;

      const img = document.createElement('img');
      img.src = newImageSrc;
      img.onload = () => {
        newState.arrayCopiedImages = [];
        newState.maxHeight = img.height;
        newState.maxWidth = img.width;
        newState.format = fileType.current.split('image/')[1];
        newState.arrayCopiedImages.push({
          lastImage: newImageSrc,
          width: img.width,
          height: img.height,
          quality: newState.quality,
          format: fileType.current.split('image/')[1],
          originImageSrc: newState.originImageSrc as string,
        });
        setState(newState);
        setImageSrc(newImageSrc);
        setLoadImage(true);
      };
    }

    useMemo(() => {
      if (imageSrc?.length) {
        return Math.ceil(((3 / 4) * imageSrc.length) / 1024);
      } else {
        return '';
      }
    }, [imageSrc]);

    function parseToBase64(
      imageUrl: string,
    ): Promise<{ imageUri: string; state: IState }> {
      let newState = { ...state };
      const types = imageUrl.split('.');
      const type = types[types.length - 1];
      if (type && (type == 'png' || type == 'jpeg' || type == 'webp')) {
        newState.format = type;
      } else {
        newState.format = 'jpeg';
      }
      newState.format = type;
      if (config.compressInitial != null) {
        let quality = 1;
        if (config.compressInitial >= 0 && config.compressInitial <= 100) {
          quality = config.compressInitial;
        }
        newState.quality = quality;
      }

      return new Promise<ImageUploadData>((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        newState.maxHeight = img.height;
        newState.maxWidth = img.width;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
          const ratio = 1.0;
          canvas.width = img.width * ratio;
          canvas.height = img.height * ratio;
          if (ctx != null) {
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const dataURI = canvas.toDataURL(
              `image/${type}`,
              newState.quality / 100,
            );

            return resolve({
              dataUri: dataURI,
              width: canvas.width,
              height: canvas.height,
            });
          } else {
            return reject('Canvas context is null');
          }
        };
        img.onerror = () => {
          return reject(`Error loading the src = ${imageUrl}`);
        };
        img.src = imageUrl;
      }).then((data: ImageUploadData) => {
        newState = {
          ...newState,
          maxHeight: data.height,
          maxWidth: data.width,
        };
        return { imageUri: data.dataUri, state: newState };
      });
    }

    function onRemove() {
      setImageSrc(null);
      setLoadImage(false);
      const newState: IState = {
        ...state,
        ...initialState,
      };
      setState(newState);
    }

    return (
      <Box className="ReactImagePickerEditor">
        {!loadImage && configuration.type === 'ProfilePicture' && (
          <Box
            style={{
              flexDirection: 'row',
              boxSizing: 'border-box',
              display: 'flex',
              placeContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              style={{
                width: '60px',
                height: '60px',
                borderRadius: configuration.borderRadius,
                aspectRatio: configuration.aspectRatio + '',
                flexDirection: 'column',
                boxSizing: 'border-box',
                display: 'flex',
                placeContent: 'center',
                alignItems: 'center',
                position: 'relative',
                maxWidth: '100% !important',
                padding: '2px',
              }}
            >
              <ProfileLogo />
            </Box>
            <Box>
              <b
                style={{
                  color: '#00C6B8',
                  fontSize: '14px',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={onUpload}
              >
                Add Profile Picture
              </b>
              <input
                ref={imagePicker}
                type="file"
                style={{ display: 'none' }}
                id={'filePicker-' + uuidFilePicker}
                onChange={handleFileSelect}
              />
            </Box>
          </Box>
        )}
        {!loadImage && configuration.type === 'ProgramImage' && (
          <Box style={{ cursor: 'pointer' }}>
            <Logo onClick={onUpload} />
            <input
              ref={imagePicker}
              type="file"
              style={{ display: 'none' }}
              id={'filePicker-' + uuidFilePicker}
              onChange={handleFileSelect}
            />
          </Box>
        )}
        {loadImage && configuration.type === 'ProfilePicture' && (
          <Box
            style={{
              flexDirection: 'row',
              boxSizing: 'border-box',
              display: 'flex',
              placeContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              style={{
                width: '60px',
                height: '60px',
                borderRadius: configuration.borderRadius,
                aspectRatio: configuration.aspectRatio + '',
                flexDirection: 'column',
                boxSizing: 'border-box',
                display: 'flex',
                placeContent: 'center',
                alignItems: 'center',
                position: 'relative',
                maxWidth: '100% !important',
                padding: '2px',
              }}
            >
              <img
                src={imageSrc as string}
                alt="image-loaded"
                style={{
                  borderRadius: configuration.borderRadius,
                  objectFit: configuration.objectFit,
                  height: '100%',
                  maxHeight: '100%',
                  width: '100%',
                  maxWidth: '100%',
                  objectPosition: 'center',
                  background: 'black',
                }}
              />
            </Box>
            <Box>
              <b
                style={{
                  color: '#00C6B8',
                  fontSize: '16px',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={onUpload}
              >
                Change
              </b>
              <input
                ref={imagePicker}
                type="file"
                style={{ display: 'none' }}
                id={'filePicker-' + uuidFilePicker}
                onChange={handleFileSelect}
              />
            </Box>

            <Box>
              <b
                style={{
                  color: '#EB4C60',
                  fontSize: '16px',
                  cursor: 'pointer',
                  marginLeft: '10px',
                }}
                onClick={onRemove}
              >
                Remove
              </b>
            </Box>
          </Box>
        )}
        {loadImage && configuration.type === 'ProgramImage' && (
          <Box
            style={{
              flexDirection: 'row',
              boxSizing: 'border-box',
              display: 'flex',
              placeContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                flexDirection: 'column',
                boxSizing: 'border-box',
                display: 'flex',
                placeContent: 'flex-start',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                maxWidth: '100% !important',
                border: '1px dashed #97A6A5',
                backgroundColor: '#fcfcfc',
                width: '273px',
                height: '69px',
                borderRadius: configuration.borderRadius,
                aspectRatio: configuration.aspectRatio + '',
                backgroundSize: 'cover',
              }}
            >
              <img
                src={imageSrc as string}
                alt="image-loaded"
                style={{
                  width: 'auto',
                  height: '60px',
                  borderRadius: configuration.borderRadius,
                  objectFit: configuration.objectFit,
                }}
              />
            </Box>
            <Box>
              <b
                style={{
                  marginLeft: '10px',
                  color: '#00C6B8',
                  fontSize: '16px',
                  cursor: 'pointer',
                }}
                onClick={onUpload}
              >
                Change Logo
              </b>
              <input
                ref={imagePicker}
                type="file"
                style={{ display: 'none' }}
                id={'filePicker-' + uuidFilePicker}
                onChange={handleFileSelect}
              />
            </Box>
          </Box>
        )}
      </Box>
    );
  },
);

export default ReactImagePickerEditor;
